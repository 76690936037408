import { CLEAR_ALERT, CLEAR_MESSAGE } from '../constants/errorConstants';
import {
  GET_TEAM_MEMBER_SUCCESS,
  GET_TEAM_MEMBER_FAIL,
  GET_TEAM_MEMBER_REQUEST,
  GET_TEAM_MEMBER_DIRECT_SUCCESS,
  GET_TEAM_MEMBER_DIRECT_FAIL,
  GET_TEAM_MEMBER_DIRECT_REQUEST,
  GET_TEAM_INIT_REQUEST,
  GET_TEAM_INIT_SUCCESS,
  GET_TEAM_INIT_FAIL,
  ADD_NEW_BLANCO_TEAM_MEMBER_REQUEST,
  ADD_NEW_BLANCO_TEAM_MEMBER_SUCCESS,
  ADD_NEW_BLANCO_TEAM_MEMBER_FAIL,
  ADD_PHONE_TO_NEW_MEMBER_SUCCESS,
  ADD_PHONE_TO_NEW_MEMBER_REQUEST,
  ADD_PHONE_TO_NEW_MEMBER_FAIL,
  CONFIRM_NEW_MEMBER_SMS_CODE_FAIL,
  CONFIRM_NEW_MEMBER_SMS_CODE_SUCCESS,
  NEW_MEMBER_RESEND_SMS_SUCCESS,
  NEW_MEMBER_RESEND_SMS_FAIL,
  CONFIRM_NEW_TEAM_MEMBER_EMAIL_SUCCESS,
  CONFIRM_NEW_TEAM_MEMBER_EMAIL_FAIL,
  OPEN_ADD_TEAM_MEMBER_DRAWER,
  CLOSE_ADD_TEAM_MEMBER_DRAWER,
  GET_DIRECT_CUSTOMERS_REQUEST,
  GET_DIRECT_CUSTOMERS_SUCCESS,
  GET_DIRECT_CUSTOMERS_FAIL,
  GET_INDIRECT_CUSTOMERS_SUCCESS,
  GET_INDIRECT_CUSTOMERS_REQUEST,
  GET_INDIRECT_CUSTOMERS_FAIL,
  GET_VOLUME_INFO_REQUEST,
  GET_VOLUME_INFO_SUCCESS,
  GET_VOLUME_INFO_FAIL,
  GET_WAITING_ROOM_REQUEST,
  GET_WAITING_ROOM_SUCCESS,
  GET_WAITING_ROOM_FAIL,
  ADD_DIRECT_BREADCRUMB_SUCCESS,
  POP_DIRECT_BREADCRUMB_SUCCESS,
  CLEAR_DIRECT_BREADCRUMB_SUCCESS
} from '../constants/myTeamConstants';

export const myTeam = (
  state = {
    rootTeamMember: {},
    leftDirectUsers: [],
    rightDirectUsers: [],
    levels: [],
    newTeamMember: {},
    userInfo: {},
    directBreadcrumbs: []
  },
  action
) => {
  switch (action.type) {
    ///
    //get team member
    ///
    case GET_TEAM_INIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_TEAM_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case GET_TEAM_INIT_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    ///
    //get team member
    ///

    ///
    //get team member
    ///
    case GET_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        rootTeamMember: action.payload
      };
    case GET_TEAM_MEMBER_FAIL:
      return {
        loading: false,
        error: action.payload
      };
    ///
    //get team member
    ///

    ///
    //get team member direct
    ///

    case GET_TEAM_MEMBER_DIRECT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_TEAM_MEMBER_DIRECT_SUCCESS:
      return {
        ...state,
        loading: false,
        leftDirectUsers: action.payload.leftDirectUsers,
        rightDirectUsers: action.payload.rightDirectUsers
      };
    case GET_TEAM_MEMBER_DIRECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_DIRECT_BREADCRUMB_SUCCESS:
      return {
        ...state,
        directBreadcrumbs: [...state.directBreadcrumbs, action.payload]
      };

    case POP_DIRECT_BREADCRUMB_SUCCESS:
      return {
        ...state,
        directBreadcrumbs: [...state.directBreadcrumbs.pop()]
      };

    case CLEAR_DIRECT_BREADCRUMB_SUCCESS:
      return {
        ...state,
        directBreadcrumbs: []
      };
    ///
    //get team member direct
    ///

    ///
    ///ADD_NEW_BLANCO_TEAM_MEMBER
    ///
    case ADD_NEW_BLANCO_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_NEW_BLANCO_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        newTeamMember: { id: action.payload }
      };

    case ADD_NEW_BLANCO_TEAM_MEMBER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    ///
    ///ADD_NEW_BLANCO_TEAM_MEMBER
    ///
    case ADD_PHONE_TO_NEW_MEMBER_REQUEST:
      return { ...state, loading: true };

    case ADD_PHONE_TO_NEW_MEMBER_SUCCESS:
      return { ...state, loading: false };

    case ADD_PHONE_TO_NEW_MEMBER_FAIL:
      return { ...state, loading: false, error: action.payload };
    ///
    ///ADD_NEW_BLANCO_TEAM_MEMBER
    ///

    ///
    ///ADD_PHONE_TO_NEW_MEMBER_FAIL
    ///

    ///
    ///CONFIRM_NEW_MEMBER_SMS_CODE
    ///

    case CONFIRM_NEW_MEMBER_SMS_CODE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CONFIRM_NEW_MEMBER_SMS_CODE_SUCCESS:
      return { ...state, loading: false, message: action.payload };

    ///
    ///NEW_MEMBER_RESEND_SMS
    ///
    case NEW_MEMBER_RESEND_SMS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case NEW_MEMBER_RESEND_SMS_SUCCESS:
      return { ...state, loading: false, message: action.payload };

    ///
    ///CONFIRM_NEW_TEAM_MEMBER_EMAIL
    ///
    case CONFIRM_NEW_TEAM_MEMBER_EMAIL_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CONFIRM_NEW_TEAM_MEMBER_EMAIL_SUCCESS:
      return { ...state, loading: false, message: action.payload };

    // case GET_TEAM_USER_INFO:
    //   return {
    //     ...state,
    //     userInfo: action.payload,
    //   };

    case OPEN_ADD_TEAM_MEMBER_DRAWER:
    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};

const addTeamMemberDrawerInitState = () => {
  return { show: false, parentId: null, childPosition: null };
};
export const addTeamMemberDrawer = (
  state = addTeamMemberDrawerInitState(),
  action
) => {
  switch (action.type) {
    case OPEN_ADD_TEAM_MEMBER_DRAWER:
      return {
        show: true,
        parentId: action.payload.parentId,
        childPosition: action.payload.childPosition
      };

    case CLOSE_ADD_TEAM_MEMBER_DRAWER:
      return addTeamMemberDrawerInitState();

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};

const directCustomersInitState = () => {
  return {
    customers: [],
    currentWeekCustomerCount: null,
    customerCount: null,
    loading: false
  };
};
export const directCustomers = (state = directCustomersInitState(), action) => {
  switch (action.type) {
    case GET_DIRECT_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_DIRECT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case GET_DIRECT_CUSTOMERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};

const indirectCustomersInitState = () => {
  return {
    customers: [],
    currentWeekCustomerCount: null,
    customerCount: null,
    loading: false
  };
};
export const indirectCustomers = (
  state = indirectCustomersInitState(),
  action
) => {
  switch (action.type) {
    case GET_INDIRECT_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_INDIRECT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case GET_INDIRECT_CUSTOMERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};

const volumeInfoInitState = () => {
  return {
    volumeInfo: {
      totalVolumeInfo: {},
      currentVolumeInfo: {},
      weekVolumeInfos: []
    }
  };
};
export const volumeInfo = (state = volumeInfoInitState(), action) => {
  switch (action.type) {
    case GET_VOLUME_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_VOLUME_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case GET_VOLUME_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};

const waitingRoomInitState = () => {
  return { teamWaitingUsers: [] };
};
export const waitingRoom = (state = waitingRoomInitState(), action) => {
  switch (action.type) {
    case GET_WAITING_ROOM_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_WAITING_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case GET_WAITING_ROOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};
