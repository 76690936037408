import styled from 'styled-components';

const View = styled.div`
  height: 100vh;
  padding: 80px 20px 30px 20px;
  width: 100%;
  overflow: scroll;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    align-items: start;

    padding: 0px 0px;
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
`;

const TextBig = styled.div`
  font-size: 34px;
  font-weight: 500;
  color: #fff;
  margin: 8px 0;
  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TextMedium = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: #fff;
  margin: 8px 0;
  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

const TextSmall = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #c6bdbd;
`;

export const TextSmallComparison = styled.div`
  font-size: 18px;
  line-height: 26px;
  color: #fefefe;
  text-align: center;
  @media (max-width: 800px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const SubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
`;

const RankContainer = styled.div`
  width: 680px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;

  background: ${(props) =>
    props.rank
      ? ` linear-gradient(132.33deg, #D24074 -0.67%, #1268C3 102.54%)`
      : '#2c2626'};

  border: ${(props) =>
    props.rank ? `` : '2px solid rgba(255, 255, 255, 0.2)'};

  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  border-radius: 12px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0 0 12px 0;
  cursor: pointer;
  img {
    margin-right: 20px;
    width: 80px;
  }

  @media (max-width: 800px) {
    width: auto;
    flex-direction: column;
    align-items: start;
    padding: 0 20px 16px 20px;
    img {
      margin-right: 8px;
    }
  }
`;

const Rank = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: start;
  }
`;

const WelcomeFooter = styled.div`
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  margin: 20px 0 40px 0;
  color: #b5a8a8;
  span {
    text-decoration: underline;
    cursor: pointer;
    color: #fff;
  }
`;

const TermsContainer = styled.div`
  color: #fff;
  height: 85vh;
  width: 934px;
  overflow: scroll;
  padding: 24px 40px;
  @media (max-width: 800px) {
    width: 100%;
    padding: 0;
  }
`;

const TermsAgree = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0;
`;

const PackagesContainer = styled.div`
  color: #fff;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Package = styled.div`
  /* max-width: 553px; */
  width: 100%;
  height: 86px;
  background: #2c2626;
  border-radius: ${(props) => (props.wide ? '30px' : '12px')};
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit; /* !importanté */
    background: ${(props) =>
      props.selectedPackage.packageId === props.item.packageId
        ? `linear-gradient(
        to right,
        rgba(245, 142, 53, 1),
        rgba(242, 108, 107, 1),
        rgba(238, 40, 138, 1)
      )`
        : `transparent`};
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 20px 12px 20px 20px;
  }
`;

const PackageTitle = styled.h3`
  font-size: 22px;
  margin-bottom: 8px;
  @media (max-width: 800px) {
    margin-bottom: 0;
    font-size: 15px;
  }
`;

const PackagePrice = styled.div`
  font-size: 14px;
  color: ${(props) =>
    props.selectedPackage.packageId === props.item.packageId
      ? `#fff`
      : `#b5a8a8`};
`;

const PackageSmallText = styled.div`
  font-size: 16px;
  color: '#b5a8a8';
`;

const Border = styled.div`
  width: 1px;
  margin: 0 80px;
  background-image: linear-gradient(#484b51, #949494, #484b51);
`;

const PackageContent = styled.div`
  width: 50%;
  padding: 10px 50px;
  @media (max-width: 800px) {
    // display: none;
    width: 100%;
  }
`;

const PackageBenefit = styled.div`
  margin-top: 24px;
  padding-bottom: 20px;
`;

const PackageSubtitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 14px;
`;

const ViewInstructions = styled.div`
  width: 230px;
  height: 50px;
  background: #2c2726;
  border-radius: 8px;
  font-weight: 700;
  padding: 8px 12px;
  margin-left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit; /* !importanté */
    background: linear-gradient(
      to bottom right,
      rgba(210, 64, 116, 1),
      rgba(18, 104, 195, 1)
    );
  }
  @media (max-width: 800px) {
    margin: 12px 0 12px 0;
    width: 100%;
  }
`;

const SectionTitle = styled.div`
  color: #fff;
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  margin: 0 0 30px 0;
  width: 100%;
`;

const SectionText = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #fff;
  width: 100%;
`;

const Separator = styled.div`
  width: 100%;
  padding: 0.5px 0;
  background: linear-gradient(to right, #484b51, #949494, #484b51);
  margin: 20px 0;
`;

const RankTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  color: #fffbfa;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

const RankSubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 4px 0;
  letter-spacing: 0.01em;
  color: #c6bdbd;
  @media (max-width: 800px) {
    display: none;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
`;

const RankSubTitleMobile = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 4px 0;
  letter-spacing: 0.01em;
  color: #c6bdbd;

  display: flex;
  justify-content: space-between;
  align-items: end;
  @media (min-width: 800px) {
    display: none;
    margin: 0;
  }
`;

const Bonus = styled.div`
  color: #fefefe;
  font-style: normal;
  font-weight: ${(props) => (props.color === '40' ? 'bold' : '500')};
  font-size: 20px;
  line-height: 24px;
  margin-top: 8px;
  background: ${(props) =>
    props.color === '40' &&
    `-webkit-linear-gradient(#f58e35, #f26c6b, #ee288a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;`};

  @media (max-width: 800px) {
    text-align: right;
    margin-top: 0px;
    font-size: 16px;
  }
`;

const Carrier = styled.span`
  font-size: 22px;
  ${(props) =>
    props.rankName
      ? 'color: #B5A8A8'
      : `
  background: -webkit-linear-gradient(#f58e35, #f26c6b, #ee288a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
 `}
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  background: ${(props) =>
    props.rankName ? '#B5A8A8' : 'linear-gradient(#f58e35, #f26c6b, #ee288a)'};
  border-radius: 50%;
  margin: 0 20px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const RankWrapper = styled.div``;

const Tabs = styled.div`
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #403c3b;
  // border: 1px solid #e1dbdb;
  border-radius: 8px;
  margin-bottom: 40px;
  overflow-x: auto;
`;

const TabButton = styled.div`
  width: 100%;
  justify-content: center;
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  border: ${(props) =>
    props.selected ? '2px solid rgba(255, 255, 255, 0.3)' : 'none'};
  box-sizing: border-box;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  background: ${(props) => (props.selected ? `#1C1716` : 'none')};

  @media (max-width: 360px) {
    font-size: 14px;
  }
`;

const Hr = styled.div`
  background: linear-gradient(160.43deg, #494c51 0%, #939393 50%, #494c51 100%);
  height: 1px;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '20px 0')};
`;

const WaitingRoomTh = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  div {
    width: 25%;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #b5a8a8;
  }
`;

const WaitingRoomTr = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin: 0 20px;

  height: 60px;
  @media (max-width: 800px) {
    height: 50px;
  }

  ${(props) =>
    props.isSelected ? `background: #1C1716;border-radius: 12px;` : ``}

  div {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #f2f0f0;

    span {
      font-weight: 500;
      color: #f48150;
    }
  }
`;

const WaitingRoomTable = styled.div`
  > *:nth-child(odd) {
    background: rgba(196, 196, 196, 0.2);
    border-radius: 8px;
  }
`;

const BonusWeb = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const BonusMobile = styled.div`
  display: flex;
  align-items: flex-end;
  @media (min-width: 800px) {
    display: none;
  }
`;

const Arrow = styled.div`
  display: flex;
  img {
    width: 12px;
    height: 10px;
    cursor: pointer;
    margin-left: 12px;
    transform: ${(props) =>
      props.collapse ? 'rotate(90deg)' : 'rotate(0deg)'};
  }
`;

const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.background};
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const MobileLayout = styled.div`
  display: none;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.background};
  @media (max-width: 1000px) {
    display: block;
  }
`;

const Main = styled.div`
  margin: 0 32px;
`;

const MainMobile = styled.div`
  margin: 0 16px;
`;

const Nav = styled.div`
  flex: none;
  background: ${(props) => props.theme.colors.neutral50};
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  width: 288px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 130px;
  @media (max-width: 1350px) {
    margin-right: 60px;
  }
  @media (max-width: 1200px) {
    margin-right: 20px;
  }
`;

const NavLink = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.neutral700};
  margin-bottom: 24px;
  position: relative;
  span {
    position: absolute;
    visibility: hidden;
    width: 4px;
    height: 4px;
    left: -12px;
    top: 10px;
    background: ${(props) => props.theme.colors.primary900};
    border-radius: 50%;
  }

  ${(props) =>
    props.state === 3 || props.state === 2
      ? `
      color: ${(props) => props.theme.colors.neutral900};
      font-weight: 500;
      span {
        visibility: visible;
      }
`
      : ''}
`;

const Links = styled.a`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.neutral500} !important;
  margin-bottom: 12px;
  display: block;
`;

const NavMobile = styled.div`
  background: ${(props) => props.theme.colors.background};
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 180px;
  padding: 16px;
  margin-bottom: 24px;
  color: ${(props) => props.theme.colors.neutral700};
  h1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
  }
  h2 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`;

const AmbassadorTitle = styled.h1`
  margin-bottom: 12px;
  font-size: 34px;
  @media (max-width: 800px) {
    font-size: 20px;
  }
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 100px - 100px);

  margin-left: 100px;
  @media (max-width: 1000px) {
    margin: 0;
    text-align: center;
  }
`;

export const CollapsableAbsolute = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  max-height: ${(props) => (props.isOpen ? `${props.height}` : '0')};
  overflow: hidden;
  transition: all 500ms;
  margin-bottom: ${(props) => (props.isOpen ? '20px' : '0')};
  position: absolute;
  right: 0;
  left: 0;
  top: 76px;
  z-index: 1;
`;

const SelectedUSDT = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #fefefe;
  border-radius: 8px;
  padding: 0 16px;
  background: #3e3a39;
`;

export {
  View,
  WelcomeContainer,
  TextBig,
  SubTitle,
  RankContainer,
  Rank,
  WelcomeFooter,
  TermsContainer,
  TermsAgree,
  PackagesContainer,
  Package,
  PackageTitle,
  PackagePrice,
  Border,
  PackageContent,
  PackageBenefit,
  PackageSubtitle,
  ViewInstructions,
  SectionTitle,
  SectionText,
  Separator,
  PackageSmallText,
  TextSmall,
  RankSubTitle,
  RankTitle,
  Bonus,
  Carrier,
  Dot,
  RankWrapper,
  Tabs,
  TabButton,
  Hr,
  WaitingRoomTh,
  WaitingRoomTr,
  WaitingRoomTable,
  BonusWeb,
  BonusMobile,
  RankSubTitleMobile,
  Arrow,
  Layout,
  Main,
  Nav,
  NavLink,
  Links,
  MobileLayout,
  NavMobile,
  MainMobile,
  AmbassadorTitle,
  PaymentWrapper,
  SelectedUSDT
};
