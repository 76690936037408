import { useSelector } from 'react-redux';
import wallet from '../../assets/icons/backOffice/Packages/wallet.svg';

import { SuccessToast } from '../../components/common/Toasts/Toasts';
import {
  formatUSD,
  USDT,
  showNetworkType
} from '../../helpers/helperFunctions';
import { useTranslation } from 'react-i18next';
import { H3, P3 } from '../../styles/FontStyles';
import { ORDERS } from '../../routes/routes';
import { color } from '../../styles/GlobalStyles';

import styled from 'styled-components';

// import { CreditCard } from '../../components/common/CreditCard';
import { Button, Flexed } from '../../styles/GlobalComponents';
import Spinner from '../../components/common/Spinner';

const SelectedUSDT = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;

  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.color
      ? props.theme.colors.neutral500
      : props.theme.colors.neutral900};
  border-radius: 8px;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.neutral100};
`;

const WaitingForPaymentPackage = ({ selectedPackage }) => {
  const { t } = useTranslation('packages');

  const { loading } = useSelector((state) => state.hubs);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
            {t('waiting.you_selected')}
          </P3>

          <H3 mb='5' color={color.colors.secondary100}>
            {selectedPackage?.package?.name}
          </H3>

          <P3 mb='8' mt='20' color={color.colors.neutral900}>
            {t('waiting.text1')}
          </P3>

          <P3 mb='8' mt='20' color={color.colors.neutral900}>
            {t('waiting.text2')}
          </P3>

          <P3 mb='8' mt='20' color={color.colors.neutral900}>
            {t('waiting.text3')}
          </P3>

          <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
            {t('waiting.payment_amount')}
          </P3>

          <SelectedUSDT>
            {formatUSD(selectedPackage?.price)} <span>{USDT}</span>
          </SelectedUSDT>

          <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
            Selected {t('waiting.network')}
          </P3>

          <SelectedUSDT>{showNetworkType(selectedPackage)}</SelectedUSDT>

          <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
            {t('waiting.wallet_address')}
          </P3>

          <SelectedUSDT
            onClick={() => {
              navigator.clipboard.writeText(selectedPackage?.paymentAddress);
              SuccessToast(t('waiting.copied'));
            }}
            style={{ cursor: 'pointer' }}>
            <div className='truncated'>
              <div>{selectedPackage?.paymentAddress}</div>
            </div>

            <img
              src={wallet}
              alt='withdraw'
              style={{ width: 16, marginLeft: 10 }}
            />
          </SelectedUSDT>
          <Flexed justifyCenter mt='40'>
            <Button
              onClick={() => {
                history.push(ORDERS);
              }}
              maxWidth='200px'>
              Close
            </Button>
          </Flexed>
          {/* {selectedPackage?.networkType === 1 ? (
            <CreditCard
              paymentAddress={selectedPackage?.paymentAddress}
              price={selectedPackage?.price}
              isPayment={true}
            />
          ) : (
            <Flexed justifyCenter mt='40'>
              <Button
                onClick={() => {
                  history.push(ORDERS);
                }}
                maxWidth='200px'>
                Close
              </Button>
            </Flexed>
          )} */}
        </div>
      )}
    </>
  );
};

export default WaitingForPaymentPackage;
