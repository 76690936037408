import styled from 'styled-components';

export const ViewBackOffice = styled.div`
  margin: 20px 0;
  min-height: 640px;
  padding-bottom: 70px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    max-width: 1500px;
    padding: 20px 100px;
    display: flex;
    justify-content: center;
  }
`;

export const WrapBackOffice = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

export const VR = styled.div`
  width: 2px !important;
  background: ${(props) => props.theme.settings.vrColor};
  margin: 0 50px;
  opacity: 0.5;

  @media (max-width: 800px) {
    margin: 0 30px;
  }
`;
