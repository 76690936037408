import React from 'react';
import { useDispatch } from 'react-redux';
import medal from '../../assets/icons/backOffice/sportsmedal.png';
import { formatUSD } from '../../helpers/helperFunctions';
import { H4, P2 } from '../../styles/FontStyles';
import { Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { FlexCenter } from '../../styles/stylesStyled';
import { claimPackageRank } from './Ranking.actions';

export const ClaimReward = ({
  step,
  setStep,
  setIsOpen,
  selectedRank,
  selected,
  setSelected
}) => {
  const dispatch = useDispatch();

  return (
    <FlexCenter style={{ flexDirection: 'column' }}>
      <img src={medal} style={{ width: 80 }} alt='' />
      <H4 mt='30'>{step === 1 ? 'Claim your reward' : 'Thank you'}</H4>
      <P2
        mt='20'
        color={color.colors.neutral500}
        style={{ textAlign: 'center' }}>
        {step === 1
          ? `Congratulations on your promotion! You’ve become “${selectedRank?.name?.toUpperCase()}” by rank. What are you waiting for, claim your reward now.`
          : `Your reward of ${selected?.reward?.name} has been successfully claimed and added to your balance.`}
      </P2>
      {step === 1 && (
        <>
          <P2 mt='32' mb='32' color={color.colors.neutral500}>
            Your reward is
          </P2>
          <div>
            {selectedRank?.rewards?.map((reward, index) => (
              <P2 bold key={index}>
                {reward.reward.proAmount
                  ? `${formatUSD(reward.reward.proAmount)} USDT`
                  : reward.reward.name}
              </P2>
            ))}
          </div>
          <Button
            w='200px'
            h='50px'
            mt='32'
            onClick={() => {
              dispatch(claimPackageRank(selectedRank, setSelected, setStep));
            }}>
            Claim
          </Button>
        </>
      )}

      {step === 2 && (
        <P2
          mt='32'
          color={color.colors.primary900}
          onClick={() => setIsOpen(false)}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}>
          Check your goals for the next rank
        </P2>
      )}
    </FlexCenter>
  );
};
