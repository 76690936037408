import React from 'react';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Spinner from '../../components/common/Spinner';
import { AuthButton, PhoneComp } from '../../styles/stylesStyled';
import { verifyNewEmailAction } from './changeEmail.actions';

const VerifyNewEmail = ({ history }) => {
  const dispatch = useDispatch();

  const code = queryString.parse(history.location.search).code;

  const { loading } = useSelector((state) => state.changeEmail);

  const verifyEmail = (e) => {
    e.preventDefault();
    dispatch(verifyNewEmailAction(code, history));
  };

  if (loading) return <Spinner fullScreen={true} />;

  return (
    <div>
      <PhoneComp>
        <form onSubmit={verifyEmail}>
          <AuthButton opacity={'true'} type='submit'>
            Verify new email
          </AuthButton>
        </form>
      </PhoneComp>
    </div>
  );
};

export default VerifyNewEmail;
