import React from 'react';
import { useWindowWide } from '../../utils/wideScreen';

import {
  Bonus,
  RankContainer,
  Rank,
  RankTitle,
  RankSubTitle,
  Carrier,
  Dot,
  BonusWeb,
  BonusMobile,
  RankSubTitleMobile
} from './WelcomeStyled';

export const RankComponent = ({
  icon,
  title,
  rankName,
  onClick,
  subtitle,
  percent,
  rank
}) => {
  const wide = useWindowWide(800);

  return (
    <RankContainer onClick={onClick} rank={rank}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={icon} alt='' />
        <div>
          <Rank>
            <RankTitle>{title}</RankTitle>
            <Dot rankName={rankName === 'Basic'} />
            <Carrier
              rankName={rankName === 'Basic'}
              style={{
                fontWeight: wide ? 'bold' : '500',
                fontSize: !wide && 16
              }}>
              {rankName}
            </Carrier>
          </Rank>
          <RankSubTitle>{subtitle}</RankSubTitle>
        </div>
      </div>

      <BonusWeb>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '15px',
            color: '#D6D6D6',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}>
          <span className={`${percent === '40' && 'textGradient'}`}>up to</span>
          <Bonus color={percent}>{`${percent}% bonus`}</Bonus>
        </div>
      </BonusWeb>
      <BonusMobile>
        <RankSubTitleMobile>{subtitle}</RankSubTitleMobile>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '15px',
            color: '#D6D6D6',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            flex: 'none'
          }}>
          up to
          <Bonus color={percent}>{`${percent}% bonus`}</Bonus>
        </div>
      </BonusMobile>
    </RankContainer>
  );
};
