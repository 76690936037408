import styled from 'styled-components';

export const StakingHubDetailsStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryBlue};
  padding: 24px;
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  border: 1px solid ${({ theme }) => theme.colors.neutral100};
`;

export const SelectedUSDT = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;

  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.color
      ? props.theme.colors.neutral500
      : props.theme.colors.neutral900};
  border-radius: 8px;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.neutral100};
`;
