import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AnimatedTab } from '../../../components/animation/AnimatedTab';
import Spinner from '../../../components/common/Spinner';
import { VirtualOfficeSelectedOrder } from '../../../components/common/VirtualOfficeSubscription/VirtaulOfficeSelectedOrder';
import { VirtualOfficeSelectedPlan } from '../../../components/common/VirtualOfficeSubscription/VirtualOfficeSelectedPlan';

import { wallets } from '../../../helpers/helperFunctions';
import { PaymentSuccess } from '../../SettingsBackoffice/PaymentSuccess';
import { Step1 } from './step1';
import { Step2 } from './step2';
import { Step3 } from './step3';

import { Step5 } from './step5';
import { Step6 } from './step6';

export const FirstBuyStakingHubFlowSteps = ({
  selectedHub,
  setSelectedHub,
  setModal,
  setStep,
  step,
  licenceDate
}) => {
  const { t } = useTranslation('marketplace');
  const [selected, setSelected] = useState({
    id: 1,
    title: t('virtual_office.plans.basic.name'),
    price: 49,
    access: 1,
    benefits: [
      {
        text: t('virtual_office.plans.basic.text1')
      },
      {
        text: t('virtual_office.plans.basic.text2')
      }
    ]
  });

  const { addHubLoading } = useSelector((state) => state.hubs);

  const { loading } = useSelector((state) => state.settings);

  const [selectedNetwork, setSelectedNetwork] = useState(wallets[0]);

  if (addHubLoading || loading) return <Spinner solid={false} />;

  return (
    <>
      {step === 1 && (
        <AnimatedTab key={1}>
          <Step1
            setStep={setStep}
            selectedHub={selectedHub}
            setSelectedHub={setSelectedHub}
          />
        </AnimatedTab>
      )}
      {!addHubLoading && step === 2 && (
        <AnimatedTab key={2}>
          <Step2
            step={3}
            setStep={setStep}
            selectedHub={selectedHub}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
          />
        </AnimatedTab>
      )}
      {!addHubLoading && step === 3 && (
        <AnimatedTab key={3}>
          <Step3
            step={step}
            setStep={setStep}
            selectedHub={selectedHub}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
            licenceDate={licenceDate}
          />
        </AnimatedTab>
      )}

      {!addHubLoading && step === 5 && (
        <AnimatedTab key={5}>
          <Step5
            setStep={setStep}
            selectedHub={selectedHub}
            setModal={setModal}
            selected={selected}
            setSelected={setSelected}
          />
        </AnimatedTab>
      )}
      {!addHubLoading && step === 6 && (
        <AnimatedTab key={6}>
          <Step6
            setStep={setStep}
            selectedHub={selectedHub}
            setSelectedHub={setSelectedHub}
          />
        </AnimatedTab>
      )}
      {console.log(selected)}
      {step === 7 && (
        <VirtualOfficeSelectedPlan
          step={8}
          setStep={setStep}
          selected={selected}
          selectedHub={selectedHub}
        />
      )}

      {!loading && step === 8 && (
        <VirtualOfficeSelectedOrder
          step={9}
          setStep={setStep}
          selected={selected}
          selectedHub={selectedHub}
        />
      )}

      {step === 9 && (
        <PaymentSuccess selectedHub={selectedHub} selected={selected} />
      )}
    </>
  );
};
