import styled from 'styled-components';

export const VirtualOfficeSubscriptionStyled = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.colors.secondaryBlue};
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    padding: 16px;
  }
`;
