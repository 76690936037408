import cosmicWhale from '../../assets/images/backoffice/hubImages/cosmicWhale.jpg';
import moonWalker from '../../assets/images/backoffice/hubImages/moonWalker.png';
import sunSeeker from '../../assets/images/backoffice/hubImages/sunSeeker.jpg';

import galaxyExplorer from '../../assets/images/backoffice/hubImages/galaxyExplorer.jpg';
import spaceSurfer from '../../assets/images/backoffice/hubImages/spaceSurfer.jpg';
import starCatcher from '../../assets/images/backoffice/hubImages/starCatcher.jpg';
import solarRanger from '../../assets/images/backoffice/hubImages/solarRanger.jpg';

import magicUnicorn from '../../assets/images/backoffice/gamingImages/magicUnicorn.jpg';
import mysticLion from '../../assets/images/backoffice/gamingImages/mysticLions.jpg';
import mightyDragon from '../../assets/images/backoffice/gamingImages/mightyDragon.jpg';
import risingPhoenix from '../../assets/images/backoffice/gamingImages/risingPhoenix.jpg';
import ragingBull from '../../assets/images/backoffice/gamingImages/ragingBull.jpg';
import wealthyTiger from '../../assets/images/backoffice/gamingImages/wealthyTiger.jpg';
import luckyApe from '../../assets/images/backoffice/gamingImages/luckyApe.jpg';
import wiseElephant from '../../assets/images/backoffice/gamingImages/wiseElephant.jpg';
import fortunePanda from '../../assets/images/backoffice/gamingImages/fortunePanda.jpg';

const directBonus = 7;

export const hubsData = [
  {
    id: 43,
    image: magicUnicorn,
    title: 'Magic Unicorn',
    weeklyCap: '$50.000',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    calculator: '10X',
    limitedQuantity: 'LIMITED QUANTITY',
    benefits: [
      {
        icon: 1,
        text: 'Maximum Payout 1.000.000 USDT'
      },

      {
        icon: 1,
        text: 'Counts in Ranking'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 42,
    image: mysticLion,
    title: 'Mighty Dragon',
    weeklyCap: '$40.000',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    calculator: '9X',
    limitedQuantity: 'LIMITED QUANTITY',
    benefits: [
      {
        icon: 1,
        text: 'Maximum Payout 450.000 USDT'
      },

      {
        icon: 1,
        text: 'Counts in Ranking'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 41,
    image: mightyDragon,
    title: 'Mighty Dragon',
    weeklyCap: '$30.000',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    calculator: '8X',
    benefits: [
      {
        icon: 1,
        text: 'Maximum Payout 240.000 USDT'
      },

      {
        icon: 1,
        text: 'Counts in Ranking'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 40,
    image: risingPhoenix,
    title: 'Rising Phoenix',
    weeklyCap: '$20.000',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    calculator: '7X',
    benefits: [
      {
        icon: 1,
        text: 'Maximum Payout 87.500 USDT'
      },

      {
        icon: 1,
        text: 'Counts in Ranking'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 39,
    image: wealthyTiger,
    title: 'Wealthy Tiger',
    weeklyCap: '$10.000',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    calculator: '6X',
    benefits: [
      {
        icon: 1,
        text: 'Maximum Payout 30.000 USDT'
      },

      {
        icon: 1,
        text: 'Counts in Ranking'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 38,
    image: ragingBull,
    title: 'Raging Bull',
    // weeklyCap: '$5.000',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    calculator: '5X',
    benefits: [
      {
        icon: 1,
        text: 'Maximum Payout 7.500 USDT'
      },

      {
        icon: 1,
        text: 'Counts in Ranking'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 37,
    image: fortunePanda,
    title: 'Fortune Panda',
    // weeklyCap: '$1.000',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    calculator: '4X',
    benefits: [
      {
        icon: 1,
        text: 'Maximum Payout 2.000 USDT'
      },

      {
        icon: 1,
        text: 'Counts in Ranking'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 36,
    image: wiseElephant,
    title: 'Wise Elephant',
    // weeklyCap: '$500',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    calculator: '3X',
    benefits: [
      {
        icon: 1,
        text: 'Maximum Payout 900 USDT'
      },

      {
        icon: 1,
        text: 'Counts in Ranking'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 35,
    image: luckyApe,
    title: 'Lucky Ape',
    // weeklyCap: '$100',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    calculator: '2X',
    benefits: [
      {
        icon: 1,
        text: 'Maximum Payout 200 USDT'
      },

      {
        icon: 1,
        text: 'Counts in Ranking'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 34,
    image: cosmicWhale,
    title: 'Cosmic Whale',
    weeklyCap: '$30.000',
    orderPlan: 'TOP G.O.A.T CHOICE',
    ranking: 'GREEN DIAMOND',
    benefits: [
      {
        icon: 1,
        text: 'Stake ratio: 1.0'
      },

      {
        icon: 1,
        text: 'Auto Stake ON: 0.2% Daily Rewards'
      },

      {
        icon: 1,
        text: 'Auto Stake OFF: up to 0.1% Daily Rewards'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 90
  },
  {
    id: 33,
    image: moonWalker,
    title: 'Moon Walker',
    price: 12500,
    weeklyCap: '$20.000',
    orderPlan: '',
    ranking: 'BLUE DIAMOND',
    benefits: [
      {
        icon: 1,
        text: 'Stake ratio: 1.0'
      },

      {
        icon: 1,
        text: 'Auto Stake ON: 0.2% Daily Rewards'
      },

      {
        icon: 1,
        text: 'Auto Stake OFF: up to 0.1% Daily Rewards'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 60
  },
  {
    id: 32,
    image: sunSeeker,
    title: 'Sun Seeker',
    price: 5000,
    weeklyCap: '$10.000',
    orderPlan: 'MOST POPULAR',
    ranking: 'DIAMOND',
    benefits: [
      {
        icon: 1,
        text: 'Stake ratio: 1.0'
      },

      {
        icon: 1,
        text: 'Auto Stake ON: 0.2% Daily Rewards'
      },

      {
        icon: 1,
        text: 'Auto Stake OFF: up to 0.1% Daily Rewards'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '60 M',
    proRankBoost: 30
  },
  {
    id: 31,
    image: galaxyExplorer,
    title: 'Galaxy Explorer',
    price: 1500,
    // weeklyCap: '$5.000',
    orderPlan: '',
    ranking: 'RUBY',
    benefits: [
      {
        icon: 1,
        text: 'Stake ratio: 1.0'
      },

      {
        icon: 1,
        text: 'Auto Stake ON: 0.2% Daily Rewards'
      },

      {
        icon: 1,
        text: 'Auto Stake OFF: up to 0.1% Daily Rewards'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '15 MONTHS'
  },
  {
    id: 30,
    image: spaceSurfer,
    title: 'Space Surfer',
    price: 500,
    // weeklyCap: '$1.000',
    orderPlan: '',
    ranking: '',
    benefits: [
      {
        icon: 1,
        text: 'Stake ratio: 1.0'
      },

      {
        icon: 1,
        text: 'Auto Stake ON: 0.2% Daily Rewards'
      },

      {
        icon: 1,
        text: 'Auto Stake OFF: up to 0.1% Daily Rewards'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '5 MONTHS'
  },
  {
    id: 29,
    image: starCatcher,
    title: 'Star Catcher',
    price: 300,
    // weeklyCap: '$500',
    orderPlan: '',
    ranking: '',
    benefits: [
      {
        icon: 1,
        text: 'Stake ratio: 1.0'
      },

      {
        icon: 1,
        text: 'Auto Stake ON: 0.2% Daily Rewards'
      },

      {
        icon: 1,
        text: 'Auto Stake OFF: up to 0.1% Daily Rewards'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '3 MONTHS'
  },
  {
    id: 28,
    image: solarRanger,
    title: 'Solar Ranger',
    price: 100,
    // weeklyCap: '$100',
    orderPlan: 'NEWCOMER',
    ranking: '',
    benefits: [
      {
        icon: 1,
        text: 'Stake ratio: 1.0'
      },

      {
        icon: 1,
        text: 'Auto Stake ON: 0.2% Daily Rewards'
      },

      {
        icon: 1,
        text: 'Auto Stake OFF: up to 0.1% Daily Rewards'
      }
    ],
    bonuses: [
      {
        icon: 1,
        text: `${directBonus}% Direct bonus`
      },
      {
        icon: 1,
        text: '10% Binary bonus'
      }
    ],
    firstGift: '1 MONTH'
  }
];
