import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SidebarNavContent } from '../../layout/common';

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(28, 23, 22, 0.1);
  backdrop-filter: blur(5px);
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? '1' : '0')};
  z-index: 100003;
  transition: all 0.5s ease;
  height: 100vh;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  padding-top: 20px;
  position: fixed;
  left: ${({ open }) => (open ? '0' : '-350px')};
  top: 0;
  bottom: 0;
  overflow-y: auto;
  background: ${(props) => props.theme.appLayout.sideNavBackground};
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  transition: all 0.3s ease;
  z-index: 100004;
  height: 100vh;
`;

const SidebarNav = ({ open, onClose }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [open]);

  return (
    <>
      <Overlay open={open} onClick={() => onClose(false)}></Overlay>
      <SidebarContainer open={open}>
        <SidebarNavContent onClose={onClose} />
      </SidebarContainer>
    </>
  );
};

export default SidebarNav;
