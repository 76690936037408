import axios from 'axios';

import { createAxiosConfigWithAuthHeader } from '../../utils/createAxiosConfigWithAuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

export const changeEmailAsync = async (email, code) => {
  const url = `${API_URL}/user/email/change`;

  return await axios.put(
    url,
    { email, code },
    createAxiosConfigWithAuthHeader()
  );
};

export const verifyNewEmailAsync = async (code) => {
  const url = `${API_URL}/user/email/confirm`;

  return await axios.put(url, { code }, createAxiosConfigWithAuthHeader());
};

export const resendNewEmailVerificationAsync = async () => {
  const url = `${API_URL}/user/email/resend`;

  return await axios.put(url, {}, createAxiosConfigWithAuthHeader());
};
