import styled from 'styled-components';
import { color } from '../../styles/GlobalStyles';

export const ViewBackOffice = styled.div`
  margin: 20px 0;
  min-height: 640px;
  padding-bottom: 70px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    max-width: 1500px;
    padding: 20px 100px;
    display: flex;
    justify-content: center;
  }
`;

export const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const NavigationWrapper = styled.div`
  flex: none;
  @media (max-width: 800px) {
    width: 100%;
    flex: auto;
  }
`;

export const NavItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin-bottom: 4px;
  border-radius: 12px;
  img {
    margin-right: 20px;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.settings.navLinkTitleColor};
  margin-bottom: 4px;
`;

export const SubTitle = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.settings.navLinkSubTitleColor};
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  background: ${(props) => props.theme.colors.neutral50};
  box-shadow: ${(props) => props.theme.shadows.shadowXS};

  border-radius: 12px;
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    padding: 0;
    background: none;
    box-shadow: none;
  }
`;

export const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.settings.labelColor};
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.settings.fontColor};
  margin-bottom: 20px;
`;

export const Username = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: ${(props) => props.theme.settings.fontColor};
  margin-bottom: 8px;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const BankAccount = styled.div`
  overflow-y: auto;
  cursor: pointer;
  width: 60%;
  background: ${(props) => props.theme.colors.secondaryBlue};
  ${(props) =>
    props.selected && 'box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);'}
  padding: 1px;
  border-radius: 8px;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 8px;
`;

export const BankAccountGradientText = styled.div`
  word-break: break-all;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.settings.fontColor};
  /* ${(props) =>
    props.selected
      ? 'background: -webkit-linear-gradient(132.33deg, #D24074 -0.67%, #1268C3 102.54%);color: #ff0084 !important;-webkit-background-clip: text;background-clip: text;-webkit-text-fill-color: transparent;'
      : ''}; */
`;

export const OutlineBox = styled.div`
  padding: 16px 24px;
  border: 1px solid #403c3b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  color: #d8d1d1;
  border-radius: 8px;
  span {
    font-weight: bold;
  }
`;

export const NotificationTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.settings.fontColor};
  margin-bottom: 4px;
`;

export const NotificationSubtitle = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.settings.labelColor};
`;

export const PlacementTitle = styled(NotificationTitle)`
  margin-bottom: 20px;
`;

export const CheckboxButton = styled.div`
  cursor: pointer;
  padding: 1px;
  width: 100%;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.checked
      ? props.theme.colors.neutral50
      : props.theme.colors.neutral900};
  background: none;
  border-radius: 8px;
  > div {
    height: 47px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: ${(props) =>
      props.checked
        ? props.theme.colors.primary900
        : props.theme.colors.neutral100};
    border-radius: 8px;
  }
  img {
    margin-right: 16px;
    width: 24px;
  }
`;

export const CamCapturedCircle = styled.div`
  border-radius: 50%;
  overflow: hidden;
  border: ${color.kycCamCapturedBorder};
  height: 311px;
  width: 311px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 800px) {
    width: 220px;
    height: 220px;
  }
`;

export const CamCircle = styled.div`
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  border: ${color.kycCamBorder};
  width: 311px;
  height: 311px;
  z-index: 100;
  @media (max-width: 800px) {
    width: 220px;
    height: 220px;
  }
`;

export const CameraUnderlineText = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  text-align: center;
  color: ${color.colors.neutral900};
`;

export const CollapsableAbsolute = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  max-height: ${(props) => (props.isOpen ? `${props.height}` : '0')};
  overflow: hidden;
  transition: all 500ms;
  margin-bottom: ${(props) => (props.isOpen ? '20px' : '0')};
  position: absolute;
  right: 0;
  left: 0;
  top: 76px;
  z-index: 1;
`;

export const SelectDropDown = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fefefe;
  border-radius: 8px;
  padding: 0 18px;
  background: #3e3a39;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

export const ReloadCheckEmpty = styled.span`
  width: 24px;
  height: 24px;
  border: 2px solid #fdfdfd;
  border-radius: 50%;
  flex: none;
`;

export const Select = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 1px;
  background: ${(props) =>
    props.isOpen
      ? 'linear-gradient(160.43deg,#f58e35 1.87%,#f26c6b 50.93%,#ee288a 99.33%);'
      : '#2C2626'};
  border-radius: 8px;
  position: relative;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 66px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #fefefe;
    border-radius: 8px;
    padding: 0 16px;
    background: #3e3a39;
    > img {
      margin-left: 16px;
      transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : '')};
      transition: all 500ms;
    }
  }
`;

export const WithSocial = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fffbfa;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.theme.settings.fontColor};
  margin-right: 13px;
  display: inline-block;
`;

export const QrCodeWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`;

export const ListImage = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 40px;
`;

export const ReferralComp = styled.div`
  @media (max-width: 800px) {
    margin-top: 20vh;
  }
`;
