/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { continueToReferral, signIn } from '../../actions/authActions';
import { InputBackOffice } from '../../components/common/InputBackoffice';
import Spinner from '../../components/common/Spinner';
import { ErrorToast } from '../../components/common/Toasts/Toasts';
import { validateEmail } from '../../helpers/helperFunctions';
import { removeUserPrizeFromLS } from '../../helpers/localstorage-helper';
import { AuthLayoutTitle } from '../../styles/GlobalComponents';
import { AuthButton } from '../../styles/stylesStyled';
import { DontHaveAcc } from './LoginStyled';

const API_URL = process.env.REACT_APP_API_URL;
const captchaId = process.env.REACT_APP_CAPTCHA_ID;

const Login = ({ history, match }) => {
  const { t } = useTranslation('onboarding');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [setReferrerId] = useState(match.params.id ?? null);
  const [captchaLoading, setCaptchaLoading] = useState(true);

  const dispatch = useDispatch();
  let { referralCode } = useParams();
  referralCode = referralCode ?? null;

  const user = useSelector((state) => state.userInfo);

  const appleAppId = process.env.REACT_APP_APPLE_ID;
  const appleRedirectUri = process.env.REACT_APP_APPLE_REDIRECT_URI;

  const onHandleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      ErrorToast(t('login.error_enter_id_or_email'));
    } else if (!validateEmail(email)) {
      ErrorToast(t('login.error_email_invalid'));
    } else if (!password) {
      ErrorToast(t('login.error_enter_password'));
    } else {
      window.initGeetest4(
        {
          captchaId,
          language: 'eng',
          product: 'bind',
          riskType: 'slide',
          nativeButton: {
            width: '100%',
            marginBottom: '20px'
          }
        },
        function (captcha) {
          setCaptchaLoading(true);
          captcha.showBox();
          captcha
            .onReady(function () {})
            .onSuccess(function () {
              const result = captcha.getValidate();
              dispatch(signIn(email, password, result, history));
            })
            .onError(function () {});
        }
      );
    }
  };

  const initApple = () => {
    window.AppleID.auth.init({
      clientId: appleAppId,
      scope: 'name email',
      redirectURI: appleRedirectUri,
      usePopup: true
    });
  };

  const location = useLocation();

  useEffect(() => {
    initApple();
    setReferrerIdByCodeIfAfiliateRegistration();

    removeUserPrizeFromLS();
  }, [user, dispatch, location]);

  const setReferrerIdByCodeIfAfiliateRegistration = async () => {
    if (referralCode !== null) {
      const { data } = await axios.get(
        `${API_URL}/user/referral-code/${referralCode}`
      );

      setReferrerId(data.data.user.id);
    }
  };

  const forwardToReferral = () => {
    dispatch(continueToReferral());
    history.push('/referral');
  };

  return (
    <div>
      {user.loadingSignIn ||
        (user.loading && <Spinner fullScreen={true} transparent />)}
      <AuthLayoutTitle>{t('login.sign_in_title')}</AuthLayoutTitle>
      <form onSubmit={onHandleSubmit}>
        <InputBackOffice
          id={'id'}
          type={'text'}
          placeholder={t('login.email_placeholder')}
          name={'email'}
          value={email.replace(/\s+/g, '')}
          onChange={(e) => setEmail(e.replace(/\s+/g, ''))}
          label={t('login.email_label')}
          style={{ textTransform: 'lowercase' }}
        />
        <InputBackOffice
          id={'password'}
          type={'password'}
          placeholder={t('login.password_placeholder')}
          name={'password'}
          value={password}
          onChange={setPassword}
          label={t('login.password_label')}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: '20px'
          }}>
          <DontHaveAcc>
            <Link to='/forgotten-password'>
              <span> {t('login.forgot_you_password')}</span>
            </Link>
          </DontHaveAcc>
        </div>

        <AuthButton
          type='submit'
          id='captcha'
          opacity={email && password && 'true'}>
          {t('login.sign_in')}
        </AuthButton>
      </form>

      <DontHaveAcc onClick={forwardToReferral} style={{ marginBottom: 40 }}>
        <span> {t('login.create_account')}</span>
      </DontHaveAcc>
    </div>
  );
};

export default Login;
