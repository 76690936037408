import React from 'react';
import { Link } from 'react-router-dom';
import {
  AuthButton,
  ButtonWrapper,
  FloatingButton,
  PhoneComp,
  Text
} from '../../styles/stylesStyled';

import { useTranslation } from 'react-i18next';
import keys from '../../assets/images/backoffice/Airplane.png';
import { AuthLayoutTitle } from '../../styles/GlobalComponents';

const PasswordChanged = () => {
  const { t } = useTranslation('onboarding.forgoten_password_changed');

  return (
    <>
      <PhoneComp>
        <div style={{ textAlign: 'center' }}>
          <img src={keys} alt='' style={{ width: 160, height: 160 }} />
        </div>

        <AuthLayoutTitle style={{ marginBottom: 0 }}>
          {t('been_changed')}
        </AuthLayoutTitle>

        <Text>{t('sign_in_with_new_password')}</Text>

        <Link to='/login'>
          <FloatingButton>
            <ButtonWrapper>
              <AuthButton opacity={'true'}>{t('continue')}</AuthButton>
            </ButtonWrapper>
          </FloatingButton>
        </Link>
      </PhoneComp>
    </>
  );
};

export default PasswordChanged;
