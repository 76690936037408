import React, { useRef, useState } from 'react';
import { Desktop, Mobile } from '../../../App';
import { formatUSDNoDecimals } from '../../../helpers/helperFunctions';
import { H3, H4, P2, P3 } from '../../../styles/FontStyles';
import { ArrowChevron, Flexed } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { Collapsable, HubArrow, HubImage, HubStyled } from './Hub.styled';
import { useTranslation } from 'react-i18next';

export const Hub = ({
  image,
  title,
  price,
  button,
  weeklyCap,
  info,
  limitedQuantity,
  stakingHubDetails,
  condensed,
  onClick,
  selected
}) => {
  const { t } = useTranslation('marketplace');

  const [expanded, setExpanded] = useState(false);

  const ref = useRef(null);
  const elementHeight = ref?.current?.clientHeight;

  const wide = window.innerWidth > 800;

  return (
    <HubStyled
      expanded={expanded}
      condensed={condensed}
      selected={selected}
      onClick={wide ? onClick : null}>
      <div>
        <HubImage
          condensed={condensed}
          onClick={() => setExpanded(!expanded)}
          image={image}
        />
        <div>
          <Flexed column between>
            <div>
              <Desktop>
                <H3 color={selected ? color.colors.primary900 : ''} mb='4'>
                  {title}
                </H3>
              </Desktop>
              <Mobile>
                <H4
                  color={expanded ? color.colors.primary900 : ''}
                  onClick={() => setExpanded(!expanded)}
                  mb='4'
                  mr='20'>
                  {title}
                </H4>
              </Mobile>

              <Desktop>
                <P2 mb='12' color={color.colors.neutral500}>
                  {formatUSDNoDecimals(price)} USDT
                </P2>
              </Desktop>

              <Mobile>
                <P3
                  onClick={() => setExpanded(!expanded)}
                  mb='12'
                  color={color.colors.neutral500}>
                  {formatUSDNoDecimals(price)} USDT
                </P3>
              </Mobile>
            </div>
            {button}
            <HubArrow onClick={() => setExpanded(!expanded)}>
              <ArrowChevron direction={expanded ? 'up' : 'down'} />
            </HubArrow>
          </Flexed>
          <Flexed column between>
            <Flexed column>
              {weeklyCap && (
                <>
                  <P3 bold right>
                    {t('binary_weekly_cap')}
                  </P3>
                  <P3 bold right color={color.colors.secondary100}>
                    {weeklyCap}
                  </P3>
                </>
              )}

              <P3 bold right color={color.colors.secondary100}>
                {limitedQuantity}
              </P3>
            </Flexed>
            {info}
          </Flexed>
        </div>
      </div>
      <Collapsable expanded={expanded} elementHeight={elementHeight}>
        <div
          onClick={() => setExpanded(!expanded)}
          ref={ref}
          style={{ padding: 16 }}>
          {/* <P2 bold mb='12'>
            <span>{weeklyCap}</span> BINARY WEEKLY CAP
          </P2> */}
          {stakingHubDetails}
        </div>
      </Collapsable>
    </HubStyled>
  );
};
