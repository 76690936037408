import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/common/Spinner';

import { getPortfolio } from './Portfolio.actions';

import { AnimatedDashboard } from '../../components/animation/AnimatedDashboard';
import { GamingHubSummary } from './GamingHubSummary';
import { Overlay } from './Overlay';
import { StakingHubSummary } from './StakingHubSummary';
import { WalletSummary } from './WalletSummary';
import { MembershipStatus } from './MembershipStatus';

export const Portfolio = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.portfolio);

  useEffect(() => {
    dispatch(getPortfolio());
  }, [dispatch]);

  if (isLoading) return <Spinner fullScreen={true} />;

  return (
    <AnimatedDashboard zoom>
      <WalletSummary />
      <MembershipStatus />
      <GamingHubSummary />
      <StakingHubSummary />
      <Overlay />
    </AnimatedDashboard>
  );
};
