import React from 'react';
import Countdown from 'react-countdown';
import { P1 } from '../../styles/FontStyles';
import { color } from '../../styles/GlobalStyles';
import { CountdownNumbers } from './RankingStyled';

export const RewardCounter = ({ timestamp }) => {
  return (
    <Countdown
      date={timestamp}
      renderer={({ days, hours, minutes, seconds }) => (
        <CountdownNumbers>
          <P1 bold mr='3' color={color.colors.secondary100}>
            {days} d {hours} h {minutes} m {seconds} s
          </P1>
        </CountdownNumbers>
      )}
    />
  );
};
