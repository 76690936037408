import axios from 'axios';
import React, { useState } from 'react';
import { InputBackOffice } from '../../../components/common/InputBackoffice';
import Spinner from '../../../components/common/Spinner';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';

import { Flexed, SecurityCodeWrapper } from '../../../styles/GlobalComponents';

import {
  SuccessToast,
  ErrorToast
} from '../../../components/common/Toasts/Toasts';

import {
  Flex,
  SidebarTitle,
  SidebarSubtitle,
  AuthButton
} from '../../../styles/stylesStyled';
import { P2, P3 } from '../../../styles/FontStyles';
import { Icon, IconWarning } from '../../../styles/Icons';
import { color } from '../../../styles/GlobalStyles';

import { updateUserEmail } from '../../../actions/authActions';

const API_URL = process.env.REACT_APP_API_URL;
const captchaId = process.env.REACT_APP_CAPTCHA_ID;

export const ChangeEmail = ({ setStep }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isMailSent, setIsMailSent] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [newEmailCode, setNewEmailCode] = useState('');
  const [oldEmailCode, oldNewEmailCode] = useState('');
  const [code2Fa, setCode2Fa] = useState('');
  const [newCodeLoading, setNewCodeLoading] = useState(false);
  const [oldCodeLoading, setOldCodeLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { user, token } = useSelector((state) => state.userInfo);

  const { verificationStatus } = useSelector((state) => state.settings);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.accessToken}`
    }
  };

  const sendNewCode = async () => {
    if (!email) {
      ErrorToast('Please enter new email');
    } else {
      try {
        window.initGeetest4(
          {
            captchaId,
            language: 'eng',
            product: 'bind',
            riskType: 'slide',
            nativeButton: {
              width: '100%',
              marginBottom: '20px'
            }
          },
          function (captcha) {
            captcha.showBox();
            captcha
              .onReady(function () {})
              .onSuccess(async function () {
                setNewCodeLoading(true);
                const {
                  lot_number: lotNumber,
                  captcha_output: captchaOutput,
                  pass_token: passToken,
                  gen_time: genTime
                } = captcha.getValidate();

                await axios.post(
                  `${API_URL}/user/email/change/code/new`,
                  {
                    email,
                    lotNumber,
                    captchaOutput,
                    passToken,
                    genTime
                  },
                  config
                );

                SuccessToast('Code sent successfully');
                setNewCodeLoading(false);
                setIsMailSent(true);
              })
              .onError(function () {});
          }
        );
      } catch (error) {
        setNewCodeLoading(false);
        ErrorToast(error.response.data.error);
      }
    }
  };
  const sendOldCode = async () => {
    try {
      setOldCodeLoading(true);
      await axios.post(`${API_URL}/user/email/change/code/old`, config);

      SuccessToast('Code sent successfully');
      setOldCodeLoading(false);
      setIsCodeSent(true);
    } catch (error) {
      setOldCodeLoading(false);
      ErrorToast(error.response.data.error);
    }
  };

  const submit2faCodes = async () => {
    if (!email) {
      ErrorToast('Please enter new email');
    } else if (!newEmailCode) {
      ErrorToast('Please enter new email verification code');
    } else if (!oldEmailCode) {
      ErrorToast('Please enter email verification code');
    } else {
      try {
        setConfirmLoading(true);

        await axios.put(
          `${API_URL}/user/email/change/confirm`,
          {
            emailCodeForNewEmail: newEmailCode,
            emailCodeForOldEmail: oldEmailCode,
            twoFACode: verificationStatus ? code2Fa : null
          },
          config
        );

        dispatch(updateUserEmail(email));

        setConfirmLoading(false);

        setStep(3);
      } catch (error) {
        setConfirmLoading(false);
        ErrorToast(error.response.data.error);
      }
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    submit2faCodes();
  };

  return (
    <>
      {newCodeLoading || oldCodeLoading || confirmLoading ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          <Flex>
            <Icon>
              <IconWarning color='#FCBF24' />
            </Icon>
            <SidebarSubtitle>
              {' '}
              Withdrawals will be disabled for 24 hours after you make this
              change to protect your account.
            </SidebarSubtitle>
          </Flex>
          <SidebarTitle>New email verification</SidebarTitle>
          <form onSubmit={(e) => onHandleSubmit(e)}>
            <InputBackOffice
              id={'email'}
              type={'text'}
              placeholder={'Enter new email'}
              name={'email'}
              value={email.replace(/\s+/g, '')}
              onChange={(e) => setEmail(e.replace(/\s+/g, ''))}
            />
            <Flexed between>
              <P2 bold color={color.colors.neutral700}>
                New email verification code
              </P2>
              <P2
                bold
                style={{ cursor: 'pointer' }}
                color={color.colors.primary900}
                onClick={() => sendNewCode()}>
                Send Code
              </P2>
            </Flexed>
            <SecurityCodeWrapper>
              <ReactCodeInput
                fields={7}
                value={newEmailCode}
                onChange={setNewEmailCode}
                type={'number'}
              />
            </SecurityCodeWrapper>

            <P3 color={color.colors.neutral500} mb='30'>
              {isMailSent && `Enter the 7 digit code sent to ${email}`}
            </P3>
            <SidebarTitle>Security verification</SidebarTitle>
            <Flexed between>
              <P2 bold color={color.colors.neutral700}>
                Email verification code
              </P2>
              <P2
                bold
                style={{ cursor: 'pointer' }}
                color={color.colors.primary900}
                onClick={() => sendOldCode()}>
                Send Code
              </P2>
            </Flexed>
            <SecurityCodeWrapper>
              <ReactCodeInput
                fields={7}
                value={oldEmailCode}
                onChange={oldNewEmailCode}
                type={'number'}
              />
            </SecurityCodeWrapper>
            <P3 color={color.colors.neutral500} mb='30'>
              {isCodeSent && `Enter the 7 digit code sent to ${user?.email}`}
            </P3>

            {verificationStatus && (
              <div>
                <Flexed between>
                  <P2 bold color={color.colors.neutral700}>
                    2FA verification code
                  </P2>
                </Flexed>
                <SecurityCodeWrapper is2Fa>
                  <ReactCodeInput
                    fields={6}
                    value={code2Fa}
                    onChange={setCode2Fa}
                    type={'number'}
                  />
                </SecurityCodeWrapper>
                <P3 color={color.colors.neutral500} mb='10'>
                  Enter the 6 digit code from your 2FA authenticator
                </P3>
              </div>
            )}

            <AuthButton
              type='submit'
              opacity={
                verificationStatus
                  ? newEmailCode !== '' && oldEmailCode !== '' && code2Fa !== ''
                  : newEmailCode !== '' && oldEmailCode !== ''
              }
              disabled={
                verificationStatus
                  ? newEmailCode === '' || code2Fa === '' || oldEmailCode === ''
                  : newEmailCode === '' || oldEmailCode === ''
              }>
              Confirm
            </AuthButton>
          </form>
        </>
      )}
    </>
  );
};
