const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const GET_WAITING_PACKAGE = {
  REQUEST: getRequestConst('GET_WAITING_PACKAGE'),
  SUCCESS: getSuccessConst('GET_WAITING_PACKAGE'),
  FAIL: getFailConst('GET_WAITING_PACKAGE')
};

export const ADD_PACKAGE = {
  REQUEST: getRequestConst('ADD_PACKAGE'),
  SUCCESS: getSuccessConst('ADD_PACKAGE'),
  FAIL: getFailConst('ADD_PACKAGE')
};

export const UPGRADE_PACKAGES = {
  REQUEST: getRequestConst('UPGRADE_PACKAGES'),
  SUCCESS: getSuccessConst('UPGRADE_PACKAGES'),
  FAIL: getFailConst('UPGRADE_PACKAGES')
};

export const SET_TAB = 'SET_TAB';
