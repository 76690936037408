import styled from 'styled-components';

export const View = styled.div`
  margin: 20px 0;
  min-height: 640px;
  padding-bottom: 70px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    max-width: 1500px;
    padding: 0 100px;
  }
  @media only screen and (max-width: 1500px) and (min-width: 800px) {
    zoom: 80%;
  }
`;

export const ResourcesContainer = styled.div`
  margin-top: 40px;
`;

export const ResourcesHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderIcon = styled.div`
  width: 32px;
  height: 32px;
  background: ${(props) => props.theme.colors.primary900};
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ResourcesBody = styled.ul`
  li {
    color: #5a5a5a;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: 800;
  margin-left: 16px;
`;
