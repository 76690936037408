import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, updateSocialProfile } from '../../actions/authActions';

import { InputBackOffice } from '../../components/common/InputBackoffice';
import Spinner from '../../components/common/Spinner';
import { ErrorToast } from '../../components/common/Toasts/Toasts';
import { P3 } from '../../styles/FontStyles';
import {
  AuthLayoutTitle,
  ToggleTabButton,
  ToggleTabs
} from '../../styles/GlobalComponents';
import { AuthButton, ButtonWrapper, Label } from '../../styles/stylesStyled';

const ProfileDetails = ({ history }) => {
  const { t } = useTranslation('onboarding');

  const [name, setName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [surname, setSurname] = useState('');
  const [gender, setGender] = useState(1);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postal, setPostal] = useState('');
  const [selectedTab, setSelectedTab] = useState();

  const letterUpercaseAndSpace = /^[a-zA-Z\s]*$/;

  const dispatch = useDispatch();

  const { user, token, loading } = useSelector((state) => state.register);

  const selectTab = (tab) => {
    setSelectedTab(tab);
    if (tab === 'male') {
      setGender(1);
    } else if (tab === 'female') {
      setGender(2);
    } else {
      setGender(3);
    }
  };

  const confirm = () => {
    if (!name) {
      ErrorToast(t('profile_details.err_please_add_name'));
    } else if (!surname) {
      ErrorToast(t('profile_details.err_please_add_lastname'));
    } else if (!middleName) {
      ErrorToast(t('profile_details.err_please_add_username'));
    } else if (!address) {
      ErrorToast(t('profile_details.err_please_add_address'));
    } else if (!postal) {
      ErrorToast(t('profile_details.err_please_add_postal_code'));
    } else if (!city) {
      ErrorToast(t('profile_details.err_please_add_city'));
    } else {
      if (user.creationType === 1) {
        dispatch(
          updateProfile(
            user.id,
            name,
            surname,
            middleName,
            gender,
            address,
            postal,
            city,
            history
          )
        );
      } else {
        dispatch(
          updateSocialProfile(
            user.creationType,
            token,
            name,
            surname,
            middleName,
            gender,
            address,
            postal,
            city,
            history
          )
        );
      }
    }
  };

  const checkFirstName = (e) => {
    if (e.match(letterUpercaseAndSpace)) {
      setName(
        e
          .replace(/|[0-9]/g, '')
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      );
    } else {
      return;
    }
  };

  const checkLastName = (e) => {
    if (e.match(letterUpercaseAndSpace)) {
      setSurname(
        e
          .replace(/|[0-9]/g, '')
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    setSelectedTab('male');
    setName(user?.name);
    setSurname(user?.surname);
  }, []);

  const Required = () => {
    return <span style={{ color: `#EF4444` }}>*</span>;
  };

  return (
    <>
      {loading && <Spinner fullScreen={true} solid={false} />}
      <>
        <AuthLayoutTitle>
          {t('profile_details.welcome_to_ultron')}
        </AuthLayoutTitle>

        <P3 center mb='40'>
          {t('profile_details.tell_us_who_you_are')}
        </P3>

        <Label>
          {t('profile_details.select_your_gender')} <Required />
        </Label>

        <ToggleTabs>
          <ToggleTabButton
            selected={selectedTab === 'male'}
            onClick={() => selectTab('male')}>
            {t('male')}
          </ToggleTabButton>
          <ToggleTabButton
            selected={selectedTab === 'female'}
            onClick={() => selectTab('female')}>
            {t('profile_details.female')}
          </ToggleTabButton>
          <ToggleTabButton
            selected={selectedTab === 'other'}
            onClick={() => selectTab('other')}>
            {t('profile_details.other')}
          </ToggleTabButton>
        </ToggleTabs>

        <Label>
          {t('profile_details.usernameLabel')}
          <Required />
        </Label>

        <InputBackOffice
          id={'middleName'}
          type={'text'}
          placeholder={t('profile_details.usernamePlaceholder')}
          name={'middleName'}
          value={middleName.replace(/[^A-Z0-9]/gi, '') || ''}
          onChange={(e) =>
            setMiddleName(e.replace(/[^A-Z0-9]/gi, '').toLowerCase())
          }
          label={t('profile_details.usernameLabel')}
        />

        <Label>
          {t('profile_details.firstAndLastName')}
          <Required />
        </Label>

        <InputBackOffice
          id={'firstName'}
          type={'text'}
          placeholder={t('profile_details.firstNamePlaceholder')}
          name={'name'}
          value={name}
          onChange={(e) => checkFirstName(e)}
          label={t('profile_details.firstNameLabel')}
          maxlength='16'
        />

        <InputBackOffice
          id={'surname'}
          type={'text'}
          placeholder={t('profile_details.lastNamePlaceholder')}
          name={'surname'}
          value={surname}
          onChange={(e) => checkLastName(e)}
          label={t('profile_details.lastNameLabel')}
          maxlength='16'
        />

        <Label>
          {t('profile_details.address')}
          <Required />
        </Label>
        <InputBackOffice
          id={'address'}
          type={'text'}
          placeholder={t('profile_details.streetAddressPlaceholder')}
          name={'address'}
          value={address || ''}
          onChange={setAddress}
          label={t('profile_details.streetAddressLabel')}
        />

        <InputBackOffice
          id={'postal'}
          type={'text'}
          placeholder={t('profile_details.postalCodePlaceholder')}
          name={'postal'}
          value={postal || ''}
          onChange={setPostal}
          label={t('profile_details.postalCodeLabel')}
        />

        <InputBackOffice
          id={'city'}
          type={'text'}
          placeholder={t('profile_details.cityPlaceholder')}
          name={'city'}
          value={city || ''}
          onChange={setCity}
          label={t('profile_details.cityLabel')}
        />

        <ButtonWrapper>
          <AuthButton
            opacity={name && middleName && surname && address && postal && city}
            disabled={
              name === '' ||
              middleName === '' ||
              surname === '' ||
              address === '' ||
              postal === '' ||
              city === ''
            }
            onClick={confirm}>
            {t('profile_details.continue')}
          </AuthButton>
        </ButtonWrapper>
      </>
    </>
  );
};

export default ProfileDetails;
