import React, { useEffect } from 'react';
import styled from 'styled-components';
import maintenance from '../../assets/icons/backOffice/maintenance.svg';

const Maintenance = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    padding-top: 150px;
  }
`;

export const MaintenanceMode = () => {
  useEffect(() => {
    localStorage.removeItem('userPrizer');
  }, []);

  return (
    <Maintenance>
      <h3>Improving, upgrading, resolving.</h3>
      <img src={maintenance} alt='maintenance' style={{ margin: '28px 0' }} />
      <h3>Be right back!</h3>
    </Maintenance>
  );
};
