import axios from 'axios';
import {
  GET_PACKAGES_REQUEST,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAIL,
  ADD_PACKAGE_REQUEST,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAIL,
  SELECTED_ID,
  COLLAPSE_PACKAGE
} from '../constants/packagesConstants';

import { CLEAR_ALERT } from '../constants/errorConstants';

import { Packages } from '../PackagesData';
import { getWallets } from './walletActions';

const API_URL = process.env.REACT_APP_API_URL;

export const getPackages = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PACKAGES_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    dispatch(getWallets());

    const { data } = await axios.get(`${API_URL}/package`, config);

    let packages = data.data.packages;

    const toCommas = (value) => {
      return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const newPackages = Packages.map((item, index) => ({
      ...item,
      imageTitle:
        packages[index]?.name === 'The GOAT'
          ? 'THE G. O. A. T'
          : packages[index]?.name === 'Partner'
          ? 'Rookie'
          : packages[index]?.name,
      imagePrice: packages[index]?.price,
      packageTitle: toCommas(packages[index]?.packagePoints) + ' B-points',
      collapse: false
    }));

    dispatch({
      type: GET_PACKAGES_SUCCESS,
      payload: newPackages
    });
  } catch (error) {
    dispatch({
      type: GET_PACKAGES_FAIL,
      payload: error.response && error.response.data
    });

    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: ''
      });
    }, 3000);
  }
};

export const addPackage =
  (packageId, currencyType, networkType, setStep) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_PACKAGE_REQUEST });

      const { userInfo } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token.accessToken}`
        }
      };

      const { data } = await axios.post(
        `${API_URL}/package/user`,
        { packageId, currencyType, networkType },
        config
      );

      dispatch({
        type: ADD_PACKAGE_SUCCESS,
        payload: { id: data.data.id, walletAddress: data.data.walletAddress }
      });

      setStep(4);
    } catch (error) {
      dispatch({
        type: ADD_PACKAGE_FAIL,
        payload: error.response && error.response.data.error
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const collapsePackage = (id) => (dispatch) => {
  dispatch({
    type: COLLAPSE_PACKAGE,
    payload: id
  });
};

export const selectPackage = (index) => (dispatch) => {
  dispatch({
    type: SELECTED_ID,
    payload: index
  });

  localStorage.setItem('packageIndex', JSON.stringify(index));
};
