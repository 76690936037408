import {
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAIL,
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAIL,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAIL
} from '../constants/addressConstants';

import { CLEAR_ALERT } from '../constants/errorConstants';

export const addressList = (state = { addresses: [] }, action) => {
  switch (action.type) {
    case ADD_ADDRESS_REQUEST:
      return {
        loading: true,
        addresses: []
      };

    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        addresses: [...state.addresses, action.payload.data.address]
      };

    case ADD_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case GET_ADDRESSES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        addresses: action.payload
      };

    case GET_ADDRESSES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state
        // error: action.payload
      };
    case DELETE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    default:
      return state;
  }
};
