import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  SET_USER_TO_AMBASSADOR_REQUEST,
  SET_USER_TO_AMBASSADOR_SUCCESS,
  SET_USER_TO_AMBASSADOR_FAIL,
  GET_TEAM_USER_INFO_SUCCESS,
  GET_TEAM_USER_INFO_REQUEST,
  GET_TEAM_USER_INFO_FAIL,
  GET_REFERRER_INFO_REQUEST,
  GET_REFERRER_INFO_SUCCESS,
  GET_REFERRER_INFO_FAIL
} from '../constants/userDetailsConstants';

export const userDetails = (
  state = { user: null, loadingReferrer: false },
  action
) => {
  switch (action.type) {
    case GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        generatedRefLinkBefore: action.payload.generatedRefLinkBefore
      };

    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SET_USER_TO_AMBASSADOR_REQUEST:
      return {
        ...state,
        loading: true
      };

    case SET_USER_TO_AMBASSADOR_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          userRank: action.payload
        }
      };

    case SET_USER_TO_AMBASSADOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case GET_TEAM_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_TEAM_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      };

    case GET_TEAM_USER_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case GET_REFERRER_INFO_REQUEST:
      return {
        ...state,
        loadingReferrer: true
      };

    case GET_REFERRER_INFO_SUCCESS:
      return {
        ...state,
        loadingReferrer: false,
        referrer: action.payload
      };

    case GET_REFERRER_INFO_FAIL:
      return {
        ...state,
        loadingReferrer: false,
        errorReferrer: action.payload
      };
    // case CLEAR_ALERT:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };
    default:
      return state;
  }
};
