import styled from 'styled-components';

export const DisplayText = styled.div`
  font-weight: 900;
  font-size: 72px;
  line-height: 80px;
  color: ${(props) => props.theme.colors.neutral900};
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.pointer && `cursor: pointer;`}
${(props) => props.center && `text-align: center;`}
${(props) => props.flex && `display: flex;`}
${(props) => props.between && `justify-content: space-between;`}
${(props) => props.inline && `display: inline;`}
${(props) => props.alignEnd && `align-items: flex-end;`}
${(props) => props.gap && `gap: ${props.gap};`}
${(props) => props.underline && `text-decoration: underline;`}
${(props) => props.right && `text-align: right;`}


  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;

  max-width: ${(props) => props.maxWidth};

  @media (max-width: 800px) {
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}px;
    margin-bottom: ${(props) => props.mbSM}px;
    margin-left: ${(props) => props.mlSM}px;
    margin-right: ${(props) => props.mrSM}px;

    max-width: ${(props) => props.maxWidthSM};
  }

  /* span {
    color: ${(props) => props.theme.colors.primary900};
  } */
`;

export const H1 = styled(DisplayText)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
`;

export const H2 = styled(H1)`
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  ${(props) => props.bold && `font-weight: 700;`}
  ${(props) => props.extraBold && `font-weight: 800;`}
`;

export const H3 = styled(H2)`
  font-size: 22px;
  line-height: 32px;
`;

export const H4 = styled(H2)`
  font-size: 20px;
  line-height: 24px;
`;

export const H5 = styled(H2)`
  font-size: 16px;
  line-height: 20px;
`;

export const P1 = styled(H2)`
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  ${(props) => props.bold && `font-weight: 700;`}
  ${(props) => props.semiBold && `font-weight: 500;`}
  ${(props) => props.light && `font-weight: 300;`}
`;

export const P2 = styled(P1)`
  font-size: 16px;
`;

export const P3 = styled(P1)`
  font-size: 14px;
`;

export const CaptionText = styled(P2)`
  font-size: 12px;
  ${(props) => props.caps && `text-transform: uppercase;`}

  @media (min-width: 800px) {
    display: ${(props) => props.mobile && `none`};
  }
`;

export const FooterText = styled(P2)`
  font-size: 10px;
`;

export const InputLabel = styled(P2)`
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 700;
  color: ${(props) => props.theme.inputLabelColor};
`;
