/* eslint-disable no-unused-vars */
import React from 'react';

import { CaptionText, H4, P1, P3 } from '../../styles/FontStyles';

import { Flexed } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { BoxBig, HubImage, Td, Tr } from './Portfolio.styled';

import moment from 'moment';

import magicUnicorn from '../../assets/images/backoffice/gamingImages/magicUnicorn.jpg';
import mysticLion from '../../assets/images/backoffice/gamingImages/mysticLions.jpg';
import mightyDragon from '../../assets/images/backoffice/gamingImages/mightyDragon.jpg';
import risingPhoenix from '../../assets/images/backoffice/gamingImages/risingPhoenix.jpg';
import ragingBull from '../../assets/images/backoffice/gamingImages/ragingBull.jpg';
import wealthyTiger from '../../assets/images/backoffice/gamingImages/wealthyTiger.jpg';
import luckyApe from '../../assets/images/backoffice/gamingImages/luckyApe.jpg';
import wiseElephant from '../../assets/images/backoffice/gamingImages/wiseElephant.jpg';
import fortunePanda from '../../assets/images/backoffice/gamingImages/fortunePanda.jpg';

import { formatUSD, formatUSDNoDecimals } from '../../helpers/helperFunctions';
import { Responsive } from '../../styles/stylesStyled';

import { useTranslation } from 'react-i18next';
import Spinner from '../../components/common/Spinner';

export const PortfolioGamingItem = ({ item }) => {
  const { t } = useTranslation('my_hubs');

  const showImages = (item) => {
    switch (item?.price) {
      case 100:
        return luckyApe;
      case 300:
        return wiseElephant;
      case 500:
        return fortunePanda;
      case 1500:
        return ragingBull;
      case 5000:
        return wealthyTiger;
      case 12500:
        return risingPhoenix;
      case 30000:
        return mightyDragon;
      case 50000:
        return mysticLion;
      case 100000:
        return magicUnicorn;
      default:
        return luckyApe;
    }
  };

  return (
    <>
      <BoxBig relative>
        <HubImage image={showImages(item)}>
          <H4 color={color.colors.neutral0}>{item.name}</H4>
        </HubImage>
        <div>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              right: '50%',
              transform: 'translate(-50%, -50%)'
            }}>
            <Spinner />
          </div>
          <Tr style={{ cursor: 'pointer' }}>
            <Td w='20%' wSM='50%'>
              <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                {t('price')}
              </CaptionText>
              <P1 bold flex alignEnd gap='5px'>
                {formatUSDNoDecimals(item.price)} <P3 mb='1'>USDT</P3>
              </P1>
            </Td>

            <Td w='20%' wSM='50%'>
              <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                {t('date_of_purchase')}
              </CaptionText>
              <P1 bold>
                {moment.unix(item?.dateOfPurchase).format('DD MMM YYYY')}
              </P1>
            </Td>
            <Td w='20%' wSM='50%'>
              <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                {t('revenue_share_points')}
              </CaptionText>
              <P1 bold flex alignEnd gap='5px'>
                {formatUSD(item?.rsp)} <P3 mb='1'>RSP</P3>
              </P1>
            </Td>
          </Tr>
          <div>
            <>
              <Responsive style={{ margin: '24px 0' }} between center>
                <Flexed mbSM='20'>
                  <P3 caps bold color={color.colors.secondary100} mr='60'>
                    {t('gaming_hub_details')}
                  </P3>
                  <P3 flex color={color.colors.secondary100} mr='60'>
                    {t('order_id')}:{' '}
                    <P3 bold ml='2' color={color.colors.secondary100}>
                      {item?.id}
                    </P3>
                  </P3>

                  <P3 flex color={color.colors.secondary100}>
                    {t('nft_id')}:
                    <P3 bold ml='2' color={color.colors.secondary100}>
                      {item?.nftId}
                    </P3>
                  </P3>
                </Flexed>
              </Responsive>
            </>
          </div>
        </div>
      </BoxBig>
    </>
  );
};
