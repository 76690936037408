import React from 'react';
import SHA256 from 'crypto-js/sha256';

import { useEffect } from 'react';

import { H5 } from '../styles/FontStyles';
import { color } from '../styles/GlobalStyles';

import binance from '../assets/icons/backOffice/Packages/binance.svg';
import trc from '../assets/icons/backOffice/Packages/trc.svg';
import ultron from '../assets/icons/backOffice/Packages/ultron.png';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { localStorageSetter } from '../lib/utils';
import {
  IconEarnings,
  IconHelpdesk,
  IconHome,
  IconMontly,
  IconGaming,
  IconMyHubs,
  IconMyPackage,
  IconMyTeam,
  IconOrders,
  IconRanking,
  IconResources
} from '../styles/Icons';

import moment from 'moment';
import rank1 from '../assets/images/backoffice/rankImages/rank1.png';
import rank10 from '../assets/images/backoffice/rankImages/rank10.png';
import rank11 from '../assets/images/backoffice/rankImages/rank11.png';
import rank12 from '../assets/images/backoffice/rankImages/rank12.png';
import rank13 from '../assets/images/backoffice/rankImages/rank13.png';
import rank14 from '../assets/images/backoffice/rankImages/rank14.png';
import rank15 from '../assets/images/backoffice/rankImages/rank15.png';
import rank16 from '../assets/images/backoffice/rankImages/rank16.png';
import rank17 from '../assets/images/backoffice/rankImages/rank17.png';
import rank18 from '../assets/images/backoffice/rankImages/rank18.png';
import rank2 from '../assets/images/backoffice/rankImages/rank2.png';
import rank3 from '../assets/images/backoffice/rankImages/rank3.png';
import rank4 from '../assets/images/backoffice/rankImages/rank4.png';
import rank5 from '../assets/images/backoffice/rankImages/rank5.png';
import rank6 from '../assets/images/backoffice/rankImages/rank6.png';
import rank7 from '../assets/images/backoffice/rankImages/rank7.png';
import rank8 from '../assets/images/backoffice/rankImages/rank8.png';
import rank9 from '../assets/images/backoffice/rankImages/rank9.png';
import {
  DASHBOARD,
  EARNINGS,
  GAMING,
  MARKETPLACE,
  MY_HUBS,
  MY_TEAM,
  ORDERS,
  RANKING,
  RESOURCES
} from '../routes/routes';
import { SuccessToast } from '../components/common/Toasts/Toasts';

const API_URL = process.env.REACT_APP_GETEXCH_URL;

export const ChooseRankImage = (name) => {
  switch (name) {
    case 'Qualified Partner':
      return rank1;
    case 'Iron':
      return rank2;
    case 'Silver':
      return rank3;
    case 'Gold':
      return rank4;
    case 'Pearl':
      return rank5;
    case 'Sapphire':
      return rank6;
    case 'Ruby':
      return rank7;
    case 'Emerald':
      return rank8;
    case 'Diamond':
      return rank9;
    case 'Blue Diamond':
      return rank10;
    case 'Green Diamond':
      return rank11;
    case 'Black Diamond':
      return rank12;
    case 'Crown Diamond':
      return rank13;
    case 'Double Crown Diamond':
      return rank14;
    case 'Ambassador':
      return rank15;
    case 'Royal Ambassador':
      return rank16;
    case 'G.O.A.T':
      return rank17;
    case 'Legendary G.O.A.T':
      return rank18;
    default:
      return null;
  }
};

export const toCommas = (value) => {
  return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const validateEmail = (mail) => {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (mail.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

export const PrizeNameFormat = (name) => {
  const first = name[0].toLowerCase();
  if (
    first === 'a' ||
    first === 'e' ||
    first === 'i' ||
    first === 'o' ||
    first === 'u'
  ) {
    return 'Win an ';
  } else {
    return 'Win a ';
  }
};

export const UploadKycImagesProgressStyles = {
  // Customize the root svg element
  root: {},
  // Customize the path, i.e. the "completed progress"
  path: {
    // Path color
    stroke: `rgba(255, 255, 255)`,
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'round',
    // Customize transition animation
    transition: 'stroke-dashoffset 0.5s ease 0s',
    // Rotate the path
    // transform: 'rotate(0.25turn)',
    transformOrigin: 'center center'
  },
  // Customize the circle behind the path, i.e. the "total progress"
  trail: {
    // Trail color
    stroke: 'none',
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'butt',
    // Rotate the trail
    transform: 'rotate(0.25turn)',
    transformOrigin: 'center center'
  },
  // Customize the text
  text: {
    // Text color
    fill: '#fff',
    // Text size
    fontSize: '30px',
    fontWeight: '500'
  },
  // Customize background - only used when the `background` prop is true
  background: {
    fill: 'none'
  }
};

export const videoConstraints = {
  facingMode: 'environment'
};

export const videoConstraintsPassport = {
  facingMode: 'environment',
  aspectRatio: { exact: window.screen.width > 600 ? 0.7 : 1.6 }
};

export const videoConstraintsSelfie = {
  facingMode: 'user',
  aspectRatio: { exact: 1 }
};

export const convertImage = async (image, imageName) => {
  const response = await image;

  const blob = await fetch(response).then((res) => res.blob());

  const file = new File([blob], imageName, { type: 'image/jpeg' });

  return file;
};

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export const NumberRound = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const SidebarNavLinks = () => {
  const { t } = useTranslation('layout');

  const links = [
    {
      name: t('nav_items.home'),
      link: DASHBOARD,
      disabled: false
    },
    {
      name: t('nav_items.my_team'),
      link: MY_TEAM,
      disabled: false
    },
    {
      name: t('nav_items.earnings'),
      link: EARNINGS,
      disabled: false
    },
    {
      // name: t('nav_items.marketplace'),
      name: 'Gaming HUBs',
      link: GAMING,
      disabled: false
    },
    {
      name: 'Staking HUBs', // t('nav_items.marketplace'),
      link: MARKETPLACE,
      disabled: false
    },
    {
      name: t('nav_items.my_hubs'),
      link: MY_HUBS,
      disabled: false
    },
    {
      name: t('nav_items.orders'),
      link: ORDERS,
      disabled: false
    },

    {
      name: t('nav_items.ranking'),
      link: RANKING,
      disabled: false
    },

    {
      name: t('nav_items.resources'),
      link: RESOURCES,
      disabled: false
    }
  ];

  return links;
};

export const SidebarIcon = ({ link }) => {
  const { t } = useTranslation('layout');
  switch (link) {
    case t('nav_items.home'):
      return <IconHome />;

    case t('nav_items.my_team'):
      return <IconMyTeam />;

    case t('nav_items.earnings'):
      return <IconEarnings />;

    // case t('nav_items.gaming'):
    //   return <IconGaming />;
    case 'Gaming HUBs':
      return <IconGaming />;

    // case t('nav_items.marketplace'):
    //   return <IconMyHubs />;
    case 'Staking HUBs':
      return <IconMyHubs />;

    case t('nav_items.my_hubs'):
      return <IconMyPackage />;

    case t('nav_items.orders'):
      return <IconOrders />;

    case t('nav_items.montly'):
      return <IconMontly />;

    case t('nav_items.ranking'):
      return <IconRanking />;

    case t('nav_items.resources'):
      return <IconResources />;

    case t('profile.helpdesk'):
      return <IconHelpdesk />;

    default:
      return null;
  }
};

export const progressCircleStyles = {
  // Customize the root svg element
  root: {},
  // Customize the path, i.e. the "completed progress"
  path: {
    // Path color
    stroke: `
    #fefefe
    `,
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    // strokeLinecap: 'butt',
    // Customize transition animation
    transition: 'stroke-dashoffset 0.5s ease 0s',
    // Rotate the path
    // transform: 'rotate(0.25turn)',
    transformOrigin: 'center center'
  },
  // Customize the circle behind the path, i.e. the "total progress"
  trail: {
    // Trail color
    stroke: '#353130',
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    // strokeLinecap: 'butt',
    // Rotate the trail
    // transform: 'rotate(0.25turn)',
    transformOrigin: 'center center',
    border: '2px solid rgba(28, 23, 22, 0.3)'
  },
  // Customize the text
  text: {
    // Text color
    fill: '#FEFEFE',
    // Text size
    color: '#FEFEFE',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: ' 24px'
  },
  // Customize background - only used when the `background` prop is true
  background: {
    fill: '#3e98c7'
  }
};

export const abbreviateNumber = (value) => {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ['', 'k', 'm', 'b', 't'];
    var suffixNum = Math.floor(('' + value).length / 3);
    var shortValue = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
};

export const NameInitials = () => {
  const userInfo = useSelector((state) => state.userInfo);
  const name =
    userInfo?.user?.name?.charAt(0) + userInfo?.user?.surname?.charAt(0);
  return name;
};

export const SomeUserNameInitials = (name, surname) => {
  const nameSurname = name?.charAt(0) + surname?.charAt(0);
  return nameSurname;
};

export const GoToMyTeam = (history, tab) => {
  const SELECTED_TAB_LC_KEY = 'MY_TEAM_SELECTED_TAB_LC_KEY';
  localStorageSetter(SELECTED_TAB_LC_KEY, tab);
  history.push(MY_TEAM);
};

export const setErorMessage = (setError, message) => {
  setError(message);
  setTimeout(() => {
    setError('');
  }, 3000);
};

export const formatUSD = (value) => {
  const isWholeNumber = Number.isInteger(value);

  if (isWholeNumber) {
    const comma =
      value && value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${comma},00`;
  } else {
    let parsedNumber = value?.toFixed(2);

    let convertedNumber = parsedNumber?.toString();

    let num = convertedNumber?.split('.');

    let splitedNumber = convertedNumber && num[1];

    convertedNumber = convertedNumber?.replace('.', ',');

    convertedNumber = convertedNumber
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (splitedNumber?.length === 1) {
      return `${convertedNumber}0`;
    } else {
      return convertedNumber;
    }
  }
};

export const formatUSDNoDecimals = (value) => {
  const isWholeNumber = Number.isInteger(value);

  if (isWholeNumber) {
    const comma =
      value && value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${comma}`;
  } else {
    let parsedNumber = value?.toFixed(2);

    let convertedNumber = parsedNumber?.toString();

    let num = convertedNumber?.split('.');

    let splitedNumber = convertedNumber && num[1];

    convertedNumber = convertedNumber?.replace('.', ',');

    convertedNumber = convertedNumber
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (splitedNumber?.length === 1) {
      return `${convertedNumber}0`;
    } else {
      return convertedNumber;
    }
  }
};

export const formatVP = (value) => {
  let convertedNumber = value?.toString();

  if (convertedNumber?.includes('.')) {
    let num = convertedNumber?.split('.');
    let splitedNumber = convertedNumber && num[1];

    if (splitedNumber?.length === 1) {
      convertedNumber = convertedNumber
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      return `${convertedNumber}0`;
    } else {
      convertedNumber = convertedNumber
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return convertedNumber;
    }
  } else {
    convertedNumber = convertedNumber
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return convertedNumber;
  }
};

// export const formatMembers = (value) => {
//   return value?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');
// };

export const USDT = 'USDT';
export const CP = 'CP';
export const RP = 'RP';
export const ULX = 'wULX';

export const calculatePercentage = (current, required) => {
  const data = (100 * current) / required;
  return data.toFixed(0);
};

export const calculatePercentageWithPrevious = (
  current,
  required,
  previousCurrent,
  previousRequired,
  index
) => {
  const data = (100 * current) / required;

  if (index !== 0 && previousCurrent < previousRequired) {
    return 0;
  }

  return data.toFixed(0);
};

export const calculatePercentage2 = (start, end, current) => {
  const total = end - start;
  const percent = ((current - start) / total) * 100;

  if (percent.toFixed(0) < 0) {
    return 0;
  } else if (percent.toFixed(0) > 100) {
    return 100;
  } else {
    return percent.toFixed(0);
  }
};

// scrollTo.js

// animateScroll.js

const pow = Math.pow;

// The easing function that makes the scroll decelerate over time
function easeOutQuart(x) {
  return 1 - pow(1 - x, 4);
}

export function animateScroll({ targetPosition, initialPosition, duration }) {
  let start;
  let position;
  let animationFrame;

  const requestAnimationFrame = window.requestAnimationFrame;
  const cancelAnimationFrame = window.cancelAnimationFrame;

  // maximum amount of pixels we can scroll
  const maxAvailableScroll =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;

  const amountOfPixelsToScroll = initialPosition - targetPosition;

  function step(timestamp) {
    if (start === undefined) {
      start = timestamp;
    }

    const elapsed = timestamp - start;

    // this just gives us a number between 0 (start) and 1 (end)
    const relativeProgress = elapsed / duration;

    // ease out that number
    const easedProgress = easeOutQuart(relativeProgress);

    // calculate new position for every thick of the requesAnimationFrame
    position =
      initialPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1);

    // set the scrollbar position
    window.scrollTo(0, position);

    // Stop when max scroll is reached
    if (
      initialPosition !== maxAvailableScroll &&
      window.scrollY === maxAvailableScroll
    ) {
      cancelAnimationFrame(animationFrame);
      return;
    }

    // repeat until the end is reached
    if (elapsed < duration) {
      animationFrame = requestAnimationFrame(step);
    }
  }

  animationFrame = requestAnimationFrame(step);
}

const logError = () =>
  console.error(
    `Invalid element, are you sure you've provided element id or react ref?`
  );

const getElementPosition = (element) => element.offsetTop;

export const scrollTo = ({ id, duration = 3000 }) => {
  // the position of the scroll bar before the user clicks the button
  const initialPosition = window.scrollY;

  // if the reference is id
  if (id) {
    const element = document.getElementById(id);

    if (!element) {
      // log error if the reference passed is invalid
      logError();
      return;
    }

    animateScroll({
      // target position is the elements offsetTop
      targetPosition: getElementPosition(element),
      initialPosition,
      duration
    });
  }
};

export const showWallet = (address, number) => {
  const text1 = address?.slice(0, 4);
  const text2 = address?.slice(number);

  return (
    <>
      {text1}...{text2}
    </>
  );
};

export const BP = 'BP';
export const BV = 'BV';
export const UV = 'UV';
export const PO = 'PO';

export const BACKOFFICE_NAME = 'Back Office';
export const BACKOFFICE_NAME_CAPS = 'BACK OFFICE';

// export const globalPlans = [
//   {
//     id: 3,
//     title: 'PRO',
//     price: 149,
//     access: 5,
//     benefits: [
//       {
//         text: 'All included in Advanced 💎'
//       },
//       {
//         text: 'Live Event Priority'
//       }
//     ]
//   },
//   {
//     id: 2,
//     title: 'Advanced',
//     price: 99,
//     access: 3,
//     benefits: [
//       {
//         text: 'Support & Updates'
//       },
//       {
//         text: 'Limited NFT Drops Access'
//       }
//     ]
//   },
//   {
//     id: 1,
//     title: 'Basic',
//     price: 49,
//     access: 1,
//     benefits: [
//       {
//         text: 'Only for Beginners'
//       },
//       {
//         text: 'Support & Updates'
//       }
//     ]
//   }
// ];

export const globalPlans = () => {
  const { t } = useTranslation('settings');

  return [
    {
      id: 1,
      title: t('virtual_office.plans.basic.name'),
      price: 49,
      access: 1,
      benefits: [
        {
          text: t('virtual_office.plans.basic.text1')
        },
        {
          text: t('virtual_office.plans.basic.text2')
        }
      ]
    }
  ];
};

export const dateDifferenceInDaysFromToday = (date) => {
  const start = moment(new Date());

  const end = moment(date);

  const duration = moment.duration(end.diff(start));

  if (Number(duration.asDays().toFixed(0)) < 0) {
    return 0;
  } else {
    return Number(duration.asDays().toFixed(0));
  }
};

export const getRequestConst = (unique) => `${unique}_REQUEST`;
export const getSuccessConst = (unique) => `${unique}_SUCCESS`;
export const getFailConst = (unique) => `${unique}_FAIL`;

export const wallets = [
  { networkType: 3, icon: ultron, wallet: 'Ultron Chain (ULC-20)', index: 0 },
  {
    networkType: 1,
    icon: binance,
    wallet: 'Binance Smart Chain (BEP-20)',
    index: 1
  },
  { networkType: 2, icon: trc, wallet: 'Tron Network (TRC-20)', index: 2 }
];

export const showNetworkType = (order) => {
  switch (order?.networkType) {
    case 1:
      return wallets[1].wallet;
    case 2:
      return wallets[2].wallet;
    case 3:
      return wallets[0].wallet;
    default:
      return '';
  }
};

export const showText = (plan) => {
  switch (plan.title) {
    case 'Basic':
      return 'ONLY FOR BEGINNERS';
    case 'Advanced':
      return 'AFFORDABLE OPTION';
    default:
      return 'MOST POPULAR';
  }
};

export const showMonthlyText = (plan) => {
  switch (plan.title) {
    case 'Basic':
      return <H5 color={color.colors.neutral500}>$4.1/month</H5>;
    case 'Advanced':
      return <H5 color='#FFC961'>$2.8/month</H5>;
    default:
      return <H5 color='#18B915'>$2.4/month</H5>;
  }
};

export const UsersWithEmptySlots = (users) => {
  if (!users) return;
  const DirectUsersWithEmptySlots = [...users];
  if (users.length < 5) {
    for (let i = 0; (i = 5 - DirectUsersWithEmptySlots?.length); i++) {
      DirectUsersWithEmptySlots.push({ value: 'emptyBox' });
    }
  }
  return DirectUsersWithEmptySlots;
};

export const PLATFORM_LINK = process.env.REACT_APP_PLATFORM_LINK;

export const copyLink = (link, message) => {
  navigator.clipboard.writeText(link);
  SuccessToast(message || 'Link has been copied');
};

export const redirectToGetexch = (address, amount, email) => {
  const hashedCurrency = SHA256('EUR').toString();

  const hashedAmount = SHA256(JSON.stringify(amount)).toString();

  window.open(
    `${API_URL}?address=${address}&from_currency=EUR&disable_from_amount=true&disable_from_currency=true&from_currency_hash=${hashedCurrency}&to_currency=USDT&to_amount=${amount}&disable_to_amount=true&disable_to_currency=true&from_amount_hash=${hashedAmount}&email=${email}`,
    '_blank' // <- This is what makes it open in a new window.
  );
};

export const calculateNextAmount = (partialValue, totalValue) => {
  return (partialValue / 100) * totalValue;
};
