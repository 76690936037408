import React from 'react';
import Drawer from 'react-bottom-drawer';
import styled from 'styled-components';

const DrawerWrap = styled.div`
  width: 311px;
  margin: 0 auto;
  @media (max-width: 450px) {
    width: 100%;
  }
  .bottomDrawerWithoutHandle__handle {
    width: 0 !important;
    height: 0 !important;
  }

  .bottomDrawerWithoutHandle {
    background: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(6px);
  }

  .bottomDrawer-zIndex {
    z-index: 111000 !important;
    width: 100% !important;
  }
`;

const BottomDrawer = ({
  isVisible,
  children,
  onClose,
  height,
  withHandle = true
}) => {
  return (
    <Drawer
      isVisible={isVisible}
      onClose={onClose}
      className={
        withHandle ? 'bottomDrawer-zIndex' : 'bottomDrawerWithoutHandle'
      }>
      <DrawerWrap
        style={{
          height: height
        }}>
        {children}
      </DrawerWrap>
    </Drawer>
  );
};

export default BottomDrawer;
