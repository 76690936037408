const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const RankType = {
  Partner: 'Partner',
  Ambassador: 'Ambassador',
  Customer: 'Customer'
};

export const EARNINGS_INIT = {
  REQUEST: getRequestConst('EARNINGS_INIT'),
  SUCCESS: getSuccessConst('EARNINGS_INIT'),
  FAIL: getFailConst('EARNINGS_INIT')
};

export const EARNINGS_HISTORY = {
  REQUEST: getRequestConst('EARNINGS_HISTORY'),
  SUCCESS: getSuccessConst('EARNINGS_HISTORY'),
  FAIL: getFailConst('EARNINGS_HISTORY')
};
