import styled from 'styled-components';
import { SectionSeparatorVertical } from '../../styles/GlobalComponents';
import { DisableSelection } from '../../styles/stylesStyled';

const LevelZeroTopInfo = styled.div`
  /* max-width: 800px; */
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const LevelZeroWrap = styled.div`
  margin-bottom: 8px;
  background: ${(props) => props.theme.colors.neutral50};
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  border-radius: 12px;
  min-width: 680px;
  padding: 12px 15px;
  width: 100%;
  display: fixed;
  align-items: center;
  justify-content: space-between;
  color: #fefefe;
  text-align: center;
  position: relative;
  h1 {
    font-weight: 900;
    font-size: 16px;
    line-height: 32px;
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
  }
  h3 {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #b5a8a8;
  }
  > div {
    width: calc(100% / 3);
  }
`;

const LevelOneWrap = styled.div`
  /* background: ${(props) => props.theme.colors.neutral50};
  box-shadow: ${(props) => props.theme.shadows.shadowXS}; */
  border-radius: 10px;
  padding: 12px;
  min-width: 188px;
  min-height: 132px;

  /* margin-top: 18px; */
  margin-bottom: 8px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fefefe;
    /* margin-top: 12px; */
  }
  h2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.neutral900};
    text-align: center;
  }
  h3 {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: ${(props) => props.theme.colors.secondary100};
    text-align: center;
  }
  @media (max-width: 800px) {
    min-height: 125px;
    min-width: auto;
    width: 125px;
    max-width: 188px;
  }
`;

const LevelOneInfoStyled = styled.div`
  height: ${(props) => (props.open ? '100%' : '0px')};
  overflow: hidden;
  transition: all 0.3s ease;
  margin-top: ${(props) => (props.open ? '16px' : '0px')};

  p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #fefefe;
    margin-bottom: 12px;
    text-align: center;
    span {
      font-weight: 500;
      color: #fefefe;
    }
  }

  h1 {
    margin: 18px 0 0 0;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.neutral500};
    text-align: center;
  }
  h2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #b5a8a8;
    text-align: center;
  }
`;

const LevelOneInfoVolume = styled.div`
  div {
    h1 {
      color: ${(props) => props.theme.colors.neutral500};
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      font-weight: normal;
    }
    h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${(props) => props.theme.colors.neutral900};
      margin: 0;
    }
  }
  margin: 4px 8px;
  width: calc(100% - 24px);
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  border-radius: 8px;
  background: ${(props) => props.theme.colors.neutral50};
  border: 2px solid
    ${({ theme, weaker }) =>
      weaker ? theme.colors.primary900 : theme.colors.neutral100};
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LevelOneImgName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

const StructureLeftRightWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  > div {
    width: 50%;
    /* justify-content: center; */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const DirectLeftRightWrapper = styled.div`
  display: flex;
  margin: 32px 0;
  width: 100%;
`;

const DirectsWrapper = styled.div`
  /* padding-bottom: 50px; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 10px;
  /* direction: rtl; */
  overflow: scroll;
  max-height: calc(100vh - 290px);
  @media (max-width: 800px) {
    max-height: calc(100vh - 340px);
  }
`;

const DirectsVR = styled(SectionSeparatorVertical)`
  margin: 0 50px;

  @media (max-width: 800px) {
    margin: 0 30px;
  }
  @media (max-width: 400px) {
    margin: 0 20px;
  }
`;

const FlexJustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const FlexCenterEqual = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  > div {
    width: 50%;
  }
`;

const FlexCenterWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

const BigArrowsDouble = styled.div`
  /* border-top: 1px dashed #857272; */
  border-left: 1px dashed #857272;
  border-right: 1px dashed #857272;
  border-radius: 5px 5px 0 0;
  width: 50%;
  height: 18px;
  position: relative;
`;

const StructureNavigation = styled.div`
  > * {
    ${DisableSelection}
  }
  background: ${(props) => props.theme.colors.neutral50};
  height: 90px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1003;
  @media (max-width: 800px) {
    padding: 0 15px 15px 15px;
    flex-direction: column;
    height: auto;
    background: none;
  }
`;

const StructureArrow = styled.div`
  margin: 4px;
  border-radius: 8px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.disabled ? 'none' : props.theme.colors.secondary100};

  border: ${(props) =>
    props.disabled
      ? `2px solid ${props.theme.colors.neutral100} `
      : props.theme.colors.secondary100};

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transform: ${(props) => (props.reverse ? 'rotate(180deg)' : '')};

  /* opacity: ${(props) => (props.disabled ? '0.3' : '1')}; */
  /* ${(props) =>
    props.disabled
      ? ''
      : 'box-shadow: 2px 5px 20px rgba(246, 110, 46, 0.3);'}; */

  /* box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1); */

  @media (max-width: 800px) {
    margin: 12px 0;
    height: 40px;
    width: 40px;
    img {
      width: 24px;
    }
  }
`;

const StructureGrayButton = styled.div`
  text-align: center;
  margin: 4px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  height: 50px;
  width: 197px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.neutral100
      : props.theme.colors.neutral50};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.neutral400
      : props.theme.colors.neutral900};
  box-shadow: ${(props) =>
    props.disabled
      ? ''
      : `inset 0px 0px 0px 2px ${props.theme.colors.secondary100};`};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) =>
    props.orangeGradient
      ? `background: ${props.theme.colors.primary900}; box-shadow: none; color: ${props.theme.colors.neutral50}`
      : ''};
  @media (max-width: 800px) {
    width: 100%;
  }
  span {
    color: ${(props) => props.theme.colors.secondary100};
  }
  > * {
    ${DisableSelection}
  }
`;

const IdSmall = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #b5a8a8;
`;

const Tabs = styled.div`
  /* background: linear-gradient(
    270deg,
    #1c1716 3.82%,
    rgba(28, 23, 22, 0) 26.36%
  ); */
  overflow-y: auto;
  display: flex;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const DirectUserWrap = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.neutral50};
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  border-radius: 10px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* max-width: 120px; */
  min-height: 95px;
  /* > div {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
  
  } */
`;

const DirectId = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
`;

const DirectLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  width: calc(100% - 20px);
  margin: 10px;
  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

const VolumesDesktop = styled.div`
  width: 100%;
  max-width: 680px;
  padding: 1px;

  /* background: ${(props) =>
    props.isCurrentWeek ? props.theme.colors.secondary100 : ''}; */

  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  > div {
    background: ${(props) => props.theme.colors.neutral50};
    border-radius: 8px;
    width: 100%;
    display: flex;
  }
`;

const GenerationsDesktop = styled(VolumesDesktop)``;

const VolumesDate = styled.div`
  height: 100px;
  width: calc(100% / 3);
  background: ${(props) => props.theme.colors.secondary100};
  border-radius: 8px 0px 0px 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.neutral50};
`;

const GenerationsDate = styled(VolumesDate)`
  ${(props) => props.isLocked && `background ${props.theme.colors.neutral100}`};
  width: 40%;
  color: ${(props) => props.theme.colors.neutral50};
  ${(props) => props.isLocked && `color ${props.theme.colors.neutral400}`};

  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.01em;
    margin-left: 24px;
    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const VolumesInfo = styled.div`
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: ${(props) => props.theme.colors.neutral900};
    margin-bottom: 8px;
  }
  h2 {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.neutral500};
    margin-bottom: 4px;
    opacity: 0.7;
  }
  h3 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.neutral900};
    span {
      color: #a6f787;
    }
  }
  @media (max-width: 800px) {
    width: calc(100% / 2);

    h1 {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const GenerationsInfo = styled(VolumesInfo)`
  width: 20%;
`;

const VolumesUser = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;

  max-width: 188px;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fefefe;
  }
  h2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #fefefe;
    text-align: center;
  }
  h3 {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: #f48150;
    text-align: center;
  }
`;

const InfoTeam = styled.div`
  margin-bottom: 8px;
  background: #272322;
  border-radius: 12px;
  min-width: 680px;
  width: 100%;
  display: fixed;
  align-items: center;
  justify-content: space-between;
  color: #fefefe;
  text-align: center;

  h1 {
    font-weight: 900;
    font-size: 16px;
    line-height: 32px;
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
  }
  h3 {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #b5a8a8;
  }

  @media (max-width: 800px) {
    min-width: auto;
  }
`;

const Separator = styled.div`
  background: linear-gradient(to right, #484b51, #949494, #484b51);
  width: 100%;
  height: 1px;
  margin: 40px 0;
  @media (max-width: 800px) {
    display: none;
  }
`;

const SeparatorRow = styled.div`
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 1px;
  margin: 8px 0;
`;

const SearchContainer = styled.div`
  width: 224px;
  height: 60px;
  background: #1c1716;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding-left: 30px;

  @media (max-width: 800px) {
    background: #272322;
    width: 170px;
    padding-left: 20px;
  }
`;

const InfoTeamContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  font-size: 18px;
  @media (max-width: 800px) {
    margin-right: 0px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const Collapsable = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  max-height: ${(props) => (props.expanded ? '235px' : '0')};
  overflow: scroll;
  transition: all 500ms;
  margin-bottom: ${(props) => (props.expanded ? '20px' : '0')};
  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* Firefox */
`;

const Row = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  color: #fff;
  cursor: pointer;
  span {
    background: -webkit-linear-gradient(#f58e35, #f26c6b, #ee288a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
// arr.length - 1 === i;

const RowTitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #b5a8a8;
  font-weight: 500;
  padding: 20px 8px;
  width: 224px;
  text-transform: uppercase;
`;

const RowTitleCountry = styled.div`
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: rgba(181, 168, 168, 1);
  font-weight: 500;
  padding: 20px 8px;
  width: 224px;
  text-align: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

const RowCountry = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const RowDate = styled.div`
  width: 224px;
  text-align: right;
`;

const InfoTeamName = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

const Arrow = styled.div`
  display: flex;
  img {
    transform: ${(props) =>
      props.personal ? 'rotate(90deg)' : 'rotate(0deg)'};
  }
`;

const CustomerContainer = styled.div`
  width: ${(props) => (props.wide ? '100%' : '396px')};
  height: ${(props) => (props.step === 1 ? ' 608px' : 'auto')};
`;

const ModalHeader = styled.div`
  // display: flex;
  // flex-direction: column;
  // align-items: center;
`;

const HeaderTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 4px;
  margin-top: 12px;
`;

const ProfilePicture = styled.div`
  background: -webkit-linear-gradient(left top, #d24074 0%, #1268c3 100%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0px 0px 24px 0px rgba(130, 81, 150, 1);
  margin-right: 12px;
`;

const TeamContainer = styled.div`
  width: 180px;
  height: 60px;
  background: #1c1716;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 0 30px;
  @media (max-width: 800px) {
    justify-content: start;
    background: #272322;
    width: 50%;
  }
`;

const FadeWrap = styled.div`
  position: relative;
  padding-bottom: 50px;
  overflow: scroll;
  max-height: calc(100vh - 535px);
  @media (max-width: 800px) {
    max-height: calc(100vh - 390px);
  }
`;

const DivFade = styled.div`
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100px;
  background: linear-gradient(
    0deg,
    ${(props) => props.theme.colors.background} 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const OrderTitle = styled.div`
  font-size: 20px;
  color: #fff;
  text-align: center;
  font-weight: 700;
`;

export const UnlockGenerations = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #fefefe;
  margin-bottom: 32px;
  span {
    font-weight: bold;
    text-decoration-line: underline;
    cursor: pointer;
  }
  img {
    margin-right: 8px;
  }
  @media (max-width: 800px) {
    margin-top: 20px;
    flex-direction: column;
    img {
      margin-bottom: 8px;
    }
    div {
      margin-bottom: 16px;
    }
  }
`;
export const VolumeUserWrap = styled.div`
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const AlertIcon = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: -5px;
`;

export const AlertWrapper = styled.div`
  position: relative;
`;

export {
  LevelZeroWrap,
  FlexJustifyCenter,
  FlexCenterEqual,
  LevelZeroTopInfo,
  FlexCenterWrap,
  LevelOneWrap,
  StructureLeftRightWrapper,
  StructureGrayButton,
  StructureArrow,
  StructureNavigation,
  IdSmall,
  LevelOneImgName,
  Tabs,
  LevelOneInfoStyled,
  LevelOneInfoVolume,
  BigArrowsDouble,
  DirectLeftRightWrapper,
  DirectUserWrap,
  DirectId,
  DirectLabel,
  DirectsVR,
  DirectsWrapper,
  VolumesInfo,
  VolumesDate,
  VolumesDesktop,
  VolumesUser,
  GenerationsDesktop,
  GenerationsDate,
  GenerationsInfo,
  Separator,
  SearchContainer,
  InfoTeamContainer,
  Collapsable,
  Row,
  SeparatorRow,
  RowTitle,
  RowTitleCountry,
  RowCountry,
  InfoTeamName,
  Arrow,
  CustomerContainer,
  ModalHeader,
  HeaderTitle,
  ProfilePicture,
  InfoTeam,
  TeamContainer,
  DivFade,
  FadeWrap,
  OrderTitle,
  RowDate
};
