import axios from 'axios';
import React, { useState } from 'react';
import { AuthButton } from '../../styles/stylesStyled';
import { ReferralComp } from './SecurityCodeStyled';
import { useDispatch, useSelector } from 'react-redux';

import ReactCodeInput from 'react-code-input';
import { P2, P3 } from '../../styles/FontStyles';
import {
  AuthLayoutTitle,
  Flexed,
  SecurityCodeWrapper
} from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  SuccessToast,
  ErrorToast
} from '../../components/common/Toasts/Toasts';
import Spinner from '../../components/common/Spinner';
import { signInComplete } from '../../actions/authActions';

const API_URL = process.env.REACT_APP_API_URL;

const SecurityCode = ({ history }) => {
  const [code, setCode] = useState('');
  const [code2Fa, setCode2Fa] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const dispatch = useDispatch();
  const {
    user,
    signInToken,
    is2FARequired,
    isEmailCodeVerificationRequired,
    loading
  } = useSelector((state) => state.userInfo);

  const onHandleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      signInComplete(signInToken, code, code2Fa, is2FARequired, history)
    );
  };

  const sendCode = async () => {
    try {
      setIsLoading(true);
      await axios.post(`${API_URL}/authorization/signin/email/code`, {
        signInToken
      });

      SuccessToast('Code sent successfully');
      setIsLoading(false);
      setIsCodeSent(true);
    } catch (error) {
      setIsLoading(false);
      ErrorToast(error.response.data.error);
    }
  };

  return (
    <>
      {loading || isLoading ? (
        <Spinner fullScreen={true} solid={false} />
      ) : (
        <ReferralComp>
          <AuthLayoutTitle>SECURITY VERIFICATION</AuthLayoutTitle>

          <form onSubmit={onHandleSubmit}>
            {isEmailCodeVerificationRequired && (
              <>
                {' '}
                <Flexed between>
                  <P2 bold color={color.colors.neutral700}>
                    Email verification code
                  </P2>
                  <P2
                    bold
                    style={{ cursor: 'pointer' }}
                    color={color.colors.primary900}
                    onClick={() => sendCode()}>
                    Send Code
                  </P2>
                </Flexed>
                <SecurityCodeWrapper>
                  <ReactCodeInput
                    fields={7}
                    value={code}
                    onChange={setCode}
                    type={'number'}
                  />
                </SecurityCodeWrapper>
                <P3 color={color.colors.neutral500} mb='10'>
                  {isCodeSent &&
                    `Enter the 7 digit code sent to ${user?.email}`}
                </P3>
              </>
            )}

            {is2FARequired && (
              <div>
                <Flexed between>
                  <P2 bold color={color.colors.neutral700}>
                    2FA verification code
                  </P2>
                </Flexed>
                <SecurityCodeWrapper is2Fa>
                  <ReactCodeInput
                    fields={6}
                    value={code2Fa}
                    onChange={setCode2Fa}
                    type={'number'}
                  />
                </SecurityCodeWrapper>

                <P3 color={color.colors.neutral500} mb='10'>
                  Enter the 6 digit code from your 2FA authenticator
                </P3>
              </div>
            )}
            <AuthButton
              opacity={is2FARequired ? code2Fa !== '' : code !== ''}
              disabled={is2FARequired ? code2Fa === '' : code === ''}>
              Confirm
            </AuthButton>
          </form>
        </ReferralComp>
      )}
    </>
  );
};

export default SecurityCode;
