import React from 'react';
import { useTranslation } from 'react-i18next';
import notice from '../../../assets/icons/backOffice/Earnings/notice.svg';

import ulxIcon from '../../../assets/icons/backOffice/ULX.svg';

import { ErrorToast } from '../../../components/common/Toasts/Toasts';
import { InputBackOffice } from '../../../components/common/InputBackoffice';
import { formatUSD, ULX, USDT } from '../../../helpers/helperFunctions';
import { P3 } from '../../../styles/FontStyles';
import { Button } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { Icon, IconWithdrawWallet } from '../../../styles/Icons';
import {
  BankCard,
  FlexBetween,
  FlexCenter
} from '../../../styles/stylesStyled';
import { SmallTxt, Subtitle, Title } from './WithdrawStyled';

export const WithdrawInitial = ({
  setStep,
  userWallets,
  amount,
  setAmount,
  selected,
  availableBalance
}) => {
  const { t } = useTranslation('earnings');

  const digit = /^(?=.*?[0-9])/;

  return (
    <div style={{ marginTop: '8vh' }}>
      <FlexCenter style={{ flexDirection: 'column' }}>
        <Title>
          {ULX} {t('withdraw_part.initial.title')}
        </Title>

        <Subtitle style={{ textAlign: 'center' }}>
          {t('withdraw_part.initial.subtitleULX', { USDT: 'wULX' })}
        </Subtitle>
        <InputBackOffice
          placeholder={t('withdraw_part.initial.witdhrawal_amount_placeholder')}
          icon={ulxIcon}
          style={{ marginBottom: 8 }}
          onChange={(e) => {
            setAmount(e.replace(/[.,\s\D]/g, ''));
          }}
          pattern='[0-9]'
          value={amount}
        />
        <FlexBetween style={{ width: '100%', marginBottom: 12 }}>
          <SmallTxt>
            {t('withdraw_part.initial.balance')}: {formatUSD(availableBalance)}{' '}
            {ULX}
          </SmallTxt>
          <SmallTxt>{t('withdraw_part.initial.feeULX')}</SmallTxt>
        </FlexBetween>
        <BankCard>
          <div>
            <div>
              {userWallets?.length > 0 ? (
                ''
              ) : (
                <div style={{ display: 'flex' }}>
                  <img src={notice} alt='card' />
                  {t('withdraw_part.initial.add_address_p1')}

                  <br />
                  {t('withdraw_part.initial.add_address_p2', { USDT: USDT })}
                </div>
              )}
            </div>
            <FlexCenter>
              {userWallets?.length > 0 && (
                <Icon mr='10' color={color.colors.neutral900}>
                  <IconWithdrawWallet />
                </Icon>
              )}

              <P3
                color={color.colors.neutral900}
                bold
                style={{ wordBreak: 'break-all' }}>
                {userWallets?.length > 0 && userWallets[selected]?.address}
              </P3>
            </FlexCenter>
          </div>
          <Button
            ml='10'
            w='auto'
            h='36px'
            onClick={() => {
              userWallets?.length > 0 ? setStep(4) : setStep(5);
            }}>
            <span>
              {userWallets?.length > 0
                ? t('withdraw_part.initial.change')
                : t('withdraw_part.initial.add')}
            </span>
          </Button>
        </BankCard>
        <Button
          maxWidth='200px'
          onClick={() => {
            if (!amount) {
              ErrorToast(t('withdraw_part.initial.err_amount_required'));
            } else if (userWallets?.length === 0) {
              ErrorToast(t('withdraw_part.initial.err_address_required'));
            } else if (!amount.match(digit)) {
              ErrorToast(t('withdraw_part.initial.err_number_required'));
            }
            // else if (availableBalance) {
            //   ErrorToast(t('withdraw_part.initial.err_no_enough_usdt'));
            // }
            else {
              setStep(2);
            }
          }}>
          {t('withdraw_part.initial.withdraw')}
        </Button>
      </FlexCenter>
    </div>
  );
};
