import axios from 'axios';
import {
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL
} from '../constants/bankConstants';

import {
  GET_USER_WALLETS_REQUEST,
  GET_USER_WALLETS_SUCCESS,
  GET_USER_WALLETS_FAIL,
  CREATE_USER_WALLET_REQUEST,
  CREATE_USER_WALLET_SUCCESS,
  CREATE_USER_WALLET_FAIL
} from '../constants/userWalletsConstants';

import { CLEAR_ALERT } from '../constants/errorConstants';

const API_URL = process.env.REACT_APP_API_URL;

export const createUserWallet =
  (address, setAddress, setStep, isTrue, label, setLabel) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_USER_WALLET_REQUEST });

      const { userInfo } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token.accessToken}`
        }
      };

      const { data } = await axios.post(
        `${API_URL}/user/wallet`,
        { address, label },
        config
      );

      dispatch({
        type: CREATE_USER_WALLET_SUCCESS,
        payload: data.data.userWallet
      });

      setAddress('');
      setLabel('');

      if (isTrue) {
        setStep(4);
      } else {
        setStep(3);
      }
    } catch (error) {
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
      dispatch({
        type: CREATE_USER_WALLET_FAIL,
        payload: error.response && error.response.data
      });
    }
  };

export const getWallets = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_WALLETS_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    const { data } = await axios.get(`${API_URL}/user/wallet`, config);

    dispatch({
      type: GET_USER_WALLETS_SUCCESS,
      payload: data.data.userWallets.sort((a, b) => {
        return b.id - a.id;
      })
    });
  } catch (error) {
    dispatch({
      type: GET_USER_WALLETS_FAIL,
      payload: error.response && error.response.data
    });
  }
};

export const deleteWallet = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_ACCOUNT_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    await axios.delete(`${API_URL}/user/wallet/${id}`, config);

    dispatch({
      type: DELETE_ACCOUNT_SUCCESS,
      payload: id
    });
  } catch (error) {
    dispatch({
      type: DELETE_ACCOUNT_FAIL,
      payload: error.response && error.response.data
    });
  }
};
