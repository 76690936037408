import React from 'react';

import { View } from './WelcomeStyled';

import PackagesList from '../Packages/PackagesList';

const WelcomePackages = ({ location }) => {
  return (
    <View>
      <PackagesList location={location} />
    </View>
  );
};

export default WelcomePackages;
