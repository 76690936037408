export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAIL = 'GET_INVOICES_FAIL';

export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL';

export const GET_ACCOUNTING_WITHDRAWALS_REQUEST =
  'GET_ACCOUNTING_WITHDRAWALS_REQUEST';
export const GET_ACCOUNTING_WITHDRAWALS_SUCCESS =
  'GET_ACCOUNTING_WITHDRAWALS_SUCCESS';
export const GET_ACCOUNTING_WITHDRAWALS_FAIL =
  'GET_ACCOUNTING_WITHDRAWALS_FAIL';
