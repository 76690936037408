import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { Desktop, Mobile } from '../../App';
import { SidebarModal } from '../../components/common/Sidebar/Sidebar';
import {
  ChooseRankImage,
  formatUSDNoDecimals
} from '../../helpers/helperFunctions';
import { CaptionText, H1, H4, P1, P3 } from '../../styles/FontStyles';
import { Button, Flexed } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { FlexCenter } from '../../styles/stylesStyled';
import { Circle, MavieRank, RankImage, RankStatus } from './RankingStyled';
import { ClaimReward } from './ClaimReward';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MARKETPLACE } from '../../routes/routes';
import { RewardCounter } from './RewardCounter';
import { useDispatch, useSelector } from 'react-redux';
import { getRanking } from './Ranking.actions';
import Spinner from '../../components/common/Spinner';
import { AnimatedDashboard } from '../../components/animation/AnimatedDashboard';

export const Ranking = () => {
  const dispatch = useDispatch();
  const { ranking, loading } = useSelector((state) => state.ranking);
  const [isRewardOpen, setIsRewardOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedRank, setSelectedRank] = useState(null);
  const [selected, setSelected] = useState(null);
  const history = useHistory();
  const { t } = useTranslation('ranking');

  useEffect(() => {
    dispatch(getRanking());
  }, [dispatch]);

  const showTime = (reward) => {
    const date = new Date(reward?.proRewardTimeLimit);
    return date.getTime();
  };

  const handleClaim = (rank) => {
    setStep(1);
    setIsRewardOpen(!isRewardOpen);
    setSelectedRank(rank);
  };

  if (loading) return <Spinner fullScreen={true} />;

  return (
    <AnimatedDashboard>
      {ranking?.map((rank, i) => {
        return (
          <MavieRank
            key={i}
            isNext={rank.isFutureRank}
            id={rank.name}
            isActive={rank.isRankStatusActive}>
            <RankImage
              image={ChooseRankImage(rank.name)}
              isActive={rank.isRankStatusActive}>
              <Desktop>
                <H1 color={color.colors.neutral0}>{rank.name}</H1>
              </Desktop>
              <Mobile>
                <H4 color={color.colors.neutral0}>{rank.name}</H4>
              </Mobile>
              {!rank.isFutureRank && (
                <RankStatus>
                  <Desktop>
                    <CaptionText color={color.colors.neutral50}>
                      Current Rank Status{' '}
                    </CaptionText>
                  </Desktop>

                  <FlexCenter>
                    <Desktop>
                      <P3 bold color={color.colors.neutral50}>
                        {rank.isRankStatusActive ? 'ACTIVE' : 'INACTIVE'}
                      </P3>
                    </Desktop>
                    <Mobile>
                      <CaptionText bold color={color.colors.neutral50}>
                        {rank.isRankStatusActive ? 'ACTIVE' : 'INACTIVE'}
                      </CaptionText>
                    </Mobile>
                    <Circle
                      color={
                        rank.isRankStatusActive
                          ? color.colors.success500
                          : color.colors.error500
                      }
                    />
                  </FlexCenter>
                </RankStatus>
              )}
            </RankImage>
            <div>
              <Flexed center between rowSM alignStartSM>
                <Flexed
                  center
                  gap='20px 80px'
                  rowSM
                  alignStartSM
                  gapSM='20px'
                  wrap>
                  {/* <div>
                      <CaptionText mb='4' color={color.colors.neutral500} caps>
                        Team BONUS weekly cAP
                      </CaptionText>
                      <P1 bold>
                        {formatUSDNoDecimals(rank.weekCapRuleLimit)} USDT /{' '}
                        {t('week')}
                      </P1>
                    </div> */}

                  <div>
                    <CaptionText mb='4' color={color.colors.neutral500} caps>
                      Rewards
                    </CaptionText>
                    <P1 bold color={color.colors.neutral700}>
                      {rank.rewards.length > 0
                        ? rank.rewards.map((reward, i) => {
                            if (
                              reward.reward.type === 2 &&
                              reward.reward.amount
                            ) {
                              return (
                                <div key={i}>
                                  <P1 bold>
                                    {formatUSDNoDecimals(reward.reward.amount)}{' '}
                                    USDT
                                  </P1>
                                </div>
                              );
                            } else
                              return (
                                <P1 key={i} bold>
                                  {reward.reward.name}
                                </P1>
                              );
                          })
                        : '-'}
                    </P1>
                  </div>

                  {rank.proRewardTimeLimit && (
                    <div>
                      <P1 bold color={color.colors.secondary100}>
                        <CaptionText
                          mb='4'
                          color={color.colors.neutral500}
                          caps>
                          PRO rewards
                        </CaptionText>
                        {rank.rewards.map((reward, i) => {
                          if (
                            reward.reward.type === 2 &&
                            reward.reward.proAmount
                          ) {
                            return (
                              <P1
                                bold
                                color={color.colors.secondary100}
                                key={i}>
                                {formatUSDNoDecimals(reward.reward.proAmount)}{' '}
                                USDT
                              </P1>
                            );
                          } else return null;
                        })}
                      </P1>

                      <Flexed>
                        <P1 bold color={color.colors.secondary100}>
                          TIME LEFT:{' '}
                        </P1>
                        <RewardCounter timestamp={showTime(rank)} />
                      </Flexed>
                    </div>
                  )}
                </Flexed>

                <Desktop>
                  {rank.name !== 'Qualified Partner' && (
                    <Flexed>
                      <Button
                        w='215px'
                        disabled={!rank.canClaimReward}
                        onClick={() => handleClaim(rank)}>
                        {rank.isRewardAlreadyClaimed
                          ? t('claimed_reward')
                          : t('claim_reward')}
                      </Button>
                    </Flexed>
                  )}

                  {rank.shouldDisplayActivateRankStatus && (
                    <Button
                      w='215px'
                      mt='10'
                      onClick={() => history.push(MARKETPLACE)}>
                      Activate Your Rank Status
                    </Button>
                  )}
                </Desktop>
              </Flexed>
              <Mobile>
                {rank.name !== 'Qualified Partner' && (
                  <Button
                    w='100%'
                    mt='20'
                    disabled={!rank.canClaimReward}
                    onClick={() => handleClaim(rank)}>
                    {rank.isRewardAlreadyClaimed
                      ? t('claimed_reward')
                      : t('claim_reward')}
                  </Button>
                )}
                {rank.shouldDisplayActivateRankStatus && (
                  <Button
                    w='215px'
                    mt='10'
                    onClick={() => history.push(MARKETPLACE)}>
                    Activate Your Rank Status
                  </Button>
                )}
              </Mobile>
            </div>
          </MavieRank>
        );
      })}
      <SidebarModal
        isOpen={isRewardOpen}
        setOpen={setIsRewardOpen}
        title='NEW RANK ACHIEVED'>
        <ClaimReward
          isOpen={isRewardOpen}
          setIsOpen={setIsRewardOpen}
          step={step}
          setStep={setStep}
          selectedRank={selectedRank}
          selected={selected}
          setSelected={setSelected}
        />
      </SidebarModal>
    </AnimatedDashboard>
  );
};
