import React from 'react';

import { Button } from '../../../styles/GlobalComponents';

import { VirtualOfficePlan } from '../../../components/common/VirtualOfficeSubscription/VirtualOfficePlan';

export const VirtualOfficeSubscription = ({
  selected,
  setSelected,
  setStep
}) => {
  return (
    <div>
      <VirtualOfficePlan />

      <Button
        mb={40}
        onClick={() => {
          setStep(2);
          setSelected(selected);
        }}>
        Continue with Selection
      </Button>
    </div>
  );
};
