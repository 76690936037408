import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { BackOfficeNumbersWrap, BackOfficeNumbers } from './DashboardStyled';
import { IconTitle } from './common';

export const NumberOfMembers = ({ backOffice, count }) => {
  const { t } = useTranslation('dashboard');
  const joinedOn = moment(backOffice?.user?.dateCreated)
    .format('DDMMYYYY')
    .split('');

  const joinedAfter = String(count).padStart(8, '0').split('');

  return (
    <BackOfficeNumbersWrap>
      <div>
        <IconTitle title={t('number_of_members.left_title')} />
        <div>
          {joinedOn.map((num, index) => {
            return <BackOfficeNumbers key={index}>{num}</BackOfficeNumbers>;
          })}
        </div>
      </div>
      <div>
        <IconTitle title={t('number_of_members.rigth_title')} />
        <div>
          {joinedAfter.map((num, index) => {
            return (
              <BackOfficeNumbers orange key={index}>
                {num}
              </BackOfficeNumbers>
            );
          })}
        </div>
      </div>
    </BackOfficeNumbersWrap>
  );
};
