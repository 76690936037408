import styled from 'styled-components';
import { SomeUserNameInitials } from '../helpers/helperFunctions';
import { useWindowWide } from '../utils/wideScreen';
import { H2, H3, H5 } from './FontStyles';
import { color } from './GlobalStyles';
import { Icon, IconArrowPolygon, IconChevron } from './Icons';
import { DisableSelection } from './stylesStyled';

export const Box = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.neutral50};
  ${({ theme, blue }) => blue && `background:${theme.colors.secondaryBlue}`};
  ${({ theme, gray }) => gray && ` background:${theme.colors.neutral100}`};
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  border: 1px solid ${({ theme }) => theme.colors.neutral100};
  border-radius: 16px;
  padding: ${({ theme }) => theme.sizes.s5}px;
  margin-bottom: ${({ theme }) => theme.sizes.s6}px;

  ${({ p }) => p && `padding: ${p}`};
  ${({ m }) => (m ? `margin: ${m};` : '')}
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};

  @media (max-width: 800px) {
    padding: ${({ theme }) => theme.sizes.s4}px;
    margin-bottom: ${({ theme }) => theme.sizes.s5}px;

    ${({ pSM }) => pSM && `padding: ${pSM}`};
    ${({ mSM }) => (mSM ? `margin: ${mSM};` : '')}
    ${({ mtSM }) => mtSM && `margin-top: ${mtSM}px`};
    ${({ mbSM }) => mbSM && `margin-bottom: ${mbSM}px`};
    ${({ mlSM }) => mlSM && `margin-left: ${mlSM}px`};
    ${({ mrSM }) => mrSM && `margin-right: ${mrSM}px`};
  }

  @media (max-width: 345px) {
    padding: ${({ theme }) => theme.sizes.s3}px;
  }
`;

export const Hr = styled.div`
  background: ${(props) => props.theme.colors.neutral100};
  height: 1px;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '40px 0')};
`;

export const SectionSeparator = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '20px 0')};
  width: 100%;
  height: 1px !important;
  background: ${(props) => props.theme.colors.secondary100}30;
  flex: none;
`;

export const SectionSeparatorVertical = styled.div`
  flex: none;
  width: 1px !important;
  height: auto;
  margin: ${(props) => (props.margin ? props.margin : '0 50px')};
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    ${(props) => props.theme.colors.secondary100}30 50%,
    rgba(0, 0, 0, 0) 100%
  ); */
  background: ${(props) => props.theme.colors.secondary100}30;
`;

export const SecurityCodeWrapper = styled.div`
  .react-code-input {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    gap: 7px !important;
    margin: 10px 0 !important;
    font-family: 'Rubik' !important;
    @media (max-width: 345px) {
      gap: 5px !important;
    }

    > * {
      flex-grow: 1 !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      height: 60px !important;
      padding-left: none !important;
      background-color: none !important;
      color: ${({ theme }) => theme.colors.primary900} !important;
      border: 2px solid ${({ theme }) => theme.colors.neutral400} !important;
      border-radius: 8px !important;
      font-size: 24px !important;
      text-align: center !important;
      box-shadow: none !important;
      margin: 0 !important;
      padding: 0 !important;
      font-family: 'Rubik' !important;

      :focus {
        border: 2px solid ${({ theme }) => theme.colors.primary900} !important;
      }

      ${({ is2Fa }) =>
        is2Fa &&
        `
        :nth-child(3) {
        margin-right: 20px !important;
      @media (max-width: 345px) {
        margin-right: 15px !important;

      }
      }
      `}

      @media (max-width: 345px) {
        font-size: 20px !important;
        height: 50px !important;
      }
    }
  }
`;

//////////////
// BUTTON

export const Button = styled.button`
  border: none;
  font-size: 15px;
  color: #fff;
  height: 44px;

  width: ${(props) => (props.w ? props.w : '100%')};
  cursor: pointer;

  font-size: ${(props) => props.theme.buttonFontSize};

  color: ${(props) =>
    props.negative
      ? props.theme.buttonNegativeFontColor
      : props.theme.buttonFontColor};

  background-color: ${(props) =>
    props.negative
      ? props.theme.buttonNegativeBackground
      : props.theme.buttonBackground};
  border: ${(props) =>
    props.negative ? props.theme.buttonNegativeBorder : 'none'};

  ${(props) =>
    props.disabled && `color: ${props.theme.buttonDisabledFontColor};`}

  ${(props) =>
    props.disabled && `background: ${props.theme.buttonDisabledBackground};`}

${(props) => props.disabled && `opacity: 0.5;`}

  ${(props) => props.disabled && `border: ${props.theme.buttonDisabledBorder};`}

  ${(props) => props.disabled && `cursor: not-allowed`};

  ${(props) => props.fs && `font-size: ${props.fs}`};

  ${(props) => props.darkFont && `color: ${props.theme.colors.neutral50};`}

  /* opacity: ${(props) => (!props.opacity ? '1' : '0.5')}; */
  /* cursor: ${(props) => (!props.opacity ? 'default' : 'pointer')}; */
  border-radius: ${(props) => props.theme.buttonBorderRadius};

  height: ${(props) => props.h};

  /* border: none; */

  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;

  max-width: ${(props) => props.maxWidth};

  ${({ condensed }) =>
    condensed &&
    `
    height: 33px; 

  `}

  @media (max-width: 800px) {
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}px;
    margin-bottom: ${(props) => props.mbSM}px;
    margin-left: ${(props) => props.mlSM}px;
    margin-right: ${(props) => props.mrSM}px;

    max-width: ${(props) => props.maxWidthSM};
    width: ${(props) => (props.wSM ? props.wSM : '100%')};
  }

  ${({ textButton, theme }) =>
    textButton &&
    `
    background-color: rgba(0,0,0,0);
    color: ${theme.colors.primary900};
    font-weight: 500;
    border: none;
  `}

  transition: transform 0.1s ease, background-color 0.3s ease, border 0.3s ease,
    color 0.3s ease;

  :active {
    transform: translateY(3px);
  }
  > * {
    ${DisableSelection}
  }
`;

export const BrowseFilesButton = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 15px;
  color: #fff;
  height: 44px;
  width: 100%;
  cursor: pointer;

  font-size: ${(props) => props.theme.buttonFontSize};

  color: ${(props) =>
    props.negative
      ? props.theme.buttonNegativeFontColor
      : props.theme.buttonFontColor};

  background-color: ${(props) =>
    props.negative
      ? props.theme.buttonNegativeBackground
      : props.theme.buttonBackground};

  border: ${(props) =>
    props.negative
      ? props.theme.buttonNegativeBorder
      : props.theme.buttonBorder};

  ${(props) =>
    props.disabled && `color: ${props.theme.buttonDisabledFontColor};`}

  ${(props) =>
    props.disabled && `background: ${props.theme.buttonDisabledBackground};`}

  ${(props) => props.disabled && `border: ${props.theme.buttonDisabledBorder};`}

  ${(props) => props.disabled && `cursor: not-allowed`};

  ${(props) => props.fs && `font-size: ${props.fs}`};

  /* opacity: ${(props) => (!props.opacity ? '1' : '0.5')}; */
  /* cursor: ${(props) => (!props.opacity ? 'default' : 'pointer')}; */
  border-radius: ${(props) => props.theme.buttonBorderRadius};

  height: ${(props) => props.h};

  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;

  max-width: ${(props) => props.maxWidth};

  @media (max-width: 800px) {
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}px;
    margin-bottom: ${(props) => props.mbSM}px;
    margin-left: ${(props) => props.mlSM}px;
    margin-right: ${(props) => props.mrSM}px;

    max-width: ${(props) => props.maxWidthSM};
  }

  transition: transform 0.1s ease, background-color 0.3s ease, border 0.3s ease,
    color 0.3s ease;

  :active {
    transform: translateY(3px);
  }
  > * {
    ${DisableSelection}
  }
`;

//////////////
// PROGRESS BAR

export const ProgressBar = styled.div`
  width: 100%;
  padding: 2px 3px;
  background: ${(props) => props.theme.colors.neutral50};
  border: 1px solid rgba(45, 45, 45, 0.02);
  box-sizing: border-box;
  border-radius: 13px;
  div {
    width: ${(props) => (props.percentage ? props.percentage : 0)};
    height: 8px;
    background: ${(props) => props.theme.colors.primary900};
    border-radius: 6px;
  }
`;

// PROGRESS BAR with percentage

export const ProgressBarWithPercentage = styled.div`
  width: 100%;
  /* padding: 2px 3px; */
  background: ${(props) => props.theme.colors.neutral100};
  border: 1px solid rgba(45, 45, 45, 0.02);
  box-sizing: border-box;
  border-radius: 13px;
  margin-bottom: 20px;
  height: 12px;
  > div {
    position: relative;
    width: ${(props) =>
      props.percentage ? `${props.percentage + '%'}` : '0%'};
    height: 12px;
    background: ${(props) => props.color};
    border-radius: 6px;
    > div {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: -20px;
      top: -7px;
      border: 2px solid ${(props) => props.color};
      box-shadow: ${(props) => props.color};
      background: ${(props) => props.theme.colors.neutral50};
      color: ${(props) => props.color};
      border-radius: 9px;
      /* width: 40px; */
      padding: 0 5px;
      height: 26px;
      font-weight: 500;
      font-size: 10px;
      line-height: 140%;
    }
  }
`;

export const ArrowPolygon = ({ direction, customColor }) => {
  return (
    <ArrowPolygonStyle direction={direction}>
      <Icon color={customColor ?? color.colors.secondary100}>
        <IconArrowPolygon />
      </Icon>
    </ArrowPolygonStyle>
  );
};

export const ArrowChevron = ({ direction, customColor }) => {
  return (
    <ArrowPolygonStyle direction={direction}>
      <Icon color={customColor ?? color.colors.secondary100}>
        <IconChevron />
      </Icon>
    </ArrowPolygonStyle>
  );
};

export const ArrowPolygonStyle = styled.div`
  transition: transform 0.3s ease;
  transform: ${(props) => {
    if (props.direction === 'up') {
      return 'rotate(180deg);';
    } else if (props.direction === 'down') {
      return '';
    } else if (props.direction === 'left') {
      return 'rotate(90deg);';
    } else if (props.direction === 'right') {
      return 'rotate(-90deg);';
    }
  }};
`;

/////////////////////////
// SELECT INPUT PLACEHOLDER

export const Select = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.inputBackground};
  border-radius: ${(props) => props.theme.buttonBorderRadius};
  width: ${(props) => (props.w ? props.w : '100%')};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};

  height: ${(props) => props.theme.inputHeight};
  padding: ${(props) => (props.padding ? props.padding : '0 20px')};
  border: ${(props) => props.theme.inputBorder};
  box-shadow: ${(props) => props.theme.inputShadow};

  font-size: ${(props) => props.theme.inputFontSize};
  color: ${(props) => props.theme.inputFontColor};

  margin: 0 0 20px 0;
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
`;

///////////////
//TABS & TABS BUTTON

export const Tabs = styled.div`
  overflow-y: auto;
  display: flex;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TabSelectButton = styled.div`
  > div {
    background: ${(props) =>
      props.selected
        ? props.light
          ? props.theme.tabs.backgroundSelectedLight
          : props.theme.tabs.backgroundSelected
        : props.theme.tabs.background};
    border-radius: 100px;
    padding: 0 12px;
    height: 35px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
  }
  background: ${(props) =>
    props.selected
      ? props.theme.tabs.borderColorSelected
      : props.theme.tabs.borderColor};
  padding: 1px;

  color: ${(props) =>
    props.selected ? props.theme.tabs.colorSelected : props.theme.tabs.color};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 100px;
  margin-right: 20px;
  margin-bottom: 20px;
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};

  ${(props) => props.m && `margin: ${props.m}`};

  cursor: pointer;
  transition: all 0.2s ease;

  @media (max-width: 800px) {
    margin-right: 8px;
  }
`;

///////////////
//TABS & TABS BUTTON
export const ToggleTabs = styled.div`
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.toggleTabs.wrapper};
  border-radius: 8px;
  margin-bottom: 20px;
  overflow-x: auto;
  z-index: 1;
`;

export const ToggleTabButton = styled.div`
  width: 100%;
  justify-content: center;
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  background: ${(props) => props.theme.toggleTabs.background};
  box-sizing: border-box;
  border-radius: 8px;
  color: ${(props) =>
    props.selected
      ? props.theme.toggleTabs.colorSelected
      : props.theme.toggleTabs.color};
  background: ${(props) =>
    props.selected ? props.theme.toggleTabs.backgroundSelected : 'none'};
  transition: all 0.2s ease;

  @media (max-width: 360px) {
    font-size: 14px;
  }
`;

export const AuthLayoutTitle = ({ children }) => {
  return (
    <H5 mb='30' center color={color.colors.neutral700}>
      {children}
    </H5>
  );
};

export const PageTitle = ({ children }) => {
  const wide = useWindowWide(800);
  if (wide) {
    return (
      <H2 m='20px 0' color={color.appLayout.pageTitleColor}>
        {children}
      </H2>
    );
  } else {
    return (
      <H3 m='20px 0' color={color.appLayout.pageTitleColor}>
        {children}
      </H3>
    );
  }
};

export const View = styled.div`
  margin: 20px 0;
  min-height: 640px;
  padding-bottom: 70px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    max-width: 1500px;
    padding: 0 100px;
  }

  @media only screen and (max-width: 1500px) and (min-width: 800px) {
    zoom: 80%;
  }
`;

////////////////
//USER IMAGE

export const ProfilePicture = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.size ? props.size : '102px')};
  width: ${(props) => (props.size ? props.size : '102px')};

  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  ); */

  /* -webkit-box-shadow: 0px 0px 0px 3px
    ${(props) => (props.color ? props.color : 'rgba(45, 45, 45, 0.05)')};
  box-shadow: 0px 0px 0px 3px
    ${(props) => (props.color ? props.color : 'rgba(45, 45, 45, 0.05)')}; */

  /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); */

  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  ${DisableSelection}/* border: 1.5px solid rgba(254, 254, 254, 0.3); */
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${DisableSelection};
`;

export const ProfileImagePlaceholder = styled.div`
  font-size: ${(props) => (props.font ? props.font : '102px')};
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    !props.disabled
      ? props.theme.colors.secondary100
      : props.theme.colors.neutral100};
  height: ${(props) => (props.size ? props.size : '102px')};
  width: ${(props) => (props.size ? props.size : '102px')};
  border-radius: 50%;

  @media (max-width: 800px) {
    font-weight: 600;
  }
`;

export const UserImageCircle = ({
  image,
  name,
  surname,
  size,
  margin,
  fontSize,
  avatarSize,
  avatar,
  disabled
}) => {
  return (
    <ProfilePicture
      size={size ? size : '56px'}
      style={{
        margin: margin
      }}>
      {image ? (
        <ProfileImage src={image} alt='Upload' />
      ) : (
        <ProfileImagePlaceholder
          disabled={disabled}
          size={size ? size : '56px'}
          font={fontSize}>
          {avatar ? (
            <Icon
              color={
                !disabled ? color.colors.neutral50 : color.colors.neutral400
              }>
              <svg
                width={avatarSize ?? '24px'}
                height={avatarSize ?? '25px'}
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.9999 12.0631C15.3138 12.0631 18.0002 9.3767 18.0002 6.06286C18.0002 2.74902 15.3138 0.0626221 11.9999 0.0626221C8.68608 0.0626221 5.99968 2.74902 5.99968 6.06286C5.99968 9.3767 8.68608 12.0631 11.9999 12.0631Z'
                  fill='#8B8CFE'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M12.0005 13.5626C7.99532 13.5626 0 15.5727 0 19.5628V22.5629C0 23.388 0.675027 24.063 1.50006 24.063H22.5009C23.3259 24.063 24.001 23.388 24.001 22.5629V19.5628C24.001 15.5727 16.0056 13.5626 12.0005 13.5626Z'
                  fill='#8B8CFE'
                />
              </svg>
            </Icon>
          ) : (
            SomeUserNameInitials(name ?? '-', surname ?? '-')
          )}
        </ProfileImagePlaceholder>
      )}
    </ProfilePicture>
  );
};

export const TierTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #8e8e8e;
  margin-top: 20px;
`;

export const PackageList = styled.div`
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 800px) {
    margin-bottom: 15px;
  }
`;
//////////////////

export const Flexed = styled.div`
  display: flex;
  ${(props) => props.between && 'justify-content: space-between'};
  ${(props) => props.justifyCenter && 'justify-content: center'};
  ${(props) => props.end && 'justify-content: end'};
  ${(props) => props.center && 'align-items:  center'};
  ${(props) => props.column && 'flex-direction: column'};
  ${(props) => props.gap && `gap:${props.gap}`};
  ${(props) => props.pointer && `cursor: pointer`};
  ${(props) => props.wrap && `flex-wrap: wrap`};
  ${(props) => props.grow && `flex-grow:${props.grow}`};
  ${(props) => props.evenly && `justify-content: space-evenly`};
  ${(props) => props.alignEnd && 'align-items:  flex-end'};

  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
  gap: ${(props) => props.gap}rem;
  width: ${(props) => props.width};

  ${(props) => props.position && 'position:' + props.position};

  @media (max-width: 800px) {
    ${(props) => props.rowSM && 'flex-direction: column'};
    ${(props) => props.alignStartSM && 'align-items: start'};
    ${(props) => props.gapSM && `gap: ${props.gapSM}`};
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}px;
    margin-bottom: ${(props) => props.mbSM}px;
    margin-left: ${(props) => props.mlSM}px;
    margin-right: ${(props) => props.mrSM}px;
    width: ${(props) => props.widthSM};
    max-width: ${(props) => props.maxWidthSM};
  }

  ${(props) =>
    props.desktop &&
    `
 @media (max-width: 800px) {
    display: none;
  }
  `}

  ${(props) =>
    props.mobile &&
    `
 @media (min-width: 800px) {
    display: none;
  }
  `}
`;

export const EarningBox = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.secondaryBlue};
  margin-bottom: 15px;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
`;

export const CookieOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.neutral50}e5;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const FloatingButton = styled.div`
  position: relative;
  z-index: 2;

  @media (max-width: 800px) {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 40px 20px 20px 20px;
    background: ${({ theme }) =>
      `linear-gradient( 0deg, ${theme.colors.neutral50} 40%, ${theme.colors.neutral50}00 100%)`};

    button {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`;

export const Table = styled.table`
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  background: ${(props) => props.theme.colors.neutral0};
  /* border: 1px solid ${({ theme }) => theme.colors.neutral100}; */
  width: 100%;
  margin-bottom: 24px;
  border-radius: 12px;
  border-collapse: collapse;
  border-style: hidden;
  overflow: hidden;

  thead tr th {
    text-transform: uppercase;
  }

  tbody tr td {
    border-collapse: collapse;
    border: 1px solid ${(props) => props.theme.colors.neutral400}80;
  }

  tbody tr:last-child td {
    box-shadow: none;
  }

  > * {
    border-collapse: collapse;
  }

  th {
    padding: 0;
    height: 60px;
  }

  td {
    height: 55px;
    padding: 0;
    text-align: center;
  }

  .colored {
    background-color: ${({ theme }) => theme.colors.primary900};
    > div {
      color: ${({ theme }) => theme.colors.neutral0};
    }
  }

  @media (max-width: 800px) {
    tbody tr td > div {
      font-size: 13px;
    }

    thead tr th > div {
      font-size: 14px;
    }
  }

  @media (max-width: 380px) {
    tbody tr td > div {
      font-size: 10px;
    }

    thead tr th > div {
      font-size: 12px;
    }
  }
`;

export const BoxMobile = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  background: ${(props) => props.theme.colors.neutral50};
  margin-bottom: 12px;
  border-radius: 12px;
  padding: 12px;
  width: 100%;
`;

export const GridDynamic = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${({ min }) => (min ? min : '200px')}, 2fr)
  );
`;

export const CheckIconBox = styled.div`
  width: ${(props) => (props.width ? props.width : '20px')};
  height: ${(props) => (props.height ? props.height : '20px')};
  background: ${(props) =>
    props.background
      ? props.theme.colors.neutral10
      : props.theme.colors.secondary100};
  border-radius: ${(props) => (props.radius ? props.props.radius : '50%')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

export const CircleIconWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : '45px')};
  height: ${(props) => (props.height ? props.height : '45px')};
  background: ${(props) => props.color};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => (props.mb ? props.mb : '0px')};
`;

export const Pill = styled.div`
  background: ${(props) => props.theme.colors.secondaryBlue};
  border-radius: 100px;
  padding: 10px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 140%;
  display: inline-flex;
`;

export const Collapsable = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${({ expanded, elementHeight }) =>
    expanded ? `${elementHeight}px` : '0px'};
  margin-top: ${(props) => (props.expanded ? '20px' : '0px')};
  overflow: hidden;
  transition: all 300ms;

  @media (min-width: 3500px) {
    margin-top: ${(props) => (props.expanded ? '40px' : '0px')};
  }

  @media (max-width: 800px) {
    margin-top: ${(props) => (props.expanded ? '10px' : '0px')};
  }
`;
