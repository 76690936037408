import React from 'react';
import { Desktop, Mobile } from '../../../App';
import { BACKOFFICE_NAME, globalPlans } from '../../../helpers/helperFunctions';
import { H3, H4, P1 } from '../../../styles/FontStyles';
import { Flexed } from '../../../styles/GlobalComponents';

import { Checkbox } from '../Checkbox/Checkbox';

import { VirtualOfficeSubscriptionStyled } from './VirtualOfficeSubscription.styled';

export const VirtualOfficePlan = () => {
  return (
    <div>
      <P1 bold mt={40} mb={20} caps>
        ADD {BACKOFFICE_NAME} SUBSCRIPTION:
      </P1>
      {globalPlans()
        .map((plan, i) => (
          <VirtualOfficeSubscriptionStyled key={i}>
            <Desktop>
              <div>
                <Flexed between center>
                  <Flexed>
                    <Checkbox margin='0 12px 0 0' size={38} checked={true} />
                    <Flexed center>
                      <P1 caps bold>
                        {plan.title}
                      </P1>
                    </Flexed>
                  </Flexed>
                  <Flexed alignEnd column>
                    <H3>${plan.price}</H3>
                  </Flexed>
                </Flexed>
              </div>
            </Desktop>

            <Mobile>
              <div>
                <Flexed between center>
                  <Flexed center>
                    <Checkbox margin='0 12px 0 0' size={38} checked={true} />
                    <div>
                      <H4>{plan.title}</H4>
                    </div>
                  </Flexed>
                  <Flexed alignEnd column>
                    <H4>${plan.price}</H4>
                  </Flexed>
                </Flexed>
              </div>
            </Mobile>
          </VirtualOfficeSubscriptionStyled>
        ))
        .reverse()}
    </div>
  );
};
