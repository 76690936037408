import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SuccessToast } from '../../components/common/Toasts/Toasts';
import { formatUSD, USDT } from '../../helpers/helperFunctions';
import { ORDERS } from '../../routes/routes';
import { H3, P3 } from '../../styles/FontStyles';
import { Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { Icon, IconCopy } from '../../styles/Icons';
import { SelectedUSDT, WelcomeContainer } from './PackagesStyled';

export const Success = ({ selectedPackage }) => {
  const { t } = useTranslation('packages');
  const history = useHistory();
  const { package: userPackage, currencyType } = useSelector(
    (state) => state.packagesList
  );

  return (
    <>
      <WelcomeContainer>
        {currencyType === 3 && (
          <H3 mb='5' color={color.colors.neutral900}>
            Staking HUB successfully purchased and activated
          </H3>
        )}

        {currencyType === 1 && userPackage?.networkType && (
          <>
            <P3 mb='8' mt='20' color={color.colors.neutral900}>
              {t('success.you_selected')}
              <br />
            </P3>
            <H3 mb='5' color={color.colors.neutral900}>
              {selectedPackage?.imageTitle}
            </H3>
            <P3 mb='8' mt='20' color={color.colors.neutral900}>
              {t('success.text1')}
              <br />
              <br />
              {t('success.text2')}
              <br />
              <br />
              {t('success.text3')}
            </P3>
          </>
        )}

        {currencyType === 2 && (
          <P3 mt='5' color={color.colors.neutral900}>
            {t('success.thank_you')}
          </P3>
        )}
        <br />

        {currencyType === 1 && userPackage?.networkType && (
          <div style={{ marginBottom: 20 }}>
            <P3 bold mb='8' color={color.colors.neutral900}>
              {t('success.payment_amount')}
            </P3>
            <SelectedUSDT>
              <div className='truncated'>
                <div>
                  {' '}
                  {formatUSD(selectedPackage?.imagePrice)} <span>{USDT}</span>
                </div>
              </div>
              <b>USDT</b>
            </SelectedUSDT>
          </div>
        )}

        {currencyType === 1 && userPackage?.networkType && (
          <div style={{ marginBottom: 20 }}>
            <P3 bold mb='8' color={color.colors.neutral900}>
              {t('success.network')}
            </P3>
            <SelectedUSDT>
              <div className='truncated'>
                <div>
                  {userPackage?.networkType === 1
                    ? 'Binance Smart Chain (BEP-20)'
                    : 'Tron Network (TRC-20)'}
                </div>
              </div>
            </SelectedUSDT>
          </div>
        )}

        {currencyType === 1 && userPackage?.networkType && (
          <P3 bold mb='8' color={color.colors.neutral900}>
            {t('success.wallet_address')}
          </P3>
        )}
        {currencyType === 1 && userPackage?.networkType && (
          <SelectedUSDT
            color={'#ffffff'}
            onClick={() => {
              navigator.clipboard.writeText(userPackage?.paymentAddress);

              {
                SuccessToast(t('success.copied'));
              }
            }}
            style={{ cursor: 'pointer' }}>
            <div className='truncated'>
              <div>{userPackage?.paymentAddress}</div>
            </div>

            <Icon ml='10' color={color.colors.neutral900}>
              <IconCopy />
            </Icon>
          </SelectedUSDT>
        )}

        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
          <Button onClick={() => history.push(ORDERS)} maxWidth='200px'>
            Close
          </Button>
        </div>
      </WelcomeContainer>
    </>
    // <>
    //   <WelcomeContainer>
    //     {message && <Alert message={message} icon={'success'} />}

    //     <H3 mb='5' color={color.colors.neutral900}>
    //       {!walletAddress?.address &&
    //         'Staking HUB successfully purchased and activated'}
    //     </H3>

    //     {walletAddress?.address ? (
    //       <>
    //         <P3 mb='8' mt='20' color={color.colors.neutral900}>
    //           {t('success.you_selected')}
    //           <br />
    //         </P3>
    //         <H3 mb='5' color={color.colors.secondary100}>
    //           {selectedPackage?.imageTitle}
    //         </H3>
    //         <P3 mb='8' mt='20' color={color.colors.neutral900}>
    //           {t('success.text1')}
    //           <br />
    //           <br />
    //           {t('success.text2')}
    //           <br />
    //           <br />
    //           {t('success.text3')}
    //         </P3>
    //       </>
    //     ) : (
    //       <P3 mt='5' color={color.colors.neutral900}>
    //         {t('success.thank_you')}
    //       </P3>
    //     )}
    //     <br />

    //     {walletAddress?.address && (
    //       <div style={{ marginBottom: 20 }}>
    //         <P3 bold mb='8' color={color.colors.neutral900}>
    //           {t('success.payment_amount')}
    //         </P3>
    //         <SelectedUSDT>
    //           <div className='truncated'>
    //             <div>
    //               {' '}
    //               {formatUSD(selectedPackage?.imagePrice)} <span>{USDT}</span>
    //             </div>
    //           </div>
    //           <b>USDT</b>
    //         </SelectedUSDT>
    //       </div>
    //     )}

    //     {walletAddress?.address && (
    //       <div style={{ marginBottom: 20 }}>
    //         <P3 bold mb='8' color={color.colors.neutral900}>
    //           {t('success.network')}
    //         </P3>
    //         <SelectedUSDT>
    //           <div className='truncated'>
    //             <div>
    //               {walletAddress?.networkType === 1
    //                 ? 'Binance smartchain (BEP-20)'
    //                 : 'Tron Network (TRC-20)'}
    //             </div>
    //           </div>
    //         </SelectedUSDT>
    //       </div>
    //     )}

    //     {walletAddress?.address && (
    //       <P3 bold mb='8' color={color.colors.neutral900}>
    //         {t('success.wallet_address')}
    //       </P3>
    //     )}
    //     {walletAddress?.address && (
    //       <SelectedUSDT
    //         color={'#ffffff'}
    //         onClick={() => {
    //           navigator.clipboard.writeText(walletAddress?.address);
    //           setMessage(t('success.copied'));
    //           setTimeout(() => {
    //             setMessage('');
    //           }, 3000);
    //         }}
    //         style={{ cursor: 'pointer' }}
    //       >
    //         <div className='truncated'>
    //           <div>{walletAddress?.address}</div>
    //         </div>

    //         <Icon ml='10' color={color.colors.neutral900}>
    //           <IconCopy />
    //         </Icon>
    //       </SelectedUSDT>
    //     )}

    //     <div
    //       style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}
    //     >
    //       <Button onClick={() => history.push('/')} maxWidth='200px'>
    //         Close
    //       </Button>
    //     </div>
    //   </WelcomeContainer>
    // </>
  );
};
