import React from 'react';
import 'react-phone-number-input/style.css';

import { Link } from 'react-router-dom';
import Spinner from '../../components/common/Spinner';
import { DASHBOARD } from '../../routes/routes';
import { AuthLayoutTitle } from '../../styles/GlobalComponents';
import { AuthButton, PhoneComp } from '../../styles/stylesStyled';

const VerifyNewEmailSuccess = ({ match }) => {
  const code = match;

  console.log(code);

  // const dispatch = useDispatch();

  const loading = false;

  // const verifyEmail = (e) => {
  //   e.preventDefault();
  //   dispatch(verifyNewEmailAction(code, history));
  // };

  if (loading) return <Spinner fullScreen={true} />;

  return (
    <div>
      <PhoneComp>
        <AuthLayoutTitle>Your email has been changed</AuthLayoutTitle>
        <Link to={DASHBOARD}>
          <AuthButton opacity={'true'} type='submit'>
            Continue to dashboard
          </AuthButton>
        </Link>
      </PhoneComp>
    </div>
  );
};

export default VerifyNewEmailSuccess;
