import React from 'react';

import {
  ButtonWrapper,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ReferralComp
} from '../ReferralDesktop/ReferralStyled';

const ReferralModal = () => {
  return (
    <ReferralComp>
      <ModalHeader>
        <div style={{ width: 20, height: 20 }}></div>
        <ModalTitle>Get Your sponsor code 🦄</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <b>You can participate by invitation only</b>{' '}
        <div style={{ margin: '12px 0 40px 0' }}>
          You can find an invitation code on instagram profiles from one of our
          GIVVO partners
        </div>
        <a
          href='https://www.instagram.com/explore/tags/givvopartner/'
          target='_blank'
          rel='noreferrer'></a>
        <ButtonWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flexWrap: 'wrap',
              marginBottom: 20,
              marginTop: 40
            }}>
            <div
              style={{
                background: '#FF5700',
                width: '100%',
                height: 52,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                borderRadius: 8
              }}>
              <a
                href='https://www.instagram.com/explore/tags/givvopartner/'
                target='_blank'
                rel='noreferrer'>
                <div style={{ color: '#fff', fontWeigth: 500 }}>
                  Go to instagram #givvopartner
                </div>
              </a>
            </div>
          </div>
        </ButtonWrapper>
      </ModalBody>
    </ReferralComp>
  );
};

export default ReferralModal;
