import styled from 'styled-components';
import { CaptionText, P3 } from '../../styles/FontStyles';
import { Flexed } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { Icon, IconInfo } from '../../styles/Icons';
import { Checkbox } from './Checkbox/Checkbox';

const ComponentStyled = styled.div`
  min-height: 24px;
  margin-bottom: 12px;
`;

export const CheckText = ({
  text,
  info,
  x,
  checked = true,
  onClick,
  smallText,
  checkColor
}) => {
  return (
    <ComponentStyled>
      <Flexed center>
        <Checkbox
          size={24}
          checked={checked}
          margin='0 12px 0 0'
          x={x}
          onClick={onClick}
          checkColor={checkColor}
        />
        <P3 color={color.colors.neutral500} semiBold>
          {text}
        </P3>
      </Flexed>

      {smallText && (
        <CaptionText ml='36' color={color.colors.neutral500}>
          {smallText}
        </CaptionText>
      )}
      {info && (
        <Icon ml='8'>
          <IconInfo />
        </Icon>
      )}
    </ComponentStyled>
  );
};
