import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import { addDocumentsImage } from '../../actions/documentActions';
import check from '../../assets/icons/checkCircleWhite.svg';
import editPen from '../../assets/icons/squareEditPen.svg';
import icon from '../../assets/images/backoffice/kyc4.png';
import Alert from '../../components/common/Alert';
import CameraButton from '../../components/common/CameraButton';
import ModalComponentCamera from '../../components/common/ModalComponentCamera';
import Spinner from '../../components/common/Spinner';
import {
  convertImage,
  UploadKycImagesProgressStyles,
  videoConstraints
} from '../../helpers/helperFunctions';
import { H2, H3, P1, P2 } from '../../styles/FontStyles';
import { Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { Flex, FlexCenter } from '../../styles/stylesStyled';
import { RenderDriversLicense } from './KycDocumentPhotos/RenderDriversLicense';
import { RenderID } from './KycDocumentPhotos/RenderID';
import { RenderPassport } from './KycDocumentPhotos/RenderPassport';
import {
  CamCaptured,
  Camera,
  CameraUnderlineText,
  Desktop,
  ImageEditOverlay,
  ImageUploadedOverlay,
  ImageUploadingOverlay,
  KycBrowseFilesButton,
  Mobile,
  TitleImage
} from './KycStyled';

export const ImgState = ({
  state,
  setImgState,
  percentage,
  uploadHandler,
  retake
}) => {
  const { t } = useTranslation('kyc.document_photos');
  switch (state) {
    case 1:
      return (
        <ImageUploadedOverlay>
          <Flex>
            <img
              src={check}
              alt='Uploaded'
              style={{ marginRight: 8 }}
              // onClick={() => setImgState(2)}
            />
            {t('uploaded_successfully')}
          </Flex>
          <img src={editPen} alt='Uploaded' onClick={() => setImgState(3)} />
        </ImageUploadedOverlay>
      );
    case 3:
      return (
        <ImageEditOverlay>
          <FlexCenter style={{ justifyContent: 'center' }}>
            <Button
              fs='12px'
              h='34px'
              maxWidth='111px'
              mr='12'
              onClick={() => retake()}>
              {t('retake_photo')}
            </Button>
            <KycBrowseFilesButton>
              <input
                type='file'
                accept='image/*'
                style={{ display: 'none' }}
                onChange={(e) => uploadHandler(e)}
              />
              {t('reupload_photo')}
            </KycBrowseFilesButton>
          </FlexCenter>
        </ImageEditOverlay>
      );
    case 2:
      return (
        <ImageUploadingOverlay>
          <div>
            <span />

            <div>{t('uploading')}</div>
          </div>
          <div style={{ width: 38 }}>
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              maxValue={100}
              styles={UploadKycImagesProgressStyles}
              strokeWidth={4}
            />
          </div>
        </ImageUploadingOverlay>
      );
    default:
      return null;
  }
};

const KycDocumentPhotos = ({ history, match }) => {
  const { t } = useTranslation('kyc.document_photos');

  // const [select, setSelect] = useState('id');
  const [id] = useState(parseInt(match.params.id));
  const [imageFrontUrl, setImageFrontUrl] = useState('');
  const [imageBackUrl, setImageBackUrl] = useState('');
  const [passport, setPassport] = useState('');
  const [driversLicense, setDriversLicense] = useState('');

  const [documentFrontId, setDocumentFrontId] = useState({});
  const [documentBackId, setDocumentBackId] = useState({});
  const [documentPassport, setDocumentPassport] = useState({});
  const [documentDriversLicense, setDocumentDriversLicense] = useState({});

  const [imgStateFront, setImgStateFront] = useState(1);
  const [imgStateBack, setImgStateBack] = useState(1);
  const [imgStatePassport, setImgStatePassport] = useState(1);
  const [imgStateDriversLicense, setImgStateDriversLicense] = useState(1);

  const [image, setImage] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [image4, setImage4] = useState('');

  const [errorMsg, setErrorMsg] = useState('');

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);

  const webcamRef = useRef(null);

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.userInfo);

  const uploadIdFrontHandler = async (e) => {
    const fileUploaded = e.target.files[0];
    setImageFrontUrl(URL.createObjectURL(fileUploaded));
    setDocumentFrontId(fileUploaded);
    setImgStateFront(1);
  };

  const captureIdFront = useCallback(async () => {
    setImageFrontUrl('');
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    const frontImage = await convertImage(imageSrc, 'frontId.jpeg');
    setDocumentFrontId(frontImage);
    setImageFrontUrl(imageSrc);
  }, [webcamRef, setImage]);

  const uploadIdBackHandler = (e) => {
    const fileUploaded = e.target.files[0];
    setImageBackUrl(URL.createObjectURL(fileUploaded));
    setDocumentBackId(fileUploaded);
    setImgStateBack(1);
  };

  const captureIdBack = useCallback(async () => {
    setImageBackUrl('');
    const imageSrc = webcamRef.current.getScreenshot();

    setImage2(imageSrc);

    const backImage = await convertImage(imageSrc, 'backId.jpeg');

    setDocumentBackId(backImage);

    setImageBackUrl(imageSrc);
  }, [webcamRef, setImage2]);

  const uploadPassportHandler = (e) => {
    const fileUploaded = e.target.files[0];

    setPassport(URL.createObjectURL(fileUploaded));

    setDocumentPassport(fileUploaded);
  };

  const capturePassport = useCallback(async () => {
    setPassport('');
    const imageSrc = webcamRef.current.getScreenshot();

    setImage3(imageSrc);

    const passportImage = await convertImage(imageSrc, 'passport.jpeg');

    setDocumentPassport(passportImage);

    setPassport(imageSrc);
  }, [webcamRef, setImage3]);

  const uploadLicenseHandler = (e) => {
    const fileUploaded = e.target.files[0];

    setDriversLicense(URL.createObjectURL(fileUploaded));

    setDocumentDriversLicense(fileUploaded);
  };

  const captureDriversLicense = useCallback(async () => {
    setDriversLicense('');
    const imageSrc = webcamRef.current.getScreenshot();

    setImage4(imageSrc);

    const driversLicenseImage = await convertImage(
      imageSrc,
      'driversLicense.jpeg'
    );

    setDocumentDriversLicense(driversLicenseImage);

    setDriversLicense(imageSrc);
  }, [webcamRef, setImage4]);

  const percentage = 66;

  const onHandleSubmit = (e) => {
    e.preventDefault();

    if (id === 1) {
      if (!imageFrontUrl) {
        setErrorMsg(t('err_front_ID_required'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
      } else if (!imageBackUrl) {
        setErrorMsg(t('err_back_ID_required'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
      } else {
        uploadIdImages();
      }
    } else if (id === 2) {
      if (!driversLicense) {
        setErrorMsg(t('err_driver_license_required'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
      } else {
        uploadDiverLicense();
      }
    } else {
      if (!passport) {
        setErrorMsg(t('err_password_required'));
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
      } else {
        uploadPassport();
      }
    }
  };

  const uploadIdImages = () => {
    const formData = new FormData();
    formData.append('images', documentFrontId);
    formData.append('images', documentBackId);

    // if (documentFrontId.name === documentBackId.name) {
    //   setErrorMsg('You cannot add two images with the same name');
    //   setTimeout(() => {
    //     setErrorMsg('');
    //   }, 3000);
    // } else {
    dispatch(addDocumentsImage(formData, history));
    // }
  };

  const uploadPassport = () => {
    const formData = new FormData();
    formData.append('images', documentPassport);

    dispatch(addDocumentsImage(formData, history));
  };

  const uploadDiverLicense = () => {
    const formData = new FormData();
    formData.append('images', documentDriversLicense);

    dispatch(addDocumentsImage(formData, history));
  };

  useEffect(() => {
    setImgStateFront(1);
    setImgStateBack(1);
    setImgStatePassport(1);
  }, [modal, modal2, modal3]);

  return (
    <div>
      {errorMsg && <Alert message={errorMsg} />}
      {loading ? (
        <Spinner fullScreen={true} />
      ) : (
        <>
          <Desktop>
            <TitleImage>
              <img
                src={icon}
                alt='icon'
                style={{ marginBottom: 40, width: 100 }}
              />
            </TitleImage>
          </Desktop>
          <Desktop>
            <H2 mb='12' color={color.kycTitleColor}>
              {t('title')}
            </H2>

            <P1
              mb='40'
              maxWidth='600px'
              maxWidthSM='1000px'
              color={color.kycSubTitleColor}>
              {t('subtitle', {
                and_back: id === 1 ? t('subtitle_and_back_side') : ''
              })}
            </P1>
          </Desktop>
          <Mobile>
            <H3 mb='8' center color={color.kycTitleColor}>
              {t('title')}
            </H3>

            <P2 mb='32' center color={color.kycSubTitleColor}>
              {t('subtitle', {
                and_back: id === 1 ? t('subtitle_and_back_side') : ''
              })}
            </P2>
          </Mobile>

          <form onSubmit={onHandleSubmit}>
            <RenderID
              id={id}
              imgStateFront={imgStateFront}
              setImgStateFront={setImgStateFront}
              imgStateBack={imgStateBack}
              setImgStateBack={setImgStateBack}
              imageFrontUrl={imageFrontUrl}
              imageBackUrl={imageBackUrl}
              uploadIdFrontHandler={uploadIdFrontHandler}
              uploadIdBackHandler={uploadIdBackHandler}
              setImage={setImage}
              setModal={setModal}
              setImage2={setImage2}
              setModal2={setModal2}
              percentage={percentage}
              setImageFrontUrl={setImageFrontUrl}
              setDocumentFrontId={setDocumentFrontId}
              setImageBackUrl={setImageBackUrl}
              setDocumentBackId={setDocumentBackId}
            />

            <RenderDriversLicense
              id={id}
              driversLicense={driversLicense}
              imgStateDriversLicense={imgStateDriversLicense}
              setImgStateDriversLicense={setImgStateDriversLicense}
              uploadLicenseHandler={uploadLicenseHandler}
              setImage4={setImage4}
              setModal4={setModal4}
              percentage={percentage}
              setDocumentDriversLicense={setDocumentDriversLicense}
              setDriversLicense={setDriversLicense}
            />

            <RenderPassport
              id={id}
              percentage={percentage}
              setModal3={setModal3}
              setImage3={setImage3}
              uploadPassportHandler={uploadPassportHandler}
              setImgStatePassport={setImgStatePassport}
              imgStatePassport={imgStatePassport}
              passport={passport}
              setPassport={setPassport}
              setDocumentPassport={setDocumentPassport}
            />

            <Desktop>
              <Button maxWidth='200px'>{t('confirm_photos_button')}</Button>
            </Desktop>

            <Mobile>
              <Button mbSM='50'>{t('confirm_photos_button')}</Button>
            </Mobile>
          </form>

          {/* TAKE A PICTURE MODALS --------------------------------------------------------------------- */}

          <ModalComponentCamera modal={modal} setModal={setModal}>
            {image ? (
              <div>
                <P2 bold center mb='30'>
                  {t('camera_modal_title')}
                </P2>
                <CamCaptured>
                  <img src={image} alt='captured' />
                </CamCaptured>
                <Button
                  opacity={true}
                  onClick={() => {
                    setModal(!modal);
                    setImgStateFront(1);
                  }}
                  type='button'
                  m='20px 0'>
                  {t('continue')}
                </Button>
                <CameraUnderlineText onClick={() => setImage('')}>
                  {t('retake')}
                </CameraUnderlineText>
              </div>
            ) : (
              <div>
                <P2 bold center mb='30'>
                  {t('camera_modal_title2')}
                </P2>
                <Camera>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    screenshotFormat='image/jpeg'
                    screenshotQuality={1}
                    minScreenshotWidth={2000}
                  />
                </Camera>
                <CameraButton click={captureIdFront} />
                <CameraUnderlineText onClick={() => setModal(false)}>
                  {t('cancel')}
                </CameraUnderlineText>
              </div>
            )}
          </ModalComponentCamera>
          <ModalComponentCamera modal={modal2} setModal={setModal2}>
            {image2 ? (
              <div>
                <P2 bold center mb='30'>
                  {t('camera_modal_title')}
                </P2>
                <CamCaptured>
                  <img src={image2} alt='captured' />
                </CamCaptured>
                <Button
                  opacity={true}
                  onClick={() => {
                    setModal2(!modal2);
                    setImgStateBack(1);
                  }}
                  type='button'
                  m='20px 0'>
                  {t('continue')}
                </Button>
                <CameraUnderlineText onClick={() => setImage2('')}>
                  {t('retake')}
                </CameraUnderlineText>
              </div>
            ) : (
              <div>
                <P2 bold center mb='30'>
                  {t('camera_modal_title3')}
                </P2>
                <Camera>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    screenshotFormat='image/jpeg'
                    screenshotQuality={1}
                    minScreenshotWidth={2000}
                  />
                </Camera>
                <CameraButton click={captureIdBack} />
                <CameraUnderlineText onClick={() => setModal2(false)}>
                  {t('cancel')}
                </CameraUnderlineText>
              </div>
            )}
          </ModalComponentCamera>
          <ModalComponentCamera modal={modal3} setModal={setModal3}>
            <P2 bold center mb='30'>
              {t('passport_camera_modal_title')}
            </P2>
            {image3 ? (
              <div>
                <CamCaptured>
                  <img src={image3} alt='captured' />
                </CamCaptured>
                <Button
                  opacity={'true'}
                  type='button'
                  onClick={() => {
                    setModal3(!modal3);
                    setImgStatePassport(1);
                  }}
                  m='20px 0'>
                  {t('continue')}
                </Button>
                <CameraUnderlineText onClick={() => setImage3('')}>
                  {t('retake')}
                </CameraUnderlineText>
              </div>
            ) : (
              <div>
                <Camera>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat='image/jpeg'
                    screenshotQuality={1}
                    width='100%'
                    videoConstraints={videoConstraints}
                  />
                </Camera>
                <CameraButton click={capturePassport} />
                <CameraUnderlineText onClick={() => setModal3(false)}>
                  {t('cancel')}
                </CameraUnderlineText>
              </div>
            )}
          </ModalComponentCamera>
          <ModalComponentCamera modal={modal4} setModal={setModal4}>
            <P2 bold center mb='30'>
              {t('driver_license_camera_modal_title')}
            </P2>
            {image4 ? (
              <div>
                <CamCaptured>
                  <img src={image4} alt='captured' />
                </CamCaptured>
                <Button
                  opacity={'true'}
                  type='button'
                  onClick={() => {
                    setModal4(!modal4);
                    setImgStateDriversLicense(1);
                  }}
                  m='20px 0'>
                  {t('continue')}
                </Button>
                <CameraUnderlineText onClick={() => setImage4('')}>
                  {t('retake')}
                </CameraUnderlineText>
              </div>
            ) : (
              <div>
                <Camera>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    screenshotFormat='image/jpeg'
                    screenshotQuality={1}
                    width='100%'
                  />
                </Camera>
                <CameraButton click={captureDriversLicense} />
                <CameraUnderlineText onClick={() => setModal4(false)}>
                  {t('cancel')}
                </CameraUnderlineText>
              </div>
            )}
          </ModalComponentCamera>
          {/* ----------------------------------------------------------------------- TAKE A PICTURE MODALS*/}
        </>
      )}
    </div>
  );
};

export default KycDocumentPhotos;
