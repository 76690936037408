const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const SETTINGS_INIT = {
  REQUEST: getRequestConst('SETTINGS_INIT'),
  SUCCESS: getSuccessConst('SETTINGS_INIT'),
  FAIL: getFailConst('SETTINGS_INIT')
};

export const UPDATE_USERNAME = {
  REQUEST: getRequestConst('UPDATE_USERNAME'),
  SUCCESS: getSuccessConst('UPDATE_USERNAME'),
  FAIL: getFailConst('UPDATE_USERNAME')
};

export const CHANGE_PASSWORD = {
  REQUEST: getRequestConst('CHANGE_PASSWORD'),
  SUCCESS: getSuccessConst('CHANGE_PASSWORD'),
  FAIL: getFailConst('CHANGE_PASSWORD')
};

export const DELETE_BANK = {
  REQUEST: getRequestConst('DELETE_BANK'),
  SUCCESS: getSuccessConst('DELETE_BANK'),
  FAIL: getFailConst('DELETE_BANK')
};

export const UPDATE_TEAM = {
  REQUEST: getRequestConst('UPDATE_TEAM'),
  SUCCESS: getSuccessConst('UPDATE_TEAM'),
  FAIL: getFailConst('UPDATE_TEAM')
};

export const SET_NOTIFICATION = {
  REQUEST: getRequestConst('SET_NOTIFICATION'),
  SUCCESS: getSuccessConst('SET_NOTIFICATION'),
  FAIL: getFailConst('SET_NOTIFICATION')
};

export const ENABLE_VERFICATION_STATUS = {
  REQUEST: getRequestConst('ENABLE_VERFICATION_STATUS'),
  SUCCESS: getSuccessConst('ENABLE_VERFICATION_STATUS'),
  FAIL: getFailConst('ENABLE_VERFICATION_STATUS')
};

export const DISABLE_VERFICATION_STATUS = {
  REQUEST: getRequestConst('DISABLE_VERFICATION_STATUS'),
  SUCCESS: getSuccessConst('DISABLE_VERFICATION_STATUS'),
  FAIL: getFailConst('DISABLE_VERFICATION_STATUS')
};

export const GET_VERFICATION_CODE = {
  REQUEST: getRequestConst('GET_VERFICATION_CODE'),
  SUCCESS: getSuccessConst('GET_VERFICATION_CODE'),
  FAIL: getFailConst('GET_VERFICATION_CODE')
};

export const CONFIRM_VERIFICATION = {
  REQUEST: getRequestConst('CONFIRM_VERIFICATION'),
  SUCCESS: getSuccessConst('CONFIRM_VERIFICATION'),
  FAIL: getFailConst('CONFIRM_VERIFICATION')
};

export const CONFIRM_2FA_VERIFICATION = {
  REQUEST: getRequestConst('CONFIRM_2FA_VERIFICATION'),
  SUCCESS: getSuccessConst('CONFIRM_2FA_VERIFICATION'),
  FAIL: getFailConst('CONFIRM_2FA_VERIFICATION')
};

export const CONFIRM_2FA_EMAIL = {
  REQUEST: getRequestConst('CONFIRM_2FA_EMAIL'),
  SUCCESS: getSuccessConst('CONFIRM_2FA_EMAIL'),
  FAIL: getFailConst('CONFIRM_2FA_EMAIL')
};

export const BUY_VIRTUAL_OFFICE = {
  REQUEST: getRequestConst('BUY_VIRTUAL_OFFICE'),
  SUCCESS: getSuccessConst('BUY_VIRTUAL_OFFICE'),
  FAIL: getFailConst('BUY_VIRTUAL_OFFICE')
};
