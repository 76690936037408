import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const SpinnerStyled = styled(motion.div)`
  @keyframes spinnerSpin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .spinnerSpin div {
    position: absolute;
    animation: spinnerSpin 0.3s linear infinite;
    width: 80px;
    height: 80px;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    box-shadow: 0 2px 0 0 ${({ theme }) => theme.colors.primary900};
    transform-origin: 40px 41px;
  }
  .spinnerInnerWrapper {
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
    background: rgba(241, 242, 243, 0);
  }
  .spinnerSpin {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  .spinnerSpin div {
    box-sizing: content-box;
  }

  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;

  ${({ fullScreen }) =>
    fullScreen &&
    `
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    position: fixed;
    > * {
    height: 100vh;
    }

  `}

  ${({ solid, fullScreen, theme }) =>
    solid && fullScreen
      ? `background: ${theme.colors.background}`
      : `background: ${theme.colors.background}90`};

  ${({ transparent, theme }) =>
    transparent && `background: ${theme.colors.background}80`};

  ${({ absolute }) =>
    absolute &&
    `
   position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
  `}
`;

const Spinner = ({ fullScreen, solid = true, transparent, absolute }) => {
  return (
    <SpinnerStyled
      absolute={absolute}
      transparent={transparent}
      fullScreen={fullScreen}
      solid={solid}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.1
      }}>
      <div className='spinnerInnerWrapper'>
        <div className='spinnerSpin'>
          <div></div>
        </div>
      </div>
    </SpinnerStyled>
  );
};

export default Spinner;
