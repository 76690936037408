import React from 'react';
import { P2, P3 } from '../../../styles/FontStyles';
import { Box } from '../../../styles/GlobalComponents';
import { Icon, IconPackagesCheck, IconX } from '../../../styles/Icons';
import { CheckCircle, Qualification } from '../CashbackBonusStyled';

import { Desktop, Mobile } from '../../../App';

import { color } from '../../../styles/GlobalStyles';
import { useTranslation } from 'react-i18next';

export const Section1 = ({ cashback }) => {
  const { t } = useTranslation('cashback');

  return (
    <Box>
      <Qualification>
        <div>
          <P2 mb='8' bold flex between>
            {t('section1.title')}
            <Mobile>
              <CheckCircle
                qualified={
                  cashback.isQualifiedPartner
                    ? 'green'
                    : cashback.isLimitDatePassed
                    ? 'red'
                    : 'gray'
                }>
                <Icon color={color.colors.neutral50}>
                  {!cashback.isQualifiedPartner &&
                  cashback.isLimitDatePassed ? (
                    <IconX size='10' />
                  ) : (
                    <IconPackagesCheck size='10' />
                  )}
                </Icon>
              </CheckCircle>
            </Mobile>
          </P2>

          <P3>{t('section1.text')}</P3>
        </div>
        <Desktop>
          <CheckCircle
            qualified={
              cashback.isQualifiedPartner
                ? 'green'
                : cashback.isLimitDatePassed
                ? 'red'
                : 'gray'
            }>
            <Icon color={color.colors.neutral50}>
              {!cashback.isQualifiedPartner && cashback.isLimitDatePassed ? (
                <IconX size='20' />
              ) : (
                <IconPackagesCheck size='20' />
              )}
            </Icon>
          </CheckCircle>
        </Desktop>
      </Qualification>
    </Box>
  );
};
