import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import {
  forgottenPassword,
  forgottenSMSPassword
} from '../../actions/authActions';
import Alert from '../../components/common/Alert';
import { SecurityCodeWrapper } from '../../styles/GlobalComponents';
import {
  AuthButton,
  ButtonWrapper,
  PhoneComp,
  TextBig,
  TextOrange
} from '../../styles/stylesStyled';

const PhoneCode = ({ history, location }) => {
  const [code, setCode] = useState('');
  const [activeTimer, setActiveTimer] = useState(false);
  const [time, setTime] = useState(60);
  const [intervalID, setIntervalID] = useState(null);
  const hasTimerEnded = time <= 0;
  const isTimerRunning = intervalID != null;

  const dispatch = useDispatch();

  const credentials = useSelector((state) => state.forgottenCredentials);

  const { error, message } = credentials;

  const id = location.state.from;

  const confirmSms = (e) => {
    e.preventDefault();
    dispatch(forgottenSMSPassword(code, id, history));
  };

  const update = () => {
    setTime((time) => time - 1);
  };
  const startTimer = () => {
    if (!hasTimerEnded && !isTimerRunning) {
      setIntervalID(setInterval(update, 1000));
      setActiveTimer(true);
    }
  };

  useEffect(() => {
    if (hasTimerEnded) {
      setTime(60);
      clearInterval(intervalID);
      setIntervalID(null);
      setActiveTimer(false);
    }
  }, [hasTimerEnded]);

  useEffect(
    () => () => {
      clearInterval(intervalID);
    },
    []
  );

  return (
    <PhoneComp>
      {error && <Alert message={error.error} />}
      {message && <Alert icon={'success'} message={message} />}
      <TextBig>ENTER SMS CONFIRMATION CODE</TextBig>

      <form onSubmit={confirmSms}>
        <SecurityCodeWrapper>
          <ReactCodeInput
            fields={6}
            value={code}
            onChange={setCode}
            type={'number'}
          />
        </SecurityCodeWrapper>
        <TextOrange
          style={{ color: activeTimer && '#C6BEBD' }}
          onClick={() => {
            if (!activeTimer) {
              dispatch(forgottenPassword(id, 2, history));
              startTimer();
            }
          }}>
          Resend SMS {activeTimer && `( in ${time} seconds )`}
        </TextOrange>
        <ButtonWrapper>
          <AuthButton
            opacity={code.length === 6}
            type='submit'
            disabled={code.length < 6 ? true : false}>
            Continue
          </AuthButton>
        </ButtonWrapper>
      </form>
    </PhoneComp>
  );
};

export default PhoneCode;
