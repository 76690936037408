import styled from 'styled-components';

export const HubStyled = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 24px;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.neutral0};
  /* border: 1px solid ${({ theme }) => theme.colors.neutral100}; */

  transition: all 300ms ease;

  > div:first-child {
    border-radius: 24px;
    display: flex;
    transition: all 300ms ease;
  }

  > div > div:nth-child(2) {
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  ${({ selected, theme }) =>
    selected &&
    `
      background-color: ${theme.colors.secondaryBlue};
    `}

  @media (max-width: 800px) {
    background-color: ${({ theme }) => theme.colors.secondaryBlue};
    > div:first-child {
      background-color: ${({ theme }) => theme.colors.neutral0};
    }

    /* border-bottom: ${({ theme, expanded }) =>
      expanded
        ? `1px solid ${theme.colors.primary900}`
        : `1px solid ${theme.colors.neutral100}`}; */
    > div > div:nth-child(2) {
      padding: 16px;

      > div:nth-child(1) {
        width: 100%;
      }

      > div:nth-child(2) {
        display: none;
      }
    }
  }

  ${({ condensed }) =>
    condensed &&
    `
    > div > div:nth-child(2) {
      padding: 16px;
      gap: 16px;
    }
  `}
`;

export const HubImage = styled.div`
  width: 170px;

  display: flex;
  flex-grow: 1;
  border-radius: 24px;
  flex: none;
  aspect-ratio: 1/1;
  /* width: 100px; */
  background: 
  /* linear-gradient(
      180deg,
      rgba(72, 48, 193, 0) 64.87%,
      #4830c1 96.21%
    ), */ url(${({ image }) => image});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;

  @media (max-width: 800px) {
    width: 140px;
    height: 100%;
    aspect-ratio: 1/1;
    background-position: top center;
  }

  ${({ condensed }) =>
    condensed &&
    `
    width: 140px;
  `}
`;

export const HubArrow = styled.div`
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  @media (max-width: 800px) {
    display: block;
  }
`;

export const Collapsable = styled.div`
  display: none;
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${({ expanded, elementHeight }) =>
    expanded ? `${elementHeight}px` : '0px'};
  /* margin-top: ${(props) => (props.expanded ? '20px' : '0px')}; */
  overflow: hidden;
  transition: all 300ms;

  @media (min-width: 3500px) {
    /* margin-top: ${(props) => (props.expanded ? '40px' : '0px')}; */
  }

  @media (max-width: 800px) {
    display: block;
    /* margin-top: ${(props) => (props.expanded ? '10px' : '0px')}; */
  }
`;
