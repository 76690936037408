import React, { useEffect, useState } from 'react';
import { H5, P1 } from '../../../styles/FontStyles';
import { Box, Button, Flexed } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles.js';
import { CheckText } from '../../../components/common/CheckText.js';
import { Icon, IconWarningMark } from '../../../styles/Icons.js';
import { useDispatch, useSelector } from 'react-redux';
import { changeAutoStake } from '../Portfolio.actions';
import Spinner from '../../../components/common/Spinner';

export const AutoStake = ({
  packageItem,
  setToggle,
  toggle,
  setAutostake,
  isOpen
}) => {
  const [state1, setState1] = useState(false);
  const [state2, setState2] = useState(false);

  const dispatch = useDispatch();

  const { loadingAutoStake } = useSelector((state) => state.portfolio);

  useEffect(() => {
    setState1(false);
    setState2(false);
  }, [isOpen]);

  if (loadingAutoStake) return <Spinner solid={false} />;

  return (
    <>
      <Box blue>
        <Flexed column center>
          <Icon mb='12'>
            <IconWarningMark size='50' />
          </Icon>
          <H5 mb='20' center color={color.colors.neutral500}>
            WARNING
          </H5>
          <P1 mb='20' center bold>
            YOU ARE CHANGING THE AUTO-STAKE FUNCTION. THIS WILL RESULT IN
            CHANGED YIELD OF THE SELECTED NFT HUB AND CAN ALSO{' '}
            <span> DRASTICALLY IMPACT </span> YOUR RANKING.
            <br />
            <br />
            YOU ARE IN RISK OF:
          </P1>

          <Flexed column>
            <CheckText x text='Loss of an active ranking position' />
            <CheckText x text='Lower daily Staking Hub NFT rewards' />
            <CheckText x text='Decreased weekly cap' />
            <CheckText x text='Total stake not increasing ' />
          </Flexed>
        </Flexed>
      </Box>

      <CheckText
        checked={state1}
        onClick={() => setState1(!state1)}
        text='I acknowledge the consequences of putting Auto-Stake function to OFF.'
      />
      <CheckText
        checked={state2}
        onClick={() => setState2(!state2)}
        text='I am accepting the risks associated with putting this Staking Hub NFT on “Off”'
      />

      <Button
        disabled={!state1 || !state2}
        mt='20'
        onClick={() => setAutostake(false)}>
        Keep the Auto-Stake ON
      </Button>
      <Button
        textButton
        onClick={() =>
          dispatch(
            changeAutoStake(
              packageItem.id,
              false,
              setAutostake,
              toggle,
              setToggle
            )
          )
        }>
        Put Auto-Stake to OFF
      </Button>
    </>
  );
};
