import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ErrorToast,
  SuccessToast
} from '../../../components/common/Toasts/Toasts';

import ReactCodeInput from 'react-code-input';
import Spinner from '../../../components/common/Spinner';

import { AuthButton, Flex } from '../../../styles/stylesStyled';

import { H5, P2, P3 } from '../../../styles/FontStyles';
import { color } from '../../../styles/GlobalStyles';
import { Icon, IconWarning } from '../../../styles/Icons';

import { Flexed, SecurityCodeWrapper } from '../../../styles/GlobalComponents';

const API_URL = process.env.REACT_APP_API_URL;

export const Password2FA = ({ setStep }) => {
  const [code, setCode] = useState('');
  const [code2Fa, setCode2Fa] = useState('');
  const [codeLoading, setCodeLoading] = useState(false);
  const [code2FaLoading, setCode2FaCodeLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const { user, token } = useSelector((state) => state.userInfo);
  const { verificationStatus } = useSelector((state) => state.settings);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.accessToken}`
    }
  };

  const sendCode = async () => {
    try {
      setCodeLoading(true);
      await axios.post(`${API_URL}/user/password/change/email/code`, config);

      SuccessToast('Code sent successfully');
      setCodeLoading(false);
      setIsCodeSent(true);
    } catch (error) {
      setCodeLoading(false);
      ErrorToast(error.response.data.error);
    }
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    if (!code) {
      ErrorToast('Please enter email verification code');
    } else if (verificationStatus && !code2Fa) {
      ErrorToast('Please enter 2FA verification code');
    } else {
      try {
        setCode2FaCodeLoading(true);

        await axios.put(
          `${API_URL}/user/password/change/confirm`,
          {
            emailCode: code,
            twoFACode: verificationStatus ? code2Fa : null
          },
          config
        );

        setCode2FaCodeLoading(false);

        setStep(3);
      } catch (error) {
        setCode2FaCodeLoading(false);
        ErrorToast(error.response.data.error);
      }
    }
  };
  return (
    <div>
      {codeLoading || code2FaLoading ? (
        <Spinner fullScreen={false} dark />
      ) : (
        <>
          <div style={{ marginBottom: 30, marginTop: 20 }}>
            <Flex>
              <Icon>
                <IconWarning color={color.colors.error500} />
              </Icon>
              <P2 light mb='30' ml='10' center>
                Withdrawals will be disabled for 24 hours after you make this
                change to protect your account.
              </P2>
            </Flex>
          </div>
          <H5 mb='40' center>
            SECURITY VERIFICATION
          </H5>

          <form onSubmit={onHandleSubmit}>
            <Flexed between>
              <P2 bold color={color.colors.neutral700}>
                Email verification code
              </P2>
              <P2
                bold
                style={{ cursor: 'pointer' }}
                color={color.colors.primary900}
                onClick={() => sendCode()}>
                Send Code
              </P2>
            </Flexed>
            <SecurityCodeWrapper>
              <ReactCodeInput
                fields={7}
                value={code}
                onChange={setCode}
                type={'number'}
              />
            </SecurityCodeWrapper>
            <P3 color={color.colors.neutral500} mb='10'>
              {isCodeSent && `Enter the 7 digit code sent to ${user?.email}`}
            </P3>

            {verificationStatus && (
              <div>
                <Flexed between>
                  <P2 bold color={color.colors.neutral700}>
                    2FA verification code
                  </P2>
                </Flexed>
                <SecurityCodeWrapper is2Fa>
                  <ReactCodeInput
                    fields={6}
                    value={code2Fa}
                    onChange={setCode2Fa}
                    type={'number'}
                  />
                </SecurityCodeWrapper>
                <P3 color={color.colors.neutral500} mb='10'>
                  Enter the 6 digit code from your 2FA authenticator
                </P3>
              </div>
            )}
            <AuthButton
              type='submit'
              opacity={code !== ''}
              disabled={code === '' ? true : false}>
              Confirm
            </AuthButton>
          </form>
        </>
      )}
    </div>
  );
};
