import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getContentBlock } from '../../actions/contentBlockActions';
import { addSocialTerms, setApprovalDate } from '../../actions/authActions';
import Spinner from '../../components/common/Spinner';
import Alert from '../../components/common/Alert';
import { AuthButton } from '../../styles/stylesStyled';
import { useTranslation } from 'react-i18next';

const TermsConditions = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { contentBlock, loading } = useSelector((state) => state.contentBlock);
  const { path, user, token, error } = useSelector((state) => state.register);

  useEffect(() => {
    dispatch(getContentBlock(200));
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Spinner fullScreen={true} />
      ) : (
        <>
          {error && <Alert message={error.error} />}
          <div
            dangerouslySetInnerHTML={{
              __html: contentBlock && contentBlock.content
            }}
          />

          <AuthButton
            style={{ marginTop: 40 }}
            opacity
            onClick={() => {
              if (path === '/social') {
                dispatch(
                  addSocialTerms(
                    user.creationType,
                    moment().format(),
                    token,
                    history
                  )
                );
              } else {
                history.push('/email-address');
                dispatch(setApprovalDate(moment().format()));
                localStorage.setItem(
                  'approvalDate',
                  JSON.stringify(moment().format())
                );
              }
            }}>
            {t('termsAndConditions.i_agree')}
          </AuthButton>
        </>
      )}
    </>
  );
};

export default TermsConditions;
