import React from 'react';
import { useTranslation } from 'react-i18next';
import { P3 } from '../../../styles/FontStyles';
import { Button } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { Icon, IconWithdrawWallet } from '../../../styles/Icons';
import { FlexCenter } from '../../../styles/stylesStyled';
import { BankAccount, Subtitle, Title } from './WithdrawStyled';

export const ChooseBankAccount = ({
  setStep,
  selected,
  setSelected,
  userWallets,
  setSelectedWallet
}) => {
  const { t } = useTranslation('earnings');
  return (
    <div>
      <FlexCenter style={{ flexDirection: 'column' }}>
        <Title>{t('withdraw_part.choose_bank_account.title')}</Title>
        <Subtitle style={{ textAlign: 'center' }}>
          {t('withdraw_part.choose_bank_account.subtitle')}
        </Subtitle>
        {userWallets?.map((account, index) => {
          if (account.status === 1) {
            return (
              <BankAccount
                selected={selected === index}
                onClick={() => {
                  if (account.status === 1) {
                    setSelected(index);
                    setSelectedWallet(account);
                  }
                }}
                key={index}
                disabled={account.status !== 1}>
                <div>
                  <span>
                    <Icon
                      mr='10'
                      color={
                        account.status !== 1
                          ? color.colors.neutral400
                          : color.colors.primary900
                      }>
                      <IconWithdrawWallet />
                    </Icon>
                  </span>

                  <P3
                    color={
                      account.status !== 1
                        ? color.colors.neutral400
                        : color.colors.neutral900
                    }
                    bold
                    style={{ wordBreak: 'break-all' }}>
                    {account.address}
                  </P3>
                </div>
              </BankAccount>
            );
          }
        })}

        <Subtitle
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            margin: '20px 0 0 0'
          }}
          onClick={() => setStep(5)}>
          {t('withdraw_part.choose_bank_account.add')}
        </Subtitle>
        <Button maxWidth='200px' mt={'30'} onClick={() => setStep(1)}>
          {t('withdraw_part.choose_bank_account.select')}
        </Button>
      </FlexCenter>
    </div>
  );
};
