import styled from 'styled-components';
// framer motion

import { ViewBackOffice } from '../../styles/stylesStyled';
import { motion } from 'framer-motion';

export const AnimatedDashboardWrapper = styled(ViewBackOffice)``;
export const AnimatedItemStyled = styled(motion.div)``;
export const AnimatedTabStyled = styled(motion.div)``;
export const AnimatedAuthStyled = styled(motion.div)``;
