import React from 'react';
import { useTranslation } from 'react-i18next';
import check from '../../assets/icons/backOffice/walletWhite.svg';
import { Button } from '../../styles/GlobalComponents';
import { FlexBetween, FlexCenter } from '../../styles/stylesStyled';

import {
  BankAccount,
  BankAccountGradientText,
  Subtitle,
  Title
} from '../EarningsBackoffice/Withdraw/WithdrawStyled';

export const ChooseBankAccount = ({
  setStep,
  selected,
  setSelected,
  userWallets,
  setSelectedWallet
}) => {
  const { t } = useTranslation('packages');
  return (
    <div style={{ marginTop: '12vh' }}>
      <FlexCenter style={{ flexDirection: 'column' }}>
        <Title>{t('choose_bank_account.title')}</Title>
        <Subtitle style={{ textAlign: 'center' }}>
          {t('choose_bank_account.subtitle')}
        </Subtitle>
        {userWallets?.map((account, index) => (
          <BankAccount
            selected={selected === index}
            onClick={() => {
              setSelected(index);
              setSelectedWallet(account);
            }}
            key={index}>
            <div>
              <img src={check} alt='checked' />

              <div>
                <FlexBetween style={{ wordBreak: 'break-all' }}>
                  <BankAccountGradientText
                    selected={selected === index}
                    style={{ wordBreak: 'break-all' }}>
                    {account.address}
                  </BankAccountGradientText>
                </FlexBetween>
              </div>
            </div>
          </BankAccount>
        ))}

        <Subtitle
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            margin: '20px 0 0 0'
          }}
          onClick={() => setStep(2)}>
          {t('choose_bank_account.add')}
        </Subtitle>
        <Button m={'30 0 0 0'} opacity={'true'} onClick={() => setStep(1)}>
          {t('choose_bank_account.select')}
        </Button>
      </FlexCenter>
    </div>
  );
};
