import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from '../../SettingsBackoffice/SettingsStyled';
import { Flexed, SecurityCodeWrapper } from '../../../styles/GlobalComponents';
import Spinner from '../../../components/common/Spinner';
import { FlexCenter, AuthButton } from '../../../styles/stylesStyled';
import { color } from '../../../styles/GlobalStyles';
import ReactCodeInput from 'react-code-input';
import { createWithdrawExplorer } from '../../EarningsBackoffice/Withdraw/Withdraw.actions';
import { P2, P3 } from '../../../styles/FontStyles';

import {
  SuccessToast,
  ErrorToast
} from '../../../components/common/Toasts/Toasts';

const API_URL = process.env.REACT_APP_API_URL;

export const TwoFASetup = ({
  setStep,
  setAmount,
  amount,
  fee,
  userWallets,
  selected
}) => {
  const dispatch = useDispatch();

  const [code, setCode] = useState('');
  const [code2Fa, setCode2Fa] = useState('');
  const [loadingCode, setLoadingCode] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const { verificationStatus } = useSelector((state) => state.settings);
  const { loadingWithdraw } = useSelector((state) => state.withdraw);
  const { user, token } = useSelector((state) => state.userInfo);

  const sendCode = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.accessToken}`
      }
    };

    try {
      setLoadingCode(true);
      await axios.post(
        `${API_URL}/explorer/withdraw/email/code`,
        {
          amount: parseInt(amount) - parseInt(fee),
          userWalletId: userWallets[selected].id
        },
        config
      );

      SuccessToast('Code sent successfully');
      setLoadingCode(false);
      setIsCodeSent(true);
    } catch (error) {
      setLoadingCode(false);
      ErrorToast(error.response.data.error);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();

    if (!code) {
      ErrorToast('Please enter email verification code');
    } else if (!code2Fa) {
      ErrorToast('Please enter 2FA code');
    } else {
      dispatch(
        createWithdrawExplorer(
          parseInt(amount),
          userWallets[selected].id,
          setStep,
          code,
          code2Fa,
          setAmount
        )
      );
    }
  };

  return (
    <div style={{ marginTop: '20vh' }}>
      <FlexCenter style={{ flexDirection: 'column' }}>
        {loadingCode || loadingWithdraw ? (
          <Spinner dark />
        ) : (
          <>
            <Title style={{ marginBottom: 60 }}>Security verification</Title>
            <form onSubmit={onHandleSubmit}>
              <Flexed between>
                <P2 bold color={color.colors.neutral700}>
                  Email verification code
                </P2>
                <P2
                  bold
                  style={{ cursor: 'pointer' }}
                  color={color.colors.primary900}
                  onClick={() => sendCode()}>
                  Send Code
                </P2>
              </Flexed>
              <SecurityCodeWrapper>
                <ReactCodeInput
                  fields={7}
                  value={code}
                  onChange={setCode}
                  type={'number'}
                />
              </SecurityCodeWrapper>
              <P3 color={color.colors.neutral500} mb='40'>
                {isCodeSent && `Enter the 7 digit code sent to ${user?.email}`}
              </P3>

              {verificationStatus && (
                <div>
                  <Flexed between>
                    <P2 bold color={color.colors.neutral700}>
                      2FA verification code
                    </P2>
                  </Flexed>
                  <SecurityCodeWrapper is2Fa>
                    <ReactCodeInput
                      fields={6}
                      value={code2Fa}
                      onChange={setCode2Fa}
                      type={'number'}
                    />
                  </SecurityCodeWrapper>
                  <P3 color={color.colors.neutral500} mb='10'>
                    Enter the 6 digit code from your 2FA authenticator
                  </P3>
                </div>
              )}
              <AuthButton
                type='submit'
                opacity={code !== ''}
                disabled={code === '' ? true : false}>
                Confirm
              </AuthButton>
            </form>
          </>
        )}
      </FlexCenter>
    </div>
  );
};
