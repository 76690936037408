import { tryExecuteSimpleAction } from '../../actions/common';
import { SuccessToast } from '../../components/common/Toasts/Toasts';
import { CHANGE_EMAIL_SEND, VERIFY__NEW_EMAIL } from './changeEmail.constants';
import {
  changeEmailAsync,
  resendNewEmailVerificationAsync,
  verifyNewEmailAsync
} from './changeEmail.requests';

export const changeEmailAction = (email, code, setStep) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    CHANGE_EMAIL_SEND.REQUEST,
    async () => {
      const { data } = await changeEmailAsync(email, code);

      dispatch({
        type: CHANGE_EMAIL_SEND.SUCCESS
      });
      setStep(3);
      SuccessToast(data?.message ?? '');
    },
    CHANGE_EMAIL_SEND.FAIL
  );
};

export const verifyNewEmailAction = (code, history) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    VERIFY__NEW_EMAIL.REQUEST,
    async () => {
      const { data } = await verifyNewEmailAsync(code);

      dispatch({
        type: VERIFY__NEW_EMAIL.SUCCESS
      });
      history.push('/new-email-verification-success');
      SuccessToast(data?.message ?? '');
    },
    VERIFY__NEW_EMAIL.FAIL
  );
};

export const resendNewEmailVerificationAction = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    VERIFY__NEW_EMAIL.REQUEST,
    async () => {
      const { data } = await resendNewEmailVerificationAsync();

      dispatch({
        type: VERIFY__NEW_EMAIL.SUCCESS
      });
      SuccessToast(data?.message ?? '');
    },
    VERIFY__NEW_EMAIL.FAIL
  );
};
