import React from 'react';

import { P2, P3 } from '../../../styles/FontStyles';
import { Box } from '../../../styles/GlobalComponents';
import { useTranslation } from 'react-i18next';

export const SectionInfo = () => {
  const { t } = useTranslation('cashback');

  return (
    <Box>
      <P2 mb='8' bold flex between>
        {t('section_info.title')}
      </P2>

      <P3>
        {t('section_info.text')}

        <br />
        <br />
        {t('section_info.text2')}
      </P3>
    </Box>
  );
};
