import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarSubtitle, SidebarTitle } from '../../styles/stylesStyled';
import { BankAccount, Box, OutlineBox } from './SettingsStyled';

import { SidebarModal } from '../../components/common/Sidebar/Sidebar';
import { AddBankAccount } from '../EarningsBackoffice/Withdraw/AddBankAccount';
import { EditBankAccount } from '../EarningsBackoffice/Withdraw/EditBankAccount';

import {
  deleteUserWallet,
  reactivateUserWallet
} from '../EarningsBackoffice/Withdraw/Withdraw.actions';
import {
  Icon,
  IconSettingsRecalculate,
  IconSettingsTrash,
  IconWithdrawWallet
} from '../../styles/Icons';
import { color } from '../../styles/GlobalStyles';
import { Button, Flexed, FloatingButton } from '../../styles/GlobalComponents';
import { useTranslation } from 'react-i18next';
import { P3 } from '../../styles/FontStyles';
import { useWindowWide } from '../../utils/wideScreen';
import Spinner from '../../components/common/Spinner';

export const PaymentsSettings = () => {
  const { t } = useTranslation('settings');
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [bank, setBank] = useState(null);

  const [setStep] = useState(1);

  const dispatch = useDispatch();
  const { userWallets, loading } = useSelector((state) => state.withdraw);

  const wide = useWindowWide(800);

  if (loading) return <Spinner fullScreen />;

  return (
    <>
      <Box>
        {userWallets?.map((account, index) => (
          <Flexed gap='12px' style={{ marginBottom: 12 }} key={index}>
            <BankAccount
              style={{ width: wide && '30%' }}
              selected={account.isActive}
              onClick={() => {
                if (account.status === 1) {
                  setBank(account);
                  setIsOpen4(!isOpen4);
                }
              }}>
              <Flexed center gap='10px'>
                <Icon
                  color={
                    account.status !== 1
                      ? color.colors.neutral400
                      : color.colors.primary900
                  }>
                  <IconWithdrawWallet />
                </Icon>

                <P3
                  color={
                    account.status !== 1 ? color.colors.neutral400 : undefined
                  }
                  semiBold>
                  {account.label}
                </P3>
              </Flexed>
            </BankAccount>
            <BankAccount
              style={{ width: wide && '60%' }}
              onClick={() => {
                if (account.status === 1) {
                  setBank(account);
                  setIsOpen4(!isOpen4);
                }
              }}>
              <P3
                color={
                  account.status !== 1 ? color.colors.neutral400 : undefined
                }
                semiBold>
                {account.address}
              </P3>
            </BankAccount>

            {account.status === 1 && (
              <Icon
                onClick={() => {
                  setIsOpen3(true);
                  setBank(account);
                }}
                color={color.colors.neutral700}
                pointer>
                <IconSettingsTrash />
              </Icon>
            )}

            {account.status === 2 && (
              <Icon
                onClick={() => {
                  dispatch(reactivateUserWallet(account.id));
                }}
                color={color.colors.neutral700}
                pointer
                ml='5'>
                <IconSettingsRecalculate />
              </Icon>
            )}
          </Flexed>
        ))}
        <FloatingButton>
          <Button maxWidth='200px' onClick={() => setIsOpen2(true)} mt='8'>
            {t('payments.add_new_address')}
          </Button>
        </FloatingButton>
      </Box>

      <SidebarModal
        isOpen={isOpen2}
        setOpen={setIsOpen2}
        title={t('payments.add_new_address')}
        sidebarWidth={'508px'}>
        <AddBankAccount setIsOpen2={setIsOpen2} setStep={setStep} />
      </SidebarModal>

      <SidebarModal
        isOpen={isOpen4}
        setOpen={setIsOpen4}
        title={t('payments.edit_address')}
        sidebarWidth={'508px'}>
        <EditBankAccount setIsOpen4={setIsOpen4} account={bank} />
      </SidebarModal>
      <SidebarModal
        isOpen={isOpen3}
        setOpen={setIsOpen3}
        title='Delete address'
        sidebarWidth={'508px'}>
        <SidebarTitle>{t('payments.are_you_sure')}</SidebarTitle>
        <SidebarSubtitle>{t('payments.info_about_deletion')}</SidebarSubtitle>

        <OutlineBox style={{ wordBreak: 'break-all' }}>
          <P3 semiBold mr='12' color={color.colors.neutral700}>
            {t('payments.address')}
          </P3>

          <P3 semiBold color={color.colors.neutral700}>
            {bank?.address}
          </P3>
          {/* <span></span> */}
        </OutlineBox>

        <Button
          m='20px auto 12px auto'
          onClick={() => {
            dispatch(deleteUserWallet(bank.id));
            setIsOpen3(false);
          }}>
          {t('payments.delete_address')}
        </Button>
        <Button negative m='0 auto' onClick={() => setIsOpen3(false)}>
          {t('payments.cancel')}
        </Button>
      </SidebarModal>
    </>
  );
};
