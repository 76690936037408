import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Webcam from 'react-webcam';
import { addAddress } from '../../actions/addressActions';
import { uploadProfileImage } from '../../actions/authActions';
import arrow from '../../assets/icons/backOffice/Arrow-Right2.svg';

import search from '../../assets/icons/search.svg';
import BottomDrawer from '../../components/common/BottomDrawer';
import CameraButton from '../../components/common/CameraButton';
import { InputBackOffice } from '../../components/common/InputBackoffice';
import { SidebarModal } from '../../components/common/Sidebar/Sidebar';
import countries from '../../countries';
import { BrowseFilesButton, Button } from '../../styles/GlobalComponents';
import {
  FlexBetween,
  FlexCenter,
  SidebarSubtitle,
  SidebarTitle
} from '../../styles/stylesStyled';

import { ChangeEmailSteps } from './EmailChange/ChangeEmailSteps';

import { useTranslation } from 'react-i18next';
import {
  ProfileImage,
  ProfileImagePlaceholder,
  ProfilePicture
} from '../../styles/GlobalComponents';
import { Icon, IconEditSettings } from '../../styles/Icons';
import { useWindowWide } from '../../utils/wideScreen';
import { CountryText, SInput, SInputContainer } from '../Address/AddressStyled';
import { Required2FA } from './EmailChange/Required2FA';
import { settingsInit, updateUsername } from './settings.actions';
import {
  Box,
  CamCapturedCircle,
  CamCircle,
  CameraUnderlineText,
  Label,
  Text,
  Username
} from './SettingsStyled';
import { ErrorToast } from '../../components/common/Toasts/Toasts';

export const AccountSettings = () => {
  const { t } = useTranslation('settings');
  const [countryList, setCountryList] = useState(countries);
  const [countryCode, setCountryCode] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [zip, setZip] = useState('');
  const [coutr, setCoutr] = useState('');
  const [city, setCity] = useState('');
  const [userCountry] = useState('');
  const [emailChange, setEmailChange] = useState(false);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.settings);

  const wide = useWindowWide(800);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [username, setUsername] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [emailStep, setEmailStep] = useState(1);

  const [searchTerm, setSearchTerm] = useState('');

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const videoConstraints = {
    facingMode: 'user',
    width: 311,
    height: 311
  };

  const checkCountry = (country) => {
    setCountryList(
      countryList.map((item) =>
        item.name === country.name
          ? {
              ...item,
              checked: true
            }
          : {
              ...item,
              checked: false
            }
      )
    );
  };

  const [image, setImage] = useState('');

  const webcamRef = useRef(null);

  const [bigDrawer, setBigDrawer] = useState(false);

  const handleOpen = () => {
    setBigDrawer(true);
    setIsVisible2(false);
  };

  const [isVisible2, setIsVisible2] = React.useState(false);
  const onClose2 = React.useCallback(() => {
    setIsVisible2(false);
  }, []);

  const uploadFileHandler = async (event) => {
    const fileUploaded = event.target.files[0];

    const formData = new FormData();
    formData.append('image', fileUploaded);

    dispatch(uploadProfileImage(formData));
  };

  const captureProfile = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);

    await convertImage(imageSrc, 'profile.jpeg');
  }, [
    webcamRef,
    setImage
    //  dispatch
  ]);

  const convertImage = async (image, imageName) => {
    const response = await image;

    const blob = await fetch(response).then((res) => res.blob());

    const file = new File([blob], imageName, { type: 'image/jpeg' });

    const formData = new FormData();
    formData.append('image', file);

    dispatch(uploadProfileImage(formData));
  };

  // useEffect(() => {
  //   fetch(`https://api.ipregistry.co/?key=${process.env.REACT_APP_IP_REGISTRY}`)
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (payload) {
  //       setUserCountry(payload?.location?.country?.name);
  //     });
  // }, [dispatch]);

  return (
    <>
      <FlexCenter style={{ marginBottom: wide ? 40 : 32 }}>
        <div
          style={{ position: 'relative' }}
          onClick={() => setIsVisible2(true)}>
          <ProfilePicture
            size={wide ? '83px' : '55px'}
            style={{
              marginRight: '20px'
            }}>
            {user ? (
              user?.profileImageUrl ? (
                <ProfileImage src={user?.profileImageUrl} alt='Upload' />
              ) : (
                <ProfileImagePlaceholder
                  size={wide ? '83px' : '55px'}
                  font={wide ? '35px' : '25px'}>
                  {user?.name?.charAt(0) + user?.surname?.charAt(0)}
                </ProfileImagePlaceholder>
              )
            ) : (
              ''
            )}
          </ProfilePicture>

          <Icon
            style={{
              width: 23,
              height: 23,
              position: 'absolute',
              top: 10,
              right: 12,
              zIndex: 100,
              cursor: 'pointer'
            }}>
            <IconEditSettings />
          </Icon>
        </div>
        <div>
          <Username>{user?.name + ' ' + user?.surname}</Username>
          <Label>ID: {user?.id}</Label>
        </div>
      </FlexCenter>
      <Box>
        <FlexBetween>
          <div>
            <Label>{t('account.username')}</Label>
            <Text>{user?.username}</Text>
          </div>

          <Button negative maxWidth='100px' onClick={() => setIsOpen(true)}>
            {t('account.change')}
          </Button>
        </FlexBetween>

        <FlexBetween>
          <div>
            <Label>{t('account.email')}</Label>
            <Text>{user?.email}</Text>
          </div>

          <Button
            negative
            maxWidth='100px'
            onClick={() => {
              setChangeEmail(true);
              setEmailStep(1);
            }}>
            {t('account.change')}
          </Button>
        </FlexBetween>

        <FlexBetween>
          <div>
            <Label>{t('account.main_address')}</Label>
            <Text>
              {user?.addresses?.map(
                (address) => address.isPermanent && address.addressLine1
              )}
            </Text>
          </div>

          <Button negative maxWidth='100px' onClick={() => setIsOpen2(true)}>
            {t('account.edit')}
          </Button>
        </FlexBetween>
      </Box>

      <SidebarModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={t('account.change_username')}
        sidebarWidth={'508px'}
        isCenteredContent={true}>
        <SidebarTitle>{t('account.enter_new_username')}</SidebarTitle>
        <SidebarSubtitle>{t('account.sidebar_subtitle')}</SidebarSubtitle>
        <InputBackOffice
          type='text'
          value={username.replace(/[^A-Z0-9]/gi, '') || ''}
          onChange={(e) =>
            setUsername(e.replace(/[^A-Z0-9]/gi, '').toLowerCase())
          }
        />
        <Button
          m='20px auto'
          maxWidth='200px'
          onClick={() => {
            dispatch(updateUsername(username, setIsOpen));
          }}>
          {t('account.save_changes')}
        </Button>
      </SidebarModal>

      <SidebarModal
        isOpen={isOpen2}
        setOpen={setIsOpen2}
        title={t('account.add_temporary_address')}
        sidebarWidth={'508px'}
        isCenteredContent={true}>
        <SidebarTitle>{t('account.enter_your_address')}</SidebarTitle>
        <SidebarSubtitle>{''}</SidebarSubtitle>
        <InputBackOffice
          type='text'
          placeholder={t('account.address_line_1')}
          value={address1}
          onChange={setAddress1}
        />
        <InputBackOffice
          type='text'
          placeholder={t('account.address_line_2')}
          value={address2}
          onChange={setAddress2}
        />
        <InputBackOffice
          type='text'
          placeholder={t('account.zip_code')}
          value={zip}
          onChange={setZip}
        />
        <InputBackOffice
          type='text'
          placeholder={t('account.city')}
          value={city}
          onChange={setCity}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: 55,
            color: '#fefefe',
            borderRadius: '8px',
            padding: '0 16px',
            background: '#3e3a39',
            marginBottom: '20px',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            cursor: 'pointer'
          }}
          onClick={() => setIsVisible(!isVisible)}>
          <span>{coutr || t('account.country')}</span>
          <img src={arrow} alt='select' />
        </div>

        {/* <Select isOpen={isOpen}>
          <div style={{ height: 55 }}>
            <span onClick={() => setIsOpen3(!isOpen3)}>
              {' '}
              {isPermanent ? 'Permanent address' : 'Temporary address'}
            </span>
            <img
              src={arrow}
              alt='select'
              onClick={() => setIsOpen3(!isOpen3)}
            />
            <CollapsableAbsolute isOpen={isOpen3}>
              <SelectDropDown>
                {Object.values(Permanent).map((passId, index) => (
                  <FlexBetween onClick={() => handleSelect(passId)} key={index}>
                    <span>{passId.name}</span>

                    {isPermanent === passId.value ? (
                      <img src={check} alt='checked' />
                    ) : (
                      <ReloadCheckEmpty />
                    )}
                  </FlexBetween>
                ))}
              </SelectDropDown>
            </CollapsableAbsolute>
          </div>
        </Select> */}
        <Button
          m='20px auto'
          maxWidth='200px'
          onClick={() => {
            if (!address1 && !address2 && !city && !zip && !coutr) {
              ErrorToast(t('account.err_fill_all_fields'));
            } else {
              dispatch(
                addAddress(
                  address1,
                  address2,
                  zip,
                  coutr,
                  countryCode,
                  false,
                  city,
                  setIsOpen2
                )
              );
            }
          }}>
          {t('account.save_changes')}
        </Button>
      </SidebarModal>

      <SidebarModal
        isOpen={changeEmail}
        setOpen={setChangeEmail}
        title='Change your email'
        sidebarWidth={'508px'}>
        <ChangeEmailSteps step={emailStep} setStep={setEmailStep} />
      </SidebarModal>

      <BottomDrawer isVisible={isVisible} onClose={onClose} height={'90vh'}>
        <SInputContainer>
          <img src={search} alt='search.svg' />
          <SInput
            placeholder={t('search')}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SInputContainer>
        {countryList
          ?.sort((a) => (a.name === userCountry ? -1 : 1))
          .filter((value) => {
            if (searchTerm === '') {
              return value;
            } else if (
              value.name.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return value;
            }
          })
          .map((country, i) => (
            <div
              onClick={() => {
                setCoutr(country.name);
                setCountryCode(country.code);
                setIsVisible(false);
                checkCountry(country);
              }}
              key={i}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer'
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px'
                }}>
                <img
                  src={country.flag}
                  alt='flag'
                  style={{
                    marginRight: '20px',
                    width: '28px',
                    height: '20px'
                  }}
                />

                <CountryText checked={false}>{country.name}</CountryText>
              </div>
              {/* {country.checked && (
                    <img
                      src={check}
                      alt='check'
                      style={{ marginBottom: '30px' }}
                    />
                  )} */}
            </div>
          ))}
      </BottomDrawer>

      <SidebarModal
        isOpen={isVisible2}
        setOpen={onClose2}
        title={t('account.add_profile_photo')}
        sidebarWidth={'508px'}
        isCenteredContent={true}>
        <div>
          <Button m='20px auto' opacity='true' onClick={handleOpen}>
            {t('account.take_profile_photo')}
          </Button>

          {/* <label className='custom-file-upload'>
                <input
                  type='file'
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    uploadFileHandler(e);
                    setIsVisible2(false);
                  }}
                />
                Upload profile photo
              </label> */}
          <BrowseFilesButton>
            <input
              type='file'
              style={{ display: 'none' }}
              onChange={(e) => {
                uploadFileHandler(e);
                setIsVisible2(false);
              }}
            />
            {t('account.upload_profile_photo')}
          </BrowseFilesButton>
        </div>
      </SidebarModal>

      <SidebarModal
        isOpen={bigDrawer}
        setOpen={setBigDrawer}
        title={t('account.add_profile_photo')}
        sidebarWidth={'508px'}
        isCenteredContent={true}>
        {/* <BigDrawerContent> */}
        {image ? (
          <FlexCenter style={{ flexDirection: 'column' }}>
            <CamCapturedCircle>
              <img src={image} alt='captured' />
            </CamCapturedCircle>
            <Button
              onClick={() => {
                setBigDrawer(!bigDrawer);
                dispatch(settingsInit());
              }}
              m='20px 0 20px 0'>
              {t('account.image_is_clear')}
            </Button>
            <CameraUnderlineText onClick={() => setImage('')}>
              {t('account.retake')}
            </CameraUnderlineText>
          </FlexCenter>
        ) : (
          <FlexCenter style={{ flexDirection: 'column' }}>
            <CamCircle>
              {bigDrawer && (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  videoConstraints={videoConstraints}
                  screenshotFormat='image/jpeg'
                  forceScreenshotSourceSize={true}
                />
              )}
            </CamCircle>
            {/* <Icon>
                  <IconCameraCapture />
                </Icon> */}
            <CameraButton click={captureProfile} />
          </FlexCenter>
        )}
        {/* </BigDrawerContent> */}
      </SidebarModal>

      <SidebarModal
        isOpen={emailChange}
        setOpen={setEmailChange}
        title={`Change email`}
        sidebarWidth={'508px'}>
        <Required2FA
          emailChange={emailChange}
          setEmailChange={setEmailChange}
        />
      </SidebarModal>
    </>
  );
};
