import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Desktop, Mobile } from '../../App';
import {
  NameInitials,
  NumberRound,
  USDT,
  dateDifferenceInDaysFromToday,
  formatUSD
} from '../../helpers/helperFunctions';

import {
  CaptionText,
  H1,
  H2,
  H3,
  H4,
  P1,
  P2,
  P3
} from '../../styles/FontStyles';
import {
  Box,
  Flexed,
  ProfileImage,
  ProfileImagePlaceholder,
  ProfilePicture,
  SectionSeparator
} from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  Icon,
  IconCashBackBonus,
  IconPortfolioDiamond,
  IconPlus
} from '../../styles/Icons';
import {
  Flex,
  FlexCenter,
  Responsive,
  SectionSeparatorDark
} from '../../styles/stylesStyled';
import { useWindowWide } from '../../utils/wideScreen';
import { IconTitleStyled, Username } from './DashboardStyled';

// import MavieLogo from '../../assets/icons/backOffice/mavieGoldBlue.svg';

import moment from 'moment';
import Countdown from 'react-countdown';
import ReactTooltip from 'react-tooltip';

import {
  CASHBACK_BONUS,
  EARNINGS,
  RANKING,
  SETTINGS_VIRTUAL_OFFICE
} from '../../routes/routes';
import {
  Office,
  Trophy
  //  Trophy
} from '../../styles/svg';
import { useSelector } from 'react-redux';

export const EarningsComponent = ({ user, backOffice }) => {
  const { t } = useTranslation('dashboard');
  const { dashboard } = useSelector((state) => state.dashboard);
  const history = useHistory();
  const wide = useWindowWide(800);
  const bonusesIcons = [
    {
      id: 'ranking-tooltip',
      icon: Trophy,
      linkTo: RANKING,
      tip: 'Ranking',
      disabled: false
    },
    {
      id: 'cashback-tooltip',
      icon: IconCashBackBonus,
      linkTo: CASHBACK_BONUS,
      tip: 'Fast Start bonus',
      disabled: false
    },
    {
      id: 'office-tooltip',
      icon: Office,
      linkTo: SETTINGS_VIRTUAL_OFFICE,
      tip: 'Backoffice',
      disabled: false
    }
  ];

  const HubInfo = () => {
    return (
      <Flexed column between>
        <Flexed
          center
          mb='20'
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/backoffice/settings/virtual-office')}>
          <Icon color={color.colors.secondary100} mr='15' fill='true'>
            <Office />
          </Icon>
          <Flexed column>
            <P2 bold>Back Office Subscription</P2>

            <Flexed between>
              <P3 color={color.colors.neutral700}>
                Days left:{' '}
                {`${dateDifferenceInDaysFromToday(
                  dashboard?.user?.currentVirtualOfficeLicence?.dateEnd
                )}`}
              </P3>

              <Icon color={color.colors.secondary100} fill='true'>
                <IconPlus size={14} />
              </Icon>
            </Flexed>
          </Flexed>
        </Flexed>
        <Flexed
          center
          mb='20'
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/backoffice/ranking')}>
          <Icon color={color.colors.secondary100} ml='-15' fill='true'>
            <IconPortfolioDiamond />
          </Icon>
          <Flexed column>
            <P2 bold>Current rank: {dashboard?.user?.lifetimeRank}</P2>

            <Flexed between>
              <P3 color={color.colors.neutral700}>
                Qualified rank: {dashboard?.user?.qualifiedRank}
              </P3>
            </Flexed>
          </Flexed>
        </Flexed>
        <Flexed
          center
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/backoffice/cashback-bonus')}>
          <Icon color={color.colors.secondary100} mr='15' fill='true'>
            <IconCashBackBonus />
          </Icon>

          <Flexed column>
            <P2 bold>Fast Start Bonus</P2>

            <Flexed between>
              <P3 color={color.colors.neutral700}>
                {dashboard?.user?.fastStartBonus?.isClaimed
                  ? 'Claimed'
                  : 'Not Claimed'}
              </P3>
            </Flexed>
          </Flexed>
        </Flexed>
      </Flexed>
    );
  };

  const CountdownComponent = () => {
    const endOfTheWeek = moment().utc().day(7).endOf('day');

    const timestamp = Number(endOfTheWeek.format('x'));

    return (
      <Countdown
        date={timestamp}
        renderer={({ days, hours, minutes }) => (
          <P3 color={color.colors.neutral500} semiBold>
            Cap week ends in: {days} days, {hours} hours, {minutes} minutes
          </P3>
        )}
      />
    );
  };

  return (
    <>
      <Responsive between end startSM mb='20' mbSM='16'>
        <IconTitleStyled style={{ margin: 0 }}>
          My Uincubator Overview
        </IconTitleStyled>
        <CountdownComponent />
      </Responsive>
      <Box p='40px'>
        <FlexCenter>
          <div style={{ position: 'relative', marginRight: wide ? 80 : 20 }}>
            <ProfilePicture size={wide ? '280px' : '64px'}>
              {user?.profileImageUrl ? (
                <ProfileImage src={user.profileImageUrl} alt='Upload' />
              ) : (
                <ProfileImagePlaceholder
                  size={window.innerWidth > 800 ? '280px' : '64px'}
                  font={window.innerWidth > 800 ? '96px' : '20px'}>
                  {NameInitials()}
                </ProfileImagePlaceholder>
              )}
            </ProfilePicture>
            <Desktop style={{ width: '100%' }}>
              <Username>
                <P1 color={color.colors.secondary100} bold>
                  {t('earnings.hi')} {user?.name},
                </P1>
                <H2 color={color.colors.neutral900}>
                  {t('earnings.sky_is_the_limit')}
                </H2>
              </Username>
            </Desktop>
          </div>
          <div style={{ width: '100%' }}>
            <Mobile>
              <P2 color={color.colors.neutral900} bold>
                {' '}
                {t('earnings.hi')} {user?.name},
              </P2>
              <H3 color={color.colors.neutral900}>
                {t('earnings.sky_is_the_limit')}
              </H3>
            </Mobile>
            <Desktop>
              <Flexed between>
                <div>
                  <Flexed between>
                    <Flex style={{ flexWrap: 'wrap' }}>
                      <div style={{ marginRight: 50 }}>
                        <CaptionText color={color.colors.neutral500}>
                          {/* {t('earnings.total_earnings')} */}
                          On-chain Distribution
                        </CaptionText>
                        <H1 bold mb='8'>
                          {formatUSD(
                            NumberRound(backOffice?.user?.totalEarnings)
                          )}{' '}
                          {USDT}
                        </H1>
                        <P3 flex color={color.colors.neutral500}>
                          <P3 color={color.colors.secondary100} mr='5' bold>
                            +
                            {formatUSD(
                              NumberRound(backOffice?.user?.currentWeekEarnings)
                            )}{' '}
                            {USDT}{' '}
                          </P3>
                          {t('earnings.this_week')}
                        </P3>
                      </div>
                    </Flex>
                  </Flexed>
                  <SectionSeparator margin='32px 0' />

                  <FlexCenter onClick={() => history.push(EARNINGS)}>
                    <FlexCenter>
                      {/* <img
                        src={MavieLogo}
                        alt='logo'
                        style={{ marginRight: 20 }}
                      /> */}
                      <div>
                        <CaptionText pointer color={color.colors.neutral500}>
                          {USDT} {t('earnings.balance')}
                        </CaptionText>
                        <H2 pointer>
                          {formatUSD(
                            backOffice?.user?.userTickets?.availableRewardPoints
                          )}{' '}
                          {USDT}
                        </H2>
                      </div>
                    </FlexCenter>
                  </FlexCenter>
                </div>
                <HubInfo />
              </Flexed>
            </Desktop>
          </div>
        </FlexCenter>
        <div>
          <Mobile>
            <SectionSeparatorDark />
            <Flex style={{ flexWrap: 'wrap' }}>
              <div style={{ marginRight: 50 }}>
                <CaptionText color={color.colors.neutral500}>
                  {t('earnings.total_earnings')}
                </CaptionText>
                <H2 bold mb='8'>
                  {formatUSD(NumberRound(backOffice?.user?.totalEarnings))}{' '}
                  {USDT}
                </H2>
                <P3 flex color={color.colors.neutral500}>
                  <P3 color={color.colors.secondary100} mr='5' bold>
                    +
                    {formatUSD(
                      NumberRound(backOffice?.user?.currentWeekEarnings)
                    )}{' '}
                    {USDT}{' '}
                  </P3>
                  {t('earnings.this_week')}
                </P3>
              </div>
            </Flex>
          </Mobile>
        </div>
      </Box>

      {!wide && (
        <Box>
          <div onClick={() => history.push(EARNINGS)}>
            <CaptionText mb='4' color={color.colors.neutral500}>
              {USDT} {t('earnings.balance')}
            </CaptionText>
            <H4 mb='20'>
              {formatUSD(backOffice?.user?.userTickets?.availableRewardPoints)}{' '}
              {USDT}
            </H4>
          </div>

          <HubInfo />
        </Box>
      )}
      {bonusesIcons.map((tip, i) => {
        return (
          <ReactTooltip
            key={i}
            id={tip.id}
            place='top'
            effect='solid'
            backgroundColor='#fff'
            textColor={color.colors.neutral900}>
            {tip.tip}
          </ReactTooltip>
        );
      })}
    </>
  );
};
