import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { H4, P3 } from '../../../styles/FontStyles';
import {
  Button,
  Flexed,
  PageTitle,
  SectionSeparatorVertical,
  UserImageCircle
} from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import {
  Icon,
  IconDashboardDownline,
  IconStructureAlert,
  IconStructurePlus
} from '../../../styles/Icons';
import {
  clearBreadCrumbs,
  getDirectMembers,
  getDirectTeamLegUsers,
  getRootTeamMember,
  openAddNewMemberModal
} from '../myTeamBackOffice.actions';

import {
  AlertIcon,
  AlertWrapper,
  DirectsWrapper,
  DirectUserWrap
} from '../MyTeamBackofficeStyled';

import AddNewMemberModal from './AddNewMemberModal';

import Spinner from '../../../components/common/Spinner';
import { formatVP } from '../../../helpers/helperFunctions';
import { Flex } from '../../../styles/stylesStyled';
import { useWindowWide } from '../../../utils/wideScreen';
import { UserInfoModal } from './UserInfoModal';
import { AnimatedDashboard } from '../../../components/animation/AnimatedDashboard';

const Direct = () => {
  const dispatch = useDispatch();
  const countPerPage = 20;
  let [numberOfPageLeft, setNumberOfPageLeft] = useState(1);
  let [numberOfPageRight, setNumberOfPageRight] = useState(1);
  const {
    leftDirectUsers,
    rightDirectUsers,
    directTeamMember,
    isLoading,
    directTeamMemberInfo,
    rootTeamMemberInfo
  } = useSelector((state) => state.myTeamBackoffice);

  const { user } = useSelector((state) => state.userInfo);

  const [userModal, setUserModal] = useState('');
  const [userForModal, setUserForModal] = useState('');

  useEffect(() => {
    if (!directTeamMember) dispatch(getDirectMembers(null, countPerPage));
    dispatch(clearBreadCrumbs());
  }, [dispatch]);

  const wide = useWindowWide(800);

  const rightLegVolume = directTeamMemberInfo?.rightLegInfo?.legTotalVolume;
  const leftLegVolume = directTeamMemberInfo?.leftLegInfo?.legTotalVolume;

  const getUserImage = (teamMember) => {
    return (
      <div>
        {teamMember.directUsersCount > 0 && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 70
            }}>
            <div style={{ float: 'right', marginTop: -5 }}>
              <Icon>
                <IconDashboardDownline />
              </Icon>
            </div>
          </div>
        )}
        {teamMember?.hasNoPackage ||
        teamMember?.hasUnpaidPackage ||
        teamMember?.isNotQualifiedPartner ||
        teamMember?.isUserNotVerified ? (
          <AlertWrapper>
            <UserImageCircle
              image={teamMember?.profileImageUrl}
              avatar
              avatarSize='20px'
              size='40px'
              margin='0 0 10px 0'
            />
            <AlertIcon color={color.colors.error500}>
              <Icon pointer w='34px' color={color.colors.neutral50}>
                <IconStructureAlert width={3} height={10} />
              </Icon>
            </AlertIcon>
          </AlertWrapper>
        ) : (
          <UserImageCircle
            image={teamMember?.profileImageUrl}
            avatar
            avatarSize='20px'
            size='40px'
            margin='0 0 12px 0'
          />
        )}
      </div>
    );
  };

  // const UsersWithEmptySlots = (users) => {
  //   const slotsCount = 20;

  //   const DirectUsersWithEmptySlots = [...users];
  //   if (users.length < slotsCount) {
  //     for (
  //       let i = 0;
  //       (i = slotsCount - DirectUsersWithEmptySlots?.length);
  //       i++
  //     ) {
  //       DirectUsersWithEmptySlots.push({ value: 'emptyBox' });
  //     }
  //   }
  //   return DirectUsersWithEmptySlots;
  // };

  const handleScrollLeft = () => {
    let numOfPage = numberOfPageLeft;
    numOfPage = numOfPage + 1;
    setNumberOfPageLeft(numOfPage);

    if (
      directTeamMemberInfo?.leftLegInfo?.totalDirectUsersCount !==
      leftDirectUsers?.length
    ) {
      dispatch(getDirectTeamLegUsers(null, numOfPage, countPerPage, 0));
    }
  };

  const handleScrollRight = () => {
    let numOfPage = numberOfPageRight;
    numOfPage = numOfPage + 1;
    setNumberOfPageRight(numOfPage);
    if (
      directTeamMemberInfo?.rightLegInfo?.totalDirectUsersCount !==
      rightDirectUsers?.length
    ) {
      dispatch(getDirectTeamLegUsers(null, numOfPage, countPerPage, 1));
    }
  };

  const MoreButtonLeft = () =>
    directTeamMemberInfo?.leftLegInfo?.totalDirectUsersCount !==
      leftDirectUsers?.length && (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Button
          w='100px'
          h='30px'
          mt='20'
          onClick={() => {
            handleScrollLeft();
          }}>
          Show more
        </Button>
      </div>
    );

  const MoreButtonRight = () =>
    directTeamMemberInfo?.rightLegInfo?.totalDirectUsersCount !==
      rightDirectUsers?.length && (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Button
          w='100px'
          h='30px'
          mt='20'
          onClick={() => {
            handleScrollRight();
          }}>
          Show more
        </Button>
      </div>
    );

  const onPlusClick = (parentId, position) => {
    dispatch(openAddNewMemberModal(parentId, position));
  };

  const LeftLeg = () =>
    leftDirectUsers?.map((team, i) => {
      if (team.value !== 'emptyBox') {
        return (
          <DirectUserWrap
            style={{ cursor: 'pointer' }}
            key={i}
            onClick={() => {
              setUserModal(true);
              setUserForModal(team);
              dispatch(getRootTeamMember(team.id));
            }}>
            {getUserImage(team)}
            <P3
              center
              color={color.colors.neutral900}
              style={{ maxWidth: '100%' }}>
              <div className='truncated'>
                <div>{team?.username ?? '---'}</div>
              </div>
            </P3>
          </DirectUserWrap>
        );
      } else {
        return <DirectUserWrap key={i}></DirectUserWrap>;
      }
    });

  const RightLeg = () =>
    rightDirectUsers?.map((team, i) => {
      if (team.value !== 'emptyBox') {
        return (
          <DirectUserWrap
            style={{ cursor: 'pointer' }}
            key={i}
            onClick={() => {
              setUserModal(true);
              setUserForModal(team);
              dispatch(getRootTeamMember(team.id));
            }}>
            {getUserImage(team)}
            <P3
              center
              color={color.colors.neutral900}
              style={{ maxWidth: '100%' }}>
              <div className='truncated'>
                <div>{team?.username ?? '---'}</div>
              </div>
            </P3>
          </DirectUserWrap>
        );
      } else {
        return <DirectUserWrap key={i}></DirectUserWrap>;
      }
    });

  if (isLoading) return <Spinner fullScreen={true} dark />;

  return (
    <AnimatedDashboard zoom>
      <PageTitle>My Miners</PageTitle>

      {!wide && (
        <P3 mb='20' right color={color.colors.primary900}>
          Personal: {formatVP(directTeamMemberInfo?.directTotalVolume)}
        </P3>
      )}

      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '100%' }}>
          {wide ? (
            <Flexed between center mb='20'>
              <Flexed center gap='10px'>
                <H4>Mining: {formatVP(leftLegVolume)}</H4>
              </Flexed>
              <Flex>
                <H4>
                  Personal: {formatVP(directTeamMemberInfo?.directTotalVolume)}{' '}
                </H4>
              </Flex>
            </Flexed>
          ) : (
            <Flexed center column mb='20'>
              <H4>Mining: {formatVP(leftLegVolume)}</H4>
            </Flexed>
          )}

          <DirectsWrapper className='noScrollBar'>
            <DirectUserWrap
              style={{ justifyContent: 'center', cursor: 'pointer' }}
              onClick={() => {
                onPlusClick(user?.id, 0);
              }}>
              <Icon color={color.colors.primary900}>
                <IconStructurePlus />
              </Icon>
            </DirectUserWrap>
            <LeftLeg />
          </DirectsWrapper>
          <MoreButtonLeft />
        </div>
        <SectionSeparatorVertical margin={wide ? '0 50px' : '0 20px'} />
        <div style={{ width: wide ? '200px' : '150px', textAlign: 'center' }}>
          <H4>Community: </H4>
          <H4>{formatVP(rightLegVolume)}</H4>
          <DirectsWrapper className='noScrollBar'>
            <DirectUserWrap
              style={{ justifyContent: 'center', cursor: 'pointer' }}
              onClick={() => {
                onPlusClick(user?.id, 1);
              }}>
              <Icon color={color.colors.primary900}>
                <IconStructurePlus />
              </Icon>
            </DirectUserWrap>
            <RightLeg />
          </DirectsWrapper>
          <MoreButtonRight />
        </div>
      </div>

      <AddNewMemberModal />

      <UserInfoModal
        userModal={userModal}
        setUserModal={setUserModal}
        userForModal={userForModal}
        rootTeamMemberInfo={rootTeamMemberInfo}
        onPlusClick={onPlusClick}
      />
    </AnimatedDashboard>
  );
};

export default Direct;
