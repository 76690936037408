/* eslint-disable no-unused-vars */
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import axiosInterceptor from './helpers/axios-helper';
import PrivateRoute from './routing/PrivateRoute';
import ScrollToTop from './components/common/ScrollToTop';
import AuthLayout from './layout/authLayout';
import ConfirmEmail from './views/ConfirmEmail/ConfirmEmail';
import EmailAddress from './views/EmailAddress/EmailAddress';

import ForgottenPassword from './views/ForgottenPassword/ForgottenPassword';
import ForgottenPasswordNew from './views/ForgottenPassword/Password';
import PasswordChanged from './views/ForgottenPassword/PasswordChanged';
import ForgottenPasswordSMS from './views/ForgottenPassword/PhoneCode';

////////VIEWS IMPORTS//////
import Login from './views/Login/Login';
import Password from './views/Password/Password';
import ProfileDetails from './views/ProfileDetails/ProfileDetails';
import Referral from './views/Referral/Referral';
// import RegistrationComplete from './views/RegistrationComplete/RegistrationComplete';
import StartKyc from './views/StartKyc/StartKyc';
import TermsConditions from './views/TermsConditions/TermsConditions';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import {
  getRefreshToken,
  removeUserPrizeFromLS,
  setNewAccessToken,
  setUserPrizerToLS
} from './helpers/localstorage-helper';
import KycLayout from './layout/kycLayout';
import KycBirth from './views/Kyc/KycBirth';
import KycDocumentDetail from './views/Kyc/KycDocumentDetails';
import KycDocumentPhotos from './views/Kyc/KycDocumentPhotos';
import KycSelfieTime from './views/Kyc/KycSelfieTime';
import PageError from './views/PageError/PageError';
import KycStart from './views/Kyc/KycStart';
import KycAgreement from './views/Kyc/KycAgreement';
import Welcome from './views/Welcome/Welcome';
import WelcomeAmbassador from './views/Welcome/WelcomeAmbassador';
import WelcomePackages from './views/Welcome/WelcomePackages';
import WelcomeLayout from './views/Welcome/WelcomeLayout';
import KycFinish from './views/Kyc/KycFinish';
import { useSelector } from 'react-redux';
import { AnimationDiv } from './styles/stylesStyled';

//Maintenance
import { MaintenanceMode } from './views/MaintenanceMode/MaintenanceMode';

import {
  BACKOFFICE_LAYOUT,
  DASHBOARD,
  SETTINGS_LAYOUT,
  SETTINGS_ACCOUNT
} from './routes/routes';
import { useWindowWide } from './utils/wideScreen';
import { BackofficeRoutes } from './routes/backofficeRoutes';
import { AnimatePresence } from 'framer-motion';
import {
  MarqueeNewMembers,
  Sidebar,
  TopNav
} from './layout/BackofficeLayout/BackofficeLayout';

import ChangeEmail from './views/ChangeEmail/ChangeEmail';
import VerifyNewEmail from './views/ChangeEmail/VerifyNewEmail';
import VerifyNewEmailSuccess from './views/ChangeEmail/VerifyNewEmailSuccess';
import SecurityCode from './views/SecurityCode/SecurityCode';
import Spinner from './components/common/Spinner';
import { useEffect } from 'react';
import { useState } from 'react';
import { FirstLogin } from './views/FirstLogin/FirstLogin';

export const Mobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

export const Desktop = styled.div`
  display: block;
  @media (max-width: 800px) {
    display: none;
  }
`;

function App() {
  const { transition } = useSelector((state) => state.transition);
  const { token } = useSelector((state) => state.userInfo);

  const [refreshedToken, setRefreshedToken] = useState(false);
  const [goTo, setGoto] = useState('');

  const rToken = getRefreshToken();

  const pathname = window.location.pathname;

  const isBackoffice =
    pathname === BACKOFFICE_LAYOUT
      ? false
      : pathname.includes(BACKOFFICE_LAYOUT);

  const API_URL = process.env.REACT_APP_API_URL;

  async function refreshToken() {
    const response = await window.axios.put(
      `${API_URL}/authorization/token/refresh`,
      { refreshToken: rToken }
    );

    let newAccessToken = response.data.data.accessToken;

    setNewAccessToken(newAccessToken);

    window.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${newAccessToken}`;

    const statusCode = response.status;

    if (statusCode === 200) {
      setRefreshedToken(true);
      setGoto(!isBackoffice ? DASHBOARD : pathname);
    }

    if (statusCode > 400) {
      setRefreshedToken(true);
      setGoto('/login');
      localStorage.removeItem('prizerRegister');
      removeUserPrizeFromLS();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (rToken) {
        refreshToken();
      }
      if (!rToken) {
        setRefreshedToken(true);
        setGoto('/login');
      }
    }, 500);
  }, []);

  if (!refreshedToken) return <Spinner fullScreen />;

  return (
    <>
      <AnimationDiv
        transition={transition === 1}
        transition2={transition === 2}
      />

      <Router>
        {token && (
          <>
            <Sidebar />
            <TopNav />
            <MarqueeNewMembers />
            <ScrollToTop />
          </>
        )}
        <Routes refreshToken={refreshToken} goTo={goTo} />
      </Router>
    </>
  );
}

export default App;

const Routes = ({ goTo }) => {
  const maintenanceMode = false;
  const wide = useWindowWide(800);
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Redirect exact from='/' to={goTo} />
        <Redirect exact from='/backoffice' to={DASHBOARD} />

        {wide && (
          <Redirect exact from={SETTINGS_LAYOUT} to={SETTINGS_ACCOUNT} />
        )}

        <Route
          path={BACKOFFICE_LAYOUT}
          basename='/backoffice'
          render={(props) => <BackofficeRoutes {...props} />}></Route>

        <Route
          path='/login'
          render={(props) =>
            maintenanceMode ? (
              <AuthLayout>
                <MaintenanceMode {...props} />
              </AuthLayout>
            ) : (
              <AuthLayout>
                <Login {...props} />
              </AuthLayout>
            )
          }
        />

        <Route
          exact
          path='/first-login'
          render={(props) => (
            <AuthLayout>
              <FirstLogin {...props} />
            </AuthLayout>
          )}
        />

        <Route
          path='/security-code'
          render={(props) => (
            <AuthLayout>
              <SecurityCode {...props} />
            </AuthLayout>
          )}
        />

        {/* -------change email------ */}
        <Route
          exact
          path='/request-email-change'
          render={(props) => (
            <AuthLayout>
              <ChangeEmail {...props} />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path='/email-change'
          render={(props) => (
            <AuthLayout>
              <VerifyNewEmail {...props} />
            </AuthLayout>
          )}
        />

        <Route
          exact
          path='/new-email-verification-success'
          render={(props) => (
            <AuthLayout>
              <VerifyNewEmailSuccess {...props} />
            </AuthLayout>
          )}
        />
        {/* -------change email------ */}

        <Route
          path='/ambassador/:referer?/:id?'
          render={(props) => (
            <AuthLayout>
              <Login {...props} />
            </AuthLayout>
          )}
        />
        <Route
          path='/ambassador/:referralCode'
          render={(props) => (
            <AuthLayout>
              <Login {...props} />
            </AuthLayout>
          )}
        />
        <Route
          path='/referral'
          render={(props) => (
            <AuthLayout>
              <Referral {...props} />
            </AuthLayout>
          )}
        />
        <Route
          path='/email-address/:id?/:childPosition?'
          render={(props) => (
            <AuthLayout>
              <EmailAddress {...props} />
            </AuthLayout>
          )}
        />
        <Route
          path='/confirm-email/:id?/:childPosition?'
          render={(props) => (
            <AuthLayout>
              <ConfirmEmail {...props} />
            </AuthLayout>
          )}
        />
        <Route
          path='/password'
          render={(props) => (
            <AuthLayout>
              <Password {...props} />
            </AuthLayout>
          )}
        />

        <Route
          path='/forgotten-password'
          render={(props) => (
            <AuthLayout>
              <ForgottenPassword {...props} />
            </AuthLayout>
          )}
        />
        <Route
          path='/forgotten-password-sms'
          render={(props) => (
            <AuthLayout>
              <ForgottenPasswordSMS {...props} />
            </AuthLayout>
          )}
        />
        <Route
          path='/forgotten-password-new'
          render={(props) => (
            <AuthLayout>
              <ForgottenPasswordNew {...props} />
            </AuthLayout>
          )}
        />
        <Route
          path='/password-changed'
          render={(props) => (
            <AuthLayout>
              <PasswordChanged {...props} />
            </AuthLayout>
          )}
        />

        <Route
          path='/profile-details'
          render={(props) => (
            <AuthLayout>
              <ProfileDetails {...props} />
            </AuthLayout>
          )}
        />
        <PrivateRoute
          path='/start-kyc'
          render={(props) => (
            <AuthLayout>
              <StartKyc {...props} />
            </AuthLayout>
          )}
        />
        <Route
          path='/terms-and-conditions'
          render={(props) => (
            <AuthLayout>
              <TermsConditions {...props} />
            </AuthLayout>
          )}
        />

        <PrivateRoute
          path='/welcome'
          render={(props) => <Welcome {...props} />}
        />
        <PrivateRoute
          path='/welcome-rank'
          render={(props) => (
            <WelcomeLayout>
              <WelcomeAmbassador {...props} />
            </WelcomeLayout>
          )}
        />
        <PrivateRoute
          path='/welcome-packages'
          render={(props) => (
            <WelcomeLayout>
              <WelcomePackages {...props} />
            </WelcomeLayout>
          )}
        />
        <Route
          path='/profile-details'
          render={(props) => (
            <AuthLayout>
              <ProfileDetails {...props} />
            </AuthLayout>
          )}
        />
        <PrivateRoute
          path='/start-kyc'
          render={(props) => (
            <AuthLayout>
              <StartKyc {...props} />
            </AuthLayout>
          )}
        />

        <Route
          path='/kyc'
          render={(props) => (
            <KycLayout>
              <KycStart {...props} />
            </KycLayout>
          )}
        />
        <Route
          path='/kyc-agreement'
          render={(props) => (
            <KycLayout>
              <KycAgreement {...props} />
            </KycLayout>
          )}
        />
        <Route
          path='/birth-details'
          render={(props) => (
            <KycLayout>
              <KycBirth {...props} />
            </KycLayout>
          )}
        />
        <Route
          path='/document-details'
          render={(props) => (
            <KycLayout>
              <KycDocumentDetail {...props} />
            </KycLayout>
          )}
        />
        <Route
          path='/document-photos/:id'
          render={(props) => (
            <KycLayout>
              <KycDocumentPhotos {...props} />
            </KycLayout>
          )}
        />
        <Route
          path='/selfie-time'
          render={(props) => (
            <KycLayout>
              <KycSelfieTime {...props} />
            </KycLayout>
          )}
        />
        <Route
          path='/kyc-congratulations'
          render={(props) => (
            <KycLayout>
              <KycFinish {...props} />
            </KycLayout>
          )}
        />
        <Route render={(props) => <PageError {...props} />} />
      </Switch>
    </AnimatePresence>
  );
};
