import React from 'react';
import { ClassicalSelectStakingHubNFT } from '../../../components/common/SelectStakingHubNFT/ClassicalSelectStakingHubNFT';

export const Step1 = ({
  setStep,
  selectedHub,
  setSelectedHub,
  isFirstGiftAvailable
}) => {
  return (
    <ClassicalSelectStakingHubNFT
      setStep={setStep}
      selectedHub={selectedHub}
      setSelectedHub={setSelectedHub}
      isFirstGiftAvailable={isFirstGiftAvailable}
    />
  );
};
