import React, { useState } from 'react';
import { InputBackOffice } from '../../components/common/InputBackoffice';

import {
  AuthButton,
  ButtonWrapper,
  PhoneComp
} from '../../styles/stylesStyled';
import { StrengthBar, StrengthText } from './PasswordStyled';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sendPassword } from '../../actions/authActions';

import Spinner from '../../components/common/Spinner';
import { AuthLayoutTitle } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { ErrorToast } from '../../components/common/Toasts/Toasts';

const Password = ({ history }) => {
  const { t } = useTranslation('onboarding');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [letterUpperCase, setLetterUpperCase] = useState(false);
  const [letterDigit, setLetterDigit] = useState(false);
  const [letterChar, setLetterChar] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const validation = [
    { text: t('password.uppercase'), strength: 1 },
    { text: t('password.special_letter'), strength: 2 },
    { text: t('password.number'), strength: 3 },
    { text: t('password.8+character'), strength: 4 }
  ];

  const upperCase = /^(?=.*?[A-Z])/;
  const digit = /^(?=.*?[0-9])/;
  const specChar = /(?=.*?[#?!@$%^&*-])/;

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.register);

  const confirmPassword = (e) => {
    e.preventDefault();

    if (password !== password2) {
      ErrorToast(t('password.passwords_do_not_match'));
    } else if (!password && !password2) {
      ErrorToast(t('password.passwords_do_not_match'));
    } else {
      const { user } = userInfo;
      dispatch(sendPassword(user?.id, password, history));
    }
  };

  const checkPassword = (e) => {
    setPassword(e);

    if (e.match(upperCase)) {
      setLetterUpperCase(true);
    } else {
      setLetterUpperCase(false);
    }

    if (e.match(digit)) {
      setLetterDigit(true);
    } else {
      setLetterDigit(false);
    }

    if (e.match(specChar)) {
      setLetterChar(true);
    } else {
      setLetterChar(false);
    }

    if (e.length >= 8) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  };

  return (
    <>
      {userInfo && userInfo.loading && (
        <Spinner fullScreen={true} solid={false} />
      )}

      <PhoneComp>
        <AuthLayoutTitle>{t('password.create_your_password')}</AuthLayoutTitle>

        <form onSubmit={confirmPassword}>
          <InputBackOffice
            id={'password'}
            type={'password'}
            placeholder={t('password.passPlaceholder')}
            name={'password'}
            value={password}
            onChange={(e) => checkPassword(e)}
          />

          <InputBackOffice
            id={'password2'}
            type={'password'}
            placeholder={t('password.confirmPassPlaceholder')}
            name={'password2'}
            value={password2}
            onChange={setPassword2}
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {validation.map((item, index) => {
              if (index === 0) {
                return (
                  <StrengthBar
                    key={index}
                    color={
                      password.length > 0
                        ? letterUpperCase
                          ? color.colors.success500
                          : color.colors.error500
                        : color.colors.neutral400
                    }>
                    <StrengthText
                      color={
                        password.length > 0
                          ? letterUpperCase
                            ? color.colors.success500
                            : color.colors.error500
                          : color.colors.neutral400
                      }>
                      {item.text}
                    </StrengthText>
                  </StrengthBar>
                );
              } else if (index === 1) {
                return (
                  <StrengthBar
                    key={index}
                    color={
                      password.length > 0
                        ? letterChar
                          ? color.colors.success500
                          : color.colors.error500
                        : color.colors.neutral400
                    }>
                    <StrengthText
                      color={
                        password.length > 0
                          ? letterChar
                            ? color.colors.success500
                            : color.colors.error500
                          : color.colors.neutral400
                      }>
                      {item.text}
                    </StrengthText>
                  </StrengthBar>
                );
              } else if (index === 2) {
                return (
                  <StrengthBar
                    key={index}
                    color={
                      password.length > 0
                        ? letterDigit
                          ? color.colors.success500
                          : color.colors.error500
                        : color.colors.neutral400
                    }>
                    <StrengthText
                      color={
                        password.length > 0
                          ? letterDigit
                            ? color.colors.success500
                            : color.colors.error500
                          : color.colors.neutral400
                      }>
                      {item.text}
                    </StrengthText>
                  </StrengthBar>
                );
              } else if (index === 3) {
                return (
                  <StrengthBar
                    key={index}
                    color={
                      password.length > 0
                        ? passLength
                          ? color.colors.success500
                          : color.colors.error500
                        : color.colors.neutral400
                    }>
                    <StrengthText
                      color={
                        password.length > 0
                          ? passLength
                            ? color.colors.success500
                            : color.colors.error500
                          : color.colors.neutral400
                      }>
                      {item.text}
                    </StrengthText>
                  </StrengthBar>
                );
              } else return null;
            })}
          </div>

          <ButtonWrapper>
            <AuthButton
              opacity={
                letterUpperCase &&
                letterDigit &&
                letterChar &&
                passLength &&
                password !== '' &&
                password2 !== ''
              }
              type='submit'
              disabled={
                !letterUpperCase ||
                !letterDigit ||
                !letterChar ||
                !passLength ||
                password === '' ||
                password2 === ''
              }>
              {t('password.continue')}
            </AuthButton>
          </ButtonWrapper>
        </form>
      </PhoneComp>
    </>
  );
};

export default Password;
