import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import notice from '../../../assets/icons/backOffice/Earnings/notice.svg';
import usdtIcon from '../../../assets/icons/backOffice/USDT.svg';
import { InputBackOffice } from '../../../components/common/InputBackoffice';
import { ErrorToast } from '../../../components/common/Toasts/Toasts';
import Spinner from '../../../components/common/Spinner';
import { formatUSD, USDT, wallets } from '../../../helpers/helperFunctions';
import { P3 } from '../../../styles/FontStyles';

import {
  Button,
  ArrowPolygon,
  SectionSeparator
} from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import {
  Icon,
  IconWithdrawWallet,
  IconSettingsCheckboxOff,
  IconSettingsCheckboxOn
} from '../../../styles/Icons';
import {
  BankCard,
  FlexBetween,
  FlexCenter
} from '../../../styles/stylesStyled';
import { SmallTxt, Subtitle, Title } from './WithdrawStyled';
import {
  CollapsableAbsolute,
  Select,
  SelectDropDown
} from '../../Packages/PackagesStyled';

export const WithdrawInitial = ({
  setStep,
  userWallets,
  amount,
  setAmount,
  selected,
  info,
  selectedNetwork,
  setSelectedNetwork,
  loading
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const { t } = useTranslation('earnings');

  const digit = /^(?=.*?[0-9])/;

  return (
    <div style={{ marginTop: '8vh' }}>
      <FlexCenter style={{ flexDirection: 'column' }}>
        <Title>
          {USDT} {t('withdraw_part.initial.title')}
        </Title>

        <Subtitle style={{ textAlign: 'center' }}>
          {t('withdraw_part.initial.subtitle', { USDT: USDT })}
        </Subtitle>
        <InputBackOffice
          placeholder={t('withdraw_part.initial.witdhrawal_amount_placeholder')}
          icon={usdtIcon}
          style={{ marginBottom: 8 }}
          onChange={(e) => {
            setAmount(e.replace(/[.,\s\D]/g, ''));
          }}
          pattern='[0-9]'
          value={amount.replace(/[.,\s\D]/g, '')}
        />
        <FlexBetween style={{ width: '100%', marginBottom: 12 }}>
          <SmallTxt>
            {t('withdraw_part.initial.balance')}:{' '}
            {formatUSD(info?.totalEarnings?.availableBalance)} {USDT}
          </SmallTxt>
          <SmallTxt>{t('withdraw_part.initial.fee')}</SmallTxt>
        </FlexBetween>
        <FlexBetween style={{ width: '100%', marginBottom: 12 }}>
          <P3 bold mb='8' mt='10' color={color.colors.neutral900}>
            Network
          </P3>
        </FlexBetween>
        <Select isOpen={isOpenDropdown}>
          <div
            onClick={() => setIsOpenDropdown(!isOpenDropdown)}
            style={{ cursor: 'pointer' }}>
            <span>
              {selectedNetwork ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  <img
                    src={selectedNetwork.icon}
                    alt='checked'
                    style={{ marginRight: 10, width: 14 }}
                  />

                  <span>{selectedNetwork.wallet}</span>
                </div>
              ) : (
                <div style={{ opacity: 0.3 }}>Select USDT network</div>
              )}
            </span>
            <ArrowPolygon
              direction={isOpenDropdown ? 'down' : 'right'}
              customColor={color.colors.neutral900}
            />
            <CollapsableAbsolute isOpen={isOpenDropdown}>
              <SelectDropDown>
                {wallets.map((wallet, index) => (
                  <div key={index}>
                    <FlexBetween
                      onClick={() => {
                        setSelectedNetwork(wallet);
                        setIsOpenDropdown(!isOpenDropdown);
                      }}
                      key={index}
                      style={{ width: '100%' }}>
                      <FlexCenter>
                        <img
                          src={wallet.icon}
                          alt='checked'
                          style={{ marginRight: 10, width: 14 }}
                        />
                        <span>{wallet.wallet}</span>
                      </FlexCenter>

                      <Icon color={color.colors.neutral900}>
                        {selectedNetwork.index === index ? (
                          <IconSettingsCheckboxOn />
                        ) : (
                          <IconSettingsCheckboxOff />
                        )}
                      </Icon>
                    </FlexBetween>
                    <SectionSeparator margin='0' />
                  </div>
                ))}
              </SelectDropDown>
            </CollapsableAbsolute>
          </div>
        </Select>

        {loading ? (
          <Spinner />
        ) : (
          <BankCard>
            <div>
              <div>
                {userWallets?.length > 0 ? (
                  ''
                ) : (
                  <div style={{ display: 'flex' }}>
                    <img src={notice} alt='card' />
                    {t('withdraw_part.initial.add_address_p1')}

                    <br />
                    {t('withdraw_part.initial.add_address_p2', {
                      USDT: USDT
                    })}
                  </div>
                )}
              </div>
              <FlexCenter>
                {userWallets?.length > 0 && (
                  <Icon mr='10' color={color.colors.neutral900}>
                    <IconWithdrawWallet />
                  </Icon>
                )}

                <P3
                  color={color.colors.neutral900}
                  bold
                  style={{ wordBreak: 'break-all' }}>
                  {userWallets?.length > 0 && userWallets[selected]?.address}
                </P3>
              </FlexCenter>
            </div>
            <Button
              ml='10'
              w='auto'
              h='36px'
              onClick={() => {
                userWallets?.length > 0 ? setStep(4) : setStep(5);
              }}>
              <span>
                {userWallets?.length > 0
                  ? t('withdraw_part.initial.change')
                  : t('withdraw_part.initial.add')}
              </span>
            </Button>
          </BankCard>
        )}

        <Button
          maxWidth='200px'
          onClick={() => {
            if (!amount) {
              ErrorToast(t('withdraw_part.initial.err_amount_required'));
            } else if (userWallets?.length === 0) {
              ErrorToast(t('withdraw_part.initial.err_address_required'));
            } else if (!amount.match(digit)) {
              ErrorToast(t('withdraw_part.initial.err_number_required'));
            } else if (amount > info?.totalEarnings?.availableBalance) {
              ErrorToast(t('withdraw_part.initial.err_no_enough_usdt'));
            } else if (!selectedNetwork) {
              ErrorToast(t('withdraw_part.initial.err_no_network'));
            } else {
              setStep(2);
            }
          }}>
          {t('withdraw_part.initial.withdraw')}
        </Button>
      </FlexCenter>
    </div>
  );
};
