import React from 'react';

import { Password2FA } from './Password2FA';
import { PasswordInit } from './PasswordInit';
import { Success } from './Success';

export const PasswordSteps = ({ step, setStep }) => {
  const steps = (step) => {
    switch (step) {
      case 1:
        return <PasswordInit setStep={setStep} />;
      case 2:
        return <Password2FA setStep={setStep} />;
      case 3:
        return <Success />;
      default:
        return <div></div>;
    }
  };
  return <>{steps(step)}</>;
};
