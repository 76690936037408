import axios from 'axios';
import { SuccessToast } from '../components/common/Toasts/Toasts';
import { GET_CASHBACK, CLAIM_CASHBACK } from '../constants/CashbackConstants';
import { tryExecuteSimpleAction } from './common';

const API_URL = process.env.REACT_APP_API_URL;

export const getCashback = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_CASHBACK.REQUEST,
    async () => {
      const url = `${API_URL}/bonus/cashback/info`;

      const { data } = await axios.get(url);

      dispatch({
        type: GET_CASHBACK.SUCCESS,
        payload: {
          info: data?.data?.info,
          statusPerPackage: data?.data?.statusPerPackage
        }
      });
    },
    GET_CASHBACK.FAIL
  );
};

export const claimCashback = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    CLAIM_CASHBACK.REQUEST,
    async () => {
      const url = `${API_URL}/bonus/cashback/claim`;

      const { data } = await axios.post(url);

      dispatch({
        type: CLAIM_CASHBACK.SUCCESS,
        payload: data
      });

      SuccessToast('Success');
    },
    CLAIM_CASHBACK.FAIL
  );
};
