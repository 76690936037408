import { CaptionText, H4, P1, P2 } from '../../../styles/FontStyles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Flexed, Button } from '../../../styles/GlobalComponents';

import { color } from '../../../styles/GlobalStyles';
import { Icon, IconWarningMark } from '../../../styles/Icons';
import { RESOURCES } from '../../../routes/routes';
import { useHistory } from 'react-router';
import { ReadMore } from '../Portfolio.styled';
import { formatUSD, ULX } from '../../../helpers/helperFunctions';
import Spinner from '../../../components/common/Spinner';

export const ClaimCalculator = ({ packageItem, setClaimCalculator }) => {
  const { t } = useTranslation('my_hubs');
  const history = useHistory();
  const { ulxAmountToBurn, loadingAmountToBurn } = useSelector(
    (state) => state.portfolio
  );
  if (loadingAmountToBurn) return <Spinner solid={false} />;
  return (
    <>
      <Box gray>
        <Flexed column>
          <Flexed column center>
            <Icon mb='12'>
              <IconWarningMark size='50' />
            </Icon>
            <H4 mb='20'>{t('claim.calculation_details')}</H4>
          </Flexed>

          <P1 mb='20' left bold color={color.colors.error400}>
            {t('claim.read_carefully')}
            <br />
          </P1>
          <P1 mb='20' left>
            <b>{t('claim.claimable_amount')}</b>
            {t('claim.claimable_amount_text')}
            <br />
            <br />
            <b>{t('claim.withdrawable_amount')}</b>
            {t('claim.withdrawable_amount_text')}
            <br />
            <br />
            💡 {t('claim.important_claimable')}{' '}
            <a
              href='https://resources.mavie.global/presentations/staking_hub_cap_rule.pdf'
              target='_blank'
              rel='noreferrer'>
              <ReadMore>{t('claim.read_more')}</ReadMore>
            </a>
            <br />
            <br />
          </P1>
          <Box blue>
            <Flexed column>
              <Flexed>
                <P1 mb='10' mr='5' color={color.colors.neutral500}>
                  {t('claim.claimable_amount_value')}
                </P1>
                <P1 semiBold>
                  {' '}
                  {formatUSD(packageItem?.availableStake)} {ULX}
                </P1>
              </Flexed>
              {/* <Flexed>
                <P1 mb='10' mr='5' color={color.colors.neutral500}>
                  {t('claim.withdrawable_amount_value')}
                </P1>
                <P1 semiBold>
                  {' '}
                  {formatUSD(packageItem?.availableStake)} {ULX}
                </P1>
              </Flexed> */}
              <Flexed>
                <P1 mb='10' mr='5' color={color.colors.error400}>
                  {t('claim.amount_burned_value')}
                </P1>
                <P1 semiBold color={color.colors.error400}>
                  {formatUSD(ulxAmountToBurn)} {ULX}
                </P1>
              </Flexed>
            </Flexed>
          </Box>
          <H4 mb='5'>
            {t('claim.total_available_to_withdraw')}{' '}
            {formatUSD(packageItem?.availableStake - ulxAmountToBurn)} {ULX}
          </H4>
          <CaptionText mb='20' center style={{ fontStyle: 'italic' }}>
            ({t('claim.received_to_your_wallet')})
          </CaptionText>
          <Flexed column>
            <P2 mb='5' semiBold color={color.colors.success500}>
              {t('claim.keep_autostake_on')}
            </P2>
            <P2 ml='5' semiBold color={color.colors.success500}>
              {t('claim.receive_payout')}
            </P2>
            <P2 ml='5' semiBold color={color.colors.success500}>
              {t('claim.no_ulx_burned')}
            </P2>
          </Flexed>
        </Flexed>
      </Box>
      <Flexed>
        <Button onClick={() => history.push(RESOURCES)} mr='10' mt='10'>
          {t('claim.go_to_resources')}
        </Button>
        <Button
          onClick={() => setClaimCalculator(false)}
          ml='10'
          mt='10'
          color={color.colors.success400}>
          {t('claim.back_to_my_hubs')}
        </Button>
      </Flexed>
    </>
  );
};
