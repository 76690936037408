import {
  GET_SUPPORT_CATEGORIES,
  GET_SUBCATEGORY_WITH_QUESTION,
  GET_CATEGORY_WITH_QUESTION,
  GET_QUESTION,
  SET_BREADCRUMBS,
  SEARCH_QUESTION,
  CLEAR_QUESTIONS
} from './helpDesk.constants';

import { CLEAR_ALERT } from '../../constants/errorConstants';

export const supportCategories = (
  state = {
    categories: [],
    category: [],
    question: {},
    subCategory: {},
    breadCrumbs: [],
    questions: []
  },
  action
) => {
  switch (action.type) {
    case GET_SUPPORT_CATEGORIES.REQUEST:
      return {
        ...state,
        category: {},
        question: {},
        subCategory: {},
        loading: true
      };

    case GET_SUPPORT_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
        category: action.payload.category
      };

    case GET_SUPPORT_CATEGORIES.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case GET_CATEGORY_WITH_QUESTION.REQUEST:
      return {
        ...state,
        categoryQuestionLoading: true,
        subCategory: {},
        breadCrumbs: [],
        question: {}
      };

    case GET_CATEGORY_WITH_QUESTION.SUCCESS:
      return {
        ...state,
        categoryQuestionLoading: false,
        category: action.payload
      };

    case GET_CATEGORY_WITH_QUESTION.FAIL:
      return {
        ...state,
        categoryQuestionLoading: false,
        error: action.payload
      };

    case GET_SUBCATEGORY_WITH_QUESTION.REQUEST:
      return {
        ...state,
        categoryQuestionLoading: true
      };

    case GET_SUBCATEGORY_WITH_QUESTION.SUCCESS:
      return {
        ...state,
        categoryQuestionLoading: false,
        subCategory: action.payload
      };

    case GET_QUESTION.REQUEST:
      return {
        ...state,
        questionLoading: true,
        subCategory: {},
        category: {}
      };

    case GET_QUESTION.SUCCESS:
      return {
        ...state,
        questionLoading: false,
        question: action.payload
      };

    case GET_QUESTION.FAIL:
      return {
        ...state,
        questionLoading: false,
        error: action.payload
      };

    case SEARCH_QUESTION.REQUEST:
      return {
        ...state
      };

    case SEARCH_QUESTION.SUCCESS:
      return {
        ...state,
        questions: action.payload
      };

    case SEARCH_QUESTION.FAIL:
      return {
        ...state,
        error: action.payload
      };

    case CLEAR_QUESTIONS:
      return {
        ...state,
        questions: []
      };

    case SET_BREADCRUMBS:
      return {
        ...state,
        breadCrumbs: [...state.breadCrumbs, action.payload]
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    default:
      return state;
  }
};
