import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../actions/authActions';
import { InputBackOffice } from '../../components/common/InputBackoffice';
import Spinner from '../../components/common/Spinner';
import { ErrorToast } from '../../components/common/Toasts/Toasts';
import { AuthLayoutTitle } from '../../styles/GlobalComponents';

import {
  AuthButton,
  ButtonWrapper,
  FloatingButton,
  PhoneComp
} from '../../styles/stylesStyled';
import { StrengthBar, StrengthText } from '../Password/PasswordStyled';

const captchaId = process.env.REACT_APP_CAPTCHA_ID;

const Password = ({ location, history }) => {
  const { t } = useTranslation('onboarding.forgoten_password_new');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [letterUpperCase, setLetterUpperCase] = useState(false);
  const [letterDigit, setLetterDigit] = useState(false);
  const [letterChar, setLetterChar] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const validation = [
    { text: t('uppercase'), strength: 1 },
    { text: t('special_letter'), strength: 2 },
    { text: t('number'), strength: 3 },
    { text: t('8+character'), strength: 4 }
  ];

  const upperCase = /^(?=.*?[A-Z])/;
  const digit = /^(?=.*?[0-9])/;
  const specChar = /(?=.*?[#?!@$%^&*-])/;

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.forgottenCredentials);

  const code = location.search.split('=')[1];

  const checkPassword = (e) => {
    setPassword(e);

    if (e.match(upperCase)) {
      setLetterUpperCase(true);
    } else {
      setLetterUpperCase(false);
    }

    if (e.match(digit)) {
      setLetterDigit(true);
    } else {
      setLetterDigit(false);
    }

    if (e.match(specChar)) {
      setLetterChar(true);
    } else {
      setLetterChar(false);
    }

    if (e.length >= 8) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  };

  const confirmResetPassword = () => {
    if (password !== password2) {
      ErrorToast(t('passwords_do_not_match'));
    } else if (!password || !password2) {
      ErrorToast(t('passwords_field_is_empty'));
    } else {
      window.initGeetest4(
        {
          captchaId,
          language: 'eng',
          product: 'bind',
          riskType: 'slide',
          nativeButton: {
            width: '100%',
            marginBottom: '20px'
          }
        },
        function (captcha) {
          captcha.showBox();
          captcha
            .onReady(function () {})
            .onSuccess(function () {
              const result = captcha.getValidate();
              dispatch(resetPassword(code, password, result, history));
            })
            .onError(function () {});
        }
      );
    }
  };

  return (
    <PhoneComp>
      {loading ? (
        <Spinner fullScreen={true} />
      ) : (
        <>
          <AuthLayoutTitle>{t('title')}</AuthLayoutTitle>
          <InputBackOffice
            id={'password'}
            type={'password'}
            placeholder={t('passPlaceholder')}
            name={'password'}
            value={password}
            onChange={(e) => checkPassword(e)}
            label={'Password'}
          />
          <InputBackOffice
            id={'password2'}
            type={'password'}
            placeholder={t('confirmPassPlaceholder')}
            name={'password2'}
            value={password2}
            onChange={setPassword2}
            label={'Password'}
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {validation.map((item, index) => {
              if (index === 0) {
                return (
                  <StrengthBar
                    key={index}
                    color={
                      password.length > 0
                        ? letterUpperCase
                          ? 'green'
                          : 'red'
                        : '#d8d1d1'
                    }>
                    <StrengthText
                      color={
                        password.length > 0
                          ? letterUpperCase
                            ? 'green'
                            : 'red'
                          : '#d8d1d1'
                      }>
                      {item.text}
                    </StrengthText>
                  </StrengthBar>
                );
              } else if (index === 1) {
                return (
                  <StrengthBar
                    key={index}
                    color={
                      password.length > 0
                        ? letterChar
                          ? 'green'
                          : 'red'
                        : '#d8d1d1'
                    }>
                    <StrengthText
                      color={
                        password.length > 0
                          ? letterChar
                            ? 'green'
                            : 'red'
                          : '#d8d1d1'
                      }>
                      {item.text}
                    </StrengthText>
                  </StrengthBar>
                );
              } else if (index === 2) {
                return (
                  <StrengthBar
                    key={index}
                    color={
                      password.length > 0
                        ? letterDigit
                          ? 'green'
                          : 'red'
                        : '#d8d1d1'
                    }>
                    <StrengthText
                      color={
                        password.length > 0
                          ? letterDigit
                            ? 'green'
                            : 'red'
                          : '#d8d1d1'
                      }>
                      {item.text}
                    </StrengthText>
                  </StrengthBar>
                );
              } else if (index === 3) {
                return (
                  <StrengthBar
                    key={index}
                    color={
                      password.length > 0
                        ? passLength
                          ? 'green'
                          : 'red'
                        : '#d8d1d1'
                    }>
                    <StrengthText
                      color={
                        password.length > 0
                          ? passLength
                            ? 'green'
                            : 'red'
                          : '#d8d1d1'
                      }>
                      {item.text}
                    </StrengthText>
                  </StrengthBar>
                );
              }
            })}
          </div>

          <FloatingButton>
            <ButtonWrapper>
              <AuthButton
                opacity={password && password2}
                disabled={
                  (!letterUpperCase &&
                    letterDigit &&
                    letterChar &&
                    passLength &&
                    password === '') ||
                  password2 === ''
                    ? true
                    : false
                }
                onClick={confirmResetPassword}>
                {t('continue')}
              </AuthButton>
            </ButtonWrapper>
          </FloatingButton>
        </>
      )}
    </PhoneComp>
  );
};

export default Password;
