import React from 'react';
import { useSelector } from 'react-redux';
import { Title, SubTitle } from './SettingsStyled';
import { Button } from '../../styles/GlobalComponents';
import { FlexCenter, SelectedAddress } from '../../styles/stylesStyled';
import { useTranslation } from 'react-i18next';
import { color } from '../../styles/GlobalStyles';
import { Icon, IconCopy } from '../../styles/Icons';
import { SuccessToast } from '../../components/common/Toasts/Toasts';

export const VerificationSuccess = ({ setIsOpen }) => {
  const { t } = useTranslation('settings');
  const { backupKey } = useSelector((state) => state.settings);
  return (
    <FlexCenter style={{ flexDirection: 'column' }}>
      <Title>{t('security.factor_activated')}</Title>
      <SubTitle style={{ textAlign: 'center', marginBottom: 0 }}>
        {t('security.factor_activated_message')}
      </SubTitle>
      <SelectedAddress
        onClick={() => {
          navigator.clipboard.writeText(backupKey);
          SuccessToast(t('security.success_copied'));
        }}
        style={{ cursor: 'pointer' }}>
        <div className='truncated'>
          <div>{backupKey}</div>
        </div>
        <Icon ml='10' color={color.colors.neutral900}>
          <IconCopy />
        </Icon>
      </SelectedAddress>
      <SubTitle style={{ textAlign: 'center' }}>
        {t('security.factor_key_message')}
      </SubTitle>
      <Button
        maxWidth='300px'
        h='50px'
        m={'30px 0 0 0'}
        opacity={'true'}
        onClick={() => setIsOpen(false)}>
        {t('security.finish')}
      </Button>
    </FlexCenter>
  );
};
