export const GET_BACK_OFFICE_REQUEST = 'GET_BACK_OFFICE_REQUEST';
export const GET_BACK_OFFICE_SUCCESS = 'GET_BACK_OFFICE_SUCCESS';
export const GET_BACK_OFFICE_FAIL = 'GET_BACK_OFFICE_FAIL';

export const GET_LATEST_ORDERS_REQUEST = 'GET_LATEST_ORDERS_REQUEST';
export const GET_LATEST_ORDERS_SUCCESS = 'GET_LATEST_ORDERS_SUCCESS';
export const GET_LATEST_ORDERS_FAIL = 'GET_LATEST_ORDERS_FAIL';

export const GET_LAST_REGISTERED_USERS_REQUEST =
  'GET_LAST_REGISTERED_USERS_REQUEST';
export const GET_LAST_REGISTERED_USERS_SUCCESS =
  'GET_LAST_REGISTERED_USERS_SUCCESS';
export const GET_LAST_REGISTERED_USERS_FAIL = 'GET_LAST_REGISTERED_USERS_FAIL';

export const GET_JOINED_AFTER_USERS_REQUEST = 'GET_JOINED_AFTER_USERS_REQUEST';
export const GET_JOINED_AFTER_USERS_SUCCESS = 'GET_JOINED_AFTER_USERS_SUCCESS';
export const GET_JOINED_AFTER_USERS_FAIL = 'GET_JOINED_AFTER_USERS_FAIL';

export const CHECK_SIDEMODAL_REQUEST = 'CHECK_SIDEMODAL_REQUEST';
export const CHECK_SIDEMODAL_SUCCESS = 'CHECK_SIDEMODAL_SUCCESS';
export const CHECK_SIDEMODAL_FAIL = 'CHECK_SIDEMODAL_FAIL';
