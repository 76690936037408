import styled from 'styled-components';

const View = styled.div`
  margin: 20px 0;
  padding-bottom: 70px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    max-width: 1500px;
    padding: 0 100px;
  }
  @media only screen and (max-width: 1500px) and (min-width: 800px) {
    zoom: 80%;
  }
`;

const Invoices = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: ${(props) => props.theme.colors.neutral500};
  font-size: 15px;
  background: ${({ theme }) => theme.colors.neutral50};
  margin-bottom: 20px;
  padding: 10px 20px;
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  border-radius: 8px;
  span {
    color: ${(props) => props.theme.colors.neutral900};
    font-weight: 600;
  }
`;

const InvoicesMobile = styled.div`
  padding: 20px 0;
  color: ${(props) => props.theme.colors.neutral900};
  font-size: 14px;
  line-height: 20px;

  background: ${({ theme }) => theme.colors.neutral50};
  margin-bottom: 20px;
  padding: 10px 20px;
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InvoiceHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 17px;
  color: ${(props) => props.theme.colors.neutral900};
  font-weight: 600;
`;

export { Invoices, InvoiceHeader, InvoicesMobile, View };
