import React from 'react';
import { P3 } from '../../styles/FontStyles';
import { BoxPortfolio } from './Portfolio.styled';
import { showWallet } from '../../helpers/helperFunctions';
import { Desktop, Mobile } from '../../App';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const Wallet = () => {
  const { t } = useTranslation('my_hubs');

  const EXPLORER_LINK = process.env.REACT_APP_EXPLORER_LINK;
  const { wallet } = useSelector((state) => state.portfolio);

  if (!wallet) return null;
  return (
    <div style={{ textAlign: 'right' }}>
      <Desktop>
        <P3 style={{ marginBottom: 10 }}>{t('my_wallet')}</P3>

        <a
          href={`${EXPLORER_LINK}/address/${wallet}`}
          target='_blank'
          rel='noreferrer'>
          <BoxPortfolio style={{ borderRadius: 8 }}>{wallet}</BoxPortfolio>
        </a>
      </Desktop>

      <Mobile>
        <a
          href={`${EXPLORER_LINK}/address/${wallet}`}
          target='_blank'
          rel='noreferrer'>
          <BoxPortfolio style={{ borderRadius: 8 }}>
            {showWallet(wallet, -20)}
          </BoxPortfolio>
        </a>
      </Mobile>
    </div>
  );
};
