import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUSD } from '../../helpers/helperFunctions';
import { CaptionText, H3, P1, P3 } from '../../styles/FontStyles';
import { Button, Flexed } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  Icon,
  IconPortfolioOrders,
  IconPortfolioWallet,
  IconPortfolioDistribution
} from '../../styles/Icons';
import { BoxPortfolio, SummaryGrid } from './Portfolio.styled';
import { Withdraw } from './Withdraw/Withdraw';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarModal } from '../../components/common/Sidebar/Sidebar';
import { settingsInit } from '../SettingsBackoffice/settings.actions';
import { Wallet } from './Wallet';

export const WalletSummary = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('my_hubs');
  const [step, setStep] = useState(1);
  const [withdraw, setWithdraw] = useState(false);

  const { walletSummary, withdrawAvailability } = useSelector(
    (state) => state.portfolio
  );

  return (
    <>
      <Flexed mb='20' between alignEnd rowSM alignStartSM gap='16px'>
        <H3>{t('my_wallet_summary')}</H3>
        <Wallet />
      </Flexed>
      <SummaryGrid>
        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioOrders />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('total_personal_orders')}
            </CaptionText>

            <P1 bold flex alignEnd gap='5px'>
              {formatUSD(walletSummary?.totalOrder)} <P3 mb='1'>USDT</P3>
            </P1>
          </div>
        </BoxPortfolio>

        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioDistribution />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('total_orders_with_autostake_intact')}
            </CaptionText>

            <P1 bold flex alignEnd gap='5px'>
              {formatUSD(walletSummary?.totalOrderWithAutostakeIntactAmount)}
              <P3 mb='1'>USDT</P3>
            </P1>
          </div>
        </BoxPortfolio>

        <BoxPortfolio hasButton>
          <Flexed center>
            <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
              <IconPortfolioWallet />
            </Icon>
            <div>
              <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                {t('available_in_wallet')}
              </CaptionText>
              <P1 bold flex alignEnd gap='5px'>
                {formatUSD(walletSummary?.totalAvailable)}
                <P3 mb='1'>wULX</P3>
              </P1>
            </div>
          </Flexed>
          <Button
            w='100px'
            h='34px'
            disabled={walletSummary?.totalAvailable < 1}
            onClick={() => {
              if (withdrawAvailability.isAvailable) {
                setWithdraw(true);
                setStep(1);
                dispatch(settingsInit());
              } else {
                setWithdraw(true);
                setStep(9);
              }
            }}>
            {t('withdraw')}
          </Button>
        </BoxPortfolio>
      </SummaryGrid>
      <SidebarModal
        isOpen={withdraw}
        setOpen={setWithdraw}
        title={`wULX ${t('withdrawal')}`}
        sidebarWidth={'508px'}>
        <Withdraw
          step={step}
          setStep={setStep}
          withdraw={withdraw}
          setWithdraw={setWithdraw}
        />
      </SidebarModal>
    </>
  );
};
