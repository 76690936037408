import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SuccessToast } from '../../components/common/Toasts/Toasts';
import {
  formatUSD,
  showNetworkType,
  USDT
} from '../../helpers/helperFunctions';

import { useHistory } from 'react-router-dom';

import { P3 } from '../../styles/FontStyles';
import { ORDERS } from '../../routes/routes';
import { color } from '../../styles/GlobalStyles';
import { Icon, IconCopy } from '../../styles/Icons';
import { SelectedUSDT } from '../Marketplace/Marketplace.styled';
// import { CreditCard } from '../../components/common/CreditCard';
import { Button, Flexed } from '../../styles/GlobalComponents';

export const PaymentSuccess = ({ selectedHub, selected }) => {
  const { t } = useTranslation('packages');
  const history = useHistory();

  const { licence } = useSelector((state) => state.settings);
  const { userPackage } = useSelector((state) => state.hubs);

  return (
    <>
      <P3 mb='8' mt='20' color={color.colors.neutral900}>
        {t('success.text1')}
        <br />
        <br />
        {t('success.text2')}
        <br />
        <br />
        {t('success.text3')}
      </P3>

      <br />

      <div style={{ marginBottom: 20 }}>
        <P3 bold mb='8' color={color.colors.neutral900}>
          {t('success.payment_amount')}
        </P3>
        <SelectedUSDT>
          <div className='truncated'>
            <div>
              {selectedHub
                ? formatUSD(userPackage?.price + selected?.price)
                : formatUSD(licence?.price)}
            </div>
          </div>
          <b>{USDT}</b>
        </SelectedUSDT>
      </div>

      <div style={{ marginBottom: 20 }}>
        <P3 bold mb='8' color={color.colors.neutral900}>
          {t('selected')} {t('success.network')}
        </P3>
        <SelectedUSDT>
          <div className='truncated'>
            <div>
              {selectedHub
                ? showNetworkType(userPackage)
                : showNetworkType(licence)}
            </div>
          </div>
        </SelectedUSDT>
      </div>

      <P3 bold mb='8' color={color.colors.neutral900}>
        {t('success.wallet_address')}
      </P3>

      <SelectedUSDT
        color={'#ffffff'}
        onClick={() => {
          navigator.clipboard.writeText(
            selectedHub ? userPackage?.paymentAddress : licence?.paymentAddress
          );
          SuccessToast(t('success.copied'));
        }}
        style={{ cursor: 'pointer' }}>
        <div className='truncated'>
          <div>
            {selectedHub
              ? userPackage?.paymentAddress
              : licence?.paymentAddress}
          </div>
        </div>

        <Icon ml='10' color={color.colors.neutral900}>
          <IconCopy />
        </Icon>
      </SelectedUSDT>
      <Flexed justifyCenter mt='40'>
        <Button
          onClick={() => {
            history.push(ORDERS);
          }}
          maxWidth='200px'>
          Close
        </Button>
      </Flexed>
      {/* {selectedHub ? (
        userPackage?.networkType === 1 ? (
          <CreditCard
            paymentAddress={
              selectedHub
                ? userPackage?.paymentAddress
                : licence?.paymentAddress
            }
            price={
              selectedHub
                ? userPackage?.price + selected?.price
                : licence?.price
            }
            isPayment={true}
          />
        ) : (
          <Flexed justifyCenter mt='40'>
            <Button
              onClick={() => {
                history.push(ORDERS);
              }}
              maxWidth='200px'>
              Close
            </Button>
          </Flexed>
        )
      ) : licence?.networkType === 1 ? (
        <CreditCard
          paymentAddress={
            selectedHub ? userPackage?.paymentAddress : licence?.paymentAddress
          }
          price={
            selectedHub ? userPackage?.price + selected?.price : licence?.price
          }
          isPayment={true}
        />
      ) : (
        <Flexed justifyCenter mt='40'>
          <Button
            onClick={() => {
              history.push(ORDERS);
            }}
            maxWidth='200px'>
            Close
          </Button>
        </Flexed>
      )} */}
    </>
  );
};
