export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const SET_USER_TO_AMBASSADOR_REQUEST = 'SET_USER_TO_AMBASSADOR_REQUEST';
export const SET_USER_TO_AMBASSADOR_SUCCESS = 'SET_USER_TO_AMBASSADOR_SUCCESS';
export const SET_USER_TO_AMBASSADOR_FAIL = 'SET_USER_TO_AMBASSADOR_FAIL';

export const GET_TEAM_USER_INFO_REQUEST = 'GET_TEAM_USER_INFO_REQUEST';
export const GET_TEAM_USER_INFO_SUCCESS = 'GET_TEAM_USER_INFO_SUCCESS';
export const GET_TEAM_USER_INFO_FAIL = 'GET_TEAM_USER_INFO_FAIL';

export const GET_USER_SETTINGS_REQUEST = 'GET_USER_SETTINGS_REQUEST';
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_FAIL = 'GET_USER_SETTINGS_FAIL';

export const UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAIL = 'UPDATE_USER_SETTINGS_FAIL';

export const GET_REFERRER_INFO_REQUEST = 'GET_REFERRER_INFO_REQUEST';
export const GET_REFERRER_INFO_SUCCESS = 'GET_REFERRER_INFO_SUCCESS';
export const GET_REFERRER_INFO_FAIL = 'GET_REFERRER_INFO_FAIL';
