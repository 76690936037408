import { tryExecuteSimpleAction } from '../../../actions/common';

import {
  CREATE_WITHDRAW_PARTNER,
  CREATE_WITHDRAW_AMBASSADOR,
  ADD_TOP_UP,
  DELETE_USER_WALLET,
  WITHDRAW_EXCHANGE,
  CREATE_WITHDRAW_EXPLORER,
  EDIT_USER_WALLET,
  CREATE_WITHDRAW_EMAIL_VERIFICATION,
  REACTIVATE_USER_WALLET
} from './Withdraw.constants';

import {
  createWithdrawPartnerAsync,
  createWithdrawAmbassadorAsync,
  addTopUpAsync,
  deleteUserWalletAsync,
  withdrawExchangeAsync,
  createWithdrawExplorerAsync,
  editUserWalletAsync,
  createWithdrawVerificationEmailAsync,
  createWithdrawExplorerVerificationEmailAsync,
  reactivateUserWalletAsync
} from './Withdraw.request';

import { init } from '../earnignsBackoffice.actions';

import { SuccessToast } from '../../../components/common/Toasts/Toasts';

export const reactivateUserWallet = (id) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    REACTIVATE_USER_WALLET.REQUEST,
    async () => {
      await reactivateUserWalletAsync(id);

      dispatch({
        type: REACTIVATE_USER_WALLET.SUCCESS,
        payload: id
      });
      SuccessToast('Successfully reactivated wallet');
    },
    REACTIVATE_USER_WALLET.FAIL
  );
};

export const deleteUserWallet = (id) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    DELETE_USER_WALLET.REQUEST,
    async () => {
      await deleteUserWalletAsync(id);

      dispatch({
        type: DELETE_USER_WALLET.SUCCESS,
        payload: id
      });

      SuccessToast('Successfully deactivated wallet');
    },
    DELETE_USER_WALLET.FAIL
  );
};

export const editUserWallet = (id, label, setIsOpen4) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    EDIT_USER_WALLET.REQUEST,
    async () => {
      const { data } = await editUserWalletAsync(id, label);

      dispatch({
        type: EDIT_USER_WALLET.SUCCESS,
        payload: data.data.userWallet
      });

      setIsOpen4(false);
    },
    EDIT_USER_WALLET.FAIL
  );
};

export const createWithdrawExplorer =
  (amount, userWalletId, setStep, emailCode, twoFACode, setAmount) =>
  async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CREATE_WITHDRAW_EXPLORER.REQUEST,
      async () => {
        await createWithdrawExplorerAsync(
          amount,
          userWalletId,
          emailCode,
          twoFACode
        );

        setAmount('');

        setStep(3);

        dispatch({
          type: CREATE_WITHDRAW_EXPLORER.SUCCESS,
          payload: amount
        });
      },
      CREATE_WITHDRAW_EXPLORER.FAIL
    );
  };

export const createWithdrawPartner =
  (
    amount,
    userWalletId,
    setStep,
    emailCode,
    twoFACode,
    networkType,
    setAmount
  ) =>
  async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CREATE_WITHDRAW_PARTNER.REQUEST,
      async () => {
        await createWithdrawPartnerAsync(
          amount,
          userWalletId,
          emailCode,
          twoFACode,
          networkType
        );

        setAmount('');

        setStep(3);

        dispatch({
          type: CREATE_WITHDRAW_PARTNER.SUCCESS,
          payload: amount
        });
      },
      CREATE_WITHDRAW_PARTNER.FAIL
    );
  };

export const createWithdrawEmailVerification =
  (amount, userWalletId, setStep, code) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CREATE_WITHDRAW_EMAIL_VERIFICATION.REQUEST,
      async () => {
        await createWithdrawVerificationEmailAsync(amount, userWalletId, code);

        setStep(3);

        dispatch({
          type: CREATE_WITHDRAW_EMAIL_VERIFICATION.SUCCESS,
          payload: amount
        });
      },
      CREATE_WITHDRAW_EMAIL_VERIFICATION.FAIL
    );
  };

export const createWithdrawEmailExplorerVerification =
  (amount, userWalletId, setStep, setAmount, code) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CREATE_WITHDRAW_PARTNER.REQUEST,
      async () => {
        await createWithdrawExplorerVerificationEmailAsync(
          amount,
          userWalletId,
          code
        );

        setStep(3);

        dispatch({
          type: CREATE_WITHDRAW_PARTNER.SUCCESS,
          payload: amount
        });

        // dispatch(init(user?.userRank?.rank?.name));

        setAmount('');
      },
      CREATE_WITHDRAW_PARTNER.FAIL
    );
  };

export const createWithdrawAmbassador =
  (amount, userBankAccountId, currency, setStep) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CREATE_WITHDRAW_AMBASSADOR.REQUEST,
      async () => {
        await createWithdrawAmbassadorAsync(
          amount,
          userBankAccountId,
          currency
        );

        setStep(3);

        dispatch({
          type: CREATE_WITHDRAW_AMBASSADOR.SUCCESS
        });
      },
      CREATE_WITHDRAW_AMBASSADOR.FAIL
    );
  };

export const addTopUp =
  (amount, name, cvc, cardNumber, expireDate, setStep) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      ADD_TOP_UP.REQUEST,
      async () => {
        await addTopUpAsync(amount, name, cvc, cardNumber, expireDate);

        setStep(5);

        dispatch({
          type: ADD_TOP_UP.SUCCESS
        });

        dispatch(init(1, 50));
      },
      ADD_TOP_UP.FAIL
    );
  };

export const withdrawExchange = (amount, setStep) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    WITHDRAW_EXCHANGE.REQUEST,
    async () => {
      await withdrawExchangeAsync(amount);

      dispatch({
        type: WITHDRAW_EXCHANGE.SUCCESS
      });
      dispatch(init(1, 50));
      setStep(9);
    },
    WITHDRAW_EXCHANGE.FAIL
  );
};
