import React from 'react';
import { CheckText } from '../../components/common/CheckText';
import { H5, P1 } from '../../styles/FontStyles';
import { Box, Button, Flexed } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { Icon, IconWarningMark } from '../../styles/Icons';

export const WarningBox = ({
  text,
  virtualOfficeButtonText,
  buttonText,
  virtualOfficeButtonFn,
  buttonFn
}) => {
  return (
    <>
      <Box blue>
        <Flexed column center>
          <Icon mb='12'>
            <IconWarningMark size='50' />
          </Icon>
          <H5 mb='20' center color={color.colors.neutral500}>
            WARNING
          </H5>
          <P1 mb='20' bold>
            {text}
            <br />
          </P1>

          <Flexed column>
            <CheckText text='Active Status' />
            <CheckText text='Mining Volume accumulation' />
            <CheckText text='Receive the bonuses' />
          </Flexed>
        </Flexed>
      </Box>
      <Button onClick={buttonFn}> {buttonText} </Button>
      <Button onClick={virtualOfficeButtonFn} textButton>
        {virtualOfficeButtonText}
      </Button>
    </>
  );
};
