import React, { useEffect } from 'react';
import Spinner from '../../components/common/Spinner';
import { Button, Flexed, PageTitle } from '../../styles/GlobalComponents';
import { useDispatch, useSelector } from 'react-redux';
import { claimCashback, getCashback } from '../../actions/CashbackActions';
import { AnimatedDashboard } from '../../components/animation/AnimatedDashboard';
import {
  SectionInfo,
  Section1,
  Section2,
  Section3,
  Section4
} from './sections';

export const CashbackBonus = () => {
  const dispatch = useDispatch();

  const { cashback, statusPerPackage, loading } = useSelector(
    (state) => state.cashback
  );

  useEffect(() => {
    dispatch(getCashback());
  }, [dispatch]);

  return (
    <AnimatedDashboard zoom>
      <>
        {loading ? (
          <Spinner fullScreen={true} dark={true} />
        ) : (
          <div style={{ marginBottom: 100 }}>
            <Flexed between center>
              <PageTitle>Fast Start bonus</PageTitle>
              <Button
                onClick={() => dispatch(claimCashback())}
                maxWidth='200px'
                disabled={!cashback.canClaimCashbackBonus}>
                Claim Fast Start
              </Button>
            </Flexed>
            <SectionInfo />
            <Section1 cashback={cashback} />
            <Section2 cashback={cashback} statusPerPackage={statusPerPackage} />
            <Section3 cashback={cashback} />
            <Section4 />
          </div>
        )}
      </>
    </AnimatedDashboard>
  );
};
