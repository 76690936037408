import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatUSD, USDT, wallets } from '../../../helpers/helperFunctions';
import { P1, P3, H2, CaptionText } from '../../../styles/FontStyles';
import {
  ArrowPolygon,
  Button,
  SectionSeparator,
  Flexed
} from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import {
  Icon,
  IconSettingsCheckboxOff,
  IconSettingsCheckboxOn
} from '../../../styles/Icons';
import { FlexBetween, FlexCenter } from '../../../styles/stylesStyled';

import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../../components/common/Checkbox/Checkbox';
import { Hub } from '../../../components/common/Hub/Hub';
import {
  CollapsableAbsolute,
  Select,
  SelectDropDown,
  SelectedUSDT
} from '../../Packages/PackagesStyled';
import { addHub } from '../../Marketplace/Marketplace.actions';
// import { CreditCard } from '../../../components/common/CreditCard';

export const Step2 = ({
  step,
  setStep,
  selectedHub,
  selectedNetwork,
  setSelectedNetwork,
  isFirstGiftAvailable
}) => {
  const { t } = useTranslation('marketplace');

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const [checkbox, setCheckbox] = useState(false);

  const dispatch = useDispatch();

  const { hubs } = useSelector((state) => state.hubs);

  const {
    id,
    image,
    title,
    price,
    weeklyCap,
    packageId,
    firstGift,
    limitedQuantity,
    calculator
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = hubs?.find((i) => i.id === selectedHub);

  const sendingTokenType = (selectedNetwork) => {
    switch (selectedNetwork.networkType) {
      case 1:
        return (
          <div>
            <b>{t('payment_method.important')}:</b> <br />
            {t('payment_method.text1')} <b>BEP-20 </b>
            {t('payment_method.text2')} <b>{t('payment_method.text3')}</b>{' '}
            {t('payment_method.text4')}
          </div>
        );

      case 2:
        return (
          <div>
            <b>{t('payment_method.important')}:</b> <br />
            {t('payment_method.text11')}
            <b> Tron Network TRC-20 </b>
            {t('payment_method.text2')} <b>{t('payment_method.text3')}</b>
            {t('payment_method.text4')}
          </div>
        );

      case 3:
        return (
          <div>
            <b>{t('payment_method.important')}:</b> <br />
            {t('payment_method.text11')}
            <b> Ultron Chain (ULC-20) </b>
            {t('payment_method.text2')} <b>{t('payment_method.text3')}</b>
            {t('payment_method.text4')}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div>
      <P1 bold mb={20}>
        {t('selected_staking_hub_nft')}:
      </P1>

      <Hub
        key={id}
        condensed
        image={image}
        title={title}
        price={price}
        weeklyCap={weeklyCap}
        limitedQuantity={limitedQuantity}
        info={
          <>
            {calculator && (
              <Flexed alignEnd end>
                <H2 bold mr='16' alignEnd>
                  {calculator}
                </H2>
                <CaptionText bold right mb='4'>
                  Reward
                  <br /> Multiplicator
                </CaptionText>
              </Flexed>
            )}
          </>
        }
        stakingHubDetails={
          <>
            {isFirstGiftAvailable && (
              <>
                <P3 bold color={color.colors.primary900}>
                  {t('first_order_gift')} 🎁
                </P3>
                <P3 bold>
                  {firstGift} {t('of_free_activity')}
                </P3>
              </>
            )}
          </>
        }
      />

      <P3 mb='8'>{t('payment_method.small_text2')}</P3>

      <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
        {t('payment_method.payment_ammount')}
      </P3>

      <SelectedUSDT>
        {formatUSD(price)} <span>{USDT}</span>
      </SelectedUSDT>

      <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
        {t('select_network')}
      </P3>

      <Select isOpen={isOpenDropdown}>
        <div
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
          style={{ cursor: 'pointer' }}>
          <span>
            {selectedNetwork ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}>
                <img
                  src={selectedNetwork.icon}
                  alt='checked'
                  style={{ marginRight: 10, width: 14 }}
                />

                <span>{selectedNetwork.wallet}</span>
              </div>
            ) : (
              <div style={{ opacity: 0.3 }}>{t('select_network')}</div>
            )}
          </span>
          <ArrowPolygon
            direction={isOpenDropdown ? 'down' : 'right'}
            customColor={color.colors.neutral900}
          />
          <CollapsableAbsolute isOpen={isOpenDropdown}>
            <SelectDropDown>
              {wallets.map((wallet, index) => (
                <div key={index}>
                  <FlexBetween
                    onClick={() => {
                      setSelectedNetwork(wallet);
                      setIsOpenDropdown(!isOpenDropdown);
                    }}
                    key={index}
                    style={{ width: '100%' }}>
                    <FlexCenter>
                      <img
                        src={wallet.icon}
                        alt='checked'
                        style={{ marginRight: 10, width: 14 }}
                      />
                      <span>{wallet.wallet}</span>
                    </FlexCenter>

                    <Icon color={color.colors.neutral900}>
                      {selectedNetwork.index === index ? (
                        <IconSettingsCheckboxOn />
                      ) : (
                        <IconSettingsCheckboxOff />
                      )}
                    </Icon>
                  </FlexBetween>
                  <SectionSeparator margin='0' />
                </div>
              ))}
            </SelectDropDown>
          </CollapsableAbsolute>
        </div>
      </Select>
      {selectedNetwork && (
        <P3 bold mb='25' color={color.colors.error400}>
          {sendingTokenType(selectedNetwork)}
        </P3>
      )}

      {selectedNetwork && (
        <FlexCenter>
          <Checkbox
            pointer
            margin='0 12px 0 0'
            checked={checkbox}
            onClick={() => setCheckbox(!checkbox)}
          />
          <P3 color={color.colors.neutral500} checked={checkbox}>
            {t('payment_method.i_understand')}
          </P3>
        </FlexCenter>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
          marginBottom: 40
        }}>
        <Button
          maxWidth={'200px'}
          disabled={!selectedNetwork || !checkbox}
          onClick={() => {
            dispatch(
              addHub(
                packageId,
                1,
                selectedNetwork.networkType,
                null,
                step,
                setStep
              )
            );
          }}>
          {t('payment_method.create_order')}
        </Button>
      </div>
      {/* {selectedNetwork.index === 0 && <CreditCard />} */}
    </div>
  );
};
