import React from 'react';
import moment from 'moment';

import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { Button } from '../../../styles/GlobalComponents';
import { FlexCenter } from '../../../styles/stylesStyled';
import { Subtitle, Title } from './WithdrawStyled';

export const WithdrawAvailability = () => {
  const { withdrawAvailability } = useSelector((state) => state.earnings);

  const activeSince = (date) => {
    const now = moment(new Date()); //todays date
    const end = moment(date);

    const duration = moment.duration(end.diff(now));

    const hours = duration.hours();
    const minutes = duration.minutes();

    if (hours > 0) {
      return `${hours} hours and ${minutes} minutes`;
    } else {
      return `${minutes} minutes`;
    }
  };
  return (
    <div style={{ marginTop: '11vh' }}>
      <FlexCenter style={{ flexDirection: 'column' }}>
        <Title>Withdrawals temporary disabled</Title>
        <Subtitle style={{ textAlign: 'center' }}>
          Because you have performed a modification of login <br /> details and
          are expected to be restored in{' '}
          {activeSince(withdrawAvailability?.limitDate)}
        </Subtitle>
        <Link to='/backoffice/dashboard'>
          <Button w='200px' opacity={'true'}>
            Back to dashboard
          </Button>
        </Link>
      </FlexCenter>
    </div>
  );
};
