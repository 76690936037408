import { CLEAR_ALERT, CLEAR_MESSAGE } from '../../constants/errorConstants';
import {
  CHANGE_PASSWORD,
  SETTINGS_INIT,
  UPDATE_USERNAME,
  UPDATE_TEAM,
  SET_NOTIFICATION,
  ENABLE_VERFICATION_STATUS,
  DISABLE_VERFICATION_STATUS,
  CONFIRM_VERIFICATION,
  CONFIRM_2FA_VERIFICATION,
  CONFIRM_2FA_EMAIL,
  BUY_VIRTUAL_OFFICE
} from './settings.constants';

import {
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_USER_EMAIL
} from '../../constants/authConstants';
import { REGISTER_ON_KYC_PLATFORM } from '../Marketplace/Marketplace.constants';

export const settings = (
  state = {
    user: null,
    notifications: null,
    teamSettings: null,
    user2FAData: null,
    backupKey: null,
    licence: null,
    isLoading: false,
    isLoadingInit: false,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case SETTINGS_INIT.REQUEST:
      return {
        ...state,
        isLoadingInit: true
      };
    case SETTINGS_INIT.SUCCESS:
      return {
        ...state,
        isLoadingInit: false,
        ...action.payload
      };
    case SETTINGS_INIT.FAIL:
      return {
        ...state,
        isLoadingInit: false,
        error: action.payload
      };

    case UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          profileImageUrl: action.payload
        }
      };

    case UPDATE_USERNAME.REQUEST:
      return {
        ...state
        // isLoading: true,
      };
    case UPDATE_USERNAME.SUCCESS:
      return {
        ...state,
        // isLoading: false,
        user: action.payload
      };
    case UPDATE_USERNAME.FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case UPDATE_USER_EMAIL:
      return {
        ...state,
        // isLoading: false,
        user: {
          ...state.user,
          email: action.payload
        }
      };

    case CHANGE_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true
      };
    case CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CHANGE_PASSWORD.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case UPDATE_TEAM.REQUEST:
      return {
        ...state,
        loading: true
      };

    case UPDATE_TEAM.SUCCESS:
      return {
        ...state,
        loading: false,
        teamSettings: action.payload,
        message: 'Success'
      };

    case UPDATE_TEAM.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SET_NOTIFICATION.REQUEST:
      return {
        ...state
      };

    case SET_NOTIFICATION.SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.payload.id
            ? { ...notification, checked: !notification.checked }
            : notification
        )
      };

    case SET_NOTIFICATION.FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ENABLE_VERFICATION_STATUS.REQUEST:
      return {
        ...state,
        loadingVerification: true
      };

    case ENABLE_VERFICATION_STATUS.SUCCESS:
      return {
        ...state,
        loadingVerification: false,
        user2FAData: action.payload
        // verificationStatus: true,
      };

    case ENABLE_VERFICATION_STATUS.FAIL:
      return {
        ...state,
        loadingVerification: false,
        error: action.payload
      };

    case DISABLE_VERFICATION_STATUS.REQUEST:
      return {
        ...state,
        loadingVerification: true
      };

    case DISABLE_VERFICATION_STATUS.SUCCESS:
      return {
        ...state,
        loadingVerification: false,
        verificationStatus: false,
        signIn2FAEnabled: false,
        message: action.payload
      };

    case DISABLE_VERFICATION_STATUS.FAIL:
      return {
        ...state,
        loadingVerification: false,
        error: action.payload
      };

    case CONFIRM_VERIFICATION.REQUEST:
      return {
        ...state,
        loadingVerification: true
      };

    case CONFIRM_VERIFICATION.SUCCESS:
      return {
        ...state,
        loadingVerification: false,
        backupKey: action.payload,
        verificationStatus: true
      };

    case CONFIRM_VERIFICATION.FAIL:
      return {
        ...state,
        loadingVerification: false,
        error: action.payload
      };

    case CONFIRM_2FA_VERIFICATION.SUCCESS:
      return {
        ...state,
        signIn2FAEnabled: action.payload
      };

    case CONFIRM_2FA_EMAIL.REQUEST:
      return {
        ...state,
        loadingVerification: true
      };

    case CONFIRM_2FA_EMAIL.SUCCESS:
      return {
        ...state,
        signInEmailCodeVerificationEnabled: true
      };

    case CONFIRM_2FA_EMAIL.FAIL:
      return {
        ...state,
        loadingVerification: false,
        error: action.payload
      };

    case BUY_VIRTUAL_OFFICE.REQUEST:
      return {
        ...state,
        loading: true
      };

    case BUY_VIRTUAL_OFFICE.SUCCESS:
      return {
        ...state,
        loading: false,
        licence: action.payload
      };

    case BUY_VIRTUAL_OFFICE.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case REGISTER_ON_KYC_PLATFORM.REQUEST:
      return {
        ...state,
        loading: true
      };

    case REGISTER_ON_KYC_PLATFORM.SUCCESS:
      return {
        ...state,
        loading: false
      };

    case REGISTER_ON_KYC_PLATFORM.FAIL:
      return {
        ...state,
        loading: false
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error,
        message: ''
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: ''
      };

    default:
      return state;
  }
};
