import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { AnimatedDashboard } from '../../components/animation/AnimatedDashboard.js';

import { SidebarModal } from '../../components/common/Sidebar/Sidebar.js';
import Spinner from '../../components/common/Spinner';
import { SETTINGS_SECURITY } from '../../routes/routes.js';
import { color } from '../../styles/GlobalStyles.js';
import { getDashboard } from './Dashboard.actions.js';
import { DashboardNotification } from './DashboardNotification.js';
import { EarningsComponent } from './EarningsComponent';
import BackofficeWarning from './BackofficeWarning';

import { NoKyc } from './NoKyc.js';
import { NumberOfMembers } from './NumberOfMembers';
import { StatisticalComponent } from './StatisticalComponent';

import { MoneyBack } from './MoneyBack.js';

const Dashboard = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useDispatch();

  const { user, kycStatus } = useSelector((state) => state.userInfo);
  const { dashboard, count, loading } = useSelector((state) => state.dashboard);
  const [kycSlide, setKycSlide] = useState(false);
  const [packageSlide, setPackageSlide] = useState(false);

  const history = useHistory();

  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);

  const KycPackageNotifications = () => {
    if (!kycStatus?.isKycCompleted) {
      return (
        <Link to='/kyc'>
          <DashboardNotification
            setOpen={setKycSlide}
            pill={t('kyc_pill')}
            title={t('kyc_title')}
            text={t('kyc_text')}
          />
        </Link>
      );
    } else return '';
  };

  if (loading) return <Spinner fullScreen={true} dark />;

  return (
    <AnimatedDashboard zoom key={'dashboard'}>
      <div style={{ margin: '70px 0' }}>
        <BackofficeWarning />
        {/* FAST START BONUS */}
        <MoneyBack />

        <EarningsComponent user={user} backOffice={dashboard} />
        <StatisticalComponent backOffice={dashboard} />
        {/* <LatestOrders orders={orders} /> */}
        {!dashboard?.user?.settings?.signInEmailCodeVerificationEnabled && (
          <DashboardNotification
            titleColor={color.colors.error500}
            pill={'Security'}
            title={
              'Protect your account. Enable email and/or 2FA verification at login'
            }
            text={
              'The security of your account will be significantly increased if you enable email and 2FA verification at login. You can do it quickly and easy in few simple steps and protect your earnings.'
            }
            buttonText='Enable now'
            buttonOnClick={() => history.push(SETTINGS_SECURITY)}
          />
        )}
        <KycPackageNotifications />
        <NumberOfMembers backOffice={dashboard} count={count} />
      </div>

      <SidebarModal
        isOpen={kycSlide}
        setOpen={setKycSlide}
        title={t('verify_your_account')}
        sidebarWidth={'508px'}>
        <NoKyc />
      </SidebarModal>

      <SidebarModal
        isOpen={packageSlide}
        setOpen={setPackageSlide}
        title={t('verify_your_account')}
        sidebarWidth={'508px'}>
        {t('package')}
      </SidebarModal>

      {/* <WelcomeSidebar
        open={welcome}
        setOpen={setWelcome}
        step={step}
        setStep={setStep}
      /> */}
      {/* <WelcomeSidebarStep2
        open={welcome}
        setOpen={setWelcome}
        step={step}
        setStep={setStep}
      /> */}
    </AnimatedDashboard>
  );
};

export default Dashboard;
