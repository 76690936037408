import {
  TRANSITION_STATE_1,
  TRANSITION_STATE_2
} from '../constants/transitionConstants';

export const transitionReducer = (state = { transition: false }, action) => {
  switch (action.type) {
    case TRANSITION_STATE_1:
      return {
        ...state,
        transition: 1
      };
    case TRANSITION_STATE_2:
      return {
        loading: false,
        transition: 2
      };

    default:
      return state;
  }
};
