import React from 'react';
import { Link } from 'react-router-dom';
import { SETTINGS_SECURITY } from '../../../routes/routes';
import { H3, P3 } from '../../../styles/FontStyles';
import { Button } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { FlexCenter } from '../../../styles/stylesStyled';

export const Required2FA = () => {
  return (
    <div>
      <FlexCenter style={{ flexDirection: 'column' }}>
        <H3 mb='20' center>
          2FA required
        </H3>
        <P3 mb='40' color={color.colors.neutral500} center>
          In order to change email, please add 2FA security
        </P3>
        <Link to={SETTINGS_SECURITY}>
          <Button w='200px' opacity={'true'}>
            Add 2FA security
          </Button>
        </Link>
      </FlexCenter>
    </div>
  );
};
