import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CaptionText, P1, P2 } from '../../styles/FontStyles';
import { Box, Flexed, Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  IconTitleStyled
  //   CashBackFlex,
} from './DashboardStyled';
import warning from '../../assets/images/backoffice/warning.png';
import warning2 from '../../assets/images/backoffice/warning2.png';
import warning3 from '../../assets/images/backoffice/warning3.png';
import { SETTINGS_VIRTUAL_OFFICE } from '../../routes/routes';

import { Counter } from './Counter';
import { dateDifferenceInDaysFromToday } from '../../helpers/helperFunctions';

const BackofficeWarning = () => {
  const history = useHistory();

  const { user } = useSelector((state) => state.userInfo);
  // const { loading } = useSelector((state) => state.dashboard);

  useEffect(() => {}, [user?.currentVirtualOfficeLicence?.dateEnd]);

  return (
    <>
      {user?.currentVirtualOfficeLicence?.dateEnd ? (
        <>
          {dateDifferenceInDaysFromToday(
            user?.currentVirtualOfficeLicence?.dateEnd
          ) === 0 ? (
            <>
              <IconTitleStyled>Notifications</IconTitleStyled>
              <Box p='40px'>
                <Flexed between center>
                  <Flexed column widthSM='100%'>
                    <CaptionText
                      mb='4'
                      caps
                      bold
                      color={color.colors.neutral500}>
                      IMPORTANT NOTICE
                    </CaptionText>
                    {/* <H4 mb='24'>&#9888; IMPORTANT NOTICE</H4> */}
                    <img
                      src={warning2}
                      style={{ width: 350, marginBottom: 24, marginTop: 10 }}
                    />

                    <P2 mb='24'>
                      <b>You are no longer receiving:</b>
                    </P2>
                    <P2 mb='5'>&#x274C; Business Volume Points (BV) </P2>
                    <P2 mb='32'>
                      &#x274C; Bonuses (Team Bonus, Matching Bonus, Direct Bonus
                      is capped to 100 USDT per week){' '}
                    </P2>
                    <Flexed mobile column pointer center width='100%' mb='20'>
                      <img src={warning3} style={{ width: 180 }} />
                      <P1 bold>TRIAL ENDED</P1>
                    </Flexed>
                    <Button
                      w='250px'
                      onClick={() => history.push(SETTINGS_VIRTUAL_OFFICE)}
                      negative>
                      GET SUBSCRTIPTION
                    </Button>
                  </Flexed>
                  <Flexed desktop column center width='40%'>
                    <img src={warning3} style={{ width: 200 }} />
                    <P1 bold>TRIAL ENDED</P1>
                  </Flexed>
                </Flexed>
              </Box>
            </>
          ) : (
            dateDifferenceInDaysFromToday(
              user?.currentVirtualOfficeLicence?.dateEnd
            ) <= 7 && (
              <>
                <IconTitleStyled>Notifications</IconTitleStyled>
                <Box p='40px'>
                  <Flexed between center>
                    <Flexed column widthSM='100%'>
                      <CaptionText
                        mb='4'
                        caps
                        bold
                        color={color.colors.neutral500}>
                        IMPORTANT NOTICE - ACTION REQUIRED
                      </CaptionText>
                      {/* <H4 mb='24'>&#9888; IMPORTANT NOTICE</H4> */}
                      <img src={warning} className='warning-img' />

                      <P2 mb='24'>
                        Without a valid Back-Office subscription{' '}
                        <b>you will STOP RECEIVING:</b>
                      </P2>
                      <P2 mb='5'>&#x274C; Business Volume Points (BV) </P2>
                      <P2 mb='32'>
                        &#x274C; Bonuses (Team Bonus, Matching Bonus, Direct
                        Bonus is capped to 100 USDT per week){' '}
                      </P2>
                      <Flexed mobile column pointer width='100%' mb='20'>
                        {user?.currentVirtualOfficeLicence?.dateEnd && (
                          <Counter
                            timestamp={
                              new Date(
                                user?.currentVirtualOfficeLicence?.dateEnd
                              )
                            }
                          />
                        )}
                      </Flexed>
                      <Button
                        w='250px'
                        onClick={() => history.push(SETTINGS_VIRTUAL_OFFICE)}
                        negative>
                        GET SUBSCRTIPTION
                      </Button>
                    </Flexed>
                    <Flexed desktop column>
                      {user?.currentVirtualOfficeLicence?.dateEnd && (
                        <Counter
                          timestamp={
                            new Date(user?.currentVirtualOfficeLicence?.dateEnd)
                          }
                        />
                      )}
                    </Flexed>
                  </Flexed>
                </Box>
              </>
            )
          )}
        </>
      ) : (
        <>
          <IconTitleStyled>Notifications</IconTitleStyled>
          <Box p='40px'>
            <Flexed between center>
              <Flexed column widthSM='100%'>
                <CaptionText mb='4' caps bold color={color.colors.neutral500}>
                  IMPORTANT NOTICE
                </CaptionText>
                {/* <H4 mb='24'>&#9888; IMPORTANT NOTICE</H4> */}
                <img
                  src={warning2}
                  style={{ width: 350, marginBottom: 24, marginTop: 10 }}
                />

                <P2 mb='24'>
                  <b>You are no longer receiving:</b>
                </P2>
                <P2 mb='5'>&#x274C; Business Volume Points (BV) </P2>
                <P2 mb='32'>
                  &#x274C; Bonuses (Team Bonus, Matching Bonus, Direct Bonus is
                  capped to 100 USDT per week){' '}
                </P2>
                <Flexed mobile column pointer center width='100%' mb='20'>
                  <img src={warning3} style={{ width: 180 }} />
                  <P1 bold>TRIAL ENDED</P1>
                </Flexed>
                <Button
                  w='250px'
                  onClick={() => history.push(SETTINGS_VIRTUAL_OFFICE)}
                  negative>
                  GET SUBSCRTIPTION
                </Button>
              </Flexed>
              <Flexed desktop column center width='40%'>
                <img src={warning3} style={{ width: 200 }} />
                <P1 bold>TRIAL ENDED</P1>
              </Flexed>
            </Flexed>
          </Box>
        </>
      )}
    </>
  );
};

export default BackofficeWarning;
