import axios from 'axios';
import {
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAIL,
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAIL,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAIL
} from '../constants/addressConstants';

import { CLEAR_ALERT } from '../constants/errorConstants';

const API_URL = process.env.REACT_APP_API_URL;

export const addAddress =
  (
    addressLine1,
    addressLine2,
    zipCode,
    country,
    countryCode,
    isPermanent,
    city,
    setIsOpen2
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_ADDRESS_REQUEST });

      const { userInfo } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token.accessToken}`
        }
      };

      const { data } = await axios.post(
        `${API_URL}/user/address`,
        {
          addressLine1,
          addressLine2,
          zipCode,
          country,
          countryCode: countryCode ? countryCode : '+386',
          isPermanent,
          city
        },
        config
      );

      setIsOpen2(false);

      dispatch({
        type: ADD_ADDRESS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: ADD_ADDRESS_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const getAddress = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ADDRESSES_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    const { data } = await axios.get(`${API_URL}/user/address`, config);

    dispatch({
      type: GET_ADDRESSES_SUCCESS,
      payload: data.data.addresses
    });
  } catch (error) {
    dispatch({
      type: GET_ADDRESSES_FAIL,
      payload: error.response && error.response.data
    });
  }
};

export const deleteAddress = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_ADDRESS_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    await axios.delete(`${API_URL}/user/address/${id}`, config);

    dispatch({
      type: DELETE_ADDRESS_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: DELETE_ADDRESS_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: ''
      });
    }, 3000);
  }
};
