/* eslint-disable no-constant-condition */
import React from 'react';
import { H3 } from '../../../styles/FontStyles';
import { color } from '../../../styles/GlobalStyles';
import Spinner from '../../../components/common/Spinner';

export const AutoStakeTransactions = () => {
  return (
    <div>
      {false ? (
        <Spinner />
      ) : (
        <div>
          <H3 mb='20' center color={color.colors.secondary100}>
            Auto Stake
          </H3>
        </div>
      )}
    </div>
  );
};
