import moment from 'moment';
import {
  GET_PORTFOLIO,
  LOCK_AUTOSTAKE,
  CHANGE_AUTOSTAKE,
  SWITCH_AUTOSTAKE,
  GET_STAKING_WITHDRAWAL_INFO,
  BURN_ULX
} from './Portfolio.constants';

import { CREATE_WITHDRAW_EXPLORER } from '../EarningsBackoffice/Withdraw/Withdraw.constants';

const initialState = {
  isLoading: false,
  loading: false,
  loadingAutoStake: false,
  loadingAmountToBurn: false,
  ulxAmountToBurn: 0,
  error: ''
};

export const portfolio = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTFOLIO.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_PORTFOLIO.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload
      };
    case GET_PORTFOLIO.FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case LOCK_AUTOSTAKE.REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOCK_AUTOSTAKE.SUCCESS:
      return {
        ...state,
        loading: false,
        staking: state.staking.userPackages.map((packageItem) => {
          if (packageItem.id === action.payload.id) {
            return {
              ...packageItem,
              dateLimitForAutoStake: action.payload.dateLimitForAutoStake
            };
          } else {
            return packageItem;
          }
        })
      };
    case LOCK_AUTOSTAKE.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CHANGE_AUTOSTAKE.REQUEST:
      return {
        ...state,
        loadingAutoStake: action.payload.id
      };

    case CHANGE_AUTOSTAKE.SUCCESS:
      return {
        ...state,
        loadingAutoStake: false,
        staking: {
          ...state.staking,
          userPackages: state.staking.userPackages.map((packageItem) => {
            if (packageItem.id === action.payload.id) {
              return {
                ...packageItem,
                shouldIncludeInHodlPoolBonusCalculation: false,
                disableAtNextWeek: !packageItem.disableAtNextWeek,
                claimDate: moment(new Date())
              };
            } else {
              return packageItem;
            }
          })
        }
      };

    case CHANGE_AUTOSTAKE.FAIL:
      return {
        ...state,
        loadingAutoStake: false,
        error: action.payload
      };

    case SWITCH_AUTOSTAKE:
      return {
        ...state,
        staking: {
          ...state.staking,
          userPackages: state.staking.userPackages.map((packageItem) => {
            if (packageItem.id === action.payload) {
              return {
                ...packageItem,
                disableAtNextWeek: !packageItem.disableAtNextWeek
              };
            } else {
              return packageItem;
            }
          })
        }
      };

    case CREATE_WITHDRAW_EXPLORER.SUCCESS:
      return {
        ...state,
        summary: state.summary
          ? {
              ...state.summary,
              availableBalance:
                state.summary.availableBalance - (action.payload + 1)
            }
          : null
      };

    case GET_STAKING_WITHDRAWAL_INFO.REQUEST:
      return {
        ...state,
        loadingAmountToBurn: true
      };

    case GET_STAKING_WITHDRAWAL_INFO.SUCCESS:
      return {
        ...state,
        loadingAmountToBurn: false,
        ulxAmountToBurn: action.payload
      };

    case GET_STAKING_WITHDRAWAL_INFO.FAIL:
      return {
        ...state,
        loadingAmountToBurn: false
      };

    case BURN_ULX.SUCCESS:
      return {
        ...state,
        staking: {
          ...state.staking,
          userPackages: state.staking.userPackages.map((packageItem) => {
            if (packageItem.id === action.payload) {
              return {
                ...packageItem,
                claimDate: moment(new Date())
              };
            } else {
              return packageItem;
            }
          })
        }
      };
    default:
      return state;
  }
};
