import axios from 'axios';
import { tryExecuteSimpleAction } from '../../actions/common';

import {
  GET_BACK_OFFICE_FAIL,
  GET_BACK_OFFICE_REQUEST,
  GET_BACK_OFFICE_SUCCESS,
  GET_JOINED_AFTER_USERS_FAIL,
  GET_JOINED_AFTER_USERS_REQUEST,
  GET_JOINED_AFTER_USERS_SUCCESS,
  GET_LAST_REGISTERED_USERS_FAIL,
  GET_LAST_REGISTERED_USERS_REQUEST,
  GET_LAST_REGISTERED_USERS_SUCCESS,
  GET_LATEST_ORDERS_FAIL,
  GET_LATEST_ORDERS_REQUEST,
  GET_LATEST_ORDERS_SUCCESS,
  CHECK_SIDEMODAL_REQUEST,
  CHECK_SIDEMODAL_SUCCESS,
  CHECK_SIDEMODAL_FAIL
} from './Dashboard.constants';
import { localStorageGetter } from '../../lib/utils';
import { setUserPrizerToLS } from '../../helpers/localstorage-helper';
import { MARKETPLACE } from '../../routes/routes';
import { getUserDetails } from '../../actions/authActions';

const API_URL = process.env.REACT_APP_API_URL;

export const getDashboard = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_BACK_OFFICE_REQUEST,
    async () => {
      const { data } = await axios.get(
        `${API_URL}/backoffice/partner/user/data`
      );

      dispatch({
        type: GET_BACK_OFFICE_SUCCESS,
        payload: data.data
      });

      dispatch(getUserDetails());
      dispatch(getLastRegisteredUsers());
      dispatch(getJoinedAfterUsers());
    },
    GET_BACK_OFFICE_FAIL
  );
};

export const getLatestOrders = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_LATEST_ORDERS_REQUEST,
    async () => {
      const { data } = await axios.get(
        `${API_URL}/package/last-user-packages?numOfUserPackages=10`
      );

      dispatch({
        type: GET_LATEST_ORDERS_SUCCESS,
        payload: data.data.userPackages
      });
    },
    GET_LATEST_ORDERS_FAIL
  );
};

export const getLastRegisteredUsers = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_LAST_REGISTERED_USERS_REQUEST,
    async () => {
      const { data } = await axios.get(
        `${API_URL}/user/last-registered-users`,
        {
          params: {
            numOfUsers: 50
          }
        }
      );

      dispatch({
        type: GET_LAST_REGISTERED_USERS_SUCCESS,
        payload: data.data.users
      });
    },
    GET_LAST_REGISTERED_USERS_FAIL
  );
};

export const getJoinedAfterUsers = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_JOINED_AFTER_USERS_REQUEST,
    async () => {
      const { data } = await axios.get(
        `${API_URL}/backoffice/user/joined-after/count`
      );

      dispatch({
        type: GET_JOINED_AFTER_USERS_SUCCESS,
        payload: data.data.count
      });
    },
    GET_JOINED_AFTER_USERS_FAIL
  );
};

export const checkSideModal = (history) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    CHECK_SIDEMODAL_REQUEST,
    async () => {
      await axios.put(`${API_URL}/user/set-side-modal-checked`);
      dispatch({
        type: CHECK_SIDEMODAL_SUCCESS
      });

      const userFormLs = localStorageGetter('userPrizer');

      const parsedUser = JSON.parse(userFormLs);

      const newUser = {
        ...parsedUser,
        isSideModalChecked: true
      };

      setUserPrizerToLS(JSON.stringify(newUser));

      history.push(MARKETPLACE);
    },
    CHECK_SIDEMODAL_FAIL
  );
};
