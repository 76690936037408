import { tryExecuteSimpleAction } from '../../actions/common';
import {
  UPDATE_USERNAME,
  SETTINGS_INIT,
  CHANGE_PASSWORD,
  UPDATE_TEAM,
  SET_NOTIFICATION,
  ENABLE_VERFICATION_STATUS,
  DISABLE_VERFICATION_STATUS,
  CONFIRM_VERIFICATION,
  CONFIRM_2FA_VERIFICATION,
  CONFIRM_2FA_EMAIL,
  BUY_VIRTUAL_OFFICE
} from './settings.constants';
import {
  getSettingsAccountAsync,
  getSettingsNottificationsAsync,
  updateUsernameAsync,
  changePasswordAsync,
  getTeamSettinsAsync,
  updateTeamSettingsAsync,
  setNotificationAsync,
  getVerificationSecurityAsync,
  enableVerificationStatusAsync,
  disableVerificationStatusAsync,
  confirmVerificationAsync,
  confirm2FAVerificationAsync,
  confirm2FAEmailAsync,
  buyVirtaulOfficeAsync
} from './settings.requests';

import { getAddress } from '../../actions/addressActions';
import { CLEAR_ALERT } from '../../constants/errorConstants';
import { getWallets } from '../../actions/walletActions';

import { SuccessToast } from '../../components/common/Toasts/Toasts';
import { getUserDetails } from '../../actions/authActions';

export const settingsInit = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    SETTINGS_INIT.REQUEST,
    async () => {
      const { data: user } = await getSettingsAccountAsync();
      const { data: notifications } = await getSettingsNottificationsAsync();
      const { data: teamSettings } = await getTeamSettinsAsync();
      const {
        data: is2FAEnabled,
        data: signIn2FAEnabled,
        data: signInEmailCodeVerificationEnabled
      } = await getVerificationSecurityAsync();

      const newNotifications = [
        {
          id: 1,
          title: 'Earnings',
          subtitle:
            'You’ll be notified of significant increases & decreases of your earnings.',
          checked: notifications.data.notificationSettings.earnings,
          earings: notifications.data.notificationSettings.earnings
        },
        {
          id: 2,
          title: 'News',
          subtitle: 'You’ll be notified of any new news updates.',
          checked: notifications.data.notificationSettings.news,
          news: notifications.data.notificationSettings.news
        },
        {
          id: 3,
          title: 'Promotions',
          subtitle: 'You’ll be notified of any new promotions.',
          checked: notifications.data.notificationSettings.promotions,
          promotions: notifications.data.notificationSettings.promotions
        },
        {
          id: 4,
          title: 'Referral',
          subtitle: 'You’ll be notified of any referral activity.',
          checked: notifications.data.notificationSettings.referral,
          referral: notifications.data.notificationSettings.referral
        }
      ];

      dispatch(getAddress());

      dispatch({
        type: SETTINGS_INIT.SUCCESS,
        payload: {
          user: user.data.user,
          notifications: newNotifications,
          teamSettings: teamSettings.data.settings.teamCompletionSorting,
          verificationStatus: is2FAEnabled.data.is2FAEnabled,
          signIn2FAEnabled: signIn2FAEnabled.data.signIn2FAEnabled,
          signInEmailCodeVerificationEnabled:
            signInEmailCodeVerificationEnabled.data
              .signInEmailCodeVerificationEnabled
        }
      });

      dispatch(getWallets());
    },
    SETTINGS_INIT.FAIL
  );
};

export const confirmVerification =
  (emailCode, twoFACode, setStep) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CONFIRM_VERIFICATION.REQUEST,
      async () => {
        const { data } = await confirmVerificationAsync(emailCode, twoFACode);

        dispatch({
          type: CONFIRM_VERIFICATION.SUCCESS,
          payload: data.data.backupKey
        });

        setStep(3);
      },
      CONFIRM_VERIFICATION.FAIL
    );
  };

export const confirm2FAVerification =
  (emailCode, twoFACode, signIn2FAEnabled, setIsOpen) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CONFIRM_2FA_VERIFICATION.REQUEST,
      async () => {
        const { data } = await confirm2FAVerificationAsync(
          emailCode,
          twoFACode,
          signIn2FAEnabled
        );

        dispatch({
          type: CONFIRM_2FA_VERIFICATION.SUCCESS,
          payload: data.data.signIn2FAEnabled
        });

        setIsOpen(false);
      },
      CONFIRM_2FA_VERIFICATION.FAIL
    );
  };

export const updateUsername = (username, setIsOpen) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    UPDATE_USERNAME.REQUEST,
    async () => {
      const { data: user } = await updateUsernameAsync(username);

      dispatch({
        type: UPDATE_USERNAME.SUCCESS,
        payload: user.data.user
      });

      setIsOpen(false);
    },
    UPDATE_USERNAME.FAIL
  );
};

export const changePassword =
  (oldPassword, newPassword, setStep) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CHANGE_PASSWORD.REQUEST,
      async () => {
        const data = await changePasswordAsync(oldPassword, newPassword);

        dispatch({
          type: CHANGE_PASSWORD.SUCCESS
        });

        SuccessToast(data.data.message);

        setStep(2);
      },
      CHANGE_PASSWORD.FAIL
    );
  };

export const updateTeamSettings = (id) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    UPDATE_TEAM.REQUEST,
    async () => {
      const { data } = await updateTeamSettingsAsync(id);

      dispatch({
        type: UPDATE_TEAM.SUCCESS,
        payload: data.data.settings.teamCompletionSorting
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    },
    UPDATE_TEAM.FAIL
  );
};

export const setNotification = (item) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    SET_NOTIFICATION.REQUEST,
    async () => {
      let notifications;
      switch (item.id) {
        case 1:
          notifications = {
            earnings: !item.checked
          };
          break;
        case 2:
          notifications = {
            news: !item.checked
          };
          break;

        case 3:
          notifications = {
            promotions: !item.checked
          };
          break;

        case 4:
          notifications = {
            referral: !item.checked
          };
          break;
        default:
          break;
      }

      await setNotificationAsync(notifications);

      dispatch({
        type: SET_NOTIFICATION.SUCCESS,
        payload: item
      });
    },
    SET_NOTIFICATION.FAIL
  );
};

export const enableVerificationStatus = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    ENABLE_VERFICATION_STATUS.REQUEST,
    async () => {
      const { data } = await enableVerificationStatusAsync();

      dispatch({
        type: ENABLE_VERFICATION_STATUS.SUCCESS,
        payload: data.data.user2FAData
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    },
    ENABLE_VERFICATION_STATUS.FAIL
  );
};

export const confirm2FAEmail = (code, setIsOpen) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    CONFIRM_2FA_EMAIL.REQUEST,
    async () => {
      const { data } = await confirm2FAEmailAsync(code);

      dispatch({
        type: CONFIRM_2FA_EMAIL.SUCCESS,
        payload: data.message
      });

      setIsOpen(false);

      SuccessToast(data.message);
    },
    CONFIRM_2FA_EMAIL.FAIL
  );
};

export const disableVerificationStatus =
  (code, twoFACode, setIsOpen) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      DISABLE_VERFICATION_STATUS.REQUEST,
      async () => {
        const { data } = await disableVerificationStatusAsync(code, twoFACode);

        dispatch({
          type: DISABLE_VERFICATION_STATUS.SUCCESS,
          payload: data.message
        });

        setIsOpen(false);

        SuccessToast(data.message);
      },
      DISABLE_VERFICATION_STATUS.FAIL
    );
  };

export const buyVirtaulOffice =
  (licenceId, networkType, currencyType, step, setStep) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      BUY_VIRTUAL_OFFICE.REQUEST,
      async () => {
        const { data } = await buyVirtaulOfficeAsync(
          licenceId,
          networkType,
          currencyType
        );

        dispatch({
          type: BUY_VIRTUAL_OFFICE.SUCCESS,
          payload: data.data.licence
        });

        setStep(step);

        if (currencyType === 3) {
          dispatch(getUserDetails());
        }
      },
      BUY_VIRTUAL_OFFICE.FAIL
    );
  };
