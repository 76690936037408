import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AnimatedDashboard } from '../../components/animation/AnimatedDashboard';
import Spinner from '../../components/common/Spinner';
import { SETTINGS_LAYOUT } from '../../routes/routes';
import { SectionSeparatorVertical } from '../../styles/GlobalComponents';
import { useWindowWide } from '../../utils/wideScreen';
import { SettingsNavigation } from './Navigation';
import { settingsInit } from './settings.actions';
import { ContentWrapper, SettingsWrapper } from './SettingsStyled';

export const Settings = ({ children }) => {
  const wide = useWindowWide(800);
  const location = useLocation();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(settingsInit());
  }, []);

  if (isLoading) return <Spinner fullScreen={true} dark />;

  return (
    <AnimatedDashboard zoom>
      <SettingsWrapper>
        {!wide && location.pathname === SETTINGS_LAYOUT && (
          <SettingsNavigation />
        )}
        {wide && <SettingsNavigation />}
        {wide && <SectionSeparatorVertical />}
        {location.pathname !== SETTINGS_LAYOUT && (
          <ContentWrapper>{children}</ContentWrapper>
        )}
      </SettingsWrapper>
    </AnimatedDashboard>
  );
};
