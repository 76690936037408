import React, { useEffect } from 'react';
import { InputLabel } from '../../../styles/FontStyles';
import { Button } from '../../../styles/GlobalComponents';
import { FlexCenter } from '../../../styles/stylesStyled';
import {
  ImagePHContainer,
  ImagePHContainerFilled,
  KycBrowseFilesButton,
  Medium,
  Small
} from '../KycStyled';

import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useWindowWide } from '../../../utils/wideScreen';
import { ImgState } from '../KycDocumentPhotos';
import { Dropzone } from './Dropzone';

export const RenderPassport = ({
  id,
  percentage,
  setModal3,
  setImage3,
  uploadPassportHandler,
  setImgStatePassport,
  imgStatePassport,
  passport,
  setPassport,
  setDocumentPassport
}) => {
  const { t } = useTranslation('kyc.document_photos');
  const wide = useWindowWide(1000);

  const {
    acceptedFiles: acceptedFiles,
    getRootProps: getRootProps,
    isDragAccept: isDragAccept,
    isDragReject: isDragReject
  } = useDropzone({
    accept: { 'image/*': [] },
    multiple: false,
    useFsAccessApi: false
  });

  useEffect(() => {
    const file = acceptedFiles[0];

    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPassport(objectUrl);
      setDocumentPassport(file);
      setImgStatePassport(1);
    }
  }, [acceptedFiles]);

  return (
    <>
      {id === 3 && (
        <div>
          <InputLabel>Passport</InputLabel>
          {passport ? (
            <ImagePHContainerFilled style={{ width: wide && 375 }}>
              <img
                src={passport}
                alt='upload'
                style={{
                  height: '182px',
                  objectFit: 'cover',
                  filter: imgStatePassport === 2 ? 'blur(8px)' : 'blur(0px)',
                  transition: '.3s filter ease'
                }}
              />
              <ImgState
                state={imgStatePassport}
                setImgState={setImgStatePassport}
                percentage={percentage}
                uploadHandler={(e) => uploadPassportHandler(e)}
                retake={() => {
                  setImage3('');
                  setModal3(true);
                }}
              />
            </ImagePHContainerFilled>
          ) : (
            <Dropzone
              acceptedFiles={acceptedFiles}
              getRootProps={getRootProps}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}>
              <ImagePHContainer style={{ width: wide && 375 }}>
                <div
                  style={{
                    textAlign: 'center'
                  }}>
                  <Medium>{t('photo_card_title')}</Medium>
                  <Small>{t('photo_card_subtitle')}</Small>

                  <FlexCenter style={{ justifyContent: 'center' }}>
                    <Button
                      fs='12px'
                      h='34px'
                      maxWidth='111px'
                      mr='12'
                      type='button'
                      onClick={() => setModal3(true)}>
                      {t('take_photo_button')}
                    </Button>
                    <KycBrowseFilesButton>
                      <input
                        type='file'
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={(e) => uploadPassportHandler(e)}
                      />
                      {t('browse_files_button')}
                    </KycBrowseFilesButton>
                  </FlexCenter>
                </div>
              </ImagePHContainer>
            </Dropzone>
          )}
        </div>
      )}
    </>
  );
};
