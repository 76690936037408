import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { forgottenPassword } from '../../actions/authActions';

import { InputBackOffice } from '../../components/common/InputBackoffice';
import Spinner from '../../components/common/Spinner';
import { validateEmail } from '../../helpers/helperFunctions';
import { AuthLayoutTitle } from '../../styles/GlobalComponents';
import { AuthButton, PhoneComp } from '../../styles/stylesStyled';

const captchaId = process.env.REACT_APP_CAPTCHA_ID;

const ForgottenPassword = () => {
  const { t } = useTranslation('onboarding');
  const [id, setId] = useState('');
  const digit = /^\d+$/;

  const dispatch = useDispatch();

  const credentials = useSelector((state) => state.forgottenCredentials);

  const { loading } = credentials ?? {
    error: null,
    message: null
  };

  const sendMail = (e) => {
    e.preventDefault();

    window.initGeetest4(
      {
        captchaId,
        language: 'eng',
        product: 'bind',
        riskType: 'slide',
        nativeButton: {
          width: '100%',
          marginBottom: '20px'
        }
      },
      function (captcha) {
        captcha.showBox();
        captcha
          .onReady(function () {})
          .onSuccess(function () {
            const result = captcha.getValidate();
            if (validateEmail(id)) {
              dispatch(forgottenPassword(id, 1, result));
            } else if (id.match(digit)) {
              dispatch(forgottenPassword(id, 2, result));
            } else {
              dispatch(forgottenPassword(id, 3, result));
            }
          })
          .onError(function () {});
      }
    );
  };

  return (
    <>
      {loading ? (
        <Spinner fullScreen={true} />
      ) : (
        <PhoneComp>
          <AuthLayoutTitle>{t('forgotten_password.title')}</AuthLayoutTitle>
          {/* <Label>{t('label')}</Label> */}
          <form onSubmit={sendMail}>
            <InputBackOffice
              id={'id'}
              type={'text'}
              placeholder={t('forgotten_password.placeholder')}
              name={'id'}
              value={id}
              onChange={setId}
              label={t('label')}
            />

            <AuthButton
              opacity={id}
              type='submit'
              disabled={id === '' ? true : false}>
              {t('forgotten_password.buttonText')}
            </AuthButton>
          </form>
        </PhoneComp>
      )}
    </>
  );
};

export default ForgottenPassword;
