import React from 'react';
import { toast } from 'react-toastify';
import { ErrorCircle, IconMessageWrap } from './ToastsStyled';
import { Icon, IconAlert, IconAlertSuccess } from '../../../styles/Icons';
import { color } from '../../../styles/GlobalStyles';
import { P2, P3 } from '../../../styles/FontStyles';

const toastConfig = {
  icon: false,
  hideProgressBar: true,
  pauseOnHover: true,
  autoClose: 5000,
  position: 'top-center',
  draggable: true,
  closeOnClick: true
};

export const SuccessToast = (successMessage) => {
  toast.success(
    <SuccessToastComponent successMessage={successMessage ?? 'Success'} />,
    toastConfig
  );
};

export const ErrorToast = (errorMessage) => {
  toast.error(
    <ErrorToastComponent errorMessage={errorMessage ?? 'Error'} />,
    toastConfig
  );
};

export const SuccessToastComponent = ({ successMessage }) => (
  <>
    <IconMessageWrap>
      <ErrorCircle success>
        <Icon color={color.colors.neutral50}>
          <IconAlertSuccess />
        </Icon>
      </ErrorCircle>
      <P2 color={color.colors.neutral900}>{successMessage}</P2>
    </IconMessageWrap>
  </>
);

export const ErrorToastComponent = ({ errorMessage }) => (
  <>
    <IconMessageWrap>
      <ErrorCircle>
        <Icon color={color.colors.neutral50}>
          <IconAlert />
        </Icon>
      </ErrorCircle>

      <P3 color={color.colors.neutral900}>{errorMessage}</P3>
    </IconMessageWrap>
  </>
);
