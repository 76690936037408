import { getUserDetails } from '../../actions/authActions';
import { tryExecuteSimpleAction } from '../../actions/common';
import { redirectToGetexch } from '../../helpers/helperFunctions';
import { setUserPrizerToLS } from '../../helpers/localstorage-helper';
import { localStorageGetter } from '../../lib/utils';
import { hubsData } from './HubsData';
import {
  ADD_HUB,
  GET_HUBS,
  REGISTER_ON_KYC_PLATFORM
} from './Marketplace.constants';
import {
  addHubAsync,
  getPackageAsync,
  getPackagesAsync,
  registerKycOnX1PlatformAsync
} from './Marketplace.requests';

export const getHubs = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_HUBS.REQUEST,
    async () => {
      const { data: userPackages } = await getPackageAsync();
      const { data } = await getPackagesAsync();

      let packages = data.data.packages;

      const newHubs = packages
        .map((hub) => {
          let item = hubsData.find((i) => i.id === hub.packageId);

          return item
            ? {
                ...item,
                ...hub
              }
            : hub;
        })
        .reverse();

      dispatch({
        type: GET_HUBS.SUCCESS,
        payload: {
          newHubs,
          walletAddress: data.data.walletAddress,
          userHubs: userPackages.data.userPackages,
          isFirstGiftAvailable: data.data.isFirstGiftAvailable
        }
      });
    },
    GET_HUBS.FAIL
  );
};

export const addHub =
  (packageId, currencyType, networkType, licenceId, step, setStep) =>
  async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      ADD_HUB.REQUEST,
      async () => {
        dispatch({ type: ADD_HUB.REQUEST });

        const { data } = await addHubAsync(
          packageId,
          currencyType,
          networkType,
          licenceId
        );

        dispatch({
          type: ADD_HUB.SUCCESS,
          payload: {
            userPackage: data.data.userPackage,
            id: data.data.id,
            walletAddress: data.data.walletAddress,
            currencyType,
            networkType,
            licenceId
          }
        });

        setStep(step);

        if (currencyType === 3) {
          dispatch(getUserDetails());
        }
      },
      GET_HUBS.FAIL
    );
  };

export const registerKycOnX1Platform =
  (paymentAddress, price, email) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      REGISTER_ON_KYC_PLATFORM.REQUEST,
      async () => {
        await registerKycOnX1PlatformAsync();

        dispatch({
          type: REGISTER_ON_KYC_PLATFORM.SUCCESS
        });

        redirectToGetexch(paymentAddress, price, email);

        const userFormLs = localStorageGetter('userPrizer');

        const parsedUser = JSON.parse(userFormLs);

        const newUser = {
          ...parsedUser,
          isX1KycRegistered: true
        };

        setUserPrizerToLS(JSON.stringify(newUser));
      },
      REGISTER_ON_KYC_PLATFORM.FAIL
    );
  };
