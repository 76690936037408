import styled from 'styled-components';

export const SummaryGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 40px;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PortfolioGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const BoxPortfolio = styled.div`
  border-radius: 16px;
  padding: 20px;
  background: ${(props) => props.theme.colors.neutral100};
  color: ${(props) => props.theme.colors.primary900};
  display: flex;
  align-items: center;

  ${({ hasButton }) =>
    hasButton &&
    `
    gap: 16px;
    justify-content: space-between;
`}

  @media (max-width: 800px) {
    padding: 16px;

    ${({ hasButton }) =>
      hasButton &&
      `
      align-items: start;
      flex-direction: column;
`}
  }
  /* box-shadow: ${(props) => props.theme.shadows.shadowXS}; */
`;

export const BoxGray = styled(BoxPortfolio)`
  border-radius: 24px;
  padding: 20px;
  background: ${(props) => props.theme.colors.neutral100};
  display: flex;
  align-items: center;
  box-shadow: none;
`;

export const BoxBig = styled.div`
  ${({ relative }) => relative && 'position: relative'};
  border-radius: 24px;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  background: ${(props) => props.theme.colors.neutral50};
  //cursor: pointer;
  /* overflow: hidden; */
  margin-bottom: 16px;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  > div:nth-child(2) {
    flex-grow: 1;
    padding: 30px;
    @media (max-width: 800px) {
      padding: 20px;
    }
  }
`;

export const HubImage = styled.div`
  width: 250px;
  border-radius: 24px;
  flex: none;
  min-height: 300px;
  /* aspect-ratio: 9/16; */
  background: linear-gradient(
      180deg,
      rgba(72, 48, 193, 0) 64.87%,
      #4830c1 96.21%
    ),
    url(${({ image }) => image});
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;

  @media (max-width: 800px) {
    min-height: 200px;

    width: 100%;
    aspect-ratio: 21/9;
  }
`;

export const Tr = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const Td = styled.div`
  ${(props) => props.w && `width: ${props.w}`};
  padding: 0 5px;

  @media (max-width: 800px) {
    ${(props) => props.wSM && `width: ${props.wSM}`};
    margin-bottom: 20px;
  }
`;

export const IconCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  background: ${(props) => props.theme.colors.neutral100};
`;

export const Collapsable = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.expanded ? '200px' : '0px')};
  margin-top: ${(props) => (props.expanded ? '40px' : '0px')};
  transition: all 300ms;

  @media (max-width: 800px) {
    margin-top: ${(props) => (props.expanded ? '20px' : '0px')};
    max-height: ${(props) => (props.expanded ? '450px' : '0px')};
  }
  /* > div {
    margin-top: 40px;
  } */
  /* margin-bottom: ${(props) => (props.expanded ? '10px' : '0')}; */
`;

export const Table = styled.table`
  border-radius: 24px !important;
  border: none;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px ${(props) => props.theme.colors.neutral900};
  border-style: hidden;
  overflow: hidden;
  tbody {
    color: ${(props) => props.theme.colors.neutral900};
  }

  thead {
    color: ${(props) => props.theme.colors.neutral500};
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    tr {
      background: ${(props) => props.theme.colors.neutral50};
    }
  }

  tr {
    background: ${(props) => props.theme.colors.primary900}20;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  }

  td {
    padding: 12px;
    border: 1px solid ${(props) => props.theme.colors.neutral900};
  }

  th {
    padding: 12px;
    border: 1px solid ${(props) => props.theme.colors.neutral900};
  }
`;

export const Transaction = styled.div`
  margin-bottom: 12px;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
`;

export const VirtualOfficeSubscription = styled.div`
  width: 100%;

  > div > div:nth-child(2) {
    text-align: right;
  }
`;

export const ReadMore = styled.span`
  color: ${(props) => props.theme.colors.primary900};
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
`;
