import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  height: 50px;
  background: ${(props) =>
    props.disabled
      ? '#403C3B;'
      : `linear-gradient(to bottom right, #d24074, #1268c3)`};
  color: #ffffff;
  border: ${(props) =>
    props.disabled ? '2px solid rgba(254, 254, 254, 0.3)' : 'none'};
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
`;

export const LinearButton = ({
  text,
  width,
  disabled,
  onClick,
  style,
  maxWidth
}) => {
  return (
    <Button
      onClick={onClick}
      width={width}
      maxWidth={maxWidth}
      disabled={disabled}
      style={style}>
      {text}
    </Button>
  );
};
