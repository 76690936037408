import { getAccessToken } from '../helpers/localstorage-helper';

export const createAxiosConfigWithAuthHeader = () => {
  const tokenFromLs = getAccessToken();

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenFromLs}`
    }
  };
};
