import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { H5, P1 } from '../../../styles/FontStyles';
import { Box, Flexed, Button } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { Icon, IconWarningMark } from '../../../styles/Icons';
import { CheckText } from '../../../components/common/CheckText';

import { ClaimSteps } from './types';

export const ClaimConfirm = ({ setClaim, setClaimStep }) => {
  const { t } = useTranslation('my_hubs');
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  return (
    <>
      <Box gray>
        <Flexed column>
          <Flexed column center>
            <Icon mb='12'>
              <IconWarningMark size='50' />
            </Icon>
            <H5 mb='20' color={color.colors.neutral500}>
              {t('claim.warning')}
            </H5>
          </Flexed>

          <P1 mb='20' left bold>
            {t('claim.text1')}
            <br />
            <br />
            {t('claim.text2')}
          </P1>

          <P1 mb='20' left bold>
            <span style={{ color: 'red' }}> {t('claim.risk')}</span>
          </P1>
          <Flexed column>
            <CheckText
              x='true'
              text={t('claim.check_text1')}
              checkColor={color.colors.error400}
            />
            <CheckText
              x='true'
              text={t('claim.check_text2')}
              checkColor={color.colors.error400}
            />
          </Flexed>
        </Flexed>
      </Box>
      <CheckText
        checked={checkbox1}
        onClick={() => setCheckbox1(!checkbox1)}
        text={t('claim.confirm_txt')}
        checkColor={color.colors.error400}
      />
      <CheckText
        checked={checkbox2}
        onClick={() => setCheckbox2(!checkbox2)}
        text={t('claim.confirm_txt2')}
        checkColor={color.colors.error400}
      />
      <Flexed>
        <Button
          onClick={() => setClaim(false)}
          mt='20'
          mr='10'
          color={color.colors.success400}>
          {t('claim.cancel_withdrawal')}
        </Button>
        <Button
          onClick={() => setClaimStep(ClaimSteps.STEP2)}
          mt='20'
          ml='10'
          disabled={!checkbox1 || !checkbox2}
          color={color.colors.error400}>
          {t('claim.continue_to_withdrawal')}
        </Button>
      </Flexed>
    </>
  );
};
