import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import ReactCodeInput from 'react-code-input';
import { AnimatedAuth } from '../../components/animation/AnimatedAuth';
import { InputBackOffice } from '../../components/common/InputBackoffice';
import Spinner from '../../components/common/Spinner';
import { ErrorToast } from '../../components/common/Toasts/Toasts';
import { validateEmail } from '../../helpers/helperFunctions';
import { P3 } from '../../styles/FontStyles';
import {
  AuthLayoutTitle,
  SecurityCodeWrapper
} from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  AuthButton,
  BlackUnderline,
  Label,
  PhoneComp
} from '../../styles/stylesStyled';
import {
  changeEmailAction,
  resendNewEmailVerificationAction
} from './changeEmail.actions';

const ChangeEmail = () => {
  const { t } = useTranslation('onboarding.email_address');

  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [emailError] = useState('');
  const [reEmailError] = useState('');

  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.changeEmail);

  // const location = useLocation();

  const sendEmail = (e) => {
    e.preventDefault();
    if (!email || !email2) {
      ErrorToast(t('error_email_empty'));
      return;
    }
    if (!validateEmail(email) || !validateEmail(email2)) {
      ErrorToast(t('error_email_invalid'));
      return;
    }
    setStep(2);
  };

  const resendEmail = (e) => {
    e.preventDefault();

    dispatch(resendNewEmailVerificationAction());
  };

  const submit = (e) => {
    e.preventDefault();
    dispatch(changeEmailAction(email, code, setStep));
  };

  return (
    <div>
      {loading && <Spinner fullScreen={true} />}
      <PhoneComp>
        <AnimatePresence exitBeforeEnter>
          {step === 1 && (
            <AnimatedAuth index={1} key={1}>
              <form onSubmit={sendEmail}>
                <AuthLayoutTitle>
                  PLEASE ENTER NEW EMAIL ADDRESS
                </AuthLayoutTitle>
                <Label>{t('label')}</Label>
                <InputBackOffice
                  id={'email'}
                  type={'text'}
                  placeholder={' New email address'}
                  name={'email'}
                  value={email.replace(/\s+/g, '')}
                  onChange={(e) => setEmail(e.replace(/\s+/g, ''))}
                  label={t('label')}
                  error={emailError}
                />

                <InputBackOffice
                  id={'email'}
                  type={'text'}
                  placeholder={'Repeat email address'}
                  name={'email'}
                  value={email2.replace(/\s+/g, '')}
                  onChange={(e) => setEmail2(e.replace(/\s+/g, ''))}
                  label={t('label')}
                  error={reEmailError}
                />

                <AuthButton
                  opacity={email && email2}
                  type='submit'
                  disabled={email === '' && email2 === '' ? true : false}>
                  {t('button_text')}
                </AuthButton>
              </form>
            </AnimatedAuth>
          )}

          {step === 2 && (
            <AnimatedAuth index={2} key={2}>
              <form onSubmit={submit}>
                <AuthLayoutTitle>ENTER 2FA VERIFICATION CODE</AuthLayoutTitle>
                <SecurityCodeWrapper is2Fa>
                  <ReactCodeInput
                    fields={6}
                    value={code}
                    onChange={setCode}
                    type={'number'}
                  />
                </SecurityCodeWrapper>
                <AuthButton
                  opacity={code.length === 6}
                  type='submit'
                  disabled={code.length < 6 ? true : false}>
                  Continue
                </AuthButton>
              </form>
            </AnimatedAuth>
          )}

          {step === 3 && (
            <AnimatedAuth index={3} key={3}>
              <AuthLayoutTitle>VERIFY YOUR EMAIL </AuthLayoutTitle>
              <P3 mb='40' center color={color.colors.neutral500}>
                We&apos;ve sent email to <br />
                <br /> {email} <br />
                <br /> Check it and follow the instructions
              </P3>
              <BlackUnderline onClick={resendEmail}>
                Resend email
              </BlackUnderline>
            </AnimatedAuth>
          )}
        </AnimatePresence>
      </PhoneComp>
    </div>
  );
};

export default ChangeEmail;
