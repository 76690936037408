import { motion } from 'framer-motion';
import styled from 'styled-components';

const Layout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* min-height: calc(100vh - 116px); */
  min-height: calc(100vh - 70px);

  flex-direction: column;
  background: ${(props) => props.theme.appLayout.layoutBackground};
  /* margin-top: 116px; */
  margin-top: 70px;
  width: 100%;
`;

export const LayoutContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 70px);
`;

const Main = styled.div`
  width: 100%;
  max-width: 1687px;
  padding: 0 50px;
  margin: 0 auto;
  width: 100%;
`;

const MainWrapper = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    padding: 0 32px;
  }
`;

const MainNav = styled.div`
  max-width: 1687px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

const TopNavigation = styled(motion.div)`
  width: 100%;
  background: ${(props) => props.theme.appLayout.navBackground};
  color: ${(props) => props.theme.appLayout.navFontColor};
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  position: fixed;
  /* top: 46px; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};

  @media (max-width: 800px) {
    padding: 0 20px;
  }

  a {
    color: ${(props) => props.theme.appLayout.navFontColor} !important;
  }
`;

const InfoNav = styled.div`
  width: 100%;
  background: #161313;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;

const InfoNavigationMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1280px;
`;

const TopNavigationMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
`;

const VerticalSeparator = styled.div`
  border-right: 1px solid #ffffff;
  height: 26px;
  margin: 0 20px;
  opacity: 0.3;
`;

const Language = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  img {
    margin-left: 10px;
    transform: ${(props) => (props.language ? 'rotate(180deg)' : '')};
    transition: all 0.3s ease;
  }
`;

const Navigation = styled.div`
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NavLeft = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-direction: column;
  color: ${(props) => props.theme.appLayout.footerFontColor} !important;

  > * {
    color: ${(props) => props.theme.appLayout.footerFontColor} !important;
  }

  a {
    color: ${(props) => props.theme.appLayout.footerFontColor} !important;
  }
`;

const FooterRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: normal;

  p {
    font-size: 13px;
    line-height: 15px;
    margin: 0 25px 0 0 !important;
  }
  img {
    margin-left: 15px;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    margin-bottom: 24px;
    justify-content: center;
    flex-wrap: wrap;
  }

  img {
    opacity: 1;
    margin: 0 6px;
  }
`;

export const FootrMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  img {
    width: 50px;
  }
`;

const SideButtons = styled(motion.div)`
  padding-top: 80px;
  width: 72px;
  background: ${(props) => props.theme.appLayout.sideNavBackground};
  position: fixed;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};

  /* top: 116px; */
  top: 70px;
  left: 0;
  overflow: hidden;
  /* min-height: calc(100vh - 116px); */
  min-height: calc(100vh - 70px);
  /* visibility: ${({ show }) => (show ? 'visible' : 'hidden')}; */
  z-index: 1000;

  img {
    margin: 20px;
    width: 32px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* color: ${(props) => props.theme.appLayout.sideNavFontColor}; */
    display: none;
    opacity: 0;
  }

  button {
    opacity: 0;
  }

  /* a {
    color: ${(props) => props.theme.appLayout.sideNavFontColor} !important;
  } */

  :hover {
    width: 210px;
    p {
      opacity: 1;
      display: flex;
      flex: none;
    }
    button {
      opacity: 1;
    }
  }
  transition: all 0.2s ease;
  @media (max-width: 800px) {
    display: none;
  }
`;

const SideButtonsContent = styled.div`
  width: 100%;

  img {
    margin: 30px 20px;
  }
`;

export const Menu = styled.div`
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  max-height: calc(100vh - 200px);
  background: ${(props) => props.theme.appLayout.navBackground};
  border-radius: 12px;
  padding: 30px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  font-size: 16px;
  line-height: 20px;
  overflow: auto;

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  /* ${(props) => (props.isOpen ? 'display: block;' : 'display: none;')}; */
  ${(props) => (props.isOpen ? 'visibility: visible;' : 'visibility: hidden;')}
  ${(props) => (props.isOpen ? 'opacity: 1;' : 'opacity: 0;')};
  transform: ${(props) =>
    props.isOpen ? ' translateY(0)' : 'translateY(-20px)'};

  transition: all 0.2s ease;

  z-index: 100005;
`;

export const MenuInviteLabels = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* color: #ffffff; */
  margin-bottom: 12px;
`;

export const MenuInvitePlaceholder = styled.div`
  background: ${(props) => props.theme.colors.neutral100};
  border-radius: 8px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 12px;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral900};
`;

export const Collapsable = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  max-height: ${(props) => (props.expanded ? '288px' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  transition: all 0.3s ease;
  /* margin-bottom: ${(props) => (props.expanded ? '20px' : '0')}; */
`;

export const LanguageWrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const LanguageSelect = styled.select`
  background: none;
  color: ${(props) => props.theme.colors.neutral900} !important;
  border: none;
`;

export const LanguageDropdown = styled.div`
  width: 180px;
  position: absolute;
  right: 0;
  top: 40px;

  ${(props) => (props.isOpen ? 'visibility: visible;' : 'visibility: hidden;')}
  ${(props) => (props.isOpen ? 'opacity: 1;' : 'opacity: 0;')};
  transform: ${(props) =>
    props.isOpen ? ' translateY(0)' : 'translateY(-20px)'};

  transition: all 0.2s ease;
  padding: 10px;
  padding-bottom: 5px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.neutral50};
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  color: ${(props) => props.theme.colors.neutral900};
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 200px;
  > div {
    margin-bottom: 5px;
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.colors.primary900};
    }
  }
`;

export const NewMembers = styled(motion.div)`
  z-index: 1001;
  height: 60px;
  background: #f2f6ff;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  top: 70px;
  left: 0;
  right: 0;
`;

export const Members = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  /* ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none; */
`;

export const User = styled.div`
  margin: 0 20px;
  display: inline-flex;
  align-items: center;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: ${(props) => props.theme.dashboard.fontColor};
  margin-bottom: 24px;

  display: flex;
  align-items: center;
`;

export {
  Layout,
  Main,
  MainWrapper,
  TopNavigation,
  MainNav,
  VerticalSeparator,
  Language,
  Navigation,
  NavRight,
  NavLeft,
  FooterRight,
  FooterLeft,
  Footer,
  TopNavigationMain,
  InfoNav,
  InfoNavigationMain,
  SideButtons,
  SideButtonsContent
};
