import React from 'react';
// import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SuccessToast } from '../../../components/common/Toasts/Toasts';
import {
  formatUSD,
  showNetworkType,
  USDT
} from '../../../helpers/helperFunctions';

import { H2, H3, P3, CaptionText } from '../../../styles/FontStyles';
import { Button, Flexed } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { Icon, IconCopy } from '../../../styles/Icons';
import { SelectedUSDT } from '../../Marketplace/Marketplace.styled';
import success from '../../../assets/images/backoffice/kyc6.png';
import { Hub } from '../../../components/common/Hub/Hub';
import { ORDERS } from '../../../routes/routes';
// import { CreditCard } from '../../../components/common/CreditCard';

export const Step3 = ({ selectedHub, isFirstGiftAvailable }) => {
  const { t } = useTranslation('marketplace');
  const history = useHistory();

  const { userPackage, currencyType, hubs } = useSelector(
    (state) => state.hubs
  );

  const {
    id,
    image,
    title,
    price,
    weeklyCap,
    firstGift,
    limitedQuantity,
    calculator
    // proRankBoost
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = hubs?.find((i) => i.id === selectedHub);

  return (
    <>
      {currencyType === 3 && (
        <>
          <Flexed justifyCenter>
            <img src={success} alt='success' />
          </Flexed>
          <H3 center mb='5' color={color.colors.neutral900}>
            {
              id > 34
                ? 'Gaming Hub NFT successfully purchased and activated'
                : 'Staking Hub NFT successfully purchased and activated' //t('success.title')
            }
          </H3>
        </>
      )}

      {currencyType === 1 && userPackage?.networkType && (
        <>
          <P3 mb='8' mt='20' color={color.colors.neutral900}>
            {t('success.you_selected')}
            <br />
          </P3>
          <Hub
            key={id}
            condensed
            image={image}
            title={title}
            price={price}
            weeklyCap={weeklyCap}
            limitedQuantity={limitedQuantity}
            info={
              <>
                {calculator && (
                  <Flexed alignEnd end>
                    <H2 bold mr='16' alignEnd>
                      {calculator}
                    </H2>
                    <CaptionText bold right mb='4'>
                      Reward
                      <br /> Multiplicator
                    </CaptionText>
                  </Flexed>
                )}
              </>
            }
            stakingHubDetails={
              <>
                {isFirstGiftAvailable && (
                  <>
                    <P3 bold color={color.colors.primary900}>
                      FIRST ORDER GIFT 🎁
                    </P3>
                    <P3 bold>{firstGift}of FREE Activity*</P3>
                  </>
                )}
              </>
            }
          />
          <P3 mb='8' mt='20' color={color.colors.neutral900}>
            {t('success.text1')}
            <br />
            <br />
            {t('success.text2')}
            <br />
            <br />
            {t('success.text3')}
          </P3>
        </>
      )}

      {currencyType === 2 && (
        <P3 mt='5' color={color.colors.neutral900}>
          {t('success.thank_you')}
        </P3>
      )}
      <br />

      {currencyType === 1 && userPackage?.networkType && (
        <div style={{ marginBottom: 20 }}>
          <P3 bold mb='8' color={color.colors.neutral900}>
            {t('success.payment_amount')}
          </P3>
          <SelectedUSDT>
            <div className='truncated'>
              <div>
                {' '}
                {formatUSD(price)} <span>{USDT}</span>
              </div>
            </div>
            <b>USDT</b>
          </SelectedUSDT>
        </div>
      )}

      {currencyType === 1 && userPackage?.networkType && (
        <div style={{ marginBottom: 20 }}>
          <P3 bold mb='8' color={color.colors.neutral900}>
            {t('selected_network')}
          </P3>
          <SelectedUSDT>
            <div className='truncated'>
              <div>{showNetworkType(userPackage)}</div>
            </div>
          </SelectedUSDT>
        </div>
      )}

      {currencyType === 1 && userPackage?.networkType && (
        <P3 bold mb='8' color={color.colors.neutral900}>
          {t('success.wallet_address')}
        </P3>
      )}
      {currencyType === 1 && userPackage?.networkType && (
        <SelectedUSDT
          color={'#ffffff'}
          onClick={() => {
            navigator.clipboard.writeText(userPackage?.paymentAddress);
            SuccessToast(t('success.copied'));
          }}
          style={{ cursor: 'pointer' }}>
          <div className='truncated'>
            <div>{userPackage?.paymentAddress}</div>
          </div>

          <Icon ml='10' color={color.colors.neutral900}>
            <IconCopy />
          </Icon>
        </SelectedUSDT>
      )}

      <Flexed justifyCenter mt='40'>
        <Button
          onClick={() => {
            history.push(ORDERS);
          }}
          maxWidth='200px'>
          Close
        </Button>
      </Flexed>

      {/* {userPackage?.networkType === 1 ? (
        <CreditCard
          paymentAddress={userPackage?.paymentAddress}
          price={price}
          isPayment={true}
        />
      ) : (
        <Flexed justifyCenter mt='40'>
          <Button
            onClick={() => {
              history.push(ORDERS);
            }}
            maxWidth='200px'>
            Close
          </Button>
        </Flexed>
      )} */}
    </>
  );
};
