import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { CaptionText, H1 } from '../../styles/FontStyles';
import { CountdownNumbers } from '../CashbackBonus/CashbackBonusStyled';

export const Counter = ({ timestamp }) => {
  const { t } = useTranslation('cashback');

  return (
    <Countdown
      date={timestamp}
      renderer={({ days, hours, minutes, seconds }) => (
        <CountdownNumbers>
          <div>
            <H1>{days}</H1>
            <CaptionText caps> {t('days')}</CaptionText>
          </div>
          <div>
            <H1>{hours}</H1>
            <CaptionText caps> {t('hours')}</CaptionText>
          </div>
          <div>
            <H1>{minutes}</H1>
            <CaptionText caps> {t('minutes')}</CaptionText>
          </div>
          <div>
            <H1>{seconds}</H1>
            <CaptionText caps> {t('seconds')}</CaptionText>
          </div>
        </CountdownNumbers>
      )}
    />
  );
};
