import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Responsive = styled.div`
  ${({ width }) => width && `width: 100%;`}
  display: flex;
  ${({ center }) => center && `align-items: center;`}
  ${({ end }) => end && `align-items: flex-end;`}
  ${({ between }) => between && `justify-content: space-between;`}
  ${({ column }) => column && `flex-direction: column;`}
  ${({ textRight }) => textRight && `text-align: right;`}
  ${({ justifyEnd }) => justifyEnd && `justify-content: flex-end;`}

  ${({ p }) => p && `padding: ${p}`};
  ${({ m }) => m && `margin: ${m}`};
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};

  @media (max-width: 800px) {
    ${({ pSM }) => pSM && pSM};
    ${({ mSM }) => mSM && mSM};
    ${({ mtSM }) => mtSM && `${mtSM}px`};
    ${({ mbSM }) => mbSM && `${mbSM}px`};
    ${({ mlSM }) => mlSM && `${mlSM}px`};
    ${({ mrSM }) => mrSM && `${mrSM}px`};

    flex-direction: column;
    ${({ reverse }) => reverse && `flex-direction: column-reverse;`}
    ${({ startSM }) => startSM && `align-items: start;`}
  }
`;

export const DisableSelection = ` 
-webkit-touch-callout: none !important;
-webkit-user-select: none !important;
-khtml-user-select: none !important; 
-moz-user-select: none !important;
-ms-user-select: none !important; 
user-select: none !important; 
`;

export const AuthLogo = styled.div`
  width: ${(props) => props.theme.authLayoutLogoWidth};
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    width: ${(props) => props.theme.authLayoutLogoWidth};
  }
`;

const Logo = styled.div`
  width: 180px;
  margin: 40px 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const TextBig = styled.div`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: ${(props) => props.theme.neutral700};
  margin-bottom: 20px;
`;

const Text = styled.div`
  font-size: 15px;
  color: #2d2d2d;
  opacity: 0.7;
  text-align: center;
  margin-bottom: 20px;
  span {
    margin-top: 20px;
  }
`;

const TextOrange = styled.div`
  font-weight: 400;
  color: #ff5700;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  margin: 12px 0;
`;

const TextPink = styled.div`
  color: #ee1a80;
  letter-spacing: 0.01em;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

const Button = styled.button`
  border: none;
  font-size: 15px;

  /* color: ${(props) => (props.negative ? '#FA8D11' : `#fff`)}; */
  height: 55px;
  width: calc(100% - 20px);

  font-size: ${(props) => props.theme.authButtonFontSize};
  color: ${(props) =>
    !props.opacity
      ? props.theme.authButtonDisabledFontColor
      : props.theme.authButtonFontColor};

  background-color: ${(props) =>
    !props.opacity
      ? props.theme.authButtonDisabledBackground
      : props.theme.authButtonBackground};

  border: ${(props) =>
    !props.opacity
      ? props.theme.authButtonDisabledBorder
      : props.theme.authButtonBorder};

  opacity: ${(props) => (!props.opacity ? '0.5' : '1')};
  cursor: ${(props) => (!props.opacity ? 'default' : 'pointer')};
  border-radius: ${(props) => props.theme.authButtonBorderRadius};

  /* box-shadow: 2px 5px 20px rgba(246, 110, 46, 0.3); */
  margin: 50px 10px 20px 10px;

  height: ${(props) => props.h};

  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;

  max-width: ${(props) => props.maxWidth};

  @media (max-width: 800px) {
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}px;
    margin-bottom: ${(props) => props.mbSM}px;
    margin-left: ${(props) => props.mlSM}px;
    margin-right: ${(props) => props.mrSM}px;

    max-width: ${(props) => props.maxWidthSM};
  }
  /* filter: drop-shadow(2px 5px 20px rgba(246, 110, 46, 0.3)); */

  color: red;
  webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  standard-user-select: none;

  transition: transform 0.1s ease;

  /* :hover {
    box-shadow: 3px 7px 20px rgba(246, 110, 46, 0.4);
    transition: box-shadow 0.5s ease;
  } */
  :active {
    transform: translateY(3px);
    /* box-shadow: 0px 0px 0px rgba(246, 110, 46, 0); */
    /* transition: box-shadow 0.3s ease, transform 0.1s ease; */
  }
  > * {
    ${DisableSelection}
  }
`;

const ButtonWrapper = styled.div`
  /* padding: 0 55px;
  @media (max-width: 550px) {
    padding: 0;
  } */
`;

const AuthButton = styled.button`
  font-weight: 500;
  height: ${(props) => props.theme.authButtonHeight};
  width: 100%;

  /* border: ${(props) => props.theme.authButtonBorder}; */
  font-size: ${(props) => props.theme.authButtonFontSize};
  color: ${(props) =>
    !props.opacity
      ? props.theme.authButtonDisabledFontColor
      : props.theme.authButtonFontColor};

  background-color: ${(props) =>
    !props.opacity
      ? props.theme.authButtonDisabledBackground
      : props.theme.authButtonBackground};

  border: ${(props) =>
    !props.opacity
      ? props.theme.authButtonDisabledBorder
      : props.theme.authButtonBorder};

  opacity: ${(props) => (!props.opacity ? '0.5' : '1')};
  cursor: ${(props) => (!props.opacity ? 'default' : 'pointer')};
  border-radius: ${(props) => props.theme.authButtonBorderRadius};
  margin: 30px 0px 10px 0px;

  webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  standard-user-select: none;

  transition: transform 0.1s ease, background-color 0.3s ease, border 0.3s ease;

  /* box-shadow: 2px 5px 20px rgba(246, 110, 46, 0.3); */
  /* :hover {
    box-shadow: 3px 7px 20px rgba(246, 110, 46, 0.4);
    transition: box-shadow 0.5s ease;
  } */
  :active {
    transform: translateY(3px);
    /* box-shadow: 0px 0px 0px rgba(246, 110, 46, 0); */
    /* transition: box-shadow 0.3s ease, transform 0.1s ease; */
  }
`;

const Navigation = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
`;

const SectionTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  color: #2d2d2d;

  @media (max-width: 350px) {
    font-size: 15px;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
`;

const X = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
`;
const Y = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const XX = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
`;
const YY = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const PlaceHolderContainer = styled.div`
  background: rgba(45, 45, 45, 0.05);
  border-radius: 13px;
  width: 100%;
  height: 55px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin: 0 0 20px 0;
  justify-content: space-between;
`;

const PlaceHolderLabel = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #2d2d2d;
  opacity: 0.7;
  margin-bottom: 3px;
`;

const PlaceHolder = styled.div`
  width: 100%;
  font-size: 17px;
  line-height: 20px;
  color: #2d2d2d;
  caret-color: #fa8d11;
`;

const SmallDrawer = styled.div`
  display: flex;
  opacity: ${(props) => (props.fbox ? '1' : '0')};
  transition: all 0.3s ease;
  width: 100vw;
  height: 235px;
  position: fixed;
  bottom: ${(props) => (props.fbox ? '0' : '-110%')};
  left: 0;
  z-index: 101;
  // align-items: center;
  justify-content: center;
  background: rgba(249, 249, 249, 0.8);
  backdrop-filter: blur(50px);
  border-radius: 8px 8px 8px 8px;
  border-top: 2px solid #fa8d11;
  // padding 100px 0;
  > * {
    width: 311px;
  }
`;

const ImagePHContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(45, 45, 45, 0.05);
  border: 2px dashed #fa8d11;
  box-sizing: border-box;
  border-radius: 13px;
  width: 100%;
  min-height: 200px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #2d2d2d;
  padding: 50px;
  margin: 0 0 20px 0;
  img {
    width: 40px;
    margin: 0 0 20px 0;
  }
  div {
    opacity: 0.7;

    margin: 0 0 5px 0;
  }
`;

const ImagePHContainerFilled = styled.div`
  background: rgba(45, 45, 45, 0.05);
  border-radius: 13px;
  padding: 7px 27px;
  margin: 0 0 20px 0;
  img {
    width: 100%;
  }
`;

const ImagePHContainerCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(45, 45, 45, 0.05);
  border: 2px dashed #fa8d11;
  box-sizing: border-box;
  border-radius: 50%;
  width: 100%;
  min-height: 311px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #2d2d2d;
  opacity: 0.7;
  margin: 0 auto 20px auto;
  overflow: hidden;
  img {
    width: 40px;
    margin: 0 0 20px 0;
  }
`;

const ImagePHContainerFilledCircle = styled.div`
  background: rgba(45, 45, 45, 0.05);
  border-radius: 50%;
  // padding: 13px;
  overflow: hidden;
  margin: 0 auto 20px auto;
  width: 311px;
  height: 311px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BigDrawer = styled.div`
  display: flex;
  opacity: ${(props) => (props.bigDrawer ? '1' : '0')};
  transition: all 0.3s ease;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: ${(props) => (props.bigDrawer ? '0' : '210%')};
  top: 0;
  z-index: 10100;
  // align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  // background-color: #fff;
  padding: 100px 0;
  overflow-y: auto;
`;

const BigDrawerX = styled.div`
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const BigDrawerContent = styled.div`
  width: 311px;
`;

const BigText = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 55px;
  color: #000000;
`;

const MediumText = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 37px;
  color: #2d2d2d;
  word-wrap: break-word;
`;

const SmallText = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: #2d2d2d;
`;

const Section = styled.div`
  margin: 0 0 20px 0;
`;

const SectionWrapper = styled.div`
  padding: 10px;
  margin: 0 0 20px 0;
  background: rgba(45, 45, 45, 0.05);
  border-radius: 13px;
  width: 100%;
  /* height: 262px; */
  position: relative;
`;

const ImageBox = styled.div`
  width: 100%;
  height: 175px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const NormalMediumText = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 37px;
  color: #2d2d2d;
`;

const DashedWrapper = styled.div`
  border-bottom: 1px dashed rgba(0, 0, 0, 15%);
  padding-bottom: 22px;
`;

const View = styled.div`
  margin: 20px 0;
  min-height: 640px;
`;

const SectionSeparator = styled.div`
  border-bottom: 1px dashed rgba(254, 254, 254, 0.15);
  margin: ${(props) => (props.margin ? props.margin : '20px 0')};
  width: 100%;
`;

const SectionSeparatorDark = styled.div`
  /* border-bottom: 1px solid #403c3b; */
  margin: ${(props) => (props.margin ? props.margin : '20px 0')};
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #484b51 0%, #949494 50%, #484b51 100%);
  opacity: 0.5;
`;

const SectionSeparatorDarkVertical = styled.div`
  width: 1px;
  background: #403c3b;
`;

const SectionSeparatorIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  img {
    position: absolute;
    top: 0;
  }
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Flex = styled.div`
  display: flex;
  align-items: start;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const FlexJustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const FlexDirectionColumnCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const FlexStart = styled.div`
  display: flex;
  align-items: flex-start;
`;

const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FlexEndReverse = styled.div`
  display: flex;
  align-items: flex-end;
`;

const FlexAround = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const HalfBox = styled.div`
  width: 50%;
`;

const ProgressBar = styled.div`
  width: 100%;
  padding: 2px 3px;
  background: ${(props) =>
    props.white ? 'rgba(255, 255, 255, 0.3);' : 'rgba(45, 45, 45, 0.05)'};
  border: 1px solid rgba(45, 45, 45, 0.02);
  box-sizing: border-box;
  border-radius: 13px;
  div {
    width: ${(props) => (props.percentage ? props.percentage : 0)};
    height: 8px;
    background: linear-gradient(
      160.43deg,
      #ee288a 1.87%,
      #f26c6b 50.93%,
      #f58e35 99.33%
    );
    border-radius: 6px;
  }
`;

const FloatingButton = styled.div`
  position: relative;
  padding-top: 40px;
  button {
    /* width: calc(100% - 40px ); */
    margin-top: 0;
  }

  @media (max-width: 800px) {
    left: 40px;
    right: 40px;

    position: fixed;
    bottom: 20px;
  }
`;

export const FloatingButtonBackoffice = styled.div`
  z-index: 10;
  padding-top: 40px;
  position: fixed;
  left: 40px;
  right: 40px;
  bottom: 20px;
  button {
    width: 100%;
    margin-top: 0;
  }
  div {
    width: 100%;
  }
`;

const FloatingCartWrapper = styled.div`
  position: fixed;
  bottom: 20px;

  left: auto;
  right: auto;
  width: 311px;

  display: flex;
  justify-content: flex-end;

  @media (max-width: 450px) {
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
  }
`;

const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #2d2d2d;
`;

const ChatOnline = styled.div`
  background: #27ae60;
  box-shadow: 0px 0px 5px rgba(39, 174, 96, 0.7);
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-left: 5px;
`;

const BasicButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 15px;
  color: #fff;
  background: ${(props) => (props.color ? props.color : '#F48150;')};
  border: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
`;

const InputLabel = styled.label`
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(45, 45, 45, 0.5);
  display: inline-block;
`;

const SuccessThank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const SuccessBig = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
`;

const SuccessMedium = styled(SuccessBig)`
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 15px;
`;

const SuccessSmall = styled.div`
  font-size: 15px;
  line-height: 20px;
  opacity: 0.7;
`;

const BlackUnderline = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  text-align: center;
`;

const ProfilePicture = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.size ? props.size : '102px')};
  width: ${(props) => (props.size ? props.size : '102px')};
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  ); */

  /* -webkit-box-shadow: 0px 0px 0px 3px
    ${(props) => (props.color ? props.color : 'rgba(45, 45, 45, 0.05)')};
  box-shadow: 0px 0px 0px 3px
    ${(props) => (props.color ? props.color : 'rgba(45, 45, 45, 0.05)')}; */

  /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); */

  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  cursor: pointer;

  ${DisableSelection}/* border: 1.5px solid rgba(254, 254, 254, 0.3); */
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${DisableSelection};
`;

export const ProfileImagePlaceholder = styled.div`
  font-size: ${(props) => (props.font ? props.font : '102px')};
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    160.43deg,
    #f58e35 1.87%,
    #f26c6b 50.93%,
    #ee288a 99.33%
  );
  height: ${(props) => (props.size ? props.size : '102px')};
  width: ${(props) => (props.size ? props.size : '102px')};
  border-radius: 50%;

  @media (max-width: 800px) {
    font-weight: 600;
  }
`;

const TabSelectButton = styled.div`
  div {
    background: ${(props) => (props.selected ? '#403C3B;' : '#2C2726')};
    border-radius: 100px;
    padding: 0 12px;
    height: 35px;
    display: flex;
    align-items: center;
  }
  background: ${(props) =>
    props.selected
      ? 'linear-gradient(160.43deg,#f58e35 1.87%,#f26c6b 50.93%,#ee288a 99.33%);'
      : ''};
  padding: 1px;

  color: ${(props) => (props.selected ? '#FEFEFE;' : '#C6BDBD')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 100px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  @media (max-width: 800px) {
    margin-right: 8px;
  }
`;

const ViewBackOffice = styled(motion.div)`
  margin: 20px 0;
  /* padding-bottom: 70px; */
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    max-width: 1500px;
    padding: 0 100px;
  }

  ${({ zoom }) =>
    zoom
      ? `
    @media only screen and (max-width: 1500px) and (min-width: 800px) {
      zoom: 80%;
    }
  `
      : ''}
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.authLayoutLabel};
  margin-bottom: 10px;
`;

const PhoneComp = styled.div`
  @media (max-width: 800px) {
    margin-top: 20vh;
  }
  @media (max-height: 570px) {
    margin: 70px 0;
  }
  @media (max-height: 515px) {
    margin: 50px 0;
  }
`;

export const SidebarTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral900};
  margin-bottom: 8px;
  @media (max-height: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
export const SidebarSubtitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors.neutral500};
  margin-bottom: 40px;
  @media (max-height: 800px) {
    margin-bottom: 32px;
  }
`;

export const AnimationDiv = styled.div`
  /* width: 100%; */
  /* height: 100vh; */
  position: fixed;
  bottom: -100vh;
  top: 100%;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.colors.background};
  z-index: 100100;

  ${(props) =>
    props.transition
      ? `
   top: 0;
  bottom:0; 
  left: 0;
  right:0;
  `
      : ''}

  ${(props) =>
    props.transition2
      ? `
   top: -100vh;
   bottom: 100vh; 
  left: 0;
  right:0;
  `
      : ''}
  transition: all 0.5s ease-in-out;
`;

const Tabs = styled.div`
  /* background: linear-gradient(
    270deg,
    #1c1716 3.82%,
    rgba(28, 23, 22, 0) 26.36%
  ); */
  overflow-y: auto;
  display: flex;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const AppWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* -moz-transform: scale(0.8);
  -moz-transform-origin: 0 0; */
  @media (max-width: 1500px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const BankCard = styled.div`
  background: ${(props) => props.theme.colors.neutral100};
  width: 100%;
  border-radius: 8px;
  border: 1px solid #403c3b;
  padding: 12px 20px;
  /* height: 64px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral900};
  margin-bottom: 40px;

  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }
  img {
    margin-right: 8px;
  }
  @media (max-width: 800px) {
    padding: 10px 10px;
  }
`;

export const BankCardChange = styled.div`
  background: ${(props) => props.theme.colors.primary900};
  border-radius: 8px;
  padding: 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  flex: none;
  color: ${(props) => props.theme.colors.neutral900};
`;

export const WelcomeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
`;

export const SectionText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin: 12px 0;
`;

export const HubLengthMessage = styled.div`
  text-align: center;
  margin-top: 45px;
  font-size: 22px;
  font-weight: 700;
`;

export const SelectedAddress = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;

  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.color
      ? props.theme.colors.neutral500
      : props.theme.colors.neutral900};
  border-radius: 8px;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.neutral100};
  margin-top: 40px;
  margin-bottom: 12px;
`;

export const FlexBottom = styled.div`
  display: flex;
  align-self: flex-end;
`;

export {
  View,
  TextBig,
  Logo,
  Text,
  TextOrange,
  TextPink,
  Button,
  Navigation,
  SectionTitle,
  Section,
  X,
  Y,
  XX,
  YY,
  PlaceHolderContainer,
  PlaceHolderLabel,
  PlaceHolder,
  SmallDrawer,
  ImagePHContainer,
  ImagePHContainerFilled,
  ImagePHContainerCircle,
  ImagePHContainerFilledCircle,
  BigDrawer,
  BigDrawerX,
  BigDrawerContent,
  SmallText,
  MediumText,
  BigText,
  ImageBox,
  NormalMediumText,
  SectionWrapper,
  DashedWrapper,
  SectionSeparator,
  FlexBetween,
  Flex,
  ProgressBar,
  FloatingButton,
  HalfBox,
  FlexEnd,
  FlexAround,
  FlexStart,
  ChatHeader,
  ChatOnline,
  FlexEndReverse,
  FloatingCartWrapper,
  BasicButton,
  SectionSeparatorIcon,
  InputLabel,
  SuccessSmall,
  SuccessMedium,
  SuccessBig,
  SuccessThank,
  BlackUnderline,
  SectionSeparatorDark,
  SectionSeparatorDarkVertical,
  ProfilePicture,
  ProfileImage,
  TabSelectButton,
  ViewBackOffice,
  ButtonWrapper,
  AuthButton,
  Label,
  PhoneComp,
  FlexCenter,
  Tabs,
  FlexDirectionColumnCenter,
  FlexJustifyCenter,
  FlexSpaceBetween
};
