import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import danger from '../../assets/icons/danger.svg';
import success from '../../assets/icons/success.svg';

const AlertContainer = styled.div`
  min-height: 55px;
  width: calc(100% - 32px);
  max-width: 500px;
  position: fixed;
  top: 16px;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.colors.neutral100}59;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.neutral900};
  padding: 10px 15px;
  margin: 0 auto;
  z-index: 2147483647;
  transition: all 0.1s ease;
  @media (max-width: 800px) {
    top: 80px;
  }
`;

const IconAndMessage = styled.div`
  display: flex;
  align-items: center;
  img {
    margin: 0 15px 0 0;
  }
`;

const Alert = (props) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (props.message) {
      setIsVisible(true);
    }
  }, [props.message]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsVisible(false);
          props.setError && props.setError('');
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <AlertContainer
      ref={wrapperRef}
      style={{
        visibility: isVisible ? 'visible' : 'hidden'
      }}
      onClick={() => {
        setIsVisible(false);
        props.setError && props.setError('');
      }}>
      <IconAndMessage>
        <img
          src={props.icon === 'success' ? success : danger}
          alt='AlertIcon'
        />
        {props.message}
      </IconAndMessage>
    </AlertContainer>
  );
};

export default Alert;
