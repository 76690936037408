import axios from 'axios';
import moment from 'moment';
import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  ADD_SOCIAL_PHONE_FAIL,
  ADD_SOCIAL_PHONE_REQUEST,
  ADD_SOCIAL_PHONE_SUCCESS,
  CHANGE_EMAIL_LINK_FAIL,
  CHANGE_EMAIL_LINK_REQUEST,
  CHANGE_EMAIL_LINK_SUCCESS,
  CLEAR_REGISTER_STATE,
  CONFIRM_EMAIL_FAIL,
  CONFIRM_EMAIL_LINK_FAIL,
  CONFIRM_EMAIL_LINK_SUCCESS,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_NEW_PASSWORD_FAIL,
  CONFIRM_NEW_PASSWORD_SUCCESS,
  CONFIRM_SMS_FAIL,
  CONFIRM_SMS_REQUEST,
  CONFIRM_SMS_SUCCESS,
  CONFIRM_SOCIAL_PHONE_FAIL,
  CONFIRM_SOCIAL_PHONE_REQUEST,
  CONFIRM_SOCIAL_PHONE_SUCCESS,
  CONTINUE_TO_PHONE_NUMBER,
  CONTINUE_TO_REFERRAL_REQUEST,
  CONTINUE_TO_TERMS_CONDITION,
  CREATE_USER_PHONE_FAIL,
  CREATE_USER_PHONE_REQUEST,
  CREATE_USER_PHONE_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_PROFILE_SUCCESS,
  FORGOTTEN_ID_FAIL,
  FORGOTTEN_ID_REQUEST,
  FORGOTTEN_ID_SUCCESS,
  FORGOTTEN_PASSWORD_FAIL,
  FORGOTTEN_PASSWORD_REQUEST,
  FORGOTTEN_PASSWORD_SMS_FAIL,
  FORGOTTEN_PASSWORD_SMS_REQUEST,
  FORGOTTEN_PASSWORD_SMS_SUCCESS,
  FORGOTTEN_PASSWORD_SUCCESS,
  LOGIN_APPLE_FAIL,
  LOGIN_APPLE_REQUEST,
  LOGIN_APPLE_SUCCESS,
  LOGIN_FACEBOOK_FAIL,
  LOGIN_FACEBOOK_REQUEST,
  LOGIN_FACEBOOK_SUCCESS,
  LOGIN_GOOGLE_FAIL,
  LOGIN_GOOGLE_REQUEST,
  LOGIN_GOOGLE_SUCCESS,
  LOGIN_LINKEDIN_FAIL,
  LOGIN_LINKEDIN_REQUEST,
  LOGIN_LINKEDIN_SUCCESS,
  LOGOUT,
  RESEND_EMAIL_FAIL,
  RESEND_EMAIL_REQUEST,
  RESEND_EMAIL_SUCCESS,
  RESEND_PHONE_FAIL,
  RESEND_PHONE_SUCCESS,
  RESEND_SMS_FAIL,
  RESEND_SMS_REQUEST,
  RESEND_SMS_SUCCESS,
  RESEND_SOCIAL_PHONE_FAIL,
  RESEND_SOCIAL_PHONE_REQUEST,
  RESEND_SOCIAL_PHONE_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  SEND_PASSWORD_FAIL,
  SEND_PASSWORD_REQUEST,
  SEND_PASSWORD_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SOCIAL_REFERRAL_FAIL,
  SOCIAL_REFERRAL_REQUEST,
  SOCIAL_REFERRAL_SUCCESS,
  SOCIAL_REGISTER_FAIL,
  SOCIAL_REGISTER_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_IMAGE_FAIL,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  SET_REFFERAL_ID,
  SIGN_UP_CUSTOMER_REQUEST,
  SIGN_UP_CUSTOMER_SUCCESS,
  SIGN_UP_CUSTOMER_FAIL,
  SET_TOOGLE_MODAL,
  SET_TOOGLE_SIDE_BAR,
  SET_TOOGLE_MODAL_MOBILE,
  SET_SIDEBAR_MOBILE,
  RESEND_PHONE_REQUEST,
  SET_APPROVAL_DATE,
  UPDATE_PROFILE_REQUEST,
  ADD_SOCIAL_TERMS_REQUEST,
  ADD_SOCIAL_TERMS_SUCCESS,
  ADD_SOCIAL_TERMS_FAIL,
  UPDATE_SOCIAL_PROFILE_REQUEST,
  UPDATE_SOCIAL_PROFILE_SUCCESS,
  UPDATE_SOCIAL_PROFILE_FAIL,
  CONTINUE_TO_AFFILIATE_TERMS,
  RESET_PASSWORD_REQUEST,
  SIGN_IN_FAIL_USER,
  SIGN_IN_COMPLETE_REQUEST,
  SIGN_IN_COMPLETE_SUCCESS,
  SIGN_IN_COMPLETE_FAIL,
  UPDATE_USER_EMAIL,
  FIRST_LOGIN_REQUEST,
  FIRST_LOGIN_FAIL,
  FIRST_LOGIN_SUCCESS
} from '../constants/authConstants';

import {
  APPROVAL_KYC_TERMS_REQUEST,
  APPROVAL_KYC_TERMS_SUCCESS,
  APPROVAL_KYC_TERMS_FAIL
} from '../constants/documentConstants';

import { UserCreationTypesEnum } from '../constants/commonConstants';
import { CLEAR_ALERT, CLEAR_MESSAGE } from '../constants/errorConstants';
import { localStorageGetter, localStorageSetter } from '../lib/utils';
import { setUserPrizerToLS } from '../helpers/localstorage-helper';
import { ErrorToast, SuccessToast } from '../components/common/Toasts/Toasts';
import {
  DASHBOARD,
  FIRST_LOGIN,
  SETTINGS_VIRTUAL_OFFICE
} from '../routes/routes';

const API_URL = process.env.REACT_APP_API_URL;

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_DETAILS_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    const { data } = await axios.get(`${API_URL}/user/info`, config);

    dispatch({
      type: GET_USER_DETAILS_SUCCESS,
      payload: {
        user: data.data
      }
    });

    const userFormLs = localStorageGetter('userPrizer');

    const parsedUser = JSON.parse(userFormLs);

    const newUser = {
      ...parsedUser,
      user: {
        ...data.data.user
      }
    };

    setUserPrizerToLS(JSON.stringify(newUser));
  } catch (error) {
    dispatch({
      type: GET_USER_DETAILS_FAIL,
      payload: error.response && error.response.data
    });
  }
};

export const loginViaFacebook =
  (accessToken, referrerId, history, toggleModal) => async (dispatch) => {
    try {
      dispatch({ type: LOGIN_FACEBOOK_REQUEST });
      let { data } = await axios.post(
        `${API_URL}/authorization/signup/facebook`,
        {
          accessToken
        }
      );

      // if (referrerId !== null && !isNaN(referrerId)) {
      //   data = await sendReferredId(
      //     referrerId,
      //     accessToken,
      //     UserCreationTypesEnum.facebook
      //   );
      // }

      if (data.data.user.registrationStatus === 9) {
        dispatch({
          type: LOGIN_FACEBOOK_SUCCESS,
          payload: data
        });

        //dispatch(setRefferalId(data.data.user.referrerId));

        if (history.location.pathname !== '/login') {
          toggleModal();
        } else {
          history.push(DASHBOARD);
        }

        setUserPrizerToLS(JSON.stringify(data.data));
      } else {
        dispatch({
          type: SOCIAL_REGISTER_SUCCESS,
          payload: {
            token: accessToken,
            user: data.data.user,
            path: '/social'
          }
        });
        if (referrerId !== null && !isNaN(referrerId)) {
          data = await sendReferredId(
            referrerId,
            accessToken,
            UserCreationTypesEnum.facebook
          );
          history.push({
            pathname: '/profile-details',
            state: {
              from: '/affiliate'
            }
          });
          // history.push('');
        } else {
          history.push({
            pathname: '/referral'
          });
        }

        const user = {
          user: data.data.user,
          token: accessToken,
          path: '/social'
        };

        localStorage.setItem('prizerRegister', JSON.stringify(user));

        // if (data.data.user.registrationStatus === 7) {
        //   history.push("/dashboard");
        //   localStorage.setItem("userPrizer", JSON.stringify(data.data));
        // } else {
        //   history.push({
        //     pathname: "/phone-number",
        //     state: {
        //       from: data.data,
        //       token: accessToken,
        //       path: "/social",
        //     },
        //   });
        //   localStorage.setItem("prizerRegister", JSON.stringify(data.data));
      }
    } catch (error) {
      if (error.response.data.error.user) {
        const user = error.response.data.error;

        dispatch({
          type: SOCIAL_REGISTER_FAIL,
          payload: {
            token: accessToken,
            user,
            path: '/social'
          }
        });

        localStorage.setItem('prizerRegister', JSON.stringify(user));

        if (user.user.registrationStatus === 6) {
          history.push({
            pathname: '/referral',
            state: {
              from: '/'
            }
          });
        } else if (user.user.registrationStatus === 7) {
          history.push({
            pathname: '/phone-number',
            state: {
              from: '/'
            }
          });
        } else {
          history.push({
            pathname: '/phone-code',
            state: {
              from: '/'
            }
          });
        }

        localStorage.setItem(
          'prizerRegister',
          JSON.stringify(error.response.data.error)
        );
      } else if (error.response.data.error) {
        dispatch({
          type: LOGIN_FACEBOOK_FAIL,
          payload: error.response && error.response.data
        });
        setTimeout(() => {
          dispatch({
            type: CLEAR_ALERT,
            payload: ''
          });
        }, 3000);
      }
    }
  };

export const loginViaLinkedIn =
  (token, referrerId, history, redirectUri, toggleModal) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOGIN_LINKEDIN_REQUEST });

      let { data } = await axios.post(
        `${API_URL}/authorization/signup/linkedin`,
        {
          code: token,
          redirectUri: redirectUri
        }
      );
      if (referrerId !== null && !isNaN(referrerId)) {
        data = await sendReferredId(
          referrerId,
          data.data.linkedInToken,
          UserCreationTypesEnum.linkedIn
        );
      }

      if (data.data.user.registrationStatus === 9) {
        dispatch({
          type: LOGIN_LINKEDIN_SUCCESS,
          payload: data
        });

        if (history.location.pathname !== '/login') {
          toggleModal();
        } else {
          history.push(DASHBOARD);
        }

        setUserPrizerToLS(JSON.stringify(data.data));
      } else {
        dispatch({
          type: SOCIAL_REGISTER_SUCCESS,
          payload: {
            token: data.data.linkedInToken,
            user: data.data.user,
            path: '/social'
          }
        });

        if (referrerId !== null && !isNaN(referrerId)) {
          data = await sendReferredId(
            referrerId,
            data.data.linkedInToken,
            UserCreationTypesEnum.linkedIn
          );
          history.push({
            pathname: '/phone-number',
            state: {
              from: '/affiliate'
            }
          });

          // history.push('');
        } else {
          history.push({
            pathname: '/referral'
          });
        }

        const user = {
          user: data.data.user,
          token: data.data.linkedInToken,
          path: '/social'
        };

        localStorage.setItem('prizerRegister', JSON.stringify(user));
      }
    } catch (error) {
      if (error.response.data.error.user) {
        const user = error.response.data.error;

        dispatch({
          type: SOCIAL_REGISTER_FAIL,
          payload: {
            token: error.response.data.error.linkedInToken,
            user,
            path: '/social'
          }
        });

        if (user.user.registrationStatus === 6) {
          history.push({
            pathname: '/referral',
            state: {
              from: '/'
            }
          });
        } else if (user.user.registrationStatus === 7) {
          history.push({
            pathname: '/phone-number',
            state: {
              from: '/'
            }
          });
        } else {
          history.push({
            pathname: '/phone-code',
            state: {
              from: '/'
            }
          });
        }

        const newUser = {
          user,
          token: error.response.data.error.linkedInToken,
          path: '/social'
        };

        localStorage.setItem('prizerRegister', JSON.stringify(newUser));
        // if (user.user.registrationStatus === 6) {
        //   history.push({
        //     pathname: "/phone-number",
        //     state: {
        //       from: error.response.data.error,
        //       token: error.response.data.error.linkedInToken,
        //       path: "/social",
        //     },
        //   });
        // } else if (user.user.registrationStatus === 9) {
        //   history.push({
        //     pathname: "/referral",
        //     state: {
        //       from: error.response.data.error,
        //       token: error.response.data.error.linkedInToken,
        //       path: "/social",
        //     },
        //   });
        // } else {
        //   history.push({
        //     pathname: "/phone-code",
        //     state: {
        //       from: error.response.data.error,
        //       token: error.response.data.error.linkedInToken,
        //       path: "/social",
        //     },
        //   });
        // }

        // localStorage.setItem(
        //   "prizerRegister",
        //   JSON.stringify(error.response.data.error)
        // );
      } else if (error.response.data.error) {
        dispatch({
          type: LOGIN_LINKEDIN_FAIL,
          payload: error.response && error.response.data
        });
        setTimeout(() => {
          dispatch({
            type: CLEAR_ALERT,
            payload: ''
          });
        }, 3000);
      }
    }
  };

export const setApprovalDate = (date) => (dispatch) => {
  dispatch({
    type: SET_APPROVAL_DATE,
    payload: date
  });
};

export const registerWithPhone =
  (phoneNumber, userId, history) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_USER_PHONE_REQUEST });

      const { data } = await axios.put(
        `${API_URL}/authorization/signup/phone`,
        {
          userId,
          phoneNumber
        }
      );

      dispatch({
        type: CREATE_USER_PHONE_SUCCESS,
        payload: {
          user: data.data.user,
          message: data.data.message
        }
      });

      history.push('/phone-code');

      const user = {
        user: data.data.user,
        path: '/',
        referrerId: data.data.user.referrerId
      };

      localStorage.setItem('prizerRegister', JSON.stringify(user));

      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
          payload: {
            message: ''
          }
        });
      }, 3000);
    } catch (error) {
      dispatch({
        type: CREATE_USER_PHONE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: {
            error: ''
          }
        });
      }, 3000);
    }
  };

export const confirmSmsCode = (id, smsCode, history) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIRM_SMS_REQUEST
    });

    const { data } = await axios.put(
      `${API_URL}/authorization/signup/sms/confirm`,
      {
        userId: id,
        code: smsCode
      }
    );

    dispatch({
      type: CONFIRM_SMS_SUCCESS,
      payload: {
        user: data.data.user,
        message: data.message
      }
    });

    history.push('/profile-details');

    const user = {
      user: data.data.user,
      path: '/',
      referrerId: data.data.user.referrerId
    };

    localStorage.setItem('prizerRegister', JSON.stringify(user));

    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
        payload: {
          message: ''
        }
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: CONFIRM_SMS_FAIL,
      payload: error.response && error.response.data
    });

    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: {
          error: ''
        }
      });
    }, 3000);
  }
};

export const resendSms = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESEND_SMS_REQUEST
    });

    const { data } = await axios.put(
      `${API_URL}/authorization/signup/sms/resend`,
      {
        userId: id
      }
    );

    dispatch({
      type: RESEND_SMS_SUCCESS,
      payload: data.message
    });

    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
        payload: {
          message: ''
        }
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: RESEND_SMS_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: {
          error: ''
        }
      });
    }, 3000);
  }
};

export const resendPhone = (id, phoneNumber, history) => async (dispatch) => {
  try {
    dispatch({
      type: RESEND_PHONE_REQUEST
    });

    const { data } = await axios.put(`${API_URL}/authorization/signup/phone`, {
      userId: id,
      phoneNumber: phoneNumber
    });

    dispatch({
      type: RESEND_PHONE_SUCCESS,
      payload: data
    });

    history.push('/phone-code');

    const user = {
      user: data.data.user,
      message: '',
      path: '/',
      referrerId: data.data.user.referrerId
    };

    localStorage.setItem('prizerRegister', JSON.stringify(user));

    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
        payload: {
          message: ''
        }
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: RESEND_PHONE_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: {
          error: ''
        }
      });
    }, 3000);
  }
};

export const addEmailAndVerificationCode =
  (approvalDate, referrerId, email, result, history) => async (dispatch) => {
    try {
      dispatch({
        type: CONFIRM_EMAIL_REQUEST
      });

      const {
        lot_number: lotNumber,
        captcha_output: captchaOutput,
        pass_token: passToken,
        gen_time: genTime
      } = result;

      const { data } = await axios.post(
        `${API_URL}/authorization/signup/email`,
        {
          approvalDate: approvalDate ? approvalDate : moment().format(),
          referrerId,
          email,
          lotNumber,
          captchaOutput,
          passToken,
          genTime
        }
      );

      dispatch({
        type: CONFIRM_EMAIL_SUCCESS,
        payload: data.data.user
      });

      history.push('/password');

      const user = {
        user: data.data.user,
        message: '',
        path: '/',
        referrerId: data.data.user.referrerId
      };

      localStorage.setItem('prizerRegister', JSON.stringify(user));

      SuccessToast('Verification email has been successfully sent');
    } catch (error) {
      dispatch({
        type: CONFIRM_EMAIL_FAIL,
        payload: error.response && error.response.data
      });

      ErrorToast(error?.response?.data.error);
    }
  };

export const reSendEmail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESEND_EMAIL_REQUEST
    });

    await axios.put(`${API_URL}/authorization/signup/email/resend`, {
      userId: id
    });

    dispatch({
      type: RESEND_EMAIL_SUCCESS,
      payload: 'Verification email has been successfully sent' //data.message,
    });

    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
        payload: {
          message: ''
        }
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: RESEND_EMAIL_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: {
          error: ''
        }
      });
    }, 3000);
  }
};

export const confirmEmail = (code) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `${API_URL}/authorization/signup/email/confirm`,
      {
        code
      }
    );

    dispatch({
      type: CONFIRM_EMAIL_LINK_SUCCESS,
      payload: data
    });

    const user = {
      user: data.data.user,
      path: '/',
      referrerId: data.data.user.referrerId
    };

    localStorage.setItem('prizerRegister', JSON.stringify(user));
  } catch (error) {
    if (error.response?.data?.data?.user?.registrationStatus === 4) return;
    dispatch({
      type: CONFIRM_EMAIL_LINK_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: {
          error: ''
        }
      });
    }, 3000);
  }
};

export const changeEmail = (userId, email, onSuccess) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_EMAIL_LINK_REQUEST
    });

    const { data } = await axios.put(
      `${API_URL}/authorization/signup/email/change`,
      {
        userId,
        email
      }
    );

    dispatch({
      type: CHANGE_EMAIL_LINK_SUCCESS,
      payload: {
        user: data.data.user,
        message: data.message
      }
    });

    const user = {
      user: data.data.user,
      path: '/',
      referrerId: data.data.user.referrerId
    };

    localStorage.setItem('prizerRegister', JSON.stringify(user));

    onSuccess();

    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
        payload: {
          error: ''
        }
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: CHANGE_EMAIL_LINK_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: {
          error: ''
        }
      });
    }, 3000);
  }
};

export const sendPassword = (id, password, history) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_PASSWORD_REQUEST
    });

    const { data } = await axios.put(
      `${API_URL}/authorization/signup/password`,
      {
        userId: id,
        password: password
      }
    );

    dispatch({
      type: SEND_PASSWORD_SUCCESS,
      payload: data
    });

    history.push('/profile-details');

    const user = {
      user: data.data.user,
      path: '/',
      referrerId: data.data.user.referrerId
    };

    localStorage.setItem('prizerRegister', JSON.stringify(user));

    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
        payload: {
          message: ''
        }
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: SEND_PASSWORD_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: {
          error: ''
        }
      });
    }, 6000);
  }
};

export const signIn =
  (email, password, result, history) => async (dispatch) => {
    try {
      dispatch({ type: SIGN_IN_REQUEST });

      const {
        lot_number: lotNumber,
        captcha_output: captchaOutput,
        pass_token: passToken,
        gen_time: genTime
      } = result;

      const { data } = await axios.post(`${API_URL}/authorization/signin`, {
        email,
        password,
        lotNumber,
        captchaOutput,
        passToken,
        genTime
      });

      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: data
      });

      const { is2FARequired, isEmailCodeVerificationRequired, signInToken } =
        data.data;

      if (isEmailCodeVerificationRequired || is2FARequired) {
        history.push('/security-code');
      } else {
        dispatch(
          signInComplete(signInToken, null, null, is2FARequired, history)
        );
      }

      localStorage.removeItem('prizerRegister');

      setUserPrizerToLS(JSON.stringify(data.data));
    } catch (error) {
      dispatch({
        type: SIGN_IN_FAIL
      });
      if (error?.response?.data?.data?.user) {
        const user = error.response.data.data?.user;

        if (user?.registrationStatus === 4) {
          history.push('/password');
        } else if (user?.registrationStatus === 3) {
          dispatch({
            type: SIGN_IN_FAIL,
            payload: 'Please confirm verification mail'
          });

          ErrorToast('Please confirm verification mail');

          setTimeout(() => {
            dispatch({
              type: CLEAR_ALERT,
              payload: ''
            });
          }, 3000);
        } else {
          dispatch({
            type: SIGN_IN_FAIL
          });

          history.push('/profile-details');

          const newUser = {
            user,
            path: '/'
          };

          localStorage.setItem('prizerRegister', JSON.stringify(newUser));

          dispatch({
            type: SIGN_IN_FAIL_USER,
            payload: user
          });
        }
      } else if (error?.response?.data?.error) {
        dispatch({
          type: SIGN_IN_FAIL,
          payload: error.response && error.response.data
        });

        ErrorToast(error?.response?.data.error);
      }
    }
  };

export const signInComplete =
  (signInToken, emailCode, twoFAcode, is2FAEnabled, history) =>
  async (dispatch) => {
    try {
      dispatch({ type: SIGN_IN_COMPLETE_REQUEST });

      const { data } = await axios.post(
        `${API_URL}/authorization/signin/complete`,
        {
          signInToken,
          emailCode: emailCode ? emailCode : null,
          twoFAcode: is2FAEnabled ? twoFAcode : null
        }
      );

      dispatch({
        type: SIGN_IN_COMPLETE_SUCCESS,
        payload: data
      });

      if (!data.data.isFirstLoginPopupChecked) {
        history.push(FIRST_LOGIN);
      } else {
        history.push(DASHBOARD);
      }

      setUserPrizerToLS(JSON.stringify(data.data));
    } catch (error) {
      dispatch({
        type: SIGN_IN_COMPLETE_FAIL,
        payload: error.response && error.response.data
      });

      ErrorToast(error?.response?.data.error);
    }
  };

export const forgottenId =
  (type, email, phoneNumber, history) => async (dispatch) => {
    try {
      dispatch({
        type: FORGOTTEN_ID_REQUEST
      });

      const { data } = await axios.post(
        `${API_URL}/authorization/forgotten-id`,
        type === 1
          ? {
              type,
              email
            }
          : {
              type,
              phoneNumber
            }
      );

      const fotgottenIdType = type === 1 ? 'email' : 'phoneNumber';
      localStorageSetter('forgotten_id_type', fotgottenIdType);

      dispatch({
        type: FORGOTTEN_ID_SUCCESS,
        payload: data
      });

      history.push(
        `/forgotten-id-sent/${type === 1 ? 'email' : 'phone-number'}`
      );

      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
          payload: ''
        });
      }, 3000);
    } catch (error) {
      dispatch({
        type: FORGOTTEN_ID_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const forgottenPassword = (id, type, result) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOTTEN_PASSWORD_REQUEST
    });

    const {
      lot_number: lotNumber,
      captcha_output: captchaOutput,
      pass_token: passToken,
      gen_time: genTime
    } = result;

    const { data } = await axios.post(
      `${API_URL}/authorization/forgotten-password/send`,
      type === 1
        ? {
            email: id,
            lotNumber,
            captchaOutput,
            passToken,
            genTime
          }
        : type === 2
        ? {
            userId: parseInt(id),
            lotNumber,
            captchaOutput,
            passToken,
            genTime
          }
        : {
            username: id,
            lotNumber,
            captchaOutput,
            passToken,
            genTime
          }
    );

    dispatch({
      type: FORGOTTEN_PASSWORD_SUCCESS,
      payload: data
    });

    SuccessToast(data.message);
  } catch (error) {
    dispatch({
      type: FORGOTTEN_PASSWORD_FAIL,
      payload: error.response && error.response.data
    });

    ErrorToast(error.response.data.error);
  }
};

export const forgottenSMSPassword =
  (code, userId, history) => async (dispatch) => {
    try {
      dispatch({
        type: FORGOTTEN_PASSWORD_SMS_REQUEST
      });

      await axios.put(
        `${API_URL}/authorization/forgotten-password/sms/confirm`,
        {
          userId: parseInt(userId),
          code: parseInt(code)
        }
      );

      dispatch({
        type: FORGOTTEN_PASSWORD_SMS_SUCCESS,
        payload: parseInt(code)
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
          payload: ''
        });
      }, 3000);

      history.push('/forgotten-password-new');
    } catch (error) {
      dispatch({
        type: FORGOTTEN_PASSWORD_SMS_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const confirmNewPassword = (code) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `${API_URL}/authorization/forgotten-password/email/confirm`,
      {
        code: code
      }
    );

    dispatch({
      type: CONFIRM_NEW_PASSWORD_SUCCESS,
      payload: data
    });

    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
        payload: ''
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: CONFIRM_NEW_PASSWORD_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: ''
      });
    }, 3000);
  }
};

export const resetPassword =
  (code, password, result, history) => async (dispatch) => {
    try {
      dispatch({
        type: RESET_PASSWORD_REQUEST
      });

      const {
        lot_number: lotNumber,
        captcha_output: captchaOutput,
        pass_token: passToken,
        gen_time: genTime
      } = result;

      const { data } = await axios.put(
        `${API_URL}/authorization/password/reset`,
        {
          code,
          password,
          lotNumber,
          captchaOutput,
          passToken,
          genTime
        }
      );

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: data
      });

      history.push('/login');

      SuccessToast(data.message);
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: error.response && error.response.data
      });
      ErrorToast(error.response.data.error);
    }
  };

export const updateProfile =
  (
    userId,
    name,
    surname,
    username,
    genderType,
    address,
    zipCode,
    city,
    history
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_PROFILE_REQUEST
      });

      const { data } = await axios.put(
        `${API_URL}/authorization/signup/profile/details`,
        {
          userId,
          name,
          surname,
          username,
          genderType,
          address,
          zipCode,
          country: 'China',
          countryCode: 'CN',
          city
        }
      );

      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: data.data
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
          payload: ''
        });
      }, 3000);

      history.push('/login');

      dispatch(clearRegisterState());

      localStorage.removeItem('approvalDate');
      localStorage.removeItem('prizerRegister');

      setUserPrizerToLS(JSON.stringify(data.data));
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const updateSocialProfile =
  (
    creationType,
    token,
    name,
    surname,
    username,
    genderType,
    address,
    zipCode,
    city,
    history
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_SOCIAL_PROFILE_REQUEST
      });

      const { data } = await axios.put(
        `${API_URL}/authorization/signup/social/profile/details`,
        {
          creationType,
          token,
          name,
          surname,
          username,
          genderType,
          address,
          zipCode,
          country: 'China',
          countryCode: 'CN',
          city
        }
      );

      dispatch({
        type: UPDATE_SOCIAL_PROFILE_SUCCESS,
        payload: data.data
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
          payload: ''
        });
      }, 3000);

      history.push('/login');

      dispatch(clearRegisterState());

      localStorage.removeItem('approvalDate');
      localStorage.removeItem('prizerRegister');

      setUserPrizerToLS(JSON.stringify(data.data));
    } catch (error) {
      dispatch({
        type: UPDATE_SOCIAL_PROFILE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const editProfile =
  (name, surname, dateOfBirth, countryCode, country, history) =>
  async (dispatch, getState) => {
    try {
      const { userInfo } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token.accessToken}`
        }
      };

      const { data } = await axios.put(
        `${API_URL}/user/profile/full`,
        {
          name,
          surname,
          dateOfBirth,
          countryCode,
          country
        },
        config
      );

      dispatch({
        type: EDIT_PROFILE_SUCCESS,
        payload: data.data.user
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
          payload: ''
        });
      }, 3000);

      const user = {
        ...userInfo,
        user: data.data.user
      };

      setUserPrizerToLS(JSON.stringify(user));

      history.push(DASHBOARD);
    } catch (error) {
      dispatch({
        type: EDIT_PROFILE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const uploadProfileImage = (formData) => async (dispatch, getState) => {
  try {
    const { userInfo } = getState();

    const config = {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    const data = await axios.post(
      `${API_URL}/user/profile/image`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_PROFILE_IMAGE_SUCCESS,
      payload: data?.data?.data?.profileImageUrl
    });

    const user = {
      ...userInfo,
      user: {
        ...userInfo.user,
        profileImageUrl: data?.data?.data?.profileImageUrl
      }
    };

    setUserPrizerToLS(JSON.stringify(user));

    setTimeout(() => {
      dispatch({
        type: CLEAR_MESSAGE,
        payload: ''
      });
    }, 3000);
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_IMAGE_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: ''
      });
    }, 3000);
  }
};

export const googleLogin =
  (token, referrerId, result, history) => async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_GOOGLE_REQUEST
      });
      const {
        lot_number: lotNumber,
        captcha_output: captchaOutput,
        pass_token: passToken,
        gen_time: genTime
      } = result;

      let { data } = await axios.post(
        `${API_URL}/authorization/signup/google`,
        {
          idToken: token,
          lotNumber,
          captchaOutput,
          passToken,
          genTime
        }
      );

      if (data.data.user.registrationStatus === 12) {
        dispatch({
          type: LOGIN_GOOGLE_SUCCESS,
          payload: data
        });

        history.push(DASHBOARD);

        setUserPrizerToLS(JSON.stringify(data.data));
      } else if (data?.data?.user?.registrationStatus === 6) {
        if (!referrerId) {
          history.push('/referral');
        } else {
          dispatch(
            socialReffer(
              data?.data?.user?.creationType,
              referrerId,
              token,
              history
            )
          );
        }

        dispatch({
          type: SOCIAL_REGISTER_SUCCESS,
          payload: {
            token,
            user: data.data.user,
            path: '/social',
            referrerId
          }
        });

        const user = {
          user: data.data.user,
          token,
          path: '/social',
          referrerId
        };

        setUserPrizerToLS(JSON.stringify(user));
      } else {
        dispatch({
          type: SOCIAL_REGISTER_SUCCESS,
          payload: {
            token,
            user: data.data.user,
            path: '/social',
            referrerId
          }
        });

        dispatch({
          type: LOGIN_GOOGLE_SUCCESS,
          payload: data
        });

        if (referrerId !== null && !isNaN(referrerId)) {
          data = await sendReferredId(
            referrerId,
            token,
            UserCreationTypesEnum.google
          );
          history.push({
            pathname: '/profile-details',
            state: {
              from: '/affiliate'
            }
          });
        } else {
          history.push({
            pathname: '/referral'
          });
        }

        const user = {
          user: data.data.user,
          token,
          path: '/social'
        };

        localStorage.setItem('prizerRegister', JSON.stringify(user));
      }
    } catch (error) {
      if (error?.response?.data?.error?.user) {
        const user = error?.response?.data?.error;

        dispatch({
          type: SOCIAL_REGISTER_FAIL,
          payload: {
            token,
            user,
            path: '/social'
          }
        });

        const newUser = {
          token,
          user,
          path: '/social'
        };

        localStorage.setItem('prizerRegister', JSON.stringify(newUser));

        if (user?.user?.registrationStatus === 6) {
          history.push('/referral');
        } else if (user?.user?.registrationStatus === 7) {
          history.push('/terms-and-conditions');
        } else if (user?.user?.registrationStatus === 13) {
          history.push('/profile-details');
        } else {
          history.push('/profile-details');
        }
      } else if (error?.response?.data?.error) {
        dispatch({
          type: LOGIN_GOOGLE_FAIL,
          payload: error.response && error.response.data
        });
        setTimeout(() => {
          dispatch({
            type: CLEAR_ALERT,
            payload: ''
          });
        }, 3000);
      }
    }
  };
export const appleLogin = (token, referrerId, history) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_APPLE_REQUEST
    });

    let { data } = await axios.post(`${API_URL}/authorization/signup/apple`, {
      idToken: token
    });

    if (data.data.user.registrationStatus === 12) {
      dispatch({
        type: LOGIN_APPLE_SUCCESS,
        payload: data
      });

      history.push(DASHBOARD);

      setUserPrizerToLS(JSON.stringify(data.data));
    } else if (data?.data?.user?.registrationStatus === 6) {
      if (!referrerId) {
        history.push('/referral');
      } else {
        dispatch(
          socialReffer(
            data?.data?.user?.creationType,
            referrerId,
            token,
            history
          )
        );
      }

      dispatch({
        type: SOCIAL_REGISTER_SUCCESS,
        payload: {
          token,
          user: data.data.user,
          path: '/social',
          referrerId
        }
      });

      const user = {
        user: data.data.user,
        token,
        path: '/social',
        referrerId
      };

      setUserPrizerToLS(JSON.stringify(user));
    } else {
      dispatch({
        type: SOCIAL_REGISTER_SUCCESS,
        payload: {
          token,
          user: data.data.user,
          path: '/social',
          referrerId
        }
      });

      dispatch({
        type: LOGIN_APPLE_SUCCESS,
        payload: data
      });

      if (referrerId !== null && !isNaN(referrerId)) {
        data = await sendReferredId(
          referrerId,
          token,
          UserCreationTypesEnum.apple
        );
        history.push({
          pathname: '/profile-details',
          state: {
            from: '/affiliate'
          }
        });
      } else {
        history.push({
          pathname: '/referral'
        });
      }

      const user = {
        user: data.data.user,
        token,
        path: '/social'
      };

      localStorage.setItem('prizerRegister', JSON.stringify(user));
    }
  } catch (error) {
    if (error?.response?.data?.error?.user) {
      const user = error?.response?.data?.error;

      dispatch({
        type: SOCIAL_REGISTER_FAIL,
        payload: {
          token,
          user,
          path: '/social'
        }
      });

      const newUser = {
        token,
        user,
        path: '/social'
      };

      localStorage.setItem('prizerRegister', JSON.stringify(newUser));

      if (user?.user?.registrationStatus === 6) {
        history.push('/referral');
      } else if (user?.user?.registrationStatus === 7) {
        history.push('/terms-and-conditions');
      } else if (user?.user?.registrationStatus === 13) {
        history.push('/profile-details');
      } else {
        history.push('/profile-details');
      }
    } else if (error?.response?.data?.error) {
      dispatch({
        type: LOGIN_APPLE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  }
};

export const continueToReferral = () => async (dispatch) => {
  dispatch({
    type: CONTINUE_TO_REFERRAL_REQUEST,
    payload: {
      path: '/'
    }
  });

  const path = {
    path: '/'
  };

  localStorage.setItem('prizerRegister', JSON.stringify(path));
};

export const continueToAffiliateTerms =
  (path, referrerId, history) => async (dispatch) => {
    dispatch({
      type: CONTINUE_TO_AFFILIATE_TERMS,
      payload: {
        path,
        referrerId: parseInt(referrerId)
      }
    });

    history.push('/terms-and-conditions');

    const newPath = {
      path,
      referrerId
    };

    localStorage.setItem('prizerRegister', JSON.stringify(newPath));
  };

export const socialReffer =
  (creationType, referrerId, token, history) => async (dispatch) => {
    try {
      dispatch({
        type: SOCIAL_REFERRAL_REQUEST
      });

      const { data } = await axios.put(
        `${API_URL}/authorization/signup/social/refferer`,
        {
          creationType,
          referrerId: parseInt(referrerId),
          token
        }
      );

      dispatch({
        type: SOCIAL_REFERRAL_SUCCESS,
        payload: {
          data,
          token,
          referrerId
        }
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
          payload: ''
        });
      }, 3000);

      history.push({
        pathname: '/terms-and-conditions',
        state: {
          from: '/referral'
        }
      });

      const user = {
        user: data.data.user,
        token,
        referrerId,
        path: '/social'
      };

      localStorage.setItem('prizerRegister', JSON.stringify(user));
    } catch (error) {
      dispatch({
        type: SOCIAL_REFERRAL_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: {
            error: ''
          }
        });
      }, 3000);
    }
  };

export const addSocialTerms =
  (creationType, approvalDate, token, history) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_SOCIAL_TERMS_REQUEST
      });

      const { data } = await axios.put(
        `${API_URL}/authorization/signup/social/terms-and-conditions`,
        {
          creationType,
          approvalDate,
          token
        }
      );

      dispatch({
        type: ADD_SOCIAL_TERMS_SUCCESS,
        payload: data.data.user
      });

      history.push('/profile-details');

      const user = {
        user: data.data.user,
        token,
        path: '/social'
      };

      localStorage.setItem('prizerRegister', JSON.stringify(user));
    } catch (error) {
      dispatch({
        type: ADD_SOCIAL_TERMS_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: {
            error: ''
          }
        });
      }, 3000);
    }
  };

export const addSocialPhoneNumber =
  (creationType, phoneNumber, token, history) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_SOCIAL_PHONE_REQUEST
      });

      const { data } = await axios.put(
        `${API_URL}/authorization/signup/social/phone`,
        {
          creationType,
          phoneNumber,
          token
        }
      );

      dispatch({
        type: ADD_SOCIAL_PHONE_SUCCESS,
        payload: {
          user: data.data.user,
          token
        }
      });

      history.push('/phone-code');

      const user = {
        user: data.data.user,
        token,
        path: '/social'
      };

      localStorage.setItem('prizerRegister', JSON.stringify(user));
    } catch (error) {
      dispatch({
        type: ADD_SOCIAL_PHONE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: {
            error: ''
          }
        });
      }, 3000);
    }
  };

export const confirmSocialPhoneNumber =
  (creationType, code, token, history) => async (dispatch) => {
    try {
      dispatch({
        type: CONFIRM_SOCIAL_PHONE_REQUEST
      });

      const { data } = await axios.put(
        `${API_URL}/authorization/signup/social/sms/confirm`,
        {
          creationType,
          code,
          token
        }
      );

      dispatch({
        type: CONFIRM_SOCIAL_PHONE_SUCCESS,
        payload: {
          user: data.data.user,
          token
        }
      });

      history.push('/profile-details');

      const user = {
        user: data.data.user,
        token,
        path: '/social'
      };

      setUserPrizerToLS(JSON.stringify(user));
    } catch (error) {
      dispatch({
        type: CONFIRM_SOCIAL_PHONE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: {
            error: ''
          }
        });
      }, 3000);
    }
  };

export const resendSocialPhoneNumber =
  (creationType, token, history) => async (dispatch) => {
    try {
      dispatch({
        type: RESEND_SOCIAL_PHONE_REQUEST
      });

      const { data } = await axios.put(
        `${API_URL}/authorization/signup/social/sms/resend`,
        {
          creationType,
          token
        }
      );

      dispatch({
        type: RESEND_SOCIAL_PHONE_SUCCESS,
        payload: data.message
      });

      history.push('/phone-code');

      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
          payload: ''
        });
      }, 3000);
    } catch (error) {
      dispatch({
        type: RESEND_SOCIAL_PHONE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: {
            error: ''
          }
        });
      }, 3000);
    }
  };

export const clearRegisterState = () => (dispatch) => {
  dispatch({
    type: CLEAR_REGISTER_STATE
  });
};

export const continueToTermsCondition =
  (referral, history, path) => (dispatch) => {
    dispatch({
      type: CONTINUE_TO_TERMS_CONDITION,
      payload: { referral, path }
    });

    history.push({
      pathname: '/terms-and-conditions',
      state: {
        from: '/referral',
        referrerId: referral
      }
    });

    localStorage.setItem(
      'prizerRegister',
      JSON.stringify({ referrerId: referral, path })
    );
  };

export const continueToPhoneNumber = (referral, history) => (dispatch) => {
  dispatch({
    type: CONTINUE_TO_PHONE_NUMBER,
    payload: referral
  });

  history.push({
    pathname: '/phone-number',
    state: {
      from: '/referral'
    }
  });
};

export const logout = (history, setMenu) => (dispatch) => {
  dispatch({
    type: LOGOUT
  });

  setMenu(false);

  history.push('/login');

  localStorage.removeItem('userPrizer');
  localStorage.removeItem('refferalId');
};

async function sendReferredId(referrerId, token, creationType) {
  let res = await axios.put(`${API_URL}/authorization/signup/social/refferer`, {
    creationType,
    referrerId,
    token
  });
  return res.data;
}

export const setRefferalId = (id) => (dispatch) => {
  dispatch({
    type: SET_REFFERAL_ID,
    payload: id
  });

  localStorage.setItem('refferalId', JSON.stringify(id));
};

export const signUpCustomer =
  (email, setEmail, referrerId) => async (dispatch) => {
    try {
      dispatch({
        type: SIGN_UP_CUSTOMER_REQUEST
      });

      const { data } = await axios.post(
        `${API_URL}/authorization/signup/customer/email`,
        {
          email,
          referrerId
        }
      );

      dispatch({
        type: SIGN_UP_CUSTOMER_SUCCESS,
        payload: {
          user: data.data.user,
          message:
            'Thank you! Your subscription has been confirmed. You’ve been added to our list. Go to your email and claim your free donation entries.',
          data: data
        }
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_MESSAGE,
          payload: {
            message: ''
          }
        });
      }, 10000);

      setEmail('');
    } catch (error) {
      dispatch({
        type: SIGN_UP_CUSTOMER_FAIL,
        payload: error.response && error.response.data
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: {
            error: ''
          }
        });
      }, 10000);
    }
  };

export const approvalKycTerms =
  (approvalDate, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: APPROVAL_KYC_TERMS_REQUEST
      });

      const { userInfo } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token.accessToken}`
        }
      };

      const { data } = await axios.post(
        `${API_URL}/user/kyc/terms-and-conditions`,
        {
          approvalDate
        },
        config
      );

      dispatch({
        type: APPROVAL_KYC_TERMS_SUCCESS,
        payload: data.data
      });

      history.push('/birth-details');
    } catch (error) {
      dispatch({
        type: APPROVAL_KYC_TERMS_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const setToggleModal = (isOpen) => (dispatch) => {
  dispatch({
    type: SET_TOOGLE_MODAL,
    payload: isOpen
  });
};

export const setToggleModalMobile = (isOpen) => (dispatch) => {
  dispatch({
    type: SET_TOOGLE_MODAL_MOBILE,
    payload: isOpen
  });
};

export const setToggleSideBar = (isOpen) => (dispatch) => {
  dispatch({
    type: SET_TOOGLE_SIDE_BAR,
    payload: isOpen
  });
};

export const setSidebarMobile = (isOpen) => (dispatch) => {
  dispatch({
    type: SET_SIDEBAR_MOBILE,
    payload: isOpen
  });
};

export const updateUserEmail = (email) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_EMAIL,
    payload: email
  });
};

export const firstLogin = (history) => async (dispatch) => {
  try {
    dispatch({
      type: FIRST_LOGIN_REQUEST
    });

    await axios.put(`${API_URL}/user/set-first-login-popup-checked`);

    dispatch({
      type: FIRST_LOGIN_SUCCESS
    });
    history.push(SETTINGS_VIRTUAL_OFFICE);
  } catch (error) {
    dispatch({
      type: FIRST_LOGIN_FAIL,
      payload: error.response && error.response.data
    });
  }
};
