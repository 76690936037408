import React from 'react';
import styled from 'styled-components';
import { H3, P1 } from '../../styles/FontStyles';

const PortfolioItemDisabledStyled = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.neutral0}cc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 24px;
  text-align: center;
`;

export const PortfolioItemDisabled = () => {
  return (
    <PortfolioItemDisabledStyled>
      <H3>Withdrawal or Auto-stake change in progress...</H3>
      <P1>It may take up to 24 Hours for Staking Hub NFT to Refresh.</P1>
    </PortfolioItemDisabledStyled>
  );
};
