import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputBackOffice } from '../../../components/common/InputBackoffice';
import { ErrorToast } from '../../../components/common/Toasts/Toasts';
import { FlexCenter } from '../../../styles/stylesStyled';
import { Title } from './WithdrawStyled';

import { useTranslation } from 'react-i18next';
import { P3 } from '../../../styles/FontStyles';
import { Button } from '../../../styles/GlobalComponents';
import { editUserWallet } from './Withdraw.actions';

export const EditBankAccount = ({ account, setIsOpen4 }) => {
  const { t } = useTranslation('earnings');
  const dispatch = useDispatch();

  const [label, setLabel] = useState('');

  useEffect(() => {
    if (account && account.label) {
      setLabel(account.label);
    }
  }, [account]);

  return (
    <div style={{ marginTop: !setIsOpen4 && '12vh' }}>
      <Title>{t('withdraw_part.edit_bank_account.title')}</Title>

      <P3 mb='8' bold>
        {t('withdraw_part.add_bank_account.walletLabel')}
      </P3>

      <InputBackOffice
        placeholder={t('withdraw_part.add_bank_account.placeholderLabel')}
        style={{ marginBottom: 16 }}
        value={label}
        onChange={(e) => setLabel(e)}
      />

      <FlexCenter style={{ flexDirection: 'column' }}>
        <Button
          onClick={() => {
            if (!label) {
              ErrorToast(
                t('withdraw_part.add_bank_account.err_label_required')
              );
            } else {
              dispatch(editUserWallet(account?.id, label, setIsOpen4));
            }
          }}
          mt='30'
          maxWidth='200px'>
          {t('withdraw_part.edit_bank_account.edit')}
        </Button>
      </FlexCenter>
    </div>
  );
};
