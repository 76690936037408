import React from 'react';
import { Layout, Main } from './authLayoutStyled';
import { AuthLogo } from '../styles/stylesStyled';
import { globalImages } from '../styles/GlobalStyles';

const AuthLayout = ({ children }) => {
  return (
    <Layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2
      }}>
      <Main>
        <AuthLogo>
          <img src={globalImages.authLayoutLogo} alt='logo' />
        </AuthLogo>
        {children}
      </Main>
    </Layout>
  );
};

export default AuthLayout;
