import React from 'react';
import { Link } from 'react-router-dom';
import { Footer, FootrMobile } from './BackofficeLayoutStyled';

import { useTranslation } from 'react-i18next';
import { Desktop, Mobile } from '../../App';
import { PRIVACY_POLICY, TERMS_OF_USE } from '../../routes/routes';
import { FooterText } from '../../styles/FontStyles';
import { Flexed } from '../../styles/GlobalComponents';
import { color, globalImages } from '../../styles/GlobalStyles';
import { FlexEnd } from '../../styles/stylesStyled';

const FooterDesktop = () => {
  const { t } = useTranslation('layout');
  return (
    <Footer>
      <FlexEnd style={{ width: '100%', maxWidth: 1500, padding: '0 100px' }}>
        <Flexed gap='20px' center>
          <img src={globalImages.appLogo} alt='logo' style={{ height: 25 }} />
          <div>
            <Flexed gap='10px' wrap='true'>
              <Link to={TERMS_OF_USE}>
                <FooterText color={color.colors.neutral500} mb='3'>
                  {t('footer.terms_of_use')}
                </FooterText>
              </Link>
              <Link to={PRIVACY_POLICY}>
                <FooterText color={color.colors.neutral500} mb='3'>
                  {t('footer.privacy_policy')}
                </FooterText>
              </Link>
            </Flexed>
            <FooterText color={color.colors.neutral500} mb='3'>
              {t('footer.copyright')}
            </FooterText>
          </div>
        </Flexed>
      </FlexEnd>
    </Footer>
  );
};

const FooterMobile = () => {
  const { t } = useTranslation('layout');
  return (
    <FootrMobile>
      <Flexed gap='20px'>
        <img src={globalImages.appLogo} alt='logo' />
        <div>
          <Flexed gap='10px' wrap='true'>
            <Link to={TERMS_OF_USE}>
              <FooterText color={color.colors.neutral500} mb='3'>
                {t('footer.terms_of_use')}
              </FooterText>
            </Link>
            <Link to={PRIVACY_POLICY}>
              <FooterText color={color.colors.neutral500} mb='3'>
                {t('footer.privacy_policy')}
              </FooterText>
            </Link>
          </Flexed>
          <FooterText color={color.colors.neutral500} mb='3'>
            {t('footer.copyright')}
          </FooterText>
        </div>
      </Flexed>
    </FootrMobile>
  );
};

export const FooterComponent = () => {
  return (
    <>
      <Desktop>
        <FooterDesktop />
      </Desktop>
      <Mobile>
        <FooterMobile />
      </Mobile>
    </>
  );
};
