import styled from 'styled-components';

const ReferralComp = styled.div`
  @media (max-width: 800px) {
    margin-top: 20vh;
  }
`;

const Scan = styled.p`
  color: #ee1a80;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

const Continue = styled.div`
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
  color: #fa8d11;
  font-weight: 500;
  cursor: pointer;
`;

const QR = styled.div`
  display: flex;
  opacity: ${(props) => (props.qrbox ? '1' : '0')};
  transition: all 0.3s ease;
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: ${(props) => (props.qrbox ? '0' : '-110%')};
  left: 0;
  z-index: 101;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2700px;
  > * {
    width: 311px;
  }
`;

const X = styled.div`
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

export { ReferralComp, Scan, Continue, QR, X };
