import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUSD, USDT } from '../../../helpers/helperFunctions';
import { FlexBetween } from '../../../styles/stylesStyled';
import { EarningDetailsComponent, Txt4 } from './EarningsHistoryStyled';

const getStatusName = (status) => {
  switch (status) {
    case 1:
      return 'Pending';
    case 2:
      return 'Paid';
    default:
      return '';
  }
};

export const EarningDetails = () => {
  const { t } = useTranslation('earnings');

  const [details] = useState();

  return (
    <EarningDetailsComponent>
      <FlexBetween>
        <Txt4>{t('status')}</Txt4>{' '}
        <Txt4 weight>{getStatusName(details?.status)}</Txt4>
      </FlexBetween>

      {details?.orderId && (
        <FlexBetween>
          <Txt4>{t('order_id')}</Txt4> <Txt4 weight>{details?.orderId}</Txt4>
        </FlexBetween>
      )}

      {details?.orderDateCreated ? (
        <FlexBetween>
          <Txt4>{t('order_date')}</Txt4>{' '}
          <Txt4 weight>
            {' '}
            {moment(details?.orderDateCreated).format('DD.MM.YYYY')}
          </Txt4>
        </FlexBetween>
      ) : (
        <FlexBetween>
          <Txt4>Bonus date </Txt4>{' '}
          <Txt4 weight>
            {' '}
            {moment(details?.bonusDateCreated).format('DD.MM.YYYY')}
          </Txt4>
        </FlexBetween>
      )}

      {details?.user && (
        <FlexBetween>
          <Txt4>{t('user_id')}</Txt4>{' '}
          <Txt4 weight>
            {details?.user &&
              `${details?.user?.name} ${
                details?.user?.surname?.length > 0 &&
                details?.user?.surname[0]?.toUpperCase()
              }. (#${details?.user?.id})`}
          </Txt4>
        </FlexBetween>
      )}
      {details?.orderAmount && (
        <FlexBetween>
          <Txt4>{t('order_volume')}</Txt4>{' '}
          <Txt4 weight>
            {formatUSD(details?.orderAmount)} {USDT}
          </Txt4>
        </FlexBetween>
      )}

      <FlexBetween>
        <Txt4>{t('reward_type')}</Txt4> <Txt4 weight>{details?.name}</Txt4>
      </FlexBetween>

      <FlexBetween>
        <Txt4>{t('reward_amount')}</Txt4>{' '}
        <Txt4 weight>
          {formatUSD(details?.amount)} {USDT}
        </Txt4>
      </FlexBetween>
      {details?.datePaid && (
        <FlexBetween>
          <Txt4>{t('payment_date')}</Txt4>{' '}
          <Txt4 weight> {moment(details?.datePaid).format('DD.MM.YYYY')}</Txt4>
        </FlexBetween>
      )}
    </EarningDetailsComponent>
  );
};
