import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWallets } from '../../../actions/walletActions';
import { AddBankAccount } from './AddBankAccount';
import { ChooseBankAccount } from './ChooseBankAccount';
import { WithdrawFinalCheck } from './WithdrawFinalCheck';
import { WithdrawInitial } from './WithdrawInitial';
import { WithdrawSuccess } from './WithdrawSuccess';
import { TwoFASetup } from './TwoFASetup';

import { Withdraw2FARequired } from './Withdraw2FARequired';
import { WithdrawAvailability } from './WithdrawAvailability';
import { wallets } from '../../../helpers/helperFunctions';

export const Withdraw = ({ step, setStep, withdraw, setWithdraw }) => {
  const [amount, setAmount] = useState('');
  const fee = 5;
  const [selectWallet, setSelectedWallet] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState(wallets[0]);

  const [selected, setSelected] = useState(0);
  const [setIsOpen2] = useState(0);

  const dispatch = useDispatch();

  const { userWallets } = useSelector((state) => state.withdraw);
  const { loading } = useSelector((state) => state.userWallets);

  const userDetails = useSelector((state) => state.userDetails);
  const { info } = useSelector((state) => state.earnings);

  useEffect(() => {
    if (withdraw) {
      dispatch(getWallets());
    }
  }, [dispatch]);

  return (
    <>
      {step === 1 && (
        <WithdrawInitial
          setStep={setStep}
          userWallets={userWallets}
          selected={selected}
          amount={amount}
          setAmount={setAmount}
          userDetails={userDetails}
          info={info}
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
          loading={loading}
        />
      )}
      {step === 2 && (
        <WithdrawFinalCheck
          setStep={setStep}
          amount={amount}
          setAmount={setAmount}
          selected={selected}
          userWallets={userWallets}
          userDetails={userDetails}
          fee={fee}
          selectedNetwork={selectedNetwork}
        />
      )}
      {step === 3 && (
        <WithdrawSuccess
          setStep={setStep}
          withdraw={withdraw}
          setWithdraw={setWithdraw}
        />
      )}
      {step === 4 && (
        <ChooseBankAccount
          setStep={setStep}
          selected={selected}
          setSelected={setSelected}
          userWallets={userWallets}
          setSelectedWallet={setSelectedWallet}
          selectWallet={selectWallet}
        />
      )}

      {step === 5 && (
        <AddBankAccount
          setStep={setStep}
          setIsOpen2={setIsOpen2}
          amount={amount}
        />
      )}
      {step === 6 && (
        <TwoFASetup
          setStep={setStep}
          amount={amount}
          setAmount={setAmount}
          selected={selected}
          userWallets={userWallets}
          fee={fee}
          networkType={selectedNetwork.networkType}
        />
      )}
      {/* {step === 7 && (
        <VerificationEmail
          setStep={setStep}
          amount={amount}
          setAmount={setAmount}
          selected={selected}
          userWallets={userWallets}
          networkType={selectedNetwork.networkType}
        />
      )} */}

      {step === 8 && (
        <Withdraw2FARequired
          setStep={setStep}
          amount={amount}
          setAmount={setAmount}
          selected={selected}
          userWallets={userWallets}
          fee={fee}
        />
      )}

      {step === 9 && <WithdrawAvailability />}
    </>
  );
};
