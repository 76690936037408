import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import { FlexBetween } from '../../styles/stylesStyled';
import { Box, NotificationSubtitle, NotificationTitle } from './SettingsStyled';

import { color } from '../../styles/GlobalStyles';
import { setNotification } from './settings.actions';

export const NotificationsSettings = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.settings);

  return (
    <>
      <Box>
        {notifications?.map((item, index) => {
          return (
            <FlexBetween style={{ marginBottom: 32 }} key={index}>
              <div>
                <NotificationTitle>{item.title}</NotificationTitle>
                <NotificationSubtitle>{item.subtitle}</NotificationSubtitle>
              </div>
              <Switch
                onChange={() => dispatch(setNotification(item, notifications))}
                checked={item.checked}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={color.colors.secondary100}
              />
            </FlexBetween>
          );
        })}
      </Box>
    </>
  );
};
