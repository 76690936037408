import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import binance from '../../assets/icons/backOffice/Packages/binance.svg';
import trc from '../../assets/icons/backOffice/Packages/trc.svg';
import Alert from '../../components/common/Alert';
import Spinner from '../../components/common/Spinner';
import { formatUSD, USDT } from '../../helpers/helperFunctions';
import { H3, P3 } from '../../styles/FontStyles';
import {
  ArrowPolygon,
  Button,
  SectionSeparator
} from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  Icon,
  IconSettingsCheckboxOff,
  IconSettingsCheckboxOn
} from '../../styles/Icons';
import { FlexBetween, FlexCenter } from '../../styles/stylesStyled';
import { addPackage } from './packages.actions';
import {
  AddressCheckbox,
  CollapsableAbsolute,
  Select,
  SelectDropDown,
  SelectedUSDT
} from './PackagesStyled';

import { useTranslation } from 'react-i18next';

const PaymentMethod = ({
  selectedPackage,
  setStep,
  selectedNetwork,
  setSelectedNetwork
}) => {
  const { t } = useTranslation('packages');
  const wallets = [
    {
      networkType: 1,
      icon: binance,
      wallet: 'Binance Smart Chain (BEP-20)',
      index: 0
    },
    { networkType: 2, icon: trc, wallet: 'Tron Network (TRC-20)', index: 1 }
  ];
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [error] = useState('');
  const [checkbox, setCheckbox] = useState(false);

  const dispatch = useDispatch();

  const { loadingPackages, error: errorMsg } = useSelector(
    (state) => state.packagesList
  );

  return (
    <>
      {loadingPackages ? (
        <Spinner dark />
      ) : (
        <div>
          {error && <Alert message={error} />}
          {errorMsg && <Alert message={errorMsg.error} />}

          <div>
            <P3 mb='8'> {t('payment_method.small_text1')}</P3>

            <H3 mb='5' color={color.colors.secondary100}>
              {selectedPackage?.imageTitle}
            </H3>

            <P3 mb='8'>{t('payment_method.small_text2')}</P3>

            <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
              {t('payment_method.payment_ammount')}
            </P3>

            <SelectedUSDT>
              {formatUSD(selectedPackage?.imagePrice)} <span>{USDT}</span>
            </SelectedUSDT>

            <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
              {t('payment_method.network')}
            </P3>

            <Select isOpen={isOpenDropdown}>
              <div
                onClick={() => setIsOpenDropdown(!isOpenDropdown)}
                style={{ cursor: 'pointer' }}>
                <span>
                  {selectedNetwork ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}>
                      <img
                        src={selectedNetwork.icon}
                        alt='checked'
                        style={{ marginRight: 10 }}
                      />

                      <span>{selectedNetwork.wallet}</span>
                    </div>
                  ) : (
                    <div style={{ opacity: 0.3 }}>
                      {t('payment_method.select_network')}
                    </div>
                  )}
                </span>
                <ArrowPolygon
                  direction={isOpenDropdown ? 'down' : 'right'}
                  customColor={color.colors.neutral900}
                />
                <CollapsableAbsolute isOpen={isOpenDropdown}>
                  <SelectDropDown>
                    {wallets.map((wallet, index) => (
                      <div key={index}>
                        <FlexBetween
                          onClick={() => {
                            setSelectedNetwork(wallet);
                            setIsOpenDropdown(!isOpenDropdown);
                          }}
                          key={index}
                          style={{ width: '100%' }}>
                          <FlexCenter>
                            <img
                              src={wallet.icon}
                              alt='checked'
                              style={{ marginRight: 10 }}
                            />
                            <span>{wallet.wallet}</span>
                          </FlexCenter>

                          <Icon color={color.colors.neutral900}>
                            {selectedNetwork.index === index ? (
                              <IconSettingsCheckboxOn />
                            ) : (
                              <IconSettingsCheckboxOff />
                            )}
                          </Icon>
                        </FlexBetween>
                        <SectionSeparator margin='0' />
                      </div>
                    ))}
                  </SelectDropDown>
                </CollapsableAbsolute>
              </div>
            </Select>
            {selectedNetwork && (
              <P3 bold mb='25' color={color.colors.error400}>
                {selectedNetwork.icon === binance ? (
                  <div>
                    <b>{t('payment_method.important')}:</b> <br />
                    {t('payment_method.text1')} <b>BEP-20 </b>
                    {t('payment_method.text2')}{' '}
                    <b>{t('payment_method.text3')}</b>{' '}
                    {t('payment_method.text4')}
                  </div>
                ) : (
                  <div>
                    <b>{t('payment_method.important')}:</b> <br />
                    {t('payment_method.text11')}
                    <b>Tron Network TRC-20 </b>
                    {t('payment_method.text2')}{' '}
                    <b>{t('payment_method.text3')}</b>
                    {t('payment_method.text4')}
                  </div>
                )}
              </P3>
            )}

            {selectedNetwork && (
              <FlexCenter>
                <AddressCheckbox
                  type='checkbox'
                  checked={checkbox}
                  onChange={() => setCheckbox(!checkbox)}
                />
                <P3 color={color.colors.neutral500} checked={checkbox}>
                  {t('payment_method.i_understand')}
                </P3>
              </FlexCenter>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 40
              }}>
              <Button
                maxWidth={'200px'}
                disabled={!selectedNetwork || !checkbox}
                onClick={() => {
                  dispatch(
                    addPackage(
                      selectedPackage?.packageId,
                      1,
                      selectedNetwork.networkType,
                      setStep
                    )
                  );
                }}>
                {t('payment_method.create_order')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentMethod;
