import React, { useRef, useState } from 'react';
import { H3, H4 } from '../../styles/FontStyles';
import {
  ArrowChevron,
  Collapsable,
  Flexed,
  Button
} from '../../styles/GlobalComponents';
import { useTranslation } from 'react-i18next';

import { CaptionText, P1, P3 } from '../../styles/FontStyles';

import { color } from '../../styles/GlobalStyles';
import {
  Icon,
  IconPortfolioOrders,
  IconPortfolioRevenue,
  IconPortfolioDiamond
} from '../../styles/Icons';
import { BoxPortfolio, SummaryGrid } from './Portfolio.styled';

import { formatUSD } from '../../helpers/helperFunctions';
import { PortfolioGamingItem } from './PortfolioGamingItem';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const GamingHubSummary = () => {
  const [expanded, setExpanded] = useState(true);
  const ref = useRef(null);
  const history = useHistory();
  const height = ref?.current?.clientHeight;

  const { t } = useTranslation('my_hubs');

  const { gaming } = useSelector((state) => state.portfolio);

  return (
    <div style={{ marginBottom: 40 }}>
      <Flexed mb='20' center between>
        <H3>{t('my_gaming_hub_nfts_summary')}</H3>
        {/* <Button
          negative
          maxWidth='350px'
          onClick={() => history.push('/backoffice/gaming')}>
          {t('add_ultron_staking_hub_to_portfolio')}
        </Button> */}
      </Flexed>

      <SummaryGrid>
        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioOrders />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('total_gaming_hub_nft_orders')}
            </CaptionText>

            <P1 bold flex alignEnd gap='5px'>
              {formatUSD(gaming?.summary?.totalOrderAmount)}
              <P3 mb='1'>USDT</P3>
            </P1>
          </div>
        </BoxPortfolio>

        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioRevenue />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('revenue_share_points')}
            </CaptionText>
            <P1 bold flex alignEnd gap='5px'>
              {formatUSD(gaming?.summary?.totalRsp)} <P3 mb='1'>RSP</P3>
            </P1>
          </div>
        </BoxPortfolio>

        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioDiamond />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('total_orders_with_autostake_on')}
            </CaptionText>
            <P1 bold flex alignEnd gap='5px'>
              {formatUSD(gaming?.summary?.totalOrderAmount)}{' '}
              <P3 mb='1'>USDT</P3>{' '}
            </P1>
          </div>
        </BoxPortfolio>
      </SummaryGrid>

      <Flexed center between wrap>
        <Flexed
          mb='20'
          center
          gap='20px'
          pointer
          onClick={() => setExpanded(!expanded)}>
          <H4>{t('my_gaming_hub_nfts')}</H4>
          <ArrowChevron direction={expanded ? 'up' : 'down'} />
        </Flexed>
        <Button
          negative
          maxWidth='350px'
          maxWidthSM='100%'
          onClick={() => history.push('/backoffice/gaming')}>
          {t('add_gaming_hub_nft')}
        </Button>
      </Flexed>

      <Collapsable expanded={expanded} elementHeight={height}>
        <div ref={ref}>
          {gaming?.userPackages?.map((item, i) => (
            <PortfolioGamingItem key={i} item={item} />
          ))}
        </div>
      </Collapsable>
    </div>
  );
};
