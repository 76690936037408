import React from 'react';
import { useSelector } from 'react-redux';
import { BACKOFFICE_NAME } from '../../../helpers/helperFunctions';
import { P2 } from '../../../styles/FontStyles';
import { PaymentSuccess } from '../../../views/SettingsBackoffice/PaymentSuccess';
import { PaymentSuccess2 } from '../../../views/SettingsBackoffice/PaymentSuccess2';
import Spinner from '../Spinner';
import { VirtualOfficeSelectedOrder } from './VirtaulOfficeSelectedOrder';
import { VirtualOfficeSelectedPlan } from './VirtualOfficeSelectedPlan';
import { VirtualOfficeSubscription } from './VirtualOfficeSubscription';

export const SelectedPlan = ({
  selected,
  setSelected,
  step,
  setStep,
  selectedHub,
  setSelectedHub
}) => {
  const { loading } = useSelector((state) => state.settings);
  const { addHubLoading, loading: creditCardLoading } = useSelector(
    (state) => state.hubs
  );
  const showSteps = (step) => {
    switch (step) {
      case 1:
        return (
          <>
            <P2>
              MaVie {BACKOFFICE_NAME} is your entry to limitless possibilities!
              Secure your plan and start earning!
            </P2>
            <VirtualOfficeSubscription
              selected={selected}
              setSelected={setSelected}
              step={step}
              setStep={setStep}
              selectedHub={selectedHub}
              setSelectedHub={setSelectedHub}
            />
          </>
        );

      case 2:
        return (
          <VirtualOfficeSelectedPlan
            step={3}
            setStep={setStep}
            selected={selected}
            selectedHub={selectedHub}
            setSelectedHub={setSelectedHub}
          />
        );

      case 3:
        return (
          <VirtualOfficeSelectedOrder
            step={4}
            setStep={setStep}
            selected={selected}
            selectedHub={selectedHub}
            setSelectedHub={setSelectedHub}
          />
        );

      case 4:
        return <PaymentSuccess selected={selected} selectedHub={selectedHub} />;

      case 5:
        return <PaymentSuccess2 />;

      default:
        return <div></div>;
    }
  };
  return (
    <>
      {loading || addHubLoading || creditCardLoading ? (
        <Spinner />
      ) : (
        <>{showSteps(step)}</>
      )}
    </>
  );
};
