/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { WrapBackOffice } from './EarningsBackofficeStyled';

import { Earnings } from './Earnings/Earnings';
import { EarningsHistory } from './EarningsHistory/EarningsHistory';
import { Desktop, Mobile } from '../../App';
import { useSelector } from 'react-redux';

import { getEarningHistory, init } from './earnignsBackoffice.actions';
import Spinner from '../../components/common/Spinner';

import { AnimatedDashboard } from '../../components/animation/AnimatedDashboard';

import { useCallback } from 'react';

export const EarningsBackoffice = () => {
  const [withdraw, setWithdraw] = useState(false);
  const [mobilePage, setMobilePage] = useState('Earnings');
  const [numberOfPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(50);

  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchId, setSearchId] = useState('');
  const [earningsType, setEarningsType] = useState(null);
  const [onMount, setOnMount] = useState(false);

  const { loading } = useSelector((state) => state.earnings);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(init(numberOfPage, countPerPage));
  }, [dispatch, numberOfPage, countPerPage]);

  const getSortByAndSortOrferFromSort = useCallback(() => {
    switch (sort) {
      case 'dateUp':
        return ['dateCreated', 'ASC'];
      case 'dateDown':
        return ['dateCreated', 'DESC'];
      case 'priceUp':
        return ['amount', 'ASC'];
      case 'priceDown':
        return ['amount', 'DESC'];

      default:
        return ['dateCreated', 'DESC'];
    }
  }, [sort]);

  const getStatusFromFilter = useCallback(() => {
    switch (filter) {
      case 'Paid':
        return 2;
      case 'Pending':
        return 1;
      default:
        return '';
    }
  }, [filter]);

  useEffect(() => {
    if (onMount) {
      const [sortBy, sortOrder] = getSortByAndSortOrferFromSort();
      let dFrom = startDate
        ? `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`
        : '';
      let dTo = endDate
        ? `${moment(endDate).format('YYYY-MM-DD')}T23:59:59.999Z`
        : '';
      if (dFrom === '' || dTo === '') {
        dFrom = '';
        dTo = '';
      }

      dispatch(
        getEarningHistory({
          id: searchId,
          sortBy,
          sortOrder,
          fromDate: dFrom,
          toDate: dTo,
          status: getStatusFromFilter(),
          earningsTypes: earningsType,
          numberOfPage,
          countPerPage
        })
      );
    } else {
      setOnMount(true);
    }
  }, [
    filter,
    sort,
    startDate,
    endDate,
    searchId,
    dispatch,
    getSortByAndSortOrferFromSort,
    getStatusFromFilter,
    earningsType,
    numberOfPage,
    countPerPage
  ]);

  if (loading) return <Spinner fullScreen={true} dark />;

  return (
    <AnimatedDashboard zoom>
      <Desktop style={{ width: '100%' }}>
        <WrapBackOffice>
          <Earnings withdraw={withdraw} setWithdraw={setWithdraw} />
          <EarningsHistory
            setCountPerPage={setCountPerPage}
            numberOfPage={numberOfPage}
            countPerPage={countPerPage}
            setEarningsType={setEarningsType}
            setSearchId={setSearchId}
            setStartDate={setStartDate}
            setSort={setSort}
            setFilter={setFilter}
            setEndDate={setEndDate}
            filter={filter}
            sort={sort}
            startDate={startDate}
            endDate={endDate}
          />
        </WrapBackOffice>
      </Desktop>
      <Mobile>
        {mobilePage === 'Earnings' && (
          <Earnings
            setMobilePage={setMobilePage}
            setWithdraw={setWithdraw}
            withdraw={withdraw}
          />
        )}
        {mobilePage === 'EarningHistory' && (
          <EarningsHistory
            setMobilePage={setMobilePage}
            setCountPerPage={setCountPerPage}
            numberOfPage={numberOfPage}
            countPerPage={countPerPage}
            setEarningsType={setEarningsType}
            setSearchId={setSearchId}
            setStartDate={setStartDate}
            setSort={setSort}
            setFilter={setFilter}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </Mobile>
    </AnimatedDashboard>
  );
};
