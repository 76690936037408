import styled from 'styled-components';
import { colors } from '../styles/GlobalStyles';
import { motion } from 'framer-motion';

export const Kyc = styled(motion.div)``;

const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.kycLayoutBackground};
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const MobileLayout = styled.div`
  display: none;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.kycLayoutBackground};
  @media (max-width: 1000px) {
    display: block;
  }
`;

const Main = styled.div`
  margin: 0 32px;
`;

const MainMobile = styled.div`
  margin: 0 16px;
`;

const Nav = styled.div`
  flex: none;
  background: ${(props) => props.theme.kycNavBackground};
  border: ${(props) => props.theme.kycNavBorder};
  width: 288px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 130px;
  @media (max-width: 1350px) {
    margin-right: 60px;
  }
  @media (max-width: 1200px) {
    margin-right: 20px;
  }
`;

const NavLink = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    props.state === 2
      ? props.theme.kycNavActiveFontColor
      : props.theme.kycNavFontColor};
  margin-bottom: 24px;
  position: relative;
  span {
    position: absolute;
    visibility: ${(props) => (props.state === 2 ? 'visible' : 'hidden')};
    width: 4px;
    height: 4px;
    left: -12px;
    top: 10px;
    background: ${(props) => props.theme.kycNavDotColor};
    border-radius: 50%;
  }

  ${(props) => props.state === 2 && `font-weight: 500;`}
`;

const Links = styled.a`
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 12px;
  display: block;
  color: ${(props) => props.theme.kycNavLinksFontColor} !important;
`;

const NavMobile = styled.div`
  background: ${(props) => props.theme.kycNavBackground};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 180px;
  padding: 16px;
  margin-bottom: 24px;
  h1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
    color: ${(props) => props.theme.colors.primary900};
  }
  h2 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    color: ${colors?.neutral500};
    span {
      color: ${(props) => props.theme.colors.primary900};
      font-weight: 500;
    }
  }
`;

export {
  Layout,
  Main,
  Nav,
  NavLink,
  Links,
  MobileLayout,
  NavMobile,
  MainMobile
};
