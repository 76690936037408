import React from 'react';
// styles
import { AnimatedTabStyled } from './AnimatedDashboardStyled';

export const AnimatedTab = ({ children, index }) => {
  return (
    <AnimatedTabStyled
      initial={{ opacity: 0, marginTop: -40 }}
      animate={{ opacity: 1, marginTop: 0 }}
      exit={{ opacity: 0, marginTop: 40 }}
      transition={{
        duration: 0.2
      }}
      key={index}>
      {children}
    </AnimatedTabStyled>
  );
};
