import {
  SELECTED_ID,
  COLLAPSE_PACKAGE
} from '../../constants/packagesConstants';

import {
  GET_WAITING_PACKAGE,
  ADD_PACKAGE,
  SET_TAB
} from './packages.constants';

import { CLEAR_ALERT } from '../../constants/errorConstants';

export const packagesList = (
  state = { packages: [], isLoading: false, selectedTab: 'hub' },
  action
) => {
  switch (action.type) {
    case SET_TAB:
      return {
        ...state,
        selectedTab: action.payload
      };

    case COLLAPSE_PACKAGE:
      return {
        ...state,
        packages: state.packages.map((item) => {
          if (item.packageId === action.payload) {
            return {
              ...item,
              collapse: !item.collapse
            };
          } else {
            return {
              ...item,
              collapse: false
            };
          }
        })
      };

    case ADD_PACKAGE.REQUEST:
      return {
        ...state,
        loadingPackages: true
      };

    case ADD_PACKAGE.SUCCESS:
      return {
        ...state,
        loadingPackages: false,
        package: action.payload.userPackage,
        userTickets: action.payload.userTickets,
        currencyType: action.payload.currencyType
      };

    case ADD_PACKAGE.FAIL:
      return {
        ...state,
        loadingPackages: false,
        error: action.payload
      };

    case GET_WAITING_PACKAGE.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_WAITING_PACKAGE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        packages: action.payload.newPackages,
        walletAddress: action.payload.walletAddress,
        userPackages: action.payload.userPackages
      };

    case GET_WAITING_PACKAGE.FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export const packageIndex = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_ID:
      return {
        ...state,
        packageIndex: action.payload
      };
    default:
      return state;
  }
};
