import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/common/Spinner';
import { InputBackOffice } from '../../components/common/InputBackoffice';
import { Label, AuthButton } from '../../styles/stylesStyled';
import { ReferralComp } from './ReferralStyled';

import Alert from '../../components/common/Alert';
import {
  socialReffer,
  continueToTermsCondition
} from '../../actions/authActions';

import ReferralModal from './ReferralModal';
import { AuthLayoutTitle } from '../../styles/GlobalComponents';
import { useTranslation } from 'react-i18next';
import { ErrorToast } from '../../components/common/Toasts/Toasts';

const API_URL = process.env.REACT_APP_API_URL;
const captchaId = process.env.REACT_APP_CAPTCHA_ID;

const Referral = ({ history }) => {
  const { t } = useTranslation('onboarding');

  const dispatch = useDispatch();
  const register = useSelector((state) => state.register);
  const [referral, setReferral] = useState('');

  const [refferalModal, setRefferalModal] = useState(false);
  const [captchaLoading, setCaptchaLoading] = useState(false);

  const onHandleSubmit = (e) => {
    e.preventDefault();

    if (!referral) {
      ErrorToast(t('referral.error_referral_empty'));
    } else {
      window.initGeetest4(
        {
          captchaId,
          language: 'eng',
          product: 'bind',
          riskType: 'slide',
          nativeButton: {
            width: '100%',
            marginBottom: '20px'
          }
        },
        function (captcha) {
          setCaptchaLoading(true);
          captcha.showBox();
          captcha
            .onReady(function () {})
            .onSuccess(function () {
              const result = captcha.getValidate();
              getReferalId(referral, result);
            })
            .onError(function () {});
        }
      );
    }
  };

  const getReferalId = async (id, result) => {
    try {
      const {
        lot_number: lotNumber,
        captcha_output: captchaOutput,
        pass_token: passToken,
        gen_time: genTime
      } = result;

      const { data } = await axios.get(
        `${API_URL}/authorization/signup/refferer/${id}/validate`,
        {
          params: {
            lotNumber,
            captchaOutput,
            passToken,
            genTime
          }
        }
      );

      if (data) {
        setReferral(id);

        if (register.path === '/social') {
          dispatch(
            socialReffer(
              register.user.creationType,
              parseInt(referral),
              register.token,
              history
            )
          );
        } else {
          dispatch(continueToTermsCondition(parseInt(referral), history, '/'));
        }
      }
    } catch (error) {
      setCaptchaLoading(false);
      ErrorToast(error.response.data.error);
    }
  };

  return (
    <>
      {captchaLoading && <Spinner fullScreen={true} transparent />}
      {register?.error && <Alert message={register?.error} />}
      <ReferralComp>
        <AuthLayoutTitle>{t('referral.title')}</AuthLayoutTitle>

        <form onSubmit={onHandleSubmit}>
          <Label>{t('referral.label')}</Label>
          <InputBackOffice
            id={'referral'}
            type={'number'}
            placeholder={'Enter Uincubator sponsor ID'}
            name={'referral'}
            value={referral || ''}
            onChange={setReferral}
            label={t('label')}
          />

          <AuthButton
            opacity={referral !== ''}
            disabled={referral === '' ? true : false}>
            {t('referral.button_text')}
          </AuthButton>
        </form>
      </ReferralComp>
      <Modal
        isOpen={refferalModal}
        onRequestClose={() => setRefferalModal(!refferalModal)}
        contentLabel='My dialog'
        className='referralModal'
        overlayClassName='myoverlay'
        closeTimeoutMS={500}
        ariaHideApp={false}>
        <ReferralModal
          refferalModal={refferalModal}
          setRefferalModal={setRefferalModal}
        />
      </Modal>
    </>
  );
};

export default Referral;
