import {
  ADD_HUB,
  GET_HUBS,
  REGISTER_ON_KYC_PLATFORM
} from './Marketplace.constants';

export const hubs = (
  state = { hubs: [], loading: false, addHubLoading: false, userHubs: [] },
  action
) => {
  switch (action.type) {
    case GET_HUBS.REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_HUBS.SUCCESS:
      return {
        ...state,
        loading: false,
        hubs: action.payload.newHubs,
        walletAddress: action.payload.walletAddress,
        userPackages: action.payload.userPackages,
        isFirstGiftAvailable: action.payload.isFirstGiftAvailable
      };

    case GET_HUBS.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_HUB.REQUEST:
      return {
        ...state,
        addHubLoading: true
      };

    case ADD_HUB.SUCCESS:
      return {
        ...state,
        addHubLoading: false,
        userPackage: action.payload.userPackage,
        userTickets: action.payload.userTickets,
        currencyType: action.payload.currencyType,
        networkType: action.payload.networkType
      };

    case ADD_HUB.FAIL:
      return {
        ...state,
        addHubLoading: false,
        error: action.payload
      };

    case REGISTER_ON_KYC_PLATFORM.REQUEST:
      return {
        ...state,
        loading: true
      };

    case REGISTER_ON_KYC_PLATFORM.SUCCESS:
      return {
        ...state,
        loading: false
      };

    case REGISTER_ON_KYC_PLATFORM.FAIL:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
