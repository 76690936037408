export const REFERRAL_LINK_REQUEST = 'REFERRAL_LINK_REQUEST';
export const REFERRAL_LINK_SUCCESS = 'REFERRAL_LINK_SUCCESS';
export const REFERRAL_LINK_FAIL = 'REFERRAL_LINK_FAIL';

export const REFERRAL_CODE_REQUEST = 'REFERRAL_CODE_REQUEST';
export const REFERRAL_CODE_SUCCESS = 'REFERRAL_CODE_SUCCESS';
export const REFERRAL_CODE_FAIL = 'REFERRAL_CODE_FAIL';

export const UPDATE_REFERRAL_LINK_REQUEST = 'UPDATE_REFERRAL_LINK_REQUEST';
export const UPDATE_REFERRAL_LINK_SUCCESS = 'UPDATE_REFERRAL_LINK_SUCCESS';
export const UPDATE_REFERRAL_LINK_FAIL = 'UPDATE_REFERRAL_LINK_FAIL';

export const REFERRAL_LINK = '_REFERRAL_LINK';
