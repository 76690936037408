import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {
  forgottenCredentials,
  register,
  toggleModal,
  userInfo
} from './reducers/authReducers';

import { addressList } from './reducers/addressReducers';

import {
  addTeamMemberDrawer,
  directCustomers,
  indirectCustomers,
  volumeInfo
} from './reducers/myTeamReducers';

import { referralLink } from './reducers/referralLinkReducers';

import { userWallets } from './reducers/userWallets';

import { contentBlock } from './reducers/contentBlockReducers';

import { userDetails } from './reducers/userDetailsReducers';
import { userSettings } from './reducers/userSettingsReducers';

import { dashboard } from './views/Dashboard/Dashboard.reducers';
import { ordersList } from './views/Orders/packages.reducers';

import { myTeamBackoffice } from './views/MyTeamBackoffice/MyTeamBackoffice.reducer';

import { globalPoolBonus } from './views/GlobalPoolBonus/globalPool.reducers';
import { hodlBonus } from './views/HodlBonus/hodlBonus.reducers';
import { topPerformerBonus } from './views/TopPerformerBonus/topPerformer.reducers';

import { earningsReducer as earnings } from './views/EarningsBackoffice/earnignsBackoffice.reducers';
import { withdrawReducer as withdraw } from './views/EarningsBackoffice/Withdraw/Withdraw.reducers';

import { rankingReducer as ranking } from './views/RankingBackoffice/Ranking.reducers';

import { supportCategories } from './views/HelpDeskBackOffice/helpDesk.reducers';

import { transitionReducer as transition } from './reducers/transitionReducer';

import { settings } from './views/SettingsBackoffice/settings.reducers';

import { changeEmail } from './views/ChangeEmail/changeEmail.reducers';

import { packagesList } from './views/Packages/packages.reducers';

import { accounting } from './reducers/accountingReducers';

import { cashbackReducer as cashback } from './reducers/CashbackReducers';
import { portfolio } from './views/Portfolio/Portfolio.reducers';

import { hubs } from './views/Marketplace/Marketplace.reducers';

const reducer = combineReducers({
  hubs,
  register,
  forgottenCredentials,
  userInfo,
  userSettings,
  userWallets,
  packagesList,
  ordersList,
  addressList,
  myTeamBackoffice,
  addTeamMemberDrawer,
  directCustomers,
  indirectCustomers,
  volumeInfo,
  referralLink,
  globalPoolBonus,
  topPerformerBonus,
  hodlBonus,
  supportCategories,
  contentBlock,
  userDetails,
  dashboard,
  toggleModal,
  earnings,
  ranking,
  withdraw,
  transition,
  settings,
  accounting,
  cashback,
  portfolio,
  changeEmail
});

const userPrizer = localStorage.getItem('userPrizer')
  ? JSON.parse(localStorage.getItem('userPrizer'))
  : null;

const prizerRegister = localStorage.getItem('prizerRegister')
  ? JSON.parse(localStorage.getItem('prizerRegister'))
  : null;

const referrerId = localStorage.getItem('refferalId')
  ? JSON.parse(localStorage.getItem('refferalId'))
  : null;

const approvalDate = localStorage.getItem('approvalDate')
  ? JSON.parse(localStorage.getItem('approvalDate'))
  : null;

const initialState = {
  userInfo: {
    ...userPrizer,
    referrerId: parseInt(referrerId)
  },

  register: {
    ...prizerRegister,
    referrerId: parseInt(prizerRegister?.referrerId),
    approvalDate
  }
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
