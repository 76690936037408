import React from 'react';

import icon2 from '../../../assets/images/backoffice/kyc6.png';

import { SidebarSubtitle, SidebarTitle } from '../../../styles/stylesStyled';

import { Button } from '../../../styles/GlobalComponents';
import { useHistory } from 'react-router';

export const Success = () => {
  const history = useHistory();
  return (
    <div style={{ paddingTop: '12vh', textAlign: 'center' }}>
      <img src={icon2} alt='icon' style={{ marginBottom: 40, width: 220 }} />
      <SidebarTitle>Success</SidebarTitle>
      <SidebarSubtitle>
        Your withdrawal request has been successfully submitted and is ready for
        processing.
      </SidebarSubtitle>
      <Button
        onClick={() => history.push('/backoffice/dashboard')}
        maxWidth='200px'>
        Back to dashboard
      </Button>
    </div>
  );
};
