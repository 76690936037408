import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CheckboxButton, PlacementTitle } from './SettingsStyled';

import { useWindowWide } from '../../utils/wideScreen';

import { useTranslation } from 'react-i18next';
import Alert from '../../components/common/Alert';
import Spinner from '../../components/common/Spinner';
import { Button } from '../../styles/GlobalComponents';
import { colors } from '../../styles/GlobalStyles';
import {
  Icon,
  IconSettingsCheckboxOff,
  IconSettingsCheckboxOn
} from '../../styles/Icons';
import { updateTeamSettings } from './settings.actions';

export const TeamSettings = () => {
  const { t } = useTranslation('settings');
  const dispatch = useDispatch();
  const { teamSettings, loading, message } = useSelector(
    (state) => state.settings
  );

  const [selected, setSelected] = useState(teamSettings);

  const wide = useWindowWide(800);

  return (
    <>
      {message && <Alert icon={'success'} message={message} />}
      {loading ? (
        <Spinner dark fullScreen={true} />
      ) : (
        <>
          <Box style={{ width: wide ? 336 : '' }}>
            <PlacementTitle>{t('team.default_placement')}</PlacementTitle>

            <CheckboxButton
              checked={selected === 1}
              onClick={() => setSelected(1)}>
              <div>
                <Icon
                  mr='16'
                  color={selected === 1 ? colors.neutral50 : colors.neutral900}>
                  {selected === 1 ? (
                    <IconSettingsCheckboxOn />
                  ) : (
                    <IconSettingsCheckboxOff />
                  )}
                </Icon>
                {t('team.left_bottom')}
              </div>
            </CheckboxButton>

            <CheckboxButton
              checked={selected === 2}
              onClick={() => setSelected(2)}>
              <div>
                <Icon
                  mr='16'
                  color={selected === 2 ? colors.neutral50 : colors.neutral900}>
                  {selected === 2 ? (
                    <IconSettingsCheckboxOn />
                  ) : (
                    <IconSettingsCheckboxOff />
                  )}
                </Icon>

                {t('team.right_bottom')}
              </div>
            </CheckboxButton>

            <CheckboxButton
              checked={selected === 3}
              onClick={() => setSelected(3)}>
              <div>
                <Icon
                  mr='16'
                  color={selected === 3 ? colors.neutral50 : colors.neutral900}>
                  {selected === 3 ? (
                    <IconSettingsCheckboxOn />
                  ) : (
                    <IconSettingsCheckboxOff />
                  )}
                </Icon>
                {t('team.weaker_team')}
              </div>
            </CheckboxButton>

            <Button
              m='12px 0 0 0'
              onClick={() => dispatch(updateTeamSettings(selected))}>
              {t('team.save_changes')}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
