const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const RANKING = {
  REQUEST: getRequestConst('RANKING'),
  SUCCESS: getSuccessConst('RANKING'),
  FAIL: getFailConst('RANKING')
};

export const CLAIM_PACKAGE_RANK = {
  REQUEST: getRequestConst('CLAIM_PACKAGE_RANK'),
  SUCCESS: getSuccessConst('CLAIM_PACKAGE_RANK'),
  FAIL: getFailConst('CLAIM_PACKAGE_RANK')
};
