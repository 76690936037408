import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatedDashboard } from '../../../components/animation/AnimatedDashboard';
import { AnimatedTab } from '../../../components/animation/AnimatedTab';
import { CheckText } from '../../../components/common/CheckText';
import { Hub } from '../../../components/common/Hub/Hub';
import { SidebarModal } from '../../../components/common/Sidebar/Sidebar';
import Spinner from '../../../components/common/Spinner';
import { H3, P1 } from '../../../styles/FontStyles';
import { Button, Flexed, PageTitle } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { FirstBuyStakingHubFlowSteps } from '../FirstBuyStakingHubFlowSteps/FirstBuyStakingHubFlowSteps';

import { CaptionText, H2 } from '../../../styles/FontStyles';
import { getHubs } from '../Marketplace.actions';
import { StakingHubDetailsStyled } from '../Marketplace.styled';
import { dateDifferenceInDaysFromToday } from '../../../helpers/helperFunctions';

export const Gaming = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('marketplace');

  const { loading, hubs } = useSelector((state) => state.hubs);
  const { user } = useSelector((state) => state.userInfo);

  const [selectedHub, setSelectedHub] = useState(hubs?.[0]?.id);
  const [modal, setModal] = useState(false);
  const [step, setStep] = useState(null);

  const handleSeeDetails = (value) => {
    setSelectedHub(value);
  };

  const handleSelectHub = () => {
    setModal(true);
  };

  const licenceDate = dateDifferenceInDaysFromToday(
    user?.currentVirtualOfficeLicence?.dateEnd
  );

  const handleOnClick = (id) => {
    handleSelectHub(id);
    handleSeeDetails(id);

    if (licenceDate <= 30) {
      setStep(5);
    } else {
      setStep(1);
    }
  };

  useEffect(() => {
    dispatch(getHubs());
  }, [dispatch]);

  if (loading) return <Spinner fullScreen={true} />;

  return (
    <AnimatedDashboard zoom key={'marketplace'}>
      <PageTitle>Gaming Hub NFTs</PageTitle>

      <P1 mb='20'>{t('gaming_subtitle1')}</P1>
      <P1 mb='50'>{t('gaming_subtitle2')}</P1>

      <Flexed rowSM gap={selectedHub !== null ? '100px' : '0'}>
        <Flexed
          column
          width={selectedHub !== null ? '65%' : '100%'}
          widthSM='100%'
          style={{ transition: 'width 300ms ease' }}>
          {hubs?.map((hub) => {
            const {
              id,
              image,
              name,
              price,
              weeklyCap,
              calculator,
              limitedQuantity
            } = hub;
            if (id > 34) {
              return (
                <Hub
                  key={id}
                  image={image}
                  title={name}
                  price={price}
                  onClick={() => handleSeeDetails(id)}
                  selected={selectedHub === id}
                  button={
                    <Button
                      w='150px'
                      wSM='100%'
                      onClick={() => handleOnClick(id)}
                      negative={selectedHub !== id}>
                      {t('select_this_hub')}
                    </Button>
                  }
                  weeklyCap={weeklyCap}
                  limitedQuantity={limitedQuantity}
                  info={
                    <>
                      <Flexed alignEnd end>
                        <H2 bold mr='16' alignEnd>
                          {calculator}
                        </H2>
                        <CaptionText bold right mb='4'>
                          Reward
                          <br /> Multiplicator
                        </CaptionText>
                      </Flexed>
                    </>
                  }
                  stakingHubDetails={
                    <>
                      <StakingHubDetails hub={hub} />
                    </>
                  }
                />
              );
            }
          })}
        </Flexed>
        <Flexed
          desktop
          column
          width={selectedHub !== null ? '35%' : '0'}
          widthSM='100%'
          style={{ transition: 'width 300ms ease' }}>
          <div
            style={{
              position: 'sticky',
              top: 200
            }}>
            <AnimatePresence exitBeforeEnter>
              {selectedHub &&
                hubs?.map((hub, i) => {
                  const { id, title } = hub;
                  if (id === selectedHub) {
                    return (
                      <AnimatedTab key={i}>
                        <H3 mb='4' color={color.colors.primary900}>
                          {title}
                        </H3>
                        <StakingHubDetailsStyled>
                          <StakingHubDetails hub={hub} />
                        </StakingHubDetailsStyled>
                      </AnimatedTab>
                    );
                  } else return null;
                })}
            </AnimatePresence>
          </div>
        </Flexed>
      </Flexed>
      <SidebarModal
        isOpen={modal}
        sidebarWidth={'600px'}
        setOpen={setModal}
        title={
          <>
            {step === 1 && t('select_staking_hub_nft')}
            {step === 2 && t('select_staking_hub_nft')}
            {step === 3 && t('order_confirmation')}
            {step === 5 && t('backoffice_combo')}
            {step === 7 && t('backoffice_combo')}
            {step === 8 && t('backoffice_combo')}
            {step === 9 && t('order_confirmation')}
          </>
        }>
        <AnimatePresence exitBeforeEnter>
          <FirstBuyStakingHubFlowSteps
            selectedHub={selectedHub}
            setSelectedHub={setSelectedHub}
            modal={modal}
            setModal={setModal}
            setStep={setStep}
            step={step}
            user={user}
            licenceDate={licenceDate}
          />
        </AnimatePresence>
      </SidebarModal>
    </AnimatedDashboard>
  );
};

const StakingHubDetails = ({ hub }) => {
  // const { t } = useTranslation('marketplace');

  return (
    <>
      <P1 semiBold color={color.colors.neutral500} mb='12'>
        Gaming Hub NFT details
      </P1>
      {hub?.benefits?.map((benefit, i) => (
        <div key={i}>
          <CheckText text={benefit.text} />
        </div>
      ))}

      <P1 semiBold color={color.colors.neutral500} mb='12'>
        Mavie Bonuses
      </P1>

      {hub?.bonuses?.map((benefit, i) => (
        <div key={i}>
          <CheckText text={benefit.text} />
        </div>
      ))}
    </>
  );
};
