import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  GET_ACCOUNTING_WITHDRAWALS_REQUEST,
  GET_ACCOUNTING_WITHDRAWALS_SUCCESS,
  GET_ACCOUNTING_WITHDRAWALS_FAIL
} from '../constants/accountingConstants';

export const accounting = (
  state = { invoices: [], transactions: [], withdrawals: [] },
  action
) => {
  switch (action.type) {
    //INVOICES
    case GET_INVOICES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.payload
      };

    case GET_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    //TRANSACTIONS

    case GET_TRANSACTIONS_REQUEST:
      return { ...state, loading: true };

    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload
      };

    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    //WITHDRAWALS

    case GET_ACCOUNTING_WITHDRAWALS_REQUEST:
      return { ...state, loading: true };

    case GET_ACCOUNTING_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawals: action.payload
      };

    case GET_ACCOUNTING_WITHDRAWALS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
