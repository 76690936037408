import styled from 'styled-components';

const SocialButtons = styled.div`
  padding: 0;
`;

const Or = styled.div`
  font-size: 20px;
  color: #1c1716;
  text-align: center;
  margin: 0 0 40px 0;
  line-height: 25px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Forgot = styled.p`
  color: #ff894c;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
`;

const DontHaveAcc = styled.div`
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  span {
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary900};
  }
`;

const ForgotText = styled.div`
  margin-right: 8px;
`;

const SocialWrapper = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  height: 50px;
  background: ${(props) => props.color};
  border-radius: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  display: flex;
  p {
    color: #fff;
    margin-left: 15px;
  }
  @media (max-width: 260px) {
    font-size: 12px;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export {
  SocialButtons,
  Or,
  Forgot,
  ForgotText,
  DontHaveAcc,
  SocialWrapper,
  ButtonContent
};
