import Cookies from 'js-cookie';

export const store = (key, value) => {
  return localStorage.setItem(key, value);
};

export const read = (key) => {
  return localStorage.getItem(key);
};

export const getTokens = () => {
  return JSON.parse(read('userPrizer')).token;
};

export const getAccessToken = () => {
  return JSON.parse(read('userPrizer'))?.token?.accessToken;
};

export const getRefreshToken = () => {
  return JSON.parse(read('userPrizer'))?.token?.refreshToken;
};

export const setNewAccessToken = (accessToken) => {
  let userPrizer = JSON.parse(read('userPrizer'));
  userPrizer.token.accessToken = accessToken;
  store('userPrizer', JSON.stringify(userPrizer));
};

export const setUserPrizerToLS = (userData) => {
  // const domainName =
  //   window.location.hostname === 'localhost' ? '' : '.givvo.com';
  store('userPrizer', userData);
  //Cookies.set('userPrizer', userData, { domain: domainName });
  Cookies.remove('userPrizer');
};

export const removeUserPrizeFromLS = () => {
  Cookies.remove('userPrizer');
  localStorage.removeItem('userPrizer');
};
