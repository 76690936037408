import React from 'react';

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import airplane from '../../assets/images/backoffice/Airplane.png';
import { DASHBOARD } from '../../routes/routes';
import { H3 } from '../../styles/FontStyles';
import { color } from '../../styles/GlobalStyles';
import {
  AuthButton,
  ButtonWrapper,
  FloatingButton,
  PhoneComp
} from '../../styles/stylesStyled';

const StartKyc = ({ history }) => {
  const { t } = useTranslation('onboarding.start_kyc');
  const { user } = useSelector((state) => state.userInfo);

  return (
    <>
      <PhoneComp>
        <div style={{ textAlign: 'center' }}>
          <img src={airplane} alt='' style={{ width: 160 }} />
        </div>

        <H3 mb='12' center color={color.authLayoutLabel}>
          {t('welcome')} {user?.name}!
        </H3>

        <p style={{ color: '#857272', fontSize: '14px', textAlign: 'center' }}>
          {t('start')}
        </p>
        <FloatingButton>
          <ButtonWrapper>
            <AuthButton
              opacity={'true'}
              onClick={() => history.push(DASHBOARD)}>
              {t('continue')}
            </AuthButton>
          </ButtonWrapper>
        </FloatingButton>
      </PhoneComp>
    </>
  );
};

export default StartKyc;
