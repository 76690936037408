import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Title, SubTitle } from '../../SettingsBackoffice/SettingsStyled';
import { Button, SecurityCodeWrapper } from '../../../styles/GlobalComponents';
import Spinner from '../../../components/common/Spinner';
import Alert from '../../../components/common/Alert';
import { FlexCenter } from '../../../styles/stylesStyled';
import { useTranslation } from 'react-i18next';
import { color } from '../../../styles/GlobalStyles';
import ReactCodeInput from 'react-code-input';

import { HELPDESK } from '../../../routes/routes';
import { createWithdrawEmailExplorerVerification } from '../../EarningsBackoffice/Withdraw/Withdraw.actions';

export const VerificationEmail = ({
  setStep,
  amount,
  userWallets,
  selected,
  setAmount
}) => {
  const { t } = useTranslation('settings');
  const history = useHistory();
  const dispatch = useDispatch();
  const [code, setCode] = useState('');

  const { error, loadingWithdraw } = useSelector((state) => state.withdraw);

  return (
    <div style={{ marginTop: '20vh' }}>
      {error && <Alert message={error.error} />}
      <FlexCenter style={{ flexDirection: 'column' }}>
        {loadingWithdraw ? (
          <Spinner dark />
        ) : (
          <>
            <Title>{t('security.verification_email_title')}</Title>
            <SubTitle style={{ textAlign: 'center' }}>
              {t('security.seven_digits')}
            </SubTitle>

            <SecurityCodeWrapper>
              <ReactCodeInput
                fields={7}
                value={code}
                onChange={setCode}
                type={'number'}
              />
            </SecurityCodeWrapper>

            <Button
              maxWidth='300px'
              h='50px'
              m={'30px 0 0 0'}
              disabled={code.length < 7}
              onClick={() => {
                dispatch(
                  createWithdrawEmailExplorerVerification(
                    parseInt(amount),
                    userWallets[selected].id,
                    setStep,
                    setAmount,
                    code
                  )
                );
              }}>
              {t('security.submit')}
            </Button>
            <SubTitle style={{ textAlign: 'center', marginTop: 12 }}>
              {t('security.verification_email_problems')}
              <span
                style={{ color: color.colors.secondary100, marginLeft: 5 }}
                onClick={() => history.push(HELPDESK)}>
                {t('security.contact_support')}
              </span>
            </SubTitle>
          </>
        )}
      </FlexCenter>
    </div>
  );
};
