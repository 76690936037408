import React from 'react';
import { useSelector } from 'react-redux';
import { Title, SubTitle, QrCodeWrapper } from './SettingsStyled';
import { Button } from '../../styles/GlobalComponents';
import { FlexCenter, SelectedAddress } from '../../styles/stylesStyled';
import { useTranslation } from 'react-i18next';
import { color } from '../../styles/GlobalStyles';
import { Icon, IconCopy } from '../../styles/Icons';
import Spinner from '../../components/common/Spinner';
import { SuccessToast } from '../../components/common/Toasts/Toasts';

export const QRSetup = ({ setStep }) => {
  const { t } = useTranslation('settings');

  const { user2FAData, loadingVerification } = useSelector(
    (state) => state.settings
  );
  return (
    <>
      {loadingVerification ? (
        <Spinner dark />
      ) : (
        <FlexCenter style={{ flexDirection: 'column', paddingTop: 50 }}>
          <>
            <Title style={{ marginBottom: 30 }}>
              {t('security.scan_code')}{' '}
            </Title>
            {user2FAData !== null && (
              <QrCodeWrapper>
                <div
                  style={{
                    width: 192,
                    height: 192,
                    backgroundImage: `url(${user2FAData.qrUrl})`,
                    backgroundRepeat: 'no-repeat'
                  }}></div>
              </QrCodeWrapper>
            )}
            <SelectedAddress
              onClick={() => {
                SuccessToast(t('security.success_copied'));
                navigator.clipboard.writeText(user2FAData?.secret);
              }}
              style={{ cursor: 'pointer' }}>
              <div className='truncated'>
                <div>{user2FAData?.secret}</div>
              </div>
              <Icon ml='10' color={color.colors.neutral900}>
                <IconCopy />
              </Icon>
            </SelectedAddress>
            <SubTitle style={{ textAlign: 'center' }}>
              {t('security.code_information')}
            </SubTitle>
            <Button
              maxWidth='300px'
              h='50px'
              m={'30px 0 0 0'}
              opacity={'true'}
              onClick={() => setStep(2)}>
              {t('security.next')}
            </Button>
          </>
        </FlexCenter>
      )}
    </>
  );
};
