import i18next from 'i18next';

import {
  getAccessToken,
  setNewAccessToken,
  getRefreshToken,
  removeUserPrizeFromLS
} from './localstorage-helper.js';

import axios from 'axios';

window.axios = axios;

const API_URL = process.env.REACT_APP_API_URL;

window.axios.defaults.headers.common['Authorization'] =
  'Bearer ' + getAccessToken();

async function refreshToken() {
  const response = await window.axios.put(
    `${API_URL}/authorization/token/refresh`,
    { refreshToken: getRefreshToken() }
  );

  let newAccessToken = response.data.data.accessToken;
  setNewAccessToken(newAccessToken);
  window.axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${newAccessToken}`;
}

window.axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${getAccessToken()}`;
    config.headers.Language = i18next.language;

    config.headers['Website-Module-Id'] = 3;
    config.headers['region-type'] = 2;

    return config;
  },
  function (err) {
    console.log(err);
    return err;
  }
);

window.axios.interceptors.response.use(
  function (response) {
    // const { status, data, config } = response;
    // console.log(`Response from ${config.url}:`, {
    //     code: status,
    //     ...data,
    // });

    return response;
  },
  async function (error) {
    if (error.response) {
      const { status, data } = error.response;

      console.log('AXIOS INTERCEPTOR ERROR');

      switch (status) {
        case 401:
          if (data.error === 'Authorization header is not valid!') {
            try {
              await refreshToken();
              const config = error.config;
              return await window.axios({
                method: config.method,
                url: config.url,
                data: config.data
              });
            } catch (e) {
              localStorage.removeItem('prizerRegister');
              removeUserPrizeFromLS();
              return (window.location.href = '/login');
            }
          } else {
            localStorage.removeItem('prizerRegister');
            removeUserPrizeFromLS();
            return (window.location.href = '/login');
          }
        default:
          return Promise.reject(error);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return Promise.reject(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(error);
    }
  }
);
