import React from 'react';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Desktop, Mobile } from '../../App';
import { SuccessToast } from '../../components/common/Toasts/Toasts';
import { CaptionText, H1, H2, P2, P3 } from '../../styles/FontStyles';
import { Box, Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { FlexCenter } from '../../styles/stylesStyled';
import {
  CashBackFlex,
  CountdownNumbers,
  IconTitleStyled
} from './DashboardStyled';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTranslation } from 'react-i18next';
import { CASHBACK_BONUS } from '../../routes/routes';

const API_URL = process.env.REACT_APP_SHARE_LINK;

export const MoneyBack = () => {
  const { t } = useTranslation('cashback');
  const userInfo = useSelector((state) => state.userInfo);
  const { dashboard } = useSelector((state) => state.dashboard);

  const referralUrl = `${API_URL}`;
  const date = new Date(dashboard?.user?.fastStartBonus?.limitDate);
  const dateNow = Date.now();
  const timestamp = date.getTime();
  const timestamp40Days = date.getTime();

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div
        style={{
          width: 302,
          height: 302,

          position: 'relative',
          border: '3px solid red',
          borderRadius: '50%',
          padding: 3
        }}>
        <CountdownCircleTimer
          isPlaying={!completed}
          duration={60}
          colors={[color.colors.error500]}
          colorsTime={[60]}
          onComplete={() => {
            // do your stuff here
            return { shouldRepeat: true, delay: 0 }; // repeat animation in 1.5 seconds
          }}
          initialRemainingTime={seconds}
          size='290'
          strokeWidth={20}
          strokeLinecap={'square'}></CountdownCircleTimer>
        <div
          style={{
            width: 290,
            height: 290,

            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CountdownNumbers>
            <div>
              <H2>{days}</H2>
              <CaptionText caps> days</CaptionText>
            </div>
            <div>
              <H2>{hours}</H2>
              <CaptionText caps> hours</CaptionText>
            </div>
            <div>
              <H2>{minutes}</H2>
              <CaptionText caps> minutes</CaptionText>
            </div>
          </CountdownNumbers>
        </div>
      </div>
    );
  };

  if (dateNow < timestamp40Days) {
    return (
      <div>
        <IconTitleStyled>Fast Start bonus</IconTitleStyled>
        <Box p='40px'>
          <CashBackFlex>
            <div>
              <Desktop>
                <H1 color={color.colors.neutral900} mb='12'>
                  {t('get_100_of')} <span> {t('your_money_back')}</span>
                </H1>
                <P2 maxWidth='522px' color={color.colors.neutral500} mb='40'>
                  {t('enroll')}
                </P2>
                <FlexCenter>
                  <Button
                    w='200px'
                    mr='12'
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${referralUrl}ambassador/${userInfo?.user?.referralCode}/${userInfo?.user?.id}`
                      );
                      SuccessToast(`${t('partner_link_has_been_copied')}`);
                    }}>
                    {t('copy_invite_link')}
                  </Button>
                  <Link to={CASHBACK_BONUS}>
                    <Button w='200px' negative>
                      {t('check_progress')}
                    </Button>
                  </Link>
                </FlexCenter>
              </Desktop>

              <Mobile>
                <H2 color={color.colors.neutral900} mb='12'>
                  {t('get_100_of')} <span> {t('your_money_back')}</span>
                </H2>
                <P3 color={color.colors.neutral500} mb='20'>
                  {t('enroll')}
                </P3>
                <Button
                  mr='12'
                  mb='12'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${referralUrl}ambassador/${userInfo?.user?.referralCode}/${userInfo?.user?.id}`
                    );
                    SuccessToast(`${t('partner_link_has_been_copied')}`);
                  }}>
                  {t('copy_invite_link')}
                </Button>
                <Link to={CASHBACK_BONUS}>
                  <Button negative mb='32'>
                    {t('check_progress')}
                  </Button>
                </Link>
              </Mobile>
            </div>
            <div>
              <Countdown date={timestamp} renderer={renderer} />
            </div>
          </CashBackFlex>
        </Box>
      </div>
    );
  } else return null;
};
