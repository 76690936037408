// import { ErrorToast } from '../components/common/Toasts/Toasts';
import { ErrorToast } from '../components/common/Toasts/Toasts';
import { CLEAR_ALERT, CLEAR_MESSAGE } from '../constants/errorConstants';

export const createAxiosConfigWithAuthHeader = (getState) => {
  const { userInfo } = getState();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token.accessToken}`
    }
  };
};

export const clearAlert = (dispatch) => {
  setTimeout(() => {
    dispatch({
      type: CLEAR_ALERT,
      payload: ''
    });
  }, 3000);
};

export const dispatchErrorMessageAndClearAlert = (
  dispatch,
  error,
  failActionType
) => {
  // let errorMessage = 'Error';
  // if (error?.response?.data) {
  //   errorMessage = error?.response?.data;
  // } else if (typeof error === 'string') {
  //   errorMessage = error;
  // }
  dispatch({
    type: failActionType
    // payload: errorMessage
  });
  // console.log(error?.response?.data);
  ErrorToast(error?.response?.data?.error);
  // clearAlert(dispatch);
};

export const tryExecuteSimpleAction = async (
  dispatch,
  requestActionType,
  action,
  failActionType
) => {
  try {
    dispatch({ type: requestActionType });
    await action();
  } catch (error) {
    // console.log(error);

    dispatchErrorMessageAndClearAlert(dispatch, error, failActionType);

    // const status = error?.response?.status;
    // const errorMessage = error?.response?.data?.error;

    // if (status >= 400 && status < 500) {
    //   ErrorToast(errorMessage);
    // } else if (status >= 500) {
    //   ErrorToast('Server error');
    // }
  }
};

export const clearMessageAfterPeriod = (dispatch, n = 3000) => {
  setTimeout(() => {
    dispatch({
      type: CLEAR_MESSAGE,
      payload: {
        message: ''
      }
    });
  }, n);
};
