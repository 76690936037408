import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Flex,
  FlexBetween,
  FlexJustifyCenter,
  HubLengthMessage
} from '../../styles/stylesStyled';

import Spinner from '../../components/common/Spinner';

import { InvoiceHeader, Invoices, InvoicesMobile } from './AccountingStyled';
import { Desktop, Mobile } from '../../App';
import {
  getInvoices,
  getTransactions,
  getWithdrawals
} from '../../actions/accountingActions';
import { useEffect } from 'react';
import moment from 'moment';
import { USDT, formatUSD, showWallet } from '../../helpers/helperFunctions';
import {
  PageTitle,
  TabSelectButton,
  Button,
  Flexed
} from '../../styles/GlobalComponents';
import { useTranslation } from 'react-i18next';
import { AnimatedDashboard } from '../../components/animation/AnimatedDashboard';
import { AnimatePresence } from 'framer-motion';
import { AnimatedItem } from '../../components/animation/AnimatedItem';
import { AnimatedTab } from '../../components/animation/AnimatedTab';

const Accounting = () => {
  const { t } = useTranslation('accounting');
  const dispatch = useDispatch();
  const { invoices, transactions, withdrawals, loading } = useSelector(
    (state) => state.accounting
  );

  const [tab, setTab] = useState(1);
  const [countInvoices, setCountInvoices] = useState(50);
  const [countTransactions, setCountTransactions] = useState(50);
  const [countWithdrawals, setCountWithdrawals] = useState(50);
  const [lastWeekStartDate, setLastWeekStartDate] = useState(null);
  const numberOfPage = 1;

  useEffect(() => {
    if (tab === 1) {
      if (countInvoices <= 100) {
        dispatch(getInvoices(countInvoices, lastWeekStartDate));
      }
    } else if (tab === 2) {
      if (countTransactions <= 100) {
        dispatch(getTransactions(countTransactions, numberOfPage));
      }
    } else {
      if (countWithdrawals <= 100) {
        dispatch(getWithdrawals(countTransactions, numberOfPage));
      }
    }
  }, [
    dispatch,
    tab,
    countInvoices,
    countTransactions,
    countWithdrawals,
    lastWeekStartDate
  ]);

  const transactionStatus = (status) => {
    switch (status) {
      case 1:
        return 'processing';
      case 2:
        return 'completed';
      case 3:
        return 'refunded';
      default:
    }
  };

  const withdrawalStatus = (status) => {
    switch (status) {
      case 1:
        return 'pending';
      case 2:
        return 'paid';
      case 3:
        return 'completed';
      case 4:
        return 'canceled';
      case 5:
        return 'automatic payout in progress';
      case 6:
        return 'paid';
      default:
    }
  };

  if (loading) return <Spinner fullScreen={true} dark={true} />;

  return (
    <AnimatedDashboard zoom>
      <div style={{ marginBottom: 100 }}>
        <PageTitle>{t('accounting')}</PageTitle>

        <Flex>
          <TabSelectButton selected={tab === 1} onClick={() => setTab(1)}>
            <div>{t('invoices')}</div>
          </TabSelectButton>
          <TabSelectButton selected={tab === 2} onClick={() => setTab(2)}>
            <div>{t('transactions')}</div>
          </TabSelectButton>
          <TabSelectButton selected={tab === 3} onClick={() => setTab(3)}>
            <div>{t('withdrawals')}</div>
          </TabSelectButton>
        </Flex>
        <AnimatePresence exitBeforeEnter>
          {tab === 1 && (
            <AnimatedTab key={1}>
              <Desktop>
                <InvoiceHeader>
                  <div style={{ width: '20%' }}>{t('date')}</div>
                  <div style={{ width: '20%' }}>{t('invoice_id')}</div>
                  <div style={{ width: '20%' }}>{t('amount')}</div>
                  <div style={{ width: '20%' }}>{t('status')}</div>
                  <div
                    style={{
                      width: '20%',
                      textAlign: 'right'
                    }}>
                    {t('invoice')}
                  </div>
                </InvoiceHeader>
              </Desktop>

              {invoices?.length === 0 ? (
                <HubLengthMessage>{t('no_invoices')}</HubLengthMessage>
              ) : (
                invoices?.map((inv, i) => {
                  return (
                    <AnimatedItem index={i} key={i}>
                      <Desktop>
                        <Invoices>
                          <div style={{ width: '20%' }}>
                            {moment.utc(inv?.weekStartDate).format('DD/MM/YY')}
                            {' - '}
                            {moment.utc(inv?.weekEndDate).format('DD/MM/YY')}
                          </div>
                          <div style={{ width: '20%' }}>#{inv.id}</div>
                          <div style={{ width: '20%' }}>
                            <span>
                              {formatUSD(inv.amount)} {USDT}
                            </span>
                          </div>
                          <div style={{ width: '20%' }}>
                            {inv.amount !== 0
                              ? inv.isPaid
                                ? t('paid')
                                : t('processing')
                              : '/'}
                          </div>
                          <div
                            style={{
                              width: '20%',
                              textAlign: 'right'
                            }}>
                            {inv.amount === 0 && t('no_report')}
                          </div>
                        </Invoices>
                      </Desktop>
                      <Mobile>
                        <InvoicesMobile>
                          <FlexBetween>
                            <div
                              style={{
                                fontWeight: '500',
                                lineHeight: '20px'
                              }}>
                              #{inv.id}
                            </div>
                            <div
                              style={{
                                fontWeight: '500',
                                lineHeight: '20px',
                                fontSize: 18
                              }}>
                              {formatUSD(inv.amount)} {USDT}
                            </div>
                          </FlexBetween>
                          <FlexBetween>
                            <div>
                              {moment
                                .utc(inv?.weekStartDate)
                                .format('DD/MM/YY')}
                              {' - '}
                              {moment.utc(inv?.weekEndDate).format('DD/MM/YY')}
                            </div>
                            <div
                              style={{
                                color: '#B5A8A8',
                                lineHeight: '18px',
                                fontSize: 12
                              }}>
                              {inv.amount !== 0
                                ? inv.isPaid
                                  ? t('paid')
                                  : t('processing')
                                : '/'}
                            </div>
                          </FlexBetween>
                        </InvoicesMobile>
                      </Mobile>
                    </AnimatedItem>
                  );
                })
              )}

              {invoices?.length >= 50 && invoices?.length === countInvoices && (
                <FlexJustifyCenter>
                  <Button
                    w='200px'
                    mt='10'
                    mb='20'
                    onClick={() => {
                      setCountInvoices((count) => count + 50);
                      setLastWeekStartDate(
                        invoices[invoices?.length - 1]?.weekEndDate
                      );
                    }}>
                    Show more
                  </Button>
                </FlexJustifyCenter>
              )}
            </AnimatedTab>
          )}

          {tab === 2 && (
            <AnimatedTab key={2}>
              <Desktop>
                <InvoiceHeader>
                  <div style={{ width: '20%' }}>{t('date')}</div>
                  <div style={{ width: '20%' }}>{t('invoice_id')}</div>
                  <div style={{ width: '20%' }}>{t('type')}</div>
                  <div style={{ width: '20%' }}>{t('amount')}</div>
                  <div style={{ width: '20%' }}>{t('status')}</div>
                </InvoiceHeader>
              </Desktop>

              {transactions?.length === 0 ? (
                <HubLengthMessage>{t('no_invoices')}</HubLengthMessage>
              ) : (
                transactions?.map((inv, i) => {
                  const type = inv.groupType;

                  const TransactionTypeAmount = () => {
                    switch (type) {
                      case 1:
                        return (
                          inv.rewardPoints &&
                          `${formatUSD(inv.rewardPoints)} ${USDT}`
                        );
                      case 2:
                        return (
                          inv.rewardPoints && (
                            <div>
                              {`${formatUSD(inv.rewardPoints)} ${USDT}`}
                              <br />

                              {inv.shoppingPoints > 0 &&
                                `${formatUSD(inv.shoppingPoints)} CLUB USDT`}

                              {inv.shoppingPoints > 0 && <br />}
                              {`${formatUSD(
                                inv.ulxPoints ? inv.ulxPoints : 0
                              )} ULX`}
                            </div>
                          )
                        );
                      case 3:
                        return (
                          inv.shoppingPoints &&
                          `${formatUSD(inv.shoppingPoints)} ${USDT}`
                        );
                      case 4:
                        return (
                          inv.shoppingPoints &&
                          `${formatUSD(inv.shoppingPoints)} ${USDT}`
                        );
                      case null:
                        return ``;
                      default:
                        return '';
                    }
                  };

                  return (
                    <AnimatedItem index={i} key={i}>
                      <Desktop>
                        {inv.rewardPoints > 0 && (
                          <Invoices>
                            <div style={{ width: '20%' }}>
                              {moment.utc(inv?.dateCreated).format('DD/MM/YY')}
                            </div>
                            <div style={{ width: '20%' }}>#{inv.id}</div>
                            <div style={{ width: '20%' }}>
                              {TransactionType(inv.groupType)}
                            </div>
                            <div style={{ width: '20%' }}>
                              <span> {TransactionTypeAmount()}</span>
                            </div>
                            <div style={{ width: '20%' }}>
                              {transactionStatus(inv.status)}
                            </div>
                          </Invoices>
                        )}
                      </Desktop>
                      <Mobile>
                        {inv.rewardPoints > 0 && (
                          <InvoicesMobile>
                            <FlexBetween>
                              <div
                                style={{
                                  fontWeight: '500',
                                  lineHeight: '20px'
                                }}>
                                {TransactionType(inv.groupType)}
                              </div>
                              <div
                                style={{
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                  fontSize: 18
                                }}>
                                {TransactionTypeAmount()}
                              </div>
                            </FlexBetween>
                            <FlexBetween>
                              <div>
                                {moment
                                  .utc(inv?.dateCreated)
                                  .format('DD/MM/YY')}{' '}
                                • #{inv.id}
                              </div>
                              <div
                                style={{
                                  color: '#B5A8A8',
                                  lineHeight: '18px',
                                  fontSize: 12
                                }}>
                                {transactionStatus(inv.status)}
                              </div>
                            </FlexBetween>
                          </InvoicesMobile>
                        )}
                      </Mobile>
                    </AnimatedItem>
                  );
                })
              )}
              {transactions?.length >= 50 &&
                transactions?.length === countTransactions && (
                  <FlexJustifyCenter>
                    <Button
                      w='200px'
                      mt='10'
                      mb='20'
                      onClick={() =>
                        setCountTransactions((count) => count + 50)
                      }>
                      Show more
                    </Button>
                  </FlexJustifyCenter>
                )}
            </AnimatedTab>
          )}

          {tab === 3 && (
            <AnimatedTab key={3}>
              <Desktop>
                <InvoiceHeader>
                  <div style={{ width: '8%' }}>{t('date')}</div>
                  <div style={{ width: '8%' }}>{t('id_withdrawal')}</div>
                  <div style={{ width: '12.5%' }}>{t('amount')}</div>
                  <div style={{ width: '12.5%' }}>{t('fee')}</div>
                  <div style={{ width: '12.5%' }}>{t('paid_amount')}</div>
                  <div style={{ width: '35%' }}>{t('wallet')}</div>
                  <div style={{ width: '12.5%' }}>{t('status')}</div>
                </InvoiceHeader>
              </Desktop>

              {withdrawals?.length === 0 ? (
                <HubLengthMessage>{t('no_withdrawals')}</HubLengthMessage>
              ) : (
                withdrawals?.map((inv, i) => {
                  return (
                    <AnimatedItem index={i} key={i}>
                      <Desktop>
                        <Invoices>
                          <div style={{ width: '8%' }}>
                            {inv?.datePaid &&
                              moment.utc(inv?.datePaid).format('DD/MM/YY')}
                          </div>
                          <div style={{ width: '8%' }}>#{inv.id}</div>
                          <div style={{ width: '12.5%' }}>
                            {' '}
                            <span>
                              {formatUSD(inv.amount)} {USDT}
                            </span>
                          </div>
                          <div style={{ width: '12.5%' }}>
                            <span>
                              {formatUSD(inv.feeAmount)} {USDT}
                            </span>
                          </div>
                          <div style={{ width: '12.5%' }}>
                            <span>
                              {formatUSD(inv.paidAmount)} {USDT}
                            </span>
                          </div>
                          <div style={{ width: '35%' }}>{inv.wallet}</div>

                          <div style={{ width: '12.5%' }}>
                            {withdrawalStatus(inv.status)}
                          </div>
                        </Invoices>
                      </Desktop>
                      <Mobile>
                        <Mobile>
                          <InvoicesMobile>
                            <FlexBetween>
                              <Flexed column>
                                <div
                                  style={{
                                    fontWeight: '500',
                                    lineHeight: '20px'
                                  }}>
                                  #{inv.id}
                                </div>
                                <div>
                                  {inv?.datePaid &&
                                    moment
                                      .utc(inv?.datePaid)
                                      .format('DD/MM/YY')}
                                </div>
                                <div
                                  style={{
                                    color: '#B5A8A8',
                                    lineHeight: '18px',
                                    fontSize: 12
                                  }}>
                                  {withdrawalStatus(inv.status)}
                                </div>
                              </Flexed>
                              <Flexed column alignEnd>
                                <div
                                  style={{
                                    fontWeight: '500',
                                    fontSize: 18
                                  }}>
                                  {formatUSD(inv.amount)} {USDT}
                                </div>

                                <div
                                  style={{
                                    fontWeight: '500',
                                    fontSize: 18
                                  }}>
                                  {formatUSD(inv.feeAmount)} {USDT}
                                </div>

                                <div
                                  style={{
                                    fontWeight: '500',
                                    fontSize: 18
                                  }}>
                                  {formatUSD(inv.paidAmount)} {USDT}
                                </div>
                              </Flexed>
                            </FlexBetween>

                            <Flex>
                              <div>{showWallet(inv.wallet, -30)}</div>
                            </Flex>
                          </InvoicesMobile>
                        </Mobile>
                      </Mobile>
                    </AnimatedItem>
                  );
                })
              )}
              {withdrawals?.length >= 50 &&
                withdrawals?.length === countWithdrawals && (
                  <FlexJustifyCenter>
                    <Button
                      w='200px'
                      mt='10'
                      mb='20'
                      onClick={() =>
                        setCountWithdrawals((count) => count + 50)
                      }>
                      Show more
                    </Button>
                  </FlexJustifyCenter>
                )}
            </AnimatedTab>
          )}
        </AnimatePresence>
      </div>
    </AnimatedDashboard>
  );
};

export default Accounting;

const TransactionType = (type) => {
  switch (type) {
    case 1:
      return 'withdraw';
    case 2:
      return 'commission';
    case 3:
      return 'exchange';
    case 4:
      return 'deposit';
    default:
      return '';
  }
};
