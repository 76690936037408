import React from 'react';
import { Desktop, Mobile } from '../../../App';
import { Hub } from '../../../components/common/Hub/Hub';
import { H3, H4, P1, P2, P3, CaptionText } from '../../../styles/FontStyles';
import { Button, Flexed } from '../../../styles/GlobalComponents';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../Checkbox/Checkbox';

import { VirtualOfficeSubscriptionStyled } from './VirtualOfficeSubscription.styled';

import {
  BACKOFFICE_NAME_CAPS,
  formatUSD,
  USDT
} from '../../../helpers/helperFunctions';

import { addHub } from '../../../views/Marketplace/Marketplace.actions';

import { buyVirtaulOffice } from '../../../views/SettingsBackoffice/settings.actions';

export const VirtualOfficeSelectedPlan = ({
  step,
  setStep,
  selected,
  selectedHub
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userInfo);
  const { hubs } = useSelector((state) => state.hubs);

  const hub = hubs?.find((i) => i.id === selectedHub);

  const { t } = useTranslation('marketplace');

  return (
    <div>
      {!selectedHub && (
        <>
          <P1 bold mt={40} mb={20}>
            SELECTED {BACKOFFICE_NAME_CAPS} SUBSCRIPTION:
          </P1>
          <VirtualOfficeSubscriptionStyled>
            <Desktop>
              <div>
                <Flexed between center>
                  <Flexed center>
                    <Checkbox margin='0 12px 0 0' size={38} checked={true} />
                    <div>
                      <P1 caps bold>
                        {selected?.title}
                      </P1>
                    </div>
                  </Flexed>
                  <div style={{ textAlign: 'end' }}>
                    <H3>${selected?.price}</H3>
                  </div>
                </Flexed>
              </div>
            </Desktop>
            <Mobile>
              <div>
                <Flexed between center>
                  <Flexed center>
                    <Checkbox margin='0 12px 0 0' size={38} checked={true} />
                    <div>
                      <H4>{selected?.title}</H4>
                    </div>
                  </Flexed>
                  <div style={{ textAlign: 'end' }}>
                    <H4>${selected?.price}</H4>
                  </div>
                </Flexed>
              </div>
            </Mobile>
          </VirtualOfficeSubscriptionStyled>
        </>
      )}
      {selectedHub && (
        <P2 mt={40} mb={20} bold>
          {t('selected_staking_hub_nft')}:
        </P2>
      )}

      {selectedHub &&
        hubs.map(({ id, image, title, price, weeklyCap }) => {
          if (id === selectedHub) {
            return (
              <Hub
                key={id}
                condensed
                image={image}
                title={title}
                price={hub?.price ? price + selected?.price : price}
                weeklyCap={weeklyCap}
                info={
                  <>
                    <CaptionText bold right mb='4'>
                      Includes one year BackOffice Subscription
                    </CaptionText>
                  </>
                }
                stakingHubDetails={<div></div>}
              />
            );
          } else return null;
        })}

      <P3 mb='10' mt='40' center>
        Available {USDT} balance:{' '}
        <b>
          {formatUSD(user?.userTickets?.availableRewardPoints)} {USDT}
        </b>
      </P3>

      <Button
        mb={20}
        onClick={() => {
          if (selectedHub) {
            dispatch(addHub(selectedHub, 3, null, 1, 3, setStep));
          } else {
            dispatch(buyVirtaulOffice(selected?.id, null, 3, 5, setStep));
          }
        }}
        disabled={
          user?.userTickets?.availableRewardPoints === 0 ||
          selected?.price > user?.userTickets?.availableRewardPoints
        }>
        Buy with {USDT} balance
      </Button>
      <Button negative mb={20} onClick={() => setStep(step)}>
        Buy with Tether ({USDT})
      </Button>
    </div>
  );
};
