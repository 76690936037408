import styled from 'styled-components';

const StrengthBar = styled.div`
  background: ${(props) => props.color};
  height: 6px;
  width: 22%;
  text-align: center;
  border-radius: 50px;
`;

const StrengthText = styled.p`
  font-size: 12px;
  margin-top: 12px;
  color: ${(props) => props.color};
`;

export { StrengthBar, StrengthText };
