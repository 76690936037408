import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MyTeamBackoffice from './MyTeamBackoffice';
import { init } from './myTeamBackOffice.actions';
import { getUserSettings } from '../../actions/userSettingsActions';

const MyTeamBackofficeRoot = ({ props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(init());
    dispatch(getUserSettings());
  }, [dispatch]);

  return <MyTeamBackoffice {...props} />;
};

export default MyTeamBackofficeRoot;
