import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { logout } from '../../actions/authActions';

import { SuccessToast } from '../../components/common/Toasts/Toasts';
import {
  ACCOUNTING,
  SETTINGS_ACCOUNT,
  SETTINGS_LAYOUT
} from '../../routes/routes';
import {
  SectionSeparator,
  UserImageCircle
} from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  Icon as Icn,
  Icon,
  IconAccounting,
  IconCopy,
  IconInviteLinks,
  IconLogout,
  IconSettings
} from '../../styles/Icons';
import { FlexCenter } from '../../styles/stylesStyled';
import { useWindowWide } from '../../utils/wideScreen';
import {
  Collapsable,
  Menu,
  MenuInviteLabels,
  MenuInvitePlaceholder
} from '../BackofficeLayout/BackofficeLayoutStyled';

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(28, 23, 22, 0.1);
  backdrop-filter: blur(5px);
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? '1' : '0')};
  z-index: 100999 !important;
  transition: all 0.5s ease;
  height: 100vh;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  padding: 40px 20px;
  position: fixed;
  right: ${({ open }) => (open ? '0' : '-350px')};
  top: 0;
  bottom: 0;
  background: ${(props) => props.theme.appLayout.sideNavBackground};
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  transition: all 0.3s ease;
  z-index: 4999999 !important;
  height: 100vh;

  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  overflow: auto;

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
`;

const API_URL = process.env.REACT_APP_MAVIE_LINK;
// const SHARE_LINK = process.env.REACT_APP_REFERRER_URL;

export const ProfileDropdown = ({ menuIsOpen, userInfo, setMenuIsOpen }) => {
  const { t } = useTranslation('layout');
  const dispatch = useDispatch();
  const history = useHistory();

  const [inviteIsOpened, setInviteIsOpened] = useState(false);

  const referralUrl = `${API_URL}`;

  const wide = useWindowWide(800);

  const MenuContent = () => {
    return (
      <div>
        <FlexCenter>
          <UserImageCircle
            image={userInfo?.user?.profileImageUrl}
            name={userInfo?.user?.name}
            surname={userInfo?.user?.surname}
            size='56px'
            fontSize='20px'
            margin={'0 20px 0 0'}
          />

          <div>
            <div>{userInfo?.user?.name + ' ' + userInfo?.user?.surname}</div>
            <span>
              {t('profile.id')} {userInfo?.user?.id}
            </span>
          </div>
        </FlexCenter>
        <SectionSeparator margin={'24px 0'} />

        <FlexCenter
          style={{ cursor: 'pointer' }}
          onClick={() => setInviteIsOpened(!inviteIsOpened)}
          className='invite-links'>
          <Icon pointer mr='16' color={color.appLayout.navIconColor}>
            <IconInviteLinks />
          </Icon>
          {t('profile.invite_links')}
        </FlexCenter>

        <Collapsable expanded={inviteIsOpened}>
          <MenuInviteLabels style={{ marginTop: inviteIsOpened && 10 }}>
            {t('profile.my_id')}{' '}
          </MenuInviteLabels>
          <MenuInvitePlaceholder
            onClick={() => {
              navigator.clipboard.writeText(userInfo?.user?.id);
              SuccessToast(t('profile.id_has_been_copied'));
            }}>
            {userInfo?.user?.id}
            <Icn color={color.colors.neutral900}>
              <IconCopy />
            </Icn>
          </MenuInvitePlaceholder>
          <MenuInviteLabels>{t('profile.referral_link')}</MenuInviteLabels>
          <MenuInvitePlaceholder
            onClick={() => {
              navigator.clipboard.writeText(
                `${referralUrl}/ambassador/${userInfo?.user?.referralCode}/${userInfo?.user?.id}`
              );
              SuccessToast(t('profile.partner_link_has_been_copied'));
            }}>
            {referralUrl
              ? referralUrl.length > 23
                ? referralUrl.substring(0, 20) + '...'
                : referralUrl
              : ''}
            <Icn color={color.colors.neutral900}>
              <IconCopy />
            </Icn>
          </MenuInvitePlaceholder>
        </Collapsable>

        <Link to={ACCOUNTING} onClick={() => setMenuIsOpen(!menuIsOpen)}>
          <FlexCenter style={{ marginBottom: 24, marginTop: 24 }}>
            <Icon pointer mr='16' color={color.appLayout.navIconColor}>
              <IconAccounting />
            </Icon>
            {t('profile.accounting')}
          </FlexCenter>
        </Link>
        {/* <Link to={CHAT_LIST} onClick={() => setMenuIsOpen(!menuIsOpen)}>
          <FlexCenter style={{ marginBottom: 24 }}>
            <Icon pointer mr='16' w='24px' color={color.appLayout.navIconColor}>
              <IconMessaging />
            </Icon>
            {t('profile.messaging')}
          </FlexCenter>
        </Link> */}
        <Link
          to={!wide ? SETTINGS_LAYOUT : SETTINGS_ACCOUNT}
          onClick={() => setMenuIsOpen(!menuIsOpen)}>
          <FlexCenter style={{ marginBottom: 24 }}>
            <Icon pointer mr='16' color={color.appLayout.navIconColor}>
              <IconSettings />
            </Icon>

            {t('profile.settings')}
          </FlexCenter>
        </Link>
        {/* <Link to={HELPDESK} onClick={() => setMenuIsOpen(!menuIsOpen)}>
          <FlexCenter style={{ marginBottom: 24 }}>
            <Icon pointer mr='16' color={color.appLayout.navIconColor}>
              <IconHelpdesk />
            </Icon>
            {t('profile.helpdesk')}
          </FlexCenter>
        </Link> */}

        <SectionSeparator margin={'24px 0'} />
        <FlexCenter
          onClick={() => dispatch(logout(history, setMenuIsOpen))}
          style={{ cursor: 'pointer' }}>
          <Icon pointer mr='16' color={color.appLayout.navIconColor}>
            <IconLogout />
          </Icon>
          {t('profile.logout')}
        </FlexCenter>
      </div>
    );
  };

  useEffect(() => {
    if (!wide && menuIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [menuIsOpen, wide]);

  return (
    <>
      {wide ? (
        <Menu isOpen={menuIsOpen}>
          <MenuContent />
        </Menu>
      ) : (
        <>
          <Overlay
            open={menuIsOpen}
            onClick={() => setMenuIsOpen(false)}></Overlay>
          <SidebarContainer open={menuIsOpen}>
            <MenuContent />
          </SidebarContainer>
        </>
      )}
    </>
  );
};
