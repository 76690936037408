import React from 'react';
import { SidebarSubtitle, SidebarTitle } from '../../styles/stylesStyled';

import { Button } from '../../styles/GlobalComponents';
import Spinner from '../../components/common/Spinner';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { cancelPackage } from './packages.actions';

const CancelPaymentPackage = ({ selectedPackage, setIsCancelModalOpen }) => {
  const { t } = useTranslation('packages');

  const { loading } = useSelector((state) => state.ordersList);
  const dispatch = useDispatch();

  console.log(selectedPackage);
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <SidebarTitle>{t('are_you_sure')}</SidebarTitle>
          <SidebarSubtitle>{t('info_about_deletion')}</SidebarSubtitle>

          <Button
            m='20px auto 12px auto'
            onClick={() => {
              dispatch(
                cancelPackage(selectedPackage.orderId, setIsCancelModalOpen)
              );
            }}>
            {t('cancel_confirm')}
          </Button>
          <Button
            negative
            m='0 auto'
            onClick={() => setIsCancelModalOpen(false)}>
            {t('cancel_payment')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CancelPaymentPackage;
