import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from '../../components/common/Spinner';
import { formatUSD, USDT } from '../../helpers/helperFunctions';
import { H3, P2, P3 } from '../../styles/FontStyles';
import { Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { addPackage } from './packages.actions';
import { useTranslation } from 'react-i18next';

export const PaymentType = ({ selectedPackage, setStep }) => {
  const { t } = useTranslation('packages');
  const dispatch = useDispatch();
  const { loadingPackages } = useSelector((state) => state.packagesList);
  const { user } = useSelector((state) => state.userDetails);
  return (
    <>
      {loadingPackages ? (
        <Spinner />
      ) : (
        <div style={{ textAlign: 'center' }}>
          <P3 mb='8'> {t('payment_type.title')}</P3>

          <H3 mb='5' color={color.colors.secondary100}>
            {selectedPackage.imageTitle}
          </H3>

          <P2 mb='8' mt='20' bold>
            {formatUSD(selectedPackage?.imagePrice)} <span>{USDT}</span>
          </P2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 40
            }}>
            <Button
              maxWidth={'200px'}
              onClick={() => {
                dispatch(
                  addPackage(selectedPackage.packageId, 3, null, setStep)
                );
              }}
              disabled={
                user?.userTickets?.availableRewardPoints === 0 ||
                selectedPackage.imagePrice >
                  user?.userTickets?.availableRewardPoints
              }>
              Buy with USDT balance
            </Button>

            <P3 mb='20' mt='8'>
              {t('payment_type.available_club_balance')}:{' '}
              {formatUSD(user?.userTickets?.availableRewardPoints)} {USDT}
            </P3>

            <Button
              negative
              maxWidth={'200px'}
              onClick={() => {
                setStep(1);
              }}>
              {t('payment_type.buy_with_tether')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
