import styled from 'styled-components';
import { color } from '../../../styles/GlobalStyles';
import { Icon, IconSearch } from '../../../styles/Icons';
import { DisableSelection } from '../../../styles/stylesStyled';

export const Collapsable = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.expanded ? `${props.height}` : '0')};
  overflow: visible;
  transition: all 500ms;
  margin-bottom: ${(props) => (props.expanded ? '20px' : '0')};
`;

export const CollapsableAbsolute = styled.div`
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  max-height: ${(props) => (props.expanded ? `${props.height}` : '0')};
  overflow: hidden;
  transition: all 500ms;
  margin-bottom: ${(props) => (props.expanded ? '20px' : '0')};
  position: absolute;
  ${(props) => (props.right ? `right: 0;` : `left: 0;`)}
  top: 35px;
  z-index: 1;
`;

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  > * {
    ${DisableSelection}
  }
`;

export const FilterButton = styled.div`
  flex: none;
  margin-left: 12px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: ${(props) => props.theme.colors.primary900};

  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* ${(props) =>
    props.toggled
      ? 'border: 1px solid #FFFBFA;'
      : ' border: 1px solid #403c3b;'} */
`;

const ContainerInputStyled = styled.div`
  /* width: 100%; */
  flex-grow: 1;
  background: ${(props) => props.theme.colors.neutral50};
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  border-radius: 8px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  img {
    margin-right: 16px;
  }
`;

const InputStyled = styled.input`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  font-size: 14px;
  /* line-height: 20px; */
  color: ${(props) => props.theme.colors.neutral900};
  caret-color: ${(props) => props.theme.colors.secondary100};
`;

export const SearchInput = (props) => (
  <ContainerInputStyled>
    <Icon color={color.colors.neutral900} mr='16'>
      <IconSearch />
    </Icon>
    <InputStyled {...props} />
  </ContainerInputStyled>
);

export const SelectButton = styled.div`
  > * {
    ${DisableSelection}
  }
  div {
    background: ${(props) => (props.selected ? '#403C3B;' : '#2C2726')};
    border-radius: 100px;
    padding: 0 12px;
    height: 35px;
    display: flex;
    align-items: center;
  }
  background: ${(props) =>
    props.selected
      ? 'linear-gradient(160.43deg,#f58e35 1.87%,#f26c6b 50.93%,#ee288a 99.33%);'
      : ''};
  padding: 1px;

  color: ${(props) => (props.selected ? '#FEFEFE;' : '#C6BDBD')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 100px;
  margin-right: 8px;
  margin-bottom: 16px;

  /* margin-bottom: 20px; */
  cursor: pointer;
  img {
    height: 12px;
    margin-left: 8px;
    opacity: 0.7;
  }

  :hover {
    img {
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    margin-right: 8px;
  }
`;

export const DateInputContainer = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral900};
  width: calc(50% - 4px);
  background: ${(props) => props.theme.colors.neutral50};
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  overflow: visible;
  img {
    margin-right: 16px;
  }
  > * {
    ${DisableSelection}
  }

  .rdrDayNumber {
    color: ${(props) => props.theme.colors.secondary100} !important;
  }

  .rdrSelected {
    color: ${(props) => props.theme.colors.secondary100} !important;
  }

  .rdrDay.rdrDayToday .rdrDayNumber span::after {
    background: ${(props) => props.theme.colors.secondary100} !important;
  }

  .rdrDay {
    color: ${(props) => props.theme.colors.secondary100} !important;
  }

  .rdrDayStartPreview.rdrDayEndPreview {
    color: ${(props) => props.theme.colors.secondary100} !important;
  }

  .rdrMonth {
    width: 100% !important;
  }

  .rdrCalendarWrapper {
    box-shadow: ${(props) => props.theme.shadows.shadowXS};
    width: 100% !important;
    border-radius: 8px !important;
    /* background: ${(props) => props.theme.colors.secondary100}; */
  }
`;

export const DateInputLabelContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
`;

export const SortContainer = styled.div`
  cursor: pointer;
  width: 100%;
  background: ${(props) => props.theme.colors.neutral50};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  /* font-size: 18px;
  line-height: 26px; */
  color: ${(props) => props.theme.colors.neutral900};
  padding: 0 8px;
  img {
    width: 16px;
    margin-left: 8px;
  }
  > div {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
    position: relative;
  }
  > * {
    ${DisableSelection}
  }
`;

export const Earning = styled.div`
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  padding: 10px;
  background: ${({ theme }) => theme.colors.neutral50};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.shadowXS};
  margin-bottom: 15px;
`;

export const Txt1 = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #fefefe;
`;

export const Txt2 = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #c6bdbd;
`;

export const Txt3 = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #fefefe;
`;

export const Txt5 = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #b5a8a8;
  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const EarningIcon = styled.div`
  flex: none;
  margin-right: 12px;
  width: 48px;
  height: 48px;
  background: ${(props) => props.theme.colors.neutral100};
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
  }
`;

export const ClearAllFilters = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: ${(props) => props.theme.colors.neutral900};
  cursor: pointer;
  display: ${(props) => (props.show ? '' : 'none')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  margin-bottom: 16px;
`;

export const EarningDetailsComponent = styled.div`
  width: 100%;
  > div {
    padding: 12px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

export const Txt4 = styled.div`
  font-weight: ${(props) => (props.weight ? 'bold' : 'normal')};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral900};
`;
