import {
  CREATE_USER_WALLET_FAIL,
  CREATE_USER_WALLET_REQUEST,
  CREATE_USER_WALLET_SUCCESS,
  GET_USER_WALLETS_FAIL,
  GET_USER_WALLETS_REQUEST,
  GET_USER_WALLETS_SUCCESS
} from '../constants/userWalletsConstants';

import { CLEAR_ALERT } from '../constants/errorConstants';

export const userWallets = (state = { wallets: [] }, action) => {
  switch (action.type) {
    case GET_USER_WALLETS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_USER_WALLETS_SUCCESS:
      return {
        ...state,
        loading: false,
        wallets: action.payload
      };
    case GET_USER_WALLETS_FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case CREATE_USER_WALLET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_USER_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        wallets: [action.payload, ...state.wallets]
      };
    case CREATE_USER_WALLET_FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};
