import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addBirthday } from '../../actions/documentActions';
import icon from '../../assets/images/backoffice/kyc1.png';
import Alert from '../../components/common/Alert';
import { InputBackOffice } from '../../components/common/InputBackoffice';
import Spinner from '../../components/common/Spinner';
import { H2, H3, InputLabel, P1, P2 } from '../../styles/FontStyles';
import { Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { useWindowWide } from '../../utils/wideScreen';
import { Desktop, Mobile, TitleImage } from './KycStyled';

const KycBirth = ({ history }) => {
  const { t } = useTranslation('kyc.personal_details');

  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [error, setError] = useState('');

  const [country] = useState('');

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.userInfo);

  const onHandleSubmit = (e) => {
    e.preventDefault();

    if (!name) {
      setError(t('err_please_add_name'));
      setTimeout(() => {
        setError('');
      }, 3000);
    } else if (!lastname) {
      setError(t('err_please_add_last_name'));
      setTimeout(() => {
        setError('');
      }, 3000);
    } else if (!day) {
      setError(t('err_please_add_day_of_birth'));
      setTimeout(() => {
        setError('');
      }, 3000);
    } else if (!month || month.length < 1) {
      setError(t('err_please_add_month_of_birth'));
      setTimeout(() => {
        setError('');
      }, 3000);
    } else if (!year || year.length < 4) {
      setError(t('err_please_add_year_of_birth'));
      setTimeout(() => {
        setError('');
      }, 3000);
    } else {
      dispatch(addBirthday(day, month, year, country, name, lastname, history));
    }
  };

  const handleChangeDay = (e) => {
    if (e <= 31 && e.length <= 2) {
      setDay(e);
    }
  };

  const handleChangeMonth = (e) => {
    if (e <= 12 && e.length <= 2) {
      setMonth(e);
    }
  };

  const handleChangeYear = (e) => {
    const currentYear = moment().format('YYYY');

    if (e <= currentYear - 18 && e.length <= 4) {
      setYear(e);
    }
  };

  const wide = useWindowWide(1000);

  return (
    <>
      {loading ? (
        <Spinner fullScreen={true} />
      ) : (
        <div style={{ width: '100%', maxWidth: wide && 333 }}>
          <Desktop>
            <TitleImage>
              <img
                src={icon}
                alt='icon'
                style={{ marginBottom: 40, width: 101 }}
              />
            </TitleImage>
          </Desktop>
          {error && <Alert message={error} />}

          <Desktop>
            <H2 mb='12' color={color.kycTitleColor}>
              {t('title')}
            </H2>

            <P1
              mb='40'
              maxWidth='600px'
              maxWidthSM='1000px'
              color={color.kycSubTitleColor}>
              {t('subtitle')}
            </P1>
          </Desktop>
          <Mobile>
            <H3 mb='8' center color={color.kycTitleColor}>
              {t('title')}
            </H3>

            <P2 mb='32' center color={color.kycSubTitleColor}>
              {t('subtitle')}
            </P2>
          </Mobile>

          <form onSubmit={onHandleSubmit}>
            <InputLabel>{t('fullname_label')}</InputLabel>

            <InputBackOffice
              id={'name'}
              type={'text'}
              placeholder={t('name_placeholder')}
              name={'name'}
              value={name ? name.replace(/[^a-zA-Z šŠĐđČčćŽž ]/g, '') : ''}
              onChange={(e) => {
                setName(
                  e
                    .replace(/[^a-zA-Z šŠĐđČčćŽž ]/g, '')
                    .charAt(0)
                    .toUpperCase() + e.slice(1).toLowerCase()
                );
              }}
              label={'text'}
              style={{ textTransform: 'capitalize' }}
            />

            <InputBackOffice
              id={'lastname'}
              type={'text'}
              placeholder={t('lastname_placeholder')}
              name={'lastname'}
              value={
                lastname ? lastname.replace(/[^a-zA-ZšŠĐđČčćŽž ]/g, '') : ''
              }
              onChange={(e) => {
                setLastname(
                  e
                    .replace(/[^a-zA-ZšŠĐđČčćŽž ]/g, '')
                    .charAt(0)
                    .toUpperCase() + e.slice(1).toLowerCase()
                );
              }}
              label={'text'}
            />

            <InputLabel>{t('birth_date_label')}</InputLabel>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: 'calc(20% - 4px)' }}>
                <InputBackOffice
                  id={'day'}
                  type={'number'}
                  placeholder={t('day_placeholder')}
                  name={'day'}
                  value={day}
                  min='1'
                  max='31'
                  onChange={(e) => {
                    handleChangeDay(e);
                  }}
                  label={'Day'}
                  padding={5}
                  inputStyle={{ textAlign: 'center' }}
                />
              </div>
              <div style={{ width: 'calc(20% - 8px)' }}>
                <InputBackOffice
                  id={'month'}
                  type={'number'}
                  placeholder={t('month_placeholder')}
                  name={'month'}
                  value={month}
                  onChange={(e) => {
                    handleChangeMonth(e);
                  }}
                  label={'Month'}
                  padding={5}
                  inputStyle={{ textAlign: 'center' }}
                />
              </div>
              <div style={{ width: 'calc(60% - 4px)' }}>
                <InputBackOffice
                  id={'year'}
                  type={'number'}
                  placeholder={t('year_placeholder')}
                  name={'year'}
                  value={year}
                  onChange={(e) => {
                    handleChangeYear(e);
                  }}
                  label={'Year'}
                  inputStyle={{ textAlign: 'center' }}
                />
              </div>
            </div>

            <Desktop>
              <Button maxWidth='200px' type='submit'>
                {t('continue')}
              </Button>
            </Desktop>
            <Mobile>
              <Button type='submit'>{t('continue')}</Button>
            </Mobile>
          </form>
        </div>
      )}
    </>
  );
};

export default KycBirth;
