import styled from 'styled-components';

export const Title = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #ffffff;
  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const WithdrawButton = styled.div`
  background: #272322;
  border: 2px solid #b5a8a8;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(188, 131, 233, 0.2));
  border-radius: 8px;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fefefe;
  height: 40px;
  width: 160px;
  cursor: pointer;
  margin: 5px;

  ${(props) =>
    props.opacity &&
    `
  border: none;
  color: rgba(255, 255, 255, 0.2);
  filter: none;
  cursor: not-allowed
`};

  img {
    height: 12px;
    margin-right: 14px;
    ${(props) => props.opacity && `opacity: 0.2;`}
  }
`;

export const EarningBoxWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`;

export const BoxGradient = styled.div`
  background: linear-gradient(
    160.43deg,
    #f58e35 1.87%,
    #f26c6b 50.93%,
    #ee288a 99.33%
  ) !important;
`;

export const BoxTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #b5a8a8;
  letter-spacing: 0.08em;
  margin-bottom: 8px;
`;

export const BoxText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #fefefe;
  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
