import {
  REFERRAL_LINK_REQUEST,
  REFERRAL_LINK_SUCCESS,
  REFERRAL_LINK_FAIL,
  UPDATE_REFERRAL_LINK_REQUEST,
  UPDATE_REFERRAL_LINK_SUCCESS,
  UPDATE_REFERRAL_LINK_FAIL,
  REFERRAL_CODE_REQUEST,
  REFERRAL_CODE_SUCCESS,
  REFERRAL_CODE_FAIL
} from '../constants/referralConstants';

import { CLEAR_ALERT } from '../constants/errorConstants';

export const referralLink = (state = {}, action) => {
  switch (action.type) {
    case REFERRAL_LINK_REQUEST:
      return {
        loading: true
      };

    case REFERRAL_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        referralUrl: action.payload.referralUrl,
        code: action.payload.code
      };

    case REFERRAL_LINK_FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case UPDATE_REFERRAL_LINK_REQUEST:
      return {
        loading: true
      };

    case UPDATE_REFERRAL_LINK_SUCCESS:
      return {
        ...state,
        loading: false
        // referralUrl: action.payload.referralUrl,
        // code: action.payload.code
      };

    case UPDATE_REFERRAL_LINK_FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case REFERRAL_CODE_REQUEST:
      return {
        loading: true
      };

    case REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        loading: false
        // referralUrl: action.payload.referralUrl,
        // code: action.payload.code
      };

    case REFERRAL_CODE_FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
};
