import styled from 'styled-components';

export const SideBarModalOverlay = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  background: rgba(28, 23, 22, 0.1);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99997;
  transition: 0.3s all ease;
  @media (max-width: 800px) {
    z-index: 9999;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  > * {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const SideBarModal = styled.div`
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: ${(props) => (props.isOpen ? '0' : '-50vw')};
  left: ${(props) => (props.isOpen ? '' : '100%')};
  position: ${(props) => (props.relative ? 'relative' : '')};

  padding: 120px 35px 50px 35px;
  background: ${(props) => props.theme.appLayout.sidebarModalBackground};
  z-index: 99998;
  transition: 0.2s all ease;
  min-width: 326px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  > * {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  ${(props) =>
    props.sidebarWidth
      ? `
 width: ${props.sidebarWidth};
`
      : ``}

  @media (max-width: 800px) {
    left: ${(props) => (props.isOpen ? '0' : '100%')};
    width: auto;
    min-width: auto;
    padding: 20px;
    top: 70px;
    padding: 80px 20px 80px 20px;
    z-index: 9999;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 57px;
  left: 35px;
  /* width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border: 1.5px solid rgba(255, 255, 255, 0.06);
  border-radius: 8px; */
  /* cursor: pointer; */
  @media (max-width: 800px) {
    top: 25px;
    left: 20px;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: ${(props) => props.theme.appLayout.sidebarModalTitleColor};
  margin-bottom: 40px;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  @media (max-width: 800px) {
    top: 20px;
    font-size: 16px !important;
  }
`;
