import { tryExecuteSimpleAction } from '../../actions/common';

import {
  ADD_PACKAGE,
  CANCEL_PACKAGES,
  GET_ORDERS,
  SET_TAB,
  UPGRADE_PACKAGES
} from './packages.constants';

import {
  addPackageAsync,
  cancelPackageAsync,
  getPackageAsync,
  upgradePackageAsync
} from './packages.request';

export const getOrders = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_ORDERS.REQUEST,
    async () => {
      const { data } = await getPackageAsync();

      dispatch({
        type: GET_ORDERS.SUCCESS,
        payload: data.data.orders.sort(function (a, b) {
          return new Date(b.dateCreated) - new Date(a.dateCreated);
        })
      });
    },
    GET_ORDERS.FAIL
  );
};

export const addPackage =
  (id, currencyType, networkType, setStep) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      ADD_PACKAGE.REQUEST,
      async () => {
        const { data } = await addPackageAsync(id, currencyType, networkType);

        dispatch({
          type: ADD_PACKAGE.SUCCESS,
          payload: data.data
        });

        setStep(4);
      },
      ADD_PACKAGE.FAIL
    );
  };

export const upgradePackage = (id) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    UPGRADE_PACKAGES.REQUEST,
    async () => {
      await upgradePackageAsync(id);

      dispatch({
        type: UPGRADE_PACKAGES.SUCCESS
      });
    },
    UPGRADE_PACKAGES.FAIL
  );
};

export const cancelPackage =
  (userPackageId, setIsCancelModalOpen) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CANCEL_PACKAGES.REQUEST,
      async () => {
        await cancelPackageAsync(userPackageId);

        dispatch({
          type: CANCEL_PACKAGES.SUCCESS,
          payload: userPackageId
        });

        setIsCancelModalOpen(false);
      },
      CANCEL_PACKAGES.FAIL
    );
  };

export const setSelectedTab = (tab) => (dispatch) => {
  dispatch({
    type: SET_TAB,
    payload: tab
  });
};
