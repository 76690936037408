import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { USDT, formatUSD } from '../../../helpers/helperFunctions';
import { P1, P2, P3, H2, CaptionText } from '../../../styles/FontStyles';
import { Button, Flexed } from '../../../styles/GlobalComponents';

import { addHub } from '../../../views/Marketplace/Marketplace.actions';
import { Checkbox } from '../Checkbox/Checkbox';
import { Hub } from '../Hub/Hub';

export const ClassicalSelectStakingHubNFT = ({
  setStep,
  selectedHub,
  setSelectedHub
}) => {
  const { hubs } = useSelector((state) => state.hubs);
  const { user } = useSelector((state) => state.userInfo);
  const { t } = useTranslation('marketplace');

  const dispatch = useDispatch();

  const {
    id,
    image,
    title,
    price,
    weeklyCap,
    packageId,
    calculator,
    limitedQuantity
  } = hubs.find((i) => i.id === selectedHub);

  const findYieldStakingHub = () => {
    if (id < 32) {
      return 32;
    } else if (id >= 32 && id < 33) {
      return 33;
    } else {
      return 34;
    }
  };

  const upgradeToHigherStackingHub = () => {
    const { id, image, title, price, weeklyCap, calculator } = hubs.find(
      (i) => i.id === findYieldStakingHub()
    );
    return (
      <Hub
        key={id}
        condensed
        image={image}
        title={title}
        price={price}
        limitedQuantity={limitedQuantity}
        button={
          <Button
            w='150px'
            wSM='100%'
            onClick={() => setSelectedHub(id)}
            negative
            condensed>
            Select this HUB
          </Button>
        }
        weeklyCap={weeklyCap}
        info={
          <>
            {calculator && (
              <Flexed alignEnd end>
                <H2 bold mr='16' alignEnd>
                  {calculator}
                </H2>
                <CaptionText bold right mb='4'>
                  Reward
                  <br /> Multiplicator
                </CaptionText>
              </Flexed>
            )}
          </>
        }
        stakingHubDetails={
          <>
            <Flexed mb='12' center gap='16px'>
              <Checkbox checked />
              <P2 bold>
                <span>{weeklyCap}</span> {t('binary_weekly_cap')}
              </P2>
            </Flexed>
          </>
        }
      />
    );
  };

  return (
    <div>
      <P1 bold mb={20}>
        {t('selected_staking_hub_nft')}:
      </P1>

      <Hub
        key={id}
        condensed
        image={image}
        title={title}
        price={price}
        weeklyCap={weeklyCap}
        limitedQuantity={limitedQuantity}
        info={
          <>
            {calculator && (
              <Flexed alignEnd end>
                <H2 bold mr='16' alignEnd>
                  {calculator}
                </H2>
                <CaptionText bold right mb='4'>
                  Reward
                  <br /> Multiplicator
                </CaptionText>
              </Flexed>
            )}
          </>
        }
        stakingHubDetails={
          <>
            <>
              <Flexed mb='12' center gap='16px'>
                <Checkbox checked />
                <P2 bold>
                  <span>{weeklyCap}</span> {t('binary_weekly_cap')}
                </P2>
              </Flexed>
            </>
          </>
        }
      />

      <P2 mb={20} mt={40}>
        {t('technology_of_the_future')}
      </P2>

      <P3 mb='10' mt='8' center>
        {USDT} {t('backoffice_balance')}:{' '}
        <b>
          {formatUSD(user?.userTickets?.availableRewardPoints)} {USDT}
        </b>
      </P3>

      <Button
        mb={20}
        onClick={() => {
          dispatch(addHub(packageId, 3, null, null, 3, setStep));
        }}
        disabled={
          user?.userTickets?.availableRewardPoints === 0 ||
          price > user?.userTickets?.availableRewardPoints
        }>
        {t('buy_with')} {USDT} {t('balance')}
      </Button>

      <Button negative mb={20} onClick={() => setStep(2)}>
        {t('buy_with_tether')} ({USDT})
      </Button>

      {selectedHub !== 34 ||
        (selectedHub !== 41 && (
          <>
            <P1 bold mt={40} mb={20}>
              {t('upgrade_to_higher')}:
            </P1>
            {upgradeToHigherStackingHub()}
          </>
        ))}
    </div>
  );
};
