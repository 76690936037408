import axios from 'axios';
import moment from 'moment';
import {
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAIL,
  ADD_UTILITY_BILL_SUCCESS,
  ADD_UTILITY_BILL_FAIL,
  ADD_DOCUMENTS_IMAGE_FAIL,
  ADD_DOCUMENTS_IMAGE_SUCCESS,
  ADD_DOCUMENTS_IMAGE_REQUEST,
  ADD_SELFIE_REQUEST,
  ADD_SELFIE_SUCCESS,
  ADD_SELFIE_FAIL,
  ADD_DOCUMENT_REQUEST,
  ADD_UTILITY_BILL_REQUEST,
  ADD_BIRTHDAY_REQUEST,
  ADD_BIRTHDAY_SUCCESS,
  ADD_BIRTHDAY_FAIL
} from '../constants/documentConstants';

import { CLEAR_ALERT } from '../constants/errorConstants';
import { setUserPrizerToLS } from '../helpers/localstorage-helper';

const API_URL = process.env.REACT_APP_API_URL;

export const addDocument =
  (type, documentHolder, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_DOCUMENT_REQUEST
      });

      const { userInfo } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token.accessToken}`
        }
      };

      const { data } = await axios.post(
        `${API_URL}/user/document`,
        {
          type,
          documentHolder
        },
        config
      );

      dispatch({
        type: ADD_DOCUMENT_SUCCESS,
        payload: data.data.kycStatus
      });

      // dispatch(addDocumentsImage(formData, history));

      const user = {
        ...userInfo,
        kycStatus: data.data.kycStatus
      };

      setUserPrizerToLS(JSON.stringify(user));
      history.push(`/document-photos/${type}`);
    } catch (error) {
      dispatch({
        type: ADD_DOCUMENT_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const addDocumentsImage =
  (formData, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_DOCUMENTS_IMAGE_REQUEST
      });

      const { userInfo } = getState();

      const config = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token.accessToken}`
        }
      };

      const { data } = await axios.post(
        `${API_URL}/user/document/image`,
        formData,
        config
      );

      dispatch({
        type: ADD_DOCUMENTS_IMAGE_SUCCESS,
        payload: data
      });

      const user = {
        ...userInfo,
        kycStatus: data.data.kycStatus
      };

      setUserPrizerToLS(JSON.stringify(user));

      history.push('/selfie-time');
    } catch (error) {
      dispatch({
        type: ADD_DOCUMENTS_IMAGE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const addUtilityImage = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_UTILITY_BILL_REQUEST
    });

    const { userInfo } = getState();

    const config = {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    const { data } = await axios.post(
      `${API_URL}/user/utility-bill/image`,
      formData,
      config
    );

    dispatch({
      type: ADD_UTILITY_BILL_SUCCESS,
      payload: data
    });

    const user = {
      ...userInfo,
      kycStatus: data.data.kycStatus
    };

    setUserPrizerToLS(JSON.stringify(user));
  } catch (error) {
    dispatch({
      type: ADD_UTILITY_BILL_FAIL,
      payload: error.response && error.response.data
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: ''
      });
    }, 3000);
  }
};

export const addSelfieImage =
  (formData, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_SELFIE_REQUEST
      });

      const { userInfo } = getState();

      const config = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token.accessToken}`
        }
      };

      const { data } = await axios.post(
        `${API_URL}/user/selfie/image`,
        formData,
        config
      );

      dispatch({
        type: ADD_SELFIE_SUCCESS,
        payload: data
      });

      history.push('/kyc-congratulations');

      const user = {
        ...userInfo,
        kycStatus: data.data.kycStatus
      };

      setUserPrizerToLS(JSON.stringify(user));
    } catch (error) {
      dispatch({
        type: ADD_SELFIE_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const addBirthday =
  (day, month, year, country, name, surname, history) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_BIRTHDAY_REQUEST
      });

      let newDate = moment(`'${year}-${month}-${day}'`, 'YYYY-MM-DD');

      const date = newDate.format();

      const convertedDate = moment().format(date);

      const { userInfo } = getState();

      const config = {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${userInfo.token.accessToken}`
        }
      };

      const { data } = await axios.post(
        `${API_URL}/user/kyc/personal-details`,
        {
          dateOfBirth: convertedDate,
          country: 'China',
          name,
          surname
        },
        config
      );

      dispatch({
        type: ADD_BIRTHDAY_SUCCESS,
        payload: data.data.kycStatus
      });

      const user = {
        ...userInfo,
        kycStatus: data.data.kycStatus
      };

      setUserPrizerToLS(JSON.stringify(user));

      history.push('/document-details');
    } catch (error) {
      dispatch({
        type: ADD_BIRTHDAY_FAIL,
        payload: error.response && error.response.data
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const addTicketPrizer = (type, payload) => (dispatch) => {
  dispatch({
    type,
    payload: payload
  });
};
