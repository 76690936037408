import { tryExecuteSimpleAction } from '../../actions/common';

import {
  ADD_PACKAGE,
  GET_WAITING_PACKAGE,
  SET_TAB,
  UPGRADE_PACKAGES
} from './packages.constants';

import {
  addPackageAsync,
  getPackageAsync,
  getPackagesAsync,
  upgradePackageAsync
} from './packages.request';

import { Packages } from '../../PackagesData';

export const getPackage = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_WAITING_PACKAGE.REQUEST,
    async () => {
      const { data: userPackages } = await getPackageAsync();
      const { data } = await getPackagesAsync();

      let packages = data.data.packages;

      const newPackages = packages.map((pack) => {
        let item = Packages.find((item2) => item2.packageId === pack.packageId);

        return item
          ? {
              ...item,
              imageTitle: pack?.name,
              imagePrice: pack?.price,
              collapse: false,
              directBonusPercent: pack?.directBonusPercent,
              donationPoints: pack?.donationPoints,
              shoppingPoints: pack?.shoppingPoints,
              teamBonusPercent: pack?.teamBonusPercent,
              order: pack?.order,
              walletAddress: pack?.walletAddress
            }
          : pack;
      });

      dispatch({
        type: GET_WAITING_PACKAGE.SUCCESS,
        payload: {
          newPackages,
          walletAddress: data.data.walletAddress,
          userPackages: userPackages.data.userPackages
        }
      });
    },
    GET_WAITING_PACKAGE.FAIL
  );
};

export const addPackage =
  (id, currencyType, networkType, setStep) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      ADD_PACKAGE.REQUEST,
      async () => {
        const { data } = await addPackageAsync(id, currencyType, networkType);

        dispatch({
          type: ADD_PACKAGE.SUCCESS,
          payload: {
            userPackage: data.data.userPackage,
            userTickets: data.data.userTickets,
            currencyType
          }
        });

        setStep(4);
      },
      ADD_PACKAGE.FAIL
    );
  };

export const upgradePackage = (id) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    UPGRADE_PACKAGES.REQUEST,
    async () => {
      await upgradePackageAsync(id);

      dispatch({
        type: UPGRADE_PACKAGES.SUCCESS
      });
    },
    UPGRADE_PACKAGES.FAIL
  );
};

export const setSelectedTab = (tab) => (dispatch) => {
  dispatch({
    type: SET_TAB,
    payload: tab
  });
};
