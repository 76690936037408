const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const GET_PORTFOLIO = {
  REQUEST: getRequestConst('GET_PORTFOLIO'),
  SUCCESS: getSuccessConst('GET_PORTFOLIO'),
  FAIL: getFailConst('GET_PORTFOLIO')
};

export const LOCK_AUTOSTAKE = {
  REQUEST: getRequestConst('LOCK_AUTOSTAKE'),
  SUCCESS: getSuccessConst('LOCK_AUTOSTAKE'),
  FAIL: getFailConst('LOCK_AUTOSTAKE')
};

export const CHANGE_AUTOSTAKE = {
  REQUEST: getRequestConst('CHANGE_AUTOSTAKE'),
  SUCCESS: getSuccessConst('CHANGE_AUTOSTAKE'),
  FAIL: getFailConst('CHANGE_AUTOSTAKE')
};

export const GET_STAKING_WITHDRAWAL_INFO = {
  REQUEST: getRequestConst('GET_STAKING_WITHDRAWAL_INFO'),
  SUCCESS: getSuccessConst('GET_STAKING_WITHDRAWAL_INFO'),
  FAIL: getFailConst('GET_STAKING_WITHDRAWAL_INFO')
};

export const BURN_ULX = {
  REQUEST: getRequestConst('BURN_ULX'),
  SUCCESS: getSuccessConst('BURN_ULX'),
  FAIL: getFailConst('BURN_ULX')
};

export const SWITCH_AUTOSTAKE = 'SWITCH_AUTOSTAKE';
