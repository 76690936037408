import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { FlexBetween, HubLengthMessage } from '../../styles/stylesStyled';

import {
  InvoiceHeader,
  Invoices,
  InvoicesMobile
} from '../Accounting/AccountingStyled';

import { SidebarModal } from '../../components/common/Sidebar/Sidebar';

import { useTranslation } from 'react-i18next';
import { Desktop, Mobile } from '../../App';
import { formatUSDNoDecimals, USDT } from '../../helpers/helperFunctions';
import CancelPaymentPackage from './CancelPaymentPackage';
import WaitingForPaymentPackage from './WaitingForPaymentPackage';

import { AnimatedDashboard } from '../../components/animation/AnimatedDashboard';
import { AnimatedItem } from '../../components/animation/AnimatedItem';
import Spinner from '../../components/common/Spinner';
import { PageTitle } from '../../styles/GlobalComponents';
import { getOrders, setSelectedTab } from './packages.actions';

export const Orders = () => {
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const { t } = useTranslation('packages');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  const { isLoading, orders } = useSelector((state) => state.ordersList);

  const orderStatus = (inv) => {
    switch (inv.status) {
      case 1:
        return (
          <>
            <div>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsSideModalOpen(!isSideModalOpen);
                }}>
                {t('my_hubs_part.waiting')}
              </span>
            </div>

            <div style={{ marginTop: 8 }}>
              {inv.type !== 3 && (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setIsCancelModalOpen(!isCancelModalOpen);
                  }}>
                  {t('my_hubs_part.cancel')}
                </span>
              )}
            </div>
          </>
        );

      case 2:
        return <> {t('my_hubs_part.paid')}</>;
      case 3:
        return 'Canceled by admin';
      case 4:
        return 'Canceled';
      default:
    }
  };

  if (isLoading) {
    return <Spinner fullScreen dark />;
  }

  return (
    <AnimatedDashboard zoom>
      <PageTitle>Orders</PageTitle>
      <Desktop>
        <InvoiceHeader>
          <div style={{ width: '20%' }}>{t('my_hubs_part.date')}</div>
          <div style={{ width: '20%' }}>{t('my_hubs_part.hub_name')}</div>
          <div style={{ width: '20%' }}>{t('my_hubs_part.order_id')}</div>
          <div style={{ width: '20%' }}>{t('my_hubs_part.value')}</div>
          <div
            style={{
              width: '20%',
              textAlign: 'right'
            }}>
            {t('my_hubs_part.status')}
          </div>
        </InvoiceHeader>
      </Desktop>
      {orders?.length === 0 ? (
        <HubLengthMessage
          onClick={() => dispatch(setSelectedTab('hub'))}
          style={{ cursor: 'pointer' }}>
          {t('my_hubs_part.no_hubs')}
        </HubLengthMessage>
      ) : (
        orders?.map((inv, i) => {
          return (
            <AnimatedItem key={i}>
              <Desktop>
                <Invoices>
                  <div style={{ width: '20%' }}>
                    {moment.utc(inv?.dateCreated).format('DD/MM/YY')}
                  </div>
                  <div style={{ width: '20%' }}>
                    {!inv?.name ? 'Free Trial Backoffice' : inv?.name}
                  </div>
                  <div style={{ width: '20%' }}>#{inv.orderId}</div>
                  <div style={{ width: '20%' }}>
                    {formatUSDNoDecimals(inv.price)} {USDT}
                  </div>
                  <div
                    style={{
                      width: '20%',
                      textAlign: 'right'
                    }}
                    onClick={() => setSelectedPackage(inv)}>
                    {orderStatus(inv)}
                  </div>
                </Invoices>
              </Desktop>
              <Mobile>
                <InvoicesMobile style={{ cursor: 'pointer' }}>
                  <FlexBetween>
                    <div
                      style={{
                        fontWeight: '500',
                        lineHeight: '20px'
                      }}>
                      {!inv?.name ? (
                        'Free Trial Backoffice'
                      ) : (
                        <>
                          {inv.name.includes('+') ? (
                            <>
                              {inv.name.substring(0, inv.name.indexOf('+') - 1)}
                              <br />
                              {inv.name.substring(inv.name.indexOf('+') - 1)}
                            </>
                          ) : (
                            inv.name
                          )}
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        fontWeight: '500',
                        lineHeight: '20px',
                        fontSize: 18
                      }}>
                      {formatUSDNoDecimals(inv.price)} USDT
                    </div>
                  </FlexBetween>
                  <FlexBetween>
                    <div>
                      {moment.utc(inv?.dateCreated).format('DD/MM/YY')}{' '}
                      <span style={{ marginLeft: '5px' }}>
                        {t('my_hubs_part.order_id')} {inv?.orderId}
                      </span>
                    </div>
                    <div
                      style={{
                        color: '#B5A8A8',
                        lineHeight: '18px',
                        fontSize: 12
                      }}
                      onClick={() => setSelectedPackage(inv)}>
                      {orderStatus(inv)}
                    </div>
                  </FlexBetween>
                </InvoicesMobile>
              </Mobile>
            </AnimatedItem>
          );
        })
      )}

      <SidebarModal
        isOpen={isSideModalOpen}
        setOpen={setIsSideModalOpen}
        title={'Payment instructions'}
        sidebarWidth={'511px'}>
        <WaitingForPaymentPackage
          selectedPackage={selectedPackage}
          setIsSideModalOpen={setIsSideModalOpen}
        />
      </SidebarModal>

      <SidebarModal
        isOpen={isCancelModalOpen}
        setOpen={setIsCancelModalOpen}
        title={'Cancel Order'}
        sidebarWidth={'511px'}
        isCenteredContent={true}>
        <CancelPaymentPackage
          selectedPackage={selectedPackage}
          setIsCancelModalOpen={setIsCancelModalOpen}
        />
      </SidebarModal>
    </AnimatedDashboard>
  );
};
