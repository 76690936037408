import React from 'react';
import { Link } from 'react-router-dom';
import { Desktop, Mobile } from '../../App';
import { DASHBOARD } from '../../routes/routes';
import { H1, P1, H2, P2 } from '../../styles/FontStyles';
import { Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { View } from './PageErrorStyled';

const PageError = () => {
  return (
    <View>
      <Desktop>
        <H1 mb='20' center color={color.colors.neutral900}>
          Sorry, we couldn’t find that page
        </H1>
        <P1 mb='50' center color={color.colors.neutral900}>
          Try returning back to dashboard
        </P1>
      </Desktop>

      <Mobile>
        <H2 mb='20' center color={color.colors.neutral900}>
          Sorry, we couldn’t find that page
        </H2>
        <P2 mb='50' center color={color.colors.neutral900}>
          Try returning back to dashboard
        </P2>
      </Mobile>

      <Link to={DASHBOARD}>
        <Button maxWidth='300px' w='100%'>
          Go back to dashboard
        </Button>
      </Link>
    </View>
  );
};

export default PageError;
