import styled from 'styled-components';

const View = styled.div`
  margin: 20px 0;
`;

const ButtonBox = styled.div`
  background: #fefefe;
  border: 1px solid #e1dbdb;
  border-radius: 8px;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin: 0 0 20px 0;
  img {
    cursor: pointer;
  }
`;

const BBText = styled.div`
  font-size: 17px;
  line-height: 20px;
  color: #2d2d2d;
  opacity: ${(props) => (props.opacity ? '1' : '0.5')};
`;

const CountryText = styled.div`
  font-size: 17px;
  line-height: 20px;
  color: #2d2d2d;
  font-weight: ${(props) => (props.checked ? '500' : '')};
`;

const CountriesContainer = styled.div`
  height: 100%;
  width: 311px;
  margin: 0 auto;
`;

const Country = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: center;
  img {
    margin-right: 20px;
  }
`;

const SInput = styled.input`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  font-size: 15px;
  color: #2d2d2d;
  caret-color: #fa8d11;
`;

const SInputContainer = styled.div`
  background: rgba(45, 45, 45, 0.05);
  border-radius: 13px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0 0 20px 0;
  img {
    margin-right: 10px;
  }
`;

export {
  View,
  ButtonBox,
  BBText,
  CountryText,
  Country,
  CountriesContainer,
  SInput,
  SInputContainer
};
