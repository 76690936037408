import {
  GET_CONTENT_BLOCK_FAIL,
  GET_CONTENT_BLOCK_SUCCESS,
  GET_CONTENT_BLOCK_REQUEST
} from '../constants/contentBlocksConstants';

import { CLEAR_ALERT } from '../constants/errorConstants';

export const contentBlock = (state = { contentBlock: [] }, action) => {
  switch (action.type) {
    case GET_CONTENT_BLOCK_REQUEST:
      return {
        loading: true,
        contentBlock: []
      };

    case GET_CONTENT_BLOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        contentBlock: action.payload
      };

    case GET_CONTENT_BLOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};
