import React from 'react';
// styles
import { AnimatedItemStyled } from './AnimatedDashboardStyled';

export const AnimatedItem = ({ children, index }) => {
  return (
    <AnimatedItemStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.2,
        delay: index * 0.02
      }}
      key={index}>
      {children}
    </AnimatedItemStyled>
  );
};
