import axios from 'axios';
import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';

import { useDispatch, useSelector } from 'react-redux';

import Spinner from '../../components/common/Spinner';
import {
  ErrorToast,
  SuccessToast
} from '../../components/common/Toasts/Toasts';

import { Flexed, SecurityCodeWrapper } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { AuthButton } from '../../styles/stylesStyled';

import { confirm2FAEmail } from './settings.actions';

import { P2, P3 } from '../../styles/FontStyles';

const API_URL = process.env.REACT_APP_API_URL;

export const TwoFAEmail = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [loadingCode, setLoadingCode] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const { loadingVerification } = useSelector((state) => state.settings);
  const { user, token } = useSelector((state) => state.userInfo);

  const sendCode = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.accessToken}`
      }
    };

    try {
      setLoadingCode(true);
      await axios.post(
        `${API_URL}/user/settings/security/signin/email-verification/enable/code`,
        config
      );

      SuccessToast('Code sent successfully');
      setLoadingCode(false);
      setIsCodeSent(true);
    } catch (error) {
      setLoadingCode(false);
      ErrorToast(error.response.data.error);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();

    dispatch(confirm2FAEmail(code, setIsOpen));
  };

  return (
    <>
      {loadingCode || loadingVerification ? (
        <Spinner dark />
      ) : (
        <>
          <form onSubmit={onHandleSubmit}>
            <Flexed between>
              <P2 bold color={color.colors.neutral700}>
                Email verification code
              </P2>
              <P2
                bold
                style={{ cursor: 'pointer' }}
                color={color.colors.primary900}
                onClick={() => sendCode()}>
                Send Code
              </P2>
            </Flexed>
            <SecurityCodeWrapper>
              <ReactCodeInput
                fields={7}
                value={code}
                onChange={setCode}
                type={'number'}
              />
            </SecurityCodeWrapper>
            <P3 color={color.colors.neutral500} mb='40'>
              {isCodeSent && `Enter the 7 digit code sent to ${user?.email}`}
            </P3>

            <AuthButton
              opacity={code !== ''}
              disabled={code === '' ? true : false}>
              Enable
            </AuthButton>
          </form>
        </>
      )}
    </>
  );
};
