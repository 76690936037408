import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { approvalKycTerms } from '../../actions/authActions';
import { getContentBlock } from '../../actions/contentBlockActions';
import Spinner from '../../components/common/Spinner';
import { PRIVACY_POLICY, TERMS_OF_USE } from '../../routes/routes';
import { H4, P2 } from '../../styles/FontStyles';
import { Button, Hr } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { useWindowWide } from '../../utils/wideScreen';
import { Agreement, AgreementCheckbox, Desktop, Mobile } from './KycStyled';

const KycAgreement = ({ history }) => {
  const { t } = useTranslation('kyc.agreement');

  const dispatch = useDispatch();
  const { contentBlock, loading } = useSelector((state) => state.contentBlock);

  const [checkbox, setCheckbox] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);

  useEffect(() => {
    dispatch(getContentBlock(201));
  }, [dispatch]);

  const wide = useWindowWide(1000);
  const wide2 = useWindowWide(1800);

  if (loading) return <Spinner fullScreen={true} />;

  return (
    <div
      style={{
        paddingBottom: !wide ? '150px' : '0',
        maxHeight: '90vh',
        overflow: 'scroll'
      }}
      className='noScrollBar'>
      <H4 center mb='40' color={color.kycTitleColor}>
        {t('title')}
      </H4>
      <Hr />
      <div style={{ maxWidth: wide2 ? 1200 : 700 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: contentBlock && contentBlock.content
          }}
        />
      </div>
      <Hr />

      <div style={{ maxWidth: wide2 ? 1000 : 700 }}>
        <Agreement>
          <AgreementCheckbox
            type='checkbox'
            checked={checkbox}
            onChange={() => setCheckbox(!checkbox)}
          />
          <P2 color={color.kycFontColor}>{t('checkbox1')}</P2>
        </Agreement>
        <Agreement>
          <AgreementCheckbox
            type='checkbox'
            checked={checkbox2}
            onChange={() => setCheckbox2(!checkbox2)}
          />

          <P2 color={color.kycFontColor}>
            {t('checkbox2')}{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href={PRIVACY_POLICY}
              target='_blank'
              rel='noreferrer'>
              {t('privacy_policy')}
            </a>{' '}
            {t('and')}{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href={TERMS_OF_USE}
              target='_blank'
              rel='noreferrer'>
              {t('terms_and_condition')}
            </a>
            .
          </P2>
        </Agreement>
        <Agreement>
          <AgreementCheckbox
            type='checkbox'
            checked={checkbox3}
            onChange={() => setCheckbox3(!checkbox3)}
          />

          <P2 color={color.kycFontColor}>{t('checkbox3')}</P2>
        </Agreement>
      </div>
      <Desktop>
        <Button
          maxWidth='200px'
          disabled={!checkbox || !checkbox2 || !checkbox3}
          onClick={() =>
            checkbox &&
            checkbox2 &&
            checkbox3 &&
            dispatch(approvalKycTerms(moment().format(), history))
          }>
          {t('agree')}
        </Button>
      </Desktop>
      <Mobile>
        <Button
          disabled={!checkbox || !checkbox2 || !checkbox3}
          onClick={() =>
            checkbox &&
            checkbox2 &&
            checkbox3 &&
            dispatch(approvalKycTerms(moment().format(), history))
          }>
          {t('agree')}
        </Button>
      </Mobile>
    </div>
  );
};

export default KycAgreement;
