import styled from 'styled-components';

const View = styled.div`
  margin: 20px 0;
  /* min-height: 640px; */
  padding-bottom: 70px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    max-width: 1500px;
    padding: 0 100px;
  }
  @media only screen and (max-width: 1500px) and (min-width: 800px) {
    zoom: 80%;
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  //align-items: center;
  flex-direction: column;
  color: #fff;
`;

const PackagesContainer = styled.div`
  display: flex;
  width: 100%;
  /* min-height: calc(100vh - 90px - 100px); */
`;

const MyPackage = styled.h1`
  font-size: 36px;
  line-height: 42px;
  margin: 0 0 20px 0;
  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Package = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};

  > div {
    width: 100%;
    height: 83px;
    background-color: ${(props) =>
      props.current
        ? props.theme.colors.primary900
        : props.theme.colors.neutral50};
    border-radius: 8px;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  @media (max-width: 800px) {
    > div {
      height: 66px;
      padding: 11px 16px;
    }
  }
`;

export const HalfContainer = styled.div`
  width: calc(50% - 81px);
  @media (max-width: 1200px) {
    width: calc(50% - 51px);
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const PackageTitle = styled.h3`
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 8px;
  color: ${({ theme, current }) =>
    current ? theme.colors.neutral50 : theme.colors.neutral900};
  @media (max-width: 800px) {
    font-size: 16px;
    margin-bottom: 4px;
  }
`;

const PackagePrice = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme, current }) =>
    current ? theme.colors.neutral50 : theme.colors.neutral500};
`;

const PackageButton = styled.button`
  height: 50px;
  /* width: 100%; */
  width: 200px;

  @media (max-width: 1300px) {
    width: auto;
  }

  background: ${(props) =>
    props.disabled || props.waitingForPaymentPackageId
      ? props.theme.buttonDisabledBackground
      : props.theme.colors.primary900};
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  color: ${(props) =>
    props.disabled
      ? props.theme.buttonDisabledFontColor
      : props.theme.colors.neutral50};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  @media (max-width: 800px) {
    width: auto;
    font-size: 12px;
    font-weight: 500;
    height: 34px;
  }
`;

const Border = styled.div`
  flex: none;
  width: 1px;
  margin: 0 80px;
  background-image: linear-gradient(#484b51, #949494, #484b51);
  @media (max-width: 1200px) {
    margin: 0 50px;
  }
`;

const PackageContent = styled.div`
  width: 544px;
  padding-bottom: 50px;
`;

const PackageBenefit = styled.div`
  margin-top: 24px;
`;

const PackageSubtitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 14px;
  color: ${(props) => props.theme.colors.neutral900};
`;

const PackagesWrapper = styled.div`
  max-width: 1266px;
  /* margin-bottom: 100px; */
`;

const CurrentlyText = styled.div`
  font-size: 14px;
  background: -webkit-linear-gradient(#f58e35, #f26c6b, #ee288a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  width: 200px;
  text-align: center;
  @media (max-width: 800px) {
    width: auto;
  }
`;

const TextSmall = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #c6bdbd;
`;

const Notice = styled.div`
  width: 100%;
  background: #403c3b;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 20px;
`;

export const Collapsable = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  max-height: ${(props) => (props.expanded ? '2000px' : '0')};
  overflow: scroll;
  transition: all 500ms;
  margin-bottom: ${(props) => (props.expanded ? '10px' : '0')};
`;

const SectionTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
`;

const SectionText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin: 12px 0;
`;

const Separator = styled.div`
  width: 100%;
  padding: 0.5px 0;
  background: linear-gradient(to right, #484b51, #949494, #484b51);
  margin: 20px 0;
`;

const Tabs = styled.div`
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #403c3b;
  // border: 1px solid #e1dbdb;
  border-radius: 8px;
  margin-bottom: 40px;
  overflow-x: auto;
`;

const TabButton = styled.div`
  width: 100%;
  justify-content: center;
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  border: ${(props) =>
    props.selected ? '2px solid rgba(255, 255, 255, 0.3)' : 'none'};
  box-sizing: border-box;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  background: ${(props) => (props.selected ? `#1C1716` : 'none')};

  @media (max-width: 360px) {
    font-size: 14px;
  }
`;

const SelectedUSDT = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;

  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.color
      ? props.theme.colors.neutral500
      : props.theme.colors.neutral900};
  border-radius: 8px;
  padding: 0 16px;
  background: ${(props) => props.theme.colors.neutral100};
`;

const Select = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 1px;
  /* background: ${(props) =>
    props.isOpen
      ? 'linear-gradient(160.43deg,#f58e35 1.87%,#f26c6b 50.93%,#ee288a 99.33%);'
      : '#2C2626'}; */
  border-radius: 8px;
  position: relative;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.neutral900};
    border-radius: 8px;
    padding: 0 16px;
    background: ${(props) => props.theme.colors.neutral100};
    > img {
      margin-left: 16px;
      transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : '')};
      transition: all 500ms;
    }
  }
`;

const SelectDropDown = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral900};
  border-radius: 8px;
  padding: 0 18px;
  background: ${(props) => props.theme.colors.neutral100};
  div {
    height: 56px;
  }
`;

const CollapsableAbsolute = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  max-height: ${(props) => (props.isOpen ? `${props.height}` : '0')};
  overflow: hidden;
  transition: all 500ms;
  margin-bottom: ${(props) => (props.isOpen ? '20px' : '0')};
  position: absolute;
  right: 0;
  left: 0;
  top: 76px;
  z-index: 1;
`;

const ReloadCheckEmpty = styled.span`
  width: 24px;
  height: 24px;
  border: 2px solid #fdfdfd;
  border-radius: 50%;
  flex: none;
`;

const AddressCheckbox = styled.input`
  margin-right: 20px;
  flex: none;
  width: 19px;
  height: 19px;
`;

const P = styled.p`
  color: ${(props) => (props.checked ? '#ffffff' : '#d8d1d1')};
`;

export const PackageName = styled.div`
  font-weight: 800;
  font-size: 22px;
  color: #8b8cfe;
  margin-top: 8px;
`;

export const CheckIconBox = styled.div`
  width: 20px;
  height: 20px;
  background: ${(props) => props.theme.colors.secondary100};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

export {
  View,
  PackagesContainer,
  MyPackage,
  Package,
  PackageTitle,
  PackagePrice,
  PackageButton,
  Border,
  PackageContent,
  PackageBenefit,
  PackageSubtitle,
  PackagesWrapper,
  CurrentlyText,
  TextSmall,
  Notice,
  WelcomeContainer,
  SectionTitle,
  SectionText,
  Separator,
  Tabs,
  TabButton,
  SelectedUSDT,
  Select,
  SelectDropDown,
  CollapsableAbsolute,
  ReloadCheckEmpty,
  AddressCheckbox,
  P
};
