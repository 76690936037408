import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWallets } from '../../../actions/walletActions';
import { AddBankAccount } from './AddBankAccount';
import { ChooseBankAccount } from './ChooseBankAccount';
import { WithdrawFinalCheck } from './WithdrawFinalCheck';
import { WithdrawInitial } from './WithdrawInitial';
import { WithdrawSuccess } from './WithdrawSuccess';
import { TwoFASetup } from './TwoFASetup';
import { VerificationEmail } from './VerificationEmail';
import { Withdraw2FARequired } from '../../EarningsBackoffice/Withdraw/Withdraw2FARequired';
import { WithdrawAvailability } from '../../EarningsBackoffice/Withdraw/WithdrawAvailability';

export const Withdraw = ({ step, setStep, setWithdraw }) => {
  const [amount, setAmount] = useState('');

  const fee = Math.max(1, Number.parseFloat(((2.5 / 100) * amount).toFixed(2)));

  const [selectWallet, setSelectedWallet] = useState('');

  const [selected, setSelected] = useState(0);
  const [setIsOpen2] = useState(0);

  const dispatch = useDispatch();

  const { userWallets } = useSelector((state) => state.withdraw);

  const { summary } = useSelector((state) => state.portfolio);

  useEffect(() => {
    dispatch(getWallets());
  }, [dispatch]);

  return (
    <>
      {step === 1 && (
        <WithdrawInitial
          setStep={setStep}
          userWallets={userWallets}
          selected={selected}
          amount={amount}
          setAmount={setAmount}
          availableBalance={summary?.availableBalance}
        />
      )}
      {step === 2 && (
        <WithdrawFinalCheck
          setStep={setStep}
          amount={amount}
          selected={selected}
          userWallets={userWallets}
        />
      )}
      {step === 3 && <WithdrawSuccess setWithdraw={setWithdraw} />}
      {step === 4 && (
        <ChooseBankAccount
          setStep={setStep}
          selected={selected}
          setSelected={setSelected}
          userWallets={userWallets}
          setSelectedWallet={setSelectedWallet}
          selectWallet={selectWallet}
        />
      )}

      {step === 5 && (
        <AddBankAccount setStep={setStep} setIsOpen2={setIsOpen2} />
      )}
      {step === 6 && (
        <TwoFASetup
          setStep={setStep}
          amount={amount}
          setAmount={setAmount}
          userWallets={userWallets}
          selected={selected}
          fee={fee}
        />
      )}
      {step === 7 && (
        <VerificationEmail
          setStep={setStep}
          amount={amount}
          setAmount={setAmount}
          selected={selected}
          userWallets={userWallets}
          fee={fee}
        />
      )}
      {step === 8 && <Withdraw2FARequired />}
      {step === 9 && <WithdrawAvailability />}
    </>
  );
};
