export const Packages = [
  {
    packageId: 28,
    packageTitle: '50  B-points',
    StakeRatio: '1.00',
    investment: [
      {
        icon: 1,
        text: '500 SP (shopping points)'
      }
    ],
    benefits: [
      // {
      //   icon: 1,
      //   text: `${percentage}% voucher for Ultron metaverse land sale`,
      //   percentage: '20% ULX',
      // },

      {
        icon: 1,
        text: 'ULTRON Premium backoffice membership',
        percentage: '15% ULX'
      }
    ],

    bonuses: [
      {
        icon: 1,
        text: '10% Direct bonus'
      },
      {
        icon: 1,
        text: '10% Team bonus'
      },
      {
        icon: 1,
        text: 'Matching bonus'
      },
      {
        icon: 1,
        text: 'Other bonuses'
      }
    ]
  },
  {
    packageId: 29,
    packageTitle: '200  B-points',
    StakeRatio: '1.00',
    investment: [
      {
        icon: 1,
        text: '1.500 SP (shopping points)'
      }
    ],
    benefits: [
      // {
      //   icon: 1,
      //   text: `${percentage}% voucher for Ultron metaverse land sale`,
      //   percentage: '20% ULX',
      // },
      // {
      //   icon: 1,
      //   text: 'ULX price discount 8%',
      // },
      {
        icon: 1,
        text: 'ULTRON Premium backoffice membership',
        percentage: '15% ULX'
      }
    ],

    bonuses: [
      {
        icon: 1,
        text: '10% Direct bonus'
      },
      {
        icon: 1,
        text: '10% Team bonus'
      },
      {
        icon: 1,
        text: 'Matching bonus'
      },
      {
        icon: 1,
        text: 'ULX Matching bonus'
      },
      {
        icon: 1,
        text: 'Other bonuses'
      }
    ]
  },
  {
    packageId: 30,
    // stars: 1,
    // image: standardPlus,
    imageTitle: 'Standard Plus',
    packageTitle: '600  B-points',
    StakeRatio: '1.00',
    investment: [
      {
        icon: 1,
        text: '5000 SP (shopping points)'
      }
    ],
    benefits: [
      // {
      //   icon: 1,
      //   text: `${percentage}% voucher for Ultron metaverse land sale`,
      //   percentage: '20% ULX',
      // },
      // {
      //   icon: 1,
      //   text: 'ULX price discount 10%',
      // },
      {
        icon: 1,
        text: 'ULTRON Premium backoffice membership',
        percentage: '15% ULX'
      }
    ],

    bonuses: [
      {
        icon: 1,
        text: '10% Direct bonus'
      },
      {
        icon: 1,
        text: '10% Team bonus'
      },
      {
        icon: 1,
        text: 'Matching bonus'
      },
      {
        icon: 1,
        text: 'ULX Matching bonus'
      },
      {
        icon: 1,
        text: 'Other bonuses'
      }
    ]
  },

  {
    packageId: 31,
    packageTitle: '50  B-points',
    StakeRatio: '1.10',
    investment: [
      {
        icon: 1,
        text: '500 SP (shopping points)'
      }
    ],
    benefits: [
      // {
      //   icon: 1,
      //   text: `${percentage}% voucher for Ultron metaverse land sale`,
      //   percentage: '20% ULX',
      // },
      // {
      //   icon: 1,
      //   text: 'ULX price discount 12%',
      // },
      {
        icon: 1,
        text: 'ULTRON Premium backoffice membership',
        percentage: '15% ULX'
      }
    ],

    bonuses: [
      {
        icon: 1,
        text: '10% Direct bonus'
      },
      {
        icon: 1,
        text: '10% Team bonus'
      },
      {
        icon: 1,
        text: 'Matching bonus'
      },
      {
        icon: 1,
        text: 'ULX Matching bonus'
      },
      {
        icon: 1,
        text: 'Other bonuses'
      }
    ]
  },

  {
    packageId: 32,
    packageTitle: '50  B-points',
    StakeRatio: '1.15',
    investment: [
      {
        icon: 1,
        text: '500 SP (shopping points)'
      }
    ],
    benefits: [
      // {
      //   icon: 1,
      //   text: `${percentage}% voucher for Ultron metaverse land sale`,
      //   percentage: '20% ULX',
      // },
      // {
      //   icon: 1,
      //   text: 'ULX price discount 15%',
      // },
      {
        icon: 1,
        text: 'ULTRON Premium backoffice membership',
        percentage: '15% ULX'
      }
    ],

    bonuses: [
      {
        icon: 1,
        text: '10% Direct bonus'
      },
      {
        icon: 1,
        text: '10% Team bonus'
      },
      {
        icon: 1,
        text: 'Matching bonus'
      },
      {
        icon: 1,
        text: 'ULX Matching bonus'
      },
      {
        icon: 1,
        text: 'Other bonuses'
      }
    ]
  },
  {
    packageId: 33,
    packageTitle: '50  B-points',
    StakeRatio: '1.20',
    investment: [
      {
        icon: 1,
        text: '500 SP (shopping points)'
      }
    ],
    benefits: [
      // {
      //   icon: 1,
      //   text: `${percentage}% voucher for Ultron metaverse land sale`,
      //   percentage: '20% ULX',
      // },
      // {
      //   icon: 1,
      //   text: 'ULX price discount 20%',
      // },
      {
        icon: 1,
        text: 'ULTRON Premium backoffice membership',
        percentage: '15% ULX'
      }
    ],

    bonuses: [
      {
        icon: 1,
        text: '10% Direct bonus'
      },
      {
        icon: 1,
        text: '10% Team bonus'
      },
      {
        icon: 1,
        text: 'Matching bonus'
      },
      {
        icon: 1,
        text: 'ULX Matching bonus'
      },
      {
        icon: 1,
        text: 'Other bonuses'
      }
    ]
  },
  {
    packageId: 34,
    packageTitle: '50  B-points',
    StakeRatio: '1.20',
    investment: [
      {
        icon: 1,
        text: '500 SP (shopping points)'
      }
    ],
    benefits: [
      // {
      //   icon: 1,
      //   text: `${percentage}% voucher for Ultron metaverse land sale`,
      //   percentage: '20% ULX',
      // },
      // {
      //   icon: 1,
      //   text: 'ULX price discount 20%',
      // },
      {
        icon: 1,
        text: 'ULTRON Premium backoffice membership',
        percentage: '15% ULX'
      }
    ],

    bonuses: [
      {
        icon: 1,
        text: '10% Direct bonus'
      },
      {
        icon: 1,
        text: '10% Team bonus'
      },
      {
        icon: 1,
        text: 'Matching bonus'
      },
      {
        icon: 1,
        text: 'ULX Matching bonus'
      },
      {
        icon: 1,
        text: 'Other bonuses'
      }
    ]
  }
];
