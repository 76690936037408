const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const CHANGE_EMAIL_SEND = {
  REQUEST: getRequestConst('CHANGE_EMAIL_SEND'),
  SUCCESS: getSuccessConst('CHANGE_EMAIL_SEND'),
  FAIL: getFailConst('CHANGE_EMAIL_SEND')
};

export const VERIFY__NEW_EMAIL = {
  REQUEST: getRequestConst('VERIFY__NEW_EMAIL'),
  SUCCESS: getSuccessConst('VERIFY__NEW_EMAIL'),
  FAIL: getFailConst('VERIFY__NEW_EMAIL')
};
