import { isValidPhoneNumber } from 'libphonenumber-js';

// LocalStorage setter
export function localStorageSetter(key, val) {
  return localStorage.setItem(key, val);
}

// LocalStorage getter
export function localStorageGetter(key) {
  return localStorage.getItem(key);
}

// LocalStorage remover
export function localStorageRemover(key) {
  return localStorage.removeItem(key);
}

// email checker
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePhoneNumber(phoneNum) {
  // const phonenoRe = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return isValidPhoneNumber(`+${phoneNum}`);
}

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
