export const GET_TEAM_MEMBER_REQUEST = 'GET_TEAM_MEMBER_REQUEST';
export const GET_TEAM_MEMBER_SUCCESS = 'GET_TEAM_MEMBER_SUCCESS';
export const GET_TEAM_MEMBER_FAIL = 'GET_TEAM_MEMBER_FAIL';

export const ADD_NEW_BLANCO_TEAM_MEMBER_REQUEST =
  'ADD_NEW_BLANCO_TEAM_MEMBER_REQUEST';
export const ADD_NEW_BLANCO_TEAM_MEMBER_SUCCESS =
  'ADD_NEW_BLANCO_TEAM_MEMBER_SUCCESS';
export const ADD_NEW_BLANCO_TEAM_MEMBER_FAIL =
  'ADD_NEW_BLANCO_TEAM_MEMBER_FAIL';

export const ADD_PHONE_TO_NEW_MEMBER_REQUEST =
  'ADD_PHONE_TO_NEW_MEMBER_REQUEST';
export const ADD_PHONE_TO_NEW_MEMBER_SUCCESS =
  'ADD_PHONE_TO_NEW_MEMBER_SUCCESS';
export const ADD_PHONE_TO_NEW_MEMBER_FAIL = 'ADD_PHONE_TO_NEW_MEMBER_FAIL';

export const CONFIRM_NEW_MEMBER_SMS_CODE_FAIL =
  'CONFIRM_NEW_MEMBER_SMS_CODE_FAIL';
export const CONFIRM_NEW_MEMBER_SMS_CODE_SUCCESS =
  'CONFIRM_NEW_MEMBER_SMS_CODE_SUCCESS';

export const NEW_MEMBER_RESEND_SMS_SUCCESS = 'NEW_MEMBER_RESEND_SMS_SUCCESS';
export const NEW_MEMBER_RESEND_SMS_FAIL = 'NEW_MEMBER_RESEND_SMS_FAIL';

export const CONFIRM_NEW_TEAM_MEMBER_EMAIL_SUCCESS =
  'CONFIRM_NEW_TEAM_MEMBER_EMAIL_SUCCESS';
export const CONFIRM_NEW_TEAM_MEMBER_EMAIL_FAIL =
  'CONFIRM_NEW_TEAM_MEMBER_EMAIL_FAIL';

export const GET_TEAM_MEMBER_DIRECT_REQUEST = 'GET_TEAM_MEMBER_DIRECT_REQUEST';
export const GET_TEAM_MEMBER_DIRECT_SUCCESS = 'GET_TEAM_MEMBER_DIRECT_SUCCESS';
export const GET_TEAM_MEMBER_DIRECT_FAIL = 'GET_TEAM_MEMBER_DIRECT_FAIL';

export const GET_TEAM_INIT_REQUEST = 'GET_TEAM_INIT_REQUEST';
export const GET_TEAM_INIT_SUCCESS = 'GET_TEAM_INIT_SUCCESS';
export const GET_TEAM_INIT_FAIL = 'GET_TEAM_INIT_FAIL';

export const GET_TEAM_USER_INFO = 'GET_TEAM_USER_INFO';

//ADD_TEAM_MEMBER_DRAWER
export const OPEN_ADD_TEAM_MEMBER_DRAWER = 'OPEN_ADD_TEAM_MEMBER_DRAWER';
export const CLOSE_ADD_TEAM_MEMBER_DRAWER = 'CLOSE_ADD_TEAM_MEMBER_DRAWER';
export const START_ADDING_NEW_MEMBER_INTO_TREE =
  'START_ADDING_NEW_MEMBER_INTO_TREE';

//CUSTOMERS
export const GET_DIRECT_CUSTOMERS_REQUEST = 'GET_DIRECT_CUSTOMERS_REQUEST';
export const GET_DIRECT_CUSTOMERS_SUCCESS = 'GET_DIRECT_CUSTOMERS_SUCCESS';
export const GET_DIRECT_CUSTOMERS_FAIL = 'GET_DIRECT_CUSTOMERS_FAIL';

export const GET_INDIRECT_CUSTOMERS_REQUEST = 'GET_INDIRECT_CUSTOMERS_REQUEST';
export const GET_INDIRECT_CUSTOMERS_SUCCESS = 'GET_INDIRECT_CUSTOMERS_SUCCESS';
export const GET_INDIRECT_CUSTOMERS_FAIL = 'GET_INDIRECT_CUSTOMERS_FAIL';

export const GET_VOLUME_INFO_REQUEST = 'GET_VOLUME_INFO_REQUEST';
export const GET_VOLUME_INFO_SUCCESS = 'GET_VOLUME_INFO_SUCCESS';
export const GET_VOLUME_INFO_FAIL = 'GET_VOLUME_INFO_FAIL';

export const GET_WAITING_ROOM_REQUEST = 'GET_WAITING_ROOM_REQUEST';
export const GET_WAITING_ROOM_SUCCESS = 'GET_WAITING_ROOM_SUCCESS';
export const GET_WAITING_ROOM_FAIL = 'GET_WAITING_ROOM_FAIL';

export const GET_ADD_WAITING_USER_TO_TEAM_REQUEST =
  'GET_ADD_WAITING_USER_TO_TEAM_REQUEST';
export const GET_ADD_WAITING_USER_TO_TEAM_SUCCESS =
  'GET_ADD_WAITING_USER_TO_TEAM_SUCCESS';
export const GET_ADD_WAITING_USER_TO_TEAM_FAIL =
  'GET_ADD_WAITING_USER_TO_TEAM_FAIL';

export const ADD_DIRECT_BREADCRUMB_REQUEST = 'ADD_DIRECT_BREADCRUMB_REQUEST';
export const ADD_DIRECT_BREADCRUMB_SUCCESS = 'ADD_DIRECT_BREADCRUMB_SUCCESS';
export const POP_DIRECT_BREADCRUMB_SUCCESS = 'POP_DIRECT_BREADCRUMB_SUCCESS';
export const CLEAR_DIRECT_BREADCRUMB_SUCCESS =
  'CLEAR_DIRECT_BREADCRUMB_SUCCESS';
export const ADD_DIRECT_BREADCRUMB_FAIL = 'ADD_DIRECT_BREADCRUMB_FAIL';
