import { tryExecuteSimpleAction } from '../../actions/common';

import { RANKING, CLAIM_PACKAGE_RANK } from './Ranking.constants';
import { getRankingAsync, claimPackageRankAsync } from './Ranking.requests';

export const getRanking = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    RANKING.REQUEST,
    async () => {
      const { data } = await getRankingAsync();

      const sortedRewards = data?.data?.packageRanks.map((rank) => ({
        ...rank,
        rewards: rank?.rewards?.sort(function (a, b) {
          return b?.reward?.type - a?.reward?.type;
        })
      }));

      const filteredRanks = sortedRewards.filter(
        (rank) => rank.packageRankId !== 16 && rank.packageRankId !== 17
      );

      dispatch({
        type: RANKING.SUCCESS,
        payload: filteredRanks
      });
    },
    RANKING.FAIL
  );
};

export const claimPackageRank =
  (selectedRank, setSelected, setStep) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      CLAIM_PACKAGE_RANK.REQUEST,
      async () => {
        await claimPackageRankAsync(selectedRank?.rewards[0]?.id);

        dispatch({
          type: CLAIM_PACKAGE_RANK.SUCCESS,
          payload: selectedRank.packageRankId
        });

        setSelected(null);

        setStep(2);
      },
      CLAIM_PACKAGE_RANK.FAIL
    );
  };
