import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorToast } from '../../../components/common/Toasts/Toasts';
import { InputBackOffice } from '../../../components/common/InputBackoffice';

import Spinner from '../../../components/common/Spinner';

import {
  Flex,
  SidebarSubtitle,
  SidebarTitle
} from '../../../styles/stylesStyled';

import { Icon, IconWarning } from '../../../styles/Icons';
import { color } from '../../../styles/GlobalStyles';
import { P2 } from '../../../styles/FontStyles';

import { Button } from '../../../styles/GlobalComponents';
import { changePassword } from '../settings.actions';

import { useTranslation } from 'react-i18next';

export const PasswordInit = ({ setStep }) => {
  const { t } = useTranslation('settings');
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');

  const { loading } = useSelector((state) => state.settings);

  const onHandleSubmit = (e) => {
    e.preventDefault();

    if (!oldPassword && !newPassword && !password) {
      ErrorToast(t('security.err_fill_all_fields'));
    } else {
      dispatch(changePassword(oldPassword, newPassword, setStep));
    }
  };

  return (
    <div>
      {loading ? (
        <Spinner fullScreen={false} dark />
      ) : (
        <>
          <SidebarTitle>{t('security.enter_new_password')}</SidebarTitle>
          <SidebarSubtitle>
            {t('security.click_save_changes_to_confirm')}
          </SidebarSubtitle>
          <div style={{ marginBottom: 30, marginTop: 20 }}>
            <Flex>
              <Icon>
                <IconWarning color={color.colors.error500} />
              </Icon>
              <P2 light mb='30' ml='10' center>
                Withdrawals will be disabled for 24 hours after you make this
                change to protect your account.
              </P2>
            </Flex>
          </div>
          <form onSubmit={(e) => onHandleSubmit(e)}>
            <InputBackOffice
              type='password'
              value={oldPassword}
              onChange={(e) => setOldPassword(e)}
              placeholder={t('security.enter_old_password')}
            />
            <InputBackOffice
              type='password'
              value={newPassword}
              onChange={(e) => setNewPassword(e)}
              placeholder={t('security.enter_new_password')}
            />
            <InputBackOffice
              type='password'
              value={password}
              onChange={(e) => setPassword(e)}
              placeholder={t('security.confirm_new_password')}
            />
            <Button m='20px auto' opacity='true'>
              {t('security.save_changes')}
            </Button>
          </form>
        </>
      )}
    </div>
  );
};
