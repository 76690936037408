import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import { addSelfieImage } from '../../actions/documentActions';
import cameraKyc from '../../assets/icons/cameraKyc.svg';
import icon from '../../assets/images/backoffice/kyc4.png';
import Alert from '../../components/common/Alert';
import CameraButton from '../../components/common/CameraButton';
import ModalComponentCamera from '../../components/common/ModalComponentCamera';
import Spinner from '../../components/common/Spinner';
import {
  convertImage,
  videoConstraintsSelfie
} from '../../helpers/helperFunctions';
import { H2, H3, P1, P2 } from '../../styles/FontStyles';
import { Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { Flex, FlexCenter } from '../../styles/stylesStyled';
import { useWindowWide } from '../../utils/wideScreen';
import { ImgState } from './KycDocumentPhotos';
import {
  CamCapturedCircle,
  CamCircle,
  CameraUnderlineText,
  Desktop,
  ImagePHContainerFilledSelfie,
  ImagePHContainerSelfie,
  Mobile,
  Small,
  TitleImage
} from './KycStyled';

const KycSelfieTime = ({ history }) => {
  const { t } = useTranslation('kyc.selfie');
  const [imgStateSelfie, setImgStateSelfie] = useState(1);

  const [modal3, setModal3] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const webcamRef = useRef(null);

  const [image, setImage] = useState('');
  const [img, setImg] = useState('');

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.userInfo);

  const uploadSelfieHandler = (e) => {
    const fileUploaded = e.target.files[0];

    setImage(URL.createObjectURL(fileUploaded));

    const formData = new FormData();
    formData.append('image', fileUploaded);

    dispatch(addSelfieImage(formData));
  };

  const captureSelfie = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);

    const selfie = await convertImage(imageSrc, 'selfie.jpeg');

    setImg(selfie);
  }, [
    webcamRef,
    setImage
    //  dispatch
  ]);

  const percentage = 66;

  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (!image) {
      setErrorMsg(t('err_selfie_requried'));
      setTimeout(() => {
        setErrorMsg('');
      }, 3000);
    } else {
      const formData = new FormData();
      formData.append('image', img);
      dispatch(addSelfieImage(formData, history));
    }
  };

  useEffect(() => {
    setImgStateSelfie(1);
  }, [modal3]);

  const wide = useWindowWide(1000);

  return (
    <>
      {loading ? (
        <Spinner fullScreen={true} />
      ) : (
        <>
          {errorMsg && <Alert message={errorMsg} />}
          <Desktop>
            <TitleImage>
              <img
                src={icon}
                alt='icon'
                style={{ marginBottom: 40, width: 100 }}
              />
            </TitleImage>
          </Desktop>

          <Desktop>
            <H2 mb='12' color={color.kycTitleColor}>
              {t('title')}
            </H2>

            <P1
              mb='40'
              maxWidth='600px'
              maxWidthSM='1000px'
              color={color.kycSubTitleColor}>
              {t('subtitle')}
            </P1>
          </Desktop>
          <Mobile>
            <H3 mb='8' center color={color.kycTitleColor}>
              {t('title')}
            </H3>

            <P2 mb='32' center color={color.kycSubTitleColor}>
              {t('subtitle')}
            </P2>
          </Mobile>
          <form onSubmit={onHandleSubmit}>
            <div
              style={{
                display: 'flex',
                justifyContent: !wide ? 'center' : 'flex-start'
              }}>
              {image ? (
                <ImagePHContainerFilledSelfie
                  style={{
                    padding: 0,
                    overflow: 'hidden'
                  }}>
                  <img
                    src={image}
                    alt='upload'
                    style={{
                      // height: '325px',
                      objectFit: 'cover',
                      filter: imgStateSelfie === 2 ? 'blur(8px)' : 'blur(0px)',
                      transition: '.3s filter ease',
                      verticalAlign: 'middle'
                    }}
                  />
                  <ImgState
                    state={imgStateSelfie}
                    setImgState={setImgStateSelfie}
                    percentage={percentage}
                    uploadHandler={(e) => uploadSelfieHandler(e)}
                    retake={() => {
                      setImage('');
                      setModal3(true);
                    }}
                  />
                </ImagePHContainerFilledSelfie>
              ) : (
                <ImagePHContainerSelfie>
                  <div
                    style={{
                      textAlign: 'center'
                    }}>
                    <Flex style={{ justifyContent: 'center' }}>
                      <img
                        src={cameraKyc}
                        alt='upload'
                        style={{ width: 20, marginRight: 12 }}
                      />
                      <Small>{t('img_holder_title')}</Small>
                    </Flex>

                    <FlexCenter style={{ justifyContent: 'center' }}>
                      <Button
                        fs='12px'
                        h='34px'
                        maxWidth='111px'
                        type='button'
                        onClick={() => setModal3(true)}>
                        {t('take_photo_btn')}
                      </Button>
                    </FlexCenter>
                  </div>
                </ImagePHContainerSelfie>
              )}
            </div>

            <Desktop>
              <Button maxWidth='200px' opacity={'true'} type='submit'>
                {t('confirm_photos_btn')}
              </Button>
            </Desktop>
            <Mobile>
              <Button mbSM='50' opacity={'true'} type='submit'>
                {t('confirm_photos_btn')}
              </Button>
            </Mobile>
          </form>
          <ModalComponentCamera
            modal={modal3}
            setModal={setModal3}
            passport={'true'}>
            {image ? (
              <div>
                <P2 center mb='20' bold>
                  {t('confirm_your_selfie')}
                </P2>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <CamCapturedCircle>
                    <img src={image} alt='captured' />
                  </CamCapturedCircle>
                </div>
                <Button
                  opacity={'true'}
                  type='button'
                  onClick={() => {
                    setModal3(!modal3);
                    setImgStateSelfie(1);
                  }}
                  m='20px 0'>
                  {t('continue_btn')}
                </Button>
                <CameraUnderlineText onClick={() => setImage('')}>
                  {t('retake_btn')}
                </CameraUnderlineText>
              </div>
            ) : (
              <div>
                <P2 bold center mb='30'>
                  {t('selfie_camera_is_ready')}
                </P2>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <CamCircle>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      videoConstraints={videoConstraintsSelfie}
                      screenshotFormat='image/jpeg'
                    />
                  </CamCircle>
                </div>
                <CameraButton click={captureSelfie} />
                <CameraUnderlineText onClick={() => setModal3(false)}>
                  {t('cancel_btn')}
                </CameraUnderlineText>
              </div>
            )}
          </ModalComponentCamera>
        </>
      )}
    </>
  );
};

export default KycSelfieTime;
