import React, { useState } from 'react';
import Direct from './Direct/Direct';
import { WarningBox } from '../../components/common/WarningBox';
import { SidebarModal } from '../../components/common/Sidebar/Sidebar';
import {
  BACKOFFICE_NAME,
  BACKOFFICE_NAME_CAPS
} from '../../helpers/helperFunctions';

const MyTeamBackoffice = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Direct />
      <SidebarModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={`${BACKOFFICE_NAME_CAPS}   RENEWAL`}
        sidebarWidth={'511px'}>
        <WarningBox
          text={`YOUR ${BACKOFFICE_NAME_CAPS}  SUBSCRIPTION IS NOT ACTIVE. PURCHASE A ${BACKOFFICE_NAME_CAPS}  SUBSCRIPTION TO ENJOY THE BENEFITS OF YOUR WORK:`}
          virtualOfficeButtonText={`Continue without ${BACKOFFICE_NAME} `}
          buttonText={`Purchase ${BACKOFFICE_NAME}  Subscription`}
          virtualOfficeButtonFn={() => history.push('/backoffice/dashboard')}
          buttonFn={() => history.push('/backoffice/settings/virtual-office')}
        />
      </SidebarModal>
    </>
  );
};

export default MyTeamBackoffice;
