import React from 'react';
// styles
import { AnimatedAuthStyled } from './AnimatedDashboardStyled';

export const AnimatedAuth = ({ children, index }) => {
  return (
    <AnimatedAuthStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2
      }}
      key={index}>
      {children}
    </AnimatedAuthStyled>
  );
};
