import React, { useState } from 'react';
import { Earning, EarningIcon } from './EarningsHistoryStyled';

import { useTranslation } from 'react-i18next';
import { SidebarModal } from '../../../components/common/Sidebar/Sidebar';
import { formatUSD, USDT } from '../../../helpers/helperFunctions';
import { CaptionText, P2 } from '../../../styles/FontStyles';
import { color } from '../../../styles/GlobalStyles';
import {
  Icon,
  IconEarningsCheck,
  IconEarningsLock
} from '../../../styles/Icons';
import { FlexCenter } from '../../../styles/stylesStyled';
import { EarningDetails } from './EarningDetails';

const getStatusIcon = (status) => {
  switch (status) {
    case 1:
      return (
        <Icon color={color.colors.secondary100}>
          <IconEarningsLock />
        </Icon>
      );
    case 2:
      return (
        <Icon color={color.colors.secondary100}>
          <IconEarningsCheck />
        </Icon>
      );
    default:
      return '';
  }
};

export const EarningsHistoryItem = ({ earning }) => {
  const { t } = useTranslation('earnings');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* onClick={() => setEarningDetails(earning)} */}
      <Earning>
        <FlexCenter>
          <EarningIcon>{getStatusIcon(earning?.status)}</EarningIcon>
          <div>
            <P2 color={color.colors.neutral900}>{earning?.name}</P2>
            <CaptionText color={color.colors.neutral500}>
              {earning?.username}
            </CaptionText>
          </div>
        </FlexCenter>
        <P2
          color={
            earning?.status === 1
              ? color.colors.neutral500
              : color.colors.neutral900
          }>
          {earning?.amount >= 0 && '+'} {formatUSD(earning?.amount)} {USDT}
        </P2>
      </Earning>
      {/* <SectionSeparator margin='0' /> */}
      <SidebarModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={t('earning_details')}
        sidebarWidth={'511px'}>
        <EarningDetails earning={earning} />
      </SidebarModal>
    </>
  );
};
