import axios from 'axios';

import { createAxiosConfigWithAuthHeader } from '../../utils/createAxiosConfigWithAuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

export const getSettingsAccountAsync = async () => {
  const url = `${API_URL}/user/settings/account`;

  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const getSettingsNottificationsAsync = async () => {
  const url = `${API_URL}/user/settings/notifications`;

  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const setNotificationAsync = async (notifications) => {
  const url = `${API_URL}/user/settings/notifications`;

  return await axios.put(url, notifications, createAxiosConfigWithAuthHeader());
};

export const updateUsernameAsync = async (username) => {
  const url = `${API_URL}/user/settings/account/username`;

  return await axios.put(url, { username }, createAxiosConfigWithAuthHeader());
};

export const changePasswordAsync = async (oldPassword, newPassword) => {
  const url = `${API_URL}/user/password/change`;
  return await axios.put(
    url,
    { oldPassword, newPassword },
    createAxiosConfigWithAuthHeader()
  );
};

export const getTeamSettinsAsync = async () => {
  const url = `${API_URL}/user/settings`;
  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const updateTeamSettingsAsync = async (teamCompletionSorting) => {
  const url = `${API_URL}/user/settings`;

  return await axios.put(
    url,
    { teamCompletionSorting },
    createAxiosConfigWithAuthHeader()
  );
};

export const getVerificationSecurityAsync = async () => {
  const url = `${API_URL}/user/settings/security`;

  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const getVerificationStatusAsync = async () => {
  const url = `${API_URL}/user/settings/security/2fa/status`;

  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const enableVerificationStatusAsync = async () => {
  const url = `${API_URL}/user/settings/security/2fa/enable`;

  return await axios.post(url, createAxiosConfigWithAuthHeader());
};

export const disableVerificationStatusAsync = async (emailCode, twoFACode) => {
  const url = `${API_URL}/user/settings/security/2fa/disable`;

  return await axios.post(
    url,
    { emailCode, twoFACode },
    createAxiosConfigWithAuthHeader()
  );
};

export const confirmVerificationAsync = async (emailCode, twoFACode) => {
  const url = `${API_URL}/user/settings/security/2fa/enable/confirm`;

  return await axios.put(
    url,
    { emailCode, twoFACode },
    createAxiosConfigWithAuthHeader()
  );
};

export const confirm2FAVerificationAsync = async (
  emailCode,
  twoFACode,
  signIn2FAEnabled
) => {
  const url = `${API_URL}/user/settings/security/signin/2fa`;

  return await axios.put(
    url,
    {
      emailCode,
      twoFACode,
      signIn2FAEnabled: signIn2FAEnabled ? false : true
    },
    createAxiosConfigWithAuthHeader()
  );
};

export const confirm2FAEmailAsync = async (emailCode) => {
  const url = `${API_URL}/user/settings/security/signin/email-verification/enable/confirm`;

  return await axios.put(
    url,
    {
      emailCode
    },
    createAxiosConfigWithAuthHeader()
  );
};

export const buyVirtaulOfficeAsync = async (
  licenceId,
  networkType,
  currencyType
) => {
  const url = `${API_URL}/virtual-office/licence/buy`;
  return await axios.post(
    url,

    currencyType === 1
      ? { currencyType, networkType, licenceId }
      : { currencyType, licenceId },

    createAxiosConfigWithAuthHeader()
  );
};
