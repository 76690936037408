import {
  GET_BACK_OFFICE_REQUEST,
  GET_BACK_OFFICE_SUCCESS,
  GET_BACK_OFFICE_FAIL,
  GET_LATEST_ORDERS_REQUEST,
  GET_LATEST_ORDERS_SUCCESS,
  GET_LATEST_ORDERS_FAIL,
  GET_LAST_REGISTERED_USERS_REQUEST,
  GET_LAST_REGISTERED_USERS_SUCCESS,
  GET_LAST_REGISTERED_USERS_FAIL,
  GET_JOINED_AFTER_USERS_SUCCESS,
  GET_JOINED_AFTER_USERS_FAIL
} from './Dashboard.constants';

export const dashboard = (
  state = {
    loading: true,
    dashboard: {},
    orders: [],
    users: [],
    count: 0
  },
  action
) => {
  switch (action.type) {
    case GET_BACK_OFFICE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_BACK_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.payload
      };
    case GET_BACK_OFFICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case GET_LAST_REGISTERED_USERS_REQUEST:
      return {
        ...state
      };

    case GET_LAST_REGISTERED_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload
      };

    case GET_LAST_REGISTERED_USERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_JOINED_AFTER_USERS_SUCCESS:
      return {
        ...state,
        count: action.payload
      };

    case GET_JOINED_AFTER_USERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_LATEST_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_LATEST_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload
      };
    case GET_LATEST_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
