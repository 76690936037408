import { SELECTED_ID } from '../../constants/packagesConstants';

import { GET_ORDERS, CANCEL_PACKAGES } from './packages.constants';

import { CLEAR_ALERT } from '../../constants/errorConstants';

export const ordersList = (state = { orders: [] }, action) => {
  switch (action.type) {
    case GET_ORDERS.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_ORDERS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: action.payload
      };

    case GET_ORDERS.FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case CANCEL_PACKAGES.REQUEST:
      return {
        ...state,
        loading: true
      };

    case CANCEL_PACKAGES.SUCCESS:
      return {
        ...state,
        loading: false,
        orders: state.orders.filter((order) => order.id !== action.payload)
      };

    case CANCEL_PACKAGES.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export const packageIndex = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_ID:
      return {
        ...state,
        packageIndex: action.payload
      };
    default:
      return state;
  }
};
