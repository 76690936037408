import React from 'react';
import Modal from 'react-modal';

const ModalComponentCamera = ({ children, setModal, modal, passport }) => {
  return (
    <Modal
      isOpen={modal}
      onRequestClose={() => setModal(!modal)}
      contentLabel='My dialog'
      className={passport ? 'cameraModalPassport' : 'cameraModal'}
      overlayClassName='myoverlay'
      closeTimeoutMS={500}
      ariaHideApp={false}>
      {children}
    </Modal>
  );
};

export default ModalComponentCamera;
