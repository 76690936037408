import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  changeEmail,
  addEmailAndVerificationCode
} from '../../actions/authActions';
import Alert from '../../components/common/Alert';
import { InputBackOffice } from '../../components/common/InputBackoffice';
import Spinner from '../../components/common/Spinner';
import { ErrorToast } from '../../components/common/Toasts/Toasts';
import { validateEmail } from '../../helpers/helperFunctions';
import { AuthLayoutTitle } from '../../styles/GlobalComponents';
import { AuthButton, Label, PhoneComp } from '../../styles/stylesStyled';
import {
  addDirectUser,
  addDirectUserPlus
} from '../MyTeamBackoffice/myTeamBackOffice.actions';

const captchaId = process.env.REACT_APP_CAPTCHA_ID;

const EmailAddress = ({ history, match }) => {
  const { t } = useTranslation('onboarding');

  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [emailError] = useState('');
  const [reEmailError] = useState('');
  const [captchaLoading, setCaptchaLoading] = useState(false);

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.register);
  // const myTeam = useSelector((state) => state.myTeam);
  const myTeamBackoffice = useSelector((state) => state.myTeamBackoffice);

  const { user, approvalDate, referrerId, loading } = userInfo ?? {};

  const location = useLocation();

  const { message: myTeamBackofficeMessage, error: myTeamBackofficeError } =
    myTeamBackoffice;

  const sendEmail = (e) => {
    e.preventDefault();
    if (!email) {
      ErrorToast(t('email_address.error_email_empty'));
      return;
    }
    if (!validateEmail(email)) {
      ErrorToast(t('email_address.error_email_invalid'));
      return;
    }

    if (email !== email2) {
      ErrorToast(t('email_address.error_email_match'));
      return;
    }

    if (location.state?.path === '/confirm-email') {
      dispatch(
        changeEmail(user.id, email, () => {
          history.push('/confirm-email');
        })
      );
    } else if (match.params.childPosition) {
      dispatch(
        addDirectUserPlus(parseInt(match.params.childPosition), email, history)
      );
    } else if (match.params.id) {
      dispatch(addDirectUser(null, parseInt(match.params.id), email, history));
    } else {
      window.initGeetest4(
        {
          captchaId,
          language: 'eng',
          product: 'bind',
          riskType: 'slide',
          nativeButton: {
            width: '100%',
            marginBottom: '20px'
          }
        },
        function (captcha) {
          setCaptchaLoading(true);
          captcha.showBox();
          captcha
            .onReady(function () {})
            .onSuccess(function () {
              const result = captcha.getValidate();
              dispatch(
                addEmailAndVerificationCode(
                  approvalDate,
                  referrerId,
                  email,
                  result,
                  history
                )
              );
            })
            .onError(function () {});
        }
      );
    }
  };

  return (
    <div>
      {loading ||
        (captchaLoading && <Spinner fullScreen={true} solid={false} />)}

      <PhoneComp>
        {/* {emailError && <Alert message={emailError} />}
        {reEmailError && <Alert message={reEmailError} />}
        {emailMessage && <Alert message={emailMessage} />}
        {error && <Alert message={error.error} />}
        {message && <Alert icon={'success'} message={message} />} */}

        {myTeamBackofficeMessage && (
          <Alert icon={'success'} message={myTeamBackofficeMessage} />
        )}
        {myTeamBackofficeError && <Alert message={myTeamBackofficeError} />}

        <AuthLayoutTitle>
          {match.params.id
            ? t('email_address.register_new_user')
            : t('email_address.enter_email_address')}
        </AuthLayoutTitle>

        <form onSubmit={sendEmail}>
          <Label>{t('email_address.label')}</Label>
          <InputBackOffice
            id={'email'}
            type={'text'}
            placeholder={
              match.params.id
                ? t('email_address.enter_users_email_placeholder')
                : t('email_address.enter_your_email_placeholder')
            }
            name={'email'}
            value={email.replace(/\s+/g, '')}
            onChange={(e) => setEmail(e.replace(/\s+/g, ''))}
            label={t('email_address.label')}
            error={emailError}
          />

          <InputBackOffice
            id={'email'}
            type={'text'}
            placeholder={t('email_address.repeat_email_placeholder')}
            name={'email'}
            value={email2.replace(/\s+/g, '')}
            onChange={(e) => setEmail2(e.replace(/\s+/g, ''))}
            label={t('email_address.label')}
            error={reEmailError}
          />

          <AuthButton
            opacity={email && email2}
            type='submit'
            disabled={email === '' && email2 === '' ? true : false}>
            {t('email_address.button_text')}
          </AuthButton>
        </form>
      </PhoneComp>
    </div>
  );
};

export default EmailAddress;
