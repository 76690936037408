import styled from 'styled-components';

const ReferralComp = styled.div`
  @media (max-height: 570px) {
  }
  @media (max-height: 515px) {
  }
`;

const Scan = styled.p`
  color: #ee1a80;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;

const Continue = styled.div`
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
  color: #fa8d11;
  font-weight: 500;
  cursor: pointer;
`;

const QR = styled.div`
  display: flex;
  opacity: ${(props) => (props.qrbox ? '1' : '0')};
  transition: all 0.3s ease;
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: ${(props) => (props.qrbox ? '0' : '-110%')};
  left: 0;
  z-index: 101;
  // align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2700px;
  // background-color: #fff;
  /* padding: 100px 0; */
  > * {
    width: 311px;
  }
`;

const X = styled.div`
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 0 20px 0 0;
  @media (max-width: 800px) {
    font-size: 17px;
  }
`;

const ModalBody = styled.div`
  padding-top: ${(props) => (props.step === 1 ? '80px' : '27px')};
  dispay: flex;
  flex-direction: column;
  text-align: center;
  span {
    color: #f26c6b;
  }
`;

const GivvoSponsor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    margin-bottom: 25px;
  }
`;

const SponsorText = styled.div`
  span {
    font-weight: bold;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrangeUnderline = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #ff5700;
  cursor: pointer;
`;

export {
  ReferralComp,
  Scan,
  Continue,
  QR,
  X,
  ModalHeader,
  ModalTitle,
  ModalBody,
  GivvoSponsor,
  SponsorText,
  ButtonWrapper,
  OrangeUnderline
};
