import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ClaimConfirm } from './ClaimConfirm';
import { ClaimAmount } from './ClaimAmount';
import { ClaimWithdrawal } from './ClaimWithdrawal';
import { ClaimWithdrawalFinish } from './ClaimWithdrawalFinish';
import { ClaimSteps } from './types';
import Spinner from '../../../components/common/Spinner';

export const Claim = ({ packageItem, setClaim, claimStep, setClaimStep }) => {
  const [amount, setAmount] = useState('');

  const { loadingAmountToBurn } = useSelector((state) => state.portfolio);

  if (loadingAmountToBurn) return <Spinner solid={false} />;

  const claimSteps = (step) => {
    switch (step) {
      case ClaimSteps.STEP1:
        return <ClaimConfirm setClaimStep={setClaimStep} setClaim={setClaim} />;

      case ClaimSteps.STEP2:
        return (
          <ClaimAmount
            packageItem={packageItem}
            setClaimStep={setClaimStep}
            setClaim={setClaim}
            amount={amount}
            setAmount={setAmount}
          />
        );

      case ClaimSteps.STEP3:
        return (
          <ClaimWithdrawal
            packageItem={packageItem}
            setClaimStep={setClaimStep}
            setClaim={setClaim}
            amount={amount}
            setAmount={setAmount}
          />
        );

      case ClaimSteps.STEP4:
        return (
          <ClaimWithdrawalFinish
            setClaim={setClaim}
            amount={amount}
            packageItem={packageItem}
          />
        );

      default:
        break;
    }
  };
  return <div>{claimSteps(claimStep)}</div>;
};
