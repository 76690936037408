import React from 'react';
import styled from 'styled-components';
import { color } from '../../../styles/GlobalStyles';

const getColor = (props) => {
  if (props.isDragAccept) {
    return color.colors.success500;
  }
  if (props.isDragReject) {
    return color.colors.error500;
  }
  if (props.isFocused) {
    return color.colors.success500;
  }
  return color.colors.neutral400;
};

const Container = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 12px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin: 0 0 20px 0;
`;

export const Dropzone = ({
  getRootProps,
  isDragAccept,
  isDragReject,
  children
}) => {
  return (
    <Container {...getRootProps({ isDragAccept, isDragReject })}>
      {children}
    </Container>
  );
};
