import React from 'react';
import { CaptionText, P2, P3 } from '../../../styles/FontStyles';
import {
  Box,
  Button,
  Flexed,
  UserImageCircle
} from '../../../styles/GlobalComponents';
import { Partner, Qualification } from '../CashbackBonusStyled';

import { Responsive } from '../../../styles/stylesStyled';

import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  PLATFORM_LINK,
  USDT,
  copyLink,
  formatUSD
} from '../../../helpers/helperFunctions';
import { MARKETPLACE } from '../../../routes/routes';
import { color } from '../../../styles/GlobalStyles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export const Section2 = ({ cashback, statusPerPackage }) => {
  const { t } = useTranslation('cashback');

  const { user } = useSelector((state) => state.userInfo);
  const history = useHistory();

  return (
    <Box>
      <Qualification style={{ marginBottom: 40 }}>
        <div>
          <P2 mb='8' bold flex between>
            {t('section2.title')}
          </P2>
          <P3>
            {t('section2.text')}{' '}
            <b>
              {cashback?.currentPackagePrice ? (
                <>
                  {formatUSD(cashback?.currentPackagePrice)} {USDT}
                </>
              ) : (
                <span
                  style={{
                    color: color.colors.neutral900,
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(MARKETPLACE)}>
                  No HUB, buy now
                </span>
              )}
            </b>
          </P3>
        </div>

        <Flexed center widthSM='100%' gap='30px' gapSM='24px'>
          <Button
            w='200px'
            wSM='100%'
            negative
            onClick={() => history.push('/backoffice/my-team')}>
            {t('section2.button1')}
          </Button>
          <Button
            w='200px'
            wSM='100%'
            onClick={() =>
              copyLink(
                `${PLATFORM_LINK}/ambassador/${user?.referralCode}/${user?.id}`
              )
            }>
            {t('section2.button2')}
          </Button>
        </Flexed>
      </Qualification>

      <>
        {statusPerPackage.map(({ hubNames, directUsers, boughtHub }, i) => {
          return (
            <Responsive mbSM='24' key={i} between center startSM>
              <div>
                <P2
                  mb='8'
                  bold
                  flex
                  between
                  color={
                    boughtHub
                      ? color.colors.primary900
                      : color.colors.neutral400
                  }>
                  {hubNames}
                </P2>
                {boughtHub ? (
                  <P3 mb='8'>
                    {t('section2.order_id')} {boughtHub.userPackageId}{' '}
                    {t('section2.payment_date')}{' '}
                    {moment(boughtHub.datePaid).format('DD.M.YYYY')}
                  </P3>
                ) : (
                  <Button
                    w='auto'
                    h='auto'
                    wSM='auto'
                    hSM='auto'
                    p='0'
                    mb='8'
                    textButton
                    onClick={() => history.push(MARKETPLACE)}>
                    {t('section2.button3')}
                  </Button>
                )}
              </div>
              <Flexed wrap>
                {directUsers.map((user, i) => {
                  return (
                    <PartnerComponent
                      username={user.username}
                      image={user.profileImageUrl}
                      packagePrice={user?.package?.price}
                      dateOfPurchase={user?.package?.datePaid}
                      disabled={!user.id}
                      key={i}
                    />
                  );
                })}
              </Flexed>
            </Responsive>
          );
        })}
      </>
    </Box>
  );
};

const PartnerComponent = ({
  packagePrice,
  dateOfPurchase,
  image,
  username,
  disabled
}) => {
  return (
    <Partner>
      <UserImageCircle
        image={image}
        avatar
        size='40px'
        fontSize='18px'
        avatarSize={'20'}
        margin={'0 0 4px 0'}
        disabled={disabled}
      />
      <div>
        <CaptionText
          mb={!dateOfPurchase && !packagePrice && disabled && '18'}
          color={color.colors.neutral900}>
          {username}
        </CaptionText>
        {dateOfPurchase && packagePrice && !disabled && (
          <CaptionText color={color.colors.neutral900}>
            {moment(dateOfPurchase).format('D.MM.YYYY')}
            <br />
            {packagePrice || '0.00'} {USDT}
          </CaptionText>
        )}

        {disabled && (
          <CaptionText color={color.colors.neutral50}>.</CaptionText>
        )}
      </div>
    </Partner>
  );
};
