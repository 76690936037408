import React from 'react';

import {
  Flex,
  FlexJustifyCenter,
  SidebarSubtitle
} from '../../../styles/stylesStyled';
import { Icon, IconWarning } from '../../../styles/Icons';
import { Button } from '../../../styles/GlobalComponents';
import { P2 } from '../../../styles/FontStyles';

export const Warning = ({ setStep }) => {
  return (
    <>
      <Flex>
        <Icon>
          <IconWarning color='#FCBF24' />
        </Icon>
        <P2 semiBold mb='20' ml='10' center>
          Are you sure you wan to to change email verification?
        </P2>
      </Flex>
      <SidebarSubtitle>
        {' '}
        Withdrawals will be disabled for 24 hours after you make this change to
        protect your account. <br />
        <br />
        You cannot register for a new account with the old email address and
        link the email address to another account within 30 days after removing
        it from this current account.
      </SidebarSubtitle>
      <FlexJustifyCenter>
        <Button mt='40' maxWidth='200px' onClick={() => setStep(2)}>
          Continue
        </Button>
      </FlexJustifyCenter>
    </>
  );
};
