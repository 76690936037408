import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { IconCheckMark, IconXMark } from '../../../styles/Icons';

const CheckboxStyled = styled.div`
  width: ${({ size }) => size ?? '26'}px;
  height: ${({ size }) => size ?? '26'}px;
  border-radius: 50%;
  background-color: ${({ theme, x }) =>
    x ? theme.colors.neutral500 : theme.colors.primary900};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid
    ${({ theme, x }) => (x ? theme.colors.neutral500 : theme.colors.primary900)};
  flex: none;

  ${({ margin }) => margin && `margin:${margin}`};
  ${({ pointer }) => pointer && `cursor: pointer`};

  ${({ checked }) =>
    !checked &&
    `
    background-color: rgba(0,0,0,0);
`}

  ${({ checkColor }) =>
    checkColor &&
    `
  
  background: ${checkColor};
  border-color: ${checkColor};
  
  `};
`;

export const Checkbox = ({
  onClick,
  checked,
  size,
  margin,
  x,
  pointer,
  checkColor
}) => {
  return (
    <CheckboxStyled
      x={x}
      onClick={onClick}
      margin={margin}
      size={size}
      checked={checked}
      pointer={pointer}
      checkColor={checkColor}>
      <AnimatePresence mode='wait' exitBeforeEnter>
        {!x && checked && (
          <motion.div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            initial={{ opacity: 0, transform: 'scale(0)' }}
            animate={{ opacity: 1, transform: 'scale(1)' }}
            exit={{ opacity: 0, transform: 'scale(0)' }}
            transition={{
              duration: 0.2
            }}>
            <IconCheckMark size={size ? size / 2 : 24 / 2} />
          </motion.div>
        )}
      </AnimatePresence>
      {x && <IconXMark />}
    </CheckboxStyled>
  );
};
