import { ADD_ADDRESS_SUCCESS } from '../constants/addressConstants';
import {
  ADD_SOCIAL_PHONE_FAIL,
  ADD_SOCIAL_PHONE_REQUEST,
  ADD_SOCIAL_PHONE_SUCCESS,
  CHANGE_EMAIL_LINK_FAIL,
  CHANGE_EMAIL_LINK_REQUEST,
  CHANGE_EMAIL_LINK_SUCCESS,
  CONFIRM_EMAIL_FAIL,
  CONFIRM_EMAIL_LINK_FAIL,
  CONFIRM_EMAIL_LINK_SUCCESS,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_NEW_PASSWORD_FAIL,
  CONFIRM_NEW_PASSWORD_SUCCESS,
  CONFIRM_SMS_FAIL,
  CONFIRM_SMS_REQUEST,
  CONFIRM_SMS_SUCCESS,
  CONFIRM_SOCIAL_PHONE_FAIL,
  CONFIRM_SOCIAL_PHONE_REQUEST,
  CONFIRM_SOCIAL_PHONE_SUCCESS,
  CONTINUE_TO_PHONE_NUMBER,
  CONTINUE_TO_REFERRAL_REQUEST,
  CONTINUE_TO_TERMS_CONDITION,
  CREATE_SOCIAL_REFERRER_ID_FAIL,
  CREATE_SOCIAL_REFERRER_ID_REQUEST,
  CREATE_SOCIAL_REFERRER_ID_SUCCESS,
  CREATE_USER_PHONE_FAIL,
  CREATE_USER_PHONE_REQUEST,
  CREATE_USER_PHONE_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_PROFILE_SUCCESS,
  FORGOTTEN_ID_FAIL,
  FORGOTTEN_ID_REQUEST,
  FORGOTTEN_ID_SUCCESS,
  FORGOTTEN_PASSWORD_FAIL,
  FORGOTTEN_PASSWORD_REQUEST,
  FORGOTTEN_PASSWORD_SMS_FAIL,
  FORGOTTEN_PASSWORD_SMS_REQUEST,
  FORGOTTEN_PASSWORD_SMS_SUCCESS,
  FORGOTTEN_PASSWORD_SUCCESS,
  LOGIN_APPLE_FAIL,
  LOGIN_APPLE_REQUEST,
  LOGIN_APPLE_SUCCESS,
  LOGIN_FACEBOOK_FAIL,
  LOGIN_FACEBOOK_REQUEST,
  LOGIN_FACEBOOK_SUCCESS,
  LOGIN_GOOGLE_FAIL,
  LOGIN_GOOGLE_REQUEST,
  LOGIN_GOOGLE_SUCCESS,
  LOGIN_LINKEDIN_FAIL,
  LOGIN_LINKEDIN_REQUEST,
  LOGIN_LINKEDIN_SUCCESS,
  LOGOUT,
  RESEND_EMAIL_FAIL,
  RESEND_EMAIL_REQUEST,
  RESEND_EMAIL_SUCCESS,
  RESEND_PHONE_FAIL,
  RESEND_PHONE_SUCCESS,
  RESEND_SMS_FAIL,
  RESEND_SMS_REQUEST,
  RESEND_SMS_SUCCESS,
  RESEND_SOCIAL_PHONE_FAIL,
  RESEND_SOCIAL_PHONE_REQUEST,
  RESEND_SOCIAL_PHONE_SUCCESS,
  SEND_PASSWORD_FAIL,
  SEND_PASSWORD_REQUEST,
  SEND_PASSWORD_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SOCIAL_REFERRAL_FAIL,
  SOCIAL_REFERRAL_REQUEST,
  SOCIAL_REFERRAL_SUCCESS,
  SOCIAL_REGISTER_FAIL,
  SOCIAL_REGISTER_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_IMAGE_FAIL,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  SET_REFFERAL_ID,
  SIGN_UP_CUSTOMER_REQUEST,
  SIGN_UP_CUSTOMER_SUCCESS,
  SIGN_UP_CUSTOMER_FAIL,
  SET_TOOGLE_MODAL,
  SET_TOOGLE_SIDE_BAR,
  SET_TOOGLE_MODAL_MOBILE,
  SET_SIDEBAR_MOBILE,
  RESEND_PHONE_REQUEST,
  SET_APPROVAL_DATE,
  ADD_SOCIAL_TERMS_REQUEST,
  ADD_SOCIAL_TERMS_FAIL,
  ADD_SOCIAL_TERMS_SUCCESS,
  UPDATE_SOCIAL_PROFILE_REQUEST,
  UPDATE_SOCIAL_PROFILE_SUCCESS,
  UPDATE_SOCIAL_PROFILE_FAIL,
  CLEAR_REGISTER_STATE,
  CONTINUE_TO_AFFILIATE_TERMS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAIL,
  SIGN_IN_FAIL_USER,
  SIGN_IN_COMPLETE_REQUEST,
  SIGN_IN_COMPLETE_SUCCESS,
  SIGN_IN_COMPLETE_FAIL,
  UPDATE_USER_EMAIL
} from '../constants/authConstants';

import { ADD_DIRECT_MEMBER } from '../views/MyTeamBackoffice/myTeamBackOffice.constants';

import {
  ADD_BIRTHDAY_FAIL,
  ADD_BIRTHDAY_REQUEST,
  ADD_BIRTHDAY_SUCCESS,
  ADD_DOCUMENTS_IMAGE_FAIL,
  ADD_DOCUMENTS_IMAGE_REQUEST,
  ADD_DOCUMENTS_IMAGE_SUCCESS,
  ADD_DOCUMENT_FAIL,
  ADD_DOCUMENT_SUCCESS,
  ADD_SELFIE_FAIL,
  ADD_SELFIE_SUCCESS,
  ADD_SELFIE_REQUEST,
  ADD_UTILITY_BILL_FAIL,
  ADD_UTILITY_BILL_REQUEST,
  ADD_UTILITY_BILL_SUCCESS,
  APPROVAL_KYC_TERMS_REQUEST,
  APPROVAL_KYC_TERMS_SUCCESS,
  APPROVAL_KYC_TERMS_FAIL
} from '../constants/documentConstants';
import { CLEAR_ALERT, CLEAR_MESSAGE } from '../constants/errorConstants';
import { REGISTER_ON_KYC_PLATFORM } from '../views/Marketplace/Marketplace.constants';

export const register = (state = {}, action) => {
  switch (action.type) {
    case CONTINUE_TO_AFFILIATE_TERMS:
      return {
        ...state,
        path: action.payload.path,
        referrerId: action.payload.referrerId
      };
    case CONTINUE_TO_REFERRAL_REQUEST:
      return {
        ...state,
        path: action.payload.path
      };

    case CONTINUE_TO_TERMS_CONDITION:
      return {
        ...state,
        referrerId: action.payload.referral,
        path: action.payload.path
      };

    case SET_APPROVAL_DATE:
      return {
        ...state,
        approvalDate: action.payload
      };

    case CONTINUE_TO_PHONE_NUMBER:
      return {
        ...state,
        referrerId: action.payload
      };

    case CREATE_USER_PHONE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case CREATE_USER_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        message: action.payload.message
      };

    case CREATE_USER_PHONE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case RESEND_PHONE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case RESEND_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data.user,
        message: action.payload.message
      };

    case RESEND_PHONE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CONFIRM_SOCIAL_PHONE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case CONFIRM_SOCIAL_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token
      };

    case CONFIRM_SOCIAL_PHONE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false
      };

    case CHANGE_EMAIL_LINK_REQUEST:
      return {
        ...state,
        loading: true
      };

    case CHANGE_EMAIL_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        message: action.payload.message
      };

    case CHANGE_EMAIL_LINK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CONFIRM_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case RESEND_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false
      };

    case RESEND_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CONFIRM_EMAIL_LINK_SUCCESS:
      return {
        ...state,
        user: action.payload.data.user,
        message: action.payload.message
      };

    case CONFIRM_EMAIL_LINK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SEND_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case SEND_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.payload.data.user,
        message: action.payload.message,
        loading: false
      };

    case SEND_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CONFIRM_SMS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case CONFIRM_SMS_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        message: action.payload.message,
        loading: false
      };

    case CONFIRM_SMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case RESEND_SMS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case RESEND_SMS_SUCCESS:
      return {
        ...state,
        message: action.payload,
        loading: false
      };

    case RESEND_SMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SOCIAL_REFERRAL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case SOCIAL_REFERRAL_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data.data.user,
        token: action.payload.token,
        referrerId: action.payload.referrerId
      };

    case SOCIAL_REFERRAL_FAIL:
      return {
        ...state,
        loading: false
      };

    case ADD_SOCIAL_TERMS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_SOCIAL_TERMS_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case ADD_SOCIAL_TERMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_SOCIAL_PHONE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_SOCIAL_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token
      };

    case ADD_SOCIAL_PHONE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case RESEND_SOCIAL_PHONE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case RESEND_SOCIAL_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload
      };

    case RESEND_SOCIAL_PHONE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SOCIAL_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
        path: action.payload.path,
        referrerId: action.payload.referrerId
      };

    case SOCIAL_REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        user: action.payload.user.user,
        token: action.payload.token,
        path: action.payload.path
      };

    case SIGN_UP_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case SIGN_UP_CUSTOMER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        message: action.payload.message
      };

    case SIGN_UP_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case UPDATE_SOCIAL_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case UPDATE_SOCIAL_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case UPDATE_SOCIAL_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SIGN_IN_FAIL_USER:
      return {
        ...state,
        loading: false,
        user: action.payload
      };

    case ADD_DIRECT_MEMBER.SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user
      };

    case CLEAR_REGISTER_STATE:
      return {
        register: null
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.message
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export const forgottenCredentials = (state = {}, action) => {
  switch (action.type) {
    case FORGOTTEN_ID_REQUEST:
      return {
        loading: true
      };

    case FORGOTTEN_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case FORGOTTEN_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case FORGOTTEN_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case FORGOTTEN_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case FORGOTTEN_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case FORGOTTEN_PASSWORD_SMS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case FORGOTTEN_PASSWORD_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
        smsCode: action.payload
      };

    case FORGOTTEN_PASSWORD_SMS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case CONFIRM_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.payload
      };

    case CONFIRM_NEW_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload
      };

    case LOGOUT:
      return null;

    default:
      return state;
  }
};

export const userInfo = (state = { user: null }, action) => {
  switch (action.type) {
    case LOGIN_FACEBOOK_REQUEST:
      return {
        loading: true
      };

    case LOGIN_FACEBOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data.user,
        kycStatus: action.payload.data.kycStatus,
        token: action.payload.data.token
      };

    case LOGIN_FACEBOOK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SOCIAL_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case CREATE_SOCIAL_REFERRER_ID_REQUEST:
      return {
        loading: false,
        user: action.payload.data.user,
        kycStatus: action.payload.data.kycStatus,
        token: action.payload.data.token
      };

    case CREATE_SOCIAL_REFERRER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data.user,
        kycStatus: action.payload.data.kycStatus,
        token: action.payload.data.token
      };

    case CREATE_SOCIAL_REFERRER_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
        // user: action.payload.data.user,
        // kycStatus: action.payload.data.kycStatus,
        // token: action.payload.data.token,
        // signInToken: action.payload.signInToken,
        referrerId: null
      };

    case SIGN_IN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SIGN_IN_COMPLETE_REQUEST:
      return {
        ...state,
        loading: true,
        loadingSignIn: true
      };

    case SIGN_IN_COMPLETE_SUCCESS:
      return {
        loading: false,
        loadingSignIn: false,
        ...action.payload.data,
        // user: action.payload.data.user,
        // kycStatus: action.payload.data.kycStatus,
        // token: action.payload.data.token,
        // signInToken: action.payload.signInToken,
        referrerId: null
      };

    case SIGN_IN_COMPLETE_FAIL:
      return {
        ...state,
        loading: false,
        loadingSignIn: false,
        error: action.payload
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false
        // user: action.payload.user,
        // kycStatus: action.payload.kycStatus,
        // token: action.payload.token
      };

    case UPDATE_SOCIAL_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false
        // user: action.payload.user,
        // kycStatus: action.payload.kycStatus,
        // token: action.payload.token
      };

    case APPROVAL_KYC_TERMS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case APPROVAL_KYC_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        kycStatus: action.payload.kycStatus
      };

    case APPROVAL_KYC_TERMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      };

    case EDIT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          profileImageUrl: action.payload
        }
      };

    case UPDATE_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        kycStatus: action.payload
      };

    case ADD_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_DOCUMENTS_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_DOCUMENTS_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        kycStatus: action.payload.data.kycStatus
      };

    case ADD_DOCUMENTS_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_SELFIE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_SELFIE_SUCCESS:
      return {
        ...state,
        loading: false,
        kycStatus: action.payload.data.kycStatus
      };

    case ADD_SELFIE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_UTILITY_BILL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_UTILITY_BILL_SUCCESS:
      return {
        ...state,
        loading: false,
        kycStatus: action.payload.data.kycStatus
      };

    case ADD_UTILITY_BILL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        kycStatus: action.payload.data.kycStatus
      };

    case LOGIN_GOOGLE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case LOGIN_GOOGLE_SUCCESS:
      return {
        loading: false,
        user: action.payload.data.user,
        kycStatus: action.payload.data.kycStatus,
        token: action.payload.data.token
      };

    case LOGIN_GOOGLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case LOGIN_LINKEDIN_REQUEST:
      return {
        loading: true
      };

    case LOGIN_LINKEDIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data.user,
        kycStatus: action.payload.data.kycStatus,
        token: action.payload.data.linkedInToken
      };

    case LOGIN_LINKEDIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case LOGIN_APPLE_REQUEST:
      return {
        loading: false
      };

    case LOGIN_APPLE_SUCCESS:
      return {
        loading: false,
        user: action.payload.data.user,
        kycStatus: action.payload.data.kycStatus,
        token: action.payload.data.token
      };

    case LOGIN_APPLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ADD_BIRTHDAY_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_BIRTHDAY_SUCCESS:
      return {
        ...state,
        loading: false,
        kycStatus: action.payload
      };

    case ADD_BIRTHDAY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case SET_REFFERAL_ID:
      return {
        ...state,
        referrerId: parseInt(action.payload)
      };

    case UPDATE_USER_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload
        }
      };

    case REGISTER_ON_KYC_PLATFORM.REQUEST:
      return {
        ...state
      };

    case REGISTER_ON_KYC_PLATFORM.SUCCESS:
      return {
        ...state,
        isX1KycRegistered: true
      };

    case REGISTER_ON_KYC_PLATFORM.FAIL:
      return {
        ...state
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload
      };

    case LOGOUT:
      return {
        ...state,
        userInfo: null
      };

    default:
      return state;
  }
};

export const toggleModal = (
  state = {
    isOpen: false,
    isSideBarOpen: false,
    isModalMobileOpen: false,
    isSideBarMobileOpen: false
  },
  action
) => {
  switch (action.type) {
    case SET_TOOGLE_MODAL:
      return {
        ...state,
        isOpen: action.payload
      };

    case SET_TOOGLE_SIDE_BAR:
      return {
        ...state,
        isSideBarOpen: action.payload
      };

    case SET_TOOGLE_MODAL_MOBILE:
      return {
        ...state,
        isModalMobileOpen: action.payload
      };

    case SET_SIDEBAR_MOBILE:
      return {
        ...state,
        isSideBarMobileOpen: action.payload
      };

    default:
      return state;
  }
};
