import axios from 'axios';

import { createAxiosConfigWithAuthHeader } from '../../../utils/createAxiosConfigWithAuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

export const createUserWalletAsync = async (address) => {
  const url = `${API_URL}/user/wallet`;

  return await axios.post(url, { address }, createAxiosConfigWithAuthHeader());
};

export const deleteUserWalletAsync = async (id) => {
  const url = `${API_URL}/user/wallet/${id}`;

  return await axios.delete(url, { id }, createAxiosConfigWithAuthHeader());
};

export const reactivateUserWalletAsync = async (id) => {
  const url = `${API_URL}/user/wallet/${id}`;

  return await axios.put(url, { id }, createAxiosConfigWithAuthHeader());
};

export const editUserWalletAsync = async (id, label) => {
  const url = `${API_URL}/user/wallet`;

  return await axios.put(url, { id, label }, createAxiosConfigWithAuthHeader());
};

export const createWithdrawPartnerAsync = async (
  amount,
  userWalletId,
  emailCode,
  twoFACode,
  networkType
) => {
  const url = `${API_URL}/user/balance/withdraw/confirm`;

  return await axios.post(
    url,
    { amount, userWalletId, emailCode, twoFACode, networkType },
    createAxiosConfigWithAuthHeader()
  );
};

export const createWithdrawExplorerAsync = async (
  amount,
  userWalletId,
  emailCode,
  twoFACode
) => {
  const url = `${API_URL}/explorer/withdraw/confirm`;

  return await axios.post(
    url,
    { amount, userWalletId, emailCode, twoFACode },
    createAxiosConfigWithAuthHeader()
  );
};

export const createWithdrawVerificationEmailAsync = async (
  amount,
  userWalletId,
  code
) => {
  const url = `${API_URL}/user/balance/withdraw/confirm`;

  return await axios.post(
    url,
    { amount, userWalletId, verificationCode: code },
    createAxiosConfigWithAuthHeader()
  );
};

export const createWithdrawExplorerVerificationEmailAsync = async (
  amount,
  userWalletId,
  code
) => {
  const url = `${API_URL}/explorer/withdraw/confirm`;

  return await axios.post(
    url,
    { amount, userWalletId, verificationCode: code },
    createAxiosConfigWithAuthHeader()
  );
};

export const createWithdrawAmbassadorAsync = async (
  amount,
  userBankAccountId,
  currencyType
) => {
  const url = `${API_URL}/user/balance/withdraw/ambassador`;

  return await axios.post(
    url,
    currencyType === 1
      ? {
          amount,
          currencyType,
          userBankAccountId
        }
      : {
          amount,
          currencyType
        },
    createAxiosConfigWithAuthHeader()
  );
};

export const addTopUpAsync = async (
  amount,
  name,
  cvc,
  cardNumber,
  expireDate
) => {
  const url = `${API_URL}/user/balance/top-up`;

  return await axios.post(
    url,

    {
      amount: parseInt(amount),
      creditCardId: null,
      name: name,
      cardNumber: cardNumber.replace(/\s/g, ''),
      cardExpire: expireDate,
      cvc: cvc
    },

    createAxiosConfigWithAuthHeader()
  );
};

export const withdrawExchangeAsync = async (amount) => {
  const url = `${API_URL}/user/balance/withdraw/exchange`;

  return await axios.post(
    url,
    { amount: amount.includes('.') ? parseFloat(amount) : parseInt(amount) },
    createAxiosConfigWithAuthHeader()
  );
};

export const getWithdrawAvailabilitiAsync = async () => {
  return await axios.get(
    `${API_URL}/user/balance/withdraw/availability`,
    createAxiosConfigWithAuthHeader()
  );
};
