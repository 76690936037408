import React from 'react';
import { CaptionText, H3 } from '../../styles/FontStyles';
import { Button, Flexed } from '../../styles/GlobalComponents';
// import { getGlobalPoolBonus } from './Portfolio.actions';
import { Table } from './Portfolio.styled';

export const RioCalculator = ({ setCalculator }) => {
  return (
    <div>
      <H3 mb='20' center>
        Calculate your ROI
      </H3>
      <Table>
        <thead>
          <tr>
            <th>Timeframe</th>
            <th>ROI</th>
            <th>ulx per $1000</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1d</td>
            <td>0.2%</td>
            <td>3.46</td>
          </tr>
          <tr>
            <td>7d</td>
            <td>1.7%</td>
            <td>24.27</td>
          </tr>
          <tr>
            <td>30d</td>
            <td>7.3%</td>
            <td>104.32</td>
          </tr>
          <tr>
            <td>365d (API)</td>
            <td>92.9%</td>
            <td>1322.4</td>
          </tr>
        </tbody>
      </Table>
      <CaptionText center mt='20'>
        Calculated based on current rates. Compoinding once annualy. Rates are
        estimates provided for your convenience only, and by no means represent
        guaranteed returns.
      </CaptionText>
      <Flexed center column>
        <Button mt='40' w='200px' onClick={() => setCalculator(false)}>
          Close
        </Button>
      </Flexed>
    </div>
  );
};
