import React from 'react';
import { Warning } from './Warning';
import { ChangeEmail } from './ChangeEmail';
import { Success } from './Success';

export const ChangeEmailSteps = ({ step, setStep }) => {
  const steps = (step) => {
    switch (step) {
      case 1:
        return <Warning setStep={setStep} />;
      case 2:
        return <ChangeEmail setStep={setStep} />;
      case 3:
        return <Success />;

      default:
        return <div></div>;
    }
  };
  return <>{steps(step)}</>;
};
