const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const GET_CASHBACK = {
  REQUEST: getRequestConst('GET_CASHBACK'),
  SUCCESS: getSuccessConst('GET_CASHBACK'),
  FAIL: getFailConst('GET_CASHBACK')
};

export const CLAIM_CASHBACK = {
  REQUEST: getRequestConst('CLAIM_CASHBACK'),
  SUCCESS: getSuccessConst('CLAIM_CASHBACK'),
  FAIL: getFailConst('CLAIM_CASHBACK')
};
