import React from 'react';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CaptionText, H3, P1, P3 } from '../../styles/FontStyles';
import { Button, Flexed } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  Icon,
  IconPortfolioDiamond,
  IconPortfolioOffice
  // IconPortfolioOrders
} from '../../styles/Icons';
import { BoxPortfolio, SummaryGrid } from './Portfolio.styled';
import { useTranslation } from 'react-i18next';
import { dateDifferenceInDaysFromToday } from '../../helpers/helperFunctions';
import { useHistory } from 'react-router-dom';

export const MembershipStatus = () => {
  const { t } = useTranslation('my_hubs');
  const history = useHistory();

  const { currentVirtualOfficeLicence, lifetimeRank, qualifiedRank } =
    useSelector((state) => state.portfolio);

  return (
    <>
      <Flexed mb='20' between alignEnd>
        <H3>{t('my_membership_status')}</H3>
      </Flexed>
      <SummaryGrid>
        <BoxPortfolio hasButton>
          <Flexed center>
            <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
              <IconPortfolioOffice />
            </Icon>
            <div>
              <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                {t('backoffice_subscription')}
              </CaptionText>
              <P1 bold flex alignEnd gap='5px'>
                {dateDifferenceInDaysFromToday(
                  currentVirtualOfficeLicence?.dateEnd
                )}
                <P3 mb='1'>{t('days_left')}</P3>
              </P1>
            </div>
          </Flexed>
          <Button
            w='100px'
            h='34px'
            onClick={() => history.push('/backoffice/settings/virtual-office')}>
            {t('add_time')}
          </Button>
        </BoxPortfolio>

        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioDiamond />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('current_rank')}
            </CaptionText>

            <P1 bold flex alignEnd gap='5px'>
              {lifetimeRank}
            </P1>
          </div>
        </BoxPortfolio>

        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioDiamond />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('qualified_rank')}
            </CaptionText>

            <P1 bold flex alignEnd gap='5px'>
              {qualifiedRank}
            </P1>
          </div>
        </BoxPortfolio>
      </SummaryGrid>
    </>
  );
};
