import React from 'react';
import { P1, P3 } from '../../../styles/FontStyles';
import { Flexed, UserImageCircle } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';

import { SidebarModal } from '../../../components/common/Sidebar/Sidebar';
import { formatVP } from '../../../helpers/helperFunctions';

export const UserInfoModal = ({
  userModal,
  setUserModal,
  userForModal,
  rootTeamMemberInfo
}) => {
  return (
    <SidebarModal
      isOpen={userModal}
      setOpen={setUserModal}
      title={'User info'}
      sidebarWidth={'400px'}>
      <Flexed column center>
        <UserImageCircle
          image={userForModal?.profileImageUrl}
          avatar
          avatarSize='50px'
          size='100px'
          margin='0 0 20px 0'
        />
        <P3 color={color.colors.primary900} mb='5'>
          ID: {userForModal.id}
        </P3>
        <P1
          bold
          mb='20'
          center
          color={color.colors.neutral900}
          style={{ maxWidth: '100%' }}>
          <div className='truncated'>
            <div>{userForModal?.username ?? '---'}</div>
          </div>
        </P1>
        <P3 color={color.colors.neutral700} mb='5'>
          Personal: {formatVP(rootTeamMemberInfo?.leftLegInfo?.legTotalVolume)}
        </P3>
        <P3 color={color.colors.neutral700} mb='5'>
          Mining: {formatVP(rootTeamMemberInfo?.directTotalVolume)}
        </P3>
        <P3 color={color.colors.neutral700} mb='5'>
          Community:{' '}
          {formatVP(rootTeamMemberInfo?.rightLegInfo?.legTotalVolume)}
        </P3>
        {/* <Button
          mt='40'
          onClick={() => {
            onPlusClick(userForModal?.id, null);
            setUserModal(false);
          }}
        >
          <Flexed center gap='10px' justifyCenter>
            Add user
            <Icon color={color.colors.neutral50}>
              <IconStructurePlus />
            </Icon>
          </Flexed>
        </Button> */}
      </Flexed>
    </SidebarModal>
  );
};

{
  /* <UserInfoModal
userModal={userModal}
setUserModal={setUserForModal}
userForModal={userForModal}
rootTeamMemberInfo={rootTeamMemberInfo}
/> */
}
