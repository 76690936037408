import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { CaptionText, H2 } from '../../styles/FontStyles';
import { CountdownNumbers } from './CashbackBonusStyled';

export const Counter = ({ timestamp }) => {
  const { t } = useTranslation('cashback');

  return (
    <Countdown
      date={timestamp}
      renderer={({ days, hours, minutes, seconds }) => (
        <CountdownNumbers>
          <div>
            <H2>{days}</H2>
            <CaptionText caps> {t('days')}</CaptionText>
          </div>
          <div>
            <H2>{hours}</H2>
            <CaptionText caps> {t('hours')}</CaptionText>
          </div>
          <div>
            <H2>{minutes}</H2>
            <CaptionText caps> {t('minutes')}</CaptionText>
          </div>
          <div>
            <H2>{seconds}</H2>
            <CaptionText caps> {t('seconds')}</CaptionText>
          </div>
        </CountdownNumbers>
      )}
    />
  );
};
