import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import airplane from '../../assets/images/backoffice/Airplane.png';
import { LinearButton } from '../../components/common/LinearButton';
import { View, WelcomeContainer, AmbassadorTitle } from './WelcomeStyled';

import { useWindowWide } from '../../utils/wideScreen';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from '../../routes/routes';

const WelcomeAmbassador = () => {
  const { user } = useSelector((state) => state.userDetails);
  const history = useHistory();
  const wide = useWindowWide(1000);

  useEffect(() => {
    setTimeout(() => {
      history.push(DASHBOARD);
    }, 60000);
  }, []);

  const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };
  return (
    <View>
      <div style={style}>
        <WelcomeContainer style={{ alignItems: wide && 'flex-start' }}>
          <img src={airplane} alt='' style={{ width: 160, height: 160 }} />
          <AmbassadorTitle>
            Welcome,{' '}
            {user?.userRank?.rank?.name
              ? user?.userRank?.rank?.name
              : 'Ambassador'}{' '}
            🔥{' '}
          </AmbassadorTitle>

          <p
            style={{
              marginBottom: 80,
              textAlign: 'center',
              lineHeight: 1.6,
              color: '#E1DBDB',
              fontSIze: 14
            }}>
            You’ll now have <b>your own business</b> and our full support
          </p>
          <Link to={DASHBOARD}>
            <LinearButton width='230px' text='Continue to dashboard' />
          </Link>
        </WelcomeContainer>
      </div>
    </View>
  );
};

export default WelcomeAmbassador;
