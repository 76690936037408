import axios from 'axios';
import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  GET_ACCOUNTING_WITHDRAWALS_REQUEST,
  GET_ACCOUNTING_WITHDRAWALS_SUCCESS,
  GET_ACCOUNTING_WITHDRAWALS_FAIL
} from '../constants/accountingConstants';

import {
  createAxiosConfigWithAuthHeader,
  tryExecuteSimpleAction
} from './common';
const API_URL = process.env.REACT_APP_API_URL;

export const getInvoices =
  (count, lastWeekStartDate) => async (dispatch, getState) => {
    await tryExecuteSimpleAction(
      dispatch,
      GET_INVOICES_REQUEST,
      async () => {
        const { data } = await axios.get(
          `${API_URL}/user/accounting/invoices`,
          {
            params: {
              count,
              lastWeekStartDate
            }
          },
          createAxiosConfigWithAuthHeader(getState)
        );

        dispatch({
          type: GET_INVOICES_SUCCESS,
          payload: data.data.invoices
        });
      },
      GET_INVOICES_FAIL
    );
  };

export const getTransactions =
  (countPerPage, numberOfPage) => async (dispatch, getState) => {
    await tryExecuteSimpleAction(
      dispatch,
      GET_TRANSACTIONS_REQUEST,
      async () => {
        const { data } = await axios.get(
          `${API_URL}/user/accounting/transactions`,
          {
            params: {
              countPerPage,
              numberOfPage
            }
          },
          createAxiosConfigWithAuthHeader(getState)
        );

        dispatch({
          type: GET_TRANSACTIONS_SUCCESS,
          payload: data.data.transactions
        });
      },
      GET_TRANSACTIONS_FAIL
    );
  };

export const getWithdrawals =
  (countPerPage, numberOfPage) => async (dispatch, getState) => {
    await tryExecuteSimpleAction(
      dispatch,
      GET_ACCOUNTING_WITHDRAWALS_REQUEST,
      async () => {
        const { data } = await axios.get(
          `${API_URL}/user/accounting/withdrawals`,
          {
            params: {
              countPerPage,
              numberOfPage
            }
          },
          createAxiosConfigWithAuthHeader(getState)
        );

        dispatch({
          type: GET_ACCOUNTING_WITHDRAWALS_SUCCESS,
          payload: data.data.withdrawals
        });
      },
      GET_ACCOUNTING_WITHDRAWALS_FAIL
    );
  };
