import React from 'react';
import { useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';

import { WelcomeContainer, SubTitle, WelcomeFooter } from './WelcomeStyled';

import crown from '../../assets/images/backoffice/crown.png';
import marketing from '../../assets/images/backoffice/horn.png';

import { LinearButton } from '../../components/common/LinearButton';

import { RankComponent } from './RankComponent';

import { setUserToAmbassador } from '../../actions/userDetailsActions';
import Spinner from '../../components/common/Spinner';
import Alert from '../../components/common/Alert';

const WelcomeStart = ({
  rankPartner,
  setRankPartner,
  rankAmbassador,
  setRankAmbassador,
  isOpen,
  setIsOpen
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.userDetails);
  return (
    <>
      {error && <Alert message={error?.error} />}
      {loading ? (
        <Spinner />
      ) : (
        <WelcomeContainer>
          {/* <img src={logo} alt='' style={{ width: 137 }} /> */}

          <SubTitle>Choose your Givvo career</SubTitle>
          <div>
            <RankComponent
              icon={crown}
              title='Givvo Partner'
              rankName='Premium'
              subtitle='Start your business with our full support'
              percent='40'
              onClick={() => {
                setRankPartner('Partner');
                setRankAmbassador('');
              }}
              rank={rankPartner}
            />
            <RankComponent
              icon={marketing}
              title='Givvo Ambassador'
              rankName='Basic'
              subtitle='Earn by recommending our products'
              percent='15'
              onClick={() => {
                setRankPartner('');
                setRankAmbassador('Ambassador');
              }}
              rank={rankAmbassador}
            />
          </div>

          <WelcomeFooter>
            Can’t decide?{' '}
            <span onClick={() => setIsOpen(!isOpen)}>Compare careers here</span>
          </WelcomeFooter>
          <LinearButton
            width='295px'
            text='Choose career'
            onClick={() => {
              if (rankAmbassador === 'Ambassador') {
                dispatch(setUserToAmbassador(history));
              }

              if (rankPartner === 'Partner') {
                history.push('/welcome-packages');
              }
            }}
            disabled={
              rankPartner === '' && rankAmbassador === '' ? true : false
            }
          />
        </WelcomeContainer>
      )}
    </>
  );
};

export default WelcomeStart;
