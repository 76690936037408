import debouce from 'lodash.debounce';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Calendar } from 'react-date-range';
import { useSelector } from 'react-redux';
import { Mobile } from '../../../App';

import { SidebarModal } from '../../../components/common/Sidebar/Sidebar';
import {
  Button,
  Flexed,
  PageTitle,
  SectionSeparator,
  TabSelectButton
} from '../../../styles/GlobalComponents';
import {
  FlexBetween,
  FlexCenter,
  FlexJustifyCenter
} from '../../../styles/stylesStyled';
import { EarningDetails } from './EarningDetails';
import { EarningsHistoryItem } from './EarningsHistoryItem';
import {
  ClearAllFilters,
  Collapsable,
  CollapsableAbsolute,
  DateInputContainer,
  DateInputLabelContainer,
  FilterButton,
  FilterContainer,
  SearchInput,
  SortContainer
} from './EarningsHistoryStyled';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from 'react-i18next';
import { AnimatedItem } from '../../../components/animation/AnimatedItem';
import { P1, P2, P3 } from '../../../styles/FontStyles';
import { color } from '../../../styles/GlobalStyles';
import {
  Icon,
  IconCalendar,
  IconFilter,
  IconSort,
  IconStructureNavArrow,
  IconX
} from '../../../styles/Icons';

export const EarningsHistory = ({
  setMobilePage,
  setEarningsType,
  setSearchId,
  setStartDate,
  setSort,
  setFilter,
  setEndDate,
  filter,
  sort,
  startDate,
  endDate,
  setCountPerPage,
  countPerPage
}) => {
  const { t } = useTranslation('earnings');

  const { history } = useSelector((s) => s.earnings);

  const [filterToggle, setFilterToggle] = useState(false);
  const [dateToggle, setDateToggle] = useState(false);

  const [earningDetails, setEarningDetails] = useState('');

  const handleChange = async (e) => {
    setSearchId(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const SortSelected = () => {
    const BtnX = () => (
      <Icon color={color.colors.neutral50} onClick={() => setSort('')} ml='10'>
        <IconX size='15' />
      </Icon>
    );

    const ArrowSort = ({ down }) => (
      <Icon
        color={color.colors.neutral50}
        ml='2'
        style={{
          transform: down ? 'rotate(180deg)' : ''
        }}>
        <IconStructureNavArrow size='15' />
      </Icon>
    );

    switch (sort) {
      case 'dateUp':
        return (
          <div>
            {t('date')}
            <ArrowSort />
            <BtnX />
          </div>
        );
      case 'dateDown':
        return (
          <div>
            {t('date')}
            <ArrowSort down />
            <BtnX />
          </div>
        );
      case 'priceUp':
        return (
          <div>
            {t('price')}
            <ArrowSort />
            <BtnX />
          </div>
        );
      case 'priceDown':
        return (
          <div>
            {t('price')}
            <ArrowSort down />
            <BtnX />
          </div>
        );
      default:
        return null;
    }
  };

  const HandleToggleFilter = (toggle) => {
    setFilterToggle(filterToggle === toggle ? '' : toggle);
    setDateToggle('');
  };

  const HandleToggleDate = (toggle) => {
    setDateToggle(dateToggle === toggle ? '' : toggle);
  };

  const HandleChangeDateStart = (date) => {
    setStartDate(date);
    setDateToggle(false);
  };

  const HandleChangeDateEnd = (date) => {
    setEndDate(date);
    setDateToggle(false);
  };

  const HandleChangeFilter = (filter) => {
    setFilter(filter);
    setFilterToggle('');

    switch (filter) {
      case 'Team bonus':
        return setEarningsType([1]);
      case 'Direct bonus':
        return setEarningsType([2]);
      case 'Matching bonus':
        return setEarningsType([3]);
      case 'Customer bonus':
        return setEarningsType([4]);
      case 'Cashback bonus':
        return setEarningsType([5]);
      case 'Global pool bonus':
        return setEarningsType([6]);
      case 'Top performer pool bonus':
        return setEarningsType([7]);
      case 'Package rank':
        return setEarningsType([8]);
      case 'Hodl Pool bonus':
        return setEarningsType([9]);
      case 'Legacy Bonuses':
        return setEarningsType([5, 6, 7, 9]);
      case null:
        return setEarningsType(null);
      default:
        return '';
    }
  };

  const HandleChangeSort = (sort) => {
    setSort(sort);
    setFilterToggle('');
  };

  const HandleClearAllFilters = () => {
    setFilter('');
    setSort('');
    setStartDate('');
    setEndDate('');
    setFilterToggle('');
    setDateToggle('');
    setEarningsType(null);
  };

  const IsAnyFilterSelected = filter || startDate || endDate || sort;

  const showMoreBonuses = (countPerPage) => {
    let page = countPerPage;
    page = page + 50;
    setCountPerPage(page);
  };

  return (
    <div>
      <FlexBetween style={{ marginBottom: 20 }}>
        <PageTitle>{t('title_history')}</PageTitle>
        <Mobile>
          <P1
            pointer
            color={color.colors.primary900}
            onClick={() => setMobilePage('Earnings')}>
            {t('show_earnings')}
          </P1>
        </Mobile>
      </FlexBetween>
      <FilterContainer>
        <SearchInput
          placeholder={t('search_label')}
          onChange={debouncedResults}
        />
        <FilterButton
          toggled={filterToggle === 'filter'}
          onClick={() => HandleToggleFilter('filter')}>
          <Icon color={color.colors.neutral50}>
            {filterToggle === 'filter' ? <IconX /> : <IconFilter />}
          </Icon>
        </FilterButton>
        <FilterButton
          toggled={filterToggle === 'sort'}
          onClick={() => HandleToggleFilter('sort')}>
          <Icon color={color.colors.neutral50}>
            {filterToggle === 'sort' ? <IconX /> : <IconSort />}
          </Icon>
        </FilterButton>
        <FilterButton
          toggled={filterToggle === 'date'}
          onClick={() => HandleToggleFilter('date')}>
          <Icon color={color.colors.neutral50}>
            {filterToggle === 'date' ? <IconX /> : <IconCalendar />}
          </Icon>
        </FilterButton>
      </FilterContainer>

      <Collapsable
        expanded={filterToggle === 'filter'}
        height={filterToggle === 'filter' ? '200px' : '100px'}>
        <Flexed wrap='true'>
          <TabSelectButton
            m='0 10px 15px 0'
            selected={filter === 'Direct bonus'}
            onClick={() => HandleChangeFilter('Direct bonus')}>
            <div>{t('direct_bonus')}</div>
          </TabSelectButton>
          <TabSelectButton
            m='0 10px 15px 0'
            selected={filter === 'NFT bonus'}
            onClick={() => HandleChangeFilter('NFT bonus')}>
            <div>NFT bonus</div>
          </TabSelectButton>

          <TabSelectButton
            m='0 10px 15px 0'
            selected={filter === 'Metaverse bonus'}
            onClick={() => HandleChangeFilter('Metaverse bonus')}>
            <div>Metaverse bonus</div>
          </TabSelectButton>

          <TabSelectButton
            m='0 10px 15px 0'
            selected={filter === 'Cashback bonus'}
            onClick={() => HandleChangeFilter('Cashback bonus')}>
            <div>Fast Start bonus</div>
          </TabSelectButton>
          <TabSelectButton
            m='0 10px 15px 0'
            selected={filter === 'Package rank'}
            onClick={() => HandleChangeFilter('Package rank')}>
            <div>{t('package_rank')}</div>
          </TabSelectButton>
        </Flexed>
      </Collapsable>
      <Flexed>
        <Collapsable expanded={filterToggle === 'sort'} height={'256px'}>
          <SortContainer>
            <div onClick={() => HandleChangeSort('dateUp')}>
              <P2>{t('latest_date')}</P2>
              <Icon color={color.colors.neutral900} ml='5'>
                <IconStructureNavArrow size='18' />
              </Icon>
            </div>

            <SectionSeparator margin='0' />

            <div onClick={() => HandleChangeSort('dateDown')}>
              <P2>{t('oldest_date')}</P2>
              <Icon
                color={color.colors.neutral900}
                ml='5'
                style={{ transform: 'rotate(180deg)' }}>
                <IconStructureNavArrow size='18' />
              </Icon>
            </div>
            <SectionSeparator margin='0' />
            <div onClick={() => HandleChangeSort('priceUp')}>
              <P2> {t('price_low_to_high')}</P2>
              <Icon color={color.colors.neutral900} ml='5'>
                <IconStructureNavArrow size='18' />
              </Icon>
            </div>
            <SectionSeparator margin='0' />
            <div onClick={() => HandleChangeSort('priceDown')}>
              <P2> {t('price_high_to_low')}</P2>
              <Icon
                color={color.colors.neutral900}
                ml='5'
                style={{ transform: 'rotate(180deg)' }}>
                <IconStructureNavArrow size='18' />
              </Icon>
            </div>
          </SortContainer>
        </Collapsable>
      </Flexed>
      <Collapsable expanded={filterToggle === 'date'} height={'100px'}>
        <FlexBetween>
          <DateInputContainer>
            <DateInputLabelContainer onClick={() => HandleToggleDate('start')}>
              <Icon color={color.colors.neutral900} mr='16'>
                <IconCalendar />
              </Icon>
              {startDate
                ? moment(startDate).format('DD.MM.YYYY')
                : t('start_date')}
            </DateInputLabelContainer>
            <CollapsableAbsolute expanded={dateToggle === 'start'}>
              <Calendar
                onChange={(item) => {
                  HandleChangeDateStart(item);
                }}
                date={startDate}
              />
            </CollapsableAbsolute>
          </DateInputContainer>
          <DateInputContainer>
            <DateInputLabelContainer onClick={() => HandleToggleDate('end')}>
              <Icon color={color.colors.neutral900} mr='16'>
                <IconCalendar />
              </Icon>
              {endDate ? moment(endDate).format('DD.MM.YYYY') : t('end_date')}
            </DateInputLabelContainer>
            <CollapsableAbsolute expanded={dateToggle === 'end'} right>
              <Calendar
                onChange={(item) => {
                  HandleChangeDateEnd(item);
                }}
                date={endDate}
                minDate={startDate}
              />
            </CollapsableAbsolute>
          </DateInputContainer>
        </FlexBetween>
      </Collapsable>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
          // height: wide ? 'calc(100vh - 255px)' : '',
        }}>
        <FlexCenter
          style={{
            // marginBottom: IsAnyFilterSelected && 20,
            visibility: IsAnyFilterSelected ? 'visible' : 'hidden',
            // overflow: 'auto',
            flexWrap: 'wrap'
          }}>
          {filter && (
            <TabSelectButton light selected={true} m='0 10px 15px 0'>
              <div>
                {filter}{' '}
                <Icon
                  color={color.colors.neutral50}
                  onClick={() => setFilter('')}
                  ml='10'>
                  <IconX size='15' />
                </Icon>
              </div>
            </TabSelectButton>
          )}

          {sort && (
            <TabSelectButton light selected={true} m='0 10px 15px 0'>
              {SortSelected()}
            </TabSelectButton>
          )}

          {startDate && endDate && (
            <TabSelectButton light selected={true} m='0 10px 15px 0'>
              <div>
                {moment(startDate).format('DD.MM.YYYY') +
                  ' - ' +
                  moment(endDate).format('DD.MM.YYYY')}

                <Icon
                  color={color.colors.neutral50}
                  onClick={() => {
                    setStartDate('');
                    setEndDate('');
                  }}
                  ml='10'>
                  <IconX size='15' />
                </Icon>
              </div>
            </TabSelectButton>
          )}

          <ClearAllFilters
            show={IsAnyFilterSelected}
            onClick={() => HandleClearAllFilters()}>
            {t('clear_all')}
          </ClearAllFilters>
        </FlexCenter>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            // overflowY: wide ? 'auto' : '',
            marginTop: 30,
            margin: '30px -10px 0 -10px',
            padding: '0 10px',
            paddingBottom: 10
          }}>
          {history?.map((earning, i) => (
            <AnimatedItem index={i} key={i}>
              <EarningsHistoryItem
                key={i}
                earning={earning}
                earningDetails={earningDetails}
                setEarningDetails={setEarningDetails}
              />
            </AnimatedItem>
          ))}
          {history?.length > 49 && history?.length === countPerPage && (
            <FlexJustifyCenter>
              <Button
                w='200px'
                mt='10'
                mb='20'
                onClick={() => showMoreBonuses(countPerPage)}>
                Show more
              </Button>
            </FlexJustifyCenter>
          )}
          {history?.length === 0 && (
            <P3 color={color.colors.neutral500}>{t('no_earnigns')}</P3>
          )}
        </div>
      </div>
      <SidebarModal
        isOpen={earningDetails}
        setOpen={setEarningDetails}
        title={t('earning_details')}
        sidebarWidth={'511px'}>
        <EarningDetails earning={earningDetails} />
      </SidebarModal>
    </div>
  );
};
