import axios from 'axios';

import { createAxiosConfigWithAuthHeader } from '../../utils/createAxiosConfigWithAuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

export const getPortfolioAsync = async () => {
  return await axios.get(
    `${API_URL}/explorer/portfolio`,
    createAxiosConfigWithAuthHeader()
  );
};

export const lockAutoStakeAsync = async (userPackageId, limitDateType) => {
  return await axios.put(
    `${API_URL}/package/autostake/lock`,
    {
      userPackageId,
      limitDateType
    },
    createAxiosConfigWithAuthHeader()
  );
};

export const changeAutoStakeAsync = async (userPackageId, newStatus) => {
  return await axios.put(
    `${API_URL}/package/autostake/change`,
    {
      userPackageId,
      newStatus
    },
    createAxiosConfigWithAuthHeader()
  );
};

export const getStakingWithdrawalInfoAsync = async (userPackageId, amount) => {
  return await axios.get(`${API_URL}/package/staking-withdraw-info`, {
    params: { userPackageId, amount }
  });
};

export const burnULXAsync = async (userPackageId, amount) => {
  return await axios.post(`${API_URL}/package/staking-withdraw`, {
    userPackageId,
    amount
  });
};
