const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const GET_TOP_PERFORMER_BONUS = {
  REQUEST: getRequestConst('GET_TOP_PERFORMER_BONUS'),
  SUCCESS: getSuccessConst('GET_TOP_PERFORMER_BONUS'),
  FAIL: getFailConst('GET_TOP_PERFORMER_BONUS')
};

export const CLAIM_TOP_PERFORMER_BONUS = {
  REQUEST: getRequestConst('CLAIM_TOP_PERFORMER_BONUS'),
  SUCCESS: getSuccessConst('CLAIM_TOP_PERFORMER_BONUS'),
  FAIL: getFailConst('CLAIM_TOP_PERFORMER_BONUS')
};
