import React, { useEffect } from 'react';
import { InputLabel } from '../../../styles/FontStyles';
import { Button } from '../../../styles/GlobalComponents';
import { FlexCenter } from '../../../styles/stylesStyled';
import {
  FlexResponsive,
  ImagePHContainer,
  ImagePHContainerFilled,
  KycBrowseFilesButton,
  Medium,
  RightMarginDiv,
  Small
} from '../KycStyled';

import { useDropzone } from 'react-dropzone';
import { Dropzone } from './Dropzone';
import { useTranslation } from 'react-i18next';
import { useWindowWide } from '../../../utils/wideScreen';
import { ImgState } from '../KycDocumentPhotos';

export const RenderID = ({
  id,
  imgStateFront,
  setImgStateFront,
  imgStateBack,
  setImgStateBack,
  imageFrontUrl,
  imageBackUrl,
  uploadIdFrontHandler,
  uploadIdBackHandler,
  setImage,
  setModal,
  setImage2,
  setModal2,
  percentage,
  setImageFrontUrl,
  setImageBackUrl,
  setDocumentFrontId,
  setDocumentBackId
}) => {
  const { t } = useTranslation('kyc.document_photos');
  const wide = useWindowWide(1000);

  const {
    acceptedFiles: acceptedFiles1,
    getRootProps: getRootProps1,
    isDragAccept: isDragAccept1,
    isDragReject: isDragReject1
  } = useDropzone({
    accept: { 'image/*': [] },
    multiple: false,
    useFsAccessApi: false
  });

  useEffect(() => {
    const file = acceptedFiles1[0];

    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setImageFrontUrl(objectUrl);
      setDocumentFrontId(file);
      setImgStateFront(1);
    }
  }, [acceptedFiles1]);

  const {
    acceptedFiles: acceptedFiles2,
    getRootProps: getRootProps2,
    isDragAccept: isDragAccept2,
    isDragReject: isDragReject2
  } = useDropzone({
    accept: { 'image/*': [] },
    multiple: false,
    useFsAccessApi: false
  });

  useEffect(() => {
    const file = acceptedFiles2[0];

    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setImageBackUrl(objectUrl);
      setDocumentBackId(file);
      setImgStateBack(1);
    }
  }, [acceptedFiles2]);

  return (
    <>
      {id === 1 && (
        <FlexResponsive>
          <RightMarginDiv>
            <InputLabel>{t('front_side')}</InputLabel>

            {imageFrontUrl ? (
              <ImagePHContainerFilled style={{ width: wide && 375 }}>
                <img
                  src={imageFrontUrl}
                  alt='upload'
                  style={{
                    height: '182px',
                    objectFit: 'cover',
                    filter: imgStateFront === 2 ? 'blur(8px)' : 'blur(0px)',
                    transition: '.3s filter ease'
                  }}
                />
                <ImgState
                  state={imgStateFront}
                  setImgState={setImgStateFront}
                  percentage={percentage}
                  uploadHandler={(e) => uploadIdFrontHandler(e)}
                  retake={() => {
                    setImage('');
                    setModal(true);
                  }}
                />
              </ImagePHContainerFilled>
            ) : (
              //DRAG AND DROP HERE---------------------------------------------------------------

              <Dropzone
                acceptedFiles={acceptedFiles1}
                getRootProps={getRootProps1}
                isDragAccept={isDragAccept1}
                isDragReject={isDragReject1}>
                <ImagePHContainer style={{ width: wide && 375 }}>
                  <div
                    style={{
                      textAlign: 'center'
                    }}>
                    <Medium>{t('photo_card_title')}</Medium>
                    <Small>{t('photo_card_subtitle')}</Small>

                    <FlexCenter style={{ justifyContent: 'center' }}>
                      <Button
                        fs='12px'
                        h='34px'
                        maxWidth='111px'
                        mr='12'
                        type='button'
                        onClick={() => setModal(true)}>
                        {t('take_photo_button')}
                      </Button>

                      <KycBrowseFilesButton>
                        <input
                          type='file'
                          accept='image/*'
                          style={{ display: 'none' }}
                          onChange={(e) => uploadIdFrontHandler(e)}
                        />
                        {t('browse_files_button')}
                      </KycBrowseFilesButton>
                    </FlexCenter>
                  </div>
                </ImagePHContainer>
              </Dropzone>
              //DRAG AND DROP HERE---------------------------------------------------------------
            )}
          </RightMarginDiv>
          <div>
            <InputLabel>{t('back_side')}</InputLabel>
            {imageBackUrl ? (
              <ImagePHContainerFilled style={{ width: wide && 375 }}>
                <img
                  src={imageBackUrl}
                  alt='upload'
                  style={{
                    height: '182px',
                    objectFit: 'cover',
                    filter: imgStateBack === 2 ? 'blur(8px)' : 'blur(0px)',
                    transition: '.3s filter ease'
                  }}
                />
                <ImgState
                  state={imgStateBack}
                  setImgState={setImgStateBack}
                  percentage={percentage}
                  uploadHandler={(e) => uploadIdBackHandler(e)}
                  retake={() => {
                    setImage2('');
                    setModal2(true);
                  }}
                />
              </ImagePHContainerFilled>
            ) : (
              <Dropzone
                acceptedFiles={acceptedFiles2}
                getRootProps={getRootProps2}
                isDragAccept={isDragAccept2}
                isDragReject={isDragReject2}>
                <ImagePHContainer style={{ width: wide && 375 }}>
                  <div
                    style={{
                      textAlign: 'center'
                    }}>
                    <Medium>{t('photo_card_title')}</Medium>
                    <Small>{t('photo_card_subtitle')}</Small>

                    <FlexCenter style={{ justifyContent: 'center' }}>
                      <Button
                        fs='12px'
                        h='34px'
                        maxWidth='111px'
                        mr='12'
                        type='button'
                        onClick={() => setModal2(true)}>
                        {t('take_photo_button')}
                      </Button>
                      <KycBrowseFilesButton>
                        <input
                          type='file'
                          accept='image/*'
                          style={{ display: 'none' }}
                          onChange={(e) => uploadIdBackHandler(e)}
                        />
                        {t('browse_files_button')}
                      </KycBrowseFilesButton>
                    </FlexCenter>
                  </div>
                </ImagePHContainer>
              </Dropzone>
            )}
          </div>
        </FlexResponsive>
      )}
    </>
  );
};
