import { tryExecuteSimpleAction } from '../../actions/common';
import { getWallets } from '../../actions/walletActions';

import {
  EARNINGS_HISTORY,
  EARNINGS_INIT
} from './earnignsBackoffice.constants';
import {
  getEarningHistoryAsync,
  getEarningsInfoAsync
} from './earnignsBackoffice.requests';

import { getWithdrawAvailabilitiAsync } from './Withdraw/Withdraw.request';

export const init = (numberOfPage, countPerPage) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    EARNINGS_INIT.REQUEST,
    async () => {
      let res = await getEarningsInfoAsync();
      const withdrawAvailability = await getWithdrawAvailabilitiAsync();

      const historyResponse = await getEarningHistoryAsync({
        id: '',
        sortBy: 'dateCreated',
        sortOrder: 'DESC',
        fromDate: '',
        toDate: '',
        numberOfPage: numberOfPage,
        countPerPage: countPerPage
      });

      dispatch({
        type: EARNINGS_INIT.SUCCESS,
        payload: {
          info: res?.data?.data?.info,
          history: historyResponse?.data?.data?.earnings,
          withdrawAvailability: withdrawAvailability?.data?.data
        }
      });
      dispatch(getWallets());
    },
    EARNINGS_INIT.FAIL
  );
};

export const getEarningHistory =
  ({
    id,
    sortBy,
    sortOrder,
    fromDate,
    toDate,
    status,
    earningsTypes,
    numberOfPage,
    countPerPage
  }) =>
  async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      EARNINGS_HISTORY.REQUEST,
      async () => {
        const { data } = await getEarningHistoryAsync({
          id,
          sortBy,
          sortOrder,
          fromDate,
          toDate,
          status,
          earningsTypes,
          numberOfPage,
          countPerPage
        });

        dispatch({
          type: EARNINGS_HISTORY.SUCCESS,
          payload: data?.data?.earnings
        });
      },
      EARNINGS_HISTORY.FAIL
    );
  };
