export const GET_PACKAGES_REQUEST = 'GET_PACKAGES_REQUEST';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_FAIL = 'GET_PACKAGES_FAIL';

export const ADD_PACKAGE_REQUEST = 'ADD_PACKAGE_REQUEST';
export const ADD_PACKAGE_SUCCESS = 'ADD_PACKAGE_SUCCESS';
export const ADD_PACKAGE_FAIL = 'ADD_PACKAGE_FAIL';

export const SELECTED_ID = 'SELECTED_ID';
export const COLLAPSE_PACKAGE = 'COLLAPSE_PACKAGE';
