import React from 'react';

import { BackofficeLayout } from '../layout/BackofficeLayout/layoutRoot';
import Accounting from '../views/Accounting/Accounting';
import { CashbackBonus } from '../views/CashbackBonus/CashbackBonus';
import { CookiePolicy } from '../views/CookiePolicy/CookiePolicy';
import { EarningsBackoffice } from '../views/EarningsBackoffice/EarningsBackoffice';
import MyTeamBackofficeRoot from '../views/MyTeamBackoffice/MyTeamBackofficeRoot';
import { Orders } from '../views/Orders/Orders';
import { Portfolio } from '../views/Portfolio/Portfolio';
import { PrivacyPolicy } from '../views/PrivacyPolicy/PrivacyPolicy';
import { TermsOfUse } from '../views/TermsOfUse/TermsOfUse';

import { Route } from 'react-router-dom';
import Dashboard from '../views/Dashboard/Dashboard';

import { Marketplace } from '../views/Marketplace/Marketplace';
import { Gaming } from '../views/Marketplace/Gaming/Gaming';
import { Ranking } from '../views/RankingBackoffice/Ranking';
import {
  ACCOUNTING,
  CASHBACK_BONUS,
  COOKIE_POLICY,
  DASHBOARD,
  EARNINGS,
  GAMING,
  MARKETPLACE,
  MY_HUBS,
  MY_TEAM,
  ORDERS,
  PRIVACY_POLICY,
  RANKING,
  RESOURCES,
  SETTINGS_LAYOUT,
  TERMS_OF_USE
} from './routes';
import { SettingsRoutes } from './settingsRoutes';
import { Resources } from '../views/Resources/Resources';

export const BackofficeRoutes = () => {
  return (
    <BackofficeLayout>
      <Route
        exact
        path={`${DASHBOARD}`}
        render={(props) => <Dashboard {...props} />}
      />

      <Route
        exact
        path={`${ORDERS}`}
        render={(props) => <Orders {...props} />}
      />

      <Route
        exact
        path={`${MY_HUBS}`}
        render={(props) => <Portfolio {...props} />}
      />

      <Route
        exact
        path={`${MY_TEAM}`}
        render={(props) => <MyTeamBackofficeRoot {...props} />}
      />

      <Route
        exact
        path={`${EARNINGS}`}
        render={(props) => <EarningsBackoffice {...props} />}
      />

      <Route
        exact
        path={`${ACCOUNTING}`}
        render={(props) => <Accounting {...props} />}
      />

      <Route
        path={`${CASHBACK_BONUS}`}
        render={(props) => <CashbackBonus {...props} />}
      />

      <Route
        exact
        path={`${TERMS_OF_USE}`}
        render={(props) => <TermsOfUse {...props} />}
      />

      <Route
        exact
        path={`${PRIVACY_POLICY}`}
        render={(props) => <PrivacyPolicy {...props} />}
      />

      <Route
        exact
        path={`${COOKIE_POLICY}`}
        render={(props) => <CookiePolicy {...props} />}
      />

      <Route
        exacts
        path={`${RANKING}`}
        render={(props) => <Ranking {...props} />}
      />

      <Route
        exact
        path={`${GAMING}`}
        render={(props) => <Gaming {...props} />}
      />

      <Route
        exact
        path={`${MARKETPLACE}`}
        render={(props) => <Marketplace {...props} />}
      />

      <Route
        exact
        path={`${RESOURCES}`}
        render={(props) => <Resources {...props} />}
      />

      <Route
        path={SETTINGS_LAYOUT}
        render={(props) => <SettingsRoutes {...props} />}
      />
    </BackofficeLayout>
  );
};
