import React from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../assets/images/backoffice/kyc1.png';
import { DASHBOARD } from '../../routes/routes';
import { H2, H3, P1, P2 } from '../../styles/FontStyles';
import { Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { Desktop, Mobile, TitleImage } from './KycStyled';

const KycStart = ({ history }) => {
  const { t } = useTranslation('kyc.start');
  return (
    <>
      <TitleImage>
        <img src={icon} alt='icon' style={{ marginBottom: 40, width: 162 }} />
      </TitleImage>

      <Desktop>
        <H2 mb='12' color={color.kycTitleColor}>
          {t('title')}
        </H2>

        <P1
          mb='40'
          maxWidth='600px'
          maxWidthSM='1000px'
          color={color.kycSubTitleColor}>
          {t('description')}
        </P1>
      </Desktop>
      <Mobile>
        <H3 mb='8' center color={color.kycTitleColor}>
          {t('title')}
        </H3>

        <P2 mb='32' center color={color.kycSubTitleColor}>
          {t('description')}
        </P2>
      </Mobile>

      <Desktop>
        <Button
          opacity={'true'}
          onClick={() => history.push('/kyc-agreement')}
          mr='20'
          h='44px'
          maxWidth='200px'>
          {t('continue')}
        </Button>
        <Button
          negative
          h='44px'
          maxWidth='200px'
          opacity={'true'}
          onClick={() => history.push(DASHBOARD)}>
          {t('verify_later')}
        </Button>

        {/* <AuthButton>asdasdasd</AuthButton> */}
      </Desktop>
      <Mobile>
        <Button mb='12' onClick={() => history.push('/kyc-agreement')}>
          {t('continue')}
        </Button>
        <Button negative onClick={() => history.push(DASHBOARD)}>
          {t('verify_later')}
        </Button>
      </Mobile>
    </>
  );
};

export default KycStart;
