import React from 'react';
import { P2, P3 } from '../../../styles/FontStyles';
import { Box } from '../../../styles/GlobalComponents';
import { Qualification } from '../CashbackBonusStyled';

import { Counter } from '../Counter';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const Section3 = ({ cashback }) => {
  const { t } = useTranslation('cashback');

  const date = new Date(cashback?.limitDate);
  const timestamp = date.getTime();

  return (
    <Box>
      <Qualification>
        <div>
          <P2 mb='8' bold flex between>
            {t('section3.title')}
          </P2>

          <P3>
            {t('section3.text')}{' '}
            {moment(cashback?.registrationDate).format('D.MM.YYYY')}
          </P3>
        </div>
        <Counter timestamp={timestamp} />
      </Qualification>
    </Box>
  );
};
