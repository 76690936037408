import { H4, P2 } from '../../styles/FontStyles';
import { Box, Button, Flexed } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  DashboardNotificationComponent,
  NotificationPill
} from './DashboardStyled';

export const DashboardNotification = ({
  pill,
  title,
  text,
  titleColor,
  buttonText,
  buttonOnClick
}) => {
  return (
    <Box>
      <DashboardNotificationComponent>
        <Flexed center between rowSM style={{ width: '100%', gap: 40 }}>
          <div>
            <NotificationPill>{pill}</NotificationPill>

            <H4 color={titleColor} mb='12'>
              {title}
            </H4>
            <P2 color={color.colors.neutral500}>{text}</P2>
          </div>
          {buttonText && (
            <Button
              style={{ flex: 'none' }}
              w='200px'
              wSM='100%'
              onClick={buttonOnClick}>
              {buttonText}
            </Button>
          )}
        </Flexed>
      </DashboardNotificationComponent>
    </Box>
  );
};
