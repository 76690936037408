import {
  getFailConst,
  getRequestConst,
  getSuccessConst
} from '../../helpers/helperFunctions';

const GET_HUBS_CONSTANT = 'GET_HUBS';

export const GET_HUBS = {
  REQUEST: getRequestConst(GET_HUBS_CONSTANT),
  SUCCESS: getSuccessConst(GET_HUBS_CONSTANT),
  FAIL: getFailConst(GET_HUBS_CONSTANT)
};

const ADD_HUB_CONSTANT = 'ADD_HUB';

export const ADD_HUB = {
  REQUEST: getRequestConst(ADD_HUB_CONSTANT),
  SUCCESS: getSuccessConst(ADD_HUB_CONSTANT),
  FAIL: getFailConst(ADD_HUB_CONSTANT)
};

const REGISTER_ON_KYC_PLATFORM_CONSTANT = 'REGISTER_ON_KYC_PLATFORM';

export const REGISTER_ON_KYC_PLATFORM = {
  REQUEST: getRequestConst(REGISTER_ON_KYC_PLATFORM_CONSTANT),
  SUCCESS: getSuccessConst(REGISTER_ON_KYC_PLATFORM_CONSTANT),
  FAIL: getFailConst(REGISTER_ON_KYC_PLATFORM_CONSTANT)
};
