const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;
const getOpenConst = (unique) => `${unique}_OPEN`;
const getCloseConst = (unique) => `${unique}_CLOSE`;

export const MTB_CLEAR_DIRECT_BREADCRUMBS = 'MTB_CLEAR_DIRECT_BREADCRUMBS';
export const MTB_ADD_DIRECT_BREADCRUMBS = 'MTB_ADD_DIRECT_BREADCRUMBS';

export const MTB_INIT = {
  REQUEST: getRequestConst('MTB_INIT'),
  SUCCESS: getSuccessConst('MTB_INIT'),
  FAIL: getFailConst('MTB_INIT')
};

export const MTB_GET_ROOT_TEAM_MEMBER = {
  REQUEST: getRequestConst('MTB_GET_ROOT_TEAM_MEMBER'),
  SUCCESS: getSuccessConst('MTB_GET_ROOT_TEAM_MEMBER'),
  FAIL: getFailConst('MTB_GET_ROOT_TEAM_MEMBER')
};

export const MTB_GET_DIRECT_MEMBERS = {
  REQUEST: getRequestConst('MTB_GET_DIRECT_MEMBERS'),
  SUCCESS: getSuccessConst('MTB_GET_DIRECT_MEMBERS'),
  FAIL: getFailConst('MTB_GET_DIRECT_MEMBERS')
};

export const MTB_GET_DIRECT_LEG_USERS = {
  REQUEST: getRequestConst('MTB_GET_DIRECT_LEG_USERS'),
  SUCCESS: getSuccessConst('MTB_GET_DIRECT_LEG_USERS'),
  FAIL: getFailConst('MTB_GET_DIRECT_LEG_USERS')
};

export const MTB_ADD_NEW_MEMBER_MODAL = {
  OPEN: getOpenConst('MTB_ADD_NEW_MEMBER_MODAL'),
  CLOSE: getCloseConst('MTB_ADD_NEW_MEMBER_MODAL')
};

export const Position = {
  L: 0,
  R: 1
};

export const ADD_DIRECT_MEMBER = {
  REQUEST: getRequestConst('ADD_DIRECT_MEMBER'),
  SUCCESS: getSuccessConst('ADD_DIRECT_MEMBER'),
  FAIL: getFailConst('ADD_DIRECT_MEMBER')
};
