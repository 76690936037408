import { CLEAR_ALERT, CLEAR_MESSAGE } from '../../constants/errorConstants';
import {
  EARNINGS_HISTORY,
  EARNINGS_INIT
} from './earnignsBackoffice.constants';

import { CREATE_WITHDRAW_PARTNER } from './Withdraw/Withdraw.constants';

export const earningsReducer = (
  state = {
    loading: false,
    loadingHistory: false,
    history: [],
    info: null
  },
  action
) => {
  switch (action.type) {
    case EARNINGS_INIT.REQUEST:
      return { ...state, loading: true };

    case EARNINGS_INIT.SUCCESS:
      return {
        ...state,
        info: action.payload.info,
        history: action.payload.history,
        withdrawAvailability: action.payload.withdrawAvailability,
        loading: false
      };

    case EARNINGS_INIT.FAIL:
      return { ...state, loading: false };

    case EARNINGS_HISTORY.REQUEST:
      return {
        ...state,
        loadingHistory: true
      };

    case EARNINGS_HISTORY.SUCCESS:
      return {
        ...state,
        history: action.payload,
        loadingHistory: false
      };

    case EARNINGS_HISTORY.FAIL:
      return { ...state, loadingHistory: false };

    case CREATE_WITHDRAW_PARTNER.SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          totalEarnings: {
            ...state.info.totalEarnings,
            availableBalance:
              state.info.totalEarnings.availableBalance - action.payload
          }
        }
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};
