import { tryExecuteSimpleAction } from '../../actions/common';
import { CLEAR_ALERT } from '../../constants/errorConstants';

import {
  MTB_INIT,
  MTB_GET_ROOT_TEAM_MEMBER,
  MTB_ADD_NEW_MEMBER_MODAL,
  MTB_GET_DIRECT_MEMBERS,
  MTB_CLEAR_DIRECT_BREADCRUMBS,
  MTB_ADD_DIRECT_BREADCRUMBS,
  MTB_GET_DIRECT_LEG_USERS,
  ADD_DIRECT_MEMBER
} from './myTeamBackOffice.constants';

import {
  getDirectMembersAsync,
  getRootTeamMemberAsync,
  getDirectTeamLegUsersAsync,
  addDirectUserAsync,
  addDirectUserPlusAsync
} from './myTeamBackOffice.requests';

export const init = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    MTB_INIT.REQUEST,
    async () => {
      const { data: teamMemberResponse } = await getRootTeamMemberAsync();
      dispatch({
        type: MTB_INIT.SUCCESS,
        payload: {
          rootTeamMember: teamMemberResponse.data.teamUser,
          userAsTeamMember: teamMemberResponse.data.teamUser,
          rootTeamMemberInfo: teamMemberResponse.data.user,
          userAsTeamMemberInfo: teamMemberResponse.data.user,
          teamUser: teamMemberResponse.data.teamUser
        }
      });
    },
    MTB_INIT.FAIL
  );
};

export const getRootTeamMember = (id) => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    MTB_GET_ROOT_TEAM_MEMBER.REQUEST,
    async () => {
      const { data } = await getRootTeamMemberAsync(id);

      dispatch({
        type: MTB_GET_ROOT_TEAM_MEMBER.SUCCESS,
        payload: {
          rootTeamMember: data.data.teamUser,
          rootTeamMemberInfo: data.data.user
        }
      });
    },
    MTB_GET_ROOT_TEAM_MEMBER.FAIL
  );
};

export const getDirectMembers =
  (id = null, countPerPage) =>
  async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      MTB_GET_DIRECT_MEMBERS.REQUEST,
      async () => {
        const { data } = await getDirectMembersAsync(id, countPerPage);

        dispatch({
          type: MTB_GET_DIRECT_MEMBERS.SUCCESS,
          payload: {
            leftDirectUsers: data.data.leftDirectUsers,
            rightDirectUsers: data.data.rightDirectUsers,
            directTeamMember: data.data.user,
            directTeamMemberInfo: data.data.userInfo
          }
        });
      },
      MTB_GET_DIRECT_MEMBERS.FAIL
    );
  };

export const getDirectTeamLegUsers =
  (id = null, numberOfPage, countPerPage, leg) =>
  async (dispatch) => {
    try {
      dispatch({ type: MTB_GET_DIRECT_LEG_USERS.REQUEST, payload: leg });

      const { data } = await getDirectTeamLegUsersAsync(
        id,
        numberOfPage,
        countPerPage,
        leg
      );

      dispatch({
        type: MTB_GET_DIRECT_LEG_USERS.SUCCESS,
        payload: {
          directUsers: data.data.directUsers,
          leg
        }
      });
    } catch (error) {
      dispatch({
        type: MTB_GET_DIRECT_LEG_USERS.FAIL,
        payload: error.response && error.response.data
      });
    }
  };

export const openAddNewMemberModal =
  (parentId, position) => async (dispatch) => {
    dispatch({
      type: MTB_ADD_NEW_MEMBER_MODAL.OPEN,
      payload: { parentId, position }
    });
  };

export const closeAddNewMemberModal = () => async (dispatch) => {
  dispatch({
    type: MTB_ADD_NEW_MEMBER_MODAL.CLOSE
  });
};

export const clearBreadCrumbs = () => async (dispatch) => {
  dispatch({
    type: MTB_CLEAR_DIRECT_BREADCRUMBS
  });
};

export const addDirectBreadCrumb = (id) => async (dispatch) => {
  dispatch({
    type: MTB_ADD_DIRECT_BREADCRUMBS,
    payload: id
  });
};

export const addDirectUser =
  (leg, directUserId, email, history) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_DIRECT_MEMBER.REQUEST
      });

      const { data } = await addDirectUserAsync(directUserId, email);

      dispatch({
        type: ADD_DIRECT_MEMBER.SUCCESS,
        payload: { leg, user: data.data.user }
      });

      history.push('/password');
    } catch (e) {
      dispatch({
        type: ADD_DIRECT_MEMBER.FAIL,
        payload: e?.response?.data?.error
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_ALERT,
          payload: ''
        });
      }, 3000);
    }
  };

export const addDirectUserPlus = (leg, email, history) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_DIRECT_MEMBER.REQUEST
    });

    const { data } = await addDirectUserPlusAsync(leg, email);

    dispatch({
      type: ADD_DIRECT_MEMBER.SUCCESS,
      payload: { leg, user: data.data.user }
    });

    history.push(`/password/`);
  } catch (e) {
    dispatch({
      type: ADD_DIRECT_MEMBER.FAIL,
      payload: e?.response?.data?.error
    });

    setTimeout(() => {
      dispatch({
        type: CLEAR_ALERT,
        payload: ''
      });
    }, 3000);
  }
};
