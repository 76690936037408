import { GET_CASHBACK, CLAIM_CASHBACK } from '../constants/CashbackConstants';

export const cashbackReducer = (
  state = {
    loading: false,
    cashback: {}
  },
  action
) => {
  switch (action.type) {
    case GET_CASHBACK.REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_CASHBACK.SUCCESS:
      return {
        ...state,
        loading: false,
        cashback: action.payload.info,
        statusPerPackage: action.payload.statusPerPackage
      };

    case GET_CASHBACK.FAIL:
      return { ...state, loading: false };

    case CLAIM_CASHBACK.REQUEST:
      return {
        ...state,
        loading: true
      };

    case CLAIM_CASHBACK.SUCCESS:
      return {
        ...state,
        loading: false,
        cashbackClaim: action.payload
        // totalPages: action.payload.totalPages,
      };

    case CLAIM_CASHBACK.FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
