import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SidebarModal } from '../../components/common/Sidebar/Sidebar';
import { formatUSDNoDecimals } from '../../helpers/helperFunctions';
import { CaptionText, H3, H4, P1, P3 } from '../../styles/FontStyles';

import {
  ArrowChevron,
  Button,
  Collapsable,
  Flexed,
  SectionSeparatorVertical
} from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  Icon,
  IconCalculator,
  IconPortfolioDiamond,
  IconPortfolioDistribution,
  IconPortfolioMoney,
  IconPortfolioOrders,
  IconPortfolioStaked,
  IconPortfolioStaking
} from '../../styles/Icons';
import { AutoStake } from './Autostake/AutoStake';
import { RioCalculator } from './CalculateTable';
import { ClaimCalculator } from './Claim/ClaimCalculator';
import { BoxPortfolio, SummaryGrid } from './Portfolio.styled';

import { PortfolioItem } from './PortfolioItem';

import { formatUSD } from '../../helpers/helperFunctions';

import { Claim } from './Claim/Claim';

import Countdown from 'react-countdown';
import { useHistory } from 'react-router';
import { Overlay } from './Overlay';

export const StakingHubSummary = () => {
  const { t } = useTranslation('my_hubs');
  const history = useHistory();
  const ref = useRef(null);

  const [claim, setClaim] = useState(false);
  const [autostake, setAutostake] = useState(false);
  const [calculator, setCalculator] = useState(false);
  const [claimCalculator, setClaimCalculator] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [packageItem, setPackageItem] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [claimStep, setClaimStep] = useState(1);

  let now = new Date();
  now.setDate(now.getDate() + 1);
  now.setHours(13);
  now.setSeconds(0);
  now.setMinutes(0);
  now.setMilliseconds(0);

  const { staking } = useSelector((state) => state.portfolio);

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds }) => {
    return (
      <div>
        {hours}h: {minutes}m: {seconds}s
      </div>
    );
  };

  const height = ref?.current?.clientHeight;

  return (
    <>
      <Flexed mb='20' center between rowSM>
        <H3 mbSM='10'>{t('my_ultron_staking_hub_nfts_summary')}</H3>
        <Button
          negative
          maxWidth='350px'
          maxWidthSM='100%'
          onClick={() => history.push('/backoffice/marketplace')}>
          {t('add_staking_hub_nft')}
        </Button>
      </Flexed>
      <SummaryGrid>
        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioStaking />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('base_stake')}
            </CaptionText>

            <P1 bold flex alignEnd gap='5px'>
              {formatUSD(staking?.summary?.baseStake)} <P3 mb='1'>wULX</P3>
            </P1>
          </div>
        </BoxPortfolio>
        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioStaked />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('total_stalked')}
            </CaptionText>

            <P1 bold flex alignEnd gap='5px'>
              {formatUSD(staking?.summary?.totalStake)}
              <P3 mb='1'>wULX</P3>
            </P1>
          </div>
        </BoxPortfolio>

        <BoxPortfolio
          onClick={() => setCalculator(true)}
          style={{ cursor: 'pointer' }}>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioMoney />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              apR
            </CaptionText>
            <P1 bold flex gap='5px'>
              73%
              <Icon ml='5'>
                <IconCalculator />
              </Icon>
            </P1>
          </div>
          <SectionSeparatorVertical margin='0 40px' h='30px' />
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              apy
            </CaptionText>
            <P1 bold flex gap='5px'>
              107%
              <Icon ml='5'>
                <IconCalculator />
              </Icon>
            </P1>
          </div>
        </BoxPortfolio>

        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioOrders />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('total_ultron_staking_hub_nft_order')}
            </CaptionText>
            <P1 bold flex alignEnd gap='5px'>
              {formatUSDNoDecimals(staking?.summary?.totalOrderAmount)}{' '}
              <P3 mb='1'>USDT</P3>
            </P1>
          </div>
        </BoxPortfolio>

        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioDiamond />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('total_orders_with_autostake_on')}
            </CaptionText>
            <P1 bold flex alignEnd gap='5px'>
              {formatUSDNoDecimals(
                staking?.summary?.totalOrderWithAutostakeIntactAmount
              )}{' '}
              <P3 mb='1'>USDT</P3>
            </P1>
          </div>
        </BoxPortfolio>

        <BoxPortfolio>
          <Icon mr='16' mrSM='8' color={color.colors.secondary100}>
            <IconPortfolioDistribution />
          </Icon>
          <div>
            <CaptionText caps bold color={color.colors.neutral500} mb='10'>
              {t('next_processing_starts_in')}
            </CaptionText>
            <P1 bold flex alignEnd gap='5px'>
              <Countdown date={now} renderer={renderer} />
            </P1>
          </div>
        </BoxPortfolio>
      </SummaryGrid>

      <Flexed
        mb='20'
        center
        gap='20px'
        pointer
        onClick={() => setExpanded(!expanded)}>
        <H4>{t('my_ultron_staking_hub_nfts')} </H4>
        <ArrowChevron direction={expanded ? 'up' : 'down'} />
      </Flexed>

      <Collapsable expanded={expanded} elementHeight={height}>
        <div ref={ref}>
          {staking?.userPackages?.map((item, index) => (
            <PortfolioItem
              setClaim={setClaim}
              setClaimStep={setClaimStep}
              key={index}
              item={item}
              setAutostake={setAutostake}
              setPackageItem={setPackageItem}
              claimCalculator={claimCalculator}
              setClaimCalculator={setClaimCalculator}
              setToggle={setToggle}
              toggle={toggle}
              history={history}
            />
          ))}
        </div>
      </Collapsable>

      <SidebarModal
        isOpen={calculator}
        setOpen={setCalculator}
        title={t('ROI Calculator')}
        sidebarWidth={'508px'}
        // isCenteredContent={true}
      >
        <RioCalculator setCalculator={setCalculator} />
      </SidebarModal>
      <SidebarModal
        isOpen={claimCalculator}
        setOpen={setClaimCalculator}
        title={t('claim.claim_calculator')}
        sidebarWidth={'580px'}
        // isCenteredContent={true}
      >
        <ClaimCalculator
          setClaimCalculator={setClaimCalculator}
          packageItem={packageItem}
        />
      </SidebarModal>
      <SidebarModal
        isOpen={claim}
        setOpen={setClaim}
        title={t('claim.claim_title')}
        sidebarWidth={'560px'}>
        <Claim
          setClaim={setClaim}
          setClaimStep={setClaimStep}
          claimStep={claimStep}
          packageItem={packageItem}
        />
      </SidebarModal>
      <SidebarModal
        isOpen={autostake}
        setOpen={setAutostake}
        title={'auto-stake settings'}
        sidebarWidth={'508px'}
        isCenteredContent={true}>
        <AutoStake
          setAutostake={setAutostake}
          packageItem={packageItem}
          setToggle={setToggle}
          toggle={toggle}
          isOpen={autostake}
        />
      </SidebarModal>
      <Overlay />
    </>
  );
};
