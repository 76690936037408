import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Layout,
  Links,
  Main,
  MainMobile,
  MobileLayout,
  Nav,
  NavLink,
  NavMobile
} from './WelcomeStyled';

import { useTranslation } from 'react-i18next';
import { getUserDetails } from '../../actions/userDetailsActions';
import { PRIVACY_POLICY, TERMS_OF_USE } from '../../routes/routes';
import { ProgressBar } from '../../styles/GlobalComponents';
import { globalImages } from '../../styles/GlobalStyles';

const WelcomeLayout = ({ children }) => {
  const path = useHistory().location.pathname;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userDetails);
  const { t } = useTranslation('kyc.layout');

  const stateFor1 = () => {
    if (path === '/welcome-packages') {
      return 2;
    }
  };
  const stateFor2 = () => {
    if (path === '/package-payment') {
      return 3;
    }
  };

  const RouteObj = () => {
    switch (path) {
      // case '/welcome':
      //   return { progress: '33%', title: '1. Choose your career', step: '1' };
      case '/welcome-packages':
        return { progress: '50%', title: '1. Select a package', step: '1' };
      case '/package-payment':
        return { progress: '100%', title: '2. Package payment', step: '2' };
      case '/welcome-rank':
        return { progress: '100%', title: '', step: '3' };
    }
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  return (
    <>
      <Layout>
        <Nav
          style={{
            display: user?.userRank?.rank?.name === 'Ambassador' && 'none',
            marginRight: 0
          }}>
          <div>
            <img
              style={{ marginBottom: 134, width: ' 100%' }}
              src={globalImages.appLogo}
              alt='logo'
            />
            <div style={{ marginBottom: 134 }}>
              <NavLink state={stateFor1()}>
                <span />
                1. Select a Staking HUB
              </NavLink>

              <NavLink state={stateFor2()}>
                <span />
                2. Staking HUB payment
              </NavLink>
            </div>

            <Links href={PRIVACY_POLICY} target='_blank'>
              {t('privacyPolicy')}
            </Links>
            <Links href={TERMS_OF_USE} target='_blank'>
              {t('termsAndConditions')}
            </Links>
          </div>
        </Nav>
        <Main
          style={{ margin: 0, borderLeft: '1px solid #594C4C', width: '100%' }}>
          {children}
        </Main>
      </Layout>
      <MobileLayout>
        <NavMobile style={{ display: RouteObj()?.step === '4' && 'none' }}>
          <h1>{RouteObj()?.title}</h1>
          <h2>
            Step <b>{RouteObj()?.step}</b> of 2
          </h2>

          <ProgressBar
            style={{ background: '#FFEEE5', padding: 0 }}
            percentage={() => RouteObj()?.progress}>
            <div></div>
          </ProgressBar>
        </NavMobile>
        <MainMobile>{children}</MainMobile>
      </MobileLayout>
    </>
  );
};

export default WelcomeLayout;
