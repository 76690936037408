import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarModal } from '../../components/common/Sidebar/Sidebar';
import Spinner from '../../components/common/Spinner';

import { PackagesWrapper } from './PackagesStyled';

import { useTranslation } from 'react-i18next';
import { getUserDetails } from '../../actions/userDetailsActions';
import { PageTitle } from '../../styles/GlobalComponents';
import { ConfirmPackage } from './ConfirmPackage';
import Packages from './Packages';
import { getPackage } from './packages.actions';
import { AnimatedDashboard } from '../../components/animation/AnimatedDashboard';

const PackagesList = ({ location }) => {
  const { t } = useTranslation('packages');

  let [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [selectedWallet, setSelectedWallet] = useState('');
  const [selectedWalletIndex, setSelectedWalletIndex] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('');

  const dispatch = useDispatch();

  const { isLoading, selectedTab } = useSelector((state) => state.packagesList);

  useEffect(() => {
    dispatch(getPackage());
    dispatch(getUserDetails());
  }, [dispatch]);

  if (isLoading) return <Spinner fullScreen={true} dark={true} />;

  return (
    <AnimatedDashboard zoom>
      <PackagesWrapper>
        <PageTitle>{t('title')}</PageTitle>

        <Packages
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          selectedTab={selectedTab}
          location={location}
          setStep={setStep}
          step={step}
        />
      </PackagesWrapper>

      <SidebarModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={'Staking HUB purchase'}
        sidebarWidth={'511px'}>
        <ConfirmPackage
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setStep={setStep}
          step={step}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          selectedWalletIndex={selectedWalletIndex}
          setSelectedWalletIndex={setSelectedWalletIndex}
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
        />
      </SidebarModal>
    </AnimatedDashboard>
  );
};

export default PackagesList;
