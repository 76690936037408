import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Desktop, Mobile } from '../../../App';
import {
  Flex,
  FlexBetween,
  FlexCenter,
  FloatingButtonBackoffice
} from '../../../styles/stylesStyled';

import { Withdraw } from '../Withdraw/Withdraw';

import { useTranslation } from 'react-i18next';
import { SidebarModal } from '../../../components/common/Sidebar/Sidebar';
import { formatUSD, USDT } from '../../../helpers/helperFunctions';
import { CaptionText, H4, P1, P3 } from '../../../styles/FontStyles';
import {
  Button,
  EarningBox,
  Flexed,
  GridDynamic,
  PageTitle
} from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import {
  Icon,
  IconEarningsTotal,
  IconTodayEarnings
} from '../../../styles/Icons';
import { NoKyc } from '../../Dashboard/NoKyc';

export const Earnings = ({ setMobilePage, withdraw, setWithdraw }) => {
  const { t } = useTranslation('earnings');

  const [step, setStep] = useState(1);
  const [noKyc, setNoKyc] = useState(false);

  const { kycStatus } = useSelector((state) => state.userInfo);
  const { info, withdrawAvailability } = useSelector((state) => state.earnings);

  return (
    <div>
      <FlexBetween style={{ marginBottom: 20 }}>
        <PageTitle>{t('title')}</PageTitle>
        <Desktop>
          <Flex
            style={{
              flexWrap: 'wrap',
              justifyContent: 'flex-end'
            }}>
            <Button
              w='130px'
              m='6px'
              negative
              onClick={() => {
                if (kycStatus.isKycCompleted) {
                  if (withdrawAvailability.isAvailable) {
                    setWithdraw(true);
                    setStep(1);
                  } else {
                    setWithdraw(true);
                    setStep(9);
                  }
                } else {
                  setNoKyc(true);
                }
              }}>
              {t('withdraw')}
            </Button>
          </Flex>
        </Desktop>
        <Mobile>
          <FloatingButtonBackoffice>
            <Flexed>
              <Button
                negative
                m='6px'
                onClick={() => {
                  if (kycStatus.isKycCompleted) {
                    if (withdrawAvailability.isAvailable) {
                      setWithdraw(true);
                      setStep(1);
                    } else {
                      setWithdraw(true);
                      setStep(9);
                    }
                  } else {
                    setNoKyc(true);
                  }
                }}>
                {t('withdraw')}
              </Button>
            </Flexed>
          </FloatingButtonBackoffice>
        </Mobile>
        <Mobile>
          <P1
            pointer
            color={color.colors.primary900}
            onClick={() => setMobilePage('EarningHistory')}>
            {t('show_history')}
          </P1>
        </Mobile>
      </FlexBetween>
      <FlexCenter style={{ marginBottom: 12 }}>
        <Icon color={color.colors.neutral900} mr='8'>
          <IconEarningsTotal />
        </Icon>
        <P3 color={color.colors.neutral900} bold>
          {t('total_earnings')}
        </P3>
      </FlexCenter>
      <GridDynamic min='250px'>
        <EarningBox>
          <CaptionText mb='8' caps color={color.colors.neutral500}>
            {t('total_earnings')}
          </CaptionText>
          <Flexed center end='true'>
            <H4 mb='8' color={color.colors.neutral900}>
              {formatUSD(info?.totalEarnings?.totalEarnings)} {USDT}
            </H4>
          </Flexed>
          <Flexed center end='true'>
            <FlexCenter>
              <Icon>
                <IconTodayEarnings />
              </Icon>
              <CaptionText ml='4' caps color={color.colors.success500}>
                {info?.totalEarnings?.todayTotalEarnings}
              </CaptionText>

              <CaptionText ml='4' caps color={color.colors.neutral500}>
                today
              </CaptionText>
            </FlexCenter>
          </Flexed>
        </EarningBox>
        <EarningBox>
          <CaptionText mb='8' caps color={color.colors.neutral500}>
            {USDT} {t('balance')}
          </CaptionText>
          <Flexed center end='true'>
            <H4 mb='8' color={color.colors.neutral900}>
              {formatUSD(info?.totalEarnings?.availableBalance)} {USDT}
            </H4>
          </Flexed>
          <Flexed center end='true'>
            <FlexCenter>
              <Icon>
                <IconTodayEarnings />
              </Icon>
              <CaptionText ml='4' caps color={color.colors.success500}>
                {info?.totalEarnings?.todayAvailableBalance}
              </CaptionText>

              <CaptionText ml='4' caps color={color.colors.neutral500}>
                today
              </CaptionText>
            </FlexCenter>
          </Flexed>
        </EarningBox>
      </GridDynamic>

      <SidebarModal
        isOpen={withdraw}
        setOpen={setWithdraw}
        title={`USDT ${t('withdrawal')}`}
        sidebarWidth={'508px'}>
        <Withdraw
          step={step}
          setStep={setStep}
          withdraw={withdraw}
          setWithdraw={setWithdraw}
        />
      </SidebarModal>

      <SidebarModal
        isOpen={noKyc}
        setOpen={setNoKyc}
        title={t('verify_your_account')}
        sidebarWidth={'508px'}
        // isCenteredContent={true}
      >
        <NoKyc
          title={t('verify_your_account')}
          text={t('complete_your_kyc')}
          button={t('start_kyc')}
          link='/kyc'
        />
      </SidebarModal>
    </div>
  );
};
