const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const GET_HODL_BONUS = {
  REQUEST: getRequestConst('GET_HODL_BONUS'),
  SUCCESS: getSuccessConst('GET_HODL_BONUS'),
  FAIL: getFailConst('GET_HODL_BONUS')
};

export const CLAIM_HODL_BONUS = {
  REQUEST: getRequestConst('CLAIM_HODL_BONUS'),
  SUCCESS: getSuccessConst('CLAIM_HODL_BONUS'),
  FAIL: getFailConst('CLAIM_HODL_BONUS')
};
