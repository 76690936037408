import React from 'react';
import { PageTitle } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { Icon, IconPresentation } from '../../styles/Icons';

import {
  HeaderIcon,
  ResourcesBody,
  ResourcesContainer,
  ResourcesHeader,
  Title,
  View
} from './ResourcesStyled';

export const Resources = () => {
  return (
    <View>
      <PageTitle>Resources</PageTitle>
      <ResourcesContainer>
        <ResourcesHeader>
          <HeaderIcon>
            <Icon color={color.colors.neutral50}>
              <IconPresentation />
            </Icon>
          </HeaderIcon>

          <Title>Presentations</Title>
        </ResourcesHeader>
        <ResourcesBody>
          <a
            href='https://resources.mavie.global/presentations/ultron_staking_hub_nft_update.pdf'
            target='_blank'
            rel='noreferrer'>
            <li>Ultron - Staking Hub NFT Update</li>
          </a>
        </ResourcesBody>
      </ResourcesContainer>
    </View>
  );
};
