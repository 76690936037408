import React from 'react';

import { QRSetup } from './QRSetup';
import { Verification } from './Verification';
import { VerificationSuccess } from './VerificationSuccess';

export const TwoFAInit = ({ step, setStep, setIsOpen }) => {
  const renderSteps = (step) => {
    switch (step) {
      case 1:
        return <QRSetup setStep={setStep} />;

      case 2:
        return <Verification setStep={setStep} setIsOpen={setIsOpen} />;

      case 3:
        return <VerificationSuccess setIsOpen={setIsOpen} />;

      default:
        return <div></div>;
    }
  };
  return <div>{renderSteps(step)}</div>;
};
