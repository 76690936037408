import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { chooseTheme, GlobalStyles } from './styles/GlobalStyles';
import { ThemeProvider } from 'styled-components';

import './i18n';
import { StyledToastContainer } from './components/common/Toasts/ToastsStyled';
import 'react-toastify/dist/ReactToastify.min.css';

// import Web3 from 'web3';
// import { Web3ReactProvider } from '@web3-react/core';
// import { MetaMaskProvider } from './hooks/useMetaMask';

// function getLibrary(provider) {
//   return new Web3(provider);
// }

ReactDOM.render(
  // <Suspense fallback={true}>
  <Provider store={store}>
    {/* <Web3ReactProvider getLibrary={getLibrary}>
      <MetaMaskProvider> */}
    <ThemeProvider theme={chooseTheme}>
      <GlobalStyles />
      <StyledToastContainer />
      <App />
    </ThemeProvider>
    {/* </MetaMaskProvider>
    </Web3ReactProvider> */}
  </Provider>,
  // </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
