import axios from 'axios';

import { createAxiosConfigWithAuthHeader } from '../../utils/createAxiosConfigWithAuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

export const getRootTeamMemberAsync = async (id) => {
  const baseUrl = `${API_URL}/user/team?levelCount=4`;
  const url = id ? `${baseUrl}&userId=${id}` : baseUrl;

  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const getDirectMembersAsync = async (id, countPerPage) => {
  const baseUrl = `${API_URL}/user/team/direct`;
  const url = id ? `${baseUrl}?userId=${id}` : baseUrl;

  return await axios.get(
    url,
    {
      params: {
        countPerPage
      }
    },
    createAxiosConfigWithAuthHeader()
  );
};

export const getDirectTeamLegUsersAsync = async (
  id,
  numberOfPage,
  countPerPage,
  leg
) => {
  const baseUrl = `${API_URL}/user/team/direct/leg`;
  const url = id ? `${baseUrl}?userId=${id}` : baseUrl;

  return await axios.get(
    url,
    {
      params: {
        numberOfPage,
        countPerPage,
        leg
      }
    },
    createAxiosConfigWithAuthHeader()
  );
};

export const addDirectUserAsync = async (directUserId, email) => {
  const url = `${API_URL}/user/team/user/direct-user`;

  return await axios.post(
    url,
    { directUserId, email },
    createAxiosConfigWithAuthHeader()
  );
};

export const addDirectUserPlusAsync = async (leg, email) => {
  const url = `${API_URL}/user/team/user/leg`;

  return await axios.post(
    url,
    { leg, email },
    createAxiosConfigWithAuthHeader()
  );
};
