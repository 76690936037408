import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { SidebarModal } from '../../components/common/Sidebar/Sidebar';
import { SelectedPlan } from '../../components/common/VirtualOfficeSubscription/SelectedPlan';

import {
  dateDifferenceInDaysFromToday,
  globalPlans
} from '../../helpers/helperFunctions';

import { H3, P2 } from '../../styles/FontStyles';

import { Flexed, PageTitle, Button } from '../../styles/GlobalComponents';

export const VirtualOffice = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(globalPlans()[0]);
  const [step, setStep] = useState(1);
  const { user } = useSelector((state) => state.userInfo);
  const { t } = useTranslation('settings');

  const virtualOfficeEndDate = moment(
    user?.currentVirtualOfficeLicence?.dateEnd
  );
  const virtualOfficeEndDatePlusOneYear = virtualOfficeEndDate
    .clone()
    .add(1, 'year');

  return (
    <div>
      <PageTitle>{t('virtual_office.title1')}</PageTitle>

      <P2 mb='45' mt='5'>
        {t('virtual_office.text1')}
      </P2>

      <Flexed justifyCenter>
        <P2 mb='5' mt='5'>
          {t('virtual_office.text3')}
        </P2>
      </Flexed>
      <Flexed justifyCenter>
        <H3>
          {moment(user?.currentVirtualOfficeLicence?.dateEnd).format(
            'Do of MMMM YYYY'
          )}
        </H3>
      </Flexed>

      <Flexed justifyCenter>
        <P2 mb='5' mt='5'>
          (
          {`${dateDifferenceInDaysFromToday(
            user?.currentVirtualOfficeLicence?.dateEnd
          )}`}{' '}
          {t('virtual_office.banner.days_left')})
        </P2>
      </Flexed>

      <H3 mb='20' mt='40'>
        {t('virtual_office.title2')}
      </H3>
      <P2 mb='25' mt='5'>
        {t('virtual_office.text2')}
      </P2>
      <P2 mb='20'>{t('virtual_office.text4')}</P2>
      <Flexed justifyCenter>
        <Button
          w='350px'
          mt='15'
          onClick={() => {
            setIsOpen(true);
            setStep(1);
          }}>
          {t('virtual_office.button')}{' '}
          {moment(virtualOfficeEndDatePlusOneYear).format('Do of MMMM YYYY')}
        </Button>
      </Flexed>

      <SidebarModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={`BACK OFFICE SUBSCRIPTION`}
        sidebarWidth={'600px'}>
        <SelectedPlan
          selected={selected}
          setSelected={setSelected}
          step={step}
          setStep={setStep}
        />
      </SidebarModal>
    </div>
  );
};
