import React from 'react';
import { useTranslation } from 'react-i18next';
import image from '../../../assets/images/backoffice/withdraw/withdraw.png';
import { Button } from '../../../styles/GlobalComponents';
import { FlexCenter } from '../../../styles/stylesStyled';
import { Image, Subtitle, Title } from './WithdrawStyled';

export const WithdrawSuccess = ({ setWithdraw }) => {
  const { t } = useTranslation('earnings');
  return (
    <div style={{ marginTop: '11vh' }}>
      <FlexCenter style={{ flexDirection: 'column' }}>
        <Image src={image} alt='withdraw' />
        <Title>{t('withdraw_part.success.title')}</Title>
        <Subtitle style={{ textAlign: 'center' }}>
          {t('withdraw_part.success.subtitle')}
        </Subtitle>
        <Button
          maxWidth='200px'
          opacity={'true'}
          onClick={() => setWithdraw(false)}>
          {t('withdraw_part.success.close')}
        </Button>
      </FlexCenter>
    </div>
  );
};
