import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Hub } from '../../../components/common/Hub/Hub';

import { P2 } from '../../../styles/FontStyles';

import { Button } from '../../../styles/GlobalComponents';

import { useTranslation } from 'react-i18next';
import { CheckText } from '../../../components/common/CheckText';
import { VirtualOfficePlan } from '../../../components/common/VirtualOfficeSubscription/VirtualOfficePlan';

export const VirtualOfficeSubscriptionStyled = styled.div`
  cursor: pointer;
  background: ${(props) => props.theme.colors.secondaryBlue};
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    padding: 16px;
  }
`;

export const Step5 = ({
  setStep,
  selectedHub,
  setModal,
  selected,
  setSelected,
  licenceDate
}) => {
  const [checkbox, setCheckbox] = useState(false);
  const { hubs } = useSelector((state) => state.hubs);
  const { t } = useTranslation('marketplace');

  return (
    <div>
      <VirtualOfficePlan selected={selected} setSelected={setSelected} />

      <P2 mt={20} mb={40}>
        {licenceDate === 0 ? (
          <div style={{ display: 'inline' }}>
            Your MaVie Back Office subscription <b>has expired. </b>
          </div>
        ) : (
          <div style={{ display: 'inline' }}>
            Your subscription is expiring in <b>next {licenceDate} days. </b>
          </div>
        )}
        Members without BackOffice subscription are <b>NOT</b> accumulating and
        receiving:
      </P2>

      <P2 mb='5'>&#x274C; Business Volume Points (BV) </P2>

      <P2 mb='32'>
        &#x274C; Bonuses (Team Bonus, Matching Bonus, Direct Bonus is capped to
        100 USDT per week){' '}
      </P2>

      <Button mt={16} onClick={() => setStep(7)}>
        {t('continue_with_subscription')}
      </Button>

      <P2 mt={40} mb={20} bold>
        {t('selected_staking_hub_nft')}:
      </P2>
      {hubs.map(({ id, image, title, price, weeklyCap }) => {
        if (id === selectedHub) {
          return (
            <Hub
              key={id}
              condensed
              image={image}
              title={title}
              price={price}
              button={
                <Button
                  w='150px'
                  wSM='100%'
                  onClick={() => setModal(false)}
                  negative
                  condensed>
                  Change Selection
                </Button>
              }
              weeklyCap={weeklyCap}
              info={<></>}
              stakingHubDetails={<div></div>}
            />
          );
        } else return null;
      })}
      <CheckText
        checked={checkbox}
        onClick={() => setCheckbox(!checkbox)}
        text='I am aware, that without BO subscription I will lose BV points accumulation and bonuses'
      />
      <Button mt={16} disabled={!checkbox} onClick={() => setStep(1)}>
        {t('continue_without_subscription')}
      </Button>
    </div>
  );
};
