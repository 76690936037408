import { tryExecuteSimpleAction } from '../../actions/common';

import {
  CHANGE_AUTOSTAKE,
  GET_PORTFOLIO,
  LOCK_AUTOSTAKE,
  SWITCH_AUTOSTAKE,
  BURN_ULX,
  GET_STAKING_WITHDRAWAL_INFO
} from './Portfolio.constants';

import {
  changeAutoStakeAsync,
  getPortfolioAsync,
  lockAutoStakeAsync,
  burnULXAsync,
  getStakingWithdrawalInfoAsync
} from './Portfolio.requests';

import { getWithdrawAvailabilitiAsync } from '../EarningsBackoffice/Withdraw/Withdraw.request';

import {
  ErrorToast,
  SuccessToast
} from '../../components/common/Toasts/Toasts';

export const getPortfolio = () => async (dispatch) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_PORTFOLIO.REQUEST,
    async () => {
      const { data } = await getPortfolioAsync();

      const withdrawAvailability = await getWithdrawAvailabilitiAsync();

      dispatch({
        type: GET_PORTFOLIO.SUCCESS,
        payload: {
          gaming: data.data.gaming,
          staking: data.data.staking,
          wallet: data.data.staking.userAddress,
          withdrawAvailability: withdrawAvailability?.data?.data,
          currentVirtualOfficeLicence: data.data.currentVirtualOfficeLicence,
          monthlyActivity: data.data.monthlyActivity,
          lifetimeRank: data.data.lifetimeRank,
          qualifiedRank: data.data.qualifiedRank,
          walletSummary: data.data.walletSummary
        }
      });
    },
    GET_PORTFOLIO.FAIL
  );
};

export const lockAutoStake =
  (userPackageId, limitDateType, setAutostake) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      LOCK_AUTOSTAKE.REQUEST,
      async () => {
        const { data } = await lockAutoStakeAsync(userPackageId, limitDateType);

        dispatch({
          type: LOCK_AUTOSTAKE.SUCCESS,
          payload: data.data.userPackage
        });

        SuccessToast('Autostake successfully changed');

        setAutostake(false);
      },
      LOCK_AUTOSTAKE.FAIL
    );
  };

export const getStakingWithdrawalInfo =
  (userPackageId, amount) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      GET_STAKING_WITHDRAWAL_INFO.REQUEST,
      async () => {
        const { data } = await getStakingWithdrawalInfoAsync(
          userPackageId,
          amount
        );

        dispatch({
          type: GET_STAKING_WITHDRAWAL_INFO.SUCCESS,
          payload: data.data.ulxAmountToBurn
        });
      },
      GET_STAKING_WITHDRAWAL_INFO.FAIL
    );
  };

export const burnULX =
  (userPackageId, amount, setClaim) => async (dispatch) => {
    await tryExecuteSimpleAction(
      dispatch,
      BURN_ULX.REQUEST,
      async () => {
        await burnULXAsync(userPackageId, amount);

        dispatch({
          type: BURN_ULX.SUCCESS,
          payload: userPackageId
        });

        setClaim(false);
      },
      BURN_ULX.FAIL
    );
  };

export const changeAutoStake =
  (userPackageId, newStatus, setAutostake, toggle, setToggle) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CHANGE_AUTOSTAKE.REQUEST,
        payload: { id: userPackageId }
      });

      setAutostake(false);

      const { data } = await changeAutoStakeAsync(userPackageId, newStatus);

      dispatch({
        type: CHANGE_AUTOSTAKE.SUCCESS,
        payload: {
          id: data.data.userPackage.id
        }
      });

      SuccessToast(data.message);
      setToggle(!toggle);
    } catch (error) {
      ErrorToast(error.response.data.error);
      dispatch({
        type: CHANGE_AUTOSTAKE.FAIL
      });
    }
  };

export const switchAutoStake = (packageId) => (dispatch) => {
  dispatch({
    type: SWITCH_AUTOSTAKE,
    payload: packageId
  });
};
