import { Route } from 'react-router';
import { AccountSettings } from '../views/SettingsBackoffice/Account';
import { NotificationsSettings } from '../views/SettingsBackoffice/Notifications';
import { PasswordSettings } from '../views/SettingsBackoffice/PasswordChange/Password';
import { PaymentsSettings } from '../views/SettingsBackoffice/Payments';
import { Settings } from '../views/SettingsBackoffice/Settings';
import { TeamSettings } from '../views/SettingsBackoffice/Team';
import { VirtualOffice } from '../views/SettingsBackoffice/VirtualOffice';
import {
  SETTINGS_ACCOUNT,
  SETTINGS_NOTIFICATIONS,
  SETTINGS_PAYMENTS,
  SETTINGS_SECURITY,
  SETTINGS_TEAM_SETTINGS,
  SETTINGS_VIRTUAL_OFFICE
} from './routes';

export const SettingsRoutes = () => (
  <Settings>
    <Route
      exact
      path={`${SETTINGS_ACCOUNT}`}
      render={(props) => <AccountSettings {...props} />}
    />

    <Route
      exact
      path={`${SETTINGS_SECURITY}`}
      render={(props) => <PasswordSettings {...props} />}
    />

    <Route
      exact
      path={`${SETTINGS_PAYMENTS}`}
      render={(props) => <PaymentsSettings {...props} />}
    />

    <Route
      exact
      path={`${SETTINGS_VIRTUAL_OFFICE}`}
      render={() => <VirtualOffice />}
    />

    <Route
      exact
      path={`${SETTINGS_NOTIFICATIONS}`}
      render={(props) => <NotificationsSettings {...props} />}
    />

    <Route
      exact
      path={`${SETTINGS_TEAM_SETTINGS}`}
      render={(props) => <TeamSettings {...props} />}
    />
  </Settings>
);
