import { useHistory } from 'react-router';
import { FlexCenter } from '../../styles/stylesStyled';
import { Image, Subtitle, Title } from './DashboardStyled';

import image from '../../assets/images/backoffice/kyc1.png';
import { useTranslation } from 'react-i18next';
import { Button } from '../../styles/GlobalComponents';

export const NoKyc = () => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();

  return (
    <div style={{ marginTop: '12vh' }}>
      <FlexCenter style={{ flexDirection: 'column' }}>
        <Image src={image} alt='withdraw' />
        <Title>{t('no_kyc.title')}</Title>
        <Subtitle style={{ textAlign: 'center' }}>
          {t('no_kyc.subtitle')}
        </Subtitle>
        <Button maxWidth='200px' onClick={() => history.push('/kyc')}>
          {t('no_kyc.btn_text')}
        </Button>
      </FlexCenter>
    </div>
  );
};
