import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addDocument } from '../../actions/documentActions';
import icon from '../../assets/images/backoffice/kyc3.png';
import Alert from '../../components/common/Alert';
import BottomDrawer from '../../components/common/BottomDrawer';
import { H2, H3, InputLabel, P1, P2 } from '../../styles/FontStyles';
import { ArrowPolygon, Button, Select } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { useWindowWide } from '../../utils/wideScreen';
import { Desktop, Mobile, TitleImage } from './KycStyled';

const KycDocumentDetail = ({ history }) => {
  const { t } = useTranslation('kyc.document_details');
  const [select, setSelect] = useState('id');
  const [id, setId] = useState(1);
  const [cardHolder, setCardHolder] = useState('');
  const [error, setError] = useState('');

  const PassID = [
    { name: t('id_option'), value: 'id', id: 1 },
    { name: t('driver_license_option'), value: 'license', id: 2 },
    { name: t('passport_option'), value: 'passport', id: 3 }
  ];
  const [isVisible3, setIsVisible3] = useState(false);
  const onClose3 = useCallback(() => {
    setIsVisible3(false);
  }, []);

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo);
  const { kycStatus, user } = userInfo;

  function handleSelect(val) {
    setId(val.id);
    setSelect(val.value);
    setIsVisible3(false);
  }

  const GoNext = () => {
    if (!cardHolder) {
      setError(t('err_add_account_holder_name'));
      setTimeout(() => {
        setError('');
      }, 3000);
    } else {
      dispatch(addDocument(id, cardHolder.trim(), history));
    }
  };

  useEffect(() => {
    if (user?.name && user?.surname) {
      setCardHolder(user?.name.trim() + ' ' + user?.surname.trim());
    }
  }, [kycStatus, dispatch]);

  const wide = useWindowWide(1000);

  return (
    <>
      {error && <Alert message={error} />}
      <Desktop>
        <TitleImage>
          <img src={icon} alt='icon' style={{ marginBottom: 40, width: 100 }} />
        </TitleImage>
      </Desktop>

      <Desktop>
        <H2 mb='12' color={color.kycTitleColor}>
          {t('title')}
        </H2>

        <P1
          mb='40'
          maxWidth='600px'
          maxWidthSM='1000px'
          color={color.kycSubTitleColor}>
          {t('subtitle')}
        </P1>
      </Desktop>
      <Mobile>
        <H3 mb='8' center color={color.kycTitleColor}>
          {t('title')}
        </H3>

        <P2 mb='32' center color={color.kycSubTitleColor}>
          {t('subtitle')}
        </P2>
      </Mobile>

      {/* <FlexResponsive>
        <RightMarginDiv> */}
      <div style={{ width: '100%', maxWidth: wide && 333 }}>
        <InputLabel>{t('label')}</InputLabel>

        <Select
          style={{ cursor: 'pointer' }}
          onClick={() => setIsVisible3(true)}>
          <div>
            {select === 'id'
              ? t('id_option')
              : select === 'license'
              ? t('driver_license_option')
              : t('passport_option')}
          </div>
          <ArrowPolygon direction={''} />
        </Select>
        {/* </RightMarginDiv>
      </FlexResponsive> */}
      </div>
      <Desktop>
        <Button onClick={() => GoNext()} maxWidth='200px'>
          {t('continue')}
        </Button>
      </Desktop>
      <Mobile>
        <Button onClick={() => GoNext()}>{t('continue')}</Button>
      </Mobile>

      <BottomDrawer isVisible={isVisible3} onClose={onClose3}>
        {Object.values(PassID).map((passId, i) => (
          <div
            key={i}
            onClick={() => handleSelect(passId)}
            className='mb-20'
            style={{ cursor: 'pointer' }}>
            {passId.name}
          </div>
        ))}
      </BottomDrawer>
    </>
  );
};

export default KycDocumentDetail;
