import styled from 'styled-components';

export const TitleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Qualification = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    align-items: start;
  }
`;

export const WhatIsCashback = styled(Qualification)`
  img {
    width: 100%;
  }
`;

export const CheckCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;

  @media (max-width: 800px) {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }

  ${(props) =>
    props.qualified === 'green' &&
    `background: ${props.theme.colors.primary900}`};
  ${(props) =>
    props.qualified === 'red' && `background: ${props.theme.colors.error500}`};
  ${(props) =>
    props.qualified === 'gray' &&
    `background: ${props.theme.colors.neutral100}`};
`;

export const CountdownNumbers = styled.div`
  display: flex;
  align-items: center;
  /* width: 100%; */
  /* flex-direction: column; */
  > div {
    width: 56px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      color: ${(props) => props.theme.colors.primary900};
    }
    @media (max-width: 800px) {
      width: calc(25% - 30px);
    }
  }
  img {
    width: 100%;
  }
`;

export const Partner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  text-align: center;

  @media (max-width: 800px) {
    margin: 8px;
    /* flex-direction: row; */
    /* gap: 15px; */
    /* text-align: left; */
  }
`;

export const Partners = styled.div`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 30px;
    flex-wrap: wrap;
  }
`;
