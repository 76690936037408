export const ADD_DOCUMENT_REQUEST = 'ADD_DOCUMENT_REQUEST';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAIL = 'ADD_DOCUMENT_FAIL';

export const ADD_UTILITY_BILL_SUCCESS = 'ADD_UTILITY_BILL_SUCCESS';
export const ADD_UTILITY_BILL_FAIL = 'ADD_UTILITY_BILL_FAIL';
export const ADD_UTILITY_BILL_REQUEST = 'ADD_UTILITY_BILL_REQUEST';

export const ADD_DOCUMENTS_IMAGE_REQUEST = 'ADD_DOCUMENTS_IMAGE_REQUEST';
export const ADD_DOCUMENTS_IMAGE_SUCCESS = 'ADD_DOCUMENTS_IMAGE_SUCCESS';
export const ADD_DOCUMENTS_IMAGE_FAIL = 'ADD_DOCUMENTS_IMAGE_FAIL';

export const ADD_SELFIE_REQUEST = 'ADD_SELFIE_REQUEST';
export const ADD_SELFIE_SUCCESS = 'ADD_SELFIE_SUCCESS';
export const ADD_SELFIE_FAIL = 'ADD_SELFIE_FAIL';

export const ADD_DOCUMENT_PRIZER_TICKET = 'ADD_DOCUMENT_PRIZER_TICKET';
export const ADD_ADDRESS_PRIZER_TICKET = 'ADD_DOCUMENT_ADDRESSICKET';
export const ADD_BANK_PRIZER_TICKET = 'ADD_DOCUMENT_BANKICKET';

export const ADD_BIRTHDAY_REQUEST = 'ADD_BIRTHDAY_REQUEST';
export const ADD_BIRTHDAY_SUCCESS = 'ADD_BIRTHDAY_SUCCESS';
export const ADD_BIRTHDAY_FAIL = 'ADD_BIRTHDAY_FAIL';

export const APPROVAL_KYC_TERMS_REQUEST = 'APPROVAL_KYC_TERMS_REQUEST';
export const APPROVAL_KYC_TERMS_SUCCESS = 'APPROVAL_KYC_TERMS_SUCCESS';
export const APPROVAL_KYC_TERMS_FAIL = 'APPROVAL_KYC_TERMS_FAIL';
