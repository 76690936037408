import { CLEAR_ALERT, CLEAR_MESSAGE } from '../../constants/errorConstants';
import {
  GET_TOP_PERFORMER_BONUS,
  CLAIM_TOP_PERFORMER_BONUS
} from './topPerformer.constants';

export const topPerformerBonus = (state = {}, action) => {
  switch (action.type) {
    case GET_TOP_PERFORMER_BONUS.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_TOP_PERFORMER_BONUS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload
      };
    case GET_TOP_PERFORMER_BONUS.FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case CLAIM_TOP_PERFORMER_BONUS.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case CLAIM_TOP_PERFORMER_BONUS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload
      };
    case CLAIM_TOP_PERFORMER_BONUS.FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};
