import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Layout = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background};
`;

export const Main = styled.div`
  width: 501px;
  margin: 80px auto;
  padding: 40px;
  background: ${(props) => props.theme.authLayoutWrapperBackground};
  border-radius: ${(props) => props.theme.authLayoutWrapperBorderRadius};
  box-shadow: ${(props) => props.theme.shadows.shadowXS};

  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
    min-height: 100vh;
    padding: 40px 16px 40px 16px;
  }
`;
