import axios from 'axios';

import { createAxiosConfigWithAuthHeader } from '../../utils/createAxiosConfigWithAuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

export const getRankingAsync = async () => {
  return await axios.get(
    `${API_URL}/package/ranking`,
    createAxiosConfigWithAuthHeader()
  );
};

export const claimPackageRankAsync = async (packageRankSelectedRewardId) => {
  return await axios.post(
    `${API_URL}/package/rank/reward/claim`,
    { packageRankSelectedRewardId },
    createAxiosConfigWithAuthHeader()
  );
};
