import React from 'react';
import { useSelector } from 'react-redux';

import {
  Layout,
  Main,
  Links,
  Nav,
  NavLink,
  NavMobile,
  MobileLayout,
  MainMobile,
  Kyc
} from './kycLayoutStyled';

import { useHistory } from 'react-router';

import { globalImages } from '../styles/GlobalStyles';
import { ProgressBar } from '../styles/GlobalComponents';
import { useTranslation } from 'react-i18next';
import { PRIVACY_POLICY, TERMS_OF_USE } from '../routes/routes';

const KycLayout = ({ children }) => {
  const { t } = useTranslation('kyc.layout');
  const path = useHistory().location.pathname;

  const { user, kycStatus } = useSelector((state) => state.userInfo);

  const stateFor1 = () => {
    if (path === '/kyc-agreement') {
      return 2;
    }
  };

  const stateFor2 = () => {
    if (path === '/birth-details') {
      return 2;
    }
  };

  const stateFor3 = () => {
    if (path === '/document-details') {
      return 2;
    }
  };

  const stateFor4 = () => {
    if (path === `/document-photos/${children.props.match.params.id}`) {
      return 2;
    }
  };

  const stateFor5 = () => {
    if (path === '/selfie-time') {
      return 2;
    }
  };

  const RouteObj = () => {
    switch (path) {
      case '/kyc':
        return { progress: '0%', title: `1. ${t('agreement')}`, step: '0' };
      case '/kyc-agreement':
        return { progress: '20%', title: `1. ${t('agreement')}`, step: '1' };
      case '/birth-details':
        return {
          progress: '40%',
          title: `2. ${t('personalDetails')}`,
          step: '2'
        };
      case '/document-details':
        return {
          progress: '60%',
          title: `3. ${t('documentDetails')}`,
          step: '3'
        };
      case `/document-photos/${children.props.match.params.id}`:
        return {
          progress: '80%',
          title: `4. ${t('documentPhotos')}`,
          step: '4'
        };
      // case '/proof-of-address':
      //   return { progress: '80%', title: '4. Utility bill', step: '4' };
      case '/selfie-time':
        return { progress: '100%', title: `5. ${t('selfieTime')}`, step: '5' };
      case '/kyc-congratulations':
        return { progress: '100%', title: `5. ${t('selfieTime')}`, step: '5' };

      default:
        return null;
    }
  };

  // console.log(children.props.match.params.id);

  return (
    <Kyc
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2
      }}>
      <Layout>
        <Nav>
          <div>
            <img
              style={{ marginBottom: 134, width: '100%' }}
              src={globalImages.kycLayoutLogo}
              alt='logo'
            />
            <div style={{ marginBottom: 134 }}>
              <NavLink state={stateFor1()}>
                <span />
                1. {t('agreement')}
              </NavLink>
              {!user?.dateOfBirth ? (
                <NavLink state={stateFor2()}>
                  <span />
                  2. {t('personalDetails')}
                </NavLink>
              ) : (
                <NavLink state={stateFor2()}>
                  <span />
                  2. {t('personalDetails')}
                </NavLink>
              )}

              {!kycStatus?.documentType ? (
                // <Link to='/document-details'>
                <NavLink state={stateFor3()}>
                  <span />
                  3. {t('documentDetails')}
                </NavLink>
              ) : (
                // </Link>
                <NavLink state={stateFor3()}>
                  <span />
                  3. {t('documentDetails')}
                </NavLink>
              )}

              {!kycStatus?.documentType ? (
                // <Link to={`/document-photos/${1}`}>
                <NavLink state={stateFor4()}>
                  <span />
                  4. {t('documentPhotos')}
                </NavLink>
              ) : (
                // </Link>
                <NavLink state={stateFor4()}>
                  <span />
                  4. {t('documentPhotos')}
                </NavLink>
              )}

              <NavLink state={stateFor5()}>
                <span />
                5. {t('selfieTime')}
              </NavLink>
            </div>
            {/* <Links href='/cookie-policy' target='_blank'>
              {t('cookiePolicy')}
            </Links> */}
            <Links href={PRIVACY_POLICY} target='_blank'>
              {t('privacyPolicy')}
            </Links>
            <Links href={TERMS_OF_USE} target='_blank'>
              {t('termsAndConditions')}
            </Links>
          </div>
        </Nav>
        <Main>{children}</Main>
      </Layout>
      <MobileLayout style={{ paddingTop: RouteObj()?.step === '0' && 100 }}>
        {path !== '/kyc-congratulations' && (
          <NavMobile
            style={{
              display: RouteObj()?.step === '0' && 'none'
            }}>
            <h1>{RouteObj()?.title}</h1>
            <h2>
              Step <span>{RouteObj()?.step}</span> of 5
            </h2>
            <ProgressBar
              style={{ padding: 0 }}
              percentage={() => RouteObj()?.progress}>
              <div></div>
            </ProgressBar>
          </NavMobile>
        )}

        <MainMobile>{children}</MainMobile>
      </MobileLayout>
    </Kyc>
  );
};

export default KycLayout;
