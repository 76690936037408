import axios from 'axios';

import { createAxiosConfigWithAuthHeader } from '../../utils/createAxiosConfigWithAuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

export const getPackageAsync = async () => {
  const url = `${API_URL}/package/my-package`;

  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const getPackagesAsync = async () => {
  const url = `${API_URL}/package`;

  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const addPackageAsync = async (packageId, currencyType, networkType) => {
  const url = `${API_URL}/package/user`;
  return await axios.post(
    url,
    currencyType === 1
      ? { packageId, currencyType, networkType }
      : { packageId, currencyType },
    createAxiosConfigWithAuthHeader()
  );
};

export const upgradePackageAsync = async (packageId) => {
  const url = `${API_URL}/package/user/upgrade`;

  return await axios.post(
    url,
    { packageId },
    createAxiosConfigWithAuthHeader()
  );
};
