import React, { useEffect, useState } from 'react';
import Alert from '../../components/common/Alert';
import Spinner from '../../components/common/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { reSendEmail } from '../../actions/authActions';
import { useLocation } from 'react-router';
import { CaptionText, H5, P3 } from '../../styles/FontStyles';
import { color } from '../../styles/GlobalStyles';
import { useTranslation } from 'react-i18next';

const TIMEOUT = 60;

const ConfirmEmail = ({ history }) => {
  const { t } = useTranslation('onboarding.confirm_email');
  const [activeTimer, setActiveTimer] = useState(false);
  const [time, setTime] = useState(TIMEOUT);
  const [intervalID, setIntervalID] = useState(null);
  let hasTimerEnded = time <= 0;
  const isTimerRunning = intervalID != null;

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.register) ?? {};

  const { user, message, error, loading } = userInfo;

  const sendMail = () => {
    let id = user?.id;
    if (location.state?.from === '/add-team-member-email') {
      id = location.state.id;
    }

    if (!activeTimer) {
      dispatch(reSendEmail(id, history));
      startTimer();
    }
  };

  useEffect(() => {
    startTimer();
  }, [userInfo, dispatch]);

  const location = useLocation();
  let email = user?.email;
  if (location.state?.from === '/add-team-member-email') {
    email = location.state.email;
  }

  const startTimer = () => {
    if (!hasTimerEnded && !isTimerRunning) {
      setIntervalID(setInterval(update, 1000));
      setActiveTimer(true);
    }
  };

  const update = () => {
    setTime((time) => time - 1);
  };

  useEffect(() => {
    if (hasTimerEnded) {
      setTime(TIMEOUT);
      clearInterval(intervalID);
      setIntervalID(null);
      setActiveTimer(false);
    }
  }, [hasTimerEnded]);

  useEffect(
    () => () => {
      clearInterval(intervalID);
    },
    []
  );

  return (
    <>
      {loading ? (
        <Spinner fullScreen={true} />
      ) : (
        <div style={{ textAlign: 'center' }}>
          {error && <Alert message={error.error} />}
          {message && <Alert icon={'success'} message={message} />}
          <H5 center mb='30' color={color.colors.neutral700}>
            {t('verify_your_email')}
          </H5>
          <P3 m='40px 0' color={color.colors.neutral500}>
            {t('we_sent_email_to')}
          </P3>
          <P3 mb='40' color={color.colors.neutral700}>
            <b>{email}</b>{' '}
          </P3>
          <P3 color={color.colors.neutral500}>{t('follow_instructions')}</P3>
          <CaptionText
            pointer
            color={color.colors.primary900}
            bold
            style={{
              marginTop: '40px',
              marginBottom: '20px',
              color: activeTimer && '#C6BEBD',
              cursor: activeTimer && 'auto'
            }}
            onClick={sendMail}>
            {/* Didn't receive an email? Resend. */}
            {t('resend_email')}{' '}
            {activeTimer && t('time_remains', { time: time })}
          </CaptionText>
          <CaptionText
            color={color.colors.primary900}
            bold
            pointer
            onClick={() =>
              history.push({
                pathname: '/email-address',
                state: {
                  path: '/confirm-email'
                }
              })
            }>
            {t('chage_email')}
          </CaptionText>
        </div>
      )}
    </>
  );
};

export default ConfirmEmail;
