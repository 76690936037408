import styled from 'styled-components';
import { color } from '../../styles/GlobalStyles';

const View = styled.div`
  margin: 20px 0;
  padding: 0 0 100px 0;
`;

const TitleImage = styled.div`
  width: 100%;
  @media (max-width: 1000px) {
    /* display: none; */
    /* justify-content: center; */
    text-align: center;
  }
`;

const Small = styled.div`
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
  color: #b5a8a8;
  max-width: 700px;
`;

const Medium = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;

const FlexResponsive = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;

const Desktop = styled.div`
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const RightMarginDiv = styled.div`
  margin-right: 40px;
  @media (max-width: 1000px) {
    margin-right: 0px;
  }
`;

const ImagePHContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fefefe;
  /* border: 1px dashed #c6bdbd; */
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  min-height: 200px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #2d2d2d;
  padding: 40px;
  /* margin: 0 0 20px 0; */
`;

const ImagePHContainerSelfie = styled(ImagePHContainer)`
  max-width: 332px;
  aspect-ratio: 1/1;
  @media (max-width: 550px) {
    max-width: 550px;
  }
  border: 2px dashed ${({ theme }) => theme.colors.neutral400};
  margin: 0 0 20px 0;
`;

const ImagePHContainerFilled = styled.div`
  position: relative;
  border: 1px solid #c6bdbd;
  background: #fefefe;
  border-radius: 8px;
  padding: 7px 27px;
  margin: 0 0 20px 0;
  width: 100%;
  > img {
    width: 100%;
  }
`;

const ImagePHContainerFilledSelfie = styled(ImagePHContainerFilled)`
  max-width: 332px;
  aspect-ratio: 1/1;
  padding: 0;
  overflow: hidden;
  @media (max-width: 550px) {
    max-width: 550px;
  }
`;

const ImageUploadedOverlay = styled.div`
  position: absolute;
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fefefe;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.3) 86.78%,
    rgba(0, 0, 0, 0.4) 100%
  );
`;

const ImageUploadingOverlay = styled.div`
  position: absolute;
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fefefe;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.3) 86.78%,
    rgba(0, 0, 0, 0.4) 100%
  );
  /* filter: blur(8px); */
  div {
    display: flex;
    align-items: center;
    span {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #ff5700;
      margin-right: 8px;
      background: #ff5700;
    }
  }
`;

const ImageEditOverlay = styled.div`
  position: absolute;
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`;

const Agreement = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 20px;
`;

const AgreementCheckbox = styled.input`
  margin-right: 20px;
  flex: none;
  width: 19px;
  height: 19px;
`;

export const KycBrowseFilesButton = styled.label`
  cursor: pointer;
  border: none;
  font-size: 17px;
  height: 55px !important;
  width: 100%;
  border-radius: 8px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 34px !important;
  width: 111px;
  color: ${(props) => props.theme.kycBrowseFilesButtonFontColor};
  background: ${(props) => props.theme.kycBrowseFilesButtonBackground};
  border: ${(props) => props.theme.kycBrowseFilesButtonBorder};
  transition: transform 0.1s ease;
  :active {
    transform: translateY(3px);
  }
`;

export const CamCaptured = styled.div`
  border-radius: 8px;
  overflow: hidden;
  border: ${(props) => props.theme.kycCamCapturedBorder};

  img {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CameraUnderlineText = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  text-align: center;
  color: ${color.colors.primary900};
`;

export const Camera = styled.div`
  border-radius: 8px;
  overflow: hidden;
  border: ${(props) => props.theme.kycCamBorder};
  width: 100%;

  > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    vertical-align: middle;
  }
`;

export const CamCapturedCircle = styled.div`
  border-radius: 50%;
  overflow: hidden;
  border: ${color.kycCamCapturedBorder};
  height: 311px;
  width: 311px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 800px) {
    width: 220px;
    height: 220px;
  }
`;

export const CamCircle = styled.div`
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  border: ${color.kycCamBorder};
  width: 311px;
  height: 311px;
  z-index: 100;
  @media (max-width: 800px) {
    width: 220px;
    height: 220px;
  }
`;

export {
  View,
  Mobile,
  Desktop,
  FlexResponsive,
  RightMarginDiv,
  ImagePHContainerFilled,
  ImagePHContainer,
  Small,
  Medium,
  ImageUploadedOverlay,
  ImageEditOverlay,
  ImageUploadingOverlay,
  ImagePHContainerSelfie,
  ImagePHContainerFilledSelfie,
  Agreement,
  AgreementCheckbox,
  TitleImage
};
