/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createUserWallet } from '../../../actions/walletActions';
import IconMetamask from '../../../assets/icons/metamask.svg';
import Alert from '../../../components/common/Alert';
import { InputBackOffice } from '../../../components/common/InputBackoffice';
import Spinner from '../../../components/common/Spinner';
import { setErorMessage } from '../../../helpers/helperFunctions';
import {
  connectWallet,
  ethereum,
  signTransaction
} from '../../../hooks/MetaMask';
// import useMetaMask from '../../../hooks/useMetaMask';
import { P2, P3 } from '../../../styles/FontStyles';
import { Button, Flexed } from '../../../styles/GlobalComponents';
import { Subtitle, Title } from './WithdrawStyled';

export const AddBankAccount = ({ setIsOpen2, setStep }) => {
  const { t } = useTranslation('earnings');
  const dispatch = useDispatch();
  const [address, setAddress] = useState('');
  const [label, setLabel] = useState('');
  const [error, setError] = useState('');
  const { error: errorMsg } = useSelector((state) => state.withdraw);

  useEffect(() => {
    if (ethereum !== undefined) {
      ethereum.on('accountsChanged', (a) => {
        setAddress(a[0]);
      });
    }
  }, []);

  return (
    <div style={{ marginTop: !setIsOpen2 && '12vh' }}>
      {error && <Alert message={error} />}
      {errorMsg && <Alert message={errorMsg.error} />}
      <Title>{t('withdraw_part.add_bank_account.title')}</Title>
      <Subtitle style={{ textAlign: 'center' }}>
        {t('withdraw_part.add_bank_account.subtitle')}
      </Subtitle>

      <P3 mb='8' bold>
        {t('withdraw_part.add_bank_account.walletLabel')}
      </P3>

      <InputBackOffice
        placeholder={t('withdraw_part.add_bank_account.placeholderLabel')}
        style={{ marginBottom: 16 }}
        value={label}
        onChange={(e) => setLabel(e)}
      />

      <P3 mb='8' w='100%' bold>
        {t('withdraw_part.add_bank_account.walletAddress')}
      </P3>

      <InputBackOffice
        placeholder={t('withdraw_part.add_bank_account.placeholderWallet')}
        style={{ marginBottom: 16 }}
        value={address}
        onChange={(e) => setAddress(e.replace(/\s+/g, ''))}
      />

      <P2 center>OR</P2>

      {/* <Button
        onClick={isActive ? disconnect : connect}
        mt='20'
        negative={isActive}>
        <Flexed center justifyCenter gap='16px'>
          <img src={IconMetamask} style={{ height: 36 }} />
          {isActive ? 'Disconnect Metamask' : 'Connect to Metamask'}
        </Flexed>
      </Button> */}

      <Button onClick={() => connectWallet(setAddress)} mt='20'>
        <Flexed center justifyCenter gap='16px'>
          <img src={IconMetamask} style={{ height: 36 }} />
          Connect to Metamask
        </Flexed>
      </Button>

      <Button onClick={() => signTransaction(address)} mt='20'>
        <Flexed center justifyCenter gap='16px'>
          <img src={IconMetamask} style={{ height: 36 }} />
          Sign with Metamask
        </Flexed>
      </Button>

      <Button
        onClick={() => {
          if (!address) {
            setErorMessage(
              setError,
              t('withdraw_part.add_bank_account.err_address_required')
            );
          } else {
            dispatch(
              createUserWallet(
                address,
                setAddress,
                setStep,
                true,
                label,
                setLabel
              )
            );
          }
        }}
        mt='20'>
        {t('withdraw_part.add_bank_account.add')}
      </Button>
    </div>
  );
};
