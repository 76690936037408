export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const LOGIN_FACEBOOK_REQUEST = 'LOGIN_FACEBOOK_REQUEST';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';
export const LOGIN_FACEBOOK_FAIL = 'LOGIN_FACEBOOK_FAIL';

export const LOGIN_LINKEDIN_REQUEST = 'LOGIN_LINKEDIN_REQUEST';
export const LOGIN_LINKEDIN_SUCCESS = 'LOGIN_LINKEDIN_SUCCESS';
export const LOGIN_LINKEDIN_FAIL = 'LOGIN_LINKEDIN_FAIL';

export const LOGIN_WITH_SOCIAL_NETWORK_REQUEST =
  'LOGIN_WITH_SOCIAL_NETWORK_REQUEST';
export const LOGIN_WITH_SOCIAL_NETWORK_SUCCESS =
  'LOGIN_WITH_SOCIAL_NETWORK_SUCCESS';
export const LOGIN_WITH_SOCIAL_NETWORK_FAIL = 'LOGIN_WITH_SOCIAL_NETWORK_FAIL';

export const CREATE_SOCIAL_REFERRER_ID_REQUEST =
  'CREATE_SOCIAL_REFERRER_ID_REQUEST';
export const CREATE_SOCIAL_REFERRER_ID_SUCCESS =
  'CREATE_SOCIAL_REFERRER_ID_SUCCESS';
export const CREATE_SOCIAL_REFERRER_ID_FAIL = 'CREATE_SOCIAL_REFERRER_ID_FAIL';

export const LOGIN_GOOGLE_REQUEST = 'LOGIN_GOOGLE_REQUEST';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL';

export const LOGIN_APPLE_REQUEST = 'LOGIN_APPLE_REQUEST';
export const LOGIN_APPLE_SUCCESS = 'LOGIN_APPLE_SUCCESS';
export const LOGIN_APPLE_FAIL = 'LOGIN_APPLE_FAIL';

export const CREATE_USER_PHONE_REQUEST = 'CREATE_USER_PHONE_REQUEST';
export const CREATE_USER_PHONE_SUCCESS = 'CREATE_USER_PHONE_SUCCESS';
export const CREATE_USER_PHONE_FAIL = 'CREATE_USER_PHONE_FAIL';

export const CONFIRM_SMS_REQUEST = 'CONFIRM_SMS_REQUEST';
export const CONFIRM_SMS_SUCCESS = 'CONFIRM_SMS_SUCCESS';
export const CONFIRM_SMS_FAIL = 'CONFIRM_SMS_FAIL';

export const RESEND_SMS_REQUEST = 'RESEND_SMS_REQUEST';
export const RESEND_SMS_SUCCESS = 'RESEND_SMS_SUCCESS';
export const RESEND_SMS_FAIL = 'RESEND_SMS_FAIL';

export const RESEND_PHONE_REQUEST = 'RESEND_PHONE_REQUEST';
export const RESEND_PHONE_SUCCESS = 'RESEND_PHONE_SUCCESS';
export const RESEND_PHONE_FAIL = 'RESEND_PHONE_FAIL';

export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAIL = 'CONFIRM_EMAIL_FAIL';

export const CONFIRM_EMAIL_LINK_REQUEST = 'CONFIRM_EMAIL_LINK_REQUEST';
export const CONFIRM_EMAIL_LINK_SUCCESS = 'CONFIRM_EMAIL_LINK_SUCCESS';
export const CONFIRM_EMAIL_LINK_FAIL = 'CONFIRM_EMAIL_LINK_FAIL';

export const CHANGE_EMAIL_LINK_REQUEST = 'CHANGE_EMAIL_LINK_REQUEST';
export const CHANGE_EMAIL_LINK_SUCCESS = 'CHANGE_EMAIL_LINK_SUCCESS';
export const CHANGE_EMAIL_LINK_FAIL = 'CHANGE_EMAIL_LINK_FAIL';

export const RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAIL = 'RESEND_EMAIL_FAIL';

export const SEND_PASSWORD_REQUEST = 'SEND_PASSWORD_REQUEST';
export const SEND_PASSWORD_SUCCESS = 'SEND_PASSWORD_SUCCESS';
export const SEND_PASSWORD_FAIL = 'SEND_PASSWORD_FAIL';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const FORGOTTEN_ID_REQUEST = 'FORGOTTEN_ID_REQUEST';
export const FORGOTTEN_ID_SUCCESS = 'FORGOTTEN_ID_SUCCESS';
export const FORGOTTEN_ID_FAIL = 'FORGOTTEN_ID_FAIL';

export const FORGOTTEN_PASSWORD_REQUEST = 'FORGOTTEN_PASSWORD_REQUEST';
export const FORGOTTEN_PASSWORD_SUCCESS = 'FORGOTTEN_PASSWORD_SUCCESS';
export const FORGOTTEN_PASSWORD_FAIL = 'FORGOTTEN_PASSWORD_FAIL';

export const CONFIRM_NEW_PASSWORD_SUCCESS = 'CONFIRM_NEW_PASSWORD_SUCCESS';
export const CONFIRM_NEW_PASSWORD_FAIL = 'CONFIRM_NEW_PASSWORD_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const FORGOTTEN_PASSWORD_SMS_REQUEST = 'FORGOTTEN_PASSWORD_SMS_REQUEST';
export const FORGOTTEN_PASSWORD_SMS_SUCCESS = 'FORGOTTEN_PASSWORD_SMS_SUCCESS';
export const FORGOTTEN_PASSWORD_SMS_FAIL = 'FORGOTTEN_PASSWORD_SMS_FAIL';

export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAIL = 'EDIT_PROFILE_FAIL';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const UPDATE_SOCIAL_PROFILE_REQUEST = 'UPDATE_SOCIAL_PROFILE_REQUEST';
export const UPDATE_SOCIAL_PROFILE_SUCCESS = 'UPDATE_SOCIAL_PROFILE_SUCCESS';
export const UPDATE_SOCIAL_PROFILE_FAIL = 'UPDATE_SOCIAL_PROFILE_FAIL';

export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS';
export const UPDATE_PROFILE_IMAGE_FAIL = 'UPDATE_PROFILE_IMAGE_FAIL';

export const SOCIAL_REFERRAL_REQUEST = 'SOCIAL_REFERRAL_REQUEST';
export const SOCIAL_REFERRAL_SUCCESS = 'SOCIAL_REFERRAL_SUCCESS';
export const SOCIAL_REFERRAL_FAIL = 'SOCIAL_REFERRAL_FAIL';

export const GET_REFERRAL_REQUEST = 'GET_REFERRAL_REQUEST';
export const GET_REFERRAL_SUCESS = 'GET_REFERRAL_SUCESS';
export const GET_REFERRAL_FAIL = 'GET_REFERRAL_FAIL';

export const ADD_SOCIAL_TERMS_REQUEST = 'ADD_SOCIAL_TERMS_REQUEST';
export const ADD_SOCIAL_TERMS_SUCCESS = 'ADD_SOCIAL_TERMS_SUCCESS';
export const ADD_SOCIAL_TERMS_FAIL = 'ADD_SOCIAL_TERMS_FAIL';

export const ADD_SOCIAL_PHONE_REQUEST = 'ADD_SOCIAL_PHONE_REQUEST';
export const ADD_SOCIAL_PHONE_SUCCESS = 'ADD_SOCIAL_PHONE_SUCCESS';
export const ADD_SOCIAL_PHONE_FAIL = 'ADD_SOCIAL_PHONE_FAIL';

export const CONFIRM_SOCIAL_PHONE_REQUEST = 'CONFIRM_SOCIAL_PHONE_REQUEST';
export const CONFIRM_SOCIAL_PHONE_SUCCESS = 'CONFIRM_SOCIAL_PHONE_SUCCESS';
export const CONFIRM_SOCIAL_PHONE_FAIL = 'CONFIRM_SOCIAL_PHONE_FAIL';

export const RESEND_SOCIAL_PHONE_REQUEST = 'RESEND_SOCIAL_PHONE_REQUEST';
export const RESEND_SOCIAL_PHONE_SUCCESS = 'RESEND_SOCIAL_PHONE_SUCCESS';
export const RESEND_SOCIAL_PHONE_FAIL = 'RESEND_SOCIAL_PHONE_FAIL';

export const SOCIAL_REGISTER_SUCCESS = 'SOCIAL_REGISTER_SUCCESS';
export const SOCIAL_REGISTER_FAIL = 'SOCIAL_REGISTER_FAIL';

export const CLEAR_REGISTER_STATE = 'CLEAR_REGISTER_STATE';

export const CONTINUE_TO_REFERRAL_REQUEST = 'CONTINUE_TO_REFERRAL_REQUEST';
export const CONTINUE_TO_PHONE_NUMBER = 'CONTINUE_TO_PHONE_NUMBER';

export const CONTINUE_TO_TERMS_CONDITION = 'CONTINUE_TO_TERMS_CONDITION';

export const SET_REFFERAL_ID = 'SET_REFFERAL_ID';

export const SIGN_UP_CUSTOMER_REQUEST = 'SIGN_UP_CUSTOMER_REQUEST';
export const SIGN_UP_CUSTOMER_SUCCESS = 'SIGN_UP_CUSTOMER_SUCCESS';
export const SIGN_UP_CUSTOMER_FAIL = 'SIGN_UP_CUSTOMER_FAIL';

export const SIGN_IN_COMPLETE_REQUEST = 'SIGN_IN_COMPLETE_REQUEST';
export const SIGN_IN_COMPLETE_SUCCESS = 'SIGN_IN_COMPLETE_SUCCESS';
export const SIGN_IN_COMPLETE_FAIL = 'SIGN_IN_COMPLETE_FAIL';

export const SIGN_IN_FAIL_USER = 'SIGN_IN_FAIL_USER';

export const SET_TOOGLE_MODAL = 'SET_TOOGLE_MODAL';
export const SET_TOOGLE_SIDE_BAR = 'SET_TOOGLE_SIDE_BAR';
export const SET_TOOGLE_MODAL_MOBILE = 'SET_TOOGLE_MODAL_MOBILE';
export const SET_SIDEBAR_MOBILE = 'SET_SIDEBAR_MOBILE';

export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';

export const CONTINUE_TO_AFFILIATE_TERMS = 'CONTINUE_TO_AFFILIATE_TERMS';

export const SET_APPROVAL_DATE = 'SET_APPROVAL_DATE';

export const LOGOUT = 'LOGOUT';

export const FIRST_LOGIN_REQUEST = 'FIRST_LOGIN_REQUEST';
export const FIRST_LOGIN_SUCCESS = 'FIRST_LOGIN_SUCCESS';
export const FIRST_LOGIN_FAIL = 'FIRST_LOGIN_FAIL';
