import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { collapsePackage } from '../../actions/packagesActions';

import {
  CheckIconBox,
  Collapsable,
  HalfContainer,
  Notice,
  Package,
  PackageBenefit,
  PackageButton,
  PackageContent,
  PackagePrice,
  PackagesContainer,
  PackageSubtitle,
  PackageTitle
} from './PackagesStyled';

import { Stars } from '../../utils/packageStars';

import { useWindowWide } from '../../utils/wideScreen';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { AnimatedTab } from '../../components/animation/AnimatedTab';
import { formatUSD, USDT } from '../../helpers/helperFunctions';
import { DASHBOARD } from '../../routes/routes';
import { P2 } from '../../styles/FontStyles';
import {
  ArrowPolygon,
  Button,
  SectionSeparatorVertical
} from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { Icon, IconPackagesCheck } from '../../styles/Icons';
import { FlexCenter } from '../../styles/stylesStyled';

const Packages = ({
  isOpen,
  setIsOpen,
  selectedPackage,
  setSelectedPackage,
  location,
  setStep
}) => {
  const [isOpen2, setIsOpen2] = useState(false);

  const dispatch = useDispatch();

  const { packages, waitingForPaymentPackageId } = useSelector(
    (state) => state.packagesList
  );

  const wide = useWindowWide(800);

  const { t } = useTranslation('packages');

  return (
    <>
      <PackagesContainer>
        <HalfContainer>
          {packages?.map((item, index) => {
            if (item.packageId !== 27) {
              return (
                <div key={index}>
                  <Package
                    onClick={() => {
                      setSelectedPackage(item);
                      dispatch(collapsePackage(item.packageId));
                    }}
                    current={selectedPackage?.packageId === item.packageId}>
                    <div>
                      <div>
                        <PackageTitle
                          current={
                            selectedPackage?.packageId === item.packageId
                          }>
                          {item?.imageTitle}
                        </PackageTitle>
                        <PackagePrice
                          current={
                            selectedPackage?.packageId === item.packageId
                          }>
                          {formatUSD(item?.imagePrice)} {USDT}
                        </PackagePrice>
                      </div>

                      <FlexCenter>
                        <Button
                          onClick={() => {
                            setIsOpen(!isOpen);
                            setStep(5);
                          }}
                          negative={
                            selectedPackage?.packageId === item.packageId
                          }
                          w={wide ? '150px' : '100px'}>
                          {t('packages.buy_now')}
                        </Button>
                        {!wide && (
                          <div
                            style={{
                              marginLeft: 16
                            }}>
                            <ArrowPolygon
                              direction={item.collapse ? 'down' : 'right'}
                              customColor={
                                selectedPackage?.packageId === item.packageId
                                  ? color.colors.neutral50
                                  : color.colors.neutral900
                              }
                            />
                          </div>
                        )}
                      </FlexCenter>
                    </div>
                  </Package>

                  {!wide && (
                    <Collapsable expanded={item.collapse}>
                      {selectedPackage && (
                        <PackageContent style={{ width: '100%' }}>
                          {selectedPackage.packageId ===
                            waitingForPaymentPackageId && (
                            <Notice>
                              {t('packages.notice')}:
                              <br />
                              <br />
                              <div>
                                {t('packages.you_have_ordered')}
                                {packages?.map((item) => {
                                  if (
                                    item.packageId ===
                                    waitingForPaymentPackageId
                                  ) {
                                    return (
                                      <>
                                        {' '}
                                        “ {item?.imageTitle}{' '}
                                        {Stars(item?.stars)} ”
                                      </>
                                    );
                                  } else return null;
                                })}{' '}
                                {t('packages.tex')}
                                <br /> {t('packages.text2')}{' '}
                                <b>{t('packages.text3')}</b>{' '}
                                {t('packages.text4')}
                                <br />
                                <br />
                                <p
                                  style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    setIsOpen2(!isOpen2);
                                    setStep(1);
                                  }}>
                                  {t('packages.instructions')}
                                </p>
                              </div>
                            </Notice>
                          )}

                          <PackageBenefit>
                            <div style={{ marginTop: 26 }}>
                              <PackageSubtitle>
                                ULX {t('packages.value')}
                              </PackageSubtitle>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginBottom: '16px'
                                }}>
                                <CheckIconBox>
                                  <Icon>
                                    <IconPackagesCheck />
                                  </Icon>
                                </CheckIconBox>

                                <PackagePrice style={{ marginBottom: 3 }}>
                                  ULX Staking ratio:{' '}
                                  {selectedPackage?.StakeRatio}
                                </PackagePrice>
                              </div>
                            </div>

                            <div style={{ marginTop: 26 }}>
                              <PackageSubtitle>
                                {t('packages.benefits')}
                              </PackageSubtitle>

                              {selectedPackage?.benefits?.map((item, i) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '16px'
                                  }}
                                  key={i}>
                                  <CheckIconBox>
                                    <Icon>
                                      <IconPackagesCheck />
                                    </Icon>
                                  </CheckIconBox>
                                  <PackagePrice>{item.text}</PackagePrice>
                                </div>
                              ))}
                            </div>
                            {selectedPackage?.projectX && (
                              <div style={{ marginTop: 26 }}>
                                <PackageSubtitle>
                                  {t('packages.project')}
                                  10X
                                </PackageSubtitle>

                                {selectedPackage?.projectX?.map((item, i) => (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '16px'
                                    }}
                                    key={i}>
                                    <CheckIconBox>
                                      <Icon>
                                        <IconPackagesCheck />
                                      </Icon>
                                    </CheckIconBox>
                                    <PackagePrice>{item.text}</PackagePrice>
                                  </div>
                                ))}
                              </div>
                            )}
                          </PackageBenefit>

                          <PackageButton
                            onClick={() => {
                              setIsOpen(!isOpen);
                              setStep(5);
                            }}
                            style={{ width: '100%', height: 55, fontSize: 14 }}>
                            {t('packages.confirm')}
                          </PackageButton>
                        </PackageContent>
                      )}
                    </Collapsable>
                  )}
                </div>
              );
            }
          })}
          {location.pathname === '/welcome-packages' && (
            <P2
              mt='50'
              pointer
              center
              underline
              onClick={() => history.push(DASHBOARD)}>
              {t('skip')}
            </P2>
          )}
        </HalfContainer>

        {selectedPackage && wide && (
          <SectionSeparatorVertical margin='0 80px' />
        )}

        {selectedPackage && wide && (
          <HalfContainer>
            <AnimatePresence exitBeforeEnter initial={false}>
              {packages?.map((packag, i) => {
                if (packag.packageId === selectedPackage?.packageId) {
                  return (
                    <AnimatedTab key={i}>
                      {waitingForPaymentPackageId && (
                        <Notice>
                          {t('packages.notice')}:
                          <br />
                          <br />
                          <div style={{ lineHeight: '140%' }}>
                            {t('packages.you_have_ordered')}:
                            {packages?.map((item) => {
                              if (
                                item.packageId === waitingForPaymentPackageId
                              ) {
                                return (
                                  <>
                                    {' '}
                                    “ {item?.imageTitle} {Stars(item?.stars)} ”
                                  </>
                                );
                              } else return null;
                            })}
                            {t('packages.text1')}
                            <br /> {t('packages.text2')}{' '}
                            <b>{t('packages.text3')}</b> {t('packages.text4')}
                            <br />
                            <br />
                            <p
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setIsOpen2(!isOpen2);
                                setStep(1);
                              }}>
                              {t('packages.instructions')}
                            </p>
                          </div>
                        </Notice>
                      )}
                      <PackageTitle>
                        {selectedPackage?.imageTitle}{' '}
                        {Stars(selectedPackage?.stars)}
                      </PackageTitle>
                      <PackageBenefit>
                        <div style={{ marginTop: 26 }}>
                          <PackageSubtitle>
                            ULX {t('packages.value')}
                          </PackageSubtitle>

                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '16px'
                            }}>
                            <CheckIconBox>
                              <Icon>
                                <IconPackagesCheck />
                              </Icon>
                            </CheckIconBox>
                            <PackagePrice style={{ marginBottom: 3 }}>
                              ULX Staking ratio: {selectedPackage?.StakeRatio}
                            </PackagePrice>
                          </div>
                        </div>

                        <div style={{ marginTop: 26 }}>
                          <PackageSubtitle>
                            {t('packages.benefits')}
                          </PackageSubtitle>

                          {selectedPackage?.benefits?.map((item, i) => (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '16px'
                              }}
                              key={i}>
                              <CheckIconBox>
                                <Icon>
                                  <IconPackagesCheck />
                                </Icon>
                              </CheckIconBox>
                              <PackagePrice style={{ marginBottom: 3 }}>
                                {item.text}
                              </PackagePrice>
                            </div>
                          ))}
                        </div>
                        {selectedPackage?.projectX && (
                          <div style={{ marginTop: 26 }}>
                            <PackageSubtitle>
                              {t('packages.project')} 10X
                            </PackageSubtitle>

                            {selectedPackage?.projectX?.map((item, i) => (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginBottom: '16px'
                                }}
                                key={i}>
                                <CheckIconBox>
                                  <Icon>
                                    <IconPackagesCheck />
                                  </Icon>
                                </CheckIconBox>
                                <PackagePrice style={{ marginBottom: 3 }}>
                                  {item.text}
                                </PackagePrice>
                              </div>
                            ))}
                          </div>
                        )}
                      </PackageBenefit>
                    </AnimatedTab>
                  );
                }
              })}
            </AnimatePresence>
          </HalfContainer>
        )}
      </PackagesContainer>
    </>
  );
};

export default Packages;
