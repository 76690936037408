import React from 'react';
// styles
import { AnimatedDashboardWrapper } from './AnimatedDashboardStyled';

export const AnimatedDashboard = ({ children, zoom }) => {
  return (
    <AnimatedDashboardWrapper
      zoom={zoom}
      initial={{ opacity: 0, marginTop: -20 }}
      animate={{ opacity: 1, marginTop: 20 }}
      exit={{ opacity: 0, marginTop: 60 }}
      transition={{
        duration: 0.2
      }}>
      {children}
    </AnimatedDashboardWrapper>
  );
};
