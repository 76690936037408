import axios from 'axios';
import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  SET_USER_TO_AMBASSADOR_REQUEST,
  SET_USER_TO_AMBASSADOR_SUCCESS,
  SET_USER_TO_AMBASSADOR_FAIL,
  GET_TEAM_USER_INFO_REQUEST,
  GET_TEAM_USER_INFO_SUCCESS,
  GET_TEAM_USER_INFO_FAIL,
  GET_REFERRER_INFO_REQUEST,
  GET_REFERRER_INFO_SUCCESS,
  GET_REFERRER_INFO_FAIL
} from '../constants/userDetailsConstants';

const API_URL = process.env.REACT_APP_API_URL;

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_USER_DETAILS_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    const { data } = await axios.get(`${API_URL}/user/info`, config);

    dispatch({
      type: GET_USER_DETAILS_SUCCESS,
      payload: {
        user: data.data.user,
        generatedRefLinkBefore: data.data.isReferralLinkGenerated
      }
    });
  } catch (error) {
    dispatch({
      type: GET_USER_DETAILS_FAIL,
      payload: error.response && error.response.data
    });
  }
};

export const setUserToAmbassador = (history) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_USER_TO_AMBASSADOR_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    const { data } = await axios.put(`${API_URL}/user/rank/ambassador`, config);

    dispatch({
      type: SET_USER_TO_AMBASSADOR_SUCCESS,
      payload: data.data.userRank
    });

    history.push('/welcome-rank');
  } catch (error) {
    dispatch({
      type: SET_USER_TO_AMBASSADOR_FAIL,
      payload: error.response && error.response.data
    });
  }
};

export const getTeamUserInfo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_TEAM_USER_INFO_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token.accessToken}`
      }
    };

    const { data } = await axios.get(
      `${API_URL}/user/team/info?userId=${id}`,
      config
    );

    dispatch({
      type: GET_TEAM_USER_INFO_SUCCESS,
      payload: data.data.teamUser
    });
  } catch (error) {
    dispatch({
      type: GET_TEAM_USER_INFO_FAIL,
      payload: error.response && error.response.data
    });
  }
};

export const getReferrerInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_REFERRER_INFO_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json'
        // Authorization: `Bearer ${userInfo.token.accessToken}`,
      }
    };

    const { data } = await axios.get(`${API_URL}/user/referrer/${id}`, config);

    dispatch({
      type: GET_REFERRER_INFO_SUCCESS,
      payload: data.data.user
    });
  } catch (error) {
    dispatch({
      type: GET_REFERRER_INFO_FAIL,
      payload: error.response && error.response.data
    });
  }
};
