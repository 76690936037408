import axios from 'axios';
import { createAxiosConfigWithAuthHeader } from '../../utils/createAxiosConfigWithAuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

export const getPackageAsync = async () => {
  const url = `${API_URL}/package/my-package`;

  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const getPackagesAsync = async () => {
  const url = `${API_URL}/package`;

  return await axios.get(url, createAxiosConfigWithAuthHeader());
};

export const addHubAsync = async (
  packageId,
  currencyType,
  networkType,
  licenceId
) => {
  const url = `${API_URL}/package/user`;

  return await axios.post(
    url,
    currencyType === 1
      ? { packageId, currencyType, networkType, licenceId }
      : { packageId, currencyType, licenceId },
    createAxiosConfigWithAuthHeader()
  );
};

export const registerKycOnX1PlatformAsync = async () => {
  const url = `${API_URL}/x1widget/kyc`;

  return await axios.post(url, createAxiosConfigWithAuthHeader());
};
