const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const GET_WAITING_PACKAGE = {
  REQUEST: getRequestConst('GET_WAITING_PACKAGE'),
  SUCCESS: getSuccessConst('GET_WAITING_PACKAGE'),
  FAIL: getFailConst('GET_WAITING_PACKAGE')
};

export const ADD_PACKAGE = {
  REQUEST: getRequestConst('ADD_PACKAGE'),
  SUCCESS: getSuccessConst('ADD_PACKAGE'),
  FAIL: getFailConst('ADD_PACKAGE')
};

export const UPGRADE_PACKAGES = {
  REQUEST: getRequestConst('UPGRADE_PACKAGES'),
  SUCCESS: getSuccessConst('UPGRADE_PACKAGES'),
  FAIL: getFailConst('UPGRADE_PACKAGES')
};

export const GET_ORDERS = {
  REQUEST: getRequestConst('GET_ORDERS'),
  SUCCESS: getSuccessConst('GET_ORDERS'),
  FAIL: getFailConst('GET_ORDERS')
};

export const CANCEL_PACKAGES = {
  REQUEST: getRequestConst('CANCEL_PACKAGES'),
  SUCCESS: getSuccessConst('CANCEL_PACKAGES'),
  FAIL: getFailConst('CANCEL_PACKAGES')
};

export const SET_TAB = 'SET_TAB';
