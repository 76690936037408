import axios from 'axios';

import { createAxiosConfigWithAuthHeader } from '../../utils/createAxiosConfigWithAuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

export const getEarningsInfoAsync = async () => {
  return await axios.get(
    `${API_URL}/user/earnings/info`,
    createAxiosConfigWithAuthHeader()
  );
};

export const getEarningHistoryAsync = async ({
  id,
  sortBy,
  sortOrder,
  fromDate,
  toDate,
  status,
  earningsTypes,
  numberOfPage,
  countPerPage
}) => {
  return await axios.get(
    `${API_URL}/user/earnings/history`,
    {
      params: {
        sortBy,
        sortOrder,
        numberOfPage,
        countPerPage,
        id: id ? id : null,
        earningsTypes,
        status: status ? status : null,
        fromDate: fromDate && toDate ? fromDate : null,
        toDate: fromDate && toDate ? toDate : null
      }
    },
    createAxiosConfigWithAuthHeader()
  );
};

export const getEarningDetails = async ({ type, id }) => {
  return await axios.get(
    `${API_URL}/user/earnings/type/${type}/id/${id}`,
    createAxiosConfigWithAuthHeader()
  );
};
