import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Spinner from '../../../components/common/Spinner';
import { FlexCenter } from '../../../styles/stylesStyled';
import {
  Info,
  InfoRight,
  Subtitle,
  Title,
  AddressCheckbox
} from './WithdrawStyled';

import { ULX } from '../../../helpers/helperFunctions';
import { color } from '../../../styles/GlobalStyles';
import { CaptionText, P3 } from '../../../styles/FontStyles';
import { Button, SectionSeparator } from '../../../styles/GlobalComponents';
import { useTranslation } from 'react-i18next';

export const WithdrawFinalCheck = ({
  setStep,
  amount,
  selected,
  userWallets
}) => {
  const { t } = useTranslation('earnings');
  const [checkbox, setCheckbox] = useState(false);
  const [total, setTotal] = useState(0);

  const fee = Math.max(1, Number.parseFloat(((2.5 / 100) * amount).toFixed(2)));

  const amountToCalculate = amount;

  const calculatedAmountWithFee = Number.parseFloat(amountToCalculate) - fee;

  const { loadingWithdraw } = useSelector((state) => state.withdraw);

  const { verificationStatus } = useSelector((state) => state.settings);

  useEffect(() => {
    setTotal(parseInt(amount) - parseInt(fee));
  }, [amount, total]);

  return (
    <div>
      <>
        {loadingWithdraw ? (
          <Spinner dark />
        ) : (
          <>
            {' '}
            <Title>{t('withdraw_part.final_check.title')}</Title>
            <Subtitle>{t('withdraw_part.final_check.subtitleULX')}</Subtitle>
            <Info>
              <div>{t('withdraw_part.final_check.amount')}:</div>
              {parseInt(amount).toFixed(2)} {ULX}
            </Info>
            <Info style={{ wordBreak: 'break-all' }}>
              <div>{t('withdraw_part.final_check.network')}:</div>{' '}
              {t('withdraw_part.final_check.ultronBlockchain')}
            </Info>
            <Info style={{ wordBreak: 'break-all' }}>
              <div>{t('withdraw_part.final_check.address')}:</div>{' '}
              {userWallets[selected].address}
            </Info>
            <Info>
              <div>{t('withdraw_part.final_check.processing_fee')}:</div> {fee}{' '}
              {ULX}
            </Info>
            <SectionSeparator />
            <InfoRight>
              <div>{t('withdraw_part.final_check.subtotal')}:</div>
              <span>
                {`${parseInt(amount).toFixed(2)}`} {ULX}
              </span>
            </InfoRight>
            <InfoRight>
              <div>{t('withdraw_part.final_check.fee')}:</div>
              <span>
                {`${fee}`} {ULX}
              </span>
            </InfoRight>
            <SectionSeparator />
            <InfoRight>
              <div>{t('withdraw_part.final_check.total')}:</div>
              <span>
                {`${calculatedAmountWithFee}`} {ULX}
              </span>
            </InfoRight>
            <P3 bold color={color.colors.error500} mb='16'>
              {t('withdraw_part.final_check.infoULX')}
            </P3>
            <FlexCenter>
              <AddressCheckbox
                type='checkbox'
                checked={checkbox}
                onChange={() => setCheckbox(!checkbox)}
              />
              <CaptionText color={color.colors.neutral500} checked={checkbox}>
                {t('withdraw_part.final_check.confirm_txt')}
              </CaptionText>
            </FlexCenter>
            <FlexCenter style={{ flexDirection: 'column' }}>
              <Button
                maxWidth='200px'
                m={'30px 0 0 0'}
                opacity={'true'}
                disabled={!checkbox}
                onClick={() => {
                  if (!verificationStatus) {
                    setStep(8);
                  } else {
                    setStep(6);
                  }
                }}>
                {t('withdraw_part.final_check.withdrawULX')}
              </Button>
            </FlexCenter>
          </>
        )}
      </>
    </div>
  );
};
