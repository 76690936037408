import React, { useState } from 'react';

import { BACKOFFICE_NAME } from '../../helpers/helperFunctions';
import { P1 } from '../../styles/FontStyles';

import { Button } from '../../styles/GlobalComponents';

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

const Maintenance = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    padding-top: 150px;
  }
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.neutral50}de;
  > div {
    max-width: 500px;
    width: 90%;
  }
`;

export const Overlay = () => {
  const [overlay, setOverlay] = useState(false);

  return (
    <AnimatePresence exitBeforeEnter>
      {overlay && (
        <Maintenance
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.2
          }}
          key='overlay-ma'>
          <div>
            <P1 mb='40' center semiBold>
              &quot;MY HUBS&quot; SYNCHRONIZATION
              <br />
              <br />
              Dear user, we are happy to share that &quot;My Hubs&quot;
              synchronization process is at 80% for regular NFTs
              <br />
              <br />
              Please note that this process is ongoing, so you may see
              discrepancies on your dashboard due to the maintenance being done
              behind the {BACKOFFICE_NAME} platform.
              <br />
              <br />
              We would like to confirm that all purchases and pending orders
              will be visible and work as normal once the update is completed.
              <br />
              <br />
              Thank you for your understanding and support!
              <br />
              <br />
              Always here, MaVie Team
            </P1>
            <Button onClick={() => setOverlay(false)}>Continue</Button>
          </div>
        </Maintenance>
      )}
    </AnimatePresence>
  );
};
