import styled from 'styled-components';

export const Ranking = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 40px; */
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Rank = styled.div`
  width: 33%;
  padding: 4px;
  border-radius: 12px;
  background: ${(props) =>
    props.current
      ? 'linear-gradient(137.86deg,#d24074 0%,#1268c3 100%);'
      : '#7a7676;'};
  > div {
    padding: 40px;
    @media (max-width: 800px) {
      padding: 20px;
    }
    background: linear-gradient(
      137.86deg,
      #403c3b 10.11%,
      #2c2523 39.13%,
      #0c0a0a 99.98%
    );
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    h1 {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 40px;
      ${(props) =>
        props.current
          ? `background: -webkit-linear-gradient(
    160.43deg,
    #ee288a 1.87%,
    #f26c6b 50.93%,
    #f58e35 99.33%
  );
  color: #ff0084 !important;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;`
          : `color: #D8D1D1; `}
    }
    h2 {
      color: #b5a8a8;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;
    }
    h3 {
      text-align: right;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #b5a8a8;
      margin-right: 8px;
    }
    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #fefefe;
    }
  }
  img {
    width: 90px;
    margin-bottom: 32px;
    ${(props) =>
      props.current
        ? ` filter: drop-shadow(0px 0px 24px #825196);`
        : `filter: drop-shadow(0px 0px 24px #B0B0B0);`}
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const RankProgressSection = styled.div`
  background: #2c2726;
  border-radius: 12px;
  padding: 40px;
  margin: 20px;
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fefefe;
    margin-bottom: 20px;
    text-align: center;
  }
  h2 {
    color: #b5a8a8;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  @media (max-width: 800px) {
    margin: 40px;
  }
`;

export const RankRows = styled.div`
  width: 50%;
`;

export const RankRowsFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const Hr = styled.div`
  background: linear-gradient(160.43deg, #494c51 0%, #939393 50%, #494c51 100%);
  height: 1px;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '20px 0')};
`;

export const StatusBadge = styled.img`
  width: 32px !important;
  height: auto !important;
  position: absolute;
  right: -16px;
  top: calc(50% - 16px);
  z-index: 1;
  cursor: pointer;
`;

export const Line = styled.div`
  position: relative;
  height: 18px;
  width: 50%;
  background: ${(props) =>
    props.filled
      ? 'linear-gradient(180deg, #F58E35 1.87%, #F26C6B 50.93%, #EE288A 99.33%);'
      : ' #403C3B;'};

  /* ${(props) => (props.filled ? 'box-shadow: 0px 0px 24px #825196;' : '')}; */
`;

export const RankMobile = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 1px;
  background: ${(props) =>
    props.selected
      ? 'linear-gradient(160.43deg,#f58e35 1.87%,#f26c6b 50.93%,#ee288a 99.33%);'
      : '#2C2626'};
  border-radius: 8px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 66px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #fefefe;
    border-radius: 8px;
    padding: 0 16px;
    background: #2c2626;
  }
  img {
    margin-left: 16px;
    transform: ${(props) => (props.expanded ? 'rotate(90deg)' : '')};
    transition: all 500ms;
  }
`;

export const Collapsable = styled.div`
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.expanded ? `1000px` : '0')};
  overflow: hidden;
  transition: all 500ms;
  margin-bottom: ${(props) => (props.expanded ? '20px' : '0')};
  padding: 10px 0;
`;

export const CollapsableAbsolute = styled.div`
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  /* max-height: ${(props) => (props.expanded ? `1000px` : '0')}; */
  overflow: hidden;
  transition: all 300ms;
  margin-bottom: ${(props) => (props.expanded ? '20px' : '0')};
  position: absolute;
  top: 70px;
  width: 340px !important;
  /* right: -170px */
  /* ${(props) => (props.right ? `right: 0;` : ``)}
  ${(props) => (props.left ? `left: 0;` : ``)} */
  z-index: 100014;
`;

export const RankProgressComponent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(100% / 15);
  overflow: visible;
  > img {
    cursor: pointer;
    height: 68px;
    margin-bottom: 20px;
    ${(props) =>
      props.current
        ? ` filter: drop-shadow(0px 0px 15px #825196);`
        : `filter: drop-shadow(0px 0px 12px #B0B0B0);`}
  }
  &:hover ${CollapsableAbsolute} {
    visibility: visible !important;
    opacity: 1 !important;
    transition: all 500ms !important;
    margin-bottom: 20px !important;
  }
`;

export const MavieRank = styled.div`
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  ${(props) => props.isNext && '-webkit-filter: grayscale(1); opacity: .5'};
  width: 100%;
  display: flex;
  flex-direction: column;
  > div {
    width: 100%;
    padding: 32px;
  }
  > img {
    width: 100%;
  }
  border-radius: 15px;
  margin-bottom: 40px;
  position: relative;

  @media (max-width: 800px) {
    border-radius: 8px;
    margin-bottom: 20px;

    > div {
      padding: 20px;
    }
  }

  overflow: hidden;
  background: ${(props) => props.theme.colors.neutral50};
`;

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background: ${(props) => props.theme.colors.success500};
  border-radius: 50%;
`;

export const CheckboxRank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: ${(props) => props.color};
  border-radius: 50%;
`;

export const CheckboxButton = styled.div`
  cursor: pointer;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  > div {
    height: 47px;
    display: flex;
    align-items: center;
  }
  img {
    margin-right: 16px;
    width: 24px;
  }
`;

export const RankStatus = styled.div`
  position: absolute;
  right: 30px;
  bottom: 30px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.colors.neutral900};
  border-radius: 5px;
  @media (max-width: 800px) {
    padding: 5px 10px;
    bottom: 50%;
    right: 20px;
    transform: translate(0, 50%);
  }
`;

export const Circle = styled.div`
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-radius: 50%;
  background: ${({ color }) => color};
  flex: none;
`;

export const CountdownNumbers = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 5px;
  /* display: flex;
  align-items: center;

  > div {
    width: 56px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      color: ${(props) => props.theme.colors.primary900};
    }
    @media (max-width: 800px) {
      width: calc(25% - 30px);
    }
  }
  img {
    width: 100%;
  } */
`;

export const RankImage = styled.div`
  position: relative;
  display: flex;
  padding: 50px;
  background-image: ${({ image }) => `url(${image}) `};
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 1300/260;

  ${({ isActive }) =>
    !isActive &&
    `
   ::before {
    background: rgba(130, 130, 130, 0.56);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  `}

  @media (max-width: 800px) {
    padding: 0;
    align-items: center;
  }
`;
