const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const CREATE_WITHDRAW_AMBASSADOR = {
  REQUEST: getRequestConst('CREATE_WITHDRAW_AMBASSADOR'),
  SUCCESS: getSuccessConst('CREATE_WITHDRAW_AMBASSADOR'),
  FAIL: getFailConst('CREATE_WITHDRAW_AMBASSADOR')
};

export const CREATE_WITHDRAW_PARTNER = {
  REQUEST: getRequestConst('CREATE_WITHDRAW_PARTNER'),
  SUCCESS: getSuccessConst('CREATE_WITHDRAW_PARTNER'),
  FAIL: getFailConst('CREATE_WITHDRAW_PARTNER')
};

export const CREATE_WITHDRAW_EXPLORER = {
  REQUEST: getRequestConst('CREATE_WITHDRAW_EXPLORER'),
  SUCCESS: getSuccessConst('CREATE_WITHDRAW_EXPLORER'),
  FAIL: getFailConst('CREATE_WITHDRAW_EXPLORER')
};

export const ADD_TOP_UP = {
  REQUEST: getRequestConst('ADD_TOP_UP'),
  SUCCESS: getSuccessConst('ADD_TOP_UP'),
  FAIL: getFailConst('ADD_TOP_UP')
};

export const GET_USER_WALLETS = {
  REQUEST: getRequestConst('GET_USER_WALLETS'),
  SUCCESS: getSuccessConst('GET_USER_WALLETS'),
  FAIL: getFailConst('GET_USER_WALLETS')
};

export const CREATE_USER_WALLET = {
  REQUEST: getRequestConst('CREATE_USER_WALLET'),
  SUCCESS: getSuccessConst('CREATE_USER_WALLET'),
  FAIL: getFailConst('CREATE_USER_WALLET')
};

export const DELETE_USER_WALLET = {
  REQUEST: getRequestConst('DELETE_USER_WALLET'),
  SUCCESS: getSuccessConst('DELETE_USER_WALLET'),
  FAIL: getFailConst('DELETE_USER_WALLET')
};

export const REACTIVATE_USER_WALLET = {
  REQUEST: getRequestConst('REACTIVATE_USER_WALLET'),
  SUCCESS: getSuccessConst('REACTIVATE_USER_WALLET'),
  FAIL: getFailConst('REACTIVATE_USER_WALLET')
};

export const EDIT_USER_WALLET = {
  REQUEST: getRequestConst('EDIT_USER_WALLET'),
  SUCCESS: getSuccessConst('EDIT_USER_WALLET'),
  FAIL: getFailConst('EDIT_USER_WALLET')
};

export const WITHDRAW_EXCHANGE = {
  REQUEST: getRequestConst('WITHDRAW_EXCHANGE'),
  SUCCESS: getSuccessConst('WITHDRAW_EXCHANGE'),
  FAIL: getFailConst('WITHDRAW_EXCHANGE')
};

export const CREATE_WITHDRAW_EMAIL_VERIFICATION = {
  REQUEST: getRequestConst('CREATE_WITHDRAW_EMAIL_VERIFICATION'),
  SUCCESS: getSuccessConst('CREATE_WITHDRAW_EMAIL_VERIFICATION'),
  FAIL: getFailConst('CREATE_WITHDRAW_EMAIL_VERIFICATION')
};
