import { CLEAR_ALERT, CLEAR_MESSAGE } from '../../../constants/errorConstants';
import {
  CREATE_WITHDRAW_PARTNER,
  ADD_TOP_UP,
  GET_USER_WALLETS,
  CREATE_USER_WALLET,
  DELETE_USER_WALLET,
  WITHDRAW_EXCHANGE,
  CREATE_WITHDRAW_EXPLORER,
  EDIT_USER_WALLET,
  CREATE_WITHDRAW_EMAIL_VERIFICATION,
  REACTIVATE_USER_WALLET
} from './Withdraw.constants';

export const withdrawReducer = (
  state = {
    loading: false,
    userWallets: []
  },
  action
) => {
  switch (action.type) {
    case GET_USER_WALLETS.REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_USER_WALLETS.SUCCESS:
      return {
        ...state,
        loading: false,
        userWallets: action.payload
      };

    case GET_USER_WALLETS.FAIL:
      return { ...state, loading: false, error: action.payload };

    case CREATE_USER_WALLET.REQUEST:
      return {
        ...state,
        loading: true
      };

    case CREATE_USER_WALLET.SUCCESS:
      return {
        ...state,
        loading: false,
        userWallets: [action.payload, ...state.userWallets]
      };

    case CREATE_USER_WALLET.FAIL:
      return { ...state, loading: false, error: action.payload };

    case DELETE_USER_WALLET.REQUEST:
      return {
        ...state,
        loading: true
      };

    case DELETE_USER_WALLET.SUCCESS:
      return {
        ...state,
        loading: false,
        userWallets: state.userWallets.map((wallet) => {
          if (wallet.id === action.payload) {
            return { ...wallet, status: 2 };
          } else {
            return wallet;
          }
        })
      };

    case DELETE_USER_WALLET.FAIL:
      return { ...state, loading: false, error: action.payload };

    case EDIT_USER_WALLET.REQUEST:
      return {
        ...state,
        loading: true
      };
    case EDIT_USER_WALLET.SUCCESS:
      return {
        ...state,
        loading: false,
        userWallets: state.userWallets.map((wallet) => {
          if (wallet.id === action.payload.id) {
            return action.payload;
          } else {
            return wallet;
          }
        })
      };
    case EDIT_USER_WALLET.FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case CREATE_WITHDRAW_PARTNER.REQUEST:
      return {
        ...state,
        loadingWithdraw: true
      };

    case CREATE_WITHDRAW_PARTNER.SUCCESS:
      return {
        ...state,
        loadingWithdraw: false
      };

    case CREATE_WITHDRAW_PARTNER.FAIL:
      return { ...state, loadingWithdraw: false, error: action.payload };

    case CREATE_WITHDRAW_EXPLORER.REQUEST:
      return {
        ...state,
        loadingWithdraw: true
      };

    case CREATE_WITHDRAW_EXPLORER.SUCCESS:
      return {
        ...state,
        loadingWithdraw: false
      };

    case CREATE_WITHDRAW_EXPLORER.FAIL:
      return { ...state, loadingWithdraw: false, error: action.payload };

    case CREATE_WITHDRAW_EMAIL_VERIFICATION.REQUEST:
      return {
        ...state,
        loadingWithdraw: true
      };

    case CREATE_WITHDRAW_EMAIL_VERIFICATION.SUCCESS:
      return {
        ...state,
        loadingWithdraw: false
      };

    case CREATE_WITHDRAW_EMAIL_VERIFICATION.FAIL:
      return { ...state, loadingWithdraw: false, error: action.payload };

    case ADD_TOP_UP.REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_TOP_UP.SUCCESS:
      return {
        ...state,
        loading: false
      };

    case ADD_TOP_UP.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case WITHDRAW_EXCHANGE.REQUEST:
      return {
        ...state,
        loading: true
      };

    case WITHDRAW_EXCHANGE.SUCCESS:
      return {
        ...state,
        loading: false
      };

    case WITHDRAW_EXCHANGE.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case REACTIVATE_USER_WALLET.REQUEST:
      return {
        ...state,
        loading: true
      };
    case REACTIVATE_USER_WALLET.SUCCESS:
      return {
        ...state,
        loading: false,
        userWallets: state.userWallets.map((wallet) => {
          if (wallet.id === action.payload) {
            return { ...wallet, status: 1 };
          } else {
            return wallet;
          }
        })
      };
    case REACTIVATE_USER_WALLET.FAIL:
      return {
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload
      };

    default:
      return state;
  }
};
