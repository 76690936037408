import React from 'react';
import { SelectStakingHubNFT } from '../../../components/common/SelectStakingHubNFT/SelectStakingHubNFT';

export const Step6 = ({ selectedHub, setSelectedHub, setStep }) => {
  return (
    <SelectStakingHubNFT
      selectedHub={selectedHub}
      setSelectedHub={setSelectedHub}
      setStep={setStep}
    />
  );
};
