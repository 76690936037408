import React, { useEffect } from 'react';
import {
  CloseButton,
  SideBarModal,
  SideBarModalOverlay,
  Title
} from './SidebarStyled';

import { Icon, IconX } from '../../../styles/Icons';
import { color } from '../../../styles/GlobalStyles';

export const SidebarModal = ({
  children,
  isOpen,
  setOpen,
  title,
  hasCloseButton = true,
  overlayClose = false,
  sidebarWidth,
  relative
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isOpen]);

  return (
    <>
      <SideBarModalOverlay
        isOpen={isOpen}
        onClick={() => {
          if (!overlayClose) {
            setOpen(false);
          }
        }}></SideBarModalOverlay>
      <SideBarModal
        isOpen={isOpen}
        sidebarWidth={sidebarWidth}
        relative={relative}>
        {title && <Title>{title}</Title>}
        {hasCloseButton && (
          <CloseButton onClick={() => setOpen(false)}>
            <Icon color={color.appLayout.sidebarModalCloseIconColor} pointer>
              <IconX />
            </Icon>
          </CloseButton>
        )}
        {children}
      </SideBarModal>
    </>
  );
};
