import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Button } from '../../styles/stylesStyled';

const View = styled(motion.div)`
  margin: 20px 0;
  min-height: 640px;
  padding-bottom: 70px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    max-width: 1500px;
    padding: 0 100px;
  }

  @media only screen and (max-width: 1500px) and (min-width: 800px) {
    zoom: 80%;
  }
`;

const ButtonBackOffice = styled(Button)`
  background: ${(props) =>
    props.negative
      ? 'none'
      : `linear-gradient(132.33deg, #d24074 -0.67%, #1268c3 102.54%)`};

  border: ${(props) => (props.negative ? `2px solid #B5A8A8` : 'none')};
  margin: ${(props) => (props.m ? props.m : '0')};
  color: ${(props) => (props.negative ? '#F2F0F0;' : '#fff')};

  /* background: linear-gradient(132.33deg, #d24074 -0.67%, #1268c3 102.54%); */
  /* border: 2px solid rgba(254, 254, 254, 0.3); */
  width: ${(props) => (props.w ? props.w : '200px')};
  height: ${(props) => (props.h ? props.h : '')};

  font-weight: bold;
  font-size: 14px;

  ${(props) => props.p && `padding: ${props.p}`};
`;

const ButtonBackOfficeNegative = styled.div`
  position: relative;
  /* background: ${(props) =>
    props.negative
      ? 'none'
      : `linear-gradient(132.33deg, #d24074 -0.67%, #1268c3 102.54%)`}; */
  cursor: pointer;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #1268c3 102.54%);
  border-radius: 8px;
  padding: 2px;
  color: #fff;
  div {
    background: #2c2726;
    display: fixed;
    align-items: center;
    justify-content: center;
    height: 51px;
    border-radius: 8px;
    width: 100%;
  }

  /* background: linear-gradient(132.33deg, #d24074 -0.67%, #1268c3 102.54%); */
  /* border: 2px solid rgba(254, 254, 254, 0.3); */
  width: 100%;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
`;

const RegisterProject = styled.div`
  width: 100%;
  height: 114px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  padding: 0 40px;

  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 56px;
  @media (max-width: 800px) {
    margin-bottom: 30px;
    padding: 0 16px;
    font-size: 24px;
    line-height: 30px;
  }
`;

const Updates = styled.div`
  position: relative;
  background: #272322;
  border-radius: 12px;
  padding: 32px 24px;
  margin-bottom: 56px;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #fefefe;
    margin-bottom: 18px;
  }
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #fefefe;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #f2f0f0;
    opacity: 0.7;
  }
  span {
    font-size: 14px;
    line-height: 17px;
    color: #f2f0f0;
    opacity: 0.7;
  }
  @media (max-width: 800px) {
    margin-bottom: 30px;
    > div {
      flex-direction: column;
      align-items: start;
    }
    h2 {
      margin-bottom: 10px;
    }
  }
`;

export const DashboardNotificationComponent = styled.div`
  cursor: pointer;
  position: relative;
  background: ${(props) => props.theme.dashboard.background};
  border-radius: 12px;
  padding: 20px 32px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 16px;
    margin-bottom: 30px;
  }
`;

export const NotificationPill = styled.div`
  background: ${(props) => props.theme.colors.neutral100};
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 12px;
  color: ${(props) => props.theme.colors.neutral500};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 140%;
  display: inline-flex;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background: ${(props) => props.theme.colors.primary900};
  border-radius: 50%;
  margin: 0 10px;
`;

const BootCamp = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 56px;
  h1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #fefefe;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #f2f0f0;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  ul {
    margin-bottom: 40px;
    li {
      font-size: 18px;
      line-height: 26px;
      color: #f2f0f0;
      opacity: 0.7;
    }
  }

  img {
    max-width: 752px;
    width: 60%;
    object-fit: contain;
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    margin-bottom: 30px;
    img {
      margin-bottom: 10px;
      max-width: 100%;
      width: 100%;
    }
    h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }
    ul {
      margin-bottom: 16px;
      li {
        font-size: 14px;
        line-height: 20px;
        color: #f2f0f0;
        opacity: 0.7;
      }
    }
  }
`;

const IconTitleStyled = styled.h1`
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: ${(props) => props.theme.dashboard.fontColor};
  margin-bottom: 24px;

  display: flex;
  align-items: center;

  img {
    margin-right: 12px;
    width: 40px;
  }

  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    img {
      width: 32px;
    }
  }
`;

export const CashBackStyled = styled.div`
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  background: ${(props) => props.theme.dashboard.background};
  border-radius: 12px;
  padding: 40px 70px;
  margin-bottom: 24px;

  @media (max-width: 800px) {
    padding: 32px 16px;
    margin-bottom: 12px;
  }
`;

export const CashBackFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const RedCircle = styled.div`
  height: 280px;
  aspect-ratio: 1 / 1;
  border: 3px solid ${(props) => props.theme.colors.error500};
  padding: 3px;
  border-radius: 50%;
  > div {
    width: 100%;
    height: 100%;
    border: 30px solid ${(props) => props.theme.colors.error500};
    border-radius: 50%;
    aspect-ratio: 1 / 1;
  }
`;

const YourEarnings = styled.div`
  background: ${(props) => props.theme.dashboard.background};
  border-radius: 12px;
  color: #fefefe;
  padding: 40px 70px;
  margin-bottom: 24px;
  box-shadow: ${(props) => props.theme.shadows.shadowXS};

  @media (max-width: 800px) {
    padding: 12px;
    margin-bottom: 12px;
  }
`;

const EarningsSmall = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin-bottom: 24px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > div {
    box-shadow: ${(props) => props.theme.shadows.shadowXS};
    cursor: pointer;
    background: ${(props) => props.theme.dashboard.background};
    border-radius: 12px;
    padding: 20px;
  }
  @media (max-width: 800px) {
    margin-bottom: 20px;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    > div {
      padding: 12px;
    }
  }
`;

const Statistical = styled(EarningsSmall)`
  > div {
    box-shadow: ${(props) => props.theme.shadows.shadowXS};

    padding: 32px;

    @media (max-width: 800px) {
      padding: 12px;
    }

    img {
      margin-right: 16px;
      width: 32px;
    }
  }
`;

// const StatisticalMobile = styled(EarningsSmall)``;

const Slide = styled.div`
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(${(props) => props.backImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  h1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    text-shadow: 0px 0px 24px #f26c6b;
    margin-bottom: 20px;
    @media (max-width: 800px) {
      font-size: 34px;
      line-height: 40px;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    opacity: 0.7;
    margin-bottom: 20px;
  }
`;

const WaitingRoomTh = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  div {
    width: 25%;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: ${(props) => props.theme.colors.neutral500};
  }
`;

const WaitingRoomTr = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;

  height: 50px;

  ${(props) =>
    props.isSelected ? `background: #1C1716;border-radius: 12px;` : ``}

  div {
    /* width: 25%; */
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${(props) => props.theme.colors.neutral700};
    span {
      font-weight: 500;
      color: #f48150;
    }
  }
`;

const WaitingRoomTable = styled.div`
  > *:nth-child(even) {
    background: ${(props) => props.theme.colors.neutral50};
    border-radius: 8px;
  }
`;

export const WaitingRoomMobile = styled.div`
  background: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #f2f0f0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px 0;
  span {
    font-size: 12px;
    line-height: 12px;
  }
`;

const Hr = styled.div`
  background: linear-gradient(160.43deg, #494c51 0%, #939393 50%, #494c51 100%);
  height: 1px;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '20px 0')};
`;

const News = styled.div`
  h1 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
    color: #f2f0f0;
    opacity: 0.7;
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: #fefefe;
  }
  h3 {
    color: #fbfbfb;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px;
  }
`;

const NewsImgWrap = styled.div`
  position: relative;
  border-radius: 8px;
  margin-bottom: 12px;
  overflow: hidden;
`;

const NewsImg = styled.div`
  width: 100%;
  height: 216px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: all 0.3s ease;
  @media (max-width: 800px) {
    height: 160px;
  }
`;

const Ranking = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Rank = styled.div`
  width: 33%;

  padding: 4px;
  border-radius: 12px;
  background: ${(props) =>
    props.current
      ? 'linear-gradient(137.86deg,#d24074 0%,#1268c3 100%);'
      : '#7a7676;'};
  > div {
    padding: 40px;
    @media (max-width: 800px) {
      padding: 20px;
    }
    background: linear-gradient(
      137.86deg,
      #403c3b 10.11%,
      #2c2523 39.13%,
      #0c0a0a 99.98%
    );
    box-shadow: ${(props) => props.theme.shadows.shadowXS};
    border-radius: 8px;
    h1 {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 40px;
      ${(props) =>
        props.current
          ? `background: -webkit-linear-gradient(
    160.43deg,
    #ee288a 1.87%,
    #f26c6b 50.93%,
    #f58e35 99.33%
  );
  color: #ff0084 !important;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;`
          : `color: #D8D1D1; `}
    }
    h2 {
      color: #b5a8a8;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;
    }
    h3 {
      text-align: right;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #b5a8a8;
      margin-right: 8px;
    }
    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #fefefe;
    }
  }
  img {
    width: 90px;
    margin-bottom: 32px;
    ${(props) =>
      props.current
        ? ` filter: drop-shadow(0px 0px 24px #825196);`
        : `filter: drop-shadow(0px 0px 24px #B0B0B0);`}
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const RankProgressSection = styled.div`
  background: #2c2726;
  border-radius: 12px;
  padding: 40px;
  margin: 20px;
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fefefe;
    margin-bottom: 20px;
    text-align: center;
  }
  h2 {
    color: #b5a8a8;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  @media (max-width: 800px) {
    margin: 40px;
  }
`;

const RankRows = styled.div`
  width: 50%;
`;

const RankRowsFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const BackOfficeNumbersWrap = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: calc(50% - 20px);

    > div {
      box-shadow: ${(props) => props.theme.shadows.shadowXS};
      background: ${(props) => props.theme.dashboard.background};
      border-radius: 12px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 800px) {
        padding: 12px;
      }
    }
    @media (max-width: 800px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const BackOfficeNumbers = styled.div`
  width: calc(100% / 8 - 6px);
  background: ${(props) =>
    props.orange
      ? (props) => props.theme.colors.secondary100
      : (props) => props.theme.colors.primary900};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: ${(props) =>
    props.orange
      ? (props) => props.theme.colors.neutral50
      : (props) => props.theme.colors.neutral50};
  height: 56px;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
    height: 36px;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.error500};
  margin: 10px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral900};
  margin-bottom: 8px;
  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral500};
  margin-bottom: 40px;
`;

export const Image = styled.img`
  width: 160px;
  margin-bottom: 40px;
`;

export const Username = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 12px;
  background: ${(props) => props.theme.dashboard.usernameBackground};
  /* border: ${({ theme }) => `1px solid ${theme.colors.primary900}`}; */
  border-radius: 22px;
  opacity: 0.95;
  width: 100%;
`;

export const CountdownNumbers = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  /* flex-direction: column; */
  justify-content: center;
  > div {
    width: 56px;
    margin: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      color: ${(props) => props.theme.colors.error500};
    }
    @media (max-width: 800px) {
      width: calc(25% - 30px);
    }
  }
  img {
    width: 100%;
  }
`;

export const ContentBlockStyled = styled.div`
  box-shadow: ${(props) => props.theme.shadows.shadowXS};
  background: ${(props) => props.theme.dashboard.background};
  border-radius: 12px;
  padding: 40px 70px;
  margin-bottom: 24px;

  @media (max-width: 800px) {
    padding: 32px 16px;
    margin-bottom: 12px;
  }
`;

export const UpdateTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.neutral500};
  text-transform: uppercase;
  line-height: 16.8px;
  margin-bottom: 19px;
`;

export const IconPosition = styled.div`
  display: flex;
  align-self: start;
`;

export {
  View,
  RegisterProject,
  ButtonBackOffice,
  Updates,
  Dot,
  BootCamp,
  IconTitleStyled,
  YourEarnings,
  EarningsSmall,
  Statistical,
  Slide,
  WaitingRoomTable,
  WaitingRoomTr,
  WaitingRoomTh,
  Hr,
  News,
  NewsImgWrap,
  NewsImg,
  Ranking,
  Rank,
  RankRows,
  RankRowsFlex,
  BackOfficeNumbersWrap,
  BackOfficeNumbers,
  ButtonBackOfficeNegative,
  ErrorMessage,
  RankProgressSection
};
