import { CLEAR_ALERT, CLEAR_MESSAGE } from '../../constants/errorConstants';
import { RANKING, CLAIM_PACKAGE_RANK } from './Ranking.constants';

export const rankingReducer = (
  state = {
    loading: false,
    ranking: []
  },
  action
) => {
  switch (action.type) {
    case RANKING.REQUEST:
      return {
        ...state,
        loading: true
      };

    case RANKING.SUCCESS:
      return {
        ...state,
        loading: false,
        ranking: action.payload
      };

    case RANKING.FAIL:
      return { ...state, loading: false };

    case CLAIM_PACKAGE_RANK.SUCCESS:
      return {
        ...state,
        ranking: state.ranking.map((rank) => {
          if (rank.packageRankId === action.payload) {
            return {
              ...rank,
              canClaimReward: false
            };
          } else {
            return rank;
          }
        })
      };

    // DEFAULT CASES

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};
