import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import usersIcon from '../../../assets/images/backoffice/3DIconUsers.png';
import { SidebarModal } from '../../../components/common/Sidebar/Sidebar';
import { H4 } from '../../../styles/FontStyles';
import { Button } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { closeAddNewMemberModal } from '../myTeamBackOffice.actions';
import { FlexJustifyCenter } from '../MyTeamBackofficeStyled';

const AddNewMemberModal = () => {
  const { t } = useTranslation('myTeam');
  const dispatch = useDispatch();
  const { addNewMemberModal } = useSelector((store) => store.myTeamBackoffice);

  const onClose = () => {
    dispatch(closeAddNewMemberModal());
  };

  const onRegisterNewUserClick = () => {
    if (addNewMemberModal?.position !== null) {
      window.open(
        `/email-address/${addNewMemberModal.parentId}/${addNewMemberModal?.position}`,
        '_blank'
      );
    } else {
      window.open(`/email-address/${addNewMemberModal.parentId}`, '_blank');
    }

    onClose();
  };

  return (
    <SidebarModal
      isOpen={addNewMemberModal?.isOpen}
      setOpen={onClose}
      sidebarWidth={'400px'}
      title={'Add user'}>
      <>
        <H4 m={'12vh 0 12px 0'} color={color.colors.neutral900} center>
          Member registration
        </H4>

        <FlexJustifyCenter>
          <img
            src={usersIcon}
            alt='new users'
            style={{ width: 160, margin: '40px 0' }}
          />
        </FlexJustifyCenter>

        <Button m='20px 0' onClick={onRegisterNewUserClick}>
          {t('add_new_member_modal.btn_txt')}
        </Button>
      </>
    </SidebarModal>
  );
};

export default AddNewMemberModal;
