export const BACKOFFICE_LAYOUT = `/backoffice`;

export const DASHBOARD = `${BACKOFFICE_LAYOUT}/dashboard`;

export const DUBAI_PROMOTION = `${BACKOFFICE_LAYOUT}/dubai-promotion`;

export const ORDERS = `${BACKOFFICE_LAYOUT}/orders`;

export const MY_HUBS = `${BACKOFFICE_LAYOUT}/my-hubs`;

export const MY_TEAM = `${BACKOFFICE_LAYOUT}/my-team`;

export const EARNINGS = `${BACKOFFICE_LAYOUT}/earnings`;

export const RANKING = `${BACKOFFICE_LAYOUT}/ranking`;

export const RESOURCES = `${BACKOFFICE_LAYOUT}/resources`;

export const SETTINGS_LAYOUT = `${BACKOFFICE_LAYOUT}/settings`;

export const SETTINGS_ACCOUNT = `${SETTINGS_LAYOUT}/account`;

export const SETTINGS_SECURITY = `${SETTINGS_LAYOUT}/security`;

export const SETTINGS_PAYMENTS = `${SETTINGS_LAYOUT}/payments`;

export const SETTINGS_VIRTUAL_OFFICE = `${SETTINGS_LAYOUT}/virtual-office`;

export const SETTINGS_NOTIFICATIONS = `${SETTINGS_LAYOUT}/notifications`;

export const SETTINGS_TEAM_SETTINGS = `${SETTINGS_LAYOUT}/team-settings`;

export const PACKAGES = `${BACKOFFICE_LAYOUT}/packages`;

export const HELPDESK = `${BACKOFFICE_LAYOUT}/helpdesk`;

export const HELPDESK_SUPPORT = `${BACKOFFICE_LAYOUT}/helpdesk-support`;

export const HELPDESK_SUBCATEGORY = `${BACKOFFICE_LAYOUT}/subcategory`;

export const HELPDESK_QUESTION = `${BACKOFFICE_LAYOUT}/question`;

export const CHAT_LIST = `${BACKOFFICE_LAYOUT}/chat-list`;

export const ACCOUNTING = `${BACKOFFICE_LAYOUT}/accounting`;

export const CASHBACK_BONUS = `${BACKOFFICE_LAYOUT}/cashback-bonus`;

export const POOL_BONUS = `${BACKOFFICE_LAYOUT}/pool-bonus`;

export const TOP_PERFORMER_BONUS = `${BACKOFFICE_LAYOUT}/top-performer-bonus`;

export const HODL_BONUS = `${BACKOFFICE_LAYOUT}/hodl-bonus`;

export const TERMS_OF_USE = `${BACKOFFICE_LAYOUT}/terms-of-use`;

export const PRIVACY_POLICY = `${BACKOFFICE_LAYOUT}/privacy-policy`;

export const COOKIE_POLICY = `${BACKOFFICE_LAYOUT}/cookie-policy`;

export const GAMING = `${BACKOFFICE_LAYOUT}/gaming`;

export const MARKETPLACE = `${BACKOFFICE_LAYOUT}/marketplace`;

export const FIRST_LOGIN = `/first-login`;
