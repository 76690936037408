import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flexed, SecurityCodeWrapper } from '../../../styles/GlobalComponents';
import { AuthButton } from '../../../styles/stylesStyled';

import Spinner from '../../../components/common/Spinner';

import { P2, P3 } from '../../../styles/FontStyles';

import { color } from '../../../styles/GlobalStyles';
import ReactCodeInput from 'react-code-input';
import { createWithdrawPartner } from './Withdraw.actions';

import {
  SuccessToast,
  ErrorToast
} from '../../../components/common/Toasts/Toasts';

const API_URL = process.env.REACT_APP_API_URL;

export const TwoFASetup = ({
  setStep,
  amount,
  fee,
  userWallets,
  selected,
  networkType,
  setAmount
}) => {
  const [code, setCode] = useState('');
  const [code2Fa, setCode2Fa] = useState('');
  const [loadingCode, setLoadingCode] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const dispatch = useDispatch();

  const { loadingWithdraw } = useSelector((state) => state.withdraw);
  const { verificationStatus } = useSelector((state) => state.settings);
  const { user, token } = useSelector((state) => state.userInfo);

  const sendCode = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.accessToken}`
      }
    };

    try {
      setLoadingCode(true);
      await axios.post(
        `${API_URL}/user/balance/withdraw/email/code`,
        {
          amount: parseInt(amount) - parseInt(fee),
          userWalletId: userWallets[selected].id,
          networkType
        },
        config
      );

      SuccessToast('Code sent successfully');
      setLoadingCode(false);
      setIsCodeSent(true);
    } catch (error) {
      setLoadingCode(false);
      ErrorToast(error.response.data.error);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      createWithdrawPartner(
        parseInt(amount),
        userWallets[selected].id,
        setStep,
        code,
        code2Fa,
        networkType,
        setAmount
      )
    );
  };

  return (
    <div style={{ marginTop: '20vh' }}>
      {loadingWithdraw || loadingCode ? (
        <Spinner dark />
      ) : (
        <form onSubmit={onHandleSubmit}>
          <Flexed between>
            <P2 bold color={color.colors.neutral700}>
              Email verification code
            </P2>
            <P2
              bold
              style={{ cursor: 'pointer' }}
              color={color.colors.primary900}
              onClick={() => sendCode()}>
              Send Code
            </P2>
          </Flexed>
          <SecurityCodeWrapper>
            <ReactCodeInput
              fields={7}
              value={code}
              onChange={setCode}
              type={'number'}
            />
          </SecurityCodeWrapper>
          <P3 color={color.colors.neutral500} mb='40'>
            {isCodeSent && `Enter the 7 digit code sent to ${user?.email}`}
          </P3>

          {verificationStatus && (
            <div>
              <Flexed between>
                <P2 bold color={color.colors.neutral700}>
                  2FA verification code
                </P2>
              </Flexed>
              <SecurityCodeWrapper is2Fa>
                <ReactCodeInput
                  fields={6}
                  value={code2Fa}
                  onChange={setCode2Fa}
                  type={'number'}
                />
              </SecurityCodeWrapper>
              <P3 color={color.colors.neutral500} mb='10'>
                Enter the 6 digit code from your 2FA authenticator
              </P3>
            </div>
          )}
          <AuthButton
            opacity={code !== ''}
            disabled={code === '' ? true : false}>
            Confirm
          </AuthButton>
        </form>
      )}
    </div>
  );
};
export const showWallet = (address, number) => {
  const text1 = address?.slice(0, 4);
  const text2 = address?.slice(number);

  return `${text1}...${text2}`;
};
