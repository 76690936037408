import React, { useState } from 'react';
import { View } from './WelcomeStyled';
import WelcomeStart from './WelcomeStart';

import { CareerComparation } from './CareerComparation';
import { SidebarModal } from '../../components/common/Sidebar/Sidebar';

const Welcome = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [rankPartner, setRankPartner] = useState('');
  const [rankAmbassador, setRankAmbassador] = useState('');

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20
      }}>
      <WelcomeStart
        setRankPartner={setRankPartner}
        rankPartner={rankPartner}
        setRankAmbassador={setRankAmbassador}
        rankAmbassador={rankAmbassador}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <SidebarModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        title='Career Comparison'
        sidebarWidth={'976px'}>
        <CareerComparation />
      </SidebarModal>
    </View>
  );
};

export default Welcome;
