const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const GET_GLOBAL_POOL = {
  REQUEST: getRequestConst('GET_GLOBAL_POOL'),
  SUCCESS: getSuccessConst('GET_GLOBAL_POOL'),
  FAIL: getFailConst('GET_GLOBAL_POOL')
};

export const CLAIM_GLOBAL_POOL = {
  REQUEST: getRequestConst('CLAIM_GLOBAL_POOL'),
  SUCCESS: getSuccessConst('CLAIM_GLOBAL_POOL'),
  FAIL: getFailConst('CLAIM_GLOBAL_POOL')
};
