import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import PaymentMethod from './PaymentMethod';

import { Success } from './Success';
import { ChooseBankAccount } from './ChooseBankAccount';
import { AddBankAccount } from './AddBankAccount';
import { PaymentType } from './PaymentType';
import { NoKyc } from '../Dashboard/NoKyc';

export const ConfirmPackage = ({
  selectedPackage,
  setSelectedPackage,
  currentlyPackage,
  step,
  setStep,
  selectedWallet,
  setSelectedWallet,
  selectedWalletIndex,
  setSelectedWalletIndex,
  selectedNetwork,
  setSelectedNetwork
}) => {
  const [selected, setSelected] = useState(0);
  const [setIsOpen2] = useState(false);

  const { userWallets } = useSelector((state) => state.withdraw);

  const renderSteps = (step) => {
    switch (step) {
      case 1:
        return (
          <PaymentMethod
            setSelectedPackage={setSelectedPackage}
            currentlyPackage={currentlyPackage}
            selectedPackage={selectedPackage}
            setStep={setStep}
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
            userWallets={userWallets}
            selectedWalletIndex={selectedWalletIndex}
            setSelectedWalletIndex={setSelectedWalletIndex}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
          />
        );

      case 2:
        return <AddBankAccount setStep={setStep} setIsOpen2={setIsOpen2} />;

      case 3:
        return (
          <ChooseBankAccount
            setStep={setStep}
            selected={selected}
            setSelected={setSelected}
            userWallets={userWallets}
            setSelectedWallet={setSelectedWallet}
          />
        );

      case 4:
        return <Success selectedPackage={selectedPackage} />;

      case 5:
        return (
          <PaymentType selectedPackage={selectedPackage} setStep={setStep} />
        );

      case 6:
        return <NoKyc />;

      default:
        return <div></div>;
    }
  };
  return <div style={{ marginRight: 10 }}>{renderSteps(step)}</div>;
};

export default ConfirmPackage;
