/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { SidebarModal } from '../../components/common/Sidebar/Sidebar';
import { CaptionText, H4, P1, P3 } from '../../styles/FontStyles';

import { Button, Flexed } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { changeAutoStake, getStakingWithdrawalInfo } from './Portfolio.actions';
import {
  BoxBig,
  BoxGray,
  HubImage,
  PortfolioGrid,
  Td,
  Tr
} from './Portfolio.styled';

import moment from 'moment';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import packageIconBonus from '../../assets/icons/backOffice/Packages/packageIconBonus.png';
import packageIconLegacy from '../../assets/icons/backOffice/Packages/packageIconLegacy.png'; //packageIconLegacy
import cosmicWhale from '../../assets/images/backoffice/hubImages/cosmicWhale.jpg';
import galaxyExplorer from '../../assets/images/backoffice/hubImages/galaxyExplorer.jpg';
import moonWalker from '../../assets/images/backoffice/hubImages/moonWalker.png';
import solarRanger from '../../assets/images/backoffice/hubImages/solarRanger.jpg';
import spaceSurfer from '../../assets/images/backoffice/hubImages/spaceSurfer.jpg';
import starCatcher from '../../assets/images/backoffice/hubImages/starCatcher.jpg';
import sunSeeker from '../../assets/images/backoffice/hubImages/sunSeeker.jpg';
import {
  calculateNextAmount,
  formatUSD,
  formatUSDNoDecimals
} from '../../helpers/helperFunctions';
import {
  Icon,
  IconQuestionMark,
  IconTimer,
  IconCalculator
} from '../../styles/Icons';
import { Flex, Responsive } from '../../styles/stylesStyled';
import { AutoStakeTransactions } from './Transactions/AutoStakeTransactions';
import { BonusHubTransactions } from './Transactions/BonusHubTransactions';

import axios from 'axios';
import Spinner from '../../components/common/Spinner';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { PortfolioItemDisabled } from './PortfolioItemDisabled';

export const PortfolioItem = ({
  setClaim,
  setClaimStep,
  item,
  setAutostake,
  setPackageItem,
  claimCalculator,
  setClaimCalculator,
  setToggle,
  toggle
}) => {
  const NFT_EXPRESS_API_LINK = process.env.REACT_APP_NFT_EXPRESS_API_LINK;

  const [hubIsOpen, setHubIsOpen] = useState(false);
  const [autoStakeIsOpen, setAutoStakeIsOpen] = useState(false);
  const [transactions, setTransactions] = useState();
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.userInfo);

  const { loadingAutoStake } = useSelector((state) => state.portfolio);

  const dispatch = useDispatch();

  const { t } = useTranslation('my_hubs');

  const fetchData = async () => {
    setLoading(true);

    try {
      const result = await axios(
        `${NFT_EXPRESS_API_LINK}/user_info?id=${user.id}`
      );
      setTransactions(result.data);
    } catch (e) {
      setLoading(false);
    }
  };

  const showImages = (item) => {
    switch (item?.price) {
      case 100:
        return solarRanger;
      case 300:
        return starCatcher;
      case 500:
        return spaceSurfer;
      case 1500:
        return galaxyExplorer;
      case 5000:
        return sunSeeker;
      case 12500:
        return moonWalker;
      case 30000:
        return cosmicWhale;
      default:
        return solarRanger;
    }
  };

  const packageType = (item) => {
    switch (item?.type) {
      case 'Bonus':
        return 'Bonus hub';
      case 'Legacy':
        return 'Legacy hub';
      default:
        break;
    }
  };

  const packageTypeForIcons = (item) => {
    switch (item?.type) {
      case 'Bonus':
        return packageIconBonus;
      case 'Legacy':
        return packageIconLegacy;
      default:
        break;
    }
  };

  const timestamp = item && moment.unix(item.nextUnlockDate);

  const isSameLikeNextCountdownDate = (item) => {
    const countdownDate = moment(new Date('2022-09-30'));
    const countdownDateFormated = countdownDate.format('YYYY-MM-D');

    const dateOfPurchaseTimestamp = moment
      .unix(item.dateOfPurchase)
      .format('YYYY-MM-D');

    const isBefore = moment(dateOfPurchaseTimestamp).isBefore(
      countdownDateFormated
    );

    const timestamp1 = new Date().getTime();
    const timestamp2 = new Date('2024-10-17T17:00:00').getTime();

    const difference = calculateDateDifference(timestamp1, timestamp2);

    const formattedDate = formatDateDifference(difference);

    if (isBefore) {
      return formattedDate;
    } else {
      return timestamp;
    }
  };

  const calculateDateDifference = (date1, date2) => {
    const diffInMilliseconds = Math.abs(date1 - date2);

    const seconds = Math.floor(diffInMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const timeDiff = {
      days: days,
      hours: hours % 24,
      minutes: minutes % 60
    };

    return timeDiff;
  };

  const formatDateDifference = (difference) => {
    const date = new Date();
    date.setDate(date.getDate() + difference.days);
    date.setHours(date.getHours() + difference.hours);
    date.setMinutes(date.getMinutes() + difference.minutes);

    const dateString = date.toISOString();
    return dateString;
  };

  const renderer = ({ days, hours, minutes }) => {
    if (days === 0 && hours === 0 && minutes === 0) {
      return (
        <>
          {days} days {hours}h {minutes}min*
        </>
      );
    } else {
      return (
        <>
          {days} days {hours}h {minutes}min
        </>
      );
    }
  };

  const showDisabledStakingHub = (item) => {
    if (item?.claimDate) {
      const dateNow = moment(new Date());
      const fromNow = moment.duration(dateNow.diff(item?.claimDate));
      const days = fromNow.days();
      const hours = fromNow.hours();

      if (days === 0 && hours < 24) {
        return <PortfolioItemDisabled />;
      }
    }
  };

  return (
    <>
      {item?.type === 'Normal' ? (
        <BoxBig relative>
          <HubImage image={showImages(item)}>
            <H4 color={color.colors.neutral0}>{item.name}</H4>
          </HubImage>
          <div>
            <div
              style={{
                position: 'absolute',
                left: '50%',
                right: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
              <Spinner />
            </div>
            <Tr style={{ cursor: 'pointer' }}>
              <Td w='20%' wSM='50%'>
                <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                  {t('price')}
                </CaptionText>
                <P1 bold flex alignEnd gap='5px'>
                  {formatUSDNoDecimals(item.price)} <P3 mb='1'>USDT</P3>
                </P1>
              </Td>

              <Td w='20%' wSM='50%'>
                <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                  {t('date_of_purchase')}
                </CaptionText>
                <P1 bold>
                  {moment.unix(item?.dateOfPurchase).format('DD MMM YYYY')}
                </P1>
              </Td>
              <Td w='20%' wSM='50%'>
                <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                  {t('base_stake')}
                </CaptionText>
                <P1 bold flex alignEnd gap='5px'>
                  {formatUSD(item.baseStake)} <P3 mb='1'>wULX</P3>
                </P1>
              </Td>
              <Td w='20%' wSM='50%'>
                <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                  {t('total_stake')}
                </CaptionText>
                <P1 bold flex alignEnd gap='5px'>
                  {formatUSD(item.totalStake)} <P3 mb='1'>wULX</P3>
                </P1>
              </Td>
              <Td w='20%' wSM='50%'>
                <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                  {t('my_earnings')}
                </CaptionText>
                <P1
                  color={color.colors.secondary100}
                  bold
                  flex
                  alignEnd
                  gap='5px'>
                  {formatUSD(item.myEarnings)} <P3 mb='1'>wULX</P3>
                </P1>
              </Td>
            </Tr>
            <div>
              <>
                <Responsive style={{ margin: '24px 0' }} between center>
                  <Flexed mbSM='20'>
                    <P3 caps bold color={color.colors.secondary100} mr='60'>
                      {t('staking_hub_details')}
                    </P3>
                    <P3 flex color={color.colors.secondary100} mr='60'>
                      {t('order_id')}:{' '}
                      <P3 bold ml='2' color={color.colors.secondary100}>
                        {item?.id}
                      </P3>
                    </P3>
                    <P3 flex color={color.colors.secondary100}>
                      {t('nft_id')}:
                      <P3 bold ml='2' color={color.colors.secondary100}>
                        {item?.nftId}
                      </P3>
                    </P3>
                  </Flexed>

                  <AutoStake
                    toggle={toggle}
                    packageItem={item}
                    setToggle={setToggle}
                    setAutostake={setAutostake}
                    setPackageItem={setPackageItem}
                    setAutoStakeIsOpen={setAutoStakeIsOpen}
                  />
                </Responsive>
                <PortfolioGrid>
                  <BoxGray>
                    <Flexed
                      center
                      between
                      width='100%'
                      rowSM
                      alignStartSM
                      gap='16px'>
                      <div>
                        <CaptionText
                          caps
                          bold
                          color={color.colors.neutral500}
                          mb='10'>
                          {t('available_stake')}
                        </CaptionText>
                        <P1 bold flex alignEnd gap='5px'>
                          {formatUSD(item?.availableStake)} <P3 mb='1'>ULX</P3>
                        </P1>
                      </div>
                      <Flexed>
                        {item?.availableStake > 0 ? (
                          <Icon
                            mr='10'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setClaimCalculator(!claimCalculator);
                              setPackageItem(item);
                              dispatch(
                                getStakingWithdrawalInfo(
                                  item.id,
                                  item.availableStake
                                )
                              );
                            }}>
                            <IconCalculator />
                          </Icon>
                        ) : (
                          <div></div>
                        )}

                        <Button
                          disabled={item?.availableStake === 0}
                          // disabled={true}
                          w='100px'
                          h='34px'
                          onClick={() => {
                            setClaim(true);
                            setClaimStep(1);
                            setPackageItem(item);
                          }}>
                          {t('claim.claim')}
                        </Button>
                      </Flexed>
                    </Flexed>
                  </BoxGray>
                  <BoxGray>
                    <div>
                      <CaptionText
                        caps
                        bold
                        color={color.colors.neutral500}
                        mb='10'>
                        {t('next_unlock_countdown')}
                      </CaptionText>
                      <P1 bold flex alignEnd gap='5px'>
                        {timestamp && (
                          <Countdown
                            date={isSameLikeNextCountdownDate(item)}
                            renderer={renderer}
                          />
                        )}
                      </P1>
                    </div>
                  </BoxGray>
                  <BoxGray>
                    <div>
                      <CaptionText
                        caps
                        bold
                        color={color.colors.neutral500}
                        mb='10'>
                        {t('next_unlock_amount')}
                      </CaptionText>
                      <P1 bold flex alignEnd gap='5px'>
                        {formatUSD(calculateNextAmount(30, item.baseStake))} ULX
                      </P1>
                    </div>
                  </BoxGray>
                </PortfolioGrid>

                {/* {isCountdownOver && (
                  <div style={{ marginTop: 15 }}>
                    The complete claim of unlocked wULXs is scheduled for
                    October 17, 2024, at 17:00 CEST.
                  </div>
                )} */}
              </>
            </div>
          </div>

          <AnimatePresence>
            {loadingAutoStake === item.id && <Spinner absolute transparent />}
          </AnimatePresence>
          {showDisabledStakingHub(item)}
        </BoxBig>
      ) : (
        <BoxBig relative>
          <HubImage style={{ height: 336 }} image={packageTypeForIcons(item)}>
            <H4 color={color.colors.neutral0}>{packageType(item)}</H4>
          </HubImage>
          <Tr>
            <Td w='20%' wSM='100%'>
              <Flexed center>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    fetchData();
                    setHubIsOpen(true);
                  }}>
                  <P1 ml='16' bold>
                    {t('transactions')}
                  </P1>
                </div>
              </Flexed>
            </Td>

            <Td w='20%' wSM='50%'>
              <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                {t('date_of_creation')}
              </CaptionText>
              <P1 bold>
                {moment.unix(item?.dateOfCreation).format('DD MMM YYYY')}
              </P1>
            </Td>
            <Td w='20%' wSM='50%'></Td>

            <Td w='20%' wSM='50%'>
              <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                {t('total_stake')}
              </CaptionText>
              <P1 bold flex alignEnd gap='5px'>
                {formatUSD(item.totalStake)} <P3 mb='1'>wULX</P3>
              </P1>
            </Td>
            <Td w='20%' wSM='50%'>
              <CaptionText caps bold color={color.colors.neutral500} mb='10'>
                {t('my_earnings')}
              </CaptionText>
              <P1
                color={color.colors.secondary100}
                bold
                flex
                alignEnd
                gap='5px'>
                {formatUSD(item.myEarnings)} <P3 mb='1'>wULX</P3>
              </P1>
            </Td>
          </Tr>

          <AnimatePresence>
            {loadingAutoStake === item.id && <Spinner absolute transparent />}
          </AnimatePresence>
        </BoxBig>
      )}
      <SidebarModal
        isOpen={hubIsOpen}
        setOpen={setHubIsOpen}
        title={'Bonus Hub transactions history'}
        sidebarWidth={'508px'}>
        <BonusHubTransactions loading={loading} transactions={transactions} />
      </SidebarModal>
      <SidebarModal
        isOpen={autoStakeIsOpen}
        setOpen={setAutoStakeIsOpen}
        title={'Auto stake changes history'}
        sidebarWidth={'508px'}>
        <AutoStakeTransactions />
      </SidebarModal>
    </>
  );
};

const AutoStake = ({
  toggle,
  packageItem,
  setToggle,
  setAutostake,
  setPackageItem
}) => {
  const dispatch = useDispatch();

  const activeSince = (date) => {
    const now = moment(new Date()); //todays date
    const end = moment.unix(
      date.lastAutocompoundTimestamp > 0
        ? date.lastAutocompoundTimestamp
        : date?.dateOfCreation
    );

    const duration = moment.duration(now.diff(end));

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (years > 0) {
      return `${years} ${years === 1 ? 'year' : 'years'} ${months} ${
        months === 1 ? 'month' : 'months'
      }  ${days} days ${hours}h ${minutes}min`;
    } else if (months > 0) {
      return `${months} ${
        months === 1 ? 'month' : 'months'
      }  ${days} days ${hours}h ${minutes}min`;
    } else if (days > 0) {
      return `${days} days ${hours}h ${minutes}min`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}min`;
    } else {
      return `${minutes}min`;
    }
  };

  const deactivatedSince = () => {
    const now = moment(new Date()); //todays date
    const start = now.format('DD MMM YYYY');

    const dayINeed = 8;

    const end = moment().day(dayINeed).format('DD MMM YYYY');

    const day = Math.abs(
      moment(start).startOf('day').diff(moment(end).startOf('day'), 'days')
    );

    return `${day} ${day === 1 ? 'day' : 'days'}`;
  };

  const showAutoStake = (packageItem) => {
    const { t } = useTranslation('my_hubs');

    const dateNow = moment(new Date()).toISOString();

    if (
      packageItem?.dateLimitForAutoStake === null ||
      packageItem?.dateLimitForAutoStake <= dateNow
    ) {
      return (
        <Flexed
          justifyCenter
          mt='10'
          style={{ textAlign: 'right', flex: 'none' }}>
          <P3 mr='4' semiBold color={color.colors.neutral500}>
            {t('auto_stake')}
          </P3>
          <Icon mr='16'>
            <IconQuestionMark />
          </Icon>

          <Switch
            onChange={() => {
              if (packageItem.autocompound && !packageItem.disableAtNextWeek) {
                setAutostake(true);
                setPackageItem(packageItem);
              } else {
                setAutostake(false);
                dispatch(
                  changeAutoStake(
                    packageItem.id,
                    true,
                    setAutostake,
                    toggle,
                    setToggle
                  )
                );
              }
            }}
            checked={
              packageItem?.autocompound && !packageItem?.disableAtNextWeek
            }
            checkedIcon={false}
            uncheckedIcon={false}
            onColor={color.colors.secondary100}
            height={20}
            width={42}
            handleDiameter={18}
          />
        </Flexed>
      );
    } else {
      return (
        <Flexed flexed end mt='10'>
          <CaptionText>
            Auto-stake switch locked until:{' '}
            {moment(packageItem.dateLimitForAutoStake).format('DD. MMM YYYY.')}
          </CaptionText>
        </Flexed>
      );
    }
  };
  const showTimer = (packageItem) => {
    if (!packageItem.autocompound && !packageItem.disableAtNextWeek) {
      return <div></div>;
    } else if (!packageItem.dateLimitForAutoStake) {
      return (
        <Icon mr='5'>
          <IconTimer />
        </Icon>
      );
    }
  };

  const showText = (packageItem) => {
    const { t } = useTranslation('my_hubs');

    if (!packageItem.autocompound && !packageItem.disableAtNextWeek) {
      return <div></div>;
    } else {
      if (
        packageItem.autocompound &&
        !packageItem.disableAtNextWeek &&
        !packageItem.dateLimitForAutoStake
      ) {
        return (
          <CaptionText>
            {t('auto_stake_active_since')} <b>{activeSince(packageItem)}</b>
          </CaptionText>
        );
      } else if (packageItem.autocompound && packageItem.disableAtNextWeek) {
        return (
          <CaptionText>
            {t('auto-stake-change')} <b>{deactivatedSince(packageItem)}</b>.
          </CaptionText>
        );
      }
    }
  };
  return (
    <div style={{ flex: 'none' }}>
      <div>
        <Flexed>
          {showTimer(packageItem)}
          <div>{showText(packageItem)}</div>
        </Flexed>
      </div>

      {showAutoStake(packageItem)}
    </div>
  );
};
