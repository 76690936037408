import { useTranslation } from 'react-i18next';

import { formatVP } from '../../helpers/helperFunctions';
import { CaptionText, H5, P1 } from '../../styles/FontStyles';
import { SectionSeparator } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  Icon,
  IconDashboardDirects,
  IconDashboardDownline
} from '../../styles/Icons';
import { Flex, FlexCenter } from '../../styles/stylesStyled';
import { useWindowWide } from '../../utils/wideScreen';
import { IconTitleStyled, Statistical } from './DashboardStyled';

export const StatisticalComponent = ({ backOffice }) => {
  const { t } = useTranslation('dashboard');
  const statisticalData = [
    {
      icon: IconDashboardDirects,
      // title: t('statistic.team_downline'),
      title: 'Shares',
      amount:
        backOffice?.user?.leftLegTotalVolume +
          backOffice?.user?.rightLegTotalVolume ?? '0.00',
      amountPlus:
        backOffice?.user?.leftLegTodayVolume +
          backOffice?.user?.rightLegTodayVolume ?? '0.00'
      // linkTo: 'structure',
    },
    {
      icon: IconDashboardDownline,
      // title: t('statistic.team_downline'),
      title: 'Numbers of miners',
      amount:
        backOffice?.user?.leftLegInfo?.userCount +
        backOffice?.user?.rightLegInfo?.userCount,
      amountPlus:
        backOffice?.user?.leftLegInfo?.todayUserCount +
          backOffice?.user?.rightLegInfo?.todayUserCount ?? 0
      // linkTo: 'structure',
    }
  ];

  const wide = useWindowWide(800);

  return (
    <>
      <IconTitleStyled>
        {/* {t('statistic.title')} */}
        On-chain Data
      </IconTitleStyled>
      <Statistical>
        {statisticalData.map((data, i) => (
          <div key={i}>
            <FlexCenter>
              <Icon color={color.colors.secondary100} mr='12' fill='true'>
                <data.icon />
              </Icon>

              <H5 color={color.colors.neutral700}>{data.title}</H5>
            </FlexCenter>

            <SectionSeparator margin={!wide && '5px 0 12px 0'} />

            <Flex style={{ alignItems: 'end', flexWrap: 'wrap' }}>
              <P1 bold mr='8'>
                {formatVP(data.amount)}{' '}
              </P1>

              <CaptionText mb='2' flex color={color.colors.neutral500}>
                <CaptionText mr='5' color={color.colors.success500}>
                  +{data.amountPlus}
                </CaptionText>{' '}
                {t('statistic.today')}
              </CaptionText>
            </Flex>
          </div>
        ))}
      </Statistical>
    </>
  );
};
