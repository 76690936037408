import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import {
  SETTINGS_ACCOUNT,
  SETTINGS_PAYMENTS,
  SETTINGS_SECURITY,
  SETTINGS_VIRTUAL_OFFICE
} from '../../routes/routes';
import { CaptionText, P2 } from '../../styles/FontStyles';
import { PageTitle } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import {
  Icon,
  IconSettingsAccount,
  IconSettingsPassword,
  IconSettingsPayments,
  IconSettingsVirtualOffice
} from '../../styles/Icons';
import { NavigationWrapper, NavItem } from './SettingsStyled';

export const SettingsNavigation = () => {
  const { t } = useTranslation('settings');
  const location = useLocation();

  const Routes = [
    {
      title: t('navigation.account_t'),
      subtitle: t('navigation.account_s'),
      icon: IconSettingsAccount,
      link: SETTINGS_ACCOUNT
    },
    {
      title: t('security.security_t'),
      subtitle: t('security.security_s'),
      icon: IconSettingsPassword,
      link: SETTINGS_SECURITY
    },
    {
      title: t('navigation.virtual_office_t'),
      subtitle: t('navigation.virtual_office_s'),
      icon: IconSettingsVirtualOffice,
      link: SETTINGS_VIRTUAL_OFFICE
    },
    {
      title: t('navigation.wallets_t'),
      subtitle: t('navigation.wallets_s'),
      icon: IconSettingsPayments,
      link: SETTINGS_PAYMENTS
    }
    // {
    //   title: t('navigation.notifications_t'),
    //   subtitle: t('navigation.notifications_s'),
    //   icon: IconSettingsNotifications,
    //   link: SETTINGS_NOTIFICATIONS,
    // },
    // {
    //   title: t('navigation.team_t'),
    //   subtitle: t('navigation.team_s'),
    //   icon: IconSettingsTeamSettings,
    //   link: SETTINGS_TEAM_SETTINGS,
    // },
  ];

  return (
    <NavigationWrapper>
      <PageTitle>{t('navigation.title')}</PageTitle>
      {Routes.map((route, i) => {
        return (
          <NavLink
            key={i}
            to={route.link}
            exact
            activeClassName='settingsNavActive'
            className='settingsNav'>
            <NavItem>
              <Icon
                mr='20'
                color={
                  location.pathname === route.link
                    ? color.colors.primary900
                    : color.settings.navLinkIconColor
                }>
                <route.icon />
              </Icon>
              <div>
                <P2
                  bold
                  color={
                    location.pathname === route.link
                      ? color.colors.primary900
                      : color.settings.navLinkIconColor
                  }>
                  {route.title}
                </P2>
                <CaptionText
                  color={
                    location.pathname === route.link
                      ? color.colors.primary900
                      : color.settings.navLinkIconColor
                  }>
                  {route.subtitle}
                </CaptionText>
              </div>
            </NavItem>
          </NavLink>
        );
      })}
    </NavigationWrapper>
  );
};
