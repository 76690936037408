import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputBackOffice } from '../../../components/common/InputBackoffice';
import { FlexCenter } from '../../../styles/stylesStyled';
import { Subtitle, Title } from './WithdrawStyled';

import Alert from '../../../components/common/Alert';

import { createUserWallet } from '../../../actions/walletActions';
import { setErorMessage } from '../../../helpers/helperFunctions';
import { Button } from '../../../styles/GlobalComponents';
import { useTranslation } from 'react-i18next';

export const AddBankAccount = ({ setStep, setIsOpen2 }) => {
  const { t } = useTranslation('earnings');
  const dispatch = useDispatch();
  const [label, setLabel] = useState('');
  const [address, setAddress] = useState('');

  const [error, setError] = useState('');
  const { error: errorMsg } = useSelector((state) => state.withdraw);

  return (
    <div style={{ marginTop: !setIsOpen2 && '12vh' }}>
      {error && <Alert message={error} />}
      {errorMsg && <Alert message={errorMsg.error} />}
      <FlexCenter style={{ flexDirection: 'column' }}>
        <Title>{t('withdraw_part.add_bank_account.title')}</Title>
        <Subtitle style={{ textAlign: 'center' }}>
          {t('withdraw_part.add_bank_account.subtitle')}
        </Subtitle>

        <InputBackOffice
          placeholder={t('withdraw_part.add_bank_account.placeholderLabel')}
          value={label}
          onChange={(e) => setLabel(e)}
        />

        <InputBackOffice
          placeholder={t('withdraw_part.add_bank_account.placeholder')}
          value={address}
          onChange={(e) => setAddress(e.replace(/\s+/g, ''))}
        />

        <Button
          onClick={() => {
            if (!address) {
              setErorMessage(
                setError,
                t('withdraw_part.add_bank_account.err_address_required')
              );
            } else {
              dispatch(
                createUserWallet(
                  address,
                  setAddress,
                  setStep,
                  true,
                  label,
                  setLabel
                )
              );

              // setIsOpen2(false);
            }
          }}
          mt='30'
          maxWidth='200px'>
          {t('withdraw_part.add_bank_account.add')}
        </Button>
      </FlexCenter>
    </div>
  );
};
