import {
  GET_USER_SETTINGS_FAIL,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_REQUEST,
  UPDATE_USER_SETTINGS_REQUEST,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_FAIL
} from '../constants/userDetailsConstants';

export const userSettings = (state = {}, action) => {
  switch (action.type) {
    //USER SETTINGS
    case GET_USER_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case GET_USER_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    //USER SETTINGS

    case UPDATE_USER_SETTINGS_REQUEST:
      return { ...state, loading: true };

    case UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case UPDATE_USER_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    // case CLEAR_ALERT:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };
    default:
      return state;
  }
};
