import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import icon2 from '../../assets/images/backoffice/kyc6.png';
import {
  TRANSITION_STATE_1,
  TRANSITION_STATE_2
} from '../../constants/transitionConstants';
import { H2, H3, P1, P2 } from '../../styles/FontStyles';
import { Button } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';
import { useWindowWide } from '../../utils/wideScreen';
import { Desktop, Mobile, TitleImage } from './KycStyled';

const KycFinish = ({ history }) => {
  const { t } = useTranslation('kyc.finish');
  const wide = useWindowWide(1000);
  const dispatch = useDispatch();

  const HandleToNextPage = () => {
    dispatch({ type: TRANSITION_STATE_1 });

    setTimeout(() => {
      history.push('/welcome-packages');
      dispatch({ type: TRANSITION_STATE_2 });
    }, 1000);
  };

  return (
    <div style={{ paddingTop: !wide && '12vh', textAlign: !wide && 'center' }}>
      <TitleImage>
        <img src={icon2} alt='icon' style={{ marginBottom: 40, width: 220 }} />
      </TitleImage>

      <Desktop>
        <H2 mb='12' color={color.kycTitleColor}>
          <b>{t('title_part_1')}</b> {t('title_part_2')}
        </H2>

        <P1
          mb='40'
          maxWidth='600px'
          maxWidthSM='1000px'
          color={color.kycSubTitleColor}>
          {t('subtitle')}
        </P1>
      </Desktop>
      <Mobile>
        <H3 mb='8' center color={color.kycTitleColor}>
          {t('title_part_1')}
        </H3>

        <P2 mb='32' center color={color.kycSubTitleColor}>
          {t('subtitle')}
        </P2>
      </Mobile>

      <Desktop>
        <Button onClick={() => HandleToNextPage()} maxWidth='200px'>
          {t('continue_btn')}
        </Button>
      </Desktop>
      <Mobile>
        <Button onClick={() => HandleToNextPage()}>{t('continue_btn')}</Button>
      </Mobile>
    </div>
  );
};

export default KycFinish;
