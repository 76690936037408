import React from 'react';
import styled from 'styled-components';
import { color } from '../../styles/GlobalStyles';
import { Icon, IconCameraCapture } from '../../styles/Icons';

const Button = styled.button`
  margin: 32px auto 20px auto;
  border: none;
  background: none;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const CameraButton = ({ click }) => {
  return (
    <ButtonContainer>
      <Button onClick={click}>
        <Icon color={color.colors.primary900}>
          <IconCameraCapture />
        </Icon>
      </Button>
    </ButtonContainer>
  );
};
export default CameraButton;
