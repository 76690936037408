import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SidebarIcon, SidebarNavLinks } from '../../helpers/helperFunctions';
import { DASHBOARD } from '../../routes/routes';
import { P2 } from '../../styles/FontStyles';
import { color, globalImages } from '../../styles/GlobalStyles';
import { Icon } from '../../styles/Icons';
import { FlexCenter } from '../../styles/stylesStyled';
import { SideButtonsContent } from '../BackofficeLayout/BackofficeLayoutStyled';

export const SidebarNavContent = ({ onClose }) => {
  const location = useLocation();

  return (
    <SideButtonsContent>
      <Link to={DASHBOARD}>
        <img src={globalImages.appLogo} alt='logo' />
      </Link>
      {SidebarNavLinks().map((link, i) => (
        <Link
          to={link.link}
          key={i}
          style={{
            pointerEvents: link.disabled && 'none'
          }}
          onClick={() => onClose(false)}>
          <FlexCenter
            style={{
              opacity: link.disabled ? 0.3 : 1
            }}>
            <Icon
              m='20px'
              mSM='10px 20px'
              w='32px'
              color={color.appLayout.sideNavIconColor}>
              <SidebarIcon link={link.name} />
            </Icon>
            <P2
              semiBold
              color={
                link.link === location.pathname
                  ? color.colors.primary900
                  : color.colors.neutral900
              }>
              {link.name}
            </P2>
          </FlexCenter>
        </Link>
      ))}
    </SideButtonsContent>
  );
};
