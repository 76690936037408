import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { H5, P1, P2, P3 } from '../../../styles/FontStyles';
import { Box, Flexed, Button } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { Icon, IconWarningMark } from '../../../styles/Icons';
import { CheckText } from '../../../components/common/CheckText';

import { formatUSD, ULX } from '../../../helpers/helperFunctions';
import { ClaimSteps } from './types';

export const ClaimWithdrawal = ({
  packageItem,
  setClaim,
  setClaimStep,
  amount
}) => {
  const { t } = useTranslation('my_hubs');
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);

  const { ulxAmountToBurn } = useSelector((state) => state.portfolio);

  return (
    <>
      <Box gray>
        <Flexed column>
          <Flexed column center>
            <Icon mb='12'>
              <IconWarningMark size='50' color={color.colors.error400} />
            </Icon>
            <H5 mb='20' color={color.colors.error400}>
              {t('claim.warning')}!
            </H5>
            <P1 mb='20' left bold color={color.colors.neutral500}>
              {t('claim.carefully_review_witdrawal')}
              <br />
            </P1>
            <Box blue>
              <Flexed column>
                <Flexed>
                  <P2 mb='10' mr='5' color={color.colors.neutral500}>
                    {t('claim.amount_unlocked')}
                  </P2>
                  <P2 semiBold>
                    {formatUSD(packageItem.availableStake)} {ULX}
                  </P2>
                </Flexed>
                <Flexed>
                  <P2 mb='10' mr='5' color={color.colors.neutral500}>
                    {t('claim.amount_to_withdraw')}
                  </P2>
                  <P2 semiBold>
                    {formatUSD(Number(amount))} {ULX}
                  </P2>
                </Flexed>
                <Flexed>
                  <P2 mb='10' mr='5' color={color.colors.error400}>
                    {t('claim.amount_burned_value')}
                  </P2>
                  <P2 semiBold color={color.colors.error400}>
                    {formatUSD(ulxAmountToBurn)} {ULX} 😢
                  </P2>
                </Flexed>
              </Flexed>
            </Box>
          </Flexed>
          <Flexed>
            <P2 mb='20' mt='20' mr='5' bold>
              {t('claim.total_receiving_amount')}
            </P2>
            <P2 bold mt='20'>
              {formatUSD(Number(amount) - ulxAmountToBurn)} {ULX}
            </P2>
          </Flexed>
          <P2 bold color={color.colors.error400}>
            {t('claim.your_withdraw_warning')}
          </P2>
        </Flexed>
      </Box>
      <CheckText
        checked={checkbox1}
        onClick={() => setCheckbox1(!checkbox1)}
        text={t('claim.confirm_txt3')}
        checkColor={color.colors.error400}
      />
      <CheckText
        checked={checkbox2}
        onClick={() => setCheckbox2(!checkbox2)}
        text={t('claim.confirm_txt4')}
        checkColor={color.colors.error400}
      />
      <CheckText
        checked={checkbox3}
        onClick={() => setCheckbox3(!checkbox3)}
        text={t('claim.confirm_txt5')}
        checkColor={color.colors.error400}
      />
      <Flexed>
        <CheckText
          checked={checkbox4}
          onClick={() => setCheckbox4(!checkbox4)}
          checkColor={color.colors.error400}
        />

        <P3 mt='3' semiBold color={color.colors.neutral500}>
          {t('claim.confirm_txt6')}{' '}
          <span style={{ color: color.colors.error400 }}>
            {formatUSD(ulxAmountToBurn)} {ULX}{' '}
          </span>
          {t('claim.confirm_txt7')}
        </P3>
      </Flexed>

      <Flexed>
        <Button
          onClick={() => setClaim(false)}
          mt='20'
          mr='10'
          color={color.colors.success400}>
          {t('claim.cancel_withdrawal')}
        </Button>
        <Button
          onClick={() => {
            setClaimStep(ClaimSteps.STEP4);
          }}
          mt='20'
          ml='10'
          disabled={!checkbox1 || !checkbox2 || !checkbox3 || !checkbox4}
          color={color.colors.error400}>
          {t('claim.continue_to_withdrawal')}
        </Button>
      </Flexed>
    </>
  );
};
