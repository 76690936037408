const getRequestConst = (unique) => `${unique}_REQUEST`;
const getSuccessConst = (unique) => `${unique}_SUCCESS`;
const getFailConst = (unique) => `${unique}_FAIL`;

export const GET_SUPPORT_CATEGORIES = {
  REQUEST: getRequestConst('GET_SUPPORT_CATEGORIES'),
  SUCCESS: getSuccessConst('GET_SUPPORT_CATEGORIES'),
  FAIL: getFailConst('GET_SUPPORT_CATEGORIES')
};

export const GET_SUBCATEGORY_WITH_QUESTION = {
  REQUEST: getRequestConst('GET_SUBCATEGORY_WITH_QUESTION'),
  SUCCESS: getSuccessConst('GET_SUBCATEGORY_WITH_QUESTION'),
  FAIL: getFailConst('GET_SUBCATEGORY_WITH_QUESTION')
};

export const GET_CATEGORY_WITH_QUESTION = {
  REQUEST: getRequestConst('GET_CATEGORY_WITH_QUESTION'),
  SUCCESS: getSuccessConst('GET_CATEGORY_WITH_QUESTION'),
  FAIL: getFailConst('GET_CATEGORY_WITH_QUESTION')
};

export const GET_QUESTION = {
  REQUEST: getRequestConst('GET_QUESTION'),
  SUCCESS: getSuccessConst('GET_QUESTION'),
  FAIL: getFailConst('GET_QUESTION')
};

export const SEARCH_QUESTION = {
  REQUEST: getRequestConst('SEARCH_QUESTION'),
  SUCCESS: getSuccessConst('SEARCH_QUESTION'),
  FAIL: getFailConst('SEARCH_QUESTION')
};

export const TOGGLE_CATEGORY_COLLAPSED = 'TOGGLE_CATEGORY_COLLAPSED';
export const TOGGLE_SUBCATEGORY_COLLAPSED = 'TOGGLE_SUBCATEGORY_COLLAPSED';
export const TOGGLE_SUBCATEGORY_QUESTION_COLLAPSED =
  'TOGGLE_SUBCATEGORY_QUESTION_COLLAPSED';
export const TOGGLE_CATEGORY_QUESTION_COLLAPSED =
  'TOGGLE_CATEGORY_QUESTION_COLLAPSED';

export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

export const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS';
