import { CHANGE_EMAIL_SEND, VERIFY__NEW_EMAIL } from './changeEmail.constants';

export const changeEmail = (
  state = {
    loading: false,
    error: ''
  },
  action
) => {
  switch (action.type) {
    case CHANGE_EMAIL_SEND.REQUEST:
      return {
        ...state,
        loading: true
      };
    case CHANGE_EMAIL_SEND.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case CHANGE_EMAIL_SEND.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case VERIFY__NEW_EMAIL.REQUEST:
      return {
        ...state,
        loading: true
      };
    case VERIFY__NEW_EMAIL.SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case VERIFY__NEW_EMAIL.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
