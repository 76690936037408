// import check from '../../assets/icons/Welcome/check.svg';
// import x from '../../assets/icons/Welcome/x.svg';

import crown from '../../assets/images/backoffice/crown.png';
import marketing from '../../assets/images/backoffice/horn.png';
import { useWindowWide } from '../../utils/wideScreen';

import {
  TextMedium,
  TextSmallComparison,
  WaitingRoomTable,
  WaitingRoomTr
} from './WelcomeStyled';

const descriptions = [
  {
    description: '10% direct customer bonus',
    partner: true,
    ambassador: true
  },
  {
    description: '5% team sales customer bonus',
    partner: true,
    ambassador: true
  },
  {
    description: 'Withdraw Option',
    partner: true,
    ambassador: true
  },
  {
    description: 'Additional 40% Bonuses',
    partner: true,
    ambassador: false
  },
  {
    description: '10% Team Bonus',
    partner: true,
    ambassador: false
  },
  {
    description: '5x 10% Matching Bonus',
    partner: true,
    ambassador: false
  },
  {
    description: 'Premium Backoffice',
    partner: true,
    ambassador: false
  },
  {
    description: 'Exclusive Prize Campaigns',
    partner: true,
    ambassador: false
  },
  {
    description: 'Business Opportunities',
    partner: true,
    ambassador: false
  },
  {
    description: 'Luxury Events',
    partner: true,
    ambassador: false
  }
];

export const CareerComparation = () => {
  const wide = useWindowWide(800);

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: '0 20px',
          display: 'flex',
          alignItems: wide && 'flex-end',
          marginBottom: wide ? 30 : 16
        }}>
        <div
          style={{
            width: '50%',
            textAlign: 'left',
            alignItems: 'flex-end',
            display: 'flex'
          }}>
          <TextMedium>Feature</TextMedium>
        </div>

        <div style={{ width: '25%', textAlign: 'center' }}>
          {wide && <img style={{ width: 80 }} src={crown} alt='im' />}
          <TextMedium>Partner</TextMedium>
          <TextSmallComparison>Starts at 250 EUR</TextSmallComparison>
        </div>
        <div style={{ width: '25%', textAlign: 'center' }}>
          {wide && <img style={{ width: 80 }} src={marketing} alt='im' />}

          <TextMedium
            style={
              {
                // padding: '0 20px',
              }
            }>
            Ambassador
          </TextMedium>
          <TextSmallComparison>Free</TextSmallComparison>
        </div>
      </div>

      <WaitingRoomTable style={{ marginBottom: 56 }}>
        {descriptions.map((order, i) => {
          return (
            <WaitingRoomTr key={i} style={{ margin: 0 }}>
              <div
                style={{
                  width: '50%',
                  textAlign: 'left',
                  fontSize: !wide && '12px',
                  lineHeight: !wide && '18px'
                }}>
                {order.description}
              </div>
              <div style={{ width: '25%' }}>
                {/* <img
                  src={order.partner ? check : x}
                  style={{ width: !wide && 24 }}
                  alt='im'
                /> */}
              </div>
              <div style={{ width: '25%' }}>
                {/* <img
                  src={order.ambassador ? check : x}
                  style={{ width: !wide && 24 }}
                  alt='im'
                /> */}
              </div>
            </WaitingRoomTr>
          );
        })}
      </WaitingRoomTable>
    </>
  );
};
