import React, { useState } from 'react';
import styled from 'styled-components';
import closed from '../../assets/icons/closedEye.svg';
import opened from '../../assets/icons/openedEye.svg';

const Input = styled.input`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  font-size: ${(props) => props.theme.inputFontSize};
  color: ${(props) => props.theme.inputFontColor};
  caret-color: ${(props) => props.theme.primary900};
  max: ${(props) => props.max && props.max};
  ::placeholder {
    color: ${(props) => props.theme.inputPlaceholderFontColor};
  }
`;

const InputContainer = styled.div`
  background: ${(props) => props.theme.inputBackground};
  border-radius: 8px;
  width: 100%;
  height: ${(props) => props.theme.inputHeight};
  display: flex;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '0 20px')};
  margin: 0 0 20px 0;
  border: ${(props) => props.theme.inputBorder};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.inputShadow};
  justify-content: space-between;
  :focus-within {
    /* border: 1px solid #e1dbdb; */
  }
`;

const InpCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  :focus-within {
    .lab {
      height: 12px;
      opacity: 0.7;
    }
  }
  ${(props) =>
    props.val
      ? ` .lab {
    height: 12px;
    opacity: 0.7;
  }`
      : ``};
`;

const Label = styled.label`
  font-size: 12px;
  color: #2d2d2d;
  text-transform: uppercase;
`;

const TextArea = styled.textarea`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  z-index: 1;
  font-size: ${(props) => props.theme.inputFontSize};
  color: ${(props) => props.theme.inputFontColor};
  caret-color: ${(props) => props.theme.primary900};
  height: 100px;
`;

const TextAreaContainer = styled.div`
  background: ${(props) => props.theme.inputBackground};
  border-radius: 8px;
  width: 100%;
  display: flex;
  // flex-direction: column;
  align-items: center;
  padding: 10px 16px;
  margin: 0 0 20px 0;
  justify-content: space-between;
  /* :focus-within { */
  /* border: 1px solid #c6bdbd; */
  /* } */
`;

const InputBackOffice = (props) => {
  const [viewPass, setViewPass] = useState(false);
  if (props.type === 'textarea')
    return (
      <TextAreaContainer className={`${props.error && 'shake-input'}`}>
        <InpCont val={props.value}>
          {props.label && <Label className='lab'>{props.label}</Label>}

          <TextArea
            className='inp'
            id={props.id}
            type={
              props.type !== 'password'
                ? props.type
                : viewPass
                ? 'text'
                : 'password'
            }
            name={props.name}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            label={props.label}
            placeholder={props.placeholder}
            style={{ zIndex: 'auto' }}
            rows={props.rows ?? 3}
            disabled={props.disabled}
          />
        </InpCont>
      </TextAreaContainer>
    );

  return (
    <InputContainer
      className={`${props.error && 'shake-input'}`}
      style={props.style}
      padding={props.padding}>
      <InpCont val={props.value}>
        {/* {props.label && <Label className='lab'>{props.label}</Label>} */}

        <Input
          className={'inp'}
          disabled={props.disabled}
          id={props.id}
          type={
            props.type !== 'password'
              ? props.type
              : viewPass
              ? 'text'
              : 'password'
          }
          name={props.name}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          label={props.label}
          placeholder={props.placeholder}
          min={props.min}
          max={props.max}
          style={props.inputStyle}
        />
      </InpCont>

      {props.type === 'password' && (
        <img
          src={viewPass ? opened : closed}
          alt='view password'
          onClick={() => setViewPass(!viewPass)}
        />
      )}
      {/* {props.required && (
        <img src={required} alt='view password' style={{ paddingBottom: 20 }} />
      )} */}
      {props.icon && <img src={props.icon} alt='icn' />}
    </InputContainer>
  );
};

export { InputBackOffice };
