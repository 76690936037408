import styled from 'styled-components';

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${(props) => props.theme.colors.neutral900};
  margin-bottom: 8px;
  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral500};
  margin-bottom: 40px;
`;

export const Image = styled.img`
  width: 160px;
  margin-bottom: 40px;
`;

export const ListImage = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 40px;
`;

export const SmallTxt = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.neutral500};
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral900};
  margin-bottom: 8px;

  div {
    color: ${(props) => props.theme.colors.neutral500};
    font-weight: normal;
    width: 120px !important;
    flex: none;
  }
`;

export const InfoRight = styled(Info)`
  span {
    width: 100px;
    text-align: right;
  }
  div {
    width: calc(100% - 100px) !important;
    text-align: right;
  }
`;

export const Select = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 1px;
  background: ${(props) =>
    props.isOpen
      ? 'linear-gradient(160.43deg,#f58e35 1.87%,#f26c6b 50.93%,#ee288a 99.33%);'
      : '#2C2626'};
  border-radius: 8px;
  position: relative;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #fefefe;
    border-radius: 8px;
    padding: 0 16px;
    background: #3e3a39;
    > img {
      margin-left: 16px;
      transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : '')};
      transition: all 500ms;
    }
  }
`;

export const SelectDropDown = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fefefe;
  border-radius: 8px;
  padding: 0 18px;
  background: #3e3a39;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

export const CollapsableAbsolute = styled.div`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  max-height: ${(props) => (props.isOpen ? `${props.height}` : '0')};
  overflow: hidden;
  transition: all 500ms;
  margin-bottom: ${(props) => (props.isOpen ? '20px' : '0')};
  position: absolute;
  right: 0;
  left: 0;
  top: 76px;
  z-index: 1;
`;

export const StretchBonus = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const AmountWrap = styled.div`
  width: ${(props) =>
    props.userRank === 'Ambassador' ? '100%' : 'calc(100% - 64px)'};
`;

export const PercentageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    160.43deg,
    #f58e35 1.87%,
    #f26c6b 50.93%,
    #ee288a 99.33%
  );
  border-radius: 8px;
  width: 56px;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #fefefe;
`;

export const AmountBox = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #b5a8a8;
  border-radius: 8px;
  padding: 0 20px;
  background: #3e3a39;
`;

export const BankAccount = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  width: 100%;
  background: ${(props) =>
    props.selected
      ? props.theme.colors.secondary100
      : props.theme.colors.neutral100};
  padding: 1px;
  border-radius: 8px;
  margin-bottom: 12px;
  > div {
    height: 60px;
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #b5a8a8;
    padding: 0 20px;
    border-radius: 8px;
    background: ${(props) =>
      props.selected
        ? props.theme.colors.neutral50
        : props.theme.colors.neutral100};
    span {
      ${(props) => (props.selected ? 'color: #FEFEFE' : '')};
    }
    img {
      margin-right: 20px;
    }
    > div {
      width: 100%;
    }
  }
`;

export const BankAccountGradientText = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #b5a8a8;
  ${(props) =>
    props.selected
      ? '  background: -webkit-linear-gradient(132.33deg, #D24074 -0.67%, #1268C3 102.54%);color: #ff0084 !important;-webkit-background-clip: text;background-clip: text;-webkit-text-fill-color: transparent;'
      : ''};
`;

export const CheckEmpty = styled.span`
  width: 24px;
  height: 24px;
  border: 1px solid #b5a8a8;
  border-radius: 50%;
  margin-right: 20px;
  flex: none;
`;

export const ReloadCheckEmpty = styled.span`
  width: 24px;
  height: 24px;
  border: 2px solid #fdfdfd;
  border-radius: 50%;
  flex: none;
`;

export const AddressCheckbox = styled.input`
  margin-right: 20px;
  flex: none;
  width: 19px;
  height: 19px;
`;

export const QrCodeWrapper = styled.div`
  margin: 40px 0px;
`;
