import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { H1, H5, P1, P2 } from '../../../styles/FontStyles';
import { Box, Flexed, Button } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { Icon, IconWarningMark } from '../../../styles/Icons';
import { formatUSD, ULX } from '../../../helpers/helperFunctions';

import { burnULX } from '../Portfolio.actions';

export const ClaimWithdrawalFinish = ({ amount, packageItem, setClaim }) => {
  const { t } = useTranslation('my_hubs');

  const dispatch = useDispatch();
  const { ulxAmountToBurn } = useSelector((state) => state.portfolio);

  return (
    <>
      <Box gray>
        <Flexed column>
          <Flexed column center>
            <Icon mb='12'>
              <IconWarningMark size='50' color={color.colors.error400} />
            </Icon>
            <H5 mb='20' color={color.colors.error400}>
              {t('claim.warning')}!
            </H5>
            <P1 mb='20' left bold color={color.colors.neutral500}>
              {t('claim.confirm_withdrawal_and_burn_wulx')}
              <br />
            </P1>
          </Flexed>
          <P2 mb='20' color={color.colors.neutral500}>
            {t('claim.i_am_aware_that_I_will_not_receive')}
            <span style={{ color: color.colors.error400 }}>
              {' '}
              {formatUSD(ulxAmountToBurn)} {ULX}{' '}
            </span>
            {t('claim.i_want_to_burn')}
          </P2>
          <Flexed column center>
            <H1 bold>
              {formatUSD(ulxAmountToBurn)} {ULX}
            </H1>
          </Flexed>
        </Flexed>
        <Button
          onClick={() => {
            dispatch(burnULX(packageItem.id, Number(amount), setClaim));
          }}
          mt='20'
          ml='10'
          color={color.colors.error400}>
          {t('claim.burn_wulx')}
        </Button>
      </Box>
    </>
  );
};
