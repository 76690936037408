import axios from 'axios';
import {
  GET_CONTENT_BLOCK_REQUEST,
  GET_CONTENT_BLOCK_SUCCESS,
  GET_CONTENT_BLOCK_FAIL
} from '../constants/contentBlocksConstants';

const API_URL = process.env.REACT_APP_API_URL;

export const getContentBlock = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_CONTENT_BLOCK_REQUEST });

    const { data } = await axios.get(`${API_URL}/content-block/${id}`);

    dispatch({
      type: GET_CONTENT_BLOCK_SUCCESS,
      payload: data.data.contentBlock
    });
  } catch (error) {
    dispatch({
      type: GET_CONTENT_BLOCK_FAIL,
      payload: error.response && error.response.data
    });
  }
};
