import React, { useState } from 'react';
import { Desktop, Mobile } from '../../../App';

import { Hub } from '../../../components/common/Hub/Hub';

import {
  BACKOFFICE_NAME,
  BACKOFFICE_NAME_CAPS,
  formatUSD,
  USDT,
  wallets
} from '../../../helpers/helperFunctions';
import { H3, H4, P1, P2, P3, CaptionText } from '../../../styles/FontStyles';
import {
  ArrowPolygon,
  Button,
  Flexed,
  SectionSeparator
} from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';

import {
  Icon,
  IconSettingsCheckboxOff,
  IconSettingsCheckboxOn
} from '../../../styles/Icons';
import { FlexBetween, FlexCenter } from '../../../styles/stylesStyled';
import { Checkbox } from '../Checkbox/Checkbox';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { buyVirtaulOffice } from '../../../views/SettingsBackoffice/settings.actions';
import { addHub } from '../../../views/Marketplace/Marketplace.actions';
import { VirtualOfficeSubscriptionStyled } from './VirtualOfficeSubscription.styled';

import {
  CollapsableAbsolute,
  Select,
  SelectDropDown,
  SelectedUSDT
} from '../../../views/Packages/PackagesStyled';
// import { CreditCard } from '../CreditCard';

export const VirtualOfficeSelectedOrder = ({
  step,
  setStep,
  selected,
  selectedHub
}) => {
  const { t } = useTranslation('packages');

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(wallets[0]);
  const [checkbox, setCheckbox] = useState(false);

  const { hubs } = useSelector((state) => state.hubs);

  const hub = hubs?.find((i) => i.id === selectedHub);

  const dispatch = useDispatch();

  const sendingTokenType = (selectedNetwork) => {
    switch (selectedNetwork.networkType) {
      case 1:
        return (
          <div>
            <b>{t('payment_method.important')}:</b> <br />
            {t('payment_method.text1')} <b>BEP-20 </b>
            {t('payment_method.text2')} <b>{t('payment_method.text3')}</b>{' '}
            {t('payment_method.text4')}
          </div>
        );

      case 2:
        return (
          <div>
            <b>{t('payment_method.important')}:</b> <br />
            {t('payment_method.text11')}
            <b> Tron Network TRC-20 </b>
            {t('payment_method.text2')} <b>{t('payment_method.text3')}</b>
            {t('payment_method.text4')}
          </div>
        );

      case 3:
        return (
          <div>
            <b>{t('payment_method.important')}:</b> <br />
            {t('payment_method.text11')}
            <b> Ultron Chain (ULC-20) </b>
            {t('payment_method.text2')} <b>{t('payment_method.text3')}</b>
            {t('payment_method.text4')}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div>
      {!selectedHub && (
        <>
          <P1 bold mt={40} mb={20}>
            SELECTED {BACKOFFICE_NAME_CAPS} SUBSCRIPTION:
          </P1>
          <VirtualOfficeSubscriptionStyled>
            <Desktop>
              <div>
                <Flexed between>
                  <Flexed center>
                    <Checkbox margin='0 12px 0 0' size={38} checked={true} />
                    <div>
                      <P1 caps bold>
                        {selected.title}
                      </P1>
                    </div>
                  </Flexed>
                  <div style={{ textAlign: 'end' }}>
                    <H3>${selected.price}</H3>
                  </div>
                </Flexed>
              </div>
            </Desktop>
            <Mobile>
              <div>
                <Flexed between center>
                  <Flexed center>
                    <Checkbox margin='0 12px 0 0' size={38} checked={true} />
                    <div>
                      <H4>{selected.title}</H4>
                    </div>
                  </Flexed>
                  <div style={{ textAlign: 'end' }}>
                    <H4>${selected.price}</H4>
                  </div>
                </Flexed>
              </div>
            </Mobile>
          </VirtualOfficeSubscriptionStyled>
        </>
      )}
      {selectedHub && (
        <P2 mt={40} mb={20} bold>
          SELECTED HUB NFT:
        </P2>
      )}

      {selectedHub &&
        hubs.map(({ id, image, title, price, weeklyCap }) => {
          if (id === selectedHub) {
            return (
              <Hub
                key={id}
                condensed
                image={image}
                title={title}
                price={hub.price ? price + selected.price : price}
                weeklyCap={weeklyCap}
                info={
                  <>
                    {' '}
                    <CaptionText bold right mb='4'>
                      Includes one year BackOffice Subscription
                    </CaptionText>
                  </>
                }
                stakingHubDetails={<div></div>}
              />
            );
          } else return null;
        })}
      <P3 mb='8' mt='40'>
        Select the USDT network and create a {BACKOFFICE_NAME} subscription
        order.
      </P3>

      <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
        {t('payment_method.payment_ammount')}
      </P3>

      <SelectedUSDT>
        {selectedHub
          ? formatUSD(selected.price + hub.price)
          : formatUSD(selected.price)}
        <b>{USDT}</b>
      </SelectedUSDT>

      <P3 bold mb='8' mt='20' color={color.colors.neutral900}>
        Select {t('payment_method.network')}
      </P3>

      <Select isOpen={isOpenDropdown}>
        <div
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
          style={{ cursor: 'pointer' }}>
          <span>
            {selectedNetwork ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}>
                <img
                  src={selectedNetwork.icon}
                  alt='checked'
                  style={{ marginRight: 10, width: 14 }}
                />

                <span>{selectedNetwork.wallet}</span>
              </div>
            ) : (
              <div style={{ opacity: 0.3 }}>
                {t('payment_method.select_network')}
              </div>
            )}
          </span>
          <ArrowPolygon
            direction={isOpenDropdown ? 'down' : 'right'}
            customColor={color.colors.neutral900}
          />
          <CollapsableAbsolute isOpen={isOpenDropdown}>
            <SelectDropDown>
              {wallets.map((wallet, index) => (
                <div key={index}>
                  <FlexBetween
                    onClick={() => {
                      setSelectedNetwork(wallet);
                      setIsOpenDropdown(!isOpenDropdown);
                    }}
                    key={index}
                    style={{ width: '100%' }}>
                    <FlexCenter>
                      <img
                        src={wallet.icon}
                        alt='checked'
                        style={{ marginRight: 10, width: 14 }}
                      />
                      <span>{wallet.wallet}</span>
                    </FlexCenter>

                    <Icon color={color.colors.neutral900}>
                      {selectedNetwork.index === index ? (
                        <IconSettingsCheckboxOn />
                      ) : (
                        <IconSettingsCheckboxOff />
                      )}
                    </Icon>
                  </FlexBetween>
                  <SectionSeparator margin='0' />
                </div>
              ))}
            </SelectDropDown>
          </CollapsableAbsolute>
        </div>
      </Select>

      {selectedNetwork && (
        <P3 bold mb='25' color={color.colors.error400}>
          {sendingTokenType(selectedNetwork)}
        </P3>
      )}

      {selectedNetwork && (
        <FlexCenter>
          <Checkbox
            pointer
            margin='0 12px 0 0'
            checked={checkbox}
            onClick={() => setCheckbox(!checkbox)}
          />
          <P3 color={color.colors.neutral500} checked={checkbox}>
            {t('payment_method.i_understand')}
          </P3>
        </FlexCenter>
      )}

      <Flexed justifyCenter mt='40' mb='40'>
        <Button
          maxWidth={'200px'}
          disabled={!checkbox}
          onClick={() => {
            if (selectedHub) {
              dispatch(
                addHub(
                  selectedHub,
                  1,
                  selectedNetwork.networkType,
                  1,
                  step,
                  setStep
                )
              );
            } else {
              dispatch(
                buyVirtaulOffice(
                  selected.id,
                  selectedNetwork.networkType,
                  1,
                  step,
                  setStep
                )
              );
            }
          }}>
          {t('payment_method.create_order')}
        </Button>
      </Flexed>
      {/* {selectedNetwork?.networkType === 1 && <CreditCard />} */}
    </div>
  );
};
