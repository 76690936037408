import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { firstLogin } from '../../actions/authActions';
import warning3 from '../../assets/images/backoffice/warning3.png';
import { P2, H3 } from '../../styles/FontStyles';
import { Button, Flexed } from '../../styles/GlobalComponents';
import { Checkbox } from '../../components/common/Checkbox/Checkbox';
import { color } from '../../styles/GlobalStyles';

export const FirstLogin = ({ history }) => {
  const [checkbox, setCheckbox] = useState(false);

  const dispatch = useDispatch();
  return (
    <div>
      <Flexed justifyCenter>
        <img src={warning3} alt='' style={{ width: 150 }} />
        {/* <img src={notice} alt='' style={{ width: 167 }} />  */}
      </Flexed>

      <H3 center m='32px 0 10px 0'>
        Back-Office Subscription Needed
        {/* {t('first_login.title')} */}
      </H3>
      {/* <P1 center bold>
        {' '}
        {t('first_login.title2')}
      </P1> */}
      <P2 m='32px 0'>
        If you haven’t purchased Back-Office Subscription <b>you</b>
        <br />
        <b>ARE NO LONGER RECEIVING:</b>
      </P2>

      <P2 mb='5'>&#x274C; Business Volume Points (BV) </P2>

      <P2 mb='32'>
        &#x274C; Bonuses (Team Bonus, Matching Bonus, Direct Bonus is capped to
        100 USDT per week){' '}
      </P2>

      <Flexed mb='40' center>
        <Checkbox
          margin={'0 20px 0 0'}
          checked={checkbox}
          onClick={() => setCheckbox(!checkbox)}
        />
        <P2 color={color.kycFontColor}>
          I am aware, that without BO subscription I can lose BV points
          accumulation and bonuses
        </P2>
      </Flexed>

      <Button
        disabled={!checkbox}
        onClick={() => dispatch(firstLogin(history))}>
        Check your Back-Office Status
      </Button>
      {/* <P2
        center
        pointer
        m='32px 0'
        color={!checkbox && color.buttonDisabledFontColor}
        onClick={() => {
          if (checkbox) {
            dispatch(firstLogin(history, false));
          }
        }}>
        Check your Back-Office Status
      </P2> */}
    </div>
  );
};
