import React from 'react';
import { useHistory } from 'react-router-dom';
import success from '../../assets/images/backoffice/kyc6.png';
import { BACKOFFICE_NAME } from '../../helpers/helperFunctions';
import { ORDERS } from '../../routes/routes';
import { H3 } from '../../styles/FontStyles';
import { Button, Flexed } from '../../styles/GlobalComponents';
import { color } from '../../styles/GlobalStyles';

export const PaymentSuccess2 = () => {
  const history = useHistory();

  return (
    <>
      <Flexed justifyCenter>
        <img src={success} alt='success' />
      </Flexed>
      <H3 center mb='5' color={color.colors.neutral900}>
        {BACKOFFICE_NAME} licence successfully purchased and activated
      </H3>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
        <Button onClick={() => history.push(ORDERS)} maxWidth='200px'>
          Close
        </Button>
      </div>
    </>
  );
};
