import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { P1, P3 } from '../../../styles/FontStyles';
import { Box, Flexed, Button } from '../../../styles/GlobalComponents';

import { color } from '../../../styles/GlobalStyles';
import { Icon, IconWarningMark } from '../../../styles/Icons';
import { CheckText } from '../../../components/common/CheckText';
import { InputBackOffice } from '../../../components/common/InputBackoffice';
import ulxIcon from '../../../assets/icons/backOffice/ULX.svg';
import { formatUSD, ULX } from '../../../helpers/helperFunctions';
import { FlexBetween } from '../../../styles/stylesStyled';
import { getStakingWithdrawalInfo } from '../Portfolio.actions';
import { ClaimSteps } from './types';

export const ClaimAmount = ({
  packageItem,
  setClaim,
  setClaimStep,
  amount,
  setAmount
}) => {
  const { t } = useTranslation('my_hubs');
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      <Box gray>
        <Flexed column center>
          <Flexed>
            <Icon mb='12'>
              <IconWarningMark size='50' />
            </Icon>
          </Flexed>

          <P1 mb='20' left bold>
            {t('claim.step_1_text')}
            <br />
          </P1>

          <P1 mb='20' left bold>
            <span style={{ color: 'red' }}> {t('claim.important')}</span>
          </P1>
          <P3 color={color.colors.neutral500}>{t('claim.amount_text1')}</P3>
          <br />
          <P3 color={color.colors.neutral500}>{t('claim.amount_text2')}</P3>
          <br />
          <a
            href='https://resources.mavie.global/presentations/staking_hub_cap_rule.pdf'
            target='_blank'
            rel='noreferrer'>
            <Button mt='20' ml='10' w='260px'>
              {t('claim.learn_more_about_capping_rule')}
            </Button>
          </a>

          <br />
          <InputBackOffice
            placeholder={t('claim.witdhrawal_amount_placeholder')}
            icon={ulxIcon}
            style={{ marginBottom: 8 }}
            onChange={(e) => {
              setAmount(e.replace(/[.,\s\D]/g, ''));
            }}
            pattern='[0-9]'
            value={amount}
          />
          <FlexBetween style={{ width: '100%', marginBottom: 5 }}>
            <P3 color={color.colors.neutral500}>
              {t('claim.unlocked')} {formatUSD(packageItem.availableStake)}{' '}
              {ULX}
            </P3>
            <div></div>
          </FlexBetween>
          <FlexBetween style={{ width: '100%', marginBottom: 12 }}>
            <P3 color={color.colors.neutral500}>
              {t('claim.minimal_claimable_amount')} 100 {ULX}
            </P3>
            <div></div>
          </FlexBetween>
        </Flexed>
      </Box>
      <CheckText
        checked={checkbox1}
        onClick={() => setCheckbox1(!checkbox1)}
        text={t('claim.confirm_i_am_aware_of_the_dolar_txt')}
        checkColor={color.colors.error400}
      />
      <CheckText
        checked={checkbox2}
        onClick={() => setCheckbox2(!checkbox2)}
        text={t('claim.i_accept_withdrawing')}
        checkColor={color.colors.error400}
      />

      <Flexed>
        <Button
          onClick={() => setClaim(false)}
          mt='20'
          mr='10'
          color={color.colors.success400}>
          {t('claim.cancel_withdrawal')}
        </Button>
        <Button
          onClick={() => {
            setClaimStep(ClaimSteps.STEP3);
            dispatch(getStakingWithdrawalInfo(packageItem.id, amount));
          }}
          mt='20'
          ml='10'
          disabled={
            !checkbox1 ||
            !checkbox2 ||
            amount < 100 ||
            amount > packageItem.availableStake
          }
          color={color.colors.error400}>
          {t('claim.continue_to_withdrawal')}
        </Button>
      </Flexed>
    </>
  );
};
