import React from 'react';
import { P2, P3 } from '../../../styles/FontStyles';
import { Box, Button } from '../../../styles/GlobalComponents';
import { Qualification } from '../CashbackBonusStyled';
import { useHistory } from 'react-router-dom';

import { Responsive } from '../../../styles/stylesStyled';
import { useTranslation } from 'react-i18next';

export const Section4 = () => {
  const { t } = useTranslation('cashback');

  const history = useHistory();
  return (
    <Box>
      <Qualification>
        <div>
          <P2 mb='8' bold flex between>
            {t('section4.title')}
          </P2>

          <P3>
            {t('section4.text')}
            <br />
            {t('section4.text2')}
          </P3>
        </div>
        <Responsive center>
          <Button
            m='10px 20px'
            w='350px'
            onClick={() => history.push('/backoffice/marketplace')}>
            {t('section4.button')}
          </Button>
        </Responsive>
      </Qualification>
    </Box>
  );
};
