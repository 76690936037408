import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { H2, H5, P1, P2, P3, CaptionText } from '../../../styles/FontStyles';
import { Button, Flexed } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';
import { Icon, IconWarningMark } from '../../../styles/Icons';
import { Checkbox } from '../Checkbox/Checkbox';
import { Hub } from '../Hub/Hub';

export const SelectStakingHubNFT = ({
  selectedHub,
  setSelectedHub,
  setStep
}) => {
  const { t } = useTranslation('marketplace');

  const { hubs } = useSelector((state) => state.hubs);
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxMonthly, setCheckboxMonthly] = useState(false);

  const yieldingStakingHub = hubs.find((i) => i.id === selectedHub);

  const findYieldStakingHub = () => {
    if (yieldingStakingHub.id < 32) {
      return 32;
    } else if (yieldingStakingHub.id >= 32 && yieldingStakingHub.id < 33) {
      return 33;
    } else {
      return 34;
    }
  };

  const upgradeToHigherStackingHub = () => {
    const { id, image, title, price, weeklyCap, calculator, limitedQuantity } =
      hubs.find((i) => i.id === findYieldStakingHub());

    return (
      <Hub
        key={id}
        condensed
        image={image}
        title={title}
        price={price}
        button={
          <Button
            w='150px'
            wSM='100%'
            onClick={() => setSelectedHub(id)}
            negative
            condensed>
            {t('select_this_hub')}
          </Button>
        }
        weeklyCap={weeklyCap}
        limitedQuantity={limitedQuantity}
        info={
          <Flexed alignEnd end>
            <H2 bold mr='16' alignEnd>
              {calculator}
            </H2>
            <CaptionText bold right>
              Reward
              <br /> Multiplicator
            </CaptionText>
          </Flexed>
        }
        stakingHubDetails={<></>}
      />
    );
  };

  return (
    <div>
      <P1 bold mb={20}>
        {t('selected_staking_hub_nft')}
      </P1>
      {hubs.map(
        ({
          id,
          image,
          title,
          price,
          weeklyCap,
          calculator,
          limitedQuantity
        }) => {
          if (id === selectedHub) {
            return (
              <Hub
                key={id}
                condensed
                image={image}
                title={title}
                price={price}
                button={
                  <Button w='150px' wSM='100%' condensed>
                    Hub selected
                  </Button>
                }
                weeklyCap={weeklyCap}
                limitedQuantity={limitedQuantity}
                info={
                  <Flexed alignEnd end>
                    <H2 bold mr='16' alignEnd>
                      {calculator}
                    </H2>
                    <CaptionText bold right>
                      Reward
                      <br /> Multiplicator
                    </CaptionText>
                  </Flexed>
                }
                stakingHubDetails={<></>}
              />
            );
          } else return null;
        }
      )}
      <Flexed center>
        <Icon>
          <IconWarningMark size='28' />
        </Icon>
        <H5 ml='5' center color={color.colors.neutral500}>
          {t('warning')}
        </H5>
      </Flexed>
      <P2 mb={20} mt={10}>
        <b> {t('continuing_without')}:</b>
        <br />- {t('not_being_able')}
        <br />- {t('do_not_want_to')}
      </P2>

      <Flexed center mb='20'>
        <Checkbox
          pointer
          margin='0 12px 0 0'
          checked={checkbox}
          onClick={() => setCheckbox(!checkbox)}
        />
        <P3 color={color.colors.neutral500} semiBold checked={checkbox}>
          {t('i_accept_that_first_order')}
        </P3>
      </Flexed>
      <Flexed center>
        <Checkbox
          pointer
          margin='0 12px 0 0'
          checked={checkboxMonthly}
          onClick={() => setCheckboxMonthly(!checkboxMonthly)}
        />
        <P3 color={color.colors.neutral500} semiBold checked={checkboxMonthly}>
          {t('i_do_not_want')}
        </P3>
      </Flexed>

      <Button mt={20} mb={20} onClick={() => setStep(5)}>
        {t('claim_first_order_gift')} 🎁
      </Button>
      <Button
        mb={20}
        negative
        disabled={!checkbox || !checkboxMonthly}
        onClick={() => setStep(1)}>
        {t('continue_with_selection')}
      </Button>

      {selectedHub !== 34 && (
        <>
          <P1 bold mt={40} mb={20}>
            {t('upgrade_to_higher')}:
          </P1>
          {upgradeToHigherStackingHub()}
        </>
      )}
    </div>
  );
};
