import { CLEAR_ALERT, CLEAR_MESSAGE } from '../../constants/errorConstants';
import {
  MTB_INIT,
  MTB_GET_ROOT_TEAM_MEMBER,
  MTB_ADD_NEW_MEMBER_MODAL,
  MTB_CLEAR_DIRECT_BREADCRUMBS,
  MTB_ADD_DIRECT_BREADCRUMBS,
  MTB_GET_DIRECT_MEMBERS,
  MTB_GET_DIRECT_LEG_USERS,
  ADD_DIRECT_MEMBER
} from './myTeamBackOffice.constants';

export const myTeamBackoffice = (
  state = {
    rootTeamMember: null,
    userAsTeamMember: null,
    userAsTeamMemberInfo: null,
    rootTeamMemberInfo: null,
    leftDirectUsers: [],
    rightDirectUsers: [],
    directBreadcrumbs: [],
    addNewMemberModal: {
      isOpen: false,
      parentId: null,
      position: null
    },
    isLoading: false,
    loadingLeftUsers: false,
    loadingRightUsers: false,
    volumeInfo: null,
    customers: null
  },
  action
) => {
  switch (action.type) {
    case MTB_INIT.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case MTB_INIT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload
      };
    case MTB_INIT.FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case MTB_GET_ROOT_TEAM_MEMBER.REQUEST:
      return {
        ...state,
        loadingDirect: true
      };
    case MTB_GET_ROOT_TEAM_MEMBER.SUCCESS:
      return {
        ...state,
        loadingDirect: false,
        ...action.payload
      };
    case MTB_GET_ROOT_TEAM_MEMBER.FAIL:
      return {
        ...state,
        loadingDirect: false,
        error: action.payload
      };
    //
    case MTB_GET_DIRECT_MEMBERS.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case MTB_GET_DIRECT_MEMBERS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload
      };
    case MTB_GET_DIRECT_MEMBERS.FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case MTB_GET_DIRECT_LEG_USERS.REQUEST:
      return {
        ...state,
        loadingLeftUsers: action.payload === 0 ? true : false,
        loadingRightUsers: action.payload === 1 ? true : false
      };
    case MTB_GET_DIRECT_LEG_USERS.SUCCESS:
      return {
        ...state,
        loadingLeftUsers: false,
        loadingRightUsers: false,
        leftDirectUsers:
          action.payload.leg === 0
            ? [...state.leftDirectUsers, ...action.payload.directUsers]
            : state.leftDirectUsers,
        rightDirectUsers:
          action.payload.leg === 1
            ? [...state.rightDirectUsers, ...action.payload.directUsers]
            : state.rightDirectUsers
      };
    case MTB_GET_DIRECT_LEG_USERS.FAIL:
      return {
        ...state,
        loadingLeftUsers: false,
        error: action.payload
      };

    case MTB_ADD_NEW_MEMBER_MODAL.OPEN:
      return {
        ...state,
        addNewMemberModal: {
          isOpen: true,
          parentId: action.payload.parentId,
          position: action.payload.position
        }
      };

    case MTB_ADD_NEW_MEMBER_MODAL.CLOSE:
      return {
        ...state,
        addNewMemberModal: {
          isOpen: false,
          parentId: null,
          position: null
        }
      };
    //
    case MTB_ADD_DIRECT_BREADCRUMBS:
      return {
        ...state,
        directBreadcrumbs: [...state.directBreadcrumbs, action.payload]
      };

    case MTB_CLEAR_DIRECT_BREADCRUMBS:
      return {
        ...state,
        directBreadcrumbs: []
      };

    case ADD_DIRECT_MEMBER.REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_DIRECT_MEMBER.SUCCESS:
      return {
        ...state,
        loading: false,
        leftDirectUsers:
          action.payload.leg === 0
            ? [...state.leftDirectUsers, action.payload.user]
            : state.leftDirectUsers,
        rightDirectUsers:
          action.payload.leg === 1
            ? [...state.rightDirectUsers, action.payload.user]
            : state.rightDirectUsers
      };

    case ADD_DIRECT_MEMBER.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CLEAR_ALERT:
      return {
        ...state,
        error: action.payload.error
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: action.payload.error
      };

    default:
      return state;
  }
};
