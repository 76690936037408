import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Spinner from '../../../components/common/Spinner';
import {
  Flex,
  // FlexSpaceBetween,
  FlexCenter
} from '../../../styles/stylesStyled';
import {
  AddressCheckbox,
  Info,
  Subtitle,
  Title,
  ShowButton,
  AmountBox
} from './WithdrawStyled';

import { useTranslation } from 'react-i18next';
import { formatUSD, USDT, showWallet } from '../../../helpers/helperFunctions';
import { CaptionText, P3 } from '../../../styles/FontStyles';
import { Button } from '../../../styles/GlobalComponents';
import { color } from '../../../styles/GlobalStyles';

export const WithdrawFinalCheck = ({
  setStep,
  amount,
  selected,
  userWallets,
  selectedNetwork
}) => {
  const { t } = useTranslation('earnings');
  const [checkbox, setCheckbox] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const fee = 5;
  const [total, setTotal] = useState(0);

  const { loadingWithdraw } = useSelector((state) => state.withdraw);

  const { verificationStatus } = useSelector((state) => state.settings);

  useEffect(() => {
    setTotal(parseInt(amount) - parseInt(fee));
  }, [amount, total]);

  return (
    <div>
      <>
        {loadingWithdraw ? (
          <Spinner dark />
        ) : (
          <>
            <Title>{t('withdraw_part.final_check.title')}</Title>
            <Subtitle>
              {t('withdraw_part.final_check.subtitle', { USDT: USDT })}{' '}
              {selectedNetwork?.networkType === 1 ? 'BEP20.' : 'TRC20.'}
            </Subtitle>
            <AmountBox>
              <Info>
                <div>{t('withdraw_part.final_check.amount')}:</div>
                {amount} {USDT}
              </Info>
              <Info style={{ wordBreak: 'break-all' }}>
                <div>{t('withdraw_part.final_check.network')}:</div>{' '}
                {selectedNetwork?.wallet}
              </Info>
              <Info style={{ wordBreak: 'break-all' }}>
                <div>{t('withdraw_part.final_check.address')}:</div>{' '}
                {showMore && userWallets[selected].address}
                {!showMore && showWallet(userWallets[selected].address, -9, 4)}
                {!showMore && (
                  <ShowButton onClick={() => setShowMore(true)}>
                    Show
                  </ShowButton>
                )}
              </Info>
              <Info>
                <div>{t('withdraw_part.final_check.processing_fee')}:</div>{' '}
                {fee} {USDT}
              </Info>
            </AmountBox>

            <Flex>
              <P3 bold mt='20' mb='20'>
                {t('withdraw_part.final_check.total')}:
              </P3>

              <P3 bold ml='10' mt='20' mb='20'>
                {formatUSD(total)} USDT
              </P3>
            </Flex>

            <P3 bold color={color.colors.error500} mb='16'>
              {t('withdraw_part.final_check.info')}
            </P3>
            <FlexCenter>
              <AddressCheckbox
                type='checkbox'
                checked={checkbox}
                onChange={() => setCheckbox(!checkbox)}
              />
              <CaptionText color={color.colors.neutral500} checked={checkbox}>
                {t('withdraw_part.final_check.confirm_txt')}
              </CaptionText>
            </FlexCenter>
            <FlexCenter style={{ flexDirection: 'column' }}>
              <Button
                maxWidth='200px'
                m={'30px 0 0 0'}
                opacity={'true'}
                disabled={!checkbox}
                onClick={() => {
                  if (!verificationStatus) {
                    setStep(8);
                  } else {
                    setStep(6);
                  }
                }}>
                {t('withdraw_part.final_check.withdraw')}
              </Button>
            </FlexCenter>
          </>
        )}
      </>
    </div>
  );
};
