import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Desktop, Mobile } from '../../App';
import SidebarNav from '../../components/common/SidebarNav';
import countries from '../../countries';
import {
  SidebarIcon,
  SidebarNavLinks,
  useOnClickOutside
} from '../../helpers/helperFunctions';
import {
  ACCOUNTING,
  CASHBACK_BONUS,
  CHAT_LIST,
  DASHBOARD,
  EARNINGS,
  GAMING,
  HODL_BONUS,
  MARKETPLACE,
  MY_HUBS,
  MY_TEAM,
  ORDERS,
  PACKAGES,
  RANKING,
  RESOURCES,
  SETTINGS_ACCOUNT,
  SETTINGS_LAYOUT,
  SETTINGS_NOTIFICATIONS,
  SETTINGS_PAYMENTS,
  SETTINGS_SECURITY,
  SETTINGS_TEAM_SETTINGS,
  SETTINGS_VIRTUAL_OFFICE
} from '../../routes/routes';
import { CaptionText, H5, P2, P3 } from '../../styles/FontStyles';
import { ArrowPolygon, UserImageCircle } from '../../styles/GlobalComponents';
import { color, globalImages } from '../../styles/GlobalStyles';
import { Icon, IconMenu } from '../../styles/Icons';
import { AppWrap, FlexCenter } from '../../styles/stylesStyled';
import { useWindowWide } from '../../utils/wideScreen';
import { ProfileDropdown } from '../common';
import {
  Layout,
  LayoutContentWrapper,
  Main,
  MainNav,
  Members,
  NavLeft,
  NewMembers,
  SideButtons,
  TopNavigation,
  TopNavigationMain,
  User
} from './BackofficeLayoutStyled';
import { FooterComponent } from './Footer';

export const BackofficeLayout = ({ children }) => {
  const location = useLocation().pathname;
  const match = useRouteMatch(`${CHAT_LIST}/:id?/:chatId?/:supportChatId?`);

  const LayoutContent = (
    <LayoutContentWrapper>
      <AppWrap
        style={{
          padding:
            location ===
              `${CHAT_LIST}/${match?.params?.id}/${match?.params?.chatId}` ||
            location === `${CHAT_LIST}` ||
            location === `${CHAT_LIST}/support`
              ? 0
              : ''
        }}>
        {children}
      </AppWrap>
      <Main
        style={{
          background: color.appLayout.footerBackground,
          width: '100%',
          maxWidth: '100%',
          zIndex: 1,
          boxShadow: color.shadows.shadowXS
        }}>
        {location !==
          `${CHAT_LIST}/${match?.params?.id}/${match?.params?.chatId}` &&
          location !== CHAT_LIST &&
          location !== MY_TEAM &&
          location !== `${CHAT_LIST}/support` &&
          location !== `${SETTINGS_ACCOUNT}` &&
          location !== `${SETTINGS_NOTIFICATIONS}` &&
          location !== `${SETTINGS_PAYMENTS}` &&
          location !== `${SETTINGS_SECURITY}` &&
          location !== `${SETTINGS_TEAM_SETTINGS}` && <FooterComponent />}
      </Main>
    </LayoutContentWrapper>
  );

  return (
    <>
      <Layout>{LayoutContent}</Layout>
    </>
  );
};

const ShowHide = () => {
  const location = useLocation().pathname;

  switch (location) {
    case MY_TEAM:
      return true;

    case ACCOUNTING:
      return true;

    case DASHBOARD:
      return true;

    case PACKAGES:
      return true;

    case EARNINGS:
      return true;

    case SETTINGS_LAYOUT:
      return true;

    case SETTINGS_ACCOUNT:
      return true;

    case SETTINGS_SECURITY:
      return true;

    case SETTINGS_PAYMENTS:
      return true;

    case SETTINGS_VIRTUAL_OFFICE:
      return true;

    case SETTINGS_NOTIFICATIONS:
      return true;

    case SETTINGS_TEAM_SETTINGS:
      return true;

    case `${ORDERS}`:
      return true;

    case `${MY_HUBS}`:
      return true;

    case `${HODL_BONUS}`:
      return true;

    case `${CASHBACK_BONUS}`:
      return true;

    case `${GAMING}`:
      return true;

    case `${MARKETPLACE}`:
      return true;

    case `${RANKING}`:
      return true;

    case `${RESOURCES}`:
      return true;

    default:
      return false;
  }
};

export const Sidebar = () => {
  const location = useLocation().pathname;

  return (
    <AnimatePresence exitBeforeEnter>
      {ShowHide() && (
        <SideButtons
          key='SideButtons'
          initial={{ transform: 'translateX(-72px)' }}
          animate={{ transform: 'translateX(0px)' }}
          exit={{ transform: 'translateX(-72px)' }}
          transition={{
            duration: 0.2
          }}>
          {SidebarNavLinks().map((link, i) => {
            return (
              <Link
                to={link.link}
                key={i}
                style={{
                  pointerEvents: link.disabled && 'none'
                }}>
                <FlexCenter
                  style={{
                    opacity: link.disabled ? 0.3 : 1
                  }}>
                  <Icon
                    m='20px'
                    w='32px'
                    color={color.appLayout.sideNavIconColor}>
                    <SidebarIcon link={link.name} />
                  </Icon>
                  <p
                    style={{
                      color:
                        link.link === location
                          ? color.colors.primary900
                          : color.colors.neutral900
                    }}>
                    {link.name}
                  </p>
                </FlexCenter>
              </Link>
            );
          })}
        </SideButtons>
      )}
    </AnimatePresence>
  );
};

export const TopNav = () => {
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const wide = useWindowWide(800);
  const [sidebar, setSidebar] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => menuIsOpen && setMenuIsOpen(false));
  const history = useHistory();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [setLanguage] = useState(false);

  return (
    <AnimatePresence exitBeforeEnter>
      {ShowHide() && (
        <TopNavigation
          key='TopNavigation'
          initial={{ transform: 'translateY(-70px)' }}
          animate={{ transform: 'translateY(0px)' }}
          exit={{ transform: 'translateY(-70px)' }}
          transition={{
            duration: 0.2
          }}>
          <TopNavigationMain>
            <NavLeft>
              <Mobile>
                <Icon
                  mr='10'
                  pointer
                  onClick={() => {
                    setSidebar(!sidebar);
                  }}
                  color={color.appLayout.navIconColor}
                  stroke='true'>
                  <IconMenu />
                </Icon>
              </Mobile>
              <img
                src={globalImages.appLogo}
                alt='logo'
                style={{
                  marginRight: wide ? 56 : '',
                  height: wide ? '' : '30px',
                  cursor: 'pointer'
                }}
                onClick={() => history.push(DASHBOARD)}
              />
            </NavLeft>

            <MainNav>
              <FlexCenter ref={ref}>
                <div
                  onClick={() => {
                    setMenuIsOpen(!menuIsOpen);
                  }}>
                  <UserImageCircle
                    image={userInfo?.user?.profileImageUrl}
                    name={userInfo?.user?.name}
                    surname={userInfo?.user?.surname}
                    size='40px'
                    fontSize='15px'
                    margin={wide ? '0 16px 0 0' : '0'}
                  />
                </div>

                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '34px',
                    position: 'relative'
                  }}>
                  <Desktop>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setMenuIsOpen(!menuIsOpen);
                      }}>
                      <P2 mr='10'>{userInfo?.user?.name}</P2>

                      {/* <Icon ml='10' pointer color={color.colors.neutral900}>
                        <IconArrowPolygon />
                      </Icon> */}

                      <ArrowPolygon direction={menuIsOpen ? 'up' : 'down'} />
                    </div>
                  </Desktop>
                  <div>
                    <ProfileDropdown
                      setLanguage={setLanguage}
                      menuIsOpen={menuIsOpen}
                      userInfo={userInfo}
                      setMenuIsOpen={setMenuIsOpen}
                      setLanguageDropdown={setLanguageDropdown}
                      languageDropdown={languageDropdown}
                    />
                  </div>
                </div>
              </FlexCenter>
            </MainNav>
          </TopNavigationMain>
          <SidebarNav open={sidebar} onClose={setSidebar} history={history} />
        </TopNavigation>
      )}
    </AnimatePresence>
  );
};

export const MarqueeNewMembers = () => {
  const location = useLocation().pathname;
  const wide = useWindowWide(800);
  const { users } = useSelector((state) => state.dashboard);

  return (
    <AnimatePresence exitBeforeEnter>
      {location === DASHBOARD && users?.length > 0 && (
        <NewMembers
          key='NewMembers'
          initial={{ transform: 'translateY(-60px)' }}
          animate={{ transform: 'translateY(0px)' }}
          exit={{ transform: 'translateY(-60px)' }}
          transition={{
            duration: 0.2
          }}>
          <div style={{ width: 150 }}>
            {wide ? (
              <H5 bold> NEW MEMBERS:</H5>
            ) : (
              <CaptionText bold> NEW MEMBERS:</CaptionText>
            )}
          </div>

          <Members>
            {users?.length > 0 && (
              <Marquee
                loop={0}
                gradientColor={[242, 246, 255]}
                speed={40}
                gradientWidth={wide ? 200 : 50}>
                {users?.map((user, i) => {
                  return (
                    <User key={i}>
                      {countries.map((country, i) => {
                        if (country.code === user.countryCode) {
                          return (
                            <img
                              key={i}
                              src={country.flag}
                              alt='flag'
                              style={{
                                width: '28px',
                                height: '20px',
                                marginRight: '8px'
                              }}
                            />
                          );
                        } else if (country.dial_code === user.countryCode) {
                          return (
                            <img
                              key={i}
                              src={country.flag}
                              alt='flag'
                              style={{
                                width: '28px',
                                height: '20px',
                                marginRight: '8px'
                              }}
                            />
                          );
                        }
                      })}
                      <div>
                        {wide ? (
                          <P3 bold>{user.username}</P3>
                        ) : (
                          <CaptionText bold>{user.username}</CaptionText>
                        )}

                        <CaptionText>
                          {moment(user.dateCreated).fromNow()}
                        </CaptionText>
                      </div>
                    </User>
                  );
                })}
              </Marquee>
            )}
          </Members>
        </NewMembers>
      )}
    </AnimatePresence>
  );
};
