import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarModal } from '../../../components/common/Sidebar/Sidebar';
import { TwoFAInit } from '../TwoFAInit';
import { TwoFAEmail } from '../TwoFAEmail';
import { FlexBetween } from '../../../styles/stylesStyled';
import { Box, Dot, Label, Text, WithSocial } from '../SettingsStyled';
import { Button } from '../../../styles/GlobalComponents';
import { enableVerificationStatus } from '../settings.actions';
import { useTranslation } from 'react-i18next';
import { P3 } from '../../../styles/FontStyles';
import { color } from '../../../styles/GlobalStyles';
import { PasswordSteps } from './PasswordSteps';
import { TwoFALogin } from '../TwoFALogin';
import { ErrorToast } from '../../../components/common/Toasts/Toasts';

export const PasswordSettings = () => {
  const { t } = useTranslation('settings');
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [step, setStep] = useState(1);

  const [passwordStep, setPasswordStep] = useState(1);

  const {
    verificationStatus,
    signIn2FAEnabled,
    signInEmailCodeVerificationEnabled
  } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.userInfo);

  const renderDots = () => {
    let content = [];
    for (let i = 0; i < 8; i++) {
      content.push(<Dot key={i} />);
    }

    return content;
  };

  return (
    <>
      <Box style={{ opacity: user?.creationType === 1 ? '' : '0.5' }}>
        <FlexBetween>
          <div>
            <Label>Password</Label>
            <Text style={{ margin: 0 }}>{renderDots()}</Text>
          </div>
          {user?.creationType === 1 ? (
            <Button
              maxWidth='150px'
              style={{
                padding: ' 0 20px',
                cursor: user?.creationType === 1 ? 'pointer' : 'not-allowed'
              }}
              negative
              opacity='true'
              onClick={() => {
                setIsOpen(user?.creationType === 1 ? true : false);
                setPasswordStep(1);
              }}>
              {t('security.edit')}
            </Button>
          ) : (
            <WithSocial>
              {t('security.registered')} <br />
              {t('security.with_social')}
            </WithSocial>
          )}
        </FlexBetween>
      </Box>
      <P3 mb='10' mt='25' color={color.colors.neutral500}>
        Email verification
      </P3>
      {!signInEmailCodeVerificationEnabled && (
        <P3 bold mb='15' mt='15' color={color.colors.error500}>
          Protecting your account with email and/or 2FA verification at login
          increases safety of your account by 800%.
        </P3>
      )}
      <Box>
        <FlexBetween>
          <Text style={{ margin: 0 }}>Email verification at login</Text>
          <Button
            maxWidth='150px'
            style={{
              padding: ' 0 20px',
              cursor: 'pointer'
            }}
            disabled={signInEmailCodeVerificationEnabled}
            negative={!signInEmailCodeVerificationEnabled}
            opacity='true'
            onClick={() => {
              if (!signInEmailCodeVerificationEnabled) {
                setIsOpen4(!isOpen4);
              }
            }}>
            {signInEmailCodeVerificationEnabled ? 'Enabled' : 'Enable'}
          </Button>
        </FlexBetween>
      </Box>

      <P3 mb='10' mt='25' color={color.colors.neutral500}>
        Two-Factor Authenticator (2FA) - Google Authenticator recommended
      </P3>
      <Box>
        <FlexBetween>
          <Text style={{ margin: 0 }}>Setup 2FA App</Text>
          <Button
            maxWidth='150px'
            style={{
              padding: ' 0 20px',
              cursor: 'pointer'
            }}
            negative={!verificationStatus ? true : false}
            opacity='true'
            onClick={() => {
              if (!verificationStatus) {
                setStep(1);
                setIsOpen2(!isOpen2);
                dispatch(enableVerificationStatus());
              } else {
                setStep(2);
                setIsOpen2(!isOpen2);
              }
            }}>
            {!verificationStatus ? 'Setup' : 'Remove'}
          </Button>
        </FlexBetween>
        <div style={{ marginTop: 50 }}>
          <FlexBetween>
            <Text style={{ margin: 0 }}>2FA verification at login</Text>
            <Button
              maxWidth='150px'
              style={{
                padding: ' 0 20px',
                cursor: 'pointer'
              }}
              negative={!signIn2FAEnabled ? true : false}
              opacity='true'
              onClick={() => {
                if (!verificationStatus) {
                  ErrorToast('Please enable Two-Factor Authenticator');
                } else {
                  setIsOpen3(!isOpen3);
                }
              }}>
              {!signIn2FAEnabled ? 'Enable' : 'Disable'}
            </Button>
          </FlexBetween>
        </div>
      </Box>
      <P3 m='20px' color={color.colors.neutral500}>
        Why is it necessary to set up a 2-step verification? <br /> <br />{' '}
        2-step verification is essential to web security as it immediately
        neutralizes the risks of compromised passwords. If a password is hacked,
        guessed, or even phished, that is no longer enough to give an intruder
        access: without approval, the second factor, a password alone, is
        useless.
      </P3>
      <SidebarModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={t('security.change_your_password')}
        sidebarWidth={'508px'}>
        <PasswordSteps
          step={passwordStep}
          setStep={setPasswordStep}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      </SidebarModal>

      <SidebarModal
        isOpen={isOpen2}
        setOpen={setIsOpen2}
        title={t('security.verification')}
        sidebarWidth={'511px'}>
        <TwoFAInit step={step} setStep={setStep} setIsOpen={setIsOpen2} />
      </SidebarModal>

      <SidebarModal
        isOpen={isOpen3}
        setOpen={setIsOpen3}
        title={'2FA verification at login'}
        sidebarWidth={'511px'}>
        <TwoFALogin setIsOpen={setIsOpen3} />
      </SidebarModal>

      <SidebarModal
        isOpen={isOpen4}
        setOpen={setIsOpen4}
        title={'Email verification setup'}
        sidebarWidth={'511px'}>
        <TwoFAEmail setIsOpen={setIsOpen4} />
      </SidebarModal>
    </>
  );
};
