import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getContentBlock } from '../../actions/contentBlockActions';

import Spinner from '../../components/common/Spinner';

export const TermsOfUse = () => {
  const dispatch = useDispatch();
  const { contentBlock, loading } = useSelector((state) => state.contentBlock);

  useEffect(() => {
    dispatch(getContentBlock(200));
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Spinner fullScreen={true} />
      ) : (
        <div style={{ maxWidth: '800px', margin: '0 auto', marginTop: 50 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: contentBlock && contentBlock.content
            }}
          />
        </div>
      )}
    </>
  );
};
