import React from 'react';
import { Desktop, Mobile } from '../../../App';
import Spinner from '../../../components/common/Spinner';
import { H3 } from '../../../styles/FontStyles';
import { color } from '../../../styles/GlobalStyles';
import { Transaction } from '../Portfolio.styled';

export const BonusHubTransactions = ({ loading, transactions }) => {
  const EXPLORER_LINK = process.env.REACT_APP_EXPLORER_LINK;

  const showTxidDesktop = (address) => {
    const text1 = address?.slice(0, 45);
    const text2 = address?.slice(-4);

    return `${text1}...${text2}`;
  };

  const showTxidMobile = (address) => {
    const text1 = address?.slice(0, 34);
    const text2 = address?.slice(-4);

    return `${text1}...${text2}`;
  };

  if (loading) return <Spinner solid={false} />;

  return (
    <div>
      <H3 mb='20' center color={color.colors.secondary100}>
        Bonus hub transactions
      </H3>
      {transactions?.user_address_transactions?.length > 0 ? (
        transactions?.user_address_transactions?.map((transaction, index) => (
          <a
            href={`${EXPLORER_LINK}/transactions/${transaction.txid}`}
            target='_blank'
            rel='noreferrer'
            key={index}>
            <Desktop>
              <Transaction>{showTxidDesktop(transaction.txid)}</Transaction>
            </Desktop>
            <Mobile>
              <Transaction>{showTxidMobile(transaction.txid)}</Transaction>
            </Mobile>
          </a>
        ))
      ) : (
        <div style={{ textAlign: 'center' }}>You have no transactions.</div>
      )}
    </div>
  );
};
