import axios from 'axios';
import {
  GET_USER_SETTINGS_REQUEST,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_FAIL,
  UPDATE_USER_SETTINGS_FAIL,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_REQUEST
} from '../constants/userDetailsConstants';

import {
  createAxiosConfigWithAuthHeader,
  tryExecuteSimpleAction
} from './common';
const API_URL = process.env.REACT_APP_API_URL;

export const getUserSettings = () => async (dispatch, getState) => {
  await tryExecuteSimpleAction(
    dispatch,
    GET_USER_SETTINGS_REQUEST,
    async () => {
      const { data } = await axios.get(
        `${API_URL}/user/settings`,
        createAxiosConfigWithAuthHeader(getState)
      );

      dispatch({
        type: GET_USER_SETTINGS_SUCCESS,
        payload: data.data.settings
      });
    },
    GET_USER_SETTINGS_FAIL
  );
};

export const updateTeamCompletionSorting =
  (teamCompletionSorting) => async (dispatch, getState) => {
    await tryExecuteSimpleAction(
      dispatch,
      UPDATE_USER_SETTINGS_REQUEST,
      async () => {
        const { data } = await axios.put(
          `${API_URL}/user/settings`,
          { teamCompletionSorting },
          createAxiosConfigWithAuthHeader(getState)
        );

        dispatch({
          type: UPDATE_USER_SETTINGS_SUCCESS,
          payload: data.data.settings
        });
      },
      UPDATE_USER_SETTINGS_FAIL
    );
  };
