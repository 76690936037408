import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SETTINGS_SECURITY } from '../../../routes/routes';
import { Button } from '../../../styles/GlobalComponents';
import { FlexCenter } from '../../../styles/stylesStyled';
import { Subtitle, Title } from './WithdrawStyled';

export const Withdraw2FARequired = () => {
  const { t } = useTranslation('earnings');
  return (
    <div style={{ marginTop: '11vh' }}>
      <FlexCenter style={{ flexDirection: 'column' }}>
        <Title>{t('withdraw_part.2FARequired.title')}</Title>
        <Subtitle style={{ textAlign: 'center' }}>
          {t('withdraw_part.2FARequired.subtitle')} <br /> <br />{' '}
          {t('withdraw_part.2FARequired.subtitle2')}
        </Subtitle>
        <Link to={SETTINGS_SECURITY}>
          <Button
            w='200px'
            opacity={'true'}
            // onClick={() => setWithdraw(false)}
          >
            {t('withdraw_part.2FARequired.Add2FASecurity')}
          </Button>
        </Link>
      </FlexCenter>
    </div>
  );
};
